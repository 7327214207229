import { observer } from 'mobx-react-lite';
import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Grid, IconButton, Theme } from '@mui/material';

import { useVm } from '../../../../domain/hooks/useVm';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { SvgWrapper } from '../../../../toolkit/components/SvgWrapper';
import { GoogleMapVm } from '../google-map/GoogleMapVm';
import { MapControlsVm } from './MapControlsVm';

import { MapLocationIcon } from '@HunterCompanion/hunter-design/icons';

interface IMapControlButtonProps extends React.PropsWithChildren<unknown> {
  theme: Theme;
  onClick: () => void;
}

const MapControlButton = ({ theme, onClick, children }: IMapControlButtonProps) => (
  <Grid
    item
    display="flex"
    justifyContent="center"
    alignItems="center"
    border={`1px solid ${theme.palette.borderDividers?.border}`}
    borderRadius="8px"
    bgcolor={theme.palette.buttons?.map_button}
    width={32}
    height={32}
  >
    <IconButton disableRipple sx={{ padding: 0, }} onClick={onClick}>
      {children}
    </IconButton>
  </Grid>
);


export interface IMapControlsProps {
  googleMapVm: GoogleMapVm;
  zoomToDistrictLevel: () => void;
}

export const MapControls = observer(function MapControls(props: IMapControlsProps) {
  const { theme } = useThemeContext();
  const vm = useVm(MapControlsVm, props);

  return (
    <Grid
      container
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      alignItems={'center'}
      position={'absolute'}
      width={32}
      rowGap={1}
      top={50}
      right={20}
    >
      <MapControlButton theme={theme} onClick={vm.onZoomIn}>
        <AddIcon htmlColor={theme.palette.icons?.secondary} sx={{ width: 20, height: 20 }} />
      </MapControlButton>
      <MapControlButton theme={theme} onClick={vm.onZoomOut}>
        <RemoveIcon htmlColor={theme.palette.icons?.secondary} sx={{ width: 20, height: 20 }} />
      </MapControlButton>
      <MapControlButton theme={theme} onClick={props.zoomToDistrictLevel}>
        <SvgWrapper icon={MapLocationIcon} fill={theme.palette.icons?.secondary} width={16} height={16} />
      </MapControlButton>
    </Grid>
  );
});
