import CheckIcon from '@mui/icons-material/Check';
import { Box, SxProps, Theme } from '@mui/material';
import { colors } from '../../../../../theme/options/palette/const/colors';

interface IProps {
  sx?: SxProps<Theme>;
}

export function FilterActive(props: IProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '3px',
        right: 0,
        borderRadius: '50%',
        width: '15px',
        height: '15px',
        bgcolor: colors.grapple,
        ...props.sx,
      }}>
      <CheckIcon sx={{ color: 'white', fontSize: '10px', }} />
    </Box>
  );
}
