import { IsEnum, IsOptional, IsUUID } from 'class-validator';
import { ApiProperty } from '@nestjs/swagger';
import { DISTRICT_ROLE, ENTITY_TYPE, EVENT_ROLE, GENERIC_ROLE, GROUP_ROLE } from '../enum';

export class InvitationCodePostRequestDto {

  @IsUUID()
  @ApiProperty()
  entityId: string;

  @IsEnum(ENTITY_TYPE)
  @ApiProperty({ enum: ENTITY_TYPE })
  entityType: ENTITY_TYPE;

  @ApiProperty()
  @IsOptional()
  role?: DISTRICT_ROLE | GROUP_ROLE | EVENT_ROLE | GENERIC_ROLE;
}
