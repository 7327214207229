import { computed, makeObservable, observable } from 'mobx';
import { DistrictModel } from '../../../domain/model/DistrictModel';
import { MapPathModel } from '../../../domain/model/MapPathModel';
import { MapPathTypeModel } from '../../../domain/model/MapPathType';

import { AbstractDistrictFilter } from './AbstractDistrictFilter';

export class MapPathTypeFilterCondition {

  @observable
  public id: string = '';

  @observable
  public active: boolean = false;

  @observable
  public type: MapPathTypeModel = null!;

  constructor() {
    makeObservable(this);
  }

  public static fromType(type: MapPathTypeModel): MapPathTypeFilterCondition {
    const filter = new MapPathTypeFilterCondition();
    filter.id = type.id;
    filter.active = true;
    filter.type = type;

    return filter;
  }
}

export class MapPathsFilter extends AbstractDistrictFilter {

  constructor(
    district: DistrictModel | null,
    private readonly paths: MapPathModel[],
    private readonly types: MapPathTypeModel[],
  ) {
    super((district && district.id) ? `DistrictFilterVm-MapPaths-district-${district!.id!}` : 'DistrictFilterVm-MapPaths', 1);
    makeObservable(this);
  }

  @computed
  public get conditions() {
    const conditions = this.types
      .filter((t) => this.paths.find((p) => p.typeId === t.id))
      .map((t) => MapPathTypeFilterCondition.fromType(t));

    return this.loadCache(conditions);
  }

  public check = (path: MapPathModel): boolean => {
    const activeConditions = this.conditions.filter((f) => f.active);

    // there is no active condition
    if (!activeConditions.length) {
      return false;
    }

    return activeConditions.some((c) => {
      return c.id === path.typeId;
    });
  }
}
