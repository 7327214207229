import clsx from 'clsx';
import React from 'react';

import { Box, capitalize } from '@mui/material';
import { ClassNameMap } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';

import BackgroundImage from '../../assets/img/bg-app.png';
import { CookieConsent } from '../plugin/cookie-consent/CookieConsent';
import { oldDesignTheme } from '../../theme/oldDesignTheme';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    height: '100vh',
    overflow: 'hidden',
    width: '100vw',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    zIndex: 1,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
  bgImgApp: {
    backgroundColor: oldDesignTheme.palette.background.default,
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
});

interface IProps extends React.PropsWithChildren<unknown> {
  bgcolor?: string;
  bgimage?: 'app';
  dataCy?: string;
}

export function View(props: IProps) {
  const classes = useStyles();

  const { bgcolor = 'background.default', bgimage = '' } = props;

  return (
    <Box
      bgcolor={bgcolor}
      data-cy={props.dataCy}
      className={clsx(classes.root, {
        [(classes as ClassNameMap)[`bgImg${capitalize(bgimage)}`]]:
          bgimage !== '' && bgimage != null,
      })}
    >
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>

      {CookieConsent()}

    </Box>
  );
}
