import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, Grid, IconButton, Link, Typography } from '@mui/material';

import { useVm } from '../../../../../domain/hooks/useVm';
import { REGION } from '../../../../../shared/enum/region.enum';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { ImageCarousel } from '../../../../../toolkit/components/image-carousel/ImageCarousel';
import { OptionalTooltip } from '../../../../../toolkit/components/OptionalTooltip';
import { toLanguageRegion } from '../../../../../util/classification/ClassificationUtils';
import { EntryAttributedTo } from './attributed-to/EntryAttributedTo';
import {
  ClassificationRegion
} from './classification/components/classification-region/ClassificationRegion';
import { EntryClassification } from './classification/EntryClassification';
import { CustomizedMenu } from './components/CustomizedMenu';
import { EntryDetails } from './details/EntryDetails';
import { EntryDisease } from './disease/EntryDisease';
import { EntryFallwild } from './fallwild/EntryFallwild';
import { EntryGearset } from './gearset/EntryGearset';
import { EntryPosition } from './position/EntryPosition';
import { EntryTabTypeEnum, IEntryTab, IViewEntryProps, ViewEntryVm } from './ViewEntryVm';

export const ViewEntry = observer(function ViewEntry(props: IViewEntryProps) {
  const vm = useVm(ViewEntryVm, props);
  const { t } = useTranslation();

  useEffect(() => {
    if (props.entry.id) {
      const detailsTab = vm.entryTabs.find((t) => t.type === EntryTabTypeEnum.DETAILS);
      if (detailsTab) {
        vm.setCurrentTab(detailsTab);
      }
    } else {
      vm.setCurrentTab(vm.entryTabs[0]);
    }
  }, [props.entry, vm]);

  const onEntryDelete = useCallback(() => {
    props.onDelete(props.entry);
  }, [props]);

  const onEntryUpsert = useCallback(() => {
    props.upsertEntry(props.entry);
  }, [props]);

  const belongingDistrict = useMemo(() => {
    return props.districtsVm.allDistricts.find(d => d.id === props.entry.districtId);
  }, [props.districtsVm.allDistricts, props.entry.districtId]);

  const renderHeader = useCallback(() => {
    return (
      <Box paddingLeft={2.5} width={'100%'}>
        {/* REGION AND CLOSE ICON */}
        <Grid item container direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <Grid item>
            {vm.currentTab.type === EntryTabTypeEnum.CLASSIFICATION && <Grid item>
              <Link onClick={vm.openRegionSelection} href="#" color="inherit">{t('entry:change_region')}</Link>
            </Grid>}
          </Grid>

          <Grid item alignSelf={'flex-end'}>
            <IconButton color="inherit" onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        {/* ENTRY ICON */}
        <Grid item container direction="column" alignItems="flex-start">
          <Grid item >
            <img src={vm.entryIcon} width="40px" height="40px" />
          </Grid>
        </Grid>

        {/* ENTRY CLASSIFICATION AND MENU */}
        <Grid item container justifyContent={'space-between'} alignItems={'center'}
          paddingBottom={props.entry.photos.length ? 3 : 0}
        >
          <Grid item container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={11} textAlign="start">
              <Typography variant="h4" sx={{ textShadow: '1px 1px 3px #00000029' }}>{vm.classificationDescription}</Typography>
              <Typography variant='caption'>{belongingDistrict?.name}</Typography>
            </Grid>

            {/* MENU */}
            {props.entry.id && <Grid item xs={1}>
              <CustomizedMenu
                locizeNamespace="entry"
                onDelete={onEntryDelete}
                onUpsert={onEntryUpsert}
                canEditAndDeleteEntries={vm.canEditAndDeleteEntries}
                item={props.entry}
              />
            </Grid>}
          </Grid>
        </Grid>
      </Box>
    );
  }, [belongingDistrict?.name, onEntryDelete, onEntryUpsert, props.entry, props.onClose, t, vm.canEditAndDeleteEntries, vm.classificationDescription, vm.currentTab.type, vm.entryIcon, vm.openRegionSelection]);

  const renderHeaderNoImages = useCallback(() => (
    <Grid container direction="column" justifyContent="center"
      sx={{
        background: vm.entryBackground,
        color: colors.paper,
        paddingX: 2,
        paddingY: 1,
        minHeight: 150,
      }}
    >
      {renderHeader()}
    </Grid>
  ), [vm.entryBackground, renderHeader]);

  const renderHeaderWithImages = useCallback(() => (
    <React.Fragment>
      <ImageCarousel
        item={props.entry}
        canDeleteImage={vm.canEditAndDeleteEntries}
        onUpsert={onEntryUpsert}
        onClose={props.onClose}
        locizeNamespace="entry"
      >{renderHeader()}</ImageCarousel>
    </React.Fragment>
  ), [onEntryUpsert, props.entry, props.onClose, renderHeader, vm.canEditAndDeleteEntries]);

  if (vm.regionSelection) {
    return <ClassificationRegion
      headerColor={vm.entryBackground}
      region={props.entry.region}
      onClose={vm.closeRegionSelection}
      onRegionChange={vm.setRegion}
    />;
  }

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box sx={{ overflowY: 'auto', paddingBottom: '70px', flex: '1 0 auto' }}>

        {/* ENTRY HEADER */}
        {!props.entry.photos.length ? renderHeaderNoImages() : renderHeaderWithImages()}

        {/* TABS */}
        <Grid container alignItems="center" spacing={1}
          padding={2}
          sx={{
            maxWidth: 'inherit',
            boxShadow: '0 8px 3px -6px #00000033',
          }}>
          {vm.entryTabs.map((entryTab: IEntryTab) => {
            return (
              <Grid item key={`entryTabItem-${entryTab.type}`}>
                <Chip
                  clickable
                  variant="outlined"
                  label={entryTab.name}
                  onClick={() => vm.setCurrentTab(entryTab)}
                  style={vm.currentTab.type === entryTab.type
                    ? {
                      borderColor: '#02654E',
                      color: '#02654E',
                      backgroundColor: '#DFF1EE',
                    }
                    : {}} />
              </Grid>
            );
          })}
        </Grid>
        <Grid container>
          {vm.currentTab.type === EntryTabTypeEnum.POSITION && <EntryPosition
            canEditAndDeleteEntries={vm.canEditAndDeleteEntries}
            isHarvestVisibleAndEditable={vm.isHarvestVisibleAndEditable}
            entry={props.entry}
          />}
          {vm.currentTab.type === EntryTabTypeEnum.CLASSIFICATION && <EntryClassification
            canEditAndDeleteEntries={vm.canEditAndDeleteEntries}
            isHarvestVisibleAndEditable={vm.isHarvestVisibleAndEditable}
            entry={props.entry}
            languageRegion={toLanguageRegion(vm.i18n.language, props.entry.region ?? REGION.dach)}
          />}
          {vm.currentTab.type === EntryTabTypeEnum.DETAILS && <EntryDetails
            canEditAndDeleteEntries={vm.canEditAndDeleteEntries}
            isHarvestVisibleAndEditable={vm.isHarvestVisibleAndEditable}
            entry={props.entry}
          />}
          {vm.currentTab.type === EntryTabTypeEnum.ATTRIBUTED_TO && <EntryAttributedTo
            canEditAndDeleteEntries={vm.canEditAndDeleteEntries}
            isHarvestVisibleAndEditable={vm.isHarvestVisibleAndEditable}
            entry={props.entry}
            districts={props.districtsVm.allDistricts}
            onSelectDistrict={props.districtsVm.setSelectedDistrict}
          />}
          {vm.currentTab.type === EntryTabTypeEnum.FALLWILD && <EntryFallwild
            entry={props.entry}
            canEditAndDeleteEntries={vm.canEditAndDeleteEntries}
            isHarvestVisibleAndEditable={vm.isHarvestVisibleAndEditable}
          />}
          {vm.currentTab.type === EntryTabTypeEnum.DISEASE && <EntryDisease
            entry={props.entry}
            canEditAndDeleteEntries={vm.canEditAndDeleteEntries}
            isHarvestVisibleAndEditable={vm.isHarvestVisibleAndEditable}
            diseasesForSpecies={vm.diseasesForSpecies}
          />}
          {vm.currentTab.type === EntryTabTypeEnum.WEAPONS_AND_AMMUNITION && <EntryGearset
            entry={props.entry}
            canEditAndDeleteEntries={vm.canEditAndDeleteEntries}
            isHarvestVisibleAndEditable={vm.isHarvestVisibleAndEditable}
          />}
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent={'center'}
        alignItems={'center'}
        position={'sticky'}
        bottom={0}
        bgcolor="white"
        width="100%"
        minHeight={'70px'}
        borderTop={1}
        borderColor={'text.details'}
        paddingX={4}
      >
        <Button onClick={props.onClose} sx={{ paddingX: 7, paddingY: 1, marginRight: 4, }} variant="outlined" color="error">
          <Typography>{t('entry:entry_cancel')}</Typography>
        </Button>
        <OptionalTooltip
          enabled={!vm.canBeSaved}
          title={<Typography
            bgcolor={'primary.main'}
            color="white"
            textAlign={'center'}
            borderRadius={1}
            paddingX={2}
            paddingY={1}
          >
            {t('entry:entry_missing_position_popup')}
          </Typography>}
        >
          <span>
            <LoadingButton
              loading={props.onSave.isBusy}
              disabled={!vm.canBeSaved || !vm.canEditAndDeleteEntries}
              onClick={vm.handleSaveClick}
              sx={{ paddingX: 7, paddingY: 1, }} variant="contained" color="secondary"
            >
              <Typography>
                {(vm.currentTab.type === EntryTabTypeEnum.POSITION || vm.currentTab.type === EntryTabTypeEnum.CLASSIFICATION) ? t('entry:entry_next_tab') : t('entry:entry_save')}
              </Typography>
            </LoadingButton>
          </span>
        </OptionalTooltip>
      </Box>
    </Box >
  );
});
