import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Button, Menu, MenuItem, MenuProps, styled, Typography, useMediaQuery
} from '@mui/material';

import { AppRoutes } from '../../../../router/AppRoutesEnum';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import {
  AssignSeatOptionType
} from '../../group-subscription/assign-area-members/assign-area-members-modal/AssignAreaMembersModal';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.surface,
    borderRadius: 6,
    marginTop: 8,
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  },
}));

export interface AssignSeatLocationState {
  assignOption: AssignSeatOptionType;
}

export const AssignEmptySeat = function AssignEmptySeat() {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = useCallback((option: AssignSeatOptionType) => {
    navigate(AppRoutes.AddMembersToGroup, { state: { assignOption: option } as AssignSeatLocationState });
    handleCloseMenu();
  }, [navigate]);

  return (
    <React.Fragment>
      <Button
        onClick={handleClick}
        disableRipple
        variant='text'
        color='primary'
        sx={{
          backgroundColor: open ? theme.palette.background.container_2 : '',
        }}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      >
        <Typography variant={isMobileSize ? 'text_md_semibold' : 'text_sm_semibold'}>
          {t('manage_group:table.button.cta.empty_seat')}
        </Typography>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        {/* EXISTING MEMBERS */}
        <MenuItem onClick={() => handleOpenModal('existing_members')} disableRipple>
          <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
            {t('group_subscription:table.button.cta.option.existing_member')}
          </Typography>
        </MenuItem>

        {/* EMAIL INVITE */}
        {/* <MenuItem onClick={() => handleOpenModal('email_invite')} disableRipple>
          <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
            {t('group_subscription:table.button.cta.option.member')}
          </Typography>
        </MenuItem> */}

        {/* <MenuItem onClick={handleClose} disableRipple>
          <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
            {t('group_subscription:table.button.cta.option.empty_seat')}
          </Typography>
        </MenuItem> */}
      </StyledMenu>
    </React.Fragment>
  );
};
