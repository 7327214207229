import React from 'react';

interface IProps {
  color: string;
}

export function PoiBackground(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="59" height="59" viewBox="0 0 59 59">
      <defs>
        <filter id="Path_31503" x="2.056" y="-2.991" width="56.889" height="67.193" filterUnits="userSpaceOnUse">
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <clipPath id="clip-Pin_59x59">
          <rect width="59" height="59" />
        </clipPath>
      </defs>
      <g id="Pin_59x59" clipPath="url(#clip-Pin_59x59)">
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_31503)">
          <path id="Path_31503-2" data-name="Path 31503" d="M22.944,1A21.466,21.466,0,0,0,7.437,7.583,22.61,22.61,0,0,0,1,23.383C1,29.674,4.8,36.55,13.581,45.62l9.363,9.363,9.217-9.363h0c4.681-4.681,7.9-8.631,9.948-12.435a19.624,19.624,0,0,0,2.78-9.8,22.61,22.61,0,0,0-6.437-15.8A21.466,21.466,0,0,0,22.944,1" transform="translate(6.56 1.51)" fill={props.color} stroke="#fff" strokeWidth="1" />
        </g>
      </g>
    </svg>

  );
}
