import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Divider, Grid, Typography, useMediaQuery } from '@mui/material';

import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { GroupSubscriptionVm } from '../GroupSubscriptionVm';
import { PriceDisplay } from './PriceDisplay';

interface IGroupOverviewPriceProps {
  groupSubscriptionVm: GroupSubscriptionVm;
}

export const GroupOverviewPrice = observer(function GroupOverviewPrice({ groupSubscriptionVm }: IGroupOverviewPriceProps) {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();
  const {
    isOwnerAlreadyPro,
    ownerPriceWithoutDiscount,
    ownerFinalPrice,
    currency,
    assignedMembers,
    assignedMembersPriceWithoutDiscount,
    assignedMembersDiscount,
    assignedMembersFinalPrice,
    groupMembersOverview,
    groupTotalPriceAfterDiscount,
    groupTotalPriceWithoutDiscount,
    endDate,
    createCheckoutSession
  } = groupSubscriptionVm;

  const payButton = useMemo(() => (
    <Grid item xs textAlign={'center'}>
      <Button
        fullWidth
        color="secondary"
        size={isMobileSize ? 'large' : 'small'}
        onClick={createCheckoutSession.run}
        disabled={groupMembersOverview.size === 1}
      >
        {t('group_subscription:price.overview.cta')}
      </Button>
      <Typography
        variant="text_sm_regular"
        color={theme.palette.text.tertiary}
        textAlign={'center'}
        px={2}
      >
        {t('group_subscription:price.overview.stripe')}
      </Typography>
    </Grid>
  ), [isMobileSize, createCheckoutSession.run, groupMembersOverview.size, t, theme.palette.text.tertiary]);

  return (
    <Grid item container gap={4} justifyContent={'space-between'} rowGap={2}>
      {/* PRICE OVERVIEW */}
      <Grid item xs={12} sm={6} container direction={'column'} rowGap={2}>
        <Grid item>
          <Typography variant={isMobileSize ? 'headingSubtitleMobile' : 'text_md_semibold'} color={theme.palette.text.secondary}>
            {t('group_subscription:price.overview.title')}
          </Typography>
        </Grid>
        <Grid item container rowGap={1}>
          <Grid item container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={isMobileSize ? 'text_md_semibold' : 'text_sm_semibold'} color={theme.palette.text.primary}>1x</Typography>&nbsp;
              <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>{t('group_subscription:table.table_row.role.owner')}</Typography>
            </Grid>
            <Grid item>
              <PriceDisplay
                isDiscounted={isOwnerAlreadyPro}
                originalPrice={ownerPriceWithoutDiscount}
                finalPrice={ownerFinalPrice}
                currency={currency}
              />
            </Grid>
          </Grid>
          {assignedMembers.size !== 0 && <Grid item container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant={isMobileSize ? 'text_md_semibold' : 'text_sm_semibold'} color={theme.palette.text.primary}>{assignedMembers.size}x</Typography>&nbsp;
              <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>{t('group_subscription:table.table_row.role.member')}</Typography>
            </Grid>
            <Grid item>
              <PriceDisplay
                isDiscounted={assignedMembersDiscount !== 0}
                originalPrice={assignedMembersPriceWithoutDiscount}
                finalPrice={assignedMembersFinalPrice}
                currency={currency}
              />
            </Grid>
          </Grid>}
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container gap={2} paddingBottom={2}>
          <Grid item container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant='text_md_semibold' color={theme.palette.text.primary}>{t('group_subscription:price.overview.total_label')}</Typography>
            </Grid>
            <Grid item>
              <Typography variant='text_md_semibold' color={theme.palette.text.primary}>{groupTotalPriceAfterDiscount.toFixed(2)}{currency}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.secondary}>
              <Trans
                i18nKey={'group_subscription:price.overview.renew_info'}
                values={{
                  amount: `${groupTotalPriceWithoutDiscount.toFixed(2)}${currency}`,
                  endDate: endDate,
                }} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='text_sm_regular' color={theme.palette.text.tertiary}>
              {t('group_subscription:price.overview.terms')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* PAY BUTTON */}
      {payButton}

    </Grid>
  );
});
