import React from 'react';
import { MapRef } from 'react-map-gl';
import { ViewModel } from '../../../../domain/core/ViewModel';
import { scoped } from '../../../../inversify/decorator';
import { IGeoLocation } from '../../../../shared/interfaces/IGeoLocation';
import { GeoUtil } from '../../../../util/GeoUtil';
import { IMapVm } from '../../MapVm.interface';

@scoped()
export class MapboxMapVm extends ViewModel implements IMapVm {

  public mapRef: React.RefObject<MapRef> = React.createRef();

  private mapReadyPromise: Promise<void>;

  private resolveMap: () => void;

  constructor() {
    super();

    this.mapReadyPromise = new Promise((resolve) => {
      this.resolveMap = resolve;
    });
  }

  public onMapReady = () => {
    this.resolveMap();
  }

  public flyTo = async (point: IGeoLocation, zoom: number | undefined = undefined) => {
    await this.mapReadyPromise;

    this.mapRef.current?.flyTo({
      center: {
        lat: point.latitude,
        lng: point.longitude,
      },
      zoom,
    });
  }

  public centerMesh = async (mesh: IGeoLocation[]) => {
    await this.mapReadyPromise;
    const bounds = GeoUtil.getMapboxBounds(mesh);
    this.mapRef.current?.fitBounds([bounds[0], bounds[1]], { padding: 120, maxDuration: 1000 });
  }
}
