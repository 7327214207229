import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Button } from '@mui/material';

import { AppRoutes } from '../../router/AppRoutesEnum';

export function ProCtaButton() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const showProPage = useCallback(() => {
    navigate(AppRoutes.Pricing);
  }, [navigate]);

  return (
    <Button onClick={showProPage} variant="contained" color="secondary">
      {t('payment:pro_cta')}
    </Button>
  );
}
