import { makeObservable, observable } from 'mobx';

import { UserLoginResponseDto } from '../../shared/dto';
import { SubscriptionReceiptResponseModel } from './SubscriptionReceiptResponseModel';
import { SubscriptionResponseModel } from './SubscriptionResponseModel';
import { UserModel } from './UserModel';
import { STRIPE_SUBSCRIPTION_STATUS } from '../../shared/enum/stripe/stripeSubscriptionStatus.enum';

export class UserLoginResponseModel {

  @observable
  public sessionToken: string = '';

  @observable
  public user: UserModel = new UserModel();

  @observable
  public subscriptions: SubscriptionResponseModel[] = [];

  // * groupSubscriptionOwner is set only for groupMember so he can see who is managing group. If user is a groupOwner, groupSubscriptionOwner will be undefined
  @observable
  public groupSubscriptionOwner: UserModel | undefined = undefined;

  @observable
  public receipts: SubscriptionReceiptResponseModel[] = [];

  @observable
  public hasRedeemedCode: boolean | null = null;

  @observable
  public isGroupOwner: boolean = false;

  @observable
  public stripeSubscriptionStatus: STRIPE_SUBSCRIPTION_STATUS | undefined = undefined;

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: UserLoginResponseDto) => {
    const userLogin = new UserLoginResponseModel();

    userLogin.sessionToken = dto.sessionToken;
    userLogin.user = UserModel.fromDto(dto.user);
    userLogin.subscriptions = dto.subscriptions.map((s) => SubscriptionResponseModel.fromDto(s));
    userLogin.receipts = dto.receipts.map((s) => SubscriptionReceiptResponseModel.fromDto(s));
    userLogin.hasRedeemedCode = dto.hasRedeemedCode;
    userLogin.groupSubscriptionOwner = dto.groupSubscriptionOwner ? UserModel.fromDto(dto.groupSubscriptionOwner) : undefined;
    userLogin.isGroupOwner = dto.isGroupOwner;
    userLogin.stripeSubscriptionStatus = dto.stripeSubscriptionStatus;

    return userLogin;
  }
}
