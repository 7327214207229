import { inject } from 'inversify';

import { singleton } from '../../inversify/decorator';
import { SubzonePostRequestDto } from '../../shared/dto/subzone/subzone.post.request.dto';
import { SubzonePutRequestDto } from '../../shared/dto/subzone/subzone.put.request.dto';
import { SubzoneResponseDto } from '../../shared/dto/subzone/subzone.response.dto';
import { SubzoneModel } from '../model/SubzoneModel';
import { AjaxService } from '../service/AjaxService';
import { Proxy } from './Proxy';

@singleton()
export class SubzoneProxy extends Proxy {

  constructor(@inject(AjaxService) private readonly ajax: AjaxService) {
    super();
  }

  public getSubzones = async (districtId: string) => {
    const result = await this.ajax.get<SubzoneResponseDto[]>(`district/v2/${districtId}/subzones`);
    if (result.ok) {
      return this.withData(result, result.data.map((path) => SubzoneModel.fromDto(path)));
    }

    return result;
  }

  public createSubzone = async (subzone: SubzonePostRequestDto) => {
    const result = await this.ajax.post<SubzoneResponseDto>('subzone', subzone);
    if (result.ok) {
      return this.withData(result, SubzoneModel.fromDto(result.data));
    }

    return result;
  }

  public updateSubzone = async (subzone: SubzonePutRequestDto) => {
    const result = await this.ajax.put<SubzoneResponseDto>('subzone', subzone);
    if (result.ok) {
      return this.withData(result, SubzoneModel.fromDto(result.data));
    }

    return result;
  }

  public deleteSubzone = async (id: string) => {
    return this.ajax.delete<SubzoneResponseDto>(`subzone/${id}`);
  }
}
