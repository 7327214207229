import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';
import { IsBoolean, IsEnum, IsNotEmpty, IsOptional, IsString, IsUUID } from 'class-validator';
import { CUSTOM_MARK_COLOR, CUSTOM_MARK_ICON } from '../enum';



export class CustomMarkPutRequestDto {

  @IsUUID()
  @ApiProperty()
  public id: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  @ApiProperty()
  public name?: string;

  @IsOptional()
  @IsEnum(CUSTOM_MARK_ICON)
  @ApiProperty()
  public foreground?: string;

  @IsOptional()
  @IsEnum(CUSTOM_MARK_COLOR)
  @ApiProperty()
  public background?: string;

  @IsOptional()
  @IsBoolean()
  @ApiPropertyOptional()
  public isBookable?: boolean;

  @IsOptional()
  @IsBoolean()
  @ApiPropertyOptional()
  public canHaveTasks?: boolean;

  @IsOptional()
  @IsBoolean()
  @ApiPropertyOptional()
  public hasShootingDirection?: boolean;

}
