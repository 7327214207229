import { ApiProperty } from '@nestjs/swagger';

import { UserResponseDto } from './user.response.dto';

export class BookingResponseDto {

  @ApiProperty()
  public id: string;

  @ApiProperty({ type: () => UserResponseDto })
  public owner: UserResponseDto;

  @ApiProperty({ type: () => UserResponseDto })
  public user: UserResponseDto;

  @ApiProperty()
  public ttlMinutes: number;

  @ApiProperty()
  public createdAt: string; // ISO string
}
