import { inject } from 'inversify';
import { computed } from 'mobx';
import { ViewModel } from '../../../../../domain/core/ViewModel';
import { I18nService } from '../../../../../domain/service/I18nService';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { transient } from '../../../../../inversify/decorator';
import { FEATURE } from '../../../../../shared/enum/feature.enum';
import { POI_TYPE } from '../../../../../shared/enum/poiType.enum';
import { PoiHelper } from '../../../../../util/PoiHelper';
import { PoiMarkerProps } from './PoiMarker';

@transient()
export class PoiMarkerVm extends ViewModel<PoiMarkerProps> {

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(PoiHelper) public readonly poiHelper: PoiHelper,
  ) {
    super();
  }

  @computed
  public get hasCustomMarksEnabled(): boolean {
    return this.session.hasFeatureEnabled(FEATURE.CUSTOM_MARKS);
  }

  @computed
  public get hasBookingsEnabled(): boolean {
    return this.session.hasFeatureEnabled(FEATURE.BOOKINGS);
  }

  @computed
  private get canSeeDetails() {
    return this.hasCustomMarksEnabled || !this.isCustomPoi;
  }

  @computed
  private get typeText() {
    return this.poiHelper.getTypeText(this.props.poi);
  }

  @computed
  public get hasShootingDirectionEnabled(): boolean {
    return this.session.hasFeatureEnabled(FEATURE.SHOOTING_DIRECTION);
  }

  @computed
  public get isCustomPoi(): boolean {
    return this.props.poi.type === POI_TYPE.CUSTOM;
  }

  @computed
  public get backgroundColor(): string | undefined {
    return this.poiHelper.getBackgroundColor(this.props.poi);
  }

  @computed
  public get headerDetail() {
    if (this.canSeeDetails) {
      return this.typeText;
    }
  }

  @computed
  public get headerTitle() {
    if (this.canSeeDetails) {
      return this.props.poi.name;
    }
    if (!this.canSeeDetails) {
      return this.i18n.t('poi:pro_poi_name');
    }
  }
}
