import { inject } from 'inversify';
import { computed, makeObservable } from 'mobx';
import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { EntryModel } from '../../../../../../domain/model/EntryModel';
import { I18nService } from '../../../../../../domain/service/I18nService';
import { transient } from '../../../../../../inversify/decorator';
import { EntryDiseaseProps } from './EntryDisease';


export interface EntryDiseaseForm {
  entry: EntryModel;
}

export interface IDiseaseListItem {
  key: string,
  value: string,
}

@transient()
export class EntryDiseaseVm extends ViewModel<EntryDiseaseProps> {

  constructor(
    @inject(I18nService) public readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get hasDisease(): boolean {
    return Boolean(this.props.entry.disease) || Boolean(this.diseaseName);
  }

  // Not yet sycned entries may not have updated disease object, so we can use diseaseKey which is always latest
  @computed
  public get diseaseName(): string {
    if (this.props.entry.diseaseKey) {
      return this.i18n.t(`diseases:${this.props.entry.diseaseKey}`);
    }

    if (this.props.entry.disease?.key) {
      return this.i18n.t(`diseases:${this.props.entry.disease.key}`);
    }

    return '';
  }
}
