import { IconButton, ThemeProvider, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import { useCallback, useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface ICopyToClipboardProps {
  tooltipText: string;
  textCopied: () => void;
}

const CopyClipboardTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.surface_80,
    minHeight: 25,
    maxWidth: 150,
    border: `1px solid ${theme.palette.borderDividers?.border}`,
  },
}));

export const CopyToClipboard = function CopyToClipboard(props: ICopyToClipboardProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const [checkmarkIcon, setCheckmarkIcon] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState(t('copy_clipboard:copy_to_clipboard'));

  const handleCopyToClipboard = useCallback(() => {
    props.textCopied();
    setCheckmarkIcon(true);
    navigator.clipboard.writeText(props.tooltipText);
    setTooltipMessage(`${t('copy_clipboard:copied')} ${props.tooltipText}`);

    setTimeout(() => setCheckmarkIcon(false), 1000);
  }, [props, t]);

  return (
    <ThemeProvider theme={theme}>
      <CopyClipboardTooltip
        title={<Typography paddingX={1} variant='text_sm_regular' color={theme.palette.text.primary}>{tooltipMessage}</Typography>}
        placement='top'
        onClose={() => {
          setTimeout(() => {
            setTooltipMessage(t('copy_clipboard:copy_to_clipboard'));
          }, 1000);
        }}
      >
        <IconButton onClick={handleCopyToClipboard}>
          {checkmarkIcon ? (
            <CheckCircleOutlineIcon />
          ) : (
            <ContentCopyIcon />
          )}
        </IconButton>
      </CopyClipboardTooltip>
    </ThemeProvider>
  );

};
