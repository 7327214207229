export enum FEATURE {
  TASKS = 'tasks',
  QUOTA_PLANNING = 'quota_planning',
  CUSTOM_MARKS = 'customMarks',
  BOOKINGS = 'bookings',
  HYBRID_MAP = 'hybridMap',
  COUNTRY_PARCELS = 'countryParcels',
  EXTENDED_DISTRICT_FILTER = 'extendedDistrictFilter',
  DISTANCE_RINGS = 'distanceRings',
  USER_COMPASS = 'userCompass',
  MULTIPLE_IMAGES_UPLOAD = 'multipleImagesUpload',
  MULTIPLE_IMAGES_VIEW = 'multipleImagesView',
  HARVEST_TAG_EXPORT = 'harvesTagExport',
  CHECKIN_TAG_EXPORT = 'checkinTagExport',
  WEATHER = 'weather',
  MAP_PATH = 'mapPath',
  SCENT_CLOUD = 'scentCloud',
  SUBZONES = 'subzones',
  SHOOTING_DIRECTION = 'shootingDirection',
  CHECKIN_POI = 'checkinPoi',
  HUNTING_SEASON = 'huntingSeason',
  EVENT = 'event',
  CHAT = 'chat',
  WATER_SOURCE_MAP = 'waterSourcesMap',
  UNLIMITED_CUSTOM_AREAS = 'unlimitedCustomAreas',
  DOCUMENTS = 'documents',
  OFFLINE_MAP = 'offlineMap',
  UNLIMITED_POIS = 'unlimitedPois',
  UNLIMITED_ENTRIES = 'unlimitedEntries'
}
