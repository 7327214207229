import { action, makeObservable, observable, runInAction } from 'mobx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncTaskFunc = (...args: any) => Promise<unknown>;

export class AsyncTask<Func extends AsyncTaskFunc> {

  @observable
  public isBusy = false;

  public run: Func;

  constructor(work: Func) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.run = action(async (...args: any) => {
      try {
        runInAction(() => {
          this.isBusy = true;
        });

        return await work(...args);
      } finally {
        runInAction(() => {
          this.isBusy = false;
        });
      }
    }) as Func;
    makeObservable(this);
  }
}
