import { makeObservable, observable } from 'mobx';
import { UserModel } from './UserModel';
import { CheckInPoiLogResponseDto } from '../../shared/dto/poi/checkinpoilog.response.dto';

export class PoiCheckinHistoryModel {

  @observable
  public id: string = '';

  @observable
  public user: UserModel = new UserModel();

  @observable
  public from: string = '';

  @observable
  public to: string | null = null;

  @observable
  public poiId?: string = undefined;

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: CheckInPoiLogResponseDto) => {
    const model = new PoiCheckinHistoryModel();

    model.id = dto.id;
    model.user = UserModel.fromDto(dto.user);
    model.from = dto.from;
    model.to = dto.to;
    model.poiId = dto.poiId;

    return model;
  }

}
