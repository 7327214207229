import { Type } from 'class-transformer';
import {
    IsEmail, IsEnum, IsISO8601, IsNotEmpty, IsOptional, IsString, ValidateNested
} from 'class-validator';

import { ApiProperty } from '@nestjs/swagger';

import { LANGUAGE, MEASUREMENT_SYSTEM } from '../enum';
import { COUNTRY_CODE } from '../enum/countryCode.enum';
import { ISortType, SortType } from './user.response.dto';

// tslint:disable:max-classes-per-file
export class TutorialDisplayedPutRequestDto {

  @IsString()
  @ApiProperty()
  public name: string;

  @IsISO8601()
  @ApiProperty()
  public ts: string;
}

export class UserProfilePutRequestDto {

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  @ApiProperty()
  public firstName?: string;

  @IsOptional()
  @IsString()
  @IsNotEmpty()
  @ApiProperty()
  public lastName?: string;

  @IsOptional()
  @IsEmail()
  @ApiProperty()
  public email?: string;

  @IsOptional()
  @IsEnum(LANGUAGE)
  @ApiProperty()
  public language?: LANGUAGE;

  @IsOptional()
  @IsEnum(COUNTRY_CODE)
  @ApiProperty()
  public country?: COUNTRY_CODE;

  @IsOptional()
  @ApiProperty({ type: () => SortType })
  public sorting?: ISortType;

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => TutorialDisplayedPutRequestDto)
  @ApiProperty({ type: TutorialDisplayedPutRequestDto, isArray: true })
  public tutorialDisplayed?: TutorialDisplayedPutRequestDto[];

  @IsOptional()
  @IsEnum(MEASUREMENT_SYSTEM)
  @ApiProperty()
  public distanceUnit?: MEASUREMENT_SYSTEM;
}
