import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';

import { Assets } from '../../../../../../../../assets';
import { useQuotaPlanContext } from '../../../../../../../../domain/context/QuotaPlanContext';
import { QuotaPlanModel } from '../../../../../../../../domain/model/QuotaPlanModel';
import { QuotaPlanningScreens } from '../ViewDistrictQuotaPlanningTabVm';
import { ViewDistrictQuotaPlan } from './view-district-quota-plan/ViewDistrictQuotaPlan';

interface IViewDistrictQuotaPlanListProps {
  quotaPlans: QuotaPlanModel[];
  districtId: string;
}

export const ViewDistrictQuotaPlanList = observer(function ViewQuotaPlanList(props: IViewDistrictQuotaPlanListProps) {

  const { setStep, setCurrentQuotaPlan } = useQuotaPlanContext();

  const handleNewQuotaPlan = useCallback(() => {
    setCurrentQuotaPlan(new QuotaPlanModel(props.districtId));
    return setStep(QuotaPlanningScreens.EDIT_QUOTA_PLAN);
  }, [props.districtId, setCurrentQuotaPlan, setStep]);

  const noData = useMemo(() => (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" paddingTop={4} rowGap={2}>
      <img src={Assets.search} width="100px" height="100px" />
      <Typography variant={'h6'} textAlign={'center'}>{t('district:quota_planning.no_quota_plans')}</Typography>
    </Box>
  ), []);

  const onDeleteQuotaPlan = useCallback((quotaPlan: QuotaPlanModel) => {
    const index = props.quotaPlans.findIndex((qp) => qp.id === quotaPlan.id);

    if (index === -1) {
      props.quotaPlans.unshift(quotaPlan);
    } else {
      props.quotaPlans.splice(index, 1);
    }
  }, [props]);

  return (
    <Grid container direction={'column'} height={'inherit'}>
      {props.quotaPlans.length === 0
        ? noData
        : <>
          <Grid item>
            <Box>
              <Typography variant="body2">{t('district:quota_planning.subtitle')}</Typography>
            </Box>
            <Divider />
            <Grid container paddingY={2} rowGap={2}>
              {props.quotaPlans.map((quotaPlan) => <ViewDistrictQuotaPlan
                key={`district-quota-plan-${quotaPlan.id}`}
                quotaPlan={quotaPlan}
                deleteQuotaPlan={onDeleteQuotaPlan}
              />)}
            </Grid>
          </Grid>
        </>
      }
      <Grid item flexGrow={1} />
      <Grid item>
        <Button
          startIcon={<AddIcon />}
          size='large'
          color='primary'
          variant='contained'
          fullWidth
          disabled={false}
          onClick={handleNewQuotaPlan}
        >
          {t('quota_plan:create_plan')}
        </Button>
      </Grid>
    </Grid>
  );
});
