import { useCallback, useEffect, useState } from 'react';
import { IGeoLocation } from '../../../../../shared/interfaces/IGeoLocation';
import { IMapClickEvent } from './useDrawDistrictMesh';

export function useMapClick(map: google.maps.Map | null, onClick: (location: IGeoLocation) => void) {
  const [listener, setListener] = useState<google.maps.MapsEventListener | null>(null);

  const onClickEvent = useCallback((e: IMapClickEvent) => {
    onClick({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });
  }, [onClick]);

  useEffect(() => {
    if (map) {
      setListener(google.maps.event.addListener(map, 'click', onClickEvent));
    }
  }, [map, setListener, onClickEvent]);

  useEffect(() => {
    return () => {
      if (listener) {
        google.maps.event.removeListener(listener);
      }
    };
  }, [listener]);
}
