import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { Avatar, Typography } from '@mui/material';

import { Assets } from '../../../../../assets';
import { IGoogleMapProps } from '../../../../../domain/core/IGoogleMapProps';
import { useVm } from '../../../../../domain/hooks/useVm';
import { PoiModel } from '../../../../../domain/model/PoiModel';
import { OptionalTooltip } from '../../../../../toolkit/components/OptionalTooltip';
import { usePinSizeContext } from '../../google-map/hooks/usePinSizeContext';
import { Marker } from '../../Marker';
import { PoiLabel } from '../../PoiLabel';
import { MarkerTopLeftIcon } from '../MarkerTopLeftIcon';
import { PoiTooltip } from '../PoiTooltip';
import { PoiMarkerVm } from './PoiMarkerVm';

export interface PoiMarkerProps extends IGoogleMapProps {
  poi: PoiModel;
  onClick?: (poi: PoiModel) => void;
  opacity: number;
  skiptransform?: boolean;
  hideTooltip?: boolean;
  width?: number;
  miniIconWidth?: number;
  symbolWidth?: number;
  shootingDirectionWidth?: number;
  icon?: string;
  hideShootingDirection?: boolean;
  showPoiLabels?: boolean;
}

export const PoiMarker = observer(function PoiMarker(props: PoiMarkerProps) {
  const vm = useVm(PoiMarkerVm, props);
  const { pinWidth } = usePinSizeContext();

  const generateInitials = (firstName: string, lastName: string): string => {
    const firstInitial = firstName.charAt(0).toLocaleUpperCase();
    const lastInitial = lastName.charAt(0).toLocaleUpperCase();
    return `${firstInitial}${lastInitial}`;
  };


  // if explicit icon is provided, use that one
  // if poi type is custom, and user does not have custom marks enabled
  // show him fallback icon
  const icon = props.icon ? props.icon : vm.isCustomPoi
    ? vm.hasCustomMarksEnabled
      ? props.poi.icon
      : Assets.poi.custom_pro
    : props.poi.icon;

  const color = vm.hasCustomMarksEnabled
    ? props.poi.customMark?.background
    : undefined;

  const onClick = useCallback(() => {
    if (!props.poi.id) {
      return;
    }

    props.onClick?.(props.poi);
  }, [props]);

  /* Override MuiTooltip styles -> https://github.com/mui-org/material-ui/issues/28679#issuecomment-949277606 */
  return <OptionalTooltip enabled={!props.hideTooltip} arrow placement='top'
    title={<PoiTooltip vm={vm} />}
    componentsProps={{
      arrow: {
        sx: {
          '::before': { backgroundColor: `${vm.backgroundColor}`, }
        },
      }
    }}>
    <Marker
      lat={props.lat}
      lng={props.lng}
      icon={icon}
      color={color ?? undefined}
      onClick={onClick}
      opacity={props.opacity}
      skiptransform={props.skiptransform}
      width={props.width ?? pinWidth}
      zIndex={2}
    >
      {props.showPoiLabels && <PoiLabel name={vm.poiHelper.truncateString(props.poi.name, 22)} />}

      {/* shooting direction */}
      {!!(!props.hideShootingDirection && vm.hasShootingDirectionEnabled && props.poi.shootingDirection != null && props.poi.shootingAngle) && <img style={{
        position: 'absolute',
        zIndex: 1,
        transform: `translate(0, ${props.shootingDirectionWidth ? '21%' : '17%'}) rotate(${props.poi.shootingDirection - (props.poi.shootingAngle / 2)}deg)`,
        opacity: props.opacity,
        width: props.shootingDirectionWidth ?? '140px',
      }} src={props.poi.shootingAngleImage} alt={'shooting direction'} />}
      {/* custom mark foreground */}
      {(props.poi.customMark && vm.hasCustomMarksEnabled) && <img style={{
        position: 'absolute',
        top: '18%',
        width: props.symbolWidth ? props.symbolWidth : pinWidth * 0.6,
        opacity: props.opacity,
        zIndex: 3,
      }}
        src={props.poi.customMark.foregroundIcon}
      />}
      {/* poi booking */}
      {(props.poi.booking && vm.hasBookingsEnabled) && <MarkerTopLeftIcon width={props.miniIconWidth} opacity={props.opacity}>
        <img
          style={{
            width: '100%',
            opacity: props.opacity,
            zIndex: 4,
          }}
          src={props.poi.booking.user.photo?.thumbnailUrl ?? undefined}
        />
      </MarkerTopLeftIcon>}
      {/* poi blocked */}
      {(props.poi.blocked && vm.hasBookingsEnabled) && <MarkerTopLeftIcon width={props.miniIconWidth} opacity={props.opacity}>
        <img
          style={{
            width: '100%',
            opacity: props.opacity,
            zIndex: 4,
          }}
          src={Assets.poi.blocked}
        />
      </MarkerTopLeftIcon>}
      {/* blocked for non pro */}
      {(!vm.hasBookingsEnabled && (props.poi.blocked || props.poi.booking?.user)) && <MarkerTopLeftIcon width={props.miniIconWidth} opacity={props.opacity}>
        <img
          style={{
            width: '100%',
            opacity: props.opacity,
            zIndex: 4,
          }}
          src={Assets.poi.blocked_non_pro}
        />
      </MarkerTopLeftIcon>}

      {/* one checked in users */}
      {(props.poi.usersCheckedIn.length === 1) && <MarkerTopLeftIcon width={props.miniIconWidth} opacity={props.opacity}>
        <Avatar sx={{ bgcolor: 'orange', zIndex: 4, width: '100%', height: '100%', opacity: props.opacity }}>
          <Typography variant='subtitle2'>{generateInitials(props.poi.usersCheckedIn[0].user.firstName, props.poi.usersCheckedIn[0].user.lastName)}</Typography>
        </Avatar>
      </MarkerTopLeftIcon>}

      {/* multiple checked in users */}
      {(props.poi.usersCheckedIn.length > 1) && <MarkerTopLeftIcon width={props.miniIconWidth} opacity={props.opacity}>
        <img
          style={{
            width: '100%',
            opacity: props.opacity,
            zIndex: 4,
          }}
          src={Assets.poi.multiple_checkin_icon}
        />
      </MarkerTopLeftIcon>}
    </Marker>
  </OptionalTooltip>;
});
