import { Type } from 'class-transformer';
import { IsUUID, ValidateNested } from 'class-validator';

import { ApiProperty } from '@nestjs/swagger';

// tslint:disable:max-classes-per-file
export class GroupFilterDto {

  @IsUUID()
  @ApiProperty()
  public id: string;
}

export class FilterDto {

  @ValidateNested({ each: true, context: { errorCode: 422 } })
  @Type(() => GroupFilterDto)
  @ApiProperty({type: [GroupFilterDto]})
  public groupIds?: GroupFilterDto[];
}
