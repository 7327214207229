import { inject } from 'inversify';

import { singleton } from '../../inversify/decorator';
import { TaskPostRequestDto, TaskPutRequestDto, TaskResponseDto } from '../../shared/dto';
import { TaskModel } from '../model/TaskModel';
import { AjaxService } from '../service/AjaxService';
import { Proxy } from './Proxy';

@singleton()
export class TaskProxy extends Proxy {

  constructor(@inject(AjaxService) private readonly ajax: AjaxService) {
    super();
  }

  public getDistrictTasks = async (districtId: string | undefined) => {
    const result = await this.ajax.get<TaskResponseDto[]>(`district/v2/${districtId ?? 'worldmap'}/tasks`);
    if (result.ok) {
      return this.withData(result, result.data.map(TaskModel.fromDto));
    }

    return result;
  };

  public createTask = async (task: TaskPostRequestDto) => {
    const result = await this.ajax.post<TaskResponseDto>('task', task);
    if (result.ok) {
      return this.withData(result, TaskModel.fromDto(result.data));
    }

    return result;
  };

  public updateTask = async (task: TaskPutRequestDto) => {
    const result = await this.ajax.put<TaskResponseDto>('task', task);
    if (result.ok) {
      return this.withData(result, TaskModel.fromDto(result.data));
    }

    return result;
  };

  public deleteTask = async (task: TaskModel) => {
    return this.ajax.delete<void>(`task/${task.id}`);
  }

}
