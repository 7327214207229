import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography } from '@mui/material';

import { MapPathModel } from '../../../../domain/model/MapPathModel';
import { colors } from '../../../../theme/options/palette/const/colors';

interface MapPathPointTooltipProps {
  mapPathPoint: MapPathModel;
}

export function MapPathPointTooltip({ mapPathPoint }: MapPathPointTooltipProps) {

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box>
        <Grid container direction="column" justifyContent="center" sx={{ borderRadius: 10 }}>
          <Grid item padding={1}
            sx={{
              background: mapPathPoint.color,
              color: colors.paper,
              borderRadius: '4px',
            }}
          >
            <Typography fontSize={10} fontWeight="regular">
              {t('map:path')}
            </Typography>
            <Typography fontSize={13} fontWeight="regular">
              {mapPathPoint.title}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
