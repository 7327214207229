import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';

interface IProps {
  onCancel?: () => void;
  onSave?: () => void;
  isSaving?: boolean;
}

export function PoiImportControls(props: IProps) {
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Stack direction={'row'} spacing={4}>
        {props.onCancel && <Button sx={{
          paddingX: 3,
          backgroundColor: 'white !important',
          borderWidth: '2px !important'
        }}
          variant="outlined"
          color="error"
          onClick={props.onCancel}
        >
          {t('district:district_setup_cancel')}
        </Button>}
        {props.onSave && <LoadingButton
          loading={props.isSaving}
          variant="outlined"
          color="secondary"
          sx={{
            paddingX: 3,
            backgroundColor: 'white !important',
            borderWidth: '2px !important',
          }}
          onClick={props.onSave}
        >
          {t('district:district_setup_save')}
        </LoadingButton>}
      </Stack>
    </Box>
  );
}
