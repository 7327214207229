import * as React from 'react';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, TableCell } from '@mui/material';
import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';


interface IActionCellProps {
  row: GroupSubscriptionUserModel;
  isGroupOwner: boolean;
  removeMember: (member: GroupSubscriptionUserModel) => void;
}

export const ActionCell: React.FC<IActionCellProps> = function ActionCell({ row, isGroupOwner, removeMember }: IActionCellProps) {
  return (
    <TableCell align='right'>
      <IconButton onClick={() => removeMember(row)} disabled={isGroupOwner}>
        <DeleteOutlineIcon fontSize='small' />
      </IconButton>
    </TableCell>
  );
};
