import { action, observable } from 'mobx';
import { IMementoCommand } from './MementoCommand';

export class MementoManager {

  @observable
  public commands: IMementoCommand[] = [];

  @action
  public do = (action: () => void, undo: () => void, id?: string) => {
    action();
    this.commands.push({
      id,
      undo
    });
  }

  @action
  public removeCommand = (id: string) => {
    this.commands = this.commands.filter((c) => c.id !== id);
  }

  @action
  public undo = (): boolean => {
    const lastCommand = this.commands.pop();
    if (lastCommand) {
      lastCommand.undo();
      return true;
    }

    return false;
  }

  @action
  public clear = () => {
    this.commands = [];
  }
}
