import { action, makeObservable, observable } from 'mobx';
import { WeightsEntryResponseDto } from '../../shared/dto/weights.entry.response.dto';
import { WEIGHT_UNIT } from '../../shared/enum/entryWeightUnit.enum';


export class WeightsEntryModel {

  @observable
  public unit: WEIGHT_UNIT;

  @observable
  public amount: number | null = null;

  constructor(dto?: WeightsEntryResponseDto) {
    if (dto !== undefined) {
      this.unit = dto.unit;
      this.amount = dto.amount;
    }

    makeObservable(this);
  }

  @action
  public setAmount = (amount: number | null) => {
    this.amount = amount;
  }

  public clone = (): WeightsEntryModel => {

    const newEntry = new WeightsEntryModel();
    newEntry.unit = this.unit;
    newEntry.amount = this.amount;

    return newEntry;
  }

  public static fromDto(dto: WeightsEntryResponseDto): WeightsEntryModel {
    return new WeightsEntryModel(dto);
  }
}
