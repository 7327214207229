import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { BaseSyntheticEvent } from 'react';
import { NavigateFunction } from 'react-router';

import { AsyncTask } from '../../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { ShareProxy } from '../../../../../../../domain/proxy/ShareProxy';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../../domain/service/NotificationService';
import { TrackingEvent } from '../../../../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../../../../domain/service/tracking/TrackingService';
import { transient } from '../../../../../../../inversify/decorator';
import { Types } from '../../../../../../../inversify/types';
import { AppRoutes } from '../../../../../../../router/AppRoutesEnum';

@transient()
export class JoinAreaModalVm extends ViewModel {

  @observable
  public invitationCode: string = '';

  @observable
  public open: boolean = false;

  constructor(
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(ShareProxy) private readonly shareProxy: ShareProxy,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get isRedeemEnabled(): boolean {
    return this.invitationCode.length === 6;
  }

  @action
  public setOpen = (open: boolean) => {
    this.open = open;
  }

  @action
  public setInvitationCode = (invitationCode: string): void => {
    this.invitationCode = invitationCode;
  }

  public handleClose = (e: BaseSyntheticEvent) => {
    this.setInvitationCode('');
    this.setOpen(false);
    e.stopPropagation();
  }

  public redeemCode = new AsyncTask(async () => {
    try {
      const response = await this.shareProxy.redeemCode(this.invitationCode);

      if (response.ok) {
        this.notification.success(this.i18n.t('district:join_area_modal.success'));
        await this.tracking.track(TrackingEvent.DISTRICT_JOINED);
        // eg: https://www.hunterco.de/m/map/district/17e4426c-f050-4000-824e-8ed1a93f9000 -> 17e4426c-f050-4000-824e-8ed1a93f9000
        const districtId = response.data.link.split('/').pop();
        const newRoute = AppRoutes.Map.concat(`/${districtId!}`);
        this.navigate(newRoute);
        return window.location.reload();
      }
      this.notification.error(this.i18n.t('district:join_area_modal.error'));

    } catch (e) {
      console.error(`error while trying to join district. ${e}`);
      this.notification.error(this.i18n.t('district:join_area_modal.error'));
    }
  });

}
