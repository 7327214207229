import React, { useCallback, useState } from 'react';
import { ControllerRenderProps, DeepMap, FieldError, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, SxProps, TextField, Theme } from '@mui/material';

type TFieldValues = FieldValues

interface PasswordProps {
  field?: ControllerRenderProps;
  errors?: DeepMap<TFieldValues, FieldError>;
  label?: string;
  placeholder?: string;
  sx?: SxProps<Theme>;

  /* Handle password visibility from outside component. */
  passwordShown?: boolean;
  toggleShowPassword?: (passwordShown: boolean) => void;
}

export const Password: React.FC<PasswordProps> = ({
  field,
  label,
  placeholder,
  sx,
  passwordShown,
  toggleShowPassword,
}: PasswordProps) => {

  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const togglePasswordVisibility = useCallback(() => {
    /* Handle password visibility from outside component. */
    if (toggleShowPassword && passwordShown !== undefined) {
      toggleShowPassword(!passwordShown);
      return;
    }
    /* Handle password visibility from this component. */
    setShowPassword(!showPassword);
  }, [passwordShown, showPassword, toggleShowPassword]);

  return (
    <TextField
      {...field}
      sx={sx}
      type={(toggleShowPassword && passwordShown !== undefined ? passwordShown : showPassword) ? 'text' : 'password'}
      label={placeholder
        ? ''
        : label ?? t('auth:register_password_form_password')}
      placeholder={placeholder ?? ''}
      variant="filled"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePasswordVisibility}
              edge="end"
              size="large">
              {(toggleShowPassword && passwordShown !== undefined ? passwordShown : showPassword) ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
