import { Property } from 'csstype';
import { singleton } from '../../inversify/decorator';
import { ENTRY_TYPE } from '../../shared/enum/entryType.enum';
import { palette } from '../../theme/options/palette/palette';
import { GrayIcons, IAssetContainer, PinsGreen, PinsGrey, PinsOrange, WhiteIcons } from './data/assets';
import { IClassificationHelper } from './IClassificationHelper';
import { IClassification, IClassificationAssets } from './types/classification.types';

const IMAGE_FALLBACK = 'FALLBACK';
const MISC_FALLBACK = 'MISC';

@singleton()
export class ClassificationHelper implements IClassificationHelper {

  public readonly classificationAssets: IClassificationAssets = {
    pinsGreen: PinsGreen,
    pinsOrange: PinsOrange,
    pinsGrey: PinsGrey,
    whiteIcons: WhiteIcons,
    grayIcons: GrayIcons,
  }

  public getClassificationPin = (classification: IClassification, entryType?: ENTRY_TYPE): string => {

    if (!entryType) {
      return this.classificationAssets.pinsGrey[MISC_FALLBACK];
    }

    const assetContainer = this.getPinsAssetContainer(entryType);

    if (!assetContainer) {
      return this.classificationAssets.pinsGrey[MISC_FALLBACK];
    }

    return this.getIcon(classification, assetContainer, entryType);
  }

  public getClassificationIcon = (classification: IClassification, gray: boolean): string => {
    const icons = gray
      ? this.classificationAssets.grayIcons
      : this.classificationAssets.whiteIcons;

    if (!classification) {
      return icons[IMAGE_FALLBACK];
    }

    if (!classification.group && !classification.species) {
      return icons[IMAGE_FALLBACK];
    }

    let icon;

    if (classification.group) {
      icon = icons[classification.group.toUpperCase()];
    } else if (classification.species) {
      icon = icons[classification.species.toUpperCase()];
    }

    if (!icon) {
      return icons[IMAGE_FALLBACK];
    }

    return icon;
  }

  public getClassificationIconForString = (name: string, gray: boolean): string | undefined => {
    return gray
      ? this.classificationAssets.grayIcons[name.toUpperCase()]
      : this.classificationAssets.whiteIcons[name.toUpperCase()];
  }

  public getIconForGroupOrSpecies = (group?: string, species?: string): string | undefined => {

    if (group && this.classificationAssets.whiteIcons[group.toUpperCase()]) {
      return this.classificationAssets.whiteIcons[group.toUpperCase()];
    }
    if (species && this.classificationAssets.whiteIcons[species.toUpperCase()]) {
      return this.classificationAssets.whiteIcons[species.toUpperCase()];
    }

    return this.classificationAssets.whiteIcons[IMAGE_FALLBACK];
  }

  public getEntryTypeIcon = (entryType: ENTRY_TYPE): string => {
    const icon = this.classificationAssets.whiteIcons[entryType!.toUpperCase()];
    if (!icon) {
      return this.classificationAssets.whiteIcons[IMAGE_FALLBACK];
    }

    return icon;
  }

  public getClassificationOrEntryTypeIcon = (classificationObject?: IClassification, entryType?: ENTRY_TYPE, gray: boolean = false): string => {
    if (classificationObject) {
      const classificationIcon = this.getClassificationIcon(classificationObject, gray);
      if (classificationIcon !== this.classificationAssets.whiteIcons[IMAGE_FALLBACK]) {
        return classificationIcon;
      }
    }

    return this.getEntryTypeIcon(entryType ?? ENTRY_TYPE.MISC);
  }

  public getEntryColor = (type?: ENTRY_TYPE): Property.Color => {
    switch (type) {
      case ENTRY_TYPE.KILLING:
        return '#EA771A';
      case ENTRY_TYPE.SIGHTING:
        return '#02654E';
      case ENTRY_TYPE.MISC:
        return '#535C68';
      default:
        return '#535C68';
    }
  }

  public getEntryHoverBackground = (type?: ENTRY_TYPE): Property.Color => {
    switch (type) {
      case ENTRY_TYPE.KILLING:
        return palette.background!.entry!.hover.killing;
      case ENTRY_TYPE.SIGHTING:
        return palette.background!.entry!.hover.sighting;
      case ENTRY_TYPE.MISC:
        return palette.background!.entry!.hover.misc;
      default:
        return palette.background!.entry!.hover.misc;
    }
  }

  public getEntryViewBackground = (type?: ENTRY_TYPE): Property.Color => {
    switch (type) {
      case ENTRY_TYPE.KILLING:
        return palette.background!.entry!.view.killing;
      case ENTRY_TYPE.SIGHTING:
        return palette.background!.entry!.view.sighting;
      case ENTRY_TYPE.MISC:
        return palette.background!.entry!.view.misc;
      default:
        return palette.background!.entry!.view.misc;
    }
  }


  private readonly getIcon = (classification: IClassification, assetContainer: IAssetContainer, entryType: ENTRY_TYPE): string => {

    if (!classification.group && !classification.species && !entryType) {
      return assetContainer[IMAGE_FALLBACK];
    }

    let icon = assetContainer[entryType!.toUpperCase()];

    if (entryType === ENTRY_TYPE.MISC) {
      icon = assetContainer[ENTRY_TYPE.MISC.toUpperCase()];
    } else if (classification.group) {
      icon = assetContainer[classification.group.toUpperCase()];
    } else if (classification.species) {
      icon = assetContainer[classification.species.toUpperCase()];
    }

    if (!icon) {
      return assetContainer[IMAGE_FALLBACK];
    }

    return icon;
  }

  private getPinsAssetContainer = (entryType: ENTRY_TYPE): IAssetContainer | undefined => {
    switch (entryType) {
      case ENTRY_TYPE.KILLING:
        return this.classificationAssets.pinsOrange;
      case ENTRY_TYPE.SIGHTING:
        return this.classificationAssets.pinsGreen;
      case ENTRY_TYPE.MISC:
        return this.classificationAssets.pinsGrey;
    }
  }

}
