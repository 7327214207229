import { format, parseISO, setHours, setMinutes } from 'date-fns';
import { action, computed, makeObservable, observable } from 'mobx';

import { EntryPostRequestDto, EntryPutRequestDto, EntryResponseDto } from '../../shared/dto';
import { ENTRY_TYPE, FALLWILD_TYPE } from '../../shared/enum';
import { WEIGHT_UNIT } from '../../shared/enum/entryWeightUnit.enum';
import { REGION } from '../../shared/enum/region.enum';
import { IClassification } from '../../util/classification/types/classification.types';
import { distanceUnitSuffix } from '../../util/MapUtils';
import { IGeoLocation } from '../core/IGeoLocation';
import { DiseaseModel } from './DiseaseModel';
import { PhotoModel } from './PhotoModel';
import { ShotModel } from './ShotModel';
import { UserModel } from './UserModel';
import { WeatherRealtimeModel } from './weather/WeatherRealTimeModel';
import { WeightsEntryModel } from './WeightsEntryModel';
import { DistrictModel } from './DistrictModel';

export type PositionSource = 'input' | 'map';

export class EntryModel {

  @observable
  public id: string = '';

  @observable
  public isPrivate: boolean = false;

  @observable
  public lat: number | null = null;

  @observable
  public long: number | null = null;

  @observable
  public positionSource: PositionSource | null = null;

  @observable
  public entryType: ENTRY_TYPE | undefined = undefined;

  @observable
  public owner: UserModel | null = null;

  @observable
  public classificationObject: IClassification = {};

  @observable
  public districtId?: string | undefined = undefined;

  @observable
  public eventId: string | undefined = undefined;

  @observable
  public createdAt: Date = new Date();

  @observable
  public userDate: Date | null = null;

  @observable
  public updatedAt?: Date = undefined;

  @observable
  public description?: string = '';

  @observable
  public count: number = 1;

  // Note: null value represents a harvest
  @observable
  public fallwildType: FALLWILD_TYPE | null = null;

  @observable
  public weights: WeightsEntryModel[] = [];

  @observable
  public disease?: DiseaseModel = undefined;

  @observable
  public diseaseKey?: string | null = null;

  @observable
  public specificAge?: number | null = null;

  @observable
  public shot?: ShotModel = undefined;

  @observable
  public weather?: WeatherRealtimeModel = undefined;

  @observable
  public photos: PhotoModel[] = [];

  @observable
  public region?: REGION = undefined;

  constructor() {
    makeObservable(this);
  }

  @computed
  public get location(): IGeoLocation | null {
    if (!this.lat || !this.long) {
      return null;
    }

    return {
      latitude: this.lat,
      longitude: this.long,
    };
  }

  @computed
  public get ownerName(): string {
    return this.owner?.fullName ?? '';
  }

  @computed
  public get time(): string {
    if (!this.userDate) return '';

    return format(this.userDate, 'HH:mm');
  }

  @computed
  public get dateTime(): string {
    if (!this.userDate) return '';

    return format(this.userDate, 'dd. MMMM yyyy HH:mm');
  }

  @computed
  public get formattedShotDistance(): string {
    if (!this.shot?.distance) {
      return '';
    }

    const amount = Number.isInteger(this.shot.distance.amount)
      ? this.shot.distance.amount
      : this.shot.distance.amount?.toFixed(2);

    return `${amount}${distanceUnitSuffix(this.shot.distance.unit)}`;
  }

  @action
  public setRegion = (region: REGION) => {
    this.region = region;
  }

  @action
  public setDescription = (description: string) => {
    this.description = description;
  }

  @action
  public setIsPrivate = (isPrivate: boolean) => {
    this.isPrivate = isPrivate;
  }

  @action
  public setUserDate = (date: Date | null): void => {
    const newDate = this.userDate ? new Date(this.userDate.valueOf()) : date;

    if (date && newDate) {
      newDate.setFullYear(date.getFullYear());
      newDate.setMonth(date.getMonth());
      newDate.setDate(date.getDate());

      this.userDate = newDate;
    }
  }

  @action
  public setUserTime = (time: string): void => {
    if (!this.userDate) {
      return;
    }

    const [hour, minute] = time.split(':');

    this.userDate = setHours(this.userDate, parseInt(hour));
    this.userDate = setMinutes(this.userDate, parseInt(minute));
  }

  @action
  public setLatitude = (value: number) => {
    this.lat = value;
    this.positionSource = 'input';
  }

  @action
  public setLongitude = (value: number) => {
    this.long = value;
    this.positionSource = 'input';
  }

  @action
  public setPosition = (position: IGeoLocation, source: PositionSource) => {
    this.lat = position.latitude;
    this.long = position.longitude;
    this.positionSource = source;
  }

  @action
  public setSpecificAge = (age: number | null): void => {
    this.specificAge = age;
  }

  @action
  public setEntryFallwildType = (fallwildType: FALLWILD_TYPE): void => {
    this.fallwildType = fallwildType;
  }

  @action
  public setEntryType = (type: ENTRY_TYPE) => {
    this.entryType = type;
  }

  @action
  public setClassification = (classification: IClassification) => {
    this.classificationObject = classification;
  }

  @action
  public setDiseaseKey = (key: string | null): void => {
    this.diseaseKey = key;
  }

  @action
  public setDistrictId = (id: string | undefined): void => {
    this.districtId = id;
  }

  @action
  public changeCount = (count: number): void => {
    if (count < 1) {
      this.count = 1;
    } else {
      this.count = count;
    }
  }

  @action
  public addPhoto = (photo: PhotoModel) => {
    this.photos.push(photo);
  }

  public clone = () => {
    const cloned = new EntryModel();

    cloned.id = this.id;
    cloned.isPrivate = this.isPrivate;
    cloned.lat = this.lat;
    cloned.long = this.long;
    cloned.entryType = this.entryType;
    cloned.owner = this.owner?.clone() ?? null;
    cloned.classificationObject = { ...this.classificationObject };
    cloned.districtId = this.districtId;
    cloned.eventId = this.eventId;
    cloned.createdAt = new Date(this.createdAt.getTime());
    cloned.userDate = this.userDate ? new Date(this.userDate.getTime()) : null;
    cloned.description = this.description;
    cloned.count = this.count;
    cloned.fallwildType = this.fallwildType;
    cloned.weights = this.weights.map((w) => w.clone());
    cloned.disease = this.disease?.clone();
    cloned.diseaseKey = this.diseaseKey;
    cloned.specificAge = this.specificAge;
    cloned.shot = this.shot?.clone() ?? undefined;
    cloned.weather = this.weather;
    cloned.photos = this.photos.map((p) => p.clone());
    cloned.region = this.region;

    return cloned;
  }

  public static fromDto = (dto: EntryResponseDto): EntryModel => {
    const entry = new EntryModel();

    entry.id = dto.id;
    entry.isPrivate = dto.isPrivate;
    entry.long = dto.lat; // switched because switched on server
    entry.lat = dto.long; // switched because switched on server
    entry.fallwildType = dto.fallwildType ? dto.fallwildType as FALLWILD_TYPE : null;
    entry.entryType = dto.entryType;
    entry.districtId = dto.districtId ? dto.districtId : DistrictModel.worldMapId;
    entry.classificationObject = dto.classificationObject ?? {};
    entry.userDate = new Date(dto.userDate);
    entry.createdAt = parseISO(dto.createdAt);
    entry.updatedAt = parseISO(dto.updateAt ?? '');
    entry.description = dto.description;
    entry.owner = UserModel.fromDto(dto.owner);
    entry.photos = dto.photos.map((photo) => PhotoModel.fromDto(photo, dto.photo));
    entry.count = dto.count || 1;
    entry.disease = dto.disease ? DiseaseModel.fromDto(dto.disease) : undefined;
    entry.diseaseKey = dto.disease?.key;
    entry.shot = dto.shot ? ShotModel.fromDto(dto.shot) : undefined;
    entry.weights = dto.weights && Array.isArray(dto.weights) ? dto.weights.map((w) => WeightsEntryModel.fromDto(w)) : [];
    entry.eventId = dto.eventId;
    entry.specificAge = dto.specificAge || undefined;
    entry.weather = dto.weather ? WeatherRealtimeModel.fromDto(dto.weather) : undefined;
    entry.region = dto.region;

    return entry;
  }

  public toPutDto = (unit?: WEIGHT_UNIT): EntryPutRequestDto => {
    const dto = new EntryPutRequestDto();

    dto.id = this.id;
    dto.districtId = this.districtId !== DistrictModel.worldMapId ? this.districtId : undefined;
    dto.isPrivate = this.isPrivate;
    dto.lat = this.long ?? undefined; // reversed because of legacy reasons in v1
    dto.long = this.lat ?? undefined; // reversed because of legacy reasons in v1
    dto.eventId = this.eventId;
    dto.fallwildType = this.fallwildType;
    dto.count = this.count;
    dto.userDate = this.userDate ? this.userDate.toISOString() : undefined;
    dto.description = this.description;
    dto.classificationObject = this.classificationObject;
    dto.entryType = this.entryType ?? undefined;
    dto.specificAge = this.specificAge;
    dto.weightAmount = this.weights.find((w) => w.unit === unit)?.amount;
    dto.weightUnit = this.weights.find((w) => w.unit === unit)?.unit;
    dto.shot = this.shot?.toPutDto();
    dto.disease = this.diseaseKey;
    dto.region = this.region;

    return dto;
  }

  public toPostDto = (unit?: WEIGHT_UNIT): EntryPostRequestDto => {
    const dto = new EntryPostRequestDto();

    dto.districtId = this.districtId !== DistrictModel.worldMapId ? this.districtId : undefined;
    dto.isPrivate = this.isPrivate;
    dto.lat = this.long ?? undefined; // reversed because of legacy reasons in v1
    dto.long = this.lat ?? undefined; // reversed because of legacy reasons in v1
    dto.eventId = this.eventId;
    dto.fallwildType = this.fallwildType;
    dto.count = this.count;
    dto.userDate = this.userDate ? this.userDate.toISOString() : undefined;
    dto.description = this.description;
    dto.classificationObject = this.classificationObject;
    dto.entryType = this.entryType ?? undefined;
    dto.specificAge = this.specificAge;
    dto.weightAmount = this.weights.find((w) => w.unit === unit)?.amount;
    dto.weightUnit = this.weights.find((w) => w.unit === unit)?.unit;
    dto.shot = this.shot?.toPutDto();
    dto.disease = this.diseaseKey;
    dto.region = this.region;

    return dto;
  }
}
