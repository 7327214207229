import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';

import { useThemeContext } from '../../../theme/ThemeContextProvider';

interface TableLayoutProps {
  children: React.ReactNode;
  count: number;
  rowsPerPage: number;
  page: number;
  onPageChange: (_event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TableLayout: React.FC<TableLayoutProps> = ({ children, count, rowsPerPage, page, onPageChange, onRowsPerPageChange }) => {
  const { theme } = useThemeContext();

  return (
    <Box sx={{ width: '100%', maxWidth: { xs: '100vw' } }}>
      <Paper sx={{ width: '100%', mb: 2, borderRadius: '10px', border: `1px solid ${theme.palette.borderDividers?.border}`, }}>
        <TableContainer
          sx={{
            maxHeight: { xs: 400, sm: 500, md: 700 },
            overflowY: 'auto',
          }}
        >
          <Table size={'medium'}
            sx={{ minWidth: 540 }}
          >
            {children}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </Paper>
    </Box>
  );
};
