import { Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Assets } from '../../../../../../../assets';
import { useVm } from '../../../../../../../domain/hooks/useVm';
import { colors } from '../../../../../../../theme/options/palette/const/colors';
import { ProCtaButton } from '../../../../../../../toolkit/components/ProCtaButton';
import { DistrictPathListItem } from './DistrictPathListItem';
import { IViewDistrictPathsProps, ViewDistrictPathsVm } from './ViewDistrictPathsVm';

export const ViewDistrictPaths = observer(function ViewDistrictPaths(props: IViewDistrictPathsProps) {
  const { t } = useTranslation();
  const vm = useVm(ViewDistrictPathsVm, props);

  return (
    <Box width="100%">
      {vm.proPathsEnabled && <Box sx={{ maxWidth: '100%', boxShadow: 1, }}>
        <Tabs
          value={vm.activeTypeId}
          indicatorColor="secondary"
          onChange={vm.changeTab}
          variant={vm.tabs.length > 2 ? 'scrollable' : 'fullWidth'}
          scrollButtons
          sx={{
            '.MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0.3,
            }
          }}
        >
          {vm.tabs.map((t) => <Tab
            key={`path-type-${t.typeId}`}
            value={t.typeId}
            label={`${t.text} (${t.count})`}
          />)}
        </Tabs>
      </Box>}
      <Box paddingX={4}>
        {vm.proPathsEnabled && vm.paths.map((path, index) => <DistrictPathListItem
          key={`district-path-${path.id}`}
          path={path}
          lastItem={index >= vm.paths.length - 1}
          typeText={vm.getTypeText(path.typeId)}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
        />)}
        {!vm.proPathsEnabled && <Box display="flex" flexDirection={'column'} alignItems="center">
          <img src={Assets.path.proTeaser} style={{ width: '100%', marginTop: '20px' }} />
          <Typography color={colors.primary} mb={5} variant="h6" textAlign={'center'}>{t('district:paths_cta_text')}</Typography>
          <ProCtaButton />
        </Box>}
      </Box>
    </Box>
  );
});
