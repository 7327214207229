import React, { BaseSyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Divider, Grid, MobileStepper } from '@mui/material';

import { useThemeContext } from '../../theme/ThemeContextProvider';

export interface IStepperSteps {
  icon: string;
  title: string;
  description_1: string;
  description_2?: string;
  bulletPoints?: string[];
}

interface IAppStepperProps extends React.PropsWithChildren<unknown> {
  closeModal: (e: BaseSyntheticEvent) => void;
  steps: IStepperSteps[];
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

export const AppStepper = function AppStepper({ steps, activeStep, children, setActiveStep, closeModal }: IAppStepperProps) {
  const { theme } = useThemeContext();
  const { t } = useTranslation();

  const handleNext = useCallback((e: BaseSyntheticEvent) => {
    if (activeStep === (steps.length - 1)) {
      closeModal(e);
      return setActiveStep(0);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [activeStep, closeModal, setActiveStep, steps.length]);

  const handleBack = useCallback((e: BaseSyntheticEvent) => {
    if (activeStep === 0) {
      return closeModal(e);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, [activeStep, closeModal, setActiveStep]);


  return (
    <Grid container direction={'column'} width={'inherit'} padding={'0px 8px 16px'}>

      {/* STEPPER BODY */}
      <Grid item container direction={'column'} alignItems={'center'} justifyContent={'flex-start'} padding={'0px 16px 16px 16px'} rowGap={2}>
        {children}
      </Grid>

      {/* EMPTY SPACE */}
      <Grid item flexGrow={1} />

      {/* STEPPER WITH BUTTONS */}
      <Grid item container direction={'column'}>
        {/* DOTS STEPPER */}
        <Box display={'flex'} justifyContent={'center'} flexDirection={'row'}>
          <MobileStepper
            variant="dots"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              paddingY: 0,
            }}
            nextButton={null}
            backButton={null}
          />
        </Box>
        {/* DIVIDER */}
        <Box marginY={theme.spacing(2)}>
          <Divider />
        </Box>
        {/* BUTTONS */}
        <Box display={'flex'} justifyContent={'space-between'} gap={2}>
          <Button onClick={handleBack} fullWidth color='secondary'>
            {activeStep === 0 ? t('common:button.skip') : t('common:button.back')}
          </Button>
          <Button onClick={handleNext} fullWidth>
            {activeStep === (steps.length - 1) ? t('common:button.finish') : t('common:button.next')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
