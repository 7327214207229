import { Drawer } from '@mui/material';
import React, { ReactNode, useImperativeHandle, useState } from 'react';

export type DrawerOpenRef = {
  open: () => void,
  close: () => void,
}

interface IProps {
  anchor: 'right' | 'left';
  children?: ReactNode;
}

export const SideBar = React.forwardRef<DrawerOpenRef, IProps>(function SideBar(props: IProps, ref) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    open() {
      setIsOpen(true);
    },
    close() {
      setIsOpen(false);
    }
  }));

  return (
    <Drawer
      open={isOpen}
      variant="persistent"
      anchor={props.anchor}
      PaperProps={{
        sx: {
          width: { xs: '100%', sm: '40%', md: '30%', lg: '25%', xl: '22%' }
        }
      }}
    >
      {props.children}
    </Drawer>
  );
});
