import { ApiProperty } from '@nestjs/swagger';

export class PoiFeatureResponseDto {

  @ApiProperty()
  public isBookable: boolean;

  @ApiProperty()
  public canHaveTasks: boolean;

  @ApiProperty()
  public hasShootingDirection: boolean;
}
