import { inject } from 'inversify';

import { singleton } from '../../inversify/decorator';
import {
  EntityMemberResponseDto, RedeemInvitationPostRequestDto, RedeemInvitationResponseDto,
  UserRoleDeleteRequestDto, UserRolePutRequestDto
} from '../../shared/dto';
import { InvitationCodePostRequestDto } from '../../shared/dto/invitationCode.post.request.dto';
import { InvitationCodeResponseDto } from '../../shared/dto/invitationCode.response.dto';
import { ENTITY_TYPE } from '../../shared/enum';
import { AsyncTask } from '../async/AsyncTask';
import { InvitationCodeModel } from '../model/InvitationCodeModel';
import { MemberModel } from '../model/MemberModel';
import { AjaxService } from '../service/AjaxService';
import { Proxy } from './Proxy';

@singleton()
export class ShareProxy extends Proxy {

  constructor(
    @inject(AjaxService) private readonly ajax: AjaxService,
  ) {
    super();
  }

  public getMembers = new AsyncTask(async (entityId: string, entityType: ENTITY_TYPE) => {
    const response = await this.ajax.get<EntityMemberResponseDto[]>(`access/${entityId}/${entityType}`);
    if (response.ok) {
      return this.withData(response, response.data.map(MemberModel.fromEntityMember));
    }

    return response;
  });

  public getInvitationCodes = async (entityId: string) => {
    const response = await this.ajax.get<InvitationCodeResponseDto[]>(`invitation-code/entity/${entityId}`);

    if (response.ok) {
      return this.withData(response, response.data.map(d => InvitationCodeModel.fromDto(d)));
    }

    return response;
  }

  /** Use this only for generating Guest code for existing districts, as existing districts initially don't have one. */
  public generateCode = async (body: InvitationCodePostRequestDto) => {
    const response = await this.ajax.post<InvitationCodeResponseDto>('invitation-code/generate', body);

    if (response.ok) {
      return this.withData(response, InvitationCodeModel.fromDto(response.data));
    }

    return response;
  }

  /** Use this only for re-generating Guest code. */
  public regenerateCode = async (body: InvitationCodePostRequestDto) => {
    const response = await this.ajax.post<InvitationCodeResponseDto>('invitation-code/regenerate', body);

    if (response.ok) {
      return this.withData(response, InvitationCodeModel.fromDto(response.data));
    }

    return response;
  }

  public redeemCode = async (code: string) => {
    const requestDto: RedeemInvitationPostRequestDto = { code };
    const response = await this.ajax.post<RedeemInvitationResponseDto>('invitation-code/redeem', requestDto);

    if (response.ok) {
      return this.withData(response, response.data);
    }

    return response;
  }

  public removeMember = async (data: UserRoleDeleteRequestDto) => {
    return this.ajax.delete('/access', data);
  }

  public changeRole = async (data: UserRolePutRequestDto) => {
    return this.ajax.put('/access', data);
  }
}
