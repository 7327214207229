import { computed, makeObservable, observable } from 'mobx';
import { AmmunitionResponseDto } from '../../shared/dto/ammunition.response.dto';
import { AmmunitionCaliberModel } from './AmmunitionCaliberModel';
import { GearManufacturerModel } from './GearManufacturerModel';


export class AmmunitionModel {

  @observable
  public id: string = '';

  @observable
  public name: string = '';

  @observable
  public caliber: AmmunitionCaliberModel | null = null;

  @observable
  public manufacturer: GearManufacturerModel | null = null;

  constructor() {
    makeObservable(this);
  }

  @computed
  public get manufacturerAndCaliber() {
    const elements = [this.manufacturer?.name, this.caliber?.name];
    return elements.filter(value => value).join(', ');
  }

  public static fromDto(dto: AmmunitionResponseDto) {
    const model = new AmmunitionModel();
    model.id = dto.id;
    model.name = dto.name || '';
    model.manufacturer = dto.manufacturer ? GearManufacturerModel.fromDto(dto.manufacturer) : null;
    model.caliber = dto.caliber ? AmmunitionCaliberModel.fromDto(dto.caliber) : null;
    return model;
  }
}
