import { formatISO } from 'date-fns';
import { action, computed, makeObservable, observable } from 'mobx';
import { ShotPutRequestDto } from '../../shared/dto/shot/shot.put.request.dto';
import { ShotResponseDto } from '../../shared/dto/shot/shot.response.dto';
import { IGeoLocation } from '../core/IGeoLocation';
import { AmmunitionModel } from './AmmunitionModel';
import { GearSetModel } from './GearsetModel';
import { PoiModel } from './PoiModel';
import { ShotDistanceModel } from './ShotDistanceModel';
import { UserModel } from './UserModel';

export class ShotModel {

  @observable
  public id: string = '';

  @observable
  public count: number = 1;

  @observable
  public lat: number | undefined = undefined;

  @observable
  public long: number | undefined = undefined;

  @observable
  public owner: UserModel | undefined = undefined;

  @observable
  public districtId: string | undefined | null = null;

  @observable
  public eventId: string | undefined | null = null;

  @observable
  public shotDate?: Date = undefined;

  @observable
  public distance?: ShotDistanceModel = undefined;

  @observable
  public poiId?: string = undefined;

  @observable
  public poi?: PoiModel = undefined;

  @observable
  public gearSetId?: string = undefined;

  @observable
  public gearSet?: GearSetModel = undefined;

  @observable
  public ammunitionId?: string = undefined;

  constructor() {
    makeObservable(this);
  }

  @computed
  public get location(): IGeoLocation | null {
    if (this.lat == null || this.long == null) {
      return null;
    }

    return {
      latitude: this.lat,
      longitude: this.long,
    };
  }

  @computed
  public get ammunition(): AmmunitionModel | undefined {
    if (!this.ammunitionId) {
      return;
    }

    if (!this.gearSet) {
      return;
    }

    return this.gearSet.ammunition.find((a: AmmunitionModel) => a.id === this.ammunitionId);
  }

  @action
  public setGearsetId(id: string | undefined) {
    this.gearSetId = id;
  }

  @action
  public setAmmunitionId(id: string | undefined) {
    this.ammunitionId = id;
  }

  public clone = () => {
    const cloned = new ShotModel();

    cloned.id = this.id;
    cloned.count = this.count;
    cloned.districtId = this.districtId;
    cloned.eventId = this.eventId;
    cloned.gearSet = this.gearSet?.clone();
    cloned.gearSetId = this.gearSetId;
    cloned.ammunitionId = this.ammunitionId;
    cloned.shotDate = this.shotDate;
    cloned.lat = this.lat;
    cloned.long = this.long;
    cloned.poiId = this.poiId;
    cloned.id = this.id;

    return cloned;
  }

  public toPutDto = (): ShotPutRequestDto => {
    const dto: ShotPutRequestDto = {
      id: this.id,
      count: this.count,
      districtId: this.districtId ?? undefined,
      eventId: this.eventId ?? undefined,
      gearSetId: this.gearSetId,
      ammunitionId: this.ammunitionId ?? undefined,
      shotDate: this.shotDate ? formatISO(this.shotDate) : undefined,
      lat: this.lat,
      long: this.long,
      poiId: this.poiId
    };

    return dto;
  }

  public static fromDto = (dto: ShotResponseDto) => {
    const model = new ShotModel();

    model.id = dto.id;
    model.count = dto.count;
    model.lat = dto.lat;
    model.long = dto.long;
    model.owner = dto.owner ? UserModel.fromDto(dto.owner) : undefined;
    model.distance = dto.distance ? ShotDistanceModel.fromDto(dto.distance) : undefined;
    model.gearSetId = dto.gearSetId;
    model.ammunitionId = dto.ammunitionId;
    model.gearSet = dto.gearSet != null ? GearSetModel.fromDto(dto.gearSet) : undefined;
    model.poiId = dto.poiId;
    if (dto.poi) {
      model.poi = PoiModel.fromDto(dto.poi);
    }

    return model;
  }

}
