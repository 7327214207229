import { Box } from '@mui/system';
import React from 'react';

interface IProps {
  color: string;
  opacity?: number;
  width?: number;
  zIndex?: number;
}

export function EmptyMarker(props: IProps) {
  return (
    <Box width={props.width ? `${props.width}px` : '40px'} zIndex={props.zIndex}>
      <svg opacity={props.opacity} id="poi_custom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 50">
        <g id="poi_custom-2" data-name="poi_custom" transform="translate(1.249 1.28)">
          <path id="pin_master_background" data-name="pin master background" d="M18.749,0A18.162,18.162,0,0,0,5.5,5.74,19.924,19.924,0,0,0,0,19.515C0,25,3.25,31,10.749,38.9l8,8.167L26.627,38.9c4-4.083,6.75-7.525,8.5-10.841A17.381,17.381,0,0,0,37.5,19.516,19.925,19.925,0,0,0,32,5.74,18.164,18.164,0,0,0,18.749,0" transform="translate(0 0)" fill={props.color} />
        </g>
        <g id="icon_custom">
          <path id="outline" d="M20,0H19.97A20.278,20.278,0,0,0,.075,18.92C.029,19.5,0,20.078,0,20.667c0,.465.029.934.071,1.405.01.107.021.215.033.323.051.463.118.929.211,1.4v.017a15.618,15.618,0,0,0,.4,1.583l.027.088c.159.528.344,1.064.559,1.607C2.883,31.1,6,35.551,11.125,40.944L20,50l8.748-9.052C36.872,32.657,40,26.535,40,20.667v-.029A20.328,20.328,0,0,0,20,0M36.374,29.33c-1.75,3.316-4.5,6.758-8.5,10.842L20,48.337l-8-8.165a64.094,64.094,0,0,1-6.464-7.83A39.859,39.859,0,0,1,3.47,28.913a17.61,17.61,0,0,1-2.221-8.13,19.924,19.924,0,0,1,5.5-13.776,18.162,18.162,0,0,1,25.673-.825q.426.4.825.825a19.933,19.933,0,0,1,5.5,13.777,17.383,17.383,0,0,1-2.375,8.546" fill="#fff" />
        </g>
      </svg>
    </Box>
  );
}
