import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { Box, Grid, TextField, Typography } from '@mui/material';

import { AppRoutes } from '../../../../router/AppRoutesEnum';
import { UserPostLoginRequestDto } from '../../../../shared/dto';
import { oldDesignTheme } from '../../../../theme/oldDesignTheme';
import { colors } from '../../../../theme/options/palette/const/colors';
import { Field } from '../../../../toolkit/components/form/Field';
import { Form } from '../../../../toolkit/components/form/Form';
import { FormSubmitButton } from '../../../../toolkit/components/form/FormSubmitButton';
import { Password } from '../../../../toolkit/components/Password';

interface IProps {
  isBusy: boolean;
  onLogin: SubmitHandler<UserPostLoginRequestDto>;
  prefilledEmail: string;
}

export function LoginForm(props: IProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <Form onSubmit={props.onLogin} defaultValues={{ email: props.prefilledEmail }} key={`predefined-form-email-${props.prefilledEmail}`}>
        <Grid container spacing={2} justifyContent="center">
          <Grid
            item
            container
            spacing={{ xs: 2, sm: 4 }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Field name="email"
                fullWidth
                schema={
                  yup
                    .string()
                    .email(t('auth:validations.valid_email'))
                    .required(t('auth:validations.login.required_email'))
                }>
                <TextField
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: colors.paper,
                    },
                    '& .MuiInputBase-input': {
                      color: oldDesignTheme.palette.text.secondary,
                      fontSize: '18px',
                      fontWeight: 'bold',
                    },
                  }}
                  type="email"
                  label={t('auth:register_email_form_email')}
                  variant="filled"
                  fullWidth
                />
              </Field>
            </Grid>

            {/* PASSWORD */}
            <Grid item xs={12}>
              <Field name="password"
                fullWidth
                schema={
                  yup
                    .string()
                    .min(8, t('auth:validations_invalid_password'))
                    .required(t('auth:validations.login.required_password'))
                }>
                <Password
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: colors.paper,
                    },
                    '& .MuiInputBase-input': {
                      color: oldDesignTheme.palette.text.secondary,
                      fontSize: '18px',
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Field>
            </Grid>
          </Grid>

          {/* FORGOT PASSWORD */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <Link to={AppRoutes.ForgotPassword} style={{ color: oldDesignTheme.palette.text.secondary }}>
                {t('auth:login_forgotpassword')}
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Box marginTop={{ xs: 1, sm: 4 }} display="flex" justifyContent="center">
              <FormSubmitButton
                submitting={props.isBusy}
                sx={{
                  minWidth: '204px',
                  minHeight: '36px',
                }}
              >
                {t('auth:login_title')}
              </FormSubmitButton>
            </Box>
          </Grid>

          <Grid item mt={'10px'}>
            <Typography fontSize={15} fontWeight={'normal'} color="textPrimary">
              {t('auth:intro_cta_register')}{'  '}
              <Link to={AppRoutes.Register} style={{
                color: oldDesignTheme.palette.text.tertiary,
                textDecoration: 'none',
                fontSize: '15px',
                fontWeight: 'bold',
              }}>
                {t('auth:body_register')}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
}
