import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, colors, Grid, List, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Assets } from '../../../../../assets';
import { useInstance } from '../../../../../domain/hooks/useInstance';
import { I18nService } from '../../../../../domain/service/I18nService';
import { CustomDatePicker } from '../../../../../toolkit/components/CustomDatePicker';
import { appLocaleList } from '../../../../../toolkit/constants/LanguageList';
import { ClassificationHelper } from '../../../../../util/classification/ClassificationHelper';
import { DateFilter, DateFilterCondition } from '../../../filters/DateFilter';
import { L1ClassificationFilter } from '../../../filters/L1ClassificationFilter';
import { PoiFilter } from '../../../filters/PoiFilter';
import { FilterPinType } from './components/FilterPinType';
import { FixedTimeFilter } from './components/FixedTimeFilter';

interface IProps {
  dateFilter: DateFilter;
  l1ClassificationFilter: L1ClassificationFilter;
  poiFilter: PoiFilter;
  totalEntities: number;
}

export const FilterSidebarGeneral = observer(function FilterSidebarGeneral(props: IProps) {
  const { t } = useTranslation();
  const i18n = useInstance(I18nService);
  const classificationHelper = useInstance(ClassificationHelper);

  const toggleCondition = useCallback((condition: DateFilterCondition) => {
    const newState = !condition.active;
    props.dateFilter.deactivate();
    props.dateFilter.setConditionTo(condition, newState);
  }, [props.dateFilter]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={appLocaleList[i18n.language]}>
      <Box mx={3} my={3}>
        <Box>
          <Typography variant="subtitle2">{t('filter:time_period')}</Typography>
          <List>
            {props.dateFilter.predefinedConditions.map((condition) => <FixedTimeFilter
              key={`date-filter-${condition.id}`}
              condition={condition}
              onClick={() => {
                toggleCondition(condition);
              }}
              totalEntities={props.totalEntities}
            />)}
          </List>
          {props.dateFilter.customCondition != null && <Box display="flex" alignItems={'center'} mt={3}>
            <Grid container dir="row" spacing={1}>
              <CustomDatePicker
                label={t('filter:date_from')}
                selectedDate={props.dateFilter.customCondition.from}
                onChange={props.dateFilter.onChangeFromDate}
              />
              <CustomDatePicker
                label={t('filter:date_to')}
                selectedDate={props.dateFilter.customCondition.to ?? null}
                onChange={props.dateFilter.onChangeToDate}
              />
            </Grid>
            <Box ml={1} mt={2}>
              <Checkbox
                onClick={() => toggleCondition(props.dateFilter.customCondition!)}
                checked={props.dateFilter.customCondition.active}
              />
            </Box>
          </Box>}
          <Box mt={3}>
            <Typography variant='subtitle2'>{t('filter:pin_type')}</Typography>
            <Box mt={1} display="grid" gridTemplateColumns={'repeat(4, 1fr)'} columnGap={1}>
              {props.l1ClassificationFilter.conditions.map((condition) => <React.Fragment key={`condition-${condition.id}`}>
                <FilterPinType
                  title={t(`filter:filter_${condition.type}`)}
                  color={condition.active ? classificationHelper.getEntryColor(condition.type) : colors.blueGrey[200]}
                  active={condition.active}
                  icon={classificationHelper.getEntryTypeIcon(condition.type)}
                  onClick={() => props.l1ClassificationFilter.toggle(condition)}
                />
              </React.Fragment>)}
              <FilterPinType
                title={t('filter:filter_pois')}
                icon={Assets.poi.icons.high_seat_white}
                color={props.poiFilter.allHidden ? colors.blueGrey[200] : '#4C89DE'}
                active={!props.poiFilter.allHidden}
                onClick={() => props.poiFilter.toggleHideAll()}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </LocalizationProvider>
  );
});
