import {
  EventDistrictRoleType
} from '../../../modules/map/components/district/view-district/components/district-members/ViewDistrictMembersVm';
import { IClassification } from '../../../util/classification/types/classification.types';

export enum RegistrationVia {
  GOOGLE = 'Google',
  FACEBOOK = 'Facebook',
  APPLE = 'Apple',
  EMAIL = 'E-Mail',
}

type RegistrationType = 'Google' | 'Apple' | 'Facebook' | 'E-Mail';

interface QuotaSpecieAmount {
  amount: number;
  specie: IClassification
}

export interface IEventParams {
  registrationType?: RegistrationType,
  dimension?: QuotaSpecieAmount,
  role?: EventDistrictRoleType;
}
