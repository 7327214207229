import { useTranslation } from 'react-i18next';

import { Button, ButtonProps, Typography } from '@mui/material';

import { Assets } from '../../../assets';
import { colors } from '../../../theme/options/palette/const/colors';
import { FilterActive } from './filter-sidebar/components/FilterActive';

interface IProps extends ButtonProps {
  onClick: () => void;
  hasActiveFilters: boolean;
}

export function FilterButton(props: IProps) {
  const { t } = useTranslation();

  return (
    <Button
      onClick={props.onClick}
      startIcon={<img src={props.hasActiveFilters ? Assets.filter.icon_white : Assets.filter.icon} alt="filter" />}
      sx={{
        backgroundColor: props.hasActiveFilters ? colors.green[800] : 'white',
        borderWidth: props.hasActiveFilters ? 2 : undefined,
        borderColor: props.hasActiveFilters ? colors.grapple : undefined,
        borderStyle: props.hasActiveFilters ? 'solid' : undefined,
        paddingX: 4,
        paddingY: 1,
        '&:hover': {
          backgroundColor: !props.hasActiveFilters ? 'white' : undefined,
        },
      }}
      variant={props.hasActiveFilters ? 'contained' : 'outlined'}
      color={props.hasActiveFilters ? 'secondary' : 'primary'}
      disabled={props.disabled}
    >
      <Typography color={props.hasActiveFilters ? 'white' : (props.disabled ? colors.blueGrey : 'initial')} fontWeight={'500'}>
        {t('filter:menu_filter_button')}
      </Typography>
      {props.hasActiveFilters && <FilterActive sx={{ top: '-2px', right: '-6px' }} />}
    </Button>
  );
}
