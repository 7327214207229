import { ApiProperty } from '@nestjs/swagger';
import { IsOptional, IsString } from 'class-validator';


export class PaymentVerifyPostRequestDto {

  @IsString()
  @ApiProperty()
  public transactionReceipt: string;

  @IsString()
  @ApiProperty({ description: 'ios or android' })
  public platform: string; // ios or android

  @IsOptional()
  @IsString()
  @ApiProperty()
  public signature?: string;
}
