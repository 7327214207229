import { action, makeObservable, observable } from 'mobx';
import { transient } from '../../../inversify/decorator';

@transient()
export class MapFilterVm {

  @observable
  public filterShown = false;

  constructor() {
    makeObservable(this);
  }

  @action
  public openFilter = () => {
    this.filterShown = true;
  }

  @action
  public closeFilter = () => {
    this.filterShown = false;
  }
}
