import {
  UserSubscriptionReceiptResponseDto
} from '../../shared/dto/user/user.subscription.receipt.response.dto';
import { SubscriptionReceiptResponseModel } from './SubscriptionReceiptResponseModel';

export class UserSubscriptionReceiptResponseModel {

  public receipts: SubscriptionReceiptResponseModel[] = [];

  public static fromDto = (dto: UserSubscriptionReceiptResponseDto) => {
    const model = new UserSubscriptionReceiptResponseModel();

    model.receipts = dto.receipts.map(receipt => SubscriptionReceiptResponseModel.fromDto(receipt));

    return model;
  }

}
