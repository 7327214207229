import * as React from 'react';

import { TableRow } from '@mui/material';

import { useContainer } from '../../../../domain/hooks/useContainer';
import { GroupSubscriptionUserModel } from '../../../../domain/model/GroupSubscriptionUserModel';
import { SessionStore } from '../../../../domain/store/SessionStore';
import { MenuCell } from '../../group-subscription/overview/cells/menu-cell/MenuCell';
import { RoleCell } from '../../group-subscription/overview/cells/RoleCell';
import { SeatCell } from '../../group-subscription/overview/cells/SeatCell';
import { ManageGroupVm } from '../ManageGroupVm';

interface IManageGroupOverviewTableRowProps {
  vm: ManageGroupVm;
  row: GroupSubscriptionUserModel;
  index: number;
}

export const ManageGroupOverviewTableRow: React.FC<IManageGroupOverviewTableRowProps> = function ManageGroupOverviewTableRow({
  vm,
  row,
  index,
}: IManageGroupOverviewTableRowProps) {

  const { userId } = useContainer().get(SessionStore);
  const isGroupOwner = React.useMemo(() => userId === row.id, [row.id, userId]);

  return (
    <TableRow tabIndex={-1} key={row.id}>
      <SeatCell index={index} row={row} isGroupOwner={isGroupOwner} />
      <RoleCell isGroupOwner={isGroupOwner} />
      <MenuCell index={index} row={row} vm={vm} />
    </TableRow>
  );
};
