import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useVm } from '../../../../../../domain/hooks/useVm';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { PoiMarker } from '../../marker/PoiMarker';
import { ViewPoiInfoVm } from './ViewPoiInfoVm';

export interface IViewPoiInfoProps {
  poi: PoiModel;
  info: string | undefined;
  onPoiEdit: (poi: PoiModel) => void;
  canEditAndDeletePoi: boolean;
}

export function ViewPoiInfo(props: IViewPoiInfoProps) {
  const { t } = useTranslation();
  const vm = useVm(ViewPoiInfoVm, props);

  const edit = useCallback(() => {
    props.onPoiEdit(props.poi);
  }, [props]);

  const renderEditButton = useMemo(() => (
    <Button
      onClick={edit}
      sx={{ mx: 'auto', paddingX: 8 }}
      color={'secondary'}
      variant="contained"
    >{t('poi:edit_poi_button')}</Button>
  ), [edit, t]);

  const renderForbiddenEditMessage = useMemo(() => (
    <Typography variant="h6" textAlign={'center'} paddingBottom={4}>{t('poi:forbidden_edit')}</Typography>
  ), [t]);

  const renderContent = vm.canEdit ? renderEditButton : renderForbiddenEditMessage;

  return (
    <Box width="100%">
      <Box paddingY={2} paddingX={4}>
        <Typography color={props.info ? 'initial' : 'text.details'}>
          {props.info || t('poi:missing_description')}
        </Typography>
      </Box>
      <Divider />

      <Box display="flex" flexDirection={'column'} justifyContent={'center'} alignItems={'center'} paddingX={4}>
        <Box paddingY={6}>
          <PoiMarker
            poi={props.poi}
            lat={0}
            lng={0}
            opacity={1}
            skiptransform={true}
            hideTooltip
            width={70}
            miniIconWidth={40}
            symbolWidth={34}
            hideShootingDirection
          />
        </Box>
        <React.Fragment>{renderContent}</React.Fragment>
      </Box>
    </Box>
  );
}
