import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SubzoneModel } from '../../../../../../../domain/model/SubzoneModel';
import { Marker } from '../../../../Marker';
import { DistrictViewListItem } from '../DistrictViewListItem';

interface IProps {
  subzone: SubzoneModel;
  lastItem: boolean;
  onEdit: (subzone: SubzoneModel) => void;
  onDelete: (subzone: SubzoneModel) => void;
}

export function DistrictZoneListItem(props: IProps) {
  const { t } = useTranslation();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const showMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const onDelete = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
    setAlertOpen(false);
    props.onDelete(props.subzone);
  }, [props]);

  const edit = useCallback((event: React.MouseEvent) => {
    closeMenu(event);
    props.onEdit(props.subzone);
  }, [closeMenu, props]);

  const closeAlert = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const confirmDeletion = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
    setAlertOpen(true);
  }, [setAlertOpen]);

  const cancelDeletion = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setAlertOpen(false);
  }, []);

  return (
    <DistrictViewListItem
      component={<Box width="50px">
        <Marker
          lat={0}
          lng={0}
          icon={props.subzone.icon}
          opacity={1}
          skiptransform={true}
        />
      </Box>}
      secondLine={props.subzone.title}
      thirdLine={t(`subzone:type_${props.subzone.subzoneType}`)}
      withDivider={!props.lastItem}
      rightComponent={<>
        <Button
          onClick={showMenu}
          color="secondary"
        >
          {t('district:options_dropdown')}
        </Button>
        <Menu
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={closeMenu}
        >
          <MenuItem onClick={edit}>{t('district:options_dropdown_edit')}</MenuItem>
          <MenuItem onClick={confirmDeletion}>{t('district:options_dropdown_delete')}</MenuItem>
        </Menu>
        <Dialog
          open={alertOpen}
          onClose={closeAlert}
        >
          <DialogTitle>
            {t('subzone:delete_subzone_alert_title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('subzone:delete_subzone_alert_message')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDeletion}>{t('district:delete_district_cancel')}</Button>
            <Button onClick={onDelete} color="error">
              {t('district:delete_district_delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </>}
    />
  );
}
