import { add, parseISO } from 'date-fns';
import { computed, makeObservable, observable } from 'mobx';
import { BookingResponseDto } from '../../shared/dto';
import { UserModel } from './UserModel';

export class BookingModel {

  @observable
  public id: string;

  @observable
  public owner: UserModel;

  @observable
  public user: UserModel;

  @observable
  public ttlMinutes: number;

  public createdAt: Date;

  constructor() {
    makeObservable(this);
  }

  @computed
  public get bookedUntil() {
    return add(this.createdAt, {
      minutes: this.ttlMinutes,
    });
  }

  public static fromDto = (dto: BookingResponseDto): BookingModel => {
    const model = new BookingModel();

    model.id = dto.id;
    model.owner = UserModel.fromDto(dto.owner);
    model.user = UserModel.fromDto(dto.user);
    model.ttlMinutes = dto.ttlMinutes;
    model.createdAt = parseISO(dto.createdAt);

    return model;
  }

  public clone = () => {
    const cloned = new BookingModel();

    cloned.id = this.id;
    cloned.owner = this.owner.clone();
    cloned.user = this.user.clone();
    cloned.ttlMinutes = this.ttlMinutes;
    cloned.createdAt = new Date(this.createdAt.getTime());

    return cloned;
  }
}
