import axios, { AxiosError } from 'axios';

import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { PurchasesOfferings } from '../../typings/revenue-cat';

@singleton()
export class RevenueCatProxy {

  public getOfferings = async () => {
    try {
      const result = await axios.get<PurchasesOfferings>('https://api.revenuecat.com/v1/subscribers/app_user_id/offerings', {
        headers: {
          Authorization: `Bearer ${env.revenueCat.apiKey}`,
          'X-Platform': 'stripe',
        }
      });

      return {
        ok: result.status === 200,
        data: result.data,
        status: result.status,
      };
    } catch (e) {
      const axiosError = e as AxiosError;

      if (axiosError.response?.status) {
        return {
          ok: false,
          status: axiosError.response.status,
        };
      }

      return {
        ok: false,
        status: 0,
      };
    }
  }
}
