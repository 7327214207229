export enum CUSTOM_MARK_COLOR {
  '#DA444C' = '#DA444C',
  '#DA4D3F' = '#DA4D3F',
  '#E8673B' = '#E8673B',
  '#F69D3B' = '#F69D3B',
  '#F6BD38' = '#F6BD38',
  '#AFC34A' = '#AFC34A',
  '#7FC44A' = '#7FC44A',
  '#32B958' = '#32B958',
  '#35BEB0' = '#35BEB0',
  '#38B0DA' = '#38B0DA',
  '#4C89DE' = '#4C89DE',
  '#7373D7' = '#7373D7',
  '#B573DB' = '#B573DB',
  '#DA6EAE' = '#DA6EAE',
  '#E0E4E7' = '#E0E4E7',
  '#A5AEB8' = '#A5AEB8',
}
