import { action, computed, makeObservable, observable } from 'mobx';

import { RightsManager } from '../../shared/checks';
import {
  DistrictPostRequestDto, DistrictPutRequestDto, DistrictResponseDto
} from '../../shared/dto';
import { DISTRICT_ROLE, RIGHT } from '../../shared/enum';
import { transformFirstLetterToLowerCase } from '../../util/stringHelper';
import { MemberModel } from './MemberModel';
import { MeshModel } from './MeshModel';
import { PoiModel } from './PoiModel';
import { SubzoneModel } from './SubzoneModel';

export class DistrictModel {

  public static worldMapId = 'worldmap';

  @observable
  public id: string;

  @observable
  public name: string = '';

  @observable
  public mesh: MeshModel = new MeshModel([]);

  @observable
  public self: MemberModel | undefined = undefined;

  @observable
  public members: MemberModel[] = [];

  @observable
  public subzones: SubzoneModel[] = [];

  constructor() {
    makeObservable(this);
    this.mesh = new MeshModel([]);
  }

  @action
  public setName = (name: string) => {
    this.name = name;
  }

  @computed
  public get isGeneric() {
    return !this.id;
  }

  @computed
  public get isUnsaved() {
    return !this.id && !this.isWorldMap;
  }

  private canPerformAction(actionType: string): boolean {
    if (this.isGeneric) {
      return true;
    }

    const member = this.self;
    if (!member || !member.role || !member.role.rights) {
      return false;
    }

    return RightsManager[transformFirstLetterToLowerCase(actionType)](member.role.rights);
  }

  @computed
  private get canEditPois(): boolean {
    return this.canPerformAction(RIGHT.CAN_UPDATE_POI);
  }

  @computed
  private get canDeletePois(): boolean {
    return this.canPerformAction(RIGHT.CAN_DELETE_POIS);
  }

  @computed
  public get canEditAndDeletePois(): boolean {
    return this.canEditPois && this.canDeletePois;
  }

  @computed
  private get canEditEntries(): boolean {
    return this.canPerformAction(RIGHT.CAN_UPDATE_ENTRIES);
  }

  @computed
  private get canDeleteEntries(): boolean {
    return this.canPerformAction(RIGHT.CAN_DELETE_ENTRIES);
  }

  @computed
  public get canEditAndDeleteEntriesRoles(): boolean {
    return this.canEditEntries && this.canDeleteEntries;
  }

  @computed
  private get canEditSubzone(): boolean {
    return this.canPerformAction(RIGHT.CAN_UPDATE_SUBZONE);
  }

  @computed
  private get canDeleteSubzone(): boolean {
    return this.canPerformAction(RIGHT.CAN_DELETE_SUBZONE);
  }

  @computed
  public get canEditAndDeleteSubzone(): boolean {
    return this.canEditSubzone && this.canDeleteSubzone;
  }

  @computed
  private get canEditMapPath(): boolean {
    return this.canPerformAction(RIGHT.CAN_UPDATE_MAP_PATHS);
  }

  @computed
  private get canDeleteMapPath(): boolean {
    return this.canPerformAction(RIGHT.CAN_DELETE_MAP_PATHS);
  }

  @computed
  public get canEditAndDeleteMapPath(): boolean {
    return this.canEditMapPath && this.canDeleteMapPath;
  }

  @computed
  public get canModify() {
    return this.self?.role?.name !== DISTRICT_ROLE.USER;
  }

  @computed
  public get canBeSaved() {
    if (this.mesh.points.length <= 2) {
      return false;
    }

    if (!this.name) {
      return false;
    }

    return true;
  }

  @computed
  public get setupActive() {
    return !this.isWorldMap && !this.mesh.isMeshClosed;
  }

  @computed
  public get isWorldMap() {
    return this.id === DistrictModel.worldMapId;
  }

  @computed
  public get realId() {
    if (this.isWorldMap) {
      return DistrictModel.worldMapId;
    }

    return this.id;
  }

  @computed
  public get roleI18nKey(): string {
    if (this.self?.role) {
      return this.self?.role?.roleNameI18n;
    }

    return 'district:no_role';
  }

  @computed
  public get memberNames(): string {
    return this.members
      .map((member) => member.user)
      .filter((user) => !!user?.lastName)
      .map((user) => user?.lastName)
      .join(', ');
  }

  @action
  public clone = () => {
    const cloned = new DistrictModel();

    cloned.id = this.id;
    cloned.name = this.name;
    cloned.mesh = this.mesh.clone();
    cloned.self = this.self?.clone();
    cloned.members = this.members.map((member) => member.clone());
    cloned.subzones = this.subzones.map((subzone) => subzone.clone());
    return cloned;
  }

  public static privateMapDistrict = (nameTranslation: string): DistrictModel => {
    const worldMap = new DistrictModel();
    worldMap.id = DistrictModel.worldMapId;
    worldMap.name = nameTranslation;
    return worldMap;
  }

  public static fromDto = (dto: DistrictResponseDto): DistrictModel => {
    const district = new DistrictModel();

    district.id = dto.id;
    district.name = dto.name;
    district.mesh = new MeshModel(dto.geoMesh);
    district.self = MemberModel.fromDto(dto.self);
    district.members = dto.members.map((x) => MemberModel.fromDto(x));
    district.subzones = dto.subzones ? dto.subzones.map(SubzoneModel.fromDto) : [];

    return district;
  }

  public toPostDto = (pois: PoiModel[]): DistrictPostRequestDto => {
    const dto: DistrictPostRequestDto = {
      id: this.id,
      name: this.name,
      geoMesh: this.mesh.points,
      mesh: undefined,
      pois: pois.map((p) => p.toPostDto()),
    };

    return dto;
  }

  public toPutDto = (): DistrictPutRequestDto => {
    const dto: DistrictPutRequestDto = {
      id: this.id,
      name: this.name,
      geoMesh: this.mesh.points,
      mesh: undefined,
    };

    return dto;
  }
}
