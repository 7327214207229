import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { AsyncTask } from '../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../domain/core/ViewModel';
import { UserModel } from '../../../../domain/model/UserModel';
import { UserProxy } from '../../../../domain/proxy/UserProxy';
import { I18nService } from '../../../../domain/service/I18nService';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { SessionStore } from '../../../../domain/store/SessionStore';
import { transient } from '../../../../inversify/decorator';
import { LANGUAGE, MEASUREMENT_SYSTEM } from '../../../../shared/enum';
import { COUNTRY_CODE } from '../../../../shared/enum/countryCode.enum';
import { countryList } from '../../../../toolkit/constants/CountryList';

export interface CountryListItem {
  code: COUNTRY_CODE;
  label: string;
}

@transient()
export class UserProfileFormPartVm extends ViewModel {

  @observable
  private initialFormValues: {
    firstName: string;
    lastName: string;
    email: string;
    language: LANGUAGE;
    distanceUnit: MEASUREMENT_SYSTEM;
    country?: CountryListItem | null;
  } = {
      firstName: '',
      lastName: '',
      email: '',
      language: LANGUAGE.en,
      distanceUnit: MEASUREMENT_SYSTEM.METRIC,
      country: null,
    };

  @observable
  public firstName: string = '';

  @observable
  public lastName: string = '';

  @observable
  public email: string = '';

  @observable
  public language: LANGUAGE = LANGUAGE.en;

  @observable
  public distanceUnit: MEASUREMENT_SYSTEM = MEASUREMENT_SYSTEM.METRIC;

  @observable
  public country?: CountryListItem | null = null;

  @observable
  public sortedCountriesList: CountryListItem[] = [];

  @observable
  public isLanguageChanged: boolean = false;

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(UserProxy) private readonly userProxy: UserProxy,
    @inject(SessionStore) private readonly sessionStore: SessionStore,
    @inject(NotificationService) private readonly notification: NotificationService,
  ) {
    super();
    makeObservable(this);
  }

  public override async onInit() {
    this.loadUser(undefined);
  }

  @computed
  public get isBusy() {
    return this.userProxy.updateUser.isBusy;
  }

  @computed
  public get isFormDirty(): boolean {
    return (
      this.firstName !== this.initialFormValues.firstName ||
      this.lastName !== this.initialFormValues.lastName ||
      this.email !== this.initialFormValues.email ||
      this.language !== this.initialFormValues.language ||
      this.distanceUnit !== this.initialFormValues.distanceUnit ||
      this.country?.code !== this.initialFormValues.country?.code
    );
  }

  @action
  public setFirstname = (firstName: string) => {
    this.firstName = firstName;
  }

  @action
  public setLastname = (lastName: string) => {
    this.lastName = lastName;
  }

  @action
  public setEmail = (email: string) => {
    this.email = email;
  }

  @action
  public setLanguage = (language: LANGUAGE) => {
    this.language = language;
    this.updateCountryList();
  }

  @action
  public setDistanceUnit = (distanceUnit: MEASUREMENT_SYSTEM) => {
    this.distanceUnit = distanceUnit;
  }

  @action
  public setCountry = (country: CountryListItem | null) => {
    this.country = country;
  }

  @action
  public updateCountryList(): void {
    this.sortedCountriesList = countryList().map(country => {
      return {
        code: country.code,
        label: this.i18n.t(country.label)
      };
    }).sort((a, b) => a.label.localeCompare(b.label));

    // It's neccessary to this because Autocomplete will recognize change in options object, but value in Textfield (vm.country) stays the same unless we manually change it
    this.setCountry(this.sortedCountriesList.find(country => country.code === this.country?.code) ?? null);
  }

  @action
  public setIsLanguageChanged = (isLanguageChanged: boolean) => {
    this.isLanguageChanged = isLanguageChanged;
  }

  @action
  private setInitialFormValues(userData: UserModel): void {
    this.initialFormValues = {
      firstName: userData.firstName,
      lastName: userData.lastName,
      email: userData.email,
      language: userData.language,
      distanceUnit: userData.distanceUnit,
      country: this.sortedCountriesList.find((country) => country.code === userData.country) ?? null,
    };
  }

  private loadUser = (user: UserModel | undefined): void => {
    if (!this.sessionStore.session) return;

    const userData = user ?? this.sessionStore.session.user;
    this.setFirstname(userData.firstName);
    this.setLastname(userData.lastName);
    this.setEmail(userData.email);
    this.setLanguage(userData.language);
    this.setDistanceUnit(userData.distanceUnit);
    this.setCountry(this.sortedCountriesList.find(country => country.code === userData.country) ?? null);

    this.setInitialFormValues(userData);
  }

  public updateUser = new AsyncTask(async (): Promise<void> => {
    try {
      const requestBody = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        language: this.language,
        distanceUnit: this.distanceUnit,
        country: this.country?.code,
      };

      if (!this.country) {
        delete requestBody.country;
      }

      const result = await this.userProxy.updateUser.run(requestBody);
      if (result.ok) {
        runInAction(() => {
          this.sessionStore.setUser(result.data);
          this.loadUser(result.data);
          this.setIsLanguageChanged(result.data.language !== this.i18n.language);
          this.updateCountryList();
        });
        return this.notification.success(this.i18n.t('profile:update_success'));
      }

      this.notification.error(this.i18n.t('profile:errors.update_error'));
    } catch (e) {
      console.error(`error while updating user. ${e}`);
      this.notification.error(this.i18n.t('profile:errors.update_error'));
    }
  })
}
