import { FirebaseOptions } from 'firebase/app';

import { BreadcrumbType } from '@bugsnag/js';

import packageJson from '../package.json';

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID!,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY || '',
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG || '',
  version: process.env.REACT_APP_LOCIZE_VERSION || '',
};

// Determine if running on localhost
const isLocalhost = () => {
  return typeof window !== 'undefined' && window.location.hostname === 'localhost';
};

export const env = {
  isDev: process.env.NODE_ENV === 'development', // * true for 'localhost', 'canary', 'staging'
  isLocalhost: isLocalhost(),
  isProduction: process.env.REACT_APP_ENV === 'production',
  api: process.env.REACT_APP_API_URL as string,
  ws: process.env.REACT_APP_WS_URL as string,
  version: packageJson.version,
  sessionTokenKey: 'x-hunter-session-token',
  locizeBackend: locizeOptions,
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID!,
  firebase: {
    apiKey: process.env.REACT_APP_API_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  } as FirebaseOptions,
  mixpanel: {
    projectToken: process.env.REACT_APP_ENV === 'production'
      ? process.env.REACT_APP_MIXPANEL_PRODUCTION_TOKEN
      : process.env.REACT_APP_MIXPANEL_TOKEN
  },
  googleTagManager: {
    containerID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID!
  },
  revenueCat: {
    apiKey: process.env.REACT_APP_REVENUE_CAT_KEY!,
    offerings: process.env.REACT_APP_ENV === 'production' ? 'default' : 'default_sandbox',
  },
  bugsnag: {
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY!,
    /**
     * Two useful config options:
     * https://docs.bugsnag.com/platforms/javascript/react/configuration-options/#enabledreleasestages
     * https://docs.bugsnag.com/platforms/javascript/react/configuration-options/#releasestage */
    enabledReleaseStages: ['staging', 'production'],
    enabledBreadcrumbTypes: ['error', 'log', 'navigation', 'request', 'user'] as BreadcrumbType[],
  },
  google: {
    mapApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY!,
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID!,
  },
  mapbox: {
    key: process.env.REACT_APP_MAPBOX_KEY!,
  },
  zendesk: {
    key: process.env.REACT_APP_ZENDESK_KEY!,
  }
};
