import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography } from '@mui/material';

import { SubzoneModel } from '../../../../domain/model/SubzoneModel';
import { CUSTOM_MARK_COLOR } from '../../../../shared/enum';
import { SUBZONE_TYPE } from '../../../../shared/enum/subzoneType.enum';
import { colors } from '../../../../theme/options/palette/const/colors';
import { SubzoneIcon } from './SubzoneIcon';
import { useContainer } from '../../../../domain/hooks/useContainer';
import { NotificationService } from '../../../../domain/service/NotificationService';

interface IProps {
  subzone: SubzoneModel;
  canEditAndDeleteSubzone: boolean;
}

export const SubzoneTypeSelection = observer(function SubzoneTypeSelection(props: IProps) {
  const { t } = useTranslation();

  const notification = useContainer().get(NotificationService);

  const selectType = useCallback((type: SUBZONE_TYPE) => {
    if (!props.canEditAndDeleteSubzone) {
      return notification.info(t('subzone:wrong_permission'));
    }
    props.subzone.setType(type);
    props.subzone.setTitle(t(`subzone:type_${type}`));
  }, [props.canEditAndDeleteSubzone, props.subzone, t, notification]);

  return (
    <Box width={'100%'} padding={4}>
      <Grid direction={'row'} container spacing={1}>
        {Object.values(SUBZONE_TYPE).map((type) => {
          const selected = props.subzone.subzoneType === type;
          const icon = SubzoneModel.typeToIcon(type);

          return (
            <Grid item key={`subzone-type-${type}`} xl={3} lg={4} sm={6}>
              <Box
                display={'flex'}
                height="100%"
                sx={{ cursor: 'pointer' }}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                bgcolor={selected ? colors.blueGrey[500] : colors.blueGrey[50]}
                padding={2}
                borderRadius={1}
                onClick={() => selectType(type)}
              >
                <img src={icon} style={{ width: '50px' }} />
                <Typography mt={1} variant="subtitle2" color={selected ? 'white' : colors.primary} textAlign="center">
                  {t(`subzone:type_${type}`)}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      {(props.subzone.subzoneType && props.subzone.subzoneType === SUBZONE_TYPE.CUSTOM) && (<Grid container paddingTop={3} spacing={1}>
        {Object.values(CUSTOM_MARK_COLOR).map((color) => <Grid key={`custom-mark-icon-${color}`} item xs={4} md={3}>
          <Box
            display="flex"
            justifyContent={'center'}
            alignItems="center"
            bgcolor={props.subzone.color === color ? colors.blueGrey[500] : colors.blueGrey[50]}
            padding={2}
            borderRadius={'4px'}
            sx={{ cursor: 'pointer' }}
            onClick={() => props.subzone.setColor(color)}
          >
            <SubzoneIcon color={color} />
          </Box>
        </Grid>)}
      </Grid>)}
    </Box>
  );
});
