import { computed, observable } from 'mobx';

import { createUUID } from '../../__test__/uuid';
import { WeaponResponseDto } from '../../shared/dto/weapon.response.dto';
import { WEAPON_TYPE } from '../../shared/enum';
import { GearManufacturerModel } from './GearManufacturerModel';
import { ITranslatable } from '../service/I18nService';

export class WeaponModel {

  @observable
  public id: string | null = null;

  @observable
  public name: string | null = null;

  @observable
  public type: WEAPON_TYPE | null = null;

  @observable
  public manufacturer: GearManufacturerModel | null = null;

  private tempID: string = createUUID();

  @computed
  public get identifier() {
    if (this.id !== null) {
      return this.id;
    }
    return this.tempID;
  }

  @computed
  public get typeLabel(): ITranslatable {
    if (!this.type) {
      return { raw: '' };
    }
    return { key: `profile:gear.weapon_type_${this.type}` };
  }

  public static fromDto(dto: WeaponResponseDto) {
    const model = new WeaponModel();
    model.id = dto.id;
    model.name = dto.name || null;
    model.type = dto.type;
    model.manufacturer = dto.manufacturer ? GearManufacturerModel.fromDto(dto.manufacturer) : null;
    return model;
  }

}
