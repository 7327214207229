import { injectable } from 'inversify';

import { IHttpOkResponse, IHttpResponse } from '../service/AjaxService';

@injectable()
export abstract class Proxy {
  protected withData<T>(apiResponse: IHttpResponse<unknown>, data: T): IHttpOkResponse<T> {
    return {
      ok: true,
      data,
      status: apiResponse.status,
    };
  }
}
