import { inject } from 'inversify';
import { singleton } from '../../inversify/decorator';
import { MapPathPostRequestDto, MapPathPutRequestDto, MapPathResponseDto, MapPathTypeResponseDto } from '../../shared/dto';
import { AsyncTask } from '../async/AsyncTask';
import { MapPathModel } from '../model/MapPathModel';
import { MapPathTypeModel } from '../model/MapPathType';
import { AjaxService } from '../service/AjaxService';
import { Proxy } from './Proxy';
import { AreaPostRequestDto } from '../../shared/dto/area.post.request.dto';


@singleton()
export class MapPathProxy extends Proxy {

  constructor(@inject(AjaxService) private readonly ajax: AjaxService) {
    super();
  }

  public getMapPathsInMapBounds = async (requestDto: AreaPostRequestDto) => {
    const result = await this.ajax.post<MapPathResponseDto[]>('/map-path/area', requestDto);
    if (result.ok) {
      return this.withData(result, result.data.map(MapPathModel.fromDto));
    }

    return result;
  }

  public getMapPaths = async (districtId: string | undefined) => {
    const result = await this.ajax.get<MapPathResponseDto[]>(`district/v2/${districtId ?? 'worldmap'}/paths`);
    if (result.ok) {
      return this.withData(result, result.data.map((path) => MapPathModel.fromDto(path)));
    }

    return result;
  };

  public getMapPathTypes = new AsyncTask(async () => {
    const result = await this.ajax.get<MapPathTypeResponseDto[]>('map-path/types');
    if (result.ok) {
      return this.withData(result, result.data.map((path) => MapPathTypeModel.fromDto(path)));
    }

    return result;
  });

  public createPath = async (body: MapPathPostRequestDto) => {
    const result = await this.ajax.post<MapPathResponseDto>('map-path', body);
    if (result.ok) {
      return this.withData(result, MapPathModel.fromDto(result.data));
    }

    return result;
  }

  public updatePath = async (body: MapPathPutRequestDto) => {
    const result = await this.ajax.put<MapPathResponseDto>('map-path', body);
    if (result.ok) {
      return this.withData(result, MapPathModel.fromDto(result.data));
    }

    return result;
  }

  public deletePath = async (id: string) => {
    return this.ajax.delete<MapPathResponseDto>(`map-path/${id}`);
  }
}
