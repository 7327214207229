import { makeObservable } from 'mobx';

import { ViewModel } from '../../../../domain/core/ViewModel';
import { singleton } from '../../../../inversify/decorator';
import { IMapControlsProps } from './MapControls';

@singleton()
export class MapControlsVm extends ViewModel<IMapControlsProps> {

  constructor() {
    super();
    makeObservable(this);
  }

  public onZoomIn = () => {
    if (!this.props.googleMapVm.map) {
      return;
    }

    const newZoomLevel = this.props.googleMapVm.map.getZoom()! + 1;
    this.props.googleMapVm.map.setZoom(newZoomLevel);
    this.props.googleMapVm.handleZoomChange(newZoomLevel);
  }

  public onZoomOut = () => {
    if (!this.props.googleMapVm.map) {
      return;
    }

    const newZoomLevel = this.props.googleMapVm.map.getZoom()! - 1;
    this.props.googleMapVm.map.setZoom(newZoomLevel);
    this.props.googleMapVm.handleZoomChange(newZoomLevel);
  }

}
