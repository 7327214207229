import { observer } from 'mobx-react-lite';
import React from 'react';

import { Box, Tab, Tabs } from '@mui/material';

import { useVm } from '../../../../../../../domain/hooks/useVm';
import { DistrictEntryListItem } from './DistrictEntryListItem';
import { IViewDistrictEntriesProps, ViewDistrictEntriesVm } from './ViewDistrictEntriesVm';

export const ViewDistrictEntries = observer(function ViewDistrictEntries(props: IViewDistrictEntriesProps) {
  const vm = useVm(ViewDistrictEntriesVm, props);

  return (
    <Box width="100%">
      <Box sx={{ maxWidth: '100%', boxShadow: 1, }}>
        <Tabs
          value={vm.activeTab}
          indicatorColor="secondary"
          onChange={vm.changeTab}
          variant="fullWidth"
          scrollButtons
          sx={{
            '.MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0.3,
            }
          }}
        >
          {vm.tabs.map((t) => <Tab
            key={`entry-tab-${t.key}`}
            value={t.key}
            label={`${t.text} (${t.count})`}
          />)}
        </Tabs>
      </Box>
      <Box paddingX={2}>
        {vm.entries.map((entry, index) => <DistrictEntryListItem
          key={`district-entry-${entry.id}`}
          entry={entry}
          lastItem={index >= vm.entries.length - 1}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          canEditOrDeleteEntry={vm.canEditOrDeleteEntry(entry)}
        />)}
      </Box>
    </Box>
  );
});
