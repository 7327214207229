import { ApiProperty } from '@nestjs/swagger';

export class WeatherValueUnitItemResponseDto {
  @ApiProperty()
  public value: number | null;
  @ApiProperty()
  public units: string;

  constructor(value: number | null, units: string) {
    this.value = value;
    this.units = units;
  }
}
