import React, { useCallback, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Button, Container, Grid, Modal, Typography } from '@mui/material';

import { Assets } from '../../assets';
import { oldDesignTheme } from '../../theme/oldDesignTheme';
import { colors } from '../../theme/options/palette/const/colors';

export type ForwardingBlockerModalRef = {
  open: () => void,
  close: () => void,
}

interface ForwardingBlockerModalProps extends React.PropsWithChildren<unknown> {
  paymentResolveUrl: string;
}


/**
 * It primarily serves users in the group subscription management interface and blocks them from making updates until payment issues are resolved.
*/
export const ForwardingBlockerModal = React.forwardRef<ForwardingBlockerModalRef, ForwardingBlockerModalProps>(function ForwardingBlockerModal(props: ForwardingBlockerModalProps, ref) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    }
  }));

  const resolvePaymentIssue = useCallback(async () => {
    window.open(props.paymentResolveUrl, '_blank', 'width=800,height=600');
  }, [props.paymentResolveUrl]);

  return (
    <Modal
      open={open}
      disableAutoFocus
      disableEscapeKeyDown
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box position="relative" display="flex" justifyContent="center"
        sx={{
          minHeight: '27.75rem',
          width: '37.5rem',
          bgcolor: colors.default,
          borderRadius: '10px',
          [oldDesignTheme.breakpoints.down('lg')]: {
            marginX: 3
          },
        }}
      >
        <Box position={'absolute'} top={'-13%'} left={'3%'}>
          <img src={Assets.logo} width={140} style={{ position: 'relative' }} />
          <img src={Assets.shield} width={45} style={{ position: 'absolute', top: '28%', right: '3%' }} />
        </Box>

        <Container sx={{
          backgroundColor: colors.default,
          minHeight: '270px',
          height: 'auto',
          marginTop: 7,
          marginBottom: 4,
        }}>
          <Grid container direction={'column'} alignItems={'center'} justifyContent={'space-between'} textAlign={'center'} px={{ xs: 1, md: 3 }} rowGap={2} height={'100%'}>
            <Grid item>
              <Typography fontSize={{ xs: 26, sm: 30, md: 34 }} fontWeight={'bold'}>{t('payment:forwarding_blocker_modal.title')}</Typography>
            </Grid>
            <Grid item>
              <Typography fontSize={{ xs: 26, sm: 30, md: 34 }}>{t('payment:forwarding_blocker_modal.body')}</Typography>
            </Grid>
            <Grid item flexGrow={1} />
            <Grid item>
              <Button onClick={resolvePaymentIssue} variant="contained" color="secondary" sx={{ position: 'relative', minWidth: 250, height: 48 }}>
                {t('payment:forwarding_blocker_modal.button.text')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Modal>
  );
});
