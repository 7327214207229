import React, { BaseSyntheticEvent, useCallback, useMemo } from 'react';

import { env } from '../../../../env';
import { AppModal, AppModalRef } from '../../../../toolkit/components/AppModal';
import { GroupSubStepper } from './stepper/GroupSubStepper';

interface IGroupSubscriptionStepperModalProps {
  ownerPrice: string;
  memberPrice: string;
}

export const GroupSubscriptionStepperModal = function GroupSubscriptionStepperModal({ ownerPrice, memberPrice }: IGroupSubscriptionStepperModalProps) {

  const appModalRef: React.RefObject<AppModalRef> = useMemo(() => React.createRef(), []);

  const handleCloseMenu = useCallback((e: BaseSyntheticEvent) => {
    appModalRef.current?.close();
    e.stopPropagation();
  }, [appModalRef]);

  return (
    // ! TODO: remove when deploying to canary/staging/production
    <AppModal ref={appModalRef} isOpen={env.isDev && env.isLocalhost ? false : true}>
      <GroupSubStepper closeModal={handleCloseMenu} ownerPrice={ownerPrice} memberPrice={memberPrice} />
    </AppModal>
  );

};
