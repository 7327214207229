import { inject } from 'inversify';

import { DistrictModel } from '../domain/model/DistrictModel';
import { EntryModel } from '../domain/model/EntryModel';
import { UserModel } from '../domain/model/UserModel';
import { I18nService } from '../domain/service/I18nService';
import { NotificationService } from '../domain/service/NotificationService';
import { SessionStore } from '../domain/store/SessionStore';
import { singleton } from '../inversify/decorator';
import { RightsManager } from '../shared/checks';
import { ENTRY_TYPE, FALLWILD_TYPE, HARVEST_TYPE } from '../shared/enum';
import { ClassificationHelper } from './classification/ClassificationHelper';
import {
  ClassificationTranslationService
} from './classification/ClassificationTranslationService';

@singleton()
export class EntryHelper {

  constructor(
    @inject(ClassificationHelper) private readonly classificationHelper: ClassificationHelper,
    @inject(ClassificationTranslationService) private readonly classificationTranslationService: ClassificationTranslationService,
    @inject(I18nService) public readonly i18n: I18nService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(SessionStore) private readonly session: SessionStore,
  ) {
  }

  public getEntryIcon(entry: EntryModel, gray: boolean): string {
    if (entry.lat && entry.long) {
      return this.classificationHelper.getClassificationPin(entry.classificationObject ?? {}, entry.entryType);
    }

    return this.classificationHelper.getClassificationIcon(entry.classificationObject ?? {}, gray);
  }

  public getEntryTitle = (entry: EntryModel): string | undefined => {
    return this.classificationTranslationService.getFullTranslationForEntry(entry);
  }

  public isFallwild = (fallwildType: string | null): boolean => {
    if (fallwildType === null) {
      return false;
    }

    return Object.values(FALLWILD_TYPE).some(value => value === fallwildType);
  }

  public isHarvestType = (fallwildType: string | null): boolean => {
    if (fallwildType === null) {
      return true;
    }

    return Object.values(HARVEST_TYPE).some(value => value === fallwildType);
  }

  public getFallwildTranslation = (fallwildType: string) => {
    if (this.isFallwild(fallwildType)) {
      switch (fallwildType) {
        case FALLWILD_TYPE.OTHER:
          return 'entry:cause_of_death.other';
        case FALLWILD_TYPE.TRAFFIC:
          return 'entry:cause_of_death.traffic';
        case FALLWILD_TYPE.MOWING:
          return 'entry:cause_of_death.mowing';
        case FALLWILD_TYPE.NATURAL_DISASTER:
          return 'entry:cause_of_death.natural_disaster';
        case FALLWILD_TYPE.PREDATOR:
          return 'entry:cause_of_death.predator';
        case FALLWILD_TYPE.DISEASE:
          return 'entry:cause_of_death.disease';
        case FALLWILD_TYPE.FROST:
          return 'entry:cause_of_death.frost';
        case FALLWILD_TYPE.STARVATION:
          return 'entry:cause_of_death.starvation';
        case FALLWILD_TYPE.POACHED:
          return 'entry:cause_of_death.poached';
      }
    }

    if (this.isHarvestType(fallwildType)) {
      switch (fallwildType) {
        case HARVEST_TYPE.HARVEST:
          return 'entry:cause_of_death.harvest';
        case HARVEST_TYPE.CULLED:
          return 'entry:cause_of_death.culled';
      }
    }
  }

  private canEditAndDeleteEntry = (
    entry: EntryModel,
    currentUser: UserModel | undefined,
    selectedDistrict: DistrictModel | null,
    districts: DistrictModel[],
  ): boolean => {
    const isEntryVisibleAndEditable = this.isHarvestVisibleAndEditable(entry, districts.map(d => d.id));

    if (currentUser?.id === entry.owner?.id && isEntryVisibleAndEditable) {
      return true;
    }

    if (entry.entryType !== ENTRY_TYPE.KILLING) {
      return false;
    }

    const currentUserAsDistrictMember = selectedDistrict?.members.find(el => el.user?.id === currentUser?.id);
    if (currentUserAsDistrictMember?.role) {
      return RightsManager['canUpdateEntries'](currentUserAsDistrictMember.role.rights);
    }
    return false;
  }

  /**
   * If a HARVEST was created in a specific district, but the user then left the district or was kicked out, that harvest should be visible on the user's Private Map but read-only mode.
   * Additionally, the HARVEST remains in the specified district, and the OWNER of the district retains the rights to edit or delete it.
   * If a user who was kicked out or left the district rejoins, both of them have the right to edit or delete the HARVEST.
  */
  public isHarvestVisibleAndEditable = (entry: EntryModel, districtsIds: string[]): boolean => {
    const entryDistrictId = entry.districtId === undefined ? 'worldmap' : entry.districtId;
    return (districtsIds.some(districtId => districtId === entryDistrictId));
  }

  /**
   * Determines whether the current user can edit and delete a specific entry based on ownership, district membership, and entry type.
   * - Entry owners can edit and delete their own entries of types HARVEST, SIGHTING, and OTHER.
   *   - If the owner is no longer a member of the district:
   *     a) HARVEST entries persist in the district, editable and deletable by the district owner. The owner sees it on a Private Map (read-only).
   *     b) SIGHTING and OTHER entries are removed from the district and appear on the owner's Private Map.
   *   - If the user rejoins the district:
   *     a) HARVEST entries they created in that district become editable and deletable again.
   *     b) SIGHTING and OTHER entries won't reappear in the district; they remain on the owner's Private Map.
   * - District owners can edit and delete all self-created entries and only HARVEST entries created by someone else.
  */
  public canEditAndDeleteEntries = (
    entry: EntryModel,
    selectedDistrict: DistrictModel | null,
    districts: DistrictModel[],
  ): boolean => {
    if (!entry.id) return true; // Assuming new entries can always be edited and deleted

    const isEntryOwner = this.session.currentUser?.id === entry.owner?.id;
    const isEntryInSelectedDistrict = selectedDistrict?.id === entry.districtId;
    const isHarvestEntry = entry.entryType === ENTRY_TYPE.KILLING;
    const canEditAndDeleteEntryRoles = Boolean(selectedDistrict?.canEditAndDeleteEntriesRoles);
    const canEditAndDeleteEntry = this.canEditAndDeleteEntry(
      entry,
      this.session.currentUser,
      selectedDistrict,
      districts
    );

    if (isEntryOwner) {
      if (isEntryInSelectedDistrict && isHarvestEntry) {
        // Entry owner is in the same district where the entry was created, allow editing/deleting regardless of entry type
        return true;
      } else {
        // Entry owner is no longer a part of the district where the entry was created
        if (isHarvestEntry) {
          return false; // Forbid editing/deleting of a HARVEST if the owner is no longer part of the district where the harvest was created
        } else {
          return true; // Allow editing/deleting for other entry types or owned district entries
        }
      }
    } else {
      // Non-owner, allow based on roles
      return canEditAndDeleteEntryRoles && canEditAndDeleteEntry;
    }
  }

  public notifyWrongPermission = (message_key?: string): void => {
    return this.notification.info(this.i18n.t(message_key ?? 'entry:wrong_permission'));
  }

}
