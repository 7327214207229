import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TabContext, TabList } from '@mui/lab';
import { Grid, Tab, Typography } from '@mui/material';

import { SCREENSHOT_ORIENTATION_ENUM } from '../../../../shared/enum/screenshot.orientation.enum';
import { colors } from '../../../../theme/options/palette/const/colors';
import { PrintMapVm } from '../PrintMapVm';

export interface PaperOrientationTabsProps {
  vm: PrintMapVm;
}

export const PaperOrientationTabs = observer(function PaperOrientationTabs({ vm }: PaperOrientationTabsProps) {

  const { t } = useTranslation();

  const handleChange = (_event: React.SyntheticEvent, newValue: SCREENSHOT_ORIENTATION_ENUM) => {
    vm.setOrientation(newValue);
  };

  return (
    <Grid item container direction={'column'} rowGap={1}>
      <Grid item>
        <Typography variant='h4' color={colors.darkFont}>{t('print_preview:modal.section_two.title')}</Typography>
      </Grid>
      <Grid item>
        <TabContext value={vm.orientation}>
          <TabList onChange={handleChange}
            TabIndicatorProps={{
              hidden: true,
            }}
            sx={{
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'space-around',
              alignItems: 'center',
              '& div.MuiTabs-scroller': {
                borderRadius: 4,
                border: `1px solid ${colors.primary}`
              },
              '& button.Mui-selected': {
                color: colors.paper,
                bgcolor: colors.green.hunterGreen,
              }
            }}
          >
            <Tab
              sx={{
                flexGrow: 1,
                flexBasis: 0,
                minWidth: 0,
              }}
              label={t('print_preview:modal.section_two.label_1')}
              value={SCREENSHOT_ORIENTATION_ENUM.PORTRAIT}
            />
            <Tab
              sx={{
                flexGrow: 1,
                flexBasis: 0,
                minWidth: 0,
              }}
              label={t('print_preview:modal.section_two.label_2')}
              value={SCREENSHOT_ORIENTATION_ENUM.LANDSCAPE}
            />
          </TabList>
        </TabContext>
      </Grid>
    </Grid>
  );
});
