import { Marker } from '../Marker';

interface MapPathMarkerProps {
  lat: number | undefined;
  lng: number | undefined;
  icon: string | undefined;
  opacity: number;
  onClick: () => void;
}

export const MapPathMarker = function MapPathMarker(props: MapPathMarkerProps) {
  return (
    <Marker
      lat={props.lat}
      lng={props.lng}
      icon={props.icon}
      opacity={props.opacity}
      onClick={props.onClick}
    />
  );
};
