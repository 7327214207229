import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@mui/material';

import { useContainer } from '../../domain/hooks/useContainer';
import { TrackingEvent } from '../../domain/service/tracking/TrackingEvent';
import { IEventParams } from '../../domain/service/tracking/TrackingEventParams';
import { TrackingService } from '../../domain/service/tracking/TrackingService';
import { colors } from '../../theme/options/palette/const/colors';
import { SubmitButton } from './SubmitButton';

interface ModalWrapperProps extends React.PropsWithChildren<unknown> {
  btnLocizeKey: string;
  event?: TrackingEvent;
  params?: IEventParams;
}

export function ModalWrapper({ children, btnLocizeKey, event }: ModalWrapperProps) {

  const tracking = useContainer().get(TrackingService);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    if (event) {
      tracking.track(event);
    }

    return setOpen(true);
  }, [event, tracking]);

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <div>
      <SubmitButton
        sx={{
          bgcolor: colors.orange[400],
          '&:hover': {
            bgcolor: colors.orange[600]
          }
        }}
        onClick={handleOpen}
      >{t(btnLocizeKey)}</SubmitButton>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051#issuecomment-644954717 */}
        <>{children}</>
      </Modal>
    </div>
  );
}
