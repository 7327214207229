import { IMapBounds } from '../../modules/map/components/google-map/GoogleMapVm';
import { AreaPostRequestDto } from '../../shared/dto/area.post.request.dto';

export class AreaPostRequestModel {

  public mapBoundsToArray(bounds: IMapBounds): number[][] {
    const { northEast, southEast, northWest, southWest } = bounds;

    // * Helper function to convert LatLngLiteral to number array
    const latLngToNumberArray = (latLng: google.maps.LatLngLiteral | null): number[] | null => {
      return latLng ? [latLng.lng, latLng.lat] : null;
    };

    // * The entry point should also be the last point. That's a requirement on the backend.
    return [
      latLngToNumberArray(northWest),
      latLngToNumberArray(northEast),
      latLngToNumberArray(southEast),
      latLngToNumberArray(southWest),
      latLngToNumberArray(northWest),
    ].filter((latLngArray) => latLngArray !== null) as number[][];
  }

  public toDto = (bounds: IMapBounds): AreaPostRequestDto => {
    const dto = new AreaPostRequestDto();
    dto.mapBoundsMesh = this.mapBoundsToArray(bounds);

    return dto;
  }

}
