import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { Box, Button } from '@mui/material';

import { Assets } from '../../../../../../assets';
import { useInstance } from '../../../../../../domain/hooks/useInstance';
import { useVm } from '../../../../../../domain/hooks/useVm';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { FEATURE } from '../../../../../../shared/enum';
import { InfoComponent } from '../../../../../../toolkit/components/InfoComponent';
import { ProCtaButton } from '../../../../../../toolkit/components/ProCtaButton';
import { TaskList } from './task-list/TaskList';
import { Task } from './task/Task';
import { TasksScreens, ViewTasksProps, ViewTasksVm } from './ViewTasksVm';

export const ViewTasks = observer(function ViewTasks(props: ViewTasksProps) {
  const session = useInstance(SessionStore);
  const vm = useVm(ViewTasksVm, props);

  useEffect(() => {
    vm.setStep(TasksScreens.TASKS_LIST);
  }, [props.poi, props.tasks, vm]);

  return (
    <Box height="100%" display={'flex'} flexDirection={'column'}>
      {session.hasFeatureEnabled(FEATURE.TASKS)
        ?
        <React.Fragment>
          <InfoComponent locizePath="task:tasks_readonly" />

          {vm.step === TasksScreens.TASKS_LIST && <Box
            display={'flex'}
            flexDirection={'column'}
            flex={'1 0 auto'}
          >

            {/* LIST OF TASKS */}
            <Box marginX={4}>
              <TaskList
                poi={props.poi}
                pois={props.pois}
                tasks={props.tasks}
                district={props.district}
                onEditTask={vm.setTask}
                onDeleteTask={vm.deleteTask.run}
                markAsCompleted={vm.toggleTaskDone.run}
              />
            </Box>
            <Box
              marginTop={'auto'}
              position="sticky"
              bgcolor={'white'}
              borderTop={1}
              bottom={0}
              display="flex"
              justifyContent={'center'}
              alignItems={'center'}
              width="100%"
              minHeight={'70px'}
              paddingX={4}
            >
              <Button
                color="secondary"
                variant="contained"
                sx={{ width: { xs: 150, lg: 300 }, minHeight: 48 }}
                onClick={() => vm.setTask(null)}>{t('task:btn.new_task')}</Button>

            </Box>
          </Box>}

          {/* TASK FORM */}
          {vm.step === TasksScreens.EDIT_TASK && <Box
            display={'flex'}
            flexDirection={'column'}
            flex={'1 0 auto'}
            paddingX={2}
          >
            <Task
              onClose={() => {
                vm.setTask(null);
                vm.setStep(TasksScreens.TASKS_LIST);
              }}
              district={props.district}
              task={vm.currentTask}
              tasks={props.tasks}
              poi={props.poi}
              pois={props.pois}
              returnToTaskList={() => vm.setStep(TasksScreens.TASKS_LIST)}
            />
          </Box>}
        </React.Fragment>
        :
        <React.Fragment>
          <InfoComponent
            locizePath="poi:pro_only_feature"
          />
          <Box display="flex" flexDirection={'column'} alignItems="center">
            <img src={Assets.poi.pro.tasks} style={{ width: '100%', marginTop: '30px' }} />
            <ProCtaButton />
          </Box>
        </React.Fragment>}
    </Box>
  );
});
