import { action, computed, makeObservable, observable } from 'mobx';
import { serializable } from 'serializr';
import { CustomMarkModel } from '../../../domain/model/CustomMarkModel';
import { DistrictModel } from '../../../domain/model/DistrictModel';
import { PoiModel } from '../../../domain/model/PoiModel';
import { CUSTOM_MARK_ICON } from '../../../shared/enum/customMarkIcon.enum';
import { POI_TYPE } from '../../../shared/enum/poiType.enum';

import { AbstractDistrictFilter, IDistrictFilterCondition } from './AbstractDistrictFilter';
import { IPoiFilter } from './IPoiFilter';

export class PoiFilterCondition {

  @observable
  public id: string = '';

  @observable
  public type: POI_TYPE | null = null;

  @observable
  public customMark: CustomMarkModel | null = null;

  @observable
  public active: boolean = true;

  @observable
  public icon: string = '';

  @observable
  public title: string = '';

  constructor() {
    makeObservable(this);
  }

  public static fromType(poiType: POI_TYPE): PoiFilterCondition {
    const poiFilter = new PoiFilterCondition();
    poiFilter.id = poiType;
    poiFilter.type = poiType;
    poiFilter.active = true;
    poiFilter.icon = PoiModel.typeToIcon(poiType);

    return poiFilter;
  }

  public static fromCustomMark(title: string, customMark: CustomMarkModel): PoiFilterCondition {
    const poiFilter = new PoiFilterCondition();
    poiFilter.id = customMark.id || `${customMark.foreground}`;
    poiFilter.customMark = customMark;
    poiFilter.active = true;
    poiFilter.title = title;

    return poiFilter;
  }
}

export class PoiFilter extends AbstractDistrictFilter implements IPoiFilter {

  @observable
  @serializable
  public allHidden: boolean;

  constructor(
    private readonly district: DistrictModel | null,
    private readonly pois: PoiModel[],
  ) {
    super((district && district.id) ? `DistrictFilterVm-PoiFilter-district-${district!.id!}` : 'DistrictFilterVm-PoiFilter', 1);
    this.allHidden = this.allHidden ?? false;

    makeObservable(this);
  }

  @action
  public toggleHideAll = () => {
    this.setAllTo(this.allHidden ? true : false);
    this.allHidden = !this.allHidden;
  }

  public override toggle(condition: IDistrictFilterCondition) {
    super.toggle(condition);
    this.allHidden = this.conditions.every(c => !c.active);
  }

  @computed
  public get conditions() {
    if (!this.district) {
      return [];
    }

    const poiTypes = new Set<POI_TYPE>();
    const customPoiTypes = new Set<CUSTOM_MARK_ICON>();
    const conditions: PoiFilterCondition[] = [];

    this.pois.forEach((p) => {
      if (p.type !== POI_TYPE.CUSTOM) {
        if (!poiTypes.has(p.type!)) {
          conditions.push(PoiFilterCondition.fromType(p.type!));
        }
        poiTypes.add(p.type!);
      } else if (p.customMark) {
        if (!customPoiTypes.has(p.customMark.foreground!)) {
          conditions.push(PoiFilterCondition.fromCustomMark(p.name, p.customMark));
        }
        customPoiTypes.add(p.customMark.foreground!);
      }
    });

    return this.loadCache(conditions);
  }

  public override deactivate = () => {
    super.deactivate();
    this.allHidden = false;
  }

  public check = (poi: PoiModel): boolean => {
    if (this.allHidden) {
      return false;
    }

    const activeConditions = this.conditions.filter((f) => f.active);

    // there is no active condition
    if (!activeConditions.length) {
      return false;
    }

    if (poi.customMark) {
      return activeConditions.some((c) => {
        return c.customMark && c.customMark.foreground === poi.customMark!.foreground;
      });
    }

    return activeConditions.some((c) => {
      return c.type === poi.type;
    });
  }
}
