export enum WEATHER_CODE {
  FREEZING_RAIN_HEAVY = 'FREEZING_RAIN_HEAVY',
  FREEZING_RAIN = 'FREEZING_RAIN',
  FREEZING_RAIN_LIGHT = 'FREEZING_RAIN_LIGHT',
  FREEZING_DRIZZLE = 'FREEZING_DRIZZLE',
  ICE_PELLETS_HEAVY = 'ICE_PELLETS_HEAVY',
  ICE_PELLETS = 'ICE_PELLETS',
  ICE_PELLETS_LIGHT = 'ICE_PELLETS_LIGHT',
  SNOW_HEAVY = 'SNOW_HEAVY',
  SNOW = 'SNOW',
  SNOW_LIGHT = 'SNOW_LIGHT',
  FLURRIES = 'FLURRIES',
  TSTORM = 'TSTORM',
  RAIN_HEAVY = 'RAIN_HEAVY',
  RAIN = 'RAIN',
  RAIN_LIGHT = 'RAIN_LIGHT',
  DRIZZLE = 'DRIZZLE',
  FOG_LIGHT = 'FOG_LIGHT',
  FOG = 'FOG',
  CLOUDY = 'CLOUDY',
  MOSTLY_CLOUDY = 'MOSTLY_CLOUDY',
  PARTLY_CLOUDY = 'PARTLY_CLOUDY',
  MOSTLY_CLEAR = 'MOSTLY_CLEAR',
  CLEAR = 'CLEAR',
  FIRST_LIGHT = 'FIRST_LIGHT',
  LAST_LIGHT = 'LAST_LIGHT',
  UNKNOWN = 'UNKNOWN',
  LIGHT_WIND = 'LIGHT_WIND',
  WIND = 'WIND',
  STRONG_WIND = 'STRONG_WIND'
}

