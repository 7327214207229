import { ApiProperty } from '@nestjs/swagger';

export class ClassificationObjectResponseDto {

  @ApiProperty()
  public group?: string;

  @ApiProperty()
  public species?: string;

  @ApiProperty()
  public age?: string;

  @ApiProperty()
  public gender?: string;

  @ApiProperty()
  public class?: string;
}
