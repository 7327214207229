import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { InformationIcon } from '@HunterCompanion/hunter-design/icons';
import { Checkbox, TableCell, TableRow, Tooltip } from '@mui/material';

import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import { redesignColors } from '../../../../../theme/options/palette/const/redesignColors';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import { SvgWrapper } from '../../../../../toolkit/components/SvgWrapper';
import { AreasColumn } from './components/AreasColumn';
import { SubscriptionStatusInfoColumn } from './components/SubscriptionStatusInfoColumn';
import { UserColumn } from './components/UserColumn';

interface IAssignAreaMembersTableRowProps {
  row: GroupSubscriptionUserModel;
  isSelected: boolean;
  handleClick: (event: React.MouseEvent<unknown>, id: string) => void;
}

export const AssignAreaMembersTableRow: React.FC<IAssignAreaMembersTableRowProps> = observer(function ({ row, isSelected, handleClick }) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const labelId = `enhanced-table-checkbox-${row.id}`;

  return (
    <TableRow
      hover
      onClick={(event) => !row.isPartOfGroup && handleClick(event, row.id)}
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      sx={{ cursor: row.isPartOfGroup ? 'not-allowed' : 'pointer', opacity: row.isPartOfGroup ? 0.5 : 1 }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          color="primary"
          checked={isSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          disabled={row.isPartOfGroup}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none">
        <UserColumn user={row} />
      </TableCell>
      <TableCell>
        <AreasColumn participant={row} />
      </TableCell>
      <TableCell>
        <SubscriptionStatusInfoColumn user={row} />
      </TableCell>
      <TableCell>
        {row.isPartOfGroup && (
          <Tooltip title={t('manage_group:add_members.part_of_group.status_tooltip.body')} placement='bottom'>
            <div>
              <SvgWrapper icon={InformationIcon} fill={theme.palette.background.error_default} />
            </div>
          </Tooltip>
        )}
        {row.proUserNotEligibleForDiscount && (
          <Tooltip title={t('manage_group:add_members.pro_not_eligible_for_discount')} placement='bottom'>
            <div>
              <SvgWrapper icon={InformationIcon} fill={redesignColors.cta[200]} />
            </div>
          </Tooltip>
        )}
      </TableCell>
    </TableRow>
  );
});
