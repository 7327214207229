import { Marker } from '../Marker';

interface SubzoneMarkerProps {
  lat: number | undefined;
  lng: number | undefined;
  icon: string | undefined;
  opacity: number;
  onClick: () => void;
}

export const SubzoneMarker = function SubzoneMarker(props: SubzoneMarkerProps) {
  return (
    <Marker
      lat={props.lat}
      lng={props.lng}
      icon={props.icon}
      onClick={props.onClick}
      opacity={props.opacity}
    />
  );
};
