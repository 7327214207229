import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, List, ListItem, ListItemButton, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { DistrictModel } from '../../../../../../domain/model/DistrictModel';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { IGeoLocation } from '../../../../../../shared/interfaces/IGeoLocation';
import {
  DistrictsSelectorFilter, DistrictsSelectorFilterCondition
} from '../../../../filters/DistrictsSelectorFilter';
import { SelectDeselectAll } from '../../../filter-sidebar/components/SelectDeselectAll';
import { JoinAreaModal, JoinAreaModalRef } from './district-join-area/JoinAreaModal';
import { DistrictSelectorItem } from './DistrictSelectorItem';
import { KmlImportModal } from './kml-upload/KmlImportModal';
import { KmlImportModalRef } from './kml-upload/KmlImportModalVm';

export interface IDistrictSelectorProps {
  onNewDistrict: (name: string, points: IGeoLocation[], pois: PoiModel[], kmlImport: boolean) => void;
  onSelect: (district: DistrictModel) => void;
  onEdit: (district: DistrictModel, points?: IGeoLocation[]) => void;
  onDelete: (district: DistrictModel) => void;
  selectedDistrict: DistrictModel;
  districts: DistrictModel[];
  districtsSelectorFilter: DistrictsSelectorFilter;
  importMultiplePois: (pois: PoiModel[]) => void
}

export const DistrictSelector = observer(function DistrictSelector(props: IDistrictSelectorProps) {
  const { t } = useTranslation();
  const joinAreaModalRef: React.RefObject<JoinAreaModalRef> = useMemo(() => React.createRef(), []);
  const kmlImportModalRef: React.RefObject<KmlImportModalRef> = useMemo(() => React.createRef(), []);

  const openKMLModal = useCallback(() => kmlImportModalRef.current?.open(), [kmlImportModalRef]);

  const handleToggleCheckbox = useCallback((condition: DistrictsSelectorFilterCondition) => {
    if (condition.id === props.selectedDistrict.id) {
      props.onSelect(props.districts.find(d => d.isWorldMap) || props.districts[0]);
    }
    props.districtsSelectorFilter.toggle(condition);
  }, [props]);

  return (
    <Box paddingY={3}>
      <Typography paddingX={3} color={'text.details'} marginBottom={1}>
        {t('district:your_areas')}:
      </Typography>
      <Box display="flex" justifyContent={'flex-end'}>
        <SelectDeselectAll filter={props.districtsSelectorFilter} onSelect={props.onSelect} />
      </Box>
      <List>
        {props.districtsSelectorFilter.conditions.map(condition =>
          <DistrictSelectorItem
            key={`district-item-${condition.district?.id}`}
            district={condition.district}
            onSelect={props.onSelect}
            onEdit={props.onEdit}
            onDelete={props.onDelete}
            onSelectOnlyThis={() => props.districtsSelectorFilter.selectOnlyCondition(condition)}
            toggleCheckbox={() => handleToggleCheckbox(condition)}
            checked={condition.district.isWorldMap ? true : condition.active}
          />
        )
        }
        <ListItem disablePadding>
          <Grid container paddingX={1}>
            <Grid item xs={12} marginTop={2}>
              <ListItemButton onClick={() => props.onNewDistrict('', [], [], false)}>
                <Typography
                  color="secondary"
                  fontSize={'14px'}
                  fontWeight={'500'}
                  sx={{ cursor: 'pointer' }}
                >
                  {t('district:create_area').toUpperCase()}
                </Typography>
              </ListItemButton>
            </Grid>
            <Grid item xs={12}>
              <ListItemButton onClick={openKMLModal}>
                <Typography
                  color="secondary"
                  fontSize={'14px'}
                  fontWeight={'500'}
                  sx={{ cursor: 'pointer' }}
                  textTransform="uppercase"
                >
                  {t('district:upload_area')}
                </Typography>
              </ListItemButton>
              <KmlImportModal ref={kmlImportModalRef} onDistrictSelected={props.onNewDistrict} importMultiplePois={props.importMultiplePois} />
            </Grid>
            <Grid item xs={12}>
              <ListItemButton onClick={() => joinAreaModalRef.current?.open()}>
                <Typography
                  color="secondary"
                  fontSize={'14px'}
                  fontWeight={'500'}
                  sx={{ cursor: 'pointer' }}
                >
                  {t('district:join_area').toUpperCase()}
                </Typography>
              </ListItemButton>
              <JoinAreaModal ref={joinAreaModalRef} />
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Box>
  );
});
