import { useContext, useMemo } from 'react';

import {
  SCREENSHOT_ORIENTATION_ENUM
} from '../../../../../shared/enum/screenshot.orientation.enum';
import { PinSizeContext } from '../../../PinSizeContext';
import { PaperSizeEnum } from '../../../print-map/components/PaperSizeTabs';
import { aspectRatio } from '../../../print-map/constants/constants';

export function usePinSizeContext() {
  const pinSizeContext = useContext(PinSizeContext);

  const widthDeterminatedByZoomLevel = useMemo(() => {
    const { zoomLevel } = pinSizeContext;

    if (!zoomLevel) {
      return 40;
    }

    if (zoomLevel < 15) {
      return 20;
    } else if (zoomLevel >= 15 && zoomLevel < 15.53) {
      return 25;
    } else if (zoomLevel >= 15.53 && zoomLevel < 17) {
      return 30;
    } else if (zoomLevel >= 17 && zoomLevel < 18) {
      return 35;
    } else if (zoomLevel >= 18) {
      return 40;
    }

    return 40;
  }, [pinSizeContext]);

  const pointSizeDeterminatedByZoomLevel = useMemo(() => {
    const { zoomLevel } = pinSizeContext;

    if (!zoomLevel) {
      return {
        pointSize: 22,
        fontSize: 16,
      };
    }

    if (zoomLevel >= 13.2 && zoomLevel < 15.53) {
      return {
        pointSize: 17,
        fontSize: 11,
      };
    }

    if (zoomLevel < 13.2) {
      return {
        pointSize: 10,
        fontSize: 4,
      };
    }

    return {
      pointSize: 22,
      fontSize: 16,
    };
  }, [pinSizeContext]);

  const widthDeterminatedByPaperSize = useMemo(() => {
    const { paperSize, paperOrientation } = pinSizeContext;

    switch (paperSize) {
      case PaperSizeEnum.A4:
        return adjustPinWidthDependingOnOrientation(25, paperOrientation);
      case PaperSizeEnum.A3:
        return adjustPinWidthDependingOnOrientation((25 / aspectRatio), paperOrientation);
      case PaperSizeEnum.A2:
        return adjustPinWidthDependingOnOrientation((25 / Math.pow(aspectRatio, 2)), paperOrientation);
      case PaperSizeEnum.A1:
      case PaperSizeEnum.A0:
        return adjustPinWidthDependingOnOrientation((25 / Math.pow(aspectRatio, 3)), paperOrientation);
      default:
        return adjustPinWidthDependingOnOrientation(25, paperOrientation);
    }
  }, [pinSizeContext]);

  const pointSizeDeterminatedByPaperSize = useMemo(() => {
    const { paperSize, paperOrientation } = pinSizeContext;

    switch (paperSize) {
      case PaperSizeEnum.A4:
        return {
          pointSize: adjustPinWidthDependingOnOrientation(22, paperOrientation),
          fontSize: adjustPinWidthDependingOnOrientation(16, paperOrientation),
        };
      case PaperSizeEnum.A3:
        return {
          pointSize: adjustPinWidthDependingOnOrientation((22 / aspectRatio), paperOrientation),
          fontSize: adjustPinWidthDependingOnOrientation((16 / aspectRatio), paperOrientation),
        };
      case PaperSizeEnum.A2:
        return {
          pointSize: adjustPinWidthDependingOnOrientation((22 / Math.pow(aspectRatio, 2)), paperOrientation),
          fontSize: adjustPinWidthDependingOnOrientation((16 / Math.pow(aspectRatio, 2)), paperOrientation),
        };
      case PaperSizeEnum.A1:
      case PaperSizeEnum.A0:
        return {
          pointSize: adjustPinWidthDependingOnOrientation((22 / Math.pow(aspectRatio, 3)), paperOrientation),
          fontSize: adjustPinWidthDependingOnOrientation((16 / Math.pow(aspectRatio, 3)), paperOrientation),
        };
      default:
        return {
          pointSize: adjustPinWidthDependingOnOrientation((22 / aspectRatio), paperOrientation),
          fontSize: adjustPinWidthDependingOnOrientation((16 / aspectRatio), paperOrientation),
        };
    }
  }, [pinSizeContext]);

  return {
    pinWidth: pinSizeContext.isPrintMapOverlayActive ? widthDeterminatedByPaperSize : widthDeterminatedByZoomLevel,
    pointSize: pinSizeContext.isPrintMapOverlayActive ? pointSizeDeterminatedByPaperSize : pointSizeDeterminatedByZoomLevel,
    zoomLevel: pinSizeContext.zoomLevel
  };
}

/**
 * We introduced this function because the Pin width was larger in PORTRAIT mode than in LANDSCAPE mode.
 * Now, in Print Map Preview mode, the Pins appear smaller in PORTRAIT mode than in LANDSCAPE mode,
 * but when the PDF is generated, they are the same width.
 */
const adjustPinWidthDependingOnOrientation = (pinWidth: number, paperOrientation: SCREENSHOT_ORIENTATION_ENUM): number => {
  return paperOrientation === SCREENSHOT_ORIENTATION_ENUM.PORTRAIT ? pinWidth * 0.78 : pinWidth;
};
