import { ApiProperty } from '@nestjs/swagger';
import { RoleResponseDto } from './role.response.dto';
import { UserResponseDto } from './user.response.dto';


export class MemberResponseDto {

  @ApiProperty({ type: UserResponseDto })
  public user: UserResponseDto;

  @ApiProperty({ type: RoleResponseDto, required: false })
  public role?: RoleResponseDto;
}
