import { computed, makeObservable, observable } from 'mobx';
import { GearSetResponseDto } from '../../shared/dto/gearSet.response.dto';
import { WEAPON_TYPE } from '../../shared/enum';
import { AmmunitionModel } from './AmmunitionModel';
import { WeaponModel } from './WeaponModel';

export class GearSetModel {

  @observable
  public id: string | null = null;

  @observable
  public name: string;

  @observable
  public notes: string | undefined;

  @observable
  public weapons: WeaponModel[];

  @observable
  public ammunition: AmmunitionModel[];

  @observable
  public isPublic: boolean;

  constructor() {
    makeObservable(this);
  }

  @computed
  public get emptyAmmunition() {
    return !this.ammunition || this.ammunition.length === 0;
  }

  @computed
  public get emptyWeapons() {
    return !this.weapons || this.weapons.length === 0;
  }

  @computed
  public get icon() {
    if (!this.weapons.length) {
      return 'target';
    }
    const type = this.weapons[0].type;

    if (type === WEAPON_TYPE.PISTOL) {
      return 'pistol';
    }

    if (type === WEAPON_TYPE.COMBINATION_GUN) {
      return 'combined';
    }

    if (type === WEAPON_TYPE.REVOLVER) {
      return 'revolver';
    }

    if (type === WEAPON_TYPE.RIFLE) {
      return 'rifle';
    }

    if (type === WEAPON_TYPE.SHOTGUN) {
      return 'shotgun';
    }

    return 'pistol';
  }

  public clone = () => {
    const cloned = new GearSetModel();

    cloned.id = this.id;
    cloned.name = this.name;
    cloned.notes = this.notes;
    cloned.isPublic = this.isPublic;
    cloned.weapons = this.weapons;
    cloned.ammunition = this.ammunition;

    return cloned;
  }

  public static fromDto(dto: GearSetResponseDto): GearSetModel {
    const model = new GearSetModel();

    model.id = dto.id;
    model.name = dto.name;
    model.notes = dto.notes;
    model.isPublic = dto.isPublic;
    model.weapons = dto.weapons.map((x) => WeaponModel.fromDto(x));
    model.ammunition = dto.ammunition ? dto.ammunition.map((x) => AmmunitionModel.fromDto(x)) : [];

    return model;
  }
}
