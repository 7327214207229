import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  ListItem, ListItemButton, Menu, MenuItem, Typography
} from '@mui/material';

import { IGeoLocation } from '../../../../../../domain/core/IGeoLocation';
import { DistrictModel } from '../../../../../../domain/model/DistrictModel';
import { DISTRICT_ROLE } from '../../../../../../shared/enum';
import { KmlImportModal } from './kml-upload/KmlImportModal';
import { KmlImportModalRef } from './kml-upload/KmlImportModalVm';

interface IProps {
  district: DistrictModel;
  onSelect: (district: DistrictModel) => void;
  onEdit: (district: DistrictModel, points?: IGeoLocation[]) => void;
  onDelete: (district: DistrictModel) => void;
  checked: boolean;
  onSelectOnlyThis: () => void;
  toggleCheckbox: () => void;
}

export function DistrictSelectorItem(props: IProps) {
  const { district } = props;
  const [showingOnly, setShowingOnly] = useState(false);

  const { t } = useTranslation();
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const kmlImportModalRef: React.RefObject<KmlImportModalRef> = useMemo(() => React.createRef(), []);

  const showMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const closeAlert = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const onEdit = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
    props.onEdit(props.district);
  }, [props]);

  const onUpdateDistrictBorders = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    kmlImportModalRef.current?.open();
    setMenuAnchorEl(null);
  }, [kmlImportModalRef]);

  const confirmDeletion = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
    setAlertOpen(true);
  }, [setAlertOpen]);

  const onDelete = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
    setAlertOpen(false);
    props.onDelete(props.district);
  }, [props]);

  const cancelDeletion = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setAlertOpen(false);
  }, []);

  const selectOnlyThis = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    props.onSelectOnlyThis();
  }, [props]);

  const handleCheckboxClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    props.toggleCheckbox();
  }, [props]);

  return (
    <ListItem disablePadding onClick={() => props.onSelect(district)}>
      <ListItemButton
        sx={{ paddingLeft: 1, paddingRight: 2 }}
        onMouseOver={() => setShowingOnly(true)}
        onMouseOut={() => setShowingOnly(false)}
        disableGutters
      >
        <Box width="100%" display="flex" flexDirection={'row'} justifyContent="flex-start" alignItems={'center'}>
          <Checkbox checked={props.checked} onClick={handleCheckboxClick} sx={{ visibility: props.district.isWorldMap ? 'hidden' : 'visible' }} />

          <Typography>{district.name}</Typography>
          <Box display='flex' flexGrow={1} justifyContent={'flex-end'}>
            <Button onClick={selectOnlyThis} sx={{
              visibility: showingOnly ? 'visible' : 'hidden',
            }}>{t('filter:select_only_this')}</Button>
            {(!props.district.isWorldMap && props.district.self?.role?.name !== DISTRICT_ROLE.USER) && <Box>
              <Button
                onClick={showMenu}
                color="secondary"
              >
                {t('district:options_dropdown')}
              </Button>
              <Menu
                anchorEl={menuAnchorEl}
                open={menuOpen}
                onClose={closeMenu}
              >
                <MenuItem onClick={onEdit}>{t('district:options_dropdown_edit')}</MenuItem>
                <MenuItem onClick={onUpdateDistrictBorders}>{t('district:options_dropdown_update_borders')}</MenuItem>
                <MenuItem onClick={confirmDeletion}>{t('district:options_dropdown_delete')}</MenuItem>
              </Menu>
              <KmlImportModal ref={kmlImportModalRef} onDistrictSelected={(_name, points) => props.onEdit(district, points)} />
              <Dialog
                open={alertOpen}
                onClose={closeAlert}
              >
                <DialogTitle>
                  {t('district:delete_district_alert_title')}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t('district:delete_district_alert_message')}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={cancelDeletion}>{t('district:delete_district_cancel')}</Button>
                  <Button onClick={onDelete} color="error">
                    {t('district:delete_district_delete')}
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>}
          </Box>
        </Box>
      </ListItemButton>
    </ListItem>
  );
}
