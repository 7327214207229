import { inject } from 'inversify';
import { computed, makeObservable } from 'mobx';
import { ReactFacebookLoginInfo } from 'react-facebook-login-typed';
import { NavigateFunction } from 'react-router-dom';

import { ViewModel } from '../../../../domain/core/ViewModel';
import { AuthProxy } from '../../../../domain/proxy/AuthProxy';
import { I18nService } from '../../../../domain/service/I18nService';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { TrackingEvent } from '../../../../domain/service/tracking/TrackingEvent';
import { RegistrationVia } from '../../../../domain/service/tracking/TrackingEventParams';
import { TrackingService } from '../../../../domain/service/tracking/TrackingService';
import { transient } from '../../../../inversify/decorator';
import { Types } from '../../../../inversify/types';
import { AppRoutes } from '../../../../router/AppRoutesEnum';
import { LANGUAGE } from '../../../../shared/enum';
import { AuthService } from '../../AuthService';
import {
  OAuthType
} from '../../register/components/social-media-registration/SocialMediaRegistrationRoute';
import { IFacebookLoginButtonProps } from './FacebookLoginButton';

@transient()
export class FacebookLoginButtonVm extends ViewModel<IFacebookLoginButtonProps> {

  constructor(
    @inject(AuthService) private readonly authService: AuthService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(AuthProxy) private readonly authProxy: AuthProxy,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get fromLocation(): string {
    const existingRoute = Object.values(AppRoutes).find(val => val === this.props.fromLocation);
    return existingRoute ?? AppRoutes.Map;
  }

  public facebookLogin = async (data: ReactFacebookLoginInfo) => {
    try {
      /* Check if user already exists. */
      const response = await this.authService.startLoginRequest(OAuthType.Facebook, data.accessToken);
      const event = !response?.ok ? TrackingEvent.REGISTRATION_STARTED : TrackingEvent.LOGIN_STARTED;

      await this.tracking.track(event, { registrationType: RegistrationVia.FACEBOOK });

      const [firstName, lastName] = data.name?.split(' ') ?? [];

      if (!response?.ok) {
        return this.navigate(AppRoutes.SocialMediaRegister, {
          state: {
            access_token: data.accessToken,
            email: data.email,
            type: OAuthType.Facebook,
            firstName,
            lastName,
            language: this.i18n.language as LANGUAGE,
          }
        });
      }

      await this.tracking.track(event, { registrationType: RegistrationVia.FACEBOOK });

      await this.tracking.track(TrackingEvent.LOGIN_COMPLETED, { registrationType: RegistrationVia.FACEBOOK });

      return this.navigate(event === TrackingEvent.REGISTRATION_STARTED ? AppRoutes.Map : this.fromLocation);
    } catch (e) {
      console.error(`unexpected exception. ${e}`);
      this.notification.error(this.i18n.t('auth:socialmedia_button.facebook_error'));
    }
  };

  @computed
  public get isBusy() {
    return this.authProxy.loginAuthViaFacebook.isBusy;
  }

  public failureFacebookResponse = () => {
    this.notification.error(this.i18n.t('auth:socialmedia_button.facebook_failure'));
  }
}
