export enum PAYMENT_PLATFORM {
    //legacy values directly from playstore/appstore
    ANDROID = 'android',
    IOS = 'ios',
    // actual revenuecat values
    PLAY_STORE = 'PLAY_STORE',
    APP_STORE = 'APP_STORE',
    STRIPE = 'STRIPE',
    MAC_APP_STORE = 'MAC_APP_STORE',
    PROMOTIONAL = 'PROMOTIONAL'
  }
