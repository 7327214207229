import { makeObservable, observable } from 'mobx';

import { createUUID } from '../../../__test__/uuid';
import { PrecipitationResponseDto } from '../../../shared/dto/weather/precipitation.response.dto';
import { PRECIPITATION_TYPE } from '../../../shared/enum/weather/precipitationType.enum';
import { PrecipitationAmountModel } from './PrecipitationAmountModel';
import { WeatherUnitValueModel } from './WeatherUnitValue';

export class PrecipitationModel {

  @observable
  public id: string;

  @observable
  public amount: PrecipitationAmountModel;

  @observable
  public probability: WeatherUnitValueModel | null;

  @observable
  public percentage: WeatherUnitValueModel;

  @observable
  public type: PRECIPITATION_TYPE | undefined;

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: PrecipitationResponseDto) => {
    const model = new PrecipitationModel();

    model.id = createUUID();
    model.amount = PrecipitationAmountModel.fromDto(dto.amount);
    model.probability = dto.probability ? WeatherUnitValueModel.fromDto(dto.probability) : null;
    if (dto.percentage) {
      model.percentage = WeatherUnitValueModel.fromDto(dto.percentage);
    }

    model.type = dto.type;

    return model;
  }

  public toDto = () => {
    const dto = new PrecipitationResponseDto();

    dto.amount = PrecipitationAmountModel.fromDto(this.amount);
    dto.probability = this.probability?.toDto();
    dto.percentage = this.percentage.toDto();
    dto.type = this.type;

    return dto;
  }
}
