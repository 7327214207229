import React from 'react';

interface IProps {
  color: string;
}

export function SubzoneIcon(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="68.569" height="68.569" viewBox="0 0 68.569 68.569">
      <defs>
        <filter id="Rectangle_7109" x="0" y="0" width="68.569" height="68.569" filterUnits="userSpaceOnUse">
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Rectangle_7109)">
        <g id="Rectangle_7109-2" data-name="Rectangle 7109" transform="translate(5 33.28) rotate(-45)" fill={props.color} stroke="#fff" strokeWidth="1">
          <rect width="40" height="40" rx="6" stroke="none" />
          <rect x="0.5" y="0.5" width="39" height="39" rx="5.5" fill="none" />
        </g>
      </g>
    </svg>

  );
}
