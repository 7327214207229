import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { colors } from '../../../../theme/options/palette/const/colors';
import { PoiMarkerVm } from './marker/PoiMarkerVm';

interface PoiTooltipProps {
  vm: PoiMarkerVm;
}

export function PoiTooltip({ vm }: PoiTooltipProps) {
  return (
    <React.Fragment>
      <Box>
        <Grid container direction="column" justifyContent="center" sx={{ borderRadius: 10 }}>
          <Grid item padding={1}
            sx={{
              background: vm.backgroundColor,
              color: colors.paper,
              borderRadius: '4px',
            }}
          >
            <Typography fontSize={10} fontWeight="regular">
              {vm.headerDetail}
            </Typography>
            <Typography fontSize={13} fontWeight="regular">
              {vm.headerTitle}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
