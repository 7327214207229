import { observer } from 'mobx-react-lite';
import { Resizable } from 're-resizable';
import { useContext, useEffect, useLayoutEffect } from 'react';

import { Box } from '@mui/material';

import { resizerElementMinDimensions } from './constants/constants';
import { PrintMapVm } from './PrintMapVm';
import { PinSizeContext } from '../PinSizeContext';

interface PrintMapOverlayProps {
  vm: PrintMapVm;
}

export const PrintMapOverlay = observer(function PrintMapOverlay({ vm }: PrintMapOverlayProps) {

  const pinSizeContext = useContext(PinSizeContext);

  /**
   * It's prepared for resizing box -> changing size of screenshot capture. But for now we will have fixed dimensions.
   */
  useLayoutEffect(() => {
    if (!vm.resizerElementBounds) return;

    vm.setOverlayCoordinates({
      north: vm.resizerElementBounds.offsetTop,
      west: vm.resizerElementBounds.offsetLeft,
      south: vm.resizerElementDimensions.height + vm.resizerElementBounds.offsetTop,
      east: vm.resizerElementDimensions.width + vm.resizerElementBounds.offsetLeft,
    });
  }, [vm]);

  useEffect(() => {
    pinSizeContext.setPaperOrientation(vm.orientation);
  }, [pinSizeContext, vm.orientation]);

  return (
    <Box
      width={'100%'}
      height={'inherit'}
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      justifyContent={'center'}
      position={'absolute'}
      top={'0%'}
      left={'0%'}
      sx={{
        cursor: 'grab',
        pointerEvents: 'none',
      }}
    >
      <Box id="resizer" sx={{
        boxShadow: '0 0 0 10000px rgba(0,0,0,0.5)',
        // pointerEvents: 'all',
      }}>
        <Resizable
          size={{
            width: vm.resizerElementDimensions.width,
            height: vm.resizerElementDimensions.height
          }}
          lockAspectRatio
          minHeight={resizerElementMinDimensions[vm.orientation].height}
          minWidth={resizerElementMinDimensions[vm.orientation].width}
          maxHeight={vm.resizerMaxDimensions?.height}
          maxWidth={vm.resizerMaxDimensions?.width}
          style={{
            position: 'relative',
            boxShadow: '0 0 0 10000px rgba(0,0,0,0.5)!important',
            border: '0.25rem solid #fff',
            boxSizing: 'border-box',
            pointerEvents: 'none',
          }}
          onResizeStart={vm.toggleHideElements}
          onResizeStop={vm.handleOverlayChange}
        >
        </Resizable>

      </Box>

    </Box >
  );
});
