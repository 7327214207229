import { makeObservable, observable } from 'mobx';

import { StripePriceResponseDto } from '../../../shared/dto/stripe/stripe.price.response.dto';
import { StripePriceTierModel } from './StripePriceTierModel';

export class StripePriceModel {
  @observable
  public priceId: string = '';

  @observable
  public lookupKey: string | null = null;

  @observable
  public priceAmount: number = 0;

  @observable
  public currency: string = '';

  @observable
  public tiers: StripePriceTierModel[] = [];

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: StripePriceResponseDto) => {
    const price = new StripePriceModel();

    price.priceId = dto.priceId;
    price.lookupKey = dto.lookupKey;
    price.priceAmount = dto.priceAmount;
    price.currency = dto.currency;
    if (dto.tiers && dto.tiers.length) {
      price.tiers = dto.tiers.map((tier) => StripePriceTierModel.fromDto(tier));
    }

    return price;
  }
}
