import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, IsUUID } from 'class-validator';

import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';

import { POI_TYPE } from '../enum';
import { CustomMarkPostRequestDto } from './customMark.post.request.dto';
import { ShootingAngleType, ShootingDirectionType } from '../types/shootingDirection';

export class PoiPostRequestDto {

  @IsOptional()
  @IsUUID()
  public id?: string;

  @IsOptional()
  @IsUUID()
  public districtId?: string;

  @IsString()
  @IsNotEmpty()
  public name: string;

  @IsEnum(POI_TYPE)
  @ApiProperty({ required: true, description: 'type of poi' })
  public type: string;

  @IsOptional()
  @IsUUID()
  @ApiPropertyOptional({ description: 'id of a customMark' })
  public customMarkId?: string;

  @IsNumber()
  @ApiProperty({ required: true, description: 'latitude of poi' })
  public lat: number;

  @IsNumber()
  @ApiProperty({ required: true, description: 'longitude of poi' })
  public long: number;

  @ApiProperty({ required: false, description: 'meta of poi' })
  public meta?: any;

  @IsOptional()
  @IsString()
  @ApiPropertyOptional()
  public description?: string;

  @IsOptional()
  @ApiProperty()
  public customMark?: CustomMarkPostRequestDto;

  @IsOptional()
  @IsNumber()
  @ApiPropertyOptional()
  public shootingDirection?: ShootingDirectionType;

  @IsOptional()
  @IsNumber()
  @ApiPropertyOptional()
  public shootingAngle?: ShootingAngleType;
}
