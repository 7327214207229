import { ApiProperty } from '@nestjs/swagger';

import { SpeedUnit } from '../../enum/weather/speedUnit.enum';
import { WIND_DIRECTION } from '../../enum/weather/windDirection.enum';
import { WeatherValueUnitItemResponseDto } from './weatherValueUnitItem.response.dto';

export class WindResponseDto {
  @ApiProperty({ enum: WIND_DIRECTION })
  public direction: WIND_DIRECTION;
  @ApiProperty()
  public speed: number;
  @ApiProperty()
  public unit: SpeedUnit;
  @ApiProperty({ type: () => WeatherValueUnitItemResponseDto })
  public degree: WeatherValueUnitItemResponseDto
}
