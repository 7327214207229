import * as React from 'react';

import { TableRow } from '@mui/material';

import { useContainer } from '../../../../domain/hooks/useContainer';
import { GroupSubscriptionUserModel } from '../../../../domain/model/GroupSubscriptionUserModel';
import { SessionStore } from '../../../../domain/store/SessionStore';
import { GroupOverviewType, isGroupOverviewMembersOnly } from '../../types/GroupOverviewTypes';
import { ActionCell } from './cells/ActionCell';
import { AreaCell } from './cells/AreaCell';
import { PriceCell } from './cells/PriceCell';
import { RoleCell } from './cells/RoleCell';
import { SeatCell } from './cells/SeatCell';

interface IGroupOverviewTableRowProps {
  row: GroupSubscriptionUserModel;
  index: number;
  groupOverviewData: GroupOverviewType;
  removeMember: (member: GroupSubscriptionUserModel) => void;
  page: number;
  rowsPerPage: number;
}

export const GroupOverviewTableRow: React.FC<IGroupOverviewTableRowProps> = function GroupOverviewTableRow({
  row,
  index,
  groupOverviewData,
  removeMember,
  page,
  rowsPerPage
}: IGroupOverviewTableRowProps) {

  const { userId } = useContainer().get(SessionStore);
  const isGroupOwner = React.useMemo(() => userId === row.id, [row.id, userId]);

  const seatIndex = React.useMemo(() => {
    // Calculate the overall index across pages by adding the page offset
    const pageOffset = page * rowsPerPage;

    if (isGroupOverviewMembersOnly(groupOverviewData)) {
      return pageOffset + index + groupOverviewData.groupSubscriptionInfo.quantity - groupOverviewData.groupSubscriptionInfo.emptySeats;
    }
    return pageOffset + index;
  }, [groupOverviewData, index, page, rowsPerPage]);


  // * If there are some unassigned seats, first assign new members to that seats
  const assignToEmptySeat = React.useMemo(() => {
    return isGroupOverviewMembersOnly(groupOverviewData) && index < groupOverviewData.groupSubscriptionInfo.emptySeats;
  }, [groupOverviewData, index]);

  return (
    <TableRow tabIndex={-1} key={row.id}>
      <SeatCell index={seatIndex} row={row} isGroupOwner={isGroupOwner} />
      <RoleCell isGroupOwner={isGroupOwner} />
      <AreaCell row={row} />
      <PriceCell row={row} groupOverviewData={groupOverviewData} assignToEmptySeat={assignToEmptySeat} />
      <ActionCell row={row} isGroupOwner={isGroupOwner} removeMember={removeMember} />
    </TableRow>
  );
};
