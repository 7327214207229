import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { SyntheticEvent, useCallback } from 'react';
import { IGoogleMapProps } from '../../../../domain/core/IGoogleMapProps';
import { MapPathModel } from '../../../../domain/model/MapPathModel';
import { OptionalTooltip } from '../../../../toolkit/components/OptionalTooltip';
import { usePinSizeContext } from '../google-map/hooks/usePinSizeContext';
import { MapPathPointTooltip } from './MapPathPointTooltip';

interface IProps extends IGoogleMapProps {
  mapPath: MapPathModel;
  start: boolean;
  end: boolean;
  opacity: number;
  onClick: (path: MapPathModel) => void;
  showUtilsTooltip: boolean;
  showInfoTooltip: boolean;
  setupInProgress: boolean;
}

export const MapPathPoint = observer(function MapPathPoint(props: IProps) {
  const { pointSize } = usePinSizeContext();

  const size = props.setupInProgress
    ? !props.start && !props.end ? 16 : 24
    : !props.start && !props.end ? 10 : pointSize.pointSize;

  const internalOnClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();

    if (!props.setupInProgress) {
      return props.onClick(props.mapPath);
    }

    if (props.lat && props.lng) {
      props.mapPath.mesh.selectMeshPoint({
        latitude: props.lat,
        longitude: props.lng,
      });
    }
  }, [props]);

  const internalOnDelete = useCallback(() => {
    if (props.lat && props.lng) {
      props.mapPath.mesh.removeMeshPoint({
        latitude: props.lat,
        longitude: props.lng,
      });
    }
  }, [props.mapPath.mesh, props.lat, props.lng]);

  const isSelected = props.lat === props.mapPath.mesh.selectedMeshPoint?.latitude
    && props.lng === props.mapPath.mesh.selectedMeshPoint?.longitude;

  const renderPoint = useCallback(() => {
    return (
      <Box
        onClick={internalOnClick}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transform: 'translate(-50%, -50%)',
          width: `${size}px`,
          height: `${size}px`,
          borderRadius: '50%',
          backgroundColor: isSelected ? 'white' : props.mapPath.color,
          opacity: props.opacity,
        }}
      >
        <Typography variant="subtitle1" sx={{
          color: isSelected ? props.mapPath.color : 'white',
          fontSize: pointSize.fontSize
        }}>
          {props.start
            ? 'A'
            : props.end
              ? 'B'
              : <Box sx={{
                backgroundColor: isSelected ? props.mapPath.color : 'white',
                width: `${size / 2}px`,
                height: `${size / 2}px`,
                borderRadius: '50%',
              }} />
          }
        </Typography>
      </Box>
    );
  }, [internalOnClick, size, isSelected, props.mapPath.color, props.opacity, props.start, props.end, pointSize.fontSize]);

  if (props.setupInProgress && props.showUtilsTooltip) {
    return (
      <Tooltip
        leaveDelay={200}
        placement="top"
        title={<Box
          display="flex"
          flexDirection="row"
          justifyContent={'space-between'}
          onClick={internalOnDelete}
        >
          <DeleteIcon />
        </Box>}
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              minWidth: 'auto',
              paddingX: 1,
              paddingY: 1,
              cursor: 'pointer',
            }
          }
        }}
      >
        {renderPoint()}
      </Tooltip>
    );
  }

  return (
    /* Override MuiTooltip styles -> https://github.com/mui-org/material-ui/issues/28679#issuecomment-949277606 */
    <OptionalTooltip enabled={props.showInfoTooltip} arrow placement='top'
      title={<MapPathPointTooltip mapPathPoint={props.mapPath} />}
      componentsProps={{
        arrow: {
          sx: {
            '::before': { backgroundColor: `${props.mapPath.color}`, }
          },
        }
      }}
    >
      {renderPoint()}
    </OptionalTooltip>
  );
});
