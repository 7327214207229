import { IsDateString, IsEnum, IsOptional, IsUUID } from 'class-validator';

import { ApiProperty } from '@nestjs/swagger';

import { LANGUAGE } from '../enum';

export class HarvestTagPostRequestDto {
  @IsUUID()
  @ApiProperty()
  public districtId: string;

  @IsDateString()
  @ApiProperty({ description: 'Date as a string' })
  public startDate: string;

  @IsDateString()
  @ApiProperty({ description: 'Date as a string' })
  public endDate: string;

  @IsOptional()
  @ApiProperty({ description: 'Users timezone as string' })
  public timezone?: string;

  @IsOptional()
  @IsEnum(LANGUAGE)
  @ApiProperty()
  public language?: LANGUAGE;
}
