import LanguageIcon from '@mui/icons-material/Language';
import { InputAdornment, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { InfoComponent } from '../../../../../../toolkit/components/InfoComponent';

interface IProps {
  poi: PoiModel;
}

export const PoiPosition = observer(function PoiPosition(props: IProps) {
  const { t } = useTranslation();

  return (
    <Box width="100%">
      <InfoComponent
        locizePath="poi:position_description"
      />
      <Box display="flex" justifyContent={'center'} alignItems={'center'} paddingY={4} paddingX={4}>
        <TextField
          label={t('entry:position_latitude')}
          placeholder={t('entry:position_latitude')}
          onChange={(e) => props.poi.setLatitude(parseFloat(e.target.value), 'input')}
          value={props.poi.lat ?? ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LanguageIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box width={'20px'} />
        <TextField
          label={t('entry:position_longitude')}
          placeholder={t('entry:position_longitude')}
          onChange={(e) => props.poi.setLongitude(parseFloat(e.target.value), 'input')}
          value={props.poi.long ?? ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LanguageIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
});
