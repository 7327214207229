import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';
import { IsEnum, IsNotEmpty, IsNumber, IsOptional, IsString, IsUUID } from 'class-validator';
import { POI_TYPE } from '../enum';
import { CustomMarkPostRequestDto } from './customMark.post.request.dto';
import { SYNC_STATE } from '../enum/syncState.enum';
import { ShootingAngleType, ShootingDirectionType } from '../types/shootingDirection';

export class PoiPutRequestDto {
  @IsUUID()
  public id: string;

  @IsOptional()
  @IsUUID()
  public districtId?: string | null;

  @IsString()
  @IsNotEmpty()
  @IsOptional()
  public name?: string;

  @IsEnum(POI_TYPE)
  @IsOptional()
  @ApiProperty({ required: true, description: 'type of poi' })
  public type?: string;

  @IsOptional()
  @IsUUID()
  @ApiPropertyOptional({ description: 'id of a customMark' })
  public customMarkId?: string;

  @IsNumber()
  @IsOptional()
  @ApiProperty({ required: true, description: 'latitude of poi' })
  public lat?: number;

  @IsNumber()
  @IsOptional()
  @ApiProperty({ required: true, description: 'longitude of poi' })
  public long?: number;

  @IsOptional()
  @ApiProperty({ required: false, description: 'meta of poi' })
  public meta?: any;

  @IsOptional()
  @IsString()
  @ApiPropertyOptional()
  public description?: string;

  @IsOptional()
  @ApiProperty()
  public customMark?: CustomMarkPostRequestDto;

  @ApiProperty({ required: false })
  @IsOptional()
  public state?: SYNC_STATE;

  @IsOptional()
  @IsNumber()
  @ApiPropertyOptional()
  public shootingDirection?: ShootingDirectionType;

  @IsOptional()
  @IsNumber()
  @ApiPropertyOptional()
  public shootingAngle?: ShootingAngleType;

  @IsOptional()
  @IsUUID()
  @ApiPropertyOptional()
  public checkinUserId?: string;
}
