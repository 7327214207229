import { IsOptional, IsString, IsUUID } from 'class-validator';

export class GroupMiniDto {

  @IsUUID()
  public id: string;

  @IsOptional()
  @IsString({ context: {errorCode: 422} })
  public name?: string;
}
