import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Onboarding_05_Icon } from '@HunterCompanion/hunter-design/icons';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';

import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { SvgWrapper } from '../../../../toolkit/components/SvgWrapper';

interface IGroupProductInfoProps {
  startDate: string;
  endDate: string;
}

export const GroupProductInfo: React.FC<IGroupProductInfoProps> = function GroupProductInfo({ startDate, endDate }: IGroupProductInfoProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <Box
      border={`1px solid ${theme.palette.borderDividers?.lighter_darker}`}
      borderRadius={2}
      padding={2}
    >
      <Grid container justifyContent={'space-around'} alignItems={'center'} rowGap={2}>
        <Grid container item xs={12} sm={6} alignItems={'center'} gap={1}>
          <Grid item>
            <SvgWrapper icon={Onboarding_05_Icon} fill={undefined} width={50} height={40} />
          </Grid>
          <Grid item xs>
            <Typography variant='text_xs_medium' color={theme.palette.text.primary}>
              {t('group_subscription:info_block.subtitle')}
            </Typography>
            <Typography variant={isMobileSize ? 'headingSubtitleMobile' : 'headingSubtitleDesktop'} color={theme.palette.text.primary}>
              {t('group_subscription:info_block.title')}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={6} textAlign={{ xs: 'center', sm: 'left' }}>
          <Typography variant='text_sm_regular' color={theme.palette.text.secondary}>
            <Trans
              i18nKey={'group_subscription:info_block.description'}
              values={{
                startDate: startDate,
                endDate: endDate,
              }} />
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
