import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { NavigateFunction } from 'react-router';

import { AsyncTask } from '../../../domain/async/AsyncTask';
import { ViewModel } from '../../../domain/core/ViewModel';
import { AuthProxy } from '../../../domain/proxy/AuthProxy';
import { I18nService } from '../../../domain/service/I18nService';
import { NotificationService } from '../../../domain/service/NotificationService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { transient } from '../../../inversify/decorator';
import { Types } from '../../../inversify/types';
import { AppRoutes } from '../../../router/AppRoutesEnum';
import { UserPostRegisterRequestDto } from '../../../shared/dto';
import {
  GroupSubscriptionInvitationRequestDto
} from '../../../shared/dto/subscription/groupSubscriptionInvitation.post.request.dto';

type AcceptFormType = Omit<UserPostRegisterRequestDto, 'email' | 'language'> & {
  privacyAccepted: boolean;
};


@transient()
export class AcceptGroupInviteVm extends ViewModel {

  @observable
  public invitationToken: string = '';

  @observable
  public email: string = '';

  constructor(
    @inject(AuthProxy) private readonly authProxy: AuthProxy,
    @inject(SessionStore) public readonly session: SessionStore,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(Types.Navigate) public readonly navigate: NavigateFunction
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setInvitationToken = (invitationToken: string) => {
    this.invitationToken = invitationToken;
  }

  @action
  public setEmail = (email: string) => {
    this.email = email;
  }

  public registerAndAcceptInvite = new AsyncTask(async (data: AcceptFormType) => {
    try {
      const registerBody: GroupSubscriptionInvitationRequestDto = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: this.email,
        password: data.password,
        token: this.invitationToken,
        privacyAccepted: data.privacyAccepted
      };

      const result = await this.authProxy.acceptGroupInvitation.run(registerBody);

      if (result.ok) {
        this.notification.success(this.i18n.t('group_subscription:group_invitation_accepted'));
        await this.session.login({
          email: registerBody.email,
          password: registerBody.password,
        });
        return this.navigate(AppRoutes.Map);
      }

      return this.notification.error(this.i18n.t('auth:register_email_form_error'));
    } catch (e) {
      console.error(`exception while doing login. ${e}`);
      this.notification.error(this.i18n.t('auth:register_email_form_error'));
    }
  });

  @computed
  public get isBusy() {
    return this.registerAndAcceptInvite.isBusy;
  }
}
