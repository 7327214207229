import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useInstance } from '../../../../../../../../domain/hooks/useInstance';
import { HarvestTagResponseDto } from '../../../../../../../../shared/dto/harvestTag.response.dto';
import { colors } from '../../../../../../../../theme/options/palette/const/colors';
import { ClassificationHelper } from '../../../../../../../../util/classification/ClassificationHelper';
import { HarvestListItems } from './HarvestListItems';
import { QuotaPlanSpecieModel } from '../../../../../../../../domain/model/QuotaPlanModel';

interface IProps {
  item: HarvestTagResponseDto;
  offset: number;
  level: number;
  showQuotas: boolean;
  quotas?: QuotaPlanSpecieModel[];
}

function quotaAmount(
  quotas: QuotaPlanSpecieModel[],
  item: HarvestTagResponseDto,
  level: number
) {
  const harvestQuota = quotas.find((q) => {
    switch (level) {
      case 0:
        return item.species === q.species;
      case 1:
        return (
          item.species === q.species &&
          item.age === q.age &&
          item.gender === null
        );
      case 2:
        return (
          item.species === q.species &&
          item.age === q.age &&
          item.gender === q.gender
        );
    }
  });

  if (harvestQuota) return harvestQuota.amount;

  return '-';
}

export function HarvestListItem(props: IProps) {
  const { level, quotas, showQuotas, item, offset } = props;
  const [expanded, setExpanded] = useState(level !== 0);
  const classificationHelper = useInstance(ClassificationHelper);
  const imgUrl = classificationHelper.getClassificationIcon(
    {
      group: item.group,
      species: item.species,
    },
    true
  );

  return (
    <Box
      display="flex"
      flexDirection={'column'}
      alignItems={'center'}
      sx={{ cursor: 'pointer' }}
      onClick={(e) => {
        e.stopPropagation();
        setExpanded(!expanded);
      }}
    >
      <Box
        display="flex"
        flexDirection={'row'}
        width="100%"
        alignItems={'center'}
      >
        <Box mr={2} display="flex" width={'24px'}>
          {!!item.children?.length &&
            level === 0 &&
            (expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />)}
        </Box>
        <Box
          borderBottom={1}
          borderColor={colors.blueGrey[100]}
          minHeight={'50px'}
          display="flex"
          flex={1}
          width="100%"
          alignItems={'center'}
          flexDirection={'row'}
        >
          {imgUrl && (
            <img style={{ width: '40px', marginRight: '12px' }} src={imgUrl} />
          )}
          <Typography>{item.name}</Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="end"
            flexGrow={1}
          >
            <Typography
              color={props.showQuotas ? colors.orange[600] : colors.green[800]}
              variant={props.offset === 0 ? 'h6' : 'subtitle1'}
            >
              {item.count}
            </Typography>
            {showQuotas && quotas && (
              <Typography variant={props.offset === 0 ? 'h6' : 'subtitle1'}>
                &nbsp; / {quotaAmount(quotas, item, props.level)}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      {!!props.item.children?.length && expanded && (
        <HarvestListItems
          items={item.children!}
          offset={offset + 1}
          showQuotas={props.showQuotas}
          quotas={props.quotas}
          level={props.level + 1}
        />
      )}
    </Box>
  );
}
