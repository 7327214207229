import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { SubzoneModel } from '../../../../../../../domain/model/SubzoneModel';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { SessionStore } from '../../../../../../../domain/store/SessionStore';
import { transient } from '../../../../../../../inversify/decorator';
import { FEATURE } from '../../../../../../../shared/enum';
import { SUBZONE_TYPE } from '../../../../../../../shared/enum/subzoneType.enum';


export interface IViewDistrictZonesProps {
  subzones: SubzoneModel[];
  onEdit: (subzone: SubzoneModel) => void;
  onDelete: (subzone: SubzoneModel) => void;
}

type TabKey = SUBZONE_TYPE | 'all';

interface ISubzoneTab {
  key: TabKey;
  count: number;
  text: string;
}

@transient()
export class ViewDistrictZonesVm extends ViewModel<IViewDistrictZonesProps> {

  @observable
  public activeTab: TabKey = 'all';

  constructor(
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get tabs(): ISubzoneTab[] {
    const tabs: { [key: string]: ISubzoneTab } = {};

    tabs['all'] = {
      key: 'all',
      count: this.props.subzones.length,
      text: this.i18n.t('district:district_details_show_all_items'),
    };

    this.props.subzones.forEach((s) => {
      if (!s.subzoneType) {
        return;
      }

      tabs[s.subzoneType] = tabs[s.subzoneType] || { key: s.subzoneType, count: 0, text: this.i18n.t(`subzone:type_${s.subzoneType}`) };
      tabs[s.subzoneType].count++;
    });

    return Object.values(tabs);
  }

  @computed
  public get hasProSubzones() {
    return this.session.hasFeatureEnabled(FEATURE.SUBZONES);
  }

  @computed
  public get subzones(): SubzoneModel[] {
    return this.props.subzones.filter((subzone) => {
      if (this.activeTab === 'all') {
        return true;
      }

      return subzone.subzoneType === this.activeTab;
    });
  }

  @action
  public changeTab = (_: unknown, value: TabKey) => {
    this.activeTab = value;
  }
}
