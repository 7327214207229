import { inject } from 'inversify';
import { computed } from 'mobx';

import { EntryModel } from '../../domain/model/EntryModel';
import { I18nService } from '../../domain/service/I18nService';
import { transient } from '../../inversify/decorator';
import { ENTRY_TYPE, LANGUAGE } from '../../shared/enum';
import { REGION } from '../../shared/enum/region.enum';
import { Translations } from '../translations/translations';
import { translations } from './data/translations';
import { IClassificationTranslationService } from './IClassificationTranslationService';
import { IClassification } from './types/classification.types';

@transient()
export class ClassificationTranslationService implements IClassificationTranslationService {

  constructor(@inject(I18nService) private readonly i18n: I18nService) {
  }

  @computed
  private get translations() {
    if (this.i18n.language) {
      return translations[this.i18n.language];
    }
  }

  @computed
  public get sharedTranslations() {
    const sharedTranslations = new Translations(translations);
    sharedTranslations.setLanguage(this.i18n.language);
    sharedTranslations.setFallbackLanguage(LANGUAGE.en);

    return sharedTranslations;
  }

  public getFullTranslationForEntry = (entry: EntryModel): string | undefined => {
    const entryTypeTranslation = this.getEntryTypeTranslation(entry.entryType);

    if (!entry.classificationObject) {
      if (!entryTypeTranslation) {
        return '...';
      }
      return entryTypeTranslation;
    }

    const classificationTranslation = this.sharedTranslations.translateFullClassification(entry.classificationObject);

    let entryTypeAndClassificationTranslation = this.combineEntryTypeAndClassificationTranslation(entryTypeTranslation, classificationTranslation);

    if ((entry.entryType === ENTRY_TYPE.SIGHTING || entry.entryType === ENTRY_TYPE.KILLING) && entry.count > 1) {
      entryTypeAndClassificationTranslation += ` (${entry.count})`;
    }

    return entryTypeAndClassificationTranslation;
  }

  public getFullTranslationForClassification = (classificationObject: IClassification): string | undefined => {
    return this.sharedTranslations.translateFullClassification(classificationObject);
  }

  public getShortTranslationForEntry = (entry: EntryModel): string | undefined => {
    const entryTypeTranslation = this.getEntryTypeTranslation(entry.entryType);

    if (!entry.classificationObject) {
      if (!entryTypeTranslation) {
        return '...';
      }
      return entryTypeTranslation;
    }

    const classificationTranslation = this.sharedTranslations.translateShortClassification(entry.classificationObject);

    return this.combineEntryTypeAndClassificationTranslation(entryTypeTranslation, classificationTranslation);
  }

  public getGroupOrSpeciesTranslationForClassificationObject = (classificationObject: IClassification): string | undefined => {
    return this.sharedTranslations.translateShortClassification(classificationObject);
  }

  public getRegionName = (region: REGION): string => {
    if (this.translations) {
      const translatedRegion = this.translations[Translations.getKeyForRegion(region.toUpperCase())];
      if (translatedRegion) {
        return translatedRegion;
      }
    }
    return region;
  }

  public getEntryTypeTranslation = (entryType?: ENTRY_TYPE): string | undefined => {
    if (this.translations && entryType) {
      return this.translations[Translations.getKeyForEntry(entryType.toUpperCase())] ?? '';
    }
  }

  private combineEntryTypeAndClassificationTranslation = (entryTypeTranslation?: string, classificationTranslation?: string) => {

    if (!classificationTranslation) {
      return entryTypeTranslation;
    }

    if (!entryTypeTranslation) {
      return classificationTranslation;
    }

    return `${entryTypeTranslation} ${classificationTranslation}`;
  }
}
