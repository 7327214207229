import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';

import { AbstractDistrictFilter } from '../../../filters/AbstractDistrictFilter';
import { PoiFilter } from '../../../filters/PoiFilter';
import { DistrictsSelectorFilter } from '../../../filters/DistrictsSelectorFilter';
import { DistrictModel } from '../../../../../domain/model/DistrictModel';

interface IProps {
  filter: AbstractDistrictFilter;
  onSelect?: (district: DistrictModel) => void;
}

export const SelectDeselectAll = observer(function SelectDeselectAll(props: IProps) {
  const { t } = useTranslation();
  const selectAllActive = useMemo(() => {
    // * Special condition for district selector -> Things from Private map should always be displayed, so you can turn them on/off by clicking on a Select/Deselect all
    if (props.filter instanceof DistrictsSelectorFilter) {
      return props.filter.conditions.filter(c => !c.district.isWorldMap).every((c) => !c.active);
    }
    return props.filter.conditions.every((c) => !c.active);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props, props.filter]);

  const selectAll = useCallback(() => {
    props.filter.setAllTo(true);

    runInAction(() => {
      if (props.filter instanceof PoiFilter) {
        props.filter.allHidden = false;
      }
    });
  }, [props.filter]);

  const deselectAll = useCallback(() => {
    props.filter.setAllTo(false);

    runInAction(() => {
      if (props.filter instanceof DistrictsSelectorFilter) {
        const conditionWorldMap = props.filter.conditions.find(c => c.district.isWorldMap);
        const worldMapDistrict = conditionWorldMap?.district || props.filter.conditions[0].district;

        if (props.onSelect) {
          props.onSelect(worldMapDistrict);
        }

        if (conditionWorldMap) {
          props.filter.setConditionTo(conditionWorldMap, true);
        }

        return;
      }
    });

    runInAction(() => {
      if (props.filter instanceof PoiFilter) {
        props.filter.allHidden = true;
      }
    });
  }, [props]);

  if (!props.filter.conditions.length) {
    return null;
  }

  return selectAllActive
    ? <Button color="secondary" onClick={selectAll}>{t('filter:select_all')}</Button>
    : <Button color="secondary" onClick={deselectAll}>{t('filter:deselect_all')}</Button>;
});
