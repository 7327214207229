import { FormControl, FormControlProps, FormHelperText } from '@mui/material';
import React, { PropsWithChildren, ReactElement, useCallback } from 'react';
import { Controller, ControllerProps, useFormContext, ValidateResult } from 'react-hook-form';
import { MixedSchema } from 'yup/lib/mixed';

type IProps = Omit<ControllerProps, 'render' | 'control'> & Omit<FormControlProps, 'error' | 'defaultValue'> & {
  schema?: MixedSchema;
};

export const Field = React.memo(function Field(props: PropsWithChildren<IProps>) {
  const { control } = useFormContext();

  const validate = useCallback((value: unknown): Promise<ValidateResult> => {
    if (!props.schema) {
      return Promise.resolve(true);
    }

    return props.schema.validate(value).then(() => {
      return true;
    }).catch((e) => {
      return e.message;
    });
  }, [props.schema]);

  return <Controller
    {...props}
    defaultValue={props.defaultValue ?? ''}
    control={control}
    rules={{ validate }}
    render={({ field, fieldState }) => <FormControl {...props} error={!!fieldState.error}>
      {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        React.Children.map(props.children, (child: any) => {
          if (!React.isValidElement(child)) {
            return;
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return React.cloneElement(child as ReactElement<any>, {
            field,
            ...field,
            ...(child.props as React.HTMLAttributes<HTMLElement>), // Type assertion to ensure object type
            ref: null,
          });
        })}
      {!!fieldState.error && (
        <FormHelperText>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>}
  ></Controller>;
});
