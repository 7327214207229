import { useTranslation } from 'react-i18next';

import { Box, FormControlLabel, FormGroup, styled, Switch, SwitchProps, Typography } from '@mui/material';

import { colors } from '../../../theme/options/palette/const/colors';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

const LabelsSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => {
  return {
    width: 40,
    height: 20,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 1,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        '& .MuiSwitch-thumb': {
          color: theme.palette.brand?.selection,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          border: 0,
          backgroundColor: theme.palette.background.container,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      paddingX: 1,
      width: 18,
      height: 18,
      color: theme.palette.icons?.secondary,
    },
    '& .MuiSwitch-track': {
      borderRadius: 16,
      opacity: 1,
      backgroundColor: theme.palette.background.container,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  };
});
interface MapPoiLabelsSwitchProps {
  showPoiLabels: boolean;
  toggleShowPoiLabels: () => void;
}

export const MapPoiLabelsSwitch = function MapPoiLabelsSwitch(props: MapPoiLabelsSwitchProps) {

  const { t } = useTranslation();
  const { theme } = useThemeContext();

  return (
    <Box sx={{
      position: 'absolute',
      top: 10,
      right: 30
    }}>
      <FormGroup>
        <FormControlLabel
          labelPlacement='start'
          control={<LabelsSwitch sx={{ color: colors.paper, ml: 1 }} />}
          checked={props.showPoiLabels}
          onChange={props.toggleShowPoiLabels}
          label={<Typography variant='text_sm_regular' color={theme.palette.system?.white}>{t('map:labels_switch')}</Typography>} />
      </FormGroup>
    </Box>
  );
};
