import React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox, TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { useThemeContext } from '../../../../../theme/ThemeContextProvider';

const headCells = [
  { id: 'members', numeric: false, disablePadding: true, label: 'group_subscription:modal.table.header.members' },
  { id: 'area', numeric: false, disablePadding: false, label: 'group_subscription:modal.table.header.area' },
  { id: 'status', numeric: false, disablePadding: false, label: 'group_subscription:modal.table.header.status' },
  { id: 'icon', numeric: false, disablePadding: false, label: '' },
];

interface IAssignAreaMembersTableProps {
  numSelected: number;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
}

export const AssignAreaMembersTableHead: React.FC<IAssignAreaMembersTableProps> = ({ numSelected, onSelectAllClick, rowCount }) => {
  const { theme } = useThemeContext();
  const { t } = useTranslation();

  return (
    <TableHead sx={{ bgcolor: theme.palette.background.container }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
          >
            <Typography variant='text_sm_semibold' color={theme.palette.text.secondary}>{t(headCell.label)}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
