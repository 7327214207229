import { inject } from 'inversify';
import { computed, makeObservable, runInAction } from 'mobx';

import { AsyncTask } from '../../../../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../../../../domain/core/ViewModel';
import { QuotaPlanProxy } from '../../../../../../../../../domain/proxy/QuotaPlanProxy';
import { I18nService } from '../../../../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../../../../domain/service/NotificationService';
import { transient } from '../../../../../../../../../inversify/decorator';
import { QuotaPlanStatus } from '../../../../../../../../../shared/enum/quotaPlanStatus.enum';
import { IViewDistrictQuotaPlanProps } from './ViewDistrictQuotaPlan';

@transient()
export class ViewDistrictQuotaPlanVm extends ViewModel<IViewDistrictQuotaPlanProps> {

  constructor(
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(QuotaPlanProxy) private readonly quotaProxy: QuotaPlanProxy,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get isFuturePlan(): boolean {
    return this.props.quotaPlan.status === QuotaPlanStatus.FUTURE;
  }

  @computed
  public get isActivePlan(): boolean {
    return this.props.quotaPlan.status === QuotaPlanStatus.ACTIVE;
  }

  @computed
  public get isPastPlan(): boolean {
    return this.props.quotaPlan.status === QuotaPlanStatus.PAST;
  }

  public deleteQuotaPlan = new AsyncTask(async () => {
    try {
      const response = await this.quotaProxy.deleteQuotaPlan(this.props.quotaPlan.id);

      if (response.ok) {
        runInAction(() => {
          this.props.deleteQuotaPlan(this.props.quotaPlan);
        });
        return this.notification.success(this.i18n.t('quota_plan:success.delete_plan'));
      }

      this.notification.error(this.i18n.t('quota_plan:error.delete_plan'));
    } catch (error) {
      this.notification.error(this.i18n.t('quota_plan:error.delete_plan'));
      console.error('Something went wrong while trying to delete quota plan: ', error);
    }
  })
}
