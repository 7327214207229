import React, { Ref, useCallback, useImperativeHandle, useState } from 'react';

import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemButton, ListItemText, Radio, Typography
} from '@mui/material';

import { DistrictModel } from '../../../../domain/model/DistrictModel';
import { useTranslation } from 'react-i18next';

export type DistrictSelectorDialogRef = {
  open: (selectedDistrict: DistrictModel | null, possibleDistricts: DistrictModel[]) => void,
  close: () => void,
  handleOpenDistrictSelectorDialog: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
}

interface DistrictSelectorDialogProps {
  onSelect: (district: DistrictModel) => void;
  onClose: (continueEditing: boolean) => void;
}

export const DistrictSelectorDialog =
  React.forwardRef<DistrictSelectorDialogRef, DistrictSelectorDialogProps>(
    function DistrictSelectorDialog(props: DistrictSelectorDialogProps, ref: Ref<DistrictSelectorDialogRef>) {
      const { t } = useTranslation();
      const [open, setOpen] = useState<boolean>(false);
      const [selectedDistrict, setSelectedDistrict] = useState<DistrictModel | null>();
      const [possibleDistricts, setPossibleDistricts] = useState<DistrictModel[]>([]);

      useImperativeHandle(ref, () => ({
        open(selectedDistrict: DistrictModel | null, possibleDistricts: DistrictModel[]) {
          setOpen(true);
          setSelectedDistrict(selectedDistrict);
          setPossibleDistricts(possibleDistricts);
        },
        close() {
          setOpen(false);
        },
        handleOpenDistrictSelectorDialog(event?: React.MouseEvent<HTMLLIElement, MouseEvent>) {
          setOpen(true);
          event?.stopPropagation();
        },
      }));

      const handleCloseDistrictSelectorDialog = useCallback((continueEditing: boolean) => {
        setOpen(false);
        props.onClose(continueEditing);
      }, [props]);

      const handleSelectDistrictConfirmation = useCallback((continueEditing: boolean) => {
        if (selectedDistrict) {
          props.onSelect(selectedDistrict);
        }
        handleCloseDistrictSelectorDialog(continueEditing);
      }, [handleCloseDistrictSelectorDialog, props, selectedDistrict]);

      return (
        <Dialog open={open} onClose={handleCloseDistrictSelectorDialog} sx={{ zIndex: 9999 }}>
          <DialogTitle sx={{ fontSize: '1.125rem', fontWeight: '500' }}>
            {t('district:selector_dialog.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant="subtitle1">
              {t('district:selector_dialog.body')}
            </DialogContentText>
            <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {possibleDistricts.map((district, index) => {
                return (
                  <ListItem
                    key={`district-${index}`}
                    disablePadding
                    onClick={() => setSelectedDistrict(district)}
                    secondaryAction={<Radio
                      onChange={() => setSelectedDistrict(district)}
                      checked={district.id === selectedDistrict?.id}
                    />}
                  >
                    <ListItemButton>
                      <ListItemText primary={district.name} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCloseDistrictSelectorDialog(false)}>
              <Typography variant="subtitle2">{t('common:button.cancel')}</Typography>
            </Button>
            <Button
              onClick={() => handleSelectDistrictConfirmation(true)}
              autoFocus
            >
              <Typography variant="subtitle2" color={'text.hunter_green'}>{t('common:button.select')}</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      );
    });
