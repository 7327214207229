import { ApiProperty } from '@nestjs/swagger';

export class SubscriptionResponseDto {

  @ApiProperty()
  public name: string;

  @ApiProperty()
  public features: string[];
}
