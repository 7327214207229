import { ClickAwayListener } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Assets } from '../../../assets';
import { MapProvider, MapType } from '../MapVm';
import { MapLayerOption } from './MapLayerOption';

interface IProps {
  mapProvider: MapProvider;
  mapType: MapType;
  styleUrl: string | undefined;
  setupMode: boolean;
  onTypeSelected: (mapProvider: MapProvider, mapType: MapType, styleUrl?: string) => void;
  mapboxDisabled: boolean;
}

export const MapLayerSelector = observer(function MapLayerSelector(props: IProps) {
  const [selecting, setSelecting] = useState(false);
  const { t } = useTranslation();

  const onSelect = useCallback((mapProvider: MapProvider, mapType: MapType, styleUrl?: string) => {
    setSelecting(false);
    props.onTypeSelected(mapProvider, mapType, styleUrl);
  }, [props]);

  const parcels = useMemo(() => {
    return [{
      title: t('map:layers_ch'),
      url: 'mapbox://styles/hunterandco/clatn8vim00a114o6n1fjpldx',
      icon: Assets.map.layers.parcels_ch,
    }, {
      title: t('map:layers_at'),
      url: 'mapbox://styles/hunterandco/clakyr6fj000614mtqpo2fidx',
      icon: Assets.map.layers.parcels_at,
    }, {
      title: t('map:layers_fr'),
      url: 'mapbox://styles/hunterandco/cl9q8totj009p14o2g1af36km',
      icon: Assets.map.layers.parcels_fr,
    }, {
      title: t('map:layers_de'),
      url: 'mapbox://styles/hunterandco/clcsym0ty00e814lc8ul9wlqm',
      icon: Assets.map.layers.parcels_de,
    }, {
      title: t('map:layers_es'),
      url: 'mapbox://styles/hunterandco/clbf13dxi003e14o3skm8dfm1',
      icon: Assets.map.layers.parcels_es,
    },
    ];
  }, [t]);

  return (
    <ClickAwayListener onClickAway={() => setSelecting(false)}>
      <Box display="flex" flexDirection={'row'} position={'absolute'} bottom={50} left={12}>
        {!props.setupMode && <MapLayerOption
          mapType={props.mapType}
          mapProvider={props.mapProvider}
          onClick={() => setSelecting(!selecting)}
          inverted={selecting}
          extended={props.setupMode}
        />}
        {(selecting || props.setupMode) && <Box ml={2} display={'flex'} flexDirection="row" alignItems={'flex-start'}>
          <MapLayerOption
            mapType={'satellite'}
            mapProvider="google"
            onClick={onSelect}
            inverted={props.setupMode && props.mapProvider === 'google' && props.mapType === 'satellite'}
            extended={props.setupMode}
          />
          <MapLayerOption
            mapType={'terrain'}
            mapProvider="google"
            onClick={onSelect}
            inverted={props.setupMode && props.mapProvider === 'google' && props.mapType === 'terrain'}
            extended={props.setupMode}
          />
          <MapLayerOption
            mapType={'roadmap'}
            mapProvider="google"
            onClick={onSelect}
            inverted={props.setupMode && props.mapProvider === 'google' && props.mapType === 'roadmap'}
            extended={props.setupMode}
          />
          {!props.mapboxDisabled && props.setupMode && <>
            {parcels.map((p) => <MapLayerOption
              key={`mapbox-parcels-${p.url}`}
              mapType={'parcels'}
              mapProvider="mapbox"
              styleUrl={p.url}
              title={p.title}
              onClick={onSelect}
              icon={p.icon}
              inverted={props.styleUrl === p.url}
              extended={props.setupMode}
            />)}
          </>}
        </Box>}
      </Box>
    </ClickAwayListener>
  );
});
