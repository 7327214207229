import React, { useCallback, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DialogContent, DialogContentText, IconButton, Menu, MenuItem, TableCell, Typography, useMediaQuery } from '@mui/material';

import {
  GroupSubscriptionUserModel
} from '../../../../../../domain/model/GroupSubscriptionUserModel';
import { DeleteDialog, DeleteDialogRef } from '../../../../../../toolkit/components/DeleteDialog';
import { ManageGroupVm } from '../../../../manage-group/ManageGroupVm';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';

interface IMenuCellRemoveMemberProps {
  index: number;
  row: GroupSubscriptionUserModel;
  vm: ManageGroupVm;
}

export const MenuCellRemoveMember: React.FC<IMenuCellRemoveMemberProps> = function MenuCellRemoveMember({ index, row, vm }) {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const removeMemberDialogRef = useRef<DeleteDialogRef>(null);

  const cancelInvitationDialogRef = useRef<DeleteDialogRef>(null);

  const showMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const openDeleteDialog = useCallback(() => {
    closeMenu();
    removeMemberDialogRef.current?.handleOpenDeleteDialog();
  }, [closeMenu]);

  const openCancelInvitationDialog = useCallback(() => {
    closeMenu();
    cancelInvitationDialogRef.current?.handleOpenDeleteDialog();
  }, [closeMenu]);

  const handleDeleteConfirm = useCallback(async () => {
    await vm.removeUserFromGroup.run(row, vm.groupSubscriptionInfo.coupon);
  }, [row, vm.removeUserFromGroup, vm.groupSubscriptionInfo.coupon]);

  const cancelInvitation = useCallback(async () => {
    await vm.cancelUsersEmailInvitation.run(row.id);
  }, [row.id, vm.cancelUsersEmailInvitation]);

  const resendInvitation = useCallback(() => {
    vm.resendEmailInvitation.run(row.id);
  }, [row.id, vm.resendEmailInvitation]);

  return (
    <TableCell component="th" scope="row" padding="none" sx={{ paddingX: 2 }} align="right">
      <IconButton onClick={showMenu} disabled={index === 0}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="remove-user-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
      >
        <MenuItem onClick={openDeleteDialog}>
          {t('group_subscription:table.table_row.action.remove')}
        </MenuItem>
        {(row.invitationPending || row.invitationExpired) && <MenuItem onClick={resendInvitation}>
          {t('group_subscription:table.table_row.action.resend')}
        </MenuItem>}
        {row.invitationPending && <MenuItem onClick={openCancelInvitationDialog}>
          {t('group_subscription:table.table_row.action.cancel_invitation')}
        </MenuItem>}
      </Menu>

      {/* CANCEL INVITATION DIALOG */}
      <DeleteDialog
        ref={cancelInvitationDialogRef}
        locizeNamespace='manage_group'
        onDelete={cancelInvitation}
        titleKey='email_invitation.cancel.title'
        agreeKey='email_invitation.cancel.cta_agree'
      >
        <DialogContent>
          <DialogContentText>
            <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'}>
              <Trans
                i18nKey="manage_group:email_invitation.cancel.description"
                values={{ email: row.email }}
              />
            </Typography>
          </DialogContentText>
        </DialogContent>
      </DeleteDialog>

      {/* REMOVE MEMBER DIALOG */}
      <DeleteDialog
        ref={removeMemberDialogRef}
        locizeNamespace="manage_group"
        onDelete={handleDeleteConfirm}
      >
        <DialogContent>
          <DialogContentText>
            <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'}>
              <Trans
                i18nKey="group_subscription:delete.description"
                values={{ email: row.email }}
              />
            </Typography>
          </DialogContentText>
        </DialogContent>
      </DeleteDialog>
    </TableCell>
  );
};
