import React from 'react';

export const PlusIcon = () => {
  return (
    <svg id="Component_240_1" data-name="Component 240 – 1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <defs>
        <clipPath id="clip-path_plus">
          <rect id="Rectangle_6178" data-name="Rectangle 6178" width="18" height="18" fill="#2ba289" />
        </clipPath>
      </defs>
      <g id="Group_18277" data-name="Group 18277" clipPath="url(#clip-path_plus)">
        <path id="Path_27866" data-name="Path 27866" d="M0,9A9.052,9.052,0,0,1,8.994,0,9.063,9.063,0,0,1,18,9a9.057,9.057,0,0,1-9,9A9.053,9.053,0,0,1,0,9m5.548.751H8.237v2.695a.754.754,0,0,0,1.508,0V9.751h2.695a.754.754,0,1,0,0-1.508H9.745V5.554a.754.754,0,1,0-1.508,0V8.242H5.548a.754.754,0,1,0,0,1.508" transform="translate(0 0)" fill="#2ba289" />
      </g>
    </svg>
  );
};
