import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../domain/core/ViewModel';
import { MapPathModel } from '../../../../../domain/model/MapPathModel';
import { MapPathTypeModel } from '../../../../../domain/model/MapPathType';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { transient } from '../../../../../inversify/decorator';
import { FEATURE } from '../../../../../shared/enum';
import { MapDistrictsVm } from '../../../MapDistrictsVm';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import { I18nService } from '../../../../../domain/service/I18nService';

export enum MapPathTabType {
  TYPE = 'paths:tab_type',
  PATH = 'paths:tab_path',
  ATTRIBUTED_TO = 'paths:tab_attributed_to',
}

export interface IMapPathTab {
  type: MapPathTabType;
}

export interface ViewMapPathProps {
  mapPath: MapPathModel;
  types: MapPathTypeModel[];
  onClose: () => void;
  onDelete: (path: MapPathModel) => void;
  upsertPath: (path: MapPathModel) => void;
  onSave: AsyncTask<() => Promise<boolean>>;
  onTabChange: (tab: MapPathTabType) => void;
  districtsVm: MapDistrictsVm;
}

@transient()
export class ViewMapPathVm extends ViewModel<ViewMapPathProps> {

  @observable
  public tabs: IMapPathTab[] = [{
    type: MapPathTabType.TYPE,
  }, {
    type: MapPathTabType.PATH,
  }, {
    type: MapPathTabType.ATTRIBUTED_TO,
  }];

  @observable
  public currentTab: IMapPathTab = this.tabs[0];

  constructor(
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(NotificationService) public readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get canBeSaved() {
    return this.props.mapPath.typeId
      && this.props.mapPath.title
      && this.props.mapPath.mesh.points.length >= 2
      && this.canEditAndDeleteMapPath;
  }

  @computed
  public get canEditAndDeleteMapPath(): boolean {
    return this.props.mapPath.owner?.id === this.session.currentUser?.id
      ? true
      : Boolean(this.props.districtsVm.selectedDistrict?.canEditAndDeleteSubzone);
  }

  @computed
  public get hasNextTab() {
    return this.currentTab.type !== MapPathTabType.PATH;
  }

  @computed
  public get hasProPaths() {
    return this.session.hasFeatureEnabled(FEATURE.MAP_PATH);
  }

  @action
  public nextOrSave = () => {
    if (!this.hasNextTab) {
      return this.props.onSave.run();
    }

    const index = this.tabs.findIndex((t) => t.type === this.currentTab.type);
    if (index !== -1) {
      this.setCurrentTab(this.tabs[index + 1]);
    }
  }

  @computed
  public get backgroundColor() {
    return this.props.mapPath.color;
  }

  @action
  public setCurrentTab = (tab: IMapPathTab) => {
    if (!this.canEditAndDeleteMapPath && tab.type === MapPathTabType.PATH) {
      return this.notification.info(this.i18n.t('paths:wrong_permission'));
    }
    this.currentTab = tab;
    this.props.onTabChange(tab.type);
  }
}
