import { makeObservable, observable } from 'mobx';
import { AmmunitionCaliberResponseDto } from '../../shared/dto/ammoCaliber.response.dto';

export class AmmunitionCaliberModel {

  @observable
  public id: string | null = null;

  @observable
  public name: string;

  constructor() {
    makeObservable(this);
  }

  public static fromDto(dto: AmmunitionCaliberResponseDto) {
    const model = new AmmunitionCaliberModel();

    model.id = dto.id;
    model.name = dto.name;

    return model;
  }
}
