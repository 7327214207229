import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import PrintIcon from '@mui/icons-material/Print';
import { Button, ButtonProps, Typography } from '@mui/material';

import { useContainer } from '../../../domain/hooks/useContainer';
import { TrackingEvent } from '../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { colors } from '../../../theme/options/palette/const/colors';
import { PrintMapVm } from './PrintMapVm';

interface PrintMapButtonProps extends ButtonProps {
  vm: PrintMapVm;
}

export function PrintMapButton(props: PrintMapButtonProps) {

  const { t } = useTranslation();
  const session = useContainer().get(SessionStore);
  const tracking = useContainer().get(TrackingService);

  const handleOpenPrintMap = useCallback(() => {
    props.vm.toggleIsPrintMapActive();
    tracking.track(TrackingEvent.PRINT_MAP_VIEW);
  }, [tracking, props.vm]);

  return (
    <Button
      onClick={handleOpenPrintMap}
      startIcon={<PrintIcon />}
      sx={{
        position: 'relative',
        backgroundColor: props.vm.isPrintMapActive ? colors.green[800] : 'white',
        borderWidth: props.vm.isPrintMapActive ? 2 : undefined,
        borderColor: props.vm.isPrintMapActive ? colors.grapple : undefined,
        borderStyle: props.vm.isPrintMapActive ? 'solid' : undefined,
        paddingX: 4,
        paddingY: 1,
        '&:hover': {
          backgroundColor: !props.vm.isPrintMapActive ? 'white' : undefined,
        }
      }}
      variant={props.vm.isPrintMapActive ? 'contained' : 'outlined'}
      color={props.vm.isPrintMapActive ? 'secondary' : 'primary'}
      disabled={!session.isProUser}
    >
      <Typography fontWeight={'500'}>{t('print_preview:preview_button')}</Typography>
    </Button>
  );
}
