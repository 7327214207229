import { BreakpointsOptions } from '@mui/material';

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 1024,
    lg: 1280,
    xl: 1920,
    mobile: 0,
    desktop: 1024,
  }
};
