import { IsLatitude, IsLongitude, IsOptional } from 'class-validator';

import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';

export class GeoLocationRequestDto {

  @IsLatitude()
  @ApiProperty()
  public latitude: number;

  @IsLongitude()
  @ApiProperty()
  public longitude: number;

  @IsOptional()
  @ApiPropertyOptional()
  public accuracy?: number;
}
