import { LngLatLike } from 'mapbox-gl';

import * as turf from '@turf/turf';

import { IGeoLocation } from '../domain/core/IGeoLocation';
import { MeshModel } from '../domain/model/MeshModel';
import { IMapBounds } from '../modules/map/components/google-map/GoogleMapVm';

export class GeoUtil {

  public static getBounds(mesh: IGeoLocation[]) {

    const latitude = mesh.map((m) => m.longitude);
    const longitude = mesh.map((m) => m.latitude);

    const north = Math.max(...latitude);
    const south = Math.min(...latitude);
    const west = Math.max(...longitude);
    const east = Math.min(...longitude);

    return new google.maps.LatLngBounds(
      { lat: west, lng: south },
      { lat: east, lng: north },
    );
  }

  public static getMapboxBounds(mesh: IGeoLocation[]): [LngLatLike, LngLatLike] {

    const latitude = mesh.map((m) => m.longitude);
    const longitude = mesh.map((m) => m.latitude);

    const north = Math.max(...latitude);
    const south = Math.min(...latitude);
    const west = Math.max(...longitude);
    const east = Math.min(...longitude);

    return [[north, east], [south, west]];
  }

  public static getPointIndexInPath(point: IGeoLocation, path: IGeoLocation[]) {
    let ctr = 0;
    const point1 = Math.trunc(point.longitude * 10000);
    const point2 = Math.trunc(point.latitude * 10000);

    for (const pointInPath of path) {

      const pointInPath1 = Math.trunc(pointInPath.longitude * 10000);
      const pointInPath2 = Math.trunc(pointInPath.latitude * 10000);

      if (point &&
        point1 === pointInPath1 &&
        point2 === pointInPath2) {
        return ctr;
      }
      ctr += 1;
    }
    return -1;
  }

  public static boundsChangedBeyondOffset = (currentBounds: IMapBounds, newBounds: IMapBounds) => {
    if (!newBounds.northEast || !newBounds.southEast || !newBounds.northWest || !newBounds.southWest ||
      !currentBounds.northEast || !currentBounds.southEast || !currentBounds.northWest || !currentBounds.southWest) {
      return false;
    }

    //* Use haversineDistance to calculate the distance between two LatLng points
    const distanceNE = this.haversineDistance(this.convertLatLngLiteralToGeoLocation(currentBounds.northEast), this.convertLatLngLiteralToGeoLocation(newBounds.northEast));
    const distanceSE = this.haversineDistance(this.convertLatLngLiteralToGeoLocation(currentBounds.southEast), this.convertLatLngLiteralToGeoLocation(newBounds.southEast));
    const distanceNW = this.haversineDistance(this.convertLatLngLiteralToGeoLocation(currentBounds.northWest), this.convertLatLngLiteralToGeoLocation(newBounds.northWest));
    const distanceSW = this.haversineDistance(this.convertLatLngLiteralToGeoLocation(currentBounds.southWest), this.convertLatLngLiteralToGeoLocation(newBounds.southWest));

    /**
     * Check if any distance is greater than 40km
     * This distance of 40km is related with @see GoogleMapVm.calculateBounds
    */
    const isBeyondOffset = distanceNE > 40 || distanceSE > 40 || distanceNW > 40 || distanceSW > 40;

    return isBeyondOffset;
  }

  private static convertLatLngLiteralToGeoLocation = (latLng: google.maps.LatLngLiteral): IGeoLocation => {
    return {
      latitude: latLng.lat,
      longitude: latLng.lng,
    };
  };

  /**
 * Calculates the haversine distance between point A, and B.
 * @param {number[]} latlngA [lat, lng] point A
 * @param {number[]} latlngB [lat, lng] point B
 * @param {boolean} isMiles If we are using miles, else km.
 */
  public static haversineDistance = (latlngA: IGeoLocation, latlngB: IGeoLocation, isMiles?: boolean) => {
    const R = 6371; // km

    const dLat = this.toRadians(latlngB.latitude - latlngA.latitude);
    const dLatSin = Math.sin(dLat / 2);
    const dLon = this.toRadians(latlngB.longitude - latlngA.longitude);
    const dLonSin = Math.sin(dLon / 2);

    const a = (dLatSin * dLatSin) +
      (Math.cos(this.toRadians(latlngA.latitude)) * Math.cos(this.toRadians(latlngB.latitude)) * dLonSin * dLonSin);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    let distance = R * c;

    if (isMiles) { distance /= 1.60934; }

    return distance;
  }

  public static toRadians = (x: number) => {
    return (x * Math.PI) / 180;
  }

  public static getClosestPointPair = (point: IGeoLocation, mesh: IGeoLocation[]): [IGeoLocation, IGeoLocation] => {
    let minimalDistance = Infinity;
    let closestPair: [IGeoLocation, IGeoLocation] = [{ latitude: 0, longitude: 0 }, { latitude: 0, longitude: 0 }];

    for (let i = 0; i < mesh.length - 1; i += 1) {
      const dist = this.getDistanceFromPointToLine(point, mesh[i], mesh[i + 1]);
      if (dist < minimalDistance) {
        minimalDistance = dist;
        closestPair = [mesh[i], mesh[i + 1]];
      }
    }

    return closestPair;
  };

  public static getDistanceFromPointToLine = (point: IGeoLocation, linePoint1: IGeoLocation, linePoint2: IGeoLocation) => {

    const A = point.longitude - linePoint1.longitude;
    const B = point.latitude - linePoint1.latitude;
    const C = linePoint2.longitude - linePoint1.longitude;
    const D = linePoint2.latitude - linePoint1.latitude;

    const dot = A * C + B * D;
    const lenSq = C * C + D * D;
    let param = -1;
    if (lenSq !== 0) {
      param = dot / lenSq;
    }

    let xx;
    let yy;

    if (param < 0) {
      xx = linePoint1.longitude;
      yy = linePoint1.latitude;
    } else if (param > 1) {
      xx = linePoint2.longitude;
      yy = linePoint2.latitude;
    } else {
      xx = linePoint1.longitude + param * C;
      yy = linePoint1.latitude + param * D;
    }

    const dx = point.longitude - xx;
    const dy = point.latitude - yy;
    return Math.sqrt(dx * dx + dy * dy);
  };

  public static isSamePoint = (p1: IGeoLocation, p2: IGeoLocation): boolean => {
    return p1.latitude === p2.latitude && p2.longitude === p2.longitude;
  }

  public static isPointInMesh = (point: IGeoLocation, mesh: IGeoLocation[]): boolean => {
    const samePoint = mesh.find((p) => p.latitude === point.latitude && p.longitude === point.longitude);
    if (samePoint) {
      return true;
    }

    if (mesh && point && mesh.length > 2) {
      return this.isPointInPolygon(point, mesh);
    }

    return false;
  };

  public static isPointInPolygon = (point: IGeoLocation, mesh: IGeoLocation[]) => {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

    const x = point.longitude;
    const y = point.latitude;

    let inside = false;
    for (let i = 0, j = mesh.length - 1; i < mesh.length; j = i++) {
      const xi = mesh[i].longitude;
      const yi = mesh[i].latitude;

      const xj = mesh[j].longitude;
      const yj = mesh[j].latitude;

      const intersect = ((yi > y) !== (yj > y))
        && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) {
        inside = !inside;
      }
    }

    return inside;
  };

  public static extractCenterForMesh = (mesh: MeshModel): IGeoLocation => {
    const meshPoints = mesh.points.map((mp) => [mp.longitude, mp.latitude]);
    const center = turf.center(turf.points(meshPoints));

    return {
      latitude: center.geometry.coordinates[1],
      longitude: center.geometry.coordinates[0],
    };
  }

}
