import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid, Typography } from '@mui/material';

import { useVm } from '../../../../../../domain/hooks/useVm';
import { FitContainerLoadingFallback } from '../../../../../../FitContainerLoadingFallback';
import { colors } from '../../../../../../theme/options/palette/const/colors';
import { InfoComponent } from '../../../../../../toolkit/components/InfoComponent';
import { SubmitButton } from '../../../../../../toolkit/components/SubmitButton';
import { CheckinLineItem } from './CheckinLineItem';
import { ViewCheckInsVm } from './ViewCheckInsVm';

export interface ViewCheckInsProps {
  poiId: string;
}

export const ViewCheckIns = observer(function ViewCheckIns(props: ViewCheckInsProps) {
  const vm = useVm(ViewCheckInsVm, props);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      {vm.getPoiCheckinHistory.isBusy ? <FitContainerLoadingFallback /> :
        (vm.poiCheckInHistory.length !== 0
          ? <Box height="100%" display={'flex'} flexDirection={'column'}>
            <Grid container py={2} px={{ xs: 3, sm: 5 }} rowSpacing={2}>
              <Grid item container>
                {vm.currentlyCheckedInUsers.length > 0 && <>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1' color={colors.blueGrey[200]} fontWeight={'bold'}>{t('check_ins:currently_checked_in')}</Typography>
                  </Grid>
                  <Grid item container rowGap={1}>
                    {vm.currentlyCheckedInUsers.map(poiCheckin => <CheckinLineItem poiCheckin={poiCheckin} key={poiCheckin.id} />)}
                  </Grid>
                </>}
              </Grid>
              {vm.currentlyCheckedInUsers.length > 0 && <Grid item xs={12}>
                <Divider />
              </Grid>}
              <Grid item container>
                {vm.previouslyCheckedInUsers.length > 0 && <>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1' color={colors.blueGrey[200]} fontWeight={'bold'}>{t('check_ins:previously_checked_in')}</Typography>
                  </Grid>
                  <Grid item container rowGap={1}>
                    {vm.previouslyCheckedInUsers.map(poiCheckin => <CheckinLineItem poiCheckin={poiCheckin} key={poiCheckin.id} />)}
                  </Grid>
                </>}
              </Grid>
            </Grid>
            <Box width={'100%'} display="flex" justifyContent={'center'} mt={5}>
              <SubmitButton sx={{ paddingX: 8 }} onClick={vm.onDownloadClick}>
                {t('check_ins:button.export_checkin_list')}
              </SubmitButton>
            </Box>
          </Box>
          : <InfoComponent locizePath="check_ins:checkin_readonly" />
        )
      }
    </React.Fragment>
  );
});
