import React from 'react';

interface PaginationResult<T> {
  page: number;
  rowsPerPage: number;
  handleChangePage: (_event: unknown, newPage: number) => void;
  handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  emptyRows: (itemsLength: number) => number;
  visibleRows: (items: T[]) => T[];
}

export const useTablePagination = <T,>(initialRowsPerPage = 5): PaginationResult<T> => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = (itemsLength: number) => page > 0 ? Math.max(0, (1 + page) * rowsPerPage - itemsLength) : 0;

  const visibleRows = (items: T[]) => items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, emptyRows, visibleRows };
};
