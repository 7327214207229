import { inject } from 'inversify';
import { action, makeObservable } from 'mobx';
import { NavigateFunction } from 'react-router';

import { ViewModel } from '../../domain/core/ViewModel';
import { I18nService } from '../../domain/service/I18nService';
import { NotificationService } from '../../domain/service/NotificationService';
import { SessionStore } from '../../domain/store/SessionStore';
import { singleton } from '../../inversify/decorator';
import { Types } from '../../inversify/types';
import { AppRoutes } from '../../router/AppRoutesEnum';

@singleton()
export class SidebarMenuVm extends ViewModel {

  constructor(
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(Types.Navigate) public readonly navigate: NavigateFunction
  ) {
    super();
    makeObservable(this);
  }

  @action
  public logout = async () => {
    try {
      const result = await this.session.logout();
      if (result.ok) {
        return this.navigate(AppRoutes.Login);
      }

      this.notification.error(this.i18n.t('auth:logout_error'));
    } catch (e) {
      console.error(`exception while doing logout. ${e}`);
      this.notification.error(this.i18n.t('auth:logout_error'));
    }
  };
}
