import { SCREENSHOT_ORIENTATION_ENUM } from '../../../../shared/enum/screenshot.orientation.enum';

export interface ElementDimensions {
  width: number;
  height: number;
}

export const resizerElementMinDimensions: Record<SCREENSHOT_ORIENTATION_ENUM, ElementDimensions> = {
  [SCREENSHOT_ORIENTATION_ENUM.PORTRAIT]: {
    width: 376,
    height: 531
  },
  [SCREENSHOT_ORIENTATION_ENUM.LANDSCAPE]: {
    width: 496,
    height: 350
  },
};

export const resizerElementDefaults: Record<SCREENSHOT_ORIENTATION_ENUM, ElementDimensions> = {
  [SCREENSHOT_ORIENTATION_ENUM.PORTRAIT]: {
    width: 496,
    height: 701
  },
  [SCREENSHOT_ORIENTATION_ENUM.LANDSCAPE]: {
    width: 496,
    height: 350
  },
};

/**
 * The aspect ratio of the A-series paper sizes is constant and equal to the square root of two (1.4142) for all paper sizes.
 * This means that if the longer side of an A-series paper size is divided by the shorter side, the result will always be 1.4142 (rounded to four decimal places).
 */
export const aspectRatio = Math.sqrt(2);
