import { ApiProperty } from '@nestjs/swagger';
import { AMMUNITION_WEAPON_TYPE } from '../enum/ammunitionWeapon.enum';

export class GearManufacturerResponseDto {
  @ApiProperty()
  public id: string;

  @ApiProperty({description: 'weapon/ammunition manufacterer company name'})
  public name: string;

  @ApiProperty({description: 'ammunition/weapon type'})
  public type: AMMUNITION_WEAPON_TYPE;
}
