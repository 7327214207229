import { Button, Checkbox, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  iconUrl?: string;
  icon?: React.ReactNode;
  title: string;
  checked: boolean;
  onClick: () => void;
  onSelectOnlyThis: () => void;
}

export const FilterListItem = observer(function FilterListItem(props: IProps) {
  const { t } = useTranslation();
  const [showingOnly, setShowingOnly] = useState(false);

  const selectOnlyThis = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    props.onSelectOnlyThis();
  }, [props]);

  return (
    <ListItemButton
      onMouseOver={() => setShowingOnly(true)}
      onMouseOut={() => setShowingOnly(false)}
      onClick={props.onClick}
      disableGutters
      divider>
      <ListItemAvatar>
        {props.iconUrl
          ? <img style={{ width: '35px' }} src={props.iconUrl} />
          : props.icon
        }
      </ListItemAvatar>
      <ListItemText primary={props.title} />
      <Button onClick={selectOnlyThis} sx={{
        visibility: showingOnly ? 'visible' : 'hidden',
      }}>{t('filter:select_only_this')}</Button>
      <Checkbox checked={props.checked} />
    </ListItemButton>
  );
});
