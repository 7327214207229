import { ApiProperty } from '@nestjs/swagger';

import { PhotoResponseDto } from './photo.response.dto';
import { UserResponseDto } from './user.response.dto';
import { WeightsEntryResponseDto } from './weights.entry.response.dto';

export class DeprecatedEntryResponseDto {

  @ApiProperty()
  public id: string;

  @ApiProperty()
  public isPrivate: boolean;

  @ApiProperty()
  public type: string;

  @ApiProperty()
  public lat: number | null;

  @ApiProperty()
  public long: number | null;

  @ApiProperty()
  public meta: any;

  @ApiProperty()
  public classification: string;

  @ApiProperty()
  public fallwildType: string | null;

  @ApiProperty()
  public count: number;

  @ApiProperty({ type: () => UserResponseDto })
  public owner: UserResponseDto;

  @ApiProperty()
  public districtId?: string;

  @ApiProperty({ type: () => PhotoResponseDto })
  public photo?: PhotoResponseDto;

  @ApiProperty({ type: () => PhotoResponseDto, isArray: true })
  public photos: PhotoResponseDto[];

  @ApiProperty()
  public description?: string;

  @ApiProperty()
  public userDate: string; // ISO string

  @ApiProperty()
  public createdAt: string; // ISO string

  @ApiProperty()
  public updateAt?: string; // ISO string

  @ApiProperty()
  public specificAge?: number | null;

  @ApiProperty()
  public weights: WeightsEntryResponseDto[];
}
