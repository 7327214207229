import { inject } from 'inversify';
import {
  action, computed, IReactionDisposer, makeObservable, observable, reaction, runInAction
} from 'mobx';

import { AsyncTask } from '../../domain/async/AsyncTask';
import { ViewModel } from '../../domain/core/ViewModel';
import { DistrictModel } from '../../domain/model/DistrictModel';
import { MeshModel } from '../../domain/model/MeshModel';
import { SubzoneModel } from '../../domain/model/SubzoneModel';
import { SubzoneProxy } from '../../domain/proxy/SubzoneProxy';
import { I18nService } from '../../domain/service/I18nService';
import { NotificationService } from '../../domain/service/NotificationService';
import { TrackingEvent } from '../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../domain/store/SessionStore';
import { transient } from '../../inversify/decorator';
import { SubzonePostRequestDto } from '../../shared/dto/subzone/subzone.post.request.dto';
import { IGeoLocation } from '../../shared/interfaces/IGeoLocation';
import { GeoUtil } from '../../util/GeoUtil';
import { GoogleMapVm } from './components/google-map/GoogleMapVm';
import { SubzoneTabType } from './components/subzone/view-subzone/ViewSubzoneVm';

@transient()
export class MapSubzonesVm extends ViewModel {

  // * for displaying on a map in a current bounds
  @observable
  private subzonesInMapBounds: SubzoneModel[] = [];

  // * for district view sidebar
  @observable
  public selectedDistrictSubzones: SubzoneModel[] = [];

  @observable
  public currentSubzone: SubzoneModel | null = null;

  @observable
  public lastTab: SubzoneTabType | null = null;

  @observable
  public subzoneTmpCircleLocation: IGeoLocation | null = null;

  private subzoneTmpCircleDisposer: IReactionDisposer | null = null;

  constructor(
    @inject(SubzoneProxy) private readonly subzoneProxy: SubzoneProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(SessionStore) private readonly sessionStore: SessionStore,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(GoogleMapVm) private readonly googleMapVm: GoogleMapVm,
  ) {
    super();
    makeObservable(this);
  }

  public override async onInit() {
    this.subzoneTmpCircleDisposer = reaction(() => this.googleMapVm.lastMouseLocation, (location) => {
      if (this.subzoneTmpCircleLocation && location && GeoUtil.haversineDistance(location, this.subzoneTmpCircleLocation) > this.tmpCircleDisappearDistance) {
        runInAction(() => {
          this.subzoneTmpCircleLocation = null;
        });
      }
    });
  }

  private metersPerPixel(latitude: number, zoomLevel: number): number {
    return (156543.03392 * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoomLevel));
  }

  @computed
  private get tmpCircleDisappearDistance() {
    if (!this.googleMapVm.lastMouseLocation || !this.googleMapVm.lastZoomLevel) {
      return 0.05;
    }

    // after n px hide the circle
    const disappearDistanceInPx = 3;
    return (
      this.metersPerPixel(
        this.googleMapVm.lastMouseLocation.latitude, this.googleMapVm.lastZoomLevel
      ) * disappearDistanceInPx
    ) / 1000;
  }

  public override async onDestroy() {
    this.subzoneTmpCircleDisposer?.();
    this.subzoneTmpCircleDisposer = null;
  }

  @computed
  public get subzones() {
    if (this.currentSubzone) {
      return this.subzonesInMapBounds
        .filter((s) => s.id !== this.currentSubzone?.id)
        .concat(this.currentSubzone);
    }

    return this.subzonesInMapBounds;
  }

  @computed
  public get creatingNewSubzone() {
    return this.currentSubzone && !this.currentSubzone.id;
  }

  @action
  public setSubzoneTmpCircle = (location: IGeoLocation | null) => {
    if (this.lastTab !== SubzoneTabType.BORDERS) {
      return;
    }

    this.subzoneTmpCircleLocation = location;
  }

  @action
  public mapClick = (location: IGeoLocation, district: DistrictModel, forceInsert: boolean = false) => {
    if (!this.currentSubzone) {
      return;
    }

    if (this.lastTab !== SubzoneTabType.BORDERS) {
      return;
    }

    if (!GeoUtil.isPointInMesh(location, district.mesh.points) && !forceInsert) {
      return;
    }

    this.currentSubzone.districtId = district.realId;
    this.currentSubzone.mesh.addMeshPoint(location);
  }

  @action
  public showSubzone = (subzone: SubzoneModel) => {
    this.currentSubzone = subzone.clone();
    this.currentSubzone.mesh.openMesh();
    this.subzoneTmpCircleLocation = null;
  }

  @action
  public closeSubzone = () => {
    this.currentSubzone?.mesh.closeMesh();
    this.currentSubzone = null;
    this.lastTab = null;
  }

  @action
  public startNewMapSubzone = async (district: DistrictModel) => {
    if (this.sessionStore.isProUser) {
      await this.tracking.track(TrackingEvent.ZONE_CREATION_STARTED);
    }

    this.currentSubzone = new SubzoneModel();
    this.currentSubzone.districtId = district.realId;
    this.currentSubzone.owner = this.sessionStore.currentUser!;
    this.currentSubzone.mesh = new MeshModel([]);
    this.subzoneTmpCircleLocation = null;
  }

  @action
  public setSelectedDistrictSubzones = (subzones: SubzoneModel[]) => {
    // * remove duplicates based on the 'id' property
    this.selectedDistrictSubzones = Array.from(new Map(subzones.map(subzone => [subzone.id, subzone])).values());
  }

  @action
  public setSubzonesInMapBounds = (subzones: SubzoneModel[]) => {
    this.subzonesInMapBounds = subzones;
  }

  @action
  public upsert = (subzone: SubzoneModel) => {
    this.updateArraySubzone(this.subzonesInMapBounds, subzone);
    this.updateArraySubzone(this.selectedDistrictSubzones, subzone);
  }

  @action
  public setTab = (tab: SubzoneTabType) => {
    this.lastTab = tab;
  }

  @action
  public deleteSubzoneId = (id: string) => {
    this.selectedDistrictSubzones = this.selectedDistrictSubzones.filter((s) => s.id !== id);
    this.subzonesInMapBounds = this.subzonesInMapBounds.filter((s) => s.id !== id);
  }

  private updateArraySubzone = (array: SubzoneModel[], entry: SubzoneModel) => {
    const index = array.findIndex((el: SubzoneModel) => el.id === entry.id);

    if (index === -1) {
      array.unshift(entry);
    } else {
      array.splice(index, 1, entry);
    }
  }

  // * Fetch subzones within the specified map bounds, disregarding district boundaries.
  public getSubzonesInMapBounds = async (districtsInMapBounds: DistrictModel[]) => {
    const subzonesInMapBounds = districtsInMapBounds.filter(d => d.subzones.length).map(d => d.subzones).flat();
    this.setSubzonesInMapBounds(subzonesInMapBounds);
  }

  public getDistrictSubzones = new AsyncTask(async (district: DistrictModel) => {
    try {
      this.setSelectedDistrictSubzones([]);

      if (!this.sessionStore.isProUser) {
        return;
      }

      // subzones are not supported on a worldmap
      if (district.isWorldMap) {
        return;
      }

      if (district.isUnsaved) {
        return;
      }

      const result = await this.subzoneProxy.getSubzones(district.id);
      if (result.ok) {
        return this.setSelectedDistrictSubzones(result.data);
      }

      console.warn(`error while loading district subzones. ${result.status}`);
      this.notification.warning(this.i18n.t('subzone:subzones_loading_error'));
    } catch (e) {
      console.error(`exception while loading district subzones. ${e}`);
      this.notification.error(this.i18n.t('subzone:subzones_loading_error'));
    }
  })

  public save = async (): Promise<boolean> => {
    try {
      if (!this.currentSubzone) {
        return false;
      }

      this.currentSubzone.mesh.closeMesh();
      const result = this.currentSubzone.id
        ? await this.subzoneProxy.updateSubzone(this.currentSubzone.toDto())
        : await this.subzoneProxy.createSubzone(this.currentSubzone.toDto() as SubzonePostRequestDto);

      if (result.ok) {
        this.notification.success(this.i18n.t('subzone:subzone_saved'));
        await this.tracking.track(TrackingEvent.ZONE_CREATION_COMPLETED);

        this.upsert(result.data);
        runInAction(() => {
          this.currentSubzone = null;
          this.lastTab = null;
        });

        return true;
      }

      this.currentSubzone.mesh.openMesh();
      this.notification.error(this.i18n.t('subzone:save_error'));
      return false;
    } catch (e) {
      this.currentSubzone?.mesh.openMesh();
      console.error(`error while updating user. ${e}`);
      this.notification.error(this.i18n.t('subzone:save_error'));
      return false;
    }
  }

  public delete = async (subzone: SubzoneModel) => {
    try {
      const result = await this.subzoneProxy.deleteSubzone(subzone.id ?? '');
      if (result.ok) {
        this.deleteSubzoneId(subzone.id);
        return this.notification.success(this.i18n.t('subzone:delete.success'));
      }

      this.notification.error(this.i18n.t('subzone:error_while_deleting'));
    } catch (e) {
      console.error(e);
      this.notification.error(this.i18n.t('subzone:error_while_deleting'));
    }
  }

}
