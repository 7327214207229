import React from 'react';

interface ErrorFallbackProps {
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
}

export const ErrorFallback: React.ComponentType<ErrorFallbackProps> | undefined = () => {
  return (
    <div style={{ padding: 20 }}>
      <h1>⚠️ Error ⚠️</h1>
      <p><strong>Uh oh, something went wrong!</strong></p>
    </div>
  );
};
