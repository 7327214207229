import React from 'react';

import { Box, Container, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';

import Logo from '../../assets/img/App_Icon.png';
import { oldDesignTheme } from '../../theme/oldDesignTheme';

export const ResponsiveContainerGrid: React.FC = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const isSmallScreen = useMediaQuery('(max-width: 601px)');

  const redirect = (): void => {
    window.open('https://www.hunterco.de/');
  };

  const logoSize = isSmallScreen ? '5rem' : '7rem';
  const logoTop = isSmallScreen ? '-3.1rem' : '-4.3rem';

  return (
    <Grid
      container
      height={'90%'}
      direction="column"
      alignItems="center"
      justifyContent={{ xs: 'flex-start', sm: 'center' }}
      sx={{
        // minHeight: 'calc(100vh - 64px)',
        position: 'relative',
      }}
    >
      <Box position="relative" display="flex" justifyContent="center" alignItems="center">
        <Grid item>
          <Box
            onClick={redirect}
            sx={{
              width: logoSize,
              position: 'absolute',
              top: logoTop,
              left: '6%',
              backgroundColor: 'transparent',
              cursor: 'pointer',
            }}
          >
            <img src={Logo} style={{ objectFit: 'cover', width: '100%' }} alt="Logo" />
          </Box>
        </Grid>
        <Grid item>
          {/**
           * children! because of: https://github.com/mui-org/material-ui/issues/21711  */}
          <Container
            sx={{
              backgroundColor: oldDesignTheme.palette.background.default,
              borderRadius: '10px',
              minHeight: '370px',
              height: 'auto',
              width: '600px',
              [oldDesignTheme.breakpoints.down('sm')]: {
                width: 350,
              },
              padding: { xs: '20px 32px 35px', sm: '40px 32px 35px' },
            }}
          >
            {children!}
          </Container>
        </Grid>
      </Box>
    </Grid>
  );
};
