import { ApiProperty } from '@nestjs/swagger';

export class RoleResponseDto {

  @ApiProperty()
  public name: string;

  @ApiProperty()
  public rights: string[];
}
