import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router';

import { ViewModel } from '../../../../domain/core/ViewModel';
import {
  appendToppleGoalTagForPROConversionPixel
} from '../../../../domain/service/tracking/ToppleTrackingUtil';
import { TrackingEvent } from '../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../../../domain/store/SessionStore';
import { transient } from '../../../../inversify/decorator';
import { Types } from '../../../../inversify/types';
import { AppRoutes } from '../../../../router/AppRoutesEnum';

const ENABLE_BTN_IN_SECONDS = 10;

@transient()
export class SuccessfulPaymentRouteVm extends ViewModel {

  @observable
  public isDisabled: boolean = true;

  @observable
  public seconds: number = ENABLE_BTN_IN_SECONDS;

  @observable
  public timer: ReturnType<typeof setInterval>;

  constructor(
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
    @inject(SessionStore) public readonly sessionStore: SessionStore,
    @inject(TrackingService) public readonly tracking: TrackingService,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = async () => {
    this.tracking.startTracking();
    appendToppleGoalTagForPROConversionPixel();
    await this.tracking.track(TrackingEvent.SUBSCRIPTION_COMPLETED);
    this.enableFunction();
  }

  @computed
  public get isGroupOwner(): boolean {
    if (!this.sessionStore.session) return false;
    return !!(this.sessionStore.session.user.id === this.sessionStore.session.groupSubscriptionOwner?.id);
  }

  @action
  public setIsDisabled = (isDisabled: boolean) => {
    this.isDisabled = isDisabled;
  }

  public enableFunction = () => {
    setTimeout(() => this.setIsDisabled(false), ENABLE_BTN_IN_SECONDS * 1000);
  };

  public handleClick = () => {
    this.sessionStore.recoverSession.run();

    if (!this.sessionStore.isAuthenticated) {
      return this.navigate(AppRoutes.Login);
    }

    this.navigate(AppRoutes.UserProfile);
  };

  public resetTimer = (): void => {
    clearInterval(this.timer);
  }

  public startTimer = (): void => {
    if (this.seconds > 0) {
      runInAction(() => {
        this.timer = setInterval(() => {
          runInAction(() => {
            this.seconds = this.seconds > 0 ? this.seconds - 1 : 0;
          });
        }, 1000);
      });
    }
  }

}
