import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Grid, Typography } from '@mui/material';

import { Assets } from '../../../assets';
import { useContainer } from '../../../domain/hooks/useContainer';
import { DistrictModel } from '../../../domain/model/DistrictModel';
import { TrackingEvent } from '../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../domain/service/tracking/TrackingService';
import { AppRoutes } from '../../../router/AppRoutesEnum';
import { colors } from '../../../theme/options/palette/const/colors';
import { SubmitButton } from '../../../toolkit/components/SubmitButton';
import { PinSizeContext } from '../PinSizeContext';
import { PaperOrientationTabs } from './components/PaperOrientationTabs';
import { PaperSizeEnum, PaperSizeTabs } from './components/PaperSizeTabs';
import { PrintMapVm } from './PrintMapVm';

export interface PrintMapModalProps {
  printMapVm: PrintMapVm;
  selectedDistrict: DistrictModel | null;
}

export const PrintMapModal = observer(function PrintMapModal({ printMapVm, selectedDistrict: selectedDistrict }: PrintMapModalProps) {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const tracking = useContainer().get(TrackingService);
  const pinSizeContext = useContext(PinSizeContext);

  const handleClickOnGeneratePDF = useCallback(() => {
    if (printMapVm.session.isProUser) {
      tracking.track(TrackingEvent.PRINT_MAP_STARTED);
      return printMapVm.onGeneratePDF.run(selectedDistrict);
    }

    tracking.track(TrackingEvent.FREE_PRINT_MAP_STARTED);
    return navigate(AppRoutes.Pricing);
  }, [selectedDistrict, navigate, printMapVm.onGeneratePDF, printMapVm.session.isProUser, tracking]);

  const handleOnClose = useCallback(() => {
    printMapVm.toggleIsPrintMapActive();
    pinSizeContext.setPaperSize(PaperSizeEnum.A4);
  }, [pinSizeContext, printMapVm]);

  const isFirefox = useMemo(() => navigator.userAgent.indexOf('Firefox') != -1, []);
  const isSafari = useMemo(() => navigator.userAgent.indexOf('Safari') != -1, []);
  const isChrome = useMemo(() => navigator.userAgent.indexOf('Chrome') != -1, []);

  return (
    <Box sx={{
      bgcolor: colors.paper,
      borderRadius: 1,
      position: 'absolute',
      top: 0,
      right: 30,
      marginTop: 4,
      paddingBottom: 3,
      visibility: printMapVm.hideElements ? 'hidden' : 'visible',
      width: 400
    }}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} p={1}>
        <CloseIcon sx={{ cursor: 'pointer' }} onClick={handleOnClose} />
      </Box>
      <Grid container direction={'column'} alignItems={'center'} rowGap={3}>
        <Grid item>
          <Typography variant='h1' color={colors.darkFont}>{t('print_preview:modal.title')}</Typography>
        </Grid>
        <Grid item>
          <img src={Assets.map.print} />
        </Grid>

        <Grid item container direction={'column'} px={5} pb={2} rowGap={3} xs={12}>
          {/* SIZE */}
          <PaperSizeTabs vm={printMapVm} />

          {/* ORIENTATION */}
          <PaperOrientationTabs vm={printMapVm} />

          {(isFirefox || isSafari) && !isChrome && <Grid item container spacing={4}>
            <Grid item xs={1} alignSelf={'center'}><ErrorOutlineIcon htmlColor={colors.error} /></Grid>
            <Grid item xs lineHeight={0.3}>
              <Typography variant='caption' color={colors.error}>{isFirefox ? t('print_preview:firefox_issue') : t('print_preview:safari_issue')}</Typography>
            </Grid>
          </Grid>}
        </Grid>

        <Grid item>
          <SubmitButton
            submitting={printMapVm.onGeneratePDF.isBusy}
            onClick={handleClickOnGeneratePDF}
            sx={{
              pointerEvents: 'all',
              width: 135,
              position: 'relative',
            }}
          >
            <Typography variant='button' textTransform={'capitalize'}>
              {printMapVm.session.isProUser ? t('print_preview:modal.button') : t('print_preview:modal.button_free')}
            </Typography>
            {!printMapVm.session.isProUser && <img src={Assets.shield}
              width='30px'
              height='30px'
              style={{
                position: 'absolute',
                bottom: 20,
                right: -15,
              }} />}
          </SubmitButton>
        </Grid>
      </Grid>
    </Box>
  );
});
