import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend, { BackendOptions } from 'i18next-http-backend';
import LocizeBackend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { LANGUAGE } from '../../shared/enum';


const availableLanguages: LANGUAGE[] = [
  LANGUAGE.de,
  LANGUAGE.en,
  LANGUAGE.es,
  LANGUAGE.fr,
  LANGUAGE.it,
  // LANGUAGE.bg,
];

interface ITOptions { [key: string]: string | number; }

export interface ITranslatable {
  key?: string;
  raw?: string;
  params?: ITOptions;
}

interface ITransactionConfig {
  useProductionConfig?: boolean;
}

const namespaces: Array<string> = [
  'auth',
  'common',
  'countries',
  'profile',
  'links',
  'cookie',
  'payment',
  'map',
  'entry',
  'poi',
  'task',
  'diseases',
  'booking',
  'district',
  'share',
  'paths',
  'group_discount',
  'refund',
  'subzone',
  'photo',
  'upload_image',
  'filter',
  'delete_account',
  'group_subscription_modal',
  'print_preview',
  'gallery',
  'manage_group',
  'pro_blocker',
  'check_ins',
  'quota_plan',
  'copy_clipboard',
  'group_subscription'
];
@singleton()
export class I18nService {
  public readonly init = async (config: Partial<ITransactionConfig> = {}) => {
    const prodConfig = config.useProductionConfig || env.isProduction;

    const languageDetector = new LanguageDetector();
    languageDetector.init({
      order: ['localStorage', 'navigator', 'querystring'],
      lookupLocalStorage: 'i18nextLng', // This should match the key used in local storage
      caches: ['localStorage'],
    });


    i18n
      .use((!prodConfig ? LocizeBackend : HttpBackend) as typeof LocizeBackend)
      .use(languageDetector)
      .use(initReactI18next)
      .init({
        ns: namespaces,
        fallbackLng: LANGUAGE.en,
        supportedLngs: availableLanguages,
        backend: (!prodConfig ? env.locizeBackend : {
          loadPath: '/i18n/{{lng}}/{{ns}}.json',
        }) as BackendOptions,
      });

    i18n.changeLanguage();
  }

  public get language(): LANGUAGE {
    return i18n.language as LANGUAGE;
  }

  public translate(translatable: ITranslatable): string | undefined {
    if (translatable.raw) {
      return translatable.raw;
    }
    if (translatable.key) {
      return this.t(translatable.key, translatable.params);
    }
  }

  public t = i18n.t;
}
