import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React, { Ref, useCallback, useImperativeHandle } from 'react';

import {
  Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Typography
} from '@mui/material';

import { Assets } from '../../../../assets';
import { useVm } from '../../../../domain/hooks/useVm';
import { colors } from '../../../../theme/options/palette/const/colors';
import { DeleteAccountVm } from './DeleteAccountVm';

export type DeleteAccountRef = {
  open: () => void,
  close: () => void,
}

export const DeleteAccount = observer(React.forwardRef<DeleteAccountRef>(function DeleteAccount(props, ref: Ref<DeleteAccountRef>) {

  const vm = useVm(DeleteAccountVm);

  const handleCloseDialog = useCallback(() => {
    vm.toggleOpen(false);
  }, [vm]);

  useImperativeHandle(ref, () => ({
    open() {
      vm.toggleOpen(true);
    },
    close() {
      vm.toggleOpen(false);
    },
  }));

  return (
    <Dialog open={vm.open} onClose={handleCloseDialog}>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} rowGap={2} padding={2} maxWidth={450}>
        <DialogTitle variant='h1' color="#000">
          {vm.step === 0 ? t('delete_account:title_1') : t('delete_account:title_2')}
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} rowGap={3}>
            <img src={Assets.icon_delete_account} width="62px" height="70px" />
            <DialogContentText variant="subtitle1" textAlign={'center'}>{vm.step === 0 ? t('delete_account:description_1') : t('delete_account:description_2')}</DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions sx={{
          display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
        }}>
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} rowGap={2}>
            <Button
              disabled={vm.isButtonDisabled || vm.handleDeleteAccount.isBusy}
              onClick={vm.handleDeleteAccount.run}
              sx={{
                minWidth: 250,
                height: 48,
                position: 'relative',
                borderColor: vm.isButtonDisabled ? 'rgba(255, 0, 0, 0.2)' : colors.error,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderRadius: 20,
                bgcolor: vm.step ? colors.error : 'inherit',
                '&:hover': {
                  bgcolor: vm.step === 0 ? colors.error : '#c12929',
                }
              }}
            >
              <Typography variant="button" padding={'15px 50px'}
                sx={{
                  position: 'relative',
                  color: vm.step === 0
                    ? (vm.seconds ? 'rgba(255, 0, 0, 0.2)' : colors.error)
                    : colors.paper,
                  '&:hover': {
                    color: colors.paper
                  }
                }}
              >
                {vm.step === 0
                  ? (vm.seconds > 0
                    ? t('delete_account:button.disabled_delete', { seconds: vm.seconds })
                    : t('delete_account:button.delete_1')
                  )
                  : t('delete_account:button.delete_2')}
              </Typography>
              {vm.handleDeleteAccount.isBusy && (
                <CircularProgress
                  disableShrink
                  size={24}
                  sx={{
                    color: colors.primary,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12,
                  }}
                />
              )}

            </Button>
            <Button onClick={handleCloseDialog}
              sx={{
                minWidth: 250,
                height: 48,
                border: `1px solid ${colors.green[800]}`,
                borderRadius: 20,
                color: colors.green[800],
                '&:hover': {
                  bgcolor: colors.green[800],
                  color: colors.paper
                }
              }}
            >
              <Typography variant="button">{vm.step === 0 ? t('delete_account:button.cancel_1') : t('delete_account:button.cancel_2')}</Typography>
            </Button>
          </Box>
        </DialogActions>
      </Box >
    </Dialog >
  );
}));
