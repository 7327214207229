import { observer } from 'mobx-react-lite';

import { Box, ThemeProvider } from '@mui/material';

import { useQuotaPlanContext } from '../../../../../../../domain/context/QuotaPlanContext';
import { useVm } from '../../../../../../../domain/hooks/useVm';
import { DistrictModel } from '../../../../../../../domain/model/DistrictModel';
import { FitContainerLoadingFallback } from '../../../../../../../FitContainerLoadingFallback';
import { useThemeContext } from '../../../../../../../theme/ThemeContextProvider';
import { ViewDistrictQuotaPlanList } from './district-quota-plan-list/ViewDistrictQuotaPlanList';
import { ViewQuotaPlan } from './quota-plan/ViewQuotaPlan';
import {
  QuotaPlanningScreens, ViewDistrictQuotaPlanningTabVm
} from './ViewDistrictQuotaPlanningTabVm';

export interface IViewDistrictQuotaPlanningTabProps {
  district: DistrictModel;
}

export const ViewDistrictQuotaPlanningTab = observer(function ViewDistrictQuotaPlanning(props: IViewDistrictQuotaPlanningTabProps) {
  const vm = useVm(ViewDistrictQuotaPlanningTabVm, props);
  const { theme } = useThemeContext();
  const { step, currentQuotaPlan } = useQuotaPlanContext();

  if (vm.getQuotaPlans.isBusy) {
    return <FitContainerLoadingFallback />;
  }
  return (
    <ThemeProvider theme={theme}>
      <Box padding={2} height={'100%'}>
        {step === QuotaPlanningScreens.QUOTA_PLANS_LIST
          ? <ViewDistrictQuotaPlanList quotaPlans={vm.quotaPlans} districtId={props.district.id} />
          : <ViewQuotaPlan quotaPlan={currentQuotaPlan} quotaPlans={vm.quotaPlans} />
        }
      </Box>
    </ThemeProvider>
  );
});
