import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography, useMediaQuery } from '@mui/material';

import { LogoIconWhite } from '../icons/LogoIconWhite';

interface IconAndTitleProps {
  title: string;
  subtitle: string;
}

export const IconAndTitle: React.FC<IconAndTitleProps> = ({ title, subtitle }: IconAndTitleProps) => {

  const { t } = useTranslation();
  const isTabletScreen = useMediaQuery('(max-width:700px)');
  const [hideIcon, setHideIcon] = useState(false);

  useEffect(() => {
    setHideIcon(isTabletScreen);
  }, [isTabletScreen]);

  return (
    <Grid item container
      spacing={2}
      alignItems="center"
      justifyContent={isTabletScreen ? 'center' : 'flex-start'}
      marginBottom={{ xs: 4, md: 7 }}
    >
      {!hideIcon && <Grid item alignSelf="flex-end">
        <LogoIconWhite />
      </Grid>}
      <Grid item>
        <Typography sx={{ fontSize: { xs: '28px', md: '34px' }, fontWeight: 'bold' }}>{t(title)}</Typography>
        <Typography sx={{ fontSize: { xs: '28px', md: '34px' } }}>{t(subtitle)}</Typography>
      </Grid>
    </Grid>
  );
};
