import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip, Grid, IconButton, Typography } from '@mui/material';

import { Assets } from '../../../../../assets';
import { useVm } from '../../../../../domain/hooks/useVm';
import { PoiModel } from '../../../../../domain/model/PoiModel';
import { TaskModel } from '../../../../../domain/model/TaskModel';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { ImageCarousel } from '../../../../../toolkit/components/image-carousel/ImageCarousel';
import { MapDistrictsVm } from '../../../MapDistrictsVm';
import { CustomizedMenu } from '../../entry/view-entry/components/CustomizedMenu';
import { IPoiTab } from '../new-poi/NewPoiVm';
import { ViewPoiAttributedTo } from './attributed-to/ViewPoiAttributedTo';
import { ViewPoiBlocked } from './booking/ViewPoiBlocked';
import { ViewPoiBooking } from './booking/ViewPoiBooking';
import { ViewCheckIns } from './check-ins/ViewCheckIns';
import { ViewPoiInfo } from './info/ViewPoiInfo';
import { ShootingDirection } from './shooting-direction/ShootingDirection';
import { ViewTasks } from './tasks/ViewTasks';
import { PoiTab, PoiTabEnum, ViewPoiVm } from './ViewPoiVm';

export interface ViewPoiProps {
  poi: PoiModel;
  pois: PoiModel[];
  tasks: TaskModel[];
  onClose: () => void;
  districtsVm: MapDistrictsVm;
  onPoiEdit: (poi: PoiModel, tab: IPoiTab) => void;
  onPoiSave: () => void;
  onPoiDelete: (poi: PoiModel) => void;
  upsertPoi: (poi: PoiModel) => void;
}

export const ViewPoi = observer(function ViewPoi(props: ViewPoiProps) {
  const vm = useVm(ViewPoiVm, props);
  const { t } = useTranslation();

  const onPoiDelete = useCallback(() => {
    props.onPoiDelete(props.poi);
  }, [props]);

  const onPoiUpsert = useCallback(() => {
    props.upsertPoi(props.poi);
  }, [props]);

  const belongingDistrict = useMemo(() => {
    return props.districtsVm.allDistricts.find(d => d.id === props.poi.districtId);
  }, [props.districtsVm.allDistricts, props.poi.districtId]);

  const renderHeader = useCallback(() => {
    return (
      <Box paddingLeft={2.5} width={'100%'}>
        {/* CLOSE ICON */}
        <Grid item container justifyContent={'flex-end'}>
          <IconButton color="inherit" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>

        {/* TITLE AND ICON */}
        <Grid item container direction="column" paddingBottom={1} marginTop={3}>
          <Grid item container alignItems={'center'} paddingBottom={props.poi.photos.length ? 1 : 0}>
            <Grid item xs={12} textAlign="start">
              <Typography variant="subtitle2">{vm.typeText}</Typography>
            </Grid>

            <Grid item container justifyContent={'space-between'} alignItems="center">
              <Grid item xs={11} textAlign="start">
                <Typography variant="h4" sx={{ textShadow: '1px 1px 3px #00000029' }}>{vm.poiHelper.truncateString(props.poi.name, 22)}</Typography>
                <Typography variant='caption'>{belongingDistrict?.name}</Typography>
              </Grid>

              {/* MENU */}
              {props.poi.id && <Grid item xs={1}>
                <CustomizedMenu
                  locizeNamespace="poi"
                  onDelete={onPoiDelete}
                  onUpsert={onPoiUpsert}
                  canEditAndDeletePoi={vm.canEditAndDeletePoi}
                  item={props.poi}
                />
              </Grid>}
            </Grid>

          </Grid>
        </Grid>
      </Box>
    );
  }, [belongingDistrict?.name, onPoiDelete, onPoiUpsert, props.onClose, props.poi, vm.canEditAndDeletePoi, vm.poiHelper, vm.typeText]);

  const renderHeaderNoImages = useCallback(() => (
    <Grid container direction="column" justifyContent="center"
      sx={{
        background: vm.backgroundColor,
        color: colors.paper,
        paddingX: 2,
        paddingY: 1,
        minHeight: 150,
      }}
    >
      {renderHeader()}
    </Grid>
  ), [vm.backgroundColor, renderHeader]);

  const renderHeaderWithImages = () => (
    <React.Fragment>
      <ImageCarousel
        item={props.poi}
        canDeleteImage={vm.canEditAndDeletePoi}
        locizeNamespace="poi"
        onUpsert={onPoiUpsert}
        onClose={props.onClose}
      >{renderHeader()}</ImageCarousel>
    </React.Fragment>
  );

  return (
    <Box height="100%" display="flex" flexDirection="column">

      {/* POI HEADER */}
      {!props.poi.photos.length ? renderHeaderNoImages() : renderHeaderWithImages()}

      {/* TABS */}
      <Grid container columnSpacing={vm.hasDisabledTab ? 2 : 1} alignItems="center"
        paddingX={2}
        spacing={1}
        paddingY={2}
        sx={{
          maxWidth: 'inherit',
          boxShadow: '0 8px 3px -6px #00000033',
        }} >
        {vm.poiTabs.map((tab: PoiTab) => {
          return (
            <Grid position="relative" item key={tab.name}>
              <Chip
                clickable
                variant="outlined"
                label={t(tab.name)}
                onClick={() => vm.setTab(tab)}
                style={vm.tab.name === tab.name
                  ? {
                    borderColor: colors.green[800],
                    color: colors.green[800],
                    backgroundColor: colors.green[50],
                  }
                  : {}
                }
              />
              {!tab.enabled && <img src={Assets.shield} style={{
                position: 'absolute',
                right: -10,
                top: -7,
                width: '24px'
              }} />}
            </Grid>
          );
        })}
      </Grid>

      {vm.tab.name === PoiTabEnum.TASKS && <ViewTasks poi={props.poi} tasks={props.tasks} pois={props.pois} district={props.districtsVm.selectedDistrict} />}
      {vm.tab.name === PoiTabEnum.BOOKING && (props.poi.blocked
        ? <ViewPoiBlocked />
        : <ViewPoiBooking booking={props.poi.booking}
        />
      )}
      {vm.tab.name === PoiTabEnum.INFO && <ViewPoiInfo
        poi={props.poi}
        info={props.poi.description}
        canEditAndDeletePoi={vm.canEditAndDeletePoi}
        onPoiEdit={vm.onPoiEdit}
      />}
      {vm.tab.name === PoiTabEnum.ATTRIBUTED_TO && <ViewPoiAttributedTo
        poi={props.poi}
        districts={props.districtsVm.allDistricts}
        onSelectDistrict={props.districtsVm.setSelectedDistrict}
        onPoiSave={props.onPoiSave}
        canEditAndDeletePoi={vm.canEditAndDeletePoi}
      />}
      {vm.tab.name === PoiTabEnum.CHECK_INS && <ViewCheckIns poiId={props.poi.id} />}
      {vm.tab.name === PoiTabEnum.SHOOTING_DIRECTION && <ShootingDirection
        poi={props.poi}
        onShootingDirectionEdit={vm.onShootingDirectionEdit}
        canEditAndDeletePoi={vm.canEditAndDeletePoi}
      />}
    </Box>
  );
});
