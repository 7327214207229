import { Assets } from '../../assets';
import MapIcon from '../../assets/img/light_map.svg';
import UserProfile from '../../assets/img/user_profile_icon.svg';
import UserProfilePro from '../../assets/img/user_profile_pro_icon.svg';
import { AppRoutes } from '../../router/AppRoutesEnum';
import { USER_TYPE } from '../enums/userTypeEnum';

export type SidebarNavigationItem = {
  to: string;
  value: string;
  icon: string;
  visibleFor: USER_TYPE[];
};

export const sidebarNavigationList: SidebarNavigationItem[] = [
  {
    to: AppRoutes.Map,
    value: 'common:sidebar_mobile_item_4',
    icon: MapIcon,
    visibleFor: [USER_TYPE.FREE_USER, USER_TYPE.PRO_USER],
  },
  {
    to: AppRoutes.UserProfile,
    value: 'common:sidebar_mobile_item_2',
    icon: UserProfile,
    visibleFor: [USER_TYPE.FREE_USER],
  },
  {
    to: AppRoutes.UserProfile,
    value: 'common:sidebar_mobile_item_2',
    icon: UserProfilePro,
    visibleFor: [USER_TYPE.PRO_USER],
  },
  {
    to: AppRoutes.Pricing,
    value: 'common:sidebar_mobile_item_3',
    icon: Assets.shield,
    visibleFor: [USER_TYPE.FREE_USER],
  },
  {
    to: AppRoutes.DownloadAppRoute,
    value: 'common:sidebar_mobile_item_1',
    icon: Assets.mobile_navigator,
    visibleFor: [USER_TYPE.FREE_USER, USER_TYPE.PRO_USER],
  },
  {
    to: AppRoutes.Gallery,
    value: 'common:sidebar_mobile_item_5',
    icon: Assets.image_gallery,
    visibleFor: [USER_TYPE.FREE_USER, USER_TYPE.PRO_USER],
  },
];
