import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import React from 'react';

import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { EntryModel } from '../../../../../../domain/model/EntryModel';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { transient } from '../../../../../../inversify/decorator';
import { DeleteDialogRef } from '../../../../../../toolkit/components/DeleteDialog';
import { AddImageRef } from '../../../../../../toolkit/components/image-add/AddImage';
import { CustomizedMenuItemType, CustomizedMenuProps } from './CustomizedMenu';

@transient()
export class CustomizedMenuVm extends ViewModel<CustomizedMenuProps<CustomizedMenuItemType>> {

  @observable
  public anchorEl: null | HTMLElement = null;

  public deleteItemDialogRef: React.RefObject<DeleteDialogRef> = React.createRef();

  public addImageRef: React.RefObject<AddImageRef> = React.createRef();

  constructor(
    @inject(SessionStore) public readonly session: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get popoverOpen(): boolean {
    return Boolean(this.anchorEl);
  }

  @computed
  public get isEntry(): boolean {
    return this.props.item instanceof EntryModel;
  }

  @computed
  public get canEditAndDeleteItem(): boolean {
    return Boolean(this.props.canEditAndDeleteEntries) || Boolean(this.props.canEditAndDeletePoi) || Boolean(this.props.canEditAndDeleteMapPath) || Boolean(this.props.canEditAndDeleteSubzone);
  }

  @action
  public setAnchorEl = (anchorEl: null | HTMLElement) => {
    this.anchorEl = anchorEl;
  }

  public showMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setAnchorEl(event.currentTarget);
    event.stopPropagation();
  }

  public closeMenu = (event: React.MouseEvent<HTMLElement>) => {
    this.setAnchorEl(null);
    event.stopPropagation();
  }

  public handleAddImage = (event: React.MouseEvent<HTMLElement>) => {
    this.addImageRef.current?.open();
    this.closeMenu(event);
    event.stopPropagation();
  }

  /**
   * Introduced because otherwise event would propagate to open image preview. It is used in a combination with 'pointer-events: 'all'' because without that,
   * MUI disabled won't recognize onClick event on disabled MenuItem.
   */
  public handleClickOnDisabledMenuItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  }

}
