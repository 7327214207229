import { IsEnum, IsUUID } from 'class-validator';

import { ApiProperty } from '@nestjs/swagger';

import { ENTITY_TYPE } from '../enum';

export class UserRoleDeleteRequestDto {

  @IsUUID()
  @ApiProperty()
  userId: string;

  @IsUUID()
  @ApiProperty()
  entityId: string;

  @IsEnum(ENTITY_TYPE)
  @ApiProperty({ enum: ENTITY_TYPE })
  entityType: ENTITY_TYPE;
}
