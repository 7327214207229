import { ApiProperty } from '@nestjs/swagger';
import { Type } from 'class-transformer';
import { IsEmail, IsIn, IsOptional, ValidateNested } from 'class-validator';
import { ROLE } from '../enum';


// tslint:disable:max-classes-per-file
export class InviteDistrictRequestDto {

  @IsEmail({}, { context: { errorCode: 422 } })
  @ApiProperty()
  public email: string;

  @IsOptional()
  @IsIn([ROLE.DISTRICT.ADMIN, ROLE.DISTRICT.USER])
  @ApiProperty({ example: ROLE.DISTRICT.ADMIN, required: false })
  public role?: string;
}

export class InviteDistrictPatchRequestDto {

  @ValidateNested({ each: true, context: { errorCode: 422 } })
  @Type(() => InviteDistrictRequestDto)
  @ApiProperty({ type: [InviteDistrictRequestDto] })
  public users: InviteDistrictRequestDto[];
}
