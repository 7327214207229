import {
  Box, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { BaseSyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Assets } from '../../../../../../../assets';
import { useVm } from '../../../../../../../domain/hooks/useVm';
import { PoiModel } from '../../../../../../../domain/model/PoiModel';
import { TaskModel } from '../../../../../../../domain/model/TaskModel';
import { POI_TYPE } from '../../../../../../../shared/enum';
import { palette } from '../../../../../../../theme/options/palette/palette';
import { PoiMarker } from '../../../marker/PoiMarker';
import { ILinkTaskListItem, LinkTaskVm, SectionListProps } from './LinkTaskVm';



export interface LinkTaskProps {
  poi?: PoiModel | null | undefined;
  pois: PoiModel[];
  task: TaskModel;
}

export const LinkTask = observer(function LinkTask(props: LinkTaskProps) {
  const { t } = useTranslation();
  const vm = useVm(LinkTaskVm, props);

  return (
    <Grid container direction="column" rowSpacing={4}>
      {vm.linkTaskList.map((sectionItem: SectionListProps) => {
        return (
          <Grid item key={`sectionTitle-${sectionItem.id}`}>
            <FormControl fullWidth>
              <FormLabel
                sx={{
                  color: palette.text?.details,
                  fontSize: '1.125rem'
                }}
              >
                <Box display="flex" flexDirection="row" alignItems="center" columnGap={2}>
                  <PoiMarker
                    poi={sectionItem.poi}
                    lat={0}
                    lng={0}
                    // in case of weather poi we provide explicit icon
                    // otherwise it is automatically selected based on poi type
                    icon={sectionItem.poi.type === POI_TYPE.WEATHER
                      ? Assets.weather.poi_weather_loading
                      : undefined
                    }
                    opacity={1}
                    skiptransform={true}
                    hideTooltip
                    hideShootingDirection
                  />
                  <Typography variant="h6">{t(sectionItem.title)}</Typography>
                </Box>
              </FormLabel>
              <RadioGroup
                value={props.task.poiId ?? ''}
                name="fallwild-radio-buttons-group"
              >
                <Divider sx={{ mr: 2 }} />
                {sectionItem.data?.map((linkTaskItem: ILinkTaskListItem) => {
                  return (
                    <Box marginRight={2} key={`fallwild-${linkTaskItem.poiId}`}>
                      <FormControlLabel
                        value={linkTaskItem.poiId}
                        control={
                          <Radio
                            color='secondary'
                            onClick={(event: BaseSyntheticEvent) => {
                              props.task.poiId === event.target.value
                                ? props.task.setPoiId(undefined)
                                : props.task.setPoiId(event.target.value);
                            }}
                          />
                        }
                        label={linkTaskItem.label}
                        labelPlacement="start"
                        sx={{
                          '&.MuiFormControlLabel-root': {
                            display: 'flex',
                            justifyContent: 'space-between',
                          },
                          '& .MuiTypography-root': {
                            color: '#000'
                          }
                        }}
                      />
                      <Divider />
                    </Box>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
        );
      })}
    </Grid>
  );
});
