import { createContext, useContext, useMemo, useState } from 'react';

import {
  QuotaPlanningScreens
} from '../../modules/map/components/district/view-district/components/district-quota-planning/ViewDistrictQuotaPlanningTabVm';
import { REGION } from '../../shared/enum/region.enum';
import { QuotaPlanModel } from '../model/QuotaPlanModel';

interface IQuotaPlanContextProviderProps {
  children: React.ReactNode;
}

type QuotaPlanContextType = {
  step: QuotaPlanningScreens;
  setStep: React.Dispatch<React.SetStateAction<QuotaPlanningScreens>>;
  currentQuotaPlan: QuotaPlanModel;
  setCurrentQuotaPlan: React.Dispatch<React.SetStateAction<QuotaPlanModel>>;
  displayChangeRegionButton: boolean;
  setDisplayChangeRegionButton: React.Dispatch<React.SetStateAction<boolean>>;
  region: REGION;
  setRegion: React.Dispatch<React.SetStateAction<REGION>>;
}

export const QuotaPlanContext = createContext<QuotaPlanContextType | null>(null);

export function QuotaPlanContextProvider({ children }: IQuotaPlanContextProviderProps) {
  const [step, setStep] = useState<QuotaPlanningScreens>(QuotaPlanningScreens.QUOTA_PLANS_LIST);
  const [currentQuotaPlan, setCurrentQuotaPlan] = useState<QuotaPlanModel>(new QuotaPlanModel());
  const [displayChangeRegionButton, setDisplayChangeRegionButton] = useState<boolean>(false);
  const [region, setRegion] = useState<REGION>(REGION.dach);

  const value = useMemo(() => ({
    step, setStep, currentQuotaPlan, setCurrentQuotaPlan, displayChangeRegionButton, setDisplayChangeRegionButton, region, setRegion
  }), [currentQuotaPlan, displayChangeRegionButton, region, step]);

  return (
    <QuotaPlanContext.Provider value={value}>{children}</QuotaPlanContext.Provider>
  );
}

export function useQuotaPlanContext() {
  const context = useContext(QuotaPlanContext);
  if (!context) {
    throw new Error('useQuotaPlanContext must be used withing a QuotaPlanContextProvider');
  }

  return context;
}
