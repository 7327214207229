import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { colors } from '@mui/material';

import { AsyncTask } from '../../domain/async/AsyncTask';
import { ViewModel } from '../../domain/core/ViewModel';
import { AreaPostRequestModel } from '../../domain/model/AreaPostRequestModel';
import { DistrictModel } from '../../domain/model/DistrictModel';
import { MapPathModel } from '../../domain/model/MapPathModel';
import { MapPathTypeModel } from '../../domain/model/MapPathType';
import { MeshModel } from '../../domain/model/MeshModel';
import { MapPathProxy } from '../../domain/proxy/MapPathProxy';
import { I18nService } from '../../domain/service/I18nService';
import { NotificationService } from '../../domain/service/NotificationService';
import { TrackingEvent } from '../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../domain/store/SessionStore';
import { transient } from '../../inversify/decorator';
import { IGeoLocation } from '../../shared/interfaces/IGeoLocation';
import { IMapBounds } from './components/google-map/GoogleMapVm';
import { MapPathTabType } from './components/map-path/view-map-path/ViewMapPathVm';

@transient()
export class MapPathsVm extends ViewModel {

  // * for displaying on a map in a current bounds
  @observable
  public mapPathsInMapBounds: MapPathModel[] = [];

  // * for district view sidebar
  @observable
  public selectedDistrictMapPaths: MapPathModel[] = [];

  @observable
  public mapPathTypes: MapPathTypeModel[] = [];

  @observable
  public currentMapPath: MapPathModel | null = null;

  @observable
  public lastTab: MapPathTabType | null = null;

  constructor(
    @inject(MapPathProxy) private readonly mapPathProxy: MapPathProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(SessionStore) private readonly sessionStore: SessionStore,
    @inject(TrackingService) private readonly tracking: TrackingService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get mapPaths() {
    if (this.currentMapPath) {
      return this.mapPathsInMapBounds
        .filter((p) => p.id !== this.currentMapPath?.id)
        .concat(this.currentMapPath);
    }

    return this.mapPathsInMapBounds;
  }

  @action
  public setSelectedDistrictMapPaths = (mapPaths: MapPathModel[]) => {
    // * remove duplicates based on the 'id' property
    this.selectedDistrictMapPaths = Array.from(new Map(mapPaths.map(path => [path.id, path])).values());
  }

  @action
  public setMapPathsInMapBounds = (mapPaths: MapPathModel[]) => {
    this.mapPathsInMapBounds = mapPaths;
  }

  @action
  private setCurrentMapPath = (mapPath: MapPathModel | null) => {
    this.currentMapPath = mapPath;
  }

  @action
  public startNewMapPath = async (district: DistrictModel | null) => {
    if (this.sessionStore.isProUser) {
      await this.tracking.track(TrackingEvent.TRAIL_CREATION_STARTED);
    }

    const currentMapPath = new MapPathModel();
    currentMapPath.districtId = district?.realId;
    currentMapPath.owner = this.sessionStore.currentUser!;
    currentMapPath.mesh = new MeshModel([]);
    currentMapPath.color = colors.blueGrey[600];

    this.setCurrentMapPath(currentMapPath);
  }

  @computed
  public get creatingNewPath() {
    return this.currentMapPath && !this.currentMapPath.id;
  }

  @action
  public showMapPath = (mapPath: MapPathModel) => {
    this.setCurrentMapPath(mapPath.clone());
  }

  @action
  public setMapPathTypes = (pathTypes: MapPathTypeModel[]) => {
    this.mapPathTypes = pathTypes;
  }

  @action
  public closeMapPath = () => {
    this.setCurrentMapPath(null);
    this.setTab(null);
  }

  @action
  public mapClick = (location: IGeoLocation, selectedDistrict: DistrictModel | null) => {
    if (!this.currentMapPath) {
      return;
    }

    if (this.lastTab !== MapPathTabType.PATH) {
      return;
    }

    this.currentMapPath.districtId = selectedDistrict?.realId;
    this.currentMapPath.mesh.addMeshPoint(location);
  }

  @action
  public deleteMapPathId = (id: string) => {
    this.mapPathsInMapBounds = this.mapPathsInMapBounds.filter((p) => p.id !== id);
    this.selectedDistrictMapPaths = this.selectedDistrictMapPaths.filter((p) => p.id !== id);
  }

  @action
  public setTab = (tab: MapPathTabType | null) => {
    this.lastTab = tab;
  }

  @action
  public upsert = (mapPath: MapPathModel) => {
    this.updateArrayMapPath(this.mapPathsInMapBounds, mapPath);
    this.updateArrayMapPath(this.selectedDistrictMapPaths, mapPath);
  }

  private updateArrayMapPath = (array: MapPathModel[], mapPath: MapPathModel) => {
    const index = array.findIndex((el: MapPathModel) => el.id === mapPath.id);

    if (index === -1) {
      array.unshift(mapPath);
    } else {
      array.splice(index, 1, mapPath);
    }
  }

  // * Fetch map paths within the specified map bounds, disregarding district boundaries.
  public getMapPathsInMapBounds = new AsyncTask(async (mapBounds: IMapBounds) => {
    try {
      this.setMapPathsInMapBounds([]);

      if (!this.sessionStore.isProUser) {
        return;
      }
      const dto = new AreaPostRequestModel().toDto(mapBounds);

      const result = await this.mapPathProxy.getMapPathsInMapBounds(dto);
      if (result.ok) {
        return this.setMapPathsInMapBounds(result.data);
      }

      console.warn(`error while loading map paths. ${result.status}`);
      this.notification.warning(this.i18n.t('map:map_paths_loading_error'));
    } catch (e) {
      console.error(`exception while loading map paths. ${e}`);
      this.notification.error(this.i18n.t('map:map_paths_loading_error'));
    }
  })

  public getDistrictMapPaths = new AsyncTask(async (district: DistrictModel) => {
    try {
      this.setSelectedDistrictMapPaths([]);

      if (district.isUnsaved) {
        return;
      }

      if (this.sessionStore.isProUser) {
        const result = await this.mapPathProxy.getMapPaths(district.id);
        if (result.ok) {
          return this.setSelectedDistrictMapPaths(result.data);
        }
        console.warn(`error while loading map paths. ${result.status}`);
      }

      this.setSelectedDistrictMapPaths([]);
    } catch (e) {
      console.error(`exception while loading map paths. ${e}`);
    }
  })

  public getMapPathTypes = new AsyncTask(async () => {
    try {
      this.setMapPathTypes([]);

      if (!this.sessionStore.isProUser) {
        return;
      }

      const result = await this.mapPathProxy.getMapPathTypes.run();
      if (result.ok) {
        return this.setMapPathTypes(result.data);
      }

      console.warn(`error while loading map path types. ${result.status}`);
      this.notification.warning(this.i18n.t('map:paths_loading_error'));
    } catch (e) {
      console.error(`exception while loading map paths. ${e}`);
      this.notification.error(this.i18n.t('map:paths_loading_error'));
    }
  })

  public delete = async (path: MapPathModel) => {
    try {
      const result = await this.mapPathProxy.deletePath(path.id);
      if (result.ok) {
        this.deleteMapPathId(path.id);
        return this.notification.success(this.i18n.t('paths:delete.success'));
      }

      this.notification.error(this.i18n.t('paths:error_while_deleting'));
    } catch (e) {
      this.notification.error(this.i18n.t('paths:exception_while_deleting'));
    }
  }

  @action
  public save = async (): Promise<boolean> => {
    if (!this.currentMapPath) {
      return false;
    }

    try {
      const result = this.currentMapPath.id
        ? await this.mapPathProxy.updatePath(this.currentMapPath.toDto())
        : await this.mapPathProxy.createPath(this.currentMapPath.toDto());

      if (result.ok) {
        await this.tracking.track(TrackingEvent.TRAIL_CREATION_COMPLETED);

        runInAction(() => {
          this.currentMapPath = null;
          this.upsert(result.data);
        });

        return true;
      }

      this.notification.error(this.i18n.t('paths:error_while_saving'));
      return false;
    } catch (e) {
      console.error(e);
      this.notification.error(this.i18n.t('paths:exception_while_saving'));
      return false;
    }
  };

}
