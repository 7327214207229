import { action, makeObservable, observable } from 'mobx';
import { PoiFeatureResponseDto } from '../../shared/dto/poiFeature.response.dto';

export class PoiFeatureModel {

  @observable
  public isBookable: boolean = false;

  @observable
  public canHaveTasks: boolean = true;

  @observable
  public canHaveShootingDirection: boolean = false;

  constructor() {
    makeObservable(this);
  }

  @action
  public toggleTasks = () => {
    this.canHaveTasks = !this.canHaveTasks;
  }

  @action
  public toggleBookable = () => {
    this.isBookable = !this.isBookable;
  }

  @action
  public toggleShootingDirection = () => {
    this.canHaveShootingDirection = !this.canHaveShootingDirection;
  }

  public static fromDto(dto: PoiFeatureResponseDto): PoiFeatureModel {
    const model = new PoiFeatureModel();

    model.canHaveTasks = dto.canHaveTasks;
    model.isBookable = dto.isBookable;
    model.canHaveShootingDirection = dto.hasShootingDirection;

    return model;
  }

  public clone = () => {
    const cloned = new PoiFeatureModel();

    cloned.isBookable = this.isBookable;
    cloned.canHaveTasks = this.canHaveTasks;
    cloned.canHaveShootingDirection = this.canHaveShootingDirection;

    return cloned;
  }
}
