import { computed, makeObservable, observable } from 'mobx';
import { DistrictModel } from '../../../domain/model/DistrictModel';
import { EntryModel } from '../../../domain/model/EntryModel';
import { IClassification } from '../../../util/classification/types/classification.types';

import { AbstractDistrictFilter } from './AbstractDistrictFilter';
import { IEntryFilter } from './IEntryFilter';

export class AnimalTypeFilterCondition {

  @observable
  public id: string;

  @observable
  public classification: IClassification;

  @observable
  public active: boolean;

  @observable
  public noClassification: boolean;

  constructor(id: string, classification: IClassification) {
    this.id = id;
    this.classification = classification;
    this.active = true;
    this.noClassification = false;

    makeObservable(this);
  }
}

export class AnimalTypeFilter extends AbstractDistrictFilter implements IEntryFilter {

  public static noClassFilter = 'NO_L2_CLASS';

  constructor(public readonly district: DistrictModel | null, private readonly entries: EntryModel[] | null) {
    super((district && district.id) ? `DistrictFilterVm-AnimalTypeFilter-district-${district!.id!}` : 'DistrictFilterVm-AnimalTypeFilter', 1);

    makeObservable(this);
  }

  public createNoClassCondition = () => {
    const condition = new AnimalTypeFilterCondition(AnimalTypeFilter.noClassFilter, {});
    condition.active = true;
    condition.noClassification = true;

    return condition;
  }

  public classificationId = (classification: IClassification | undefined): string | null => {
    if (!classification) {
      return null;
    }

    return classification.group ?? classification.species ?? null;
  }

  @computed
  public get conditions() {
    if (!this.entries) {
      return [];
    }

    const animalTypes: { [classification: string]: AnimalTypeFilterCondition } = {};

    let hasEntryWithoutClassification = false;
    this.entries.forEach((e) => {
      const classificationId = this.classificationId(e.classificationObject);

      if (classificationId) {
        if (!animalTypes[classificationId]) {
          animalTypes[classificationId] = new AnimalTypeFilterCondition(classificationId, e.classificationObject!);
        }
      } else {
        hasEntryWithoutClassification = true;
      }
    });

    const conditions = Object.values(animalTypes);
    if (hasEntryWithoutClassification) {
      conditions.push(this.createNoClassCondition());
    }

    return this.loadCache(conditions);
  }

  public check = (entry: EntryModel): boolean => {
    const activeConditions = this.conditions.filter((f) => f.active);

    if (!activeConditions.length) {
      if (!this.conditions.length) {
        return true;
      }

      // there is no active condition
      return false;
    }

    const classificationId = this.classificationId(entry.classificationObject);
    if (!classificationId) {
      return activeConditions.some((c) => c.noClassification);
    }

    return activeConditions.some((c) => c.id === classificationId);
  }
}
