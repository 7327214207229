import { ApiProperty } from '@nestjs/swagger';
import { IsIn, IsUUID } from 'class-validator';
import { ROLE } from '../enum';

export class DistrictChangeRolePatchRequestDto {

  @IsUUID()
  @ApiProperty()
  public userId: string;

  @IsIn([ROLE.DISTRICT.OWNER, ROLE.DISTRICT.ADMIN, ROLE.DISTRICT.USER])
  @ApiProperty({ example: ROLE.DISTRICT.ADMIN })
  public role: string;
}
