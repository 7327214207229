import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'yet-another-react-lightbox/styles.css';
import './ImagePreview.css';

import { observer } from 'mobx-react-lite';
import Lightbox from 'yet-another-react-lightbox';

import { useVm } from '../../../domain/hooks/useVm';
import { EntryModel } from '../../../domain/model/EntryModel';
import { PoiModel } from '../../../domain/model/PoiModel';
import { DeleteImageButton } from './DeleteImageButton';
import { ImagePreviewVm } from './ImagePreviewVm';

export interface ImagePreviewProps<T> {
  item: T;
  onUpsert: (item: T) => void;
  locizeNamespace: 'entry' | 'poi';
  canDeleteImage: boolean;
  index: number;
  onSetIndex: (index: number) => void;
}

export const ImagePreview = observer(function ImagePreview(props: ImagePreviewProps<PoiModel | EntryModel>) {
  const vm = useVm(ImagePreviewVm, props);

  return (
    <Lightbox
      slides={vm.slides}
      open={props.index >= 0}
      index={props.index}
      close={() => props.onSetIndex(-1)}
      on={{ view: ({ index }) => props.onSetIndex(index) }}
      toolbar={{
        buttons: [
          <DeleteImageButton
            key={'delete-button'}
            canDeleteImage={props.canDeleteImage}
            onDelete={vm.deletePhoto.run}
          />
          ,
          'close'
        ]
      }}
    />
  );
});
