import { Divider, Grid, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Avatar from 'react-avatar';

interface IProps {
  image?: string | undefined;
  component?: React.ReactNode;
  firstLine?: string;
  secondLine?: string;
  thirdLine?: string;
  withDivider: boolean;
  rightComponent?: React.ReactNode;
}

export const DistrictViewListItem = observer(function DistrictViewListItem(props: IProps) {
  return (
    <>
      <Grid container alignItems="center" paddingY={2}>
        <Grid item>
          {props.image && <Avatar
            src={props.image}
            round
            size="50px"
          />}
          {props.component}
        </Grid>
        <Grid item marginLeft={2} flex={1}>
          {props.firstLine && <Typography variant="body2" fontWeight="300">{props.firstLine}</Typography>}
          {props.secondLine && <Typography variant="h6" fontWeight="bold">{props.secondLine}</Typography>}
          {props.thirdLine && <Typography variant="body2">{props.thirdLine}</Typography>}
        </Grid>
        {props.rightComponent && <Grid item>
          {props.rightComponent}
        </Grid>}
      </Grid>
      {props.withDivider && <Divider />}
    </>
  );
});
