import React from 'react';

import { Box, BoxProps } from '@mui/material';

import BackgroundImage from '../../assets/img/background_image_transparent.png';

interface BackgroundProps extends React.PropsWithChildren<unknown>, BoxProps {
  bgImgApp?: string;
}

export function Background({ children, bgImgApp = BackgroundImage, ...others }: BackgroundProps) {
  return (
    <Box
      paddingY={{ xs: 7 }}
      paddingLeft={{ xs: '3%', md: '8%' }}
      paddingRight={'3%'}
      sx={{
        position: 'relative',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        minHeight: '100vh',
        overflow: 'hidden',
        width: '100%',
        backgroundImage: `url(${bgImgApp})`,
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      {...others}
    >
      {children}
    </Box>
  );
}
