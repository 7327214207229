import { IReactionDisposer, reaction, runInAction } from 'mobx';
import { serialize, update } from 'serializr';
import { OfflineCache } from './OfflineCache';

export abstract class Persistence {

  private reactionDisposer: IReactionDisposer;

  private storageKey: string;

  private offlineCache: OfflineCache;

  public activatePersistence(
    key: string,
    version: number,
    offlineCache = new OfflineCache(),
  ) {
    this.storageKey = `${key}-${version}`;
    this.offlineCache = offlineCache;

    this.recover();
    this.watch();
  }

  public deactivatePersistence() {
    this.reactionDisposer();
  }

  protected recover = () => {
    try {
      const key = this.storageKey;

      const recovered = this.offlineCache.get(key);
      if (!recovered) {
        return;
      }

      runInAction(() => {
        update(this, recovered);
      });
    } catch (e) {
      console.error(`error while recovering persisted object of type ${this.storageKey}. error: ${e}`);
      return this.invalidate();
    }
  }

  private watch = () => {
    this.reactionDisposer = reaction(
      () => {
        try {
          return serialize(this);
        } catch (e) {
          console.error(`serializr error while serializing ${this.storageKey}. error: ${e}`);
          return null;
        }
      },
      (serialized) => {
        this.persist(serialized);
      },
      { fireImmediately: true },
    );
  }

  private persist = (serialized: unknown): void => {
    if (!serialized) {
      return;
    }

    try {
      const key = this.storageKey;
      this.offlineCache.set(key, serialized);
    } catch (e) {
      console.error(`error while serializing fields of class ${this.storageKey}. error: ${e}`);
    }
  }

  private invalidate = () => {
    return this.offlineCache.delete(this.storageKey);
  }
}
