import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, Typography, useMediaQuery } from '@mui/material';

import { useThemeContext } from '../../../../../theme/ThemeContextProvider';

interface IRoleCellProps {
  isGroupOwner: boolean;
}

export const RoleCell: React.FC<IRoleCellProps> = function RoleCell({ isGroupOwner }: IRoleCellProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <TableCell>
      <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
        {isGroupOwner ? t('group_subscription:table.table_row.role.owner') : t('group_subscription:table.table_row.role.member')}
      </Typography>
    </TableCell>
  );
};
