import { add, format, isBefore, isSameDay } from 'date-fns';
import { inject } from 'inversify';
import { computed, makeObservable } from 'mobx';

import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { MemberModel } from '../../../../../../../domain/model/MemberModel';
import { PoiModel } from '../../../../../../../domain/model/PoiModel';
import { UserModel } from '../../../../../../../domain/model/UserModel';
import { TaskProxy } from '../../../../../../../domain/proxy/TaskProxy';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../../domain/service/NotificationService';
import { transient } from '../../../../../../../inversify/decorator';
import { ITaskListItemProps } from './TaskListItem';

@transient()
export class TaskListItemVm extends ViewModel<ITaskListItemProps> {

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TaskProxy) private readonly taskProxy: TaskProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get assigneesList(): UserModel[] {
    return this.props.district
      ?.members?.filter((member: MemberModel) => this.props.task.assigneesIds.find(assigneId => member.user?.id === assigneId))
      .map(member => member.user!) || [];
  }

  @computed
  public get assignee(): UserModel | null {
    return this.assigneesList.length === 1 ? this.assigneesList[0] : null;
  }

  @computed
  public get linkedPoi(): PoiModel | undefined {
    return this.props.pois.find((poi: PoiModel) => poi.id === this.props.task.poiId);
  }

  @computed
  public get linkedPoiName(): string {
    return this.props.pois.find((poi: PoiModel) => poi.id === this.props.task.poiId)?.name || '';
  }

  @computed
  public get hasCustomDueDateText() {
    if (!this.props.task.dueDate || this.props.task.dueInDays === undefined) {
      return;
    }

    const today = new Date();

    if (isSameDay(today, this.props.task.dueDate)) {
      return true;
    }

    return this.props.task.dueInDays <= 2;
  }

  @computed
  public get getDueDateText() {
    if (!this.props.task.dueDate) {
      return;
    }

    const today = new Date();

    if (isSameDay(today, this.props.task.dueDate)) {
      return this.i18n.t('task:due_days_today');
    }

    if (isSameDay(add(today, { days: 1 }), this.props.task.dueDate)) {
      return this.i18n.t('task:due_days_tomorrow');
    }

    if (isBefore(this.props.task.dueDate, today)) {
      return this.i18n.t('task:due_days_past');
    }

    return this.i18n.t('task:due_days_left', { days: this.props.task.dueInDays });
  }

  @computed
  public get dateUntil(): string | undefined {
    if (!this.props.task.dueDate) return;
    return format(this.props.task.dueDate, 'PP');
  }

}
