import io, { Socket } from 'socket.io-client';
import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { WsEvent } from '../../shared/ws/ws.event';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WsHandler = (data: any) => void;

@singleton()
export class WsService {
  private client: Socket | null = null;

  // used to store event handlers before we actually connect to WS
  private eventHandlersQueue: { [key: string]: WsHandler[] } = {};
  private onNextConnectHandlersQueue: (() => void)[] = [];

  public connect = (token: string) => {
    this.client = io(`${env.ws}?x-auth-token=${token}`, {
      transports: ['websocket'],
      autoConnect: true,
      reconnection: true,
      reconnectionDelay: 1000,
      transportOptions: {
        websocket: {
          extraHeaders: {
            'x-auth-token': token,
          }
        }
      }
    });

    this.client.on('connect', () => {
      console.info('connected to ws');
      this.onNextConnectHandlersQueue.forEach((handler) => handler());
      this.onNextConnectHandlersQueue = [];
    });

    this.client.on('connect_error', (err: Error) => {
      console.info(`got connect_error. ${err}`);
    });

    this.client.on('connect_failed', (err: Error) => {
      console.info(`got connect_failed. ${err}`);
    });

    this.client.on('disconnect', (err: string) => {
      console.info(`got disconnect. ${err}`);
    });

    this.client.on('error', (err: Error) => {
      console.info('got error', err);
    });

    for (const event in this.eventHandlersQueue) {
      this.eventHandlersQueue[event].forEach((handler: WsHandler) => {
        this.client?.on(event, handler);
      });
      console.info(`subscribed to ${event} ws event`);
    }
  }

  public emit = (event: string, payload: unknown) => {
    console.info(`sending ws event ${event}`);
    this.client?.emit(event, payload);
  }

  public registerHandler = (event: WsEvent, handler: WsHandler) => {
    if (!this.eventHandlersQueue[event] || this.eventHandlersQueue[event].length === 0) {
      this.eventHandlersQueue[event] = [];
    }

    const exits = this.eventHandlersQueue[event]?.find(h => h === handler);

    if (!exits) {
      this.eventHandlersQueue[event].push(handler);
    }

    if (this.client && !env.isDev) {
      this.client.on(event, handler);
      console.info(`subscribed to ${event} ws event`);
    }
  }

  public deregisterHandler = (event: WsEvent, handler: WsHandler) => {
    this.client?.off(event, handler);
    const exits = this.eventHandlersQueue[event]?.findIndex(h => h === handler);
    if (exits && exits > -1) {
      this.eventHandlersQueue[event] = this.eventHandlersQueue[event].splice(exits, 1);
    }
  }

  public disconnect = () => {
    this.client?.removeAllListeners();
    this.client?.disconnect();
  }

  public onNextConnect = (fn: () => void) => {
    this.onNextConnectHandlersQueue.push(fn);
  }
}
