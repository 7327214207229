export enum DISTRICT_ROLE {
  OWNER = 'OwnerDistrictRole',
  ADMIN = 'AdminDistrictRole',
  USER = 'UserDistrictRole',
  GUEST = 'GuestDistrictRole'
}

export enum GROUP_ROLE {
  OWNER = 'OwnerGroupRole',
  ADMIN = 'AdminGroupRole',
  USER = 'UserGroupRole'
}

export enum EVENT_ROLE {
  OWNER = 'OwnerEventRole',
  ADMIN = 'AdminEventRole',
  USER = 'UserEventRole'
}

export enum GENERIC_ROLE {
  OWNER = 'OwnerGenericRole',
  USER = 'UserGenericRole'
}

export const ROLE = {
  DISTRICT: DISTRICT_ROLE,
  GROUP: GROUP_ROLE,
  EVENT: EVENT_ROLE,
  GENERIC: GENERIC_ROLE
};

export type ROLE_TYPE = GROUP_ROLE | DISTRICT_ROLE | EVENT_ROLE | GENERIC_ROLE;
