import 'reflect-metadata';
import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import TagManager from '@sooro-io/react-gtm-module';

import App from './App';
import { env } from './env';
import reportWebVitals from './reportWebVitals';
import { ThemeContextProvider } from './theme/ThemeContextProvider';

if (process.env.REACT_APP_ENV === 'production') {
  try {
    const tagManagerArgs = {
      gtmId: env.googleTagManager.containerID,
    };
    TagManager.initialize(tagManagerArgs);
  } catch (error) {
    console.error('Failed to initialize Google Tag Manager:', error);
  }
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeContextProvider>
      <App />
    </ThemeContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
