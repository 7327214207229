import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Box, Checkbox, Grid, TextField, Typography } from '@mui/material';

import {
  useComingFromLocationContext
} from '../../../../../domain/context/ComingFromLocationContext';
import { useVm } from '../../../../../domain/hooks/useVm';
import { oldDesignTheme } from '../../../../../theme/oldDesignTheme';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { Field } from '../../../../../toolkit/components/form/Field';
import { Form } from '../../../../../toolkit/components/form/Form';
import { FormSubmitButton } from '../../../../../toolkit/components/form/FormSubmitButton';
import { Password } from '../../../../../toolkit/components/Password';
import { registrationUseStyles } from '../styles/registrationUseStyles';
import { EmailRegistrationRouteVm } from './EmailRegistrationRouteVm';

export const EmailRegistrationRoute = observer(function RegistrationRoute() {

  const { t } = useTranslation();
  const vm = useVm(EmailRegistrationRouteVm);
  const classes = registrationUseStyles();
  const { comingFromLocation } = useComingFromLocationContext();

  useEffect(() => {
    vm.setComingFrom(comingFromLocation);
  }, [comingFromLocation, vm]);

  return (
    <Box>
      <Form onSubmit={vm.normalRegister}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Box mb={2}>
              <Typography color="textPrimary" variant="h1">
                {t('auth:register_registration_title')}
              </Typography>
            </Box>
          </Grid>

          <Grid item container spacing={2} direction="column">
            {/* FIRST NAME */}
            <Grid item container spacing={2} justifyContent="space-between">
              <Grid item xs={12} sm={6}>
                <Field name="firstName" fullWidth
                  schema={yup
                    .string()
                    .required(t('auth:validations.register_form.required.firstname'))
                  }
                >
                  <TextField
                    className={classes.root}
                    label={t('auth:register_name_form_firstname')}
                    variant="filled"
                    fullWidth
                  />
                </Field>
              </Grid>

              {/* LAST NAME */}
              <Grid item xs={12} sm={6}>
                <Field name="lastName" fullWidth
                  schema={yup
                    .string()
                    .required(t('auth:validations.register_form.required.lastname'))
                  }
                >
                  <TextField
                    className={classes.root}
                    label={t('auth:register_name_form_lastname')}
                    variant="filled"
                    fullWidth
                  />
                </Field>
              </Grid>
            </Grid>

            {/* EMAIL */}
            <Grid item>
              <Field name="email" fullWidth
                schema={yup
                  .string()
                  .email(t('auth:validations.valid_email'))
                  .required(t('auth:validations.register_form.required.email'))
                }
              >
                <TextField
                  className={classes.root}
                  type="email"
                  label={t('auth:register_email_form_email')}
                  variant="filled"
                  fullWidth
                />
              </Field>
            </Grid>

            {/* PASSWORD */}
            <Grid item>
              <Field name="password" fullWidth
                schema={yup
                  .string()
                  .min(8, t('auth:validations_invalid_password'))
                  .required(t('auth:validations.register_form.required.password'))
                }
              >
                <Password
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: colors.paper,
                    },
                    '& .MuiInputBase-input': {
                      color: oldDesignTheme.palette.text.secondary,
                      fontSize: '18px',
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Field>
            </Grid>

            {/* TERMS OF AGREAMENT */}
            <Grid item container alignItems="center">
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t('auth:register_privacy_accept')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Field name="privacyAccepted"
                  fullWidth
                  defaultValue={false}
                  schema={yup
                    .boolean()
                    .oneOf([true], t('auth:invalid_privacy_check'))
                    .required('auth:validations.register_form.required.privacy')
                  }>
                  <Grid container alignItems="center">
                    <Grid item xs={1}>
                      <Checkbox
                        className={classes.checkboxPadding}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography
                        variant="subtitle2"
                        className={classes.privacyField}
                      >
                        {t('auth:registration_termsagreement_body_1')}{' '}
                        <a
                          href="https://en-uk.hunterco.de/en-us/terms-of-use"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: 'inherit' }}
                        >
                          {t('auth:registration_termsagreement_body_2')}
                        </a>{' '}
                        {t('auth:registration_termsagreement_body_3')}{' '}
                        <a
                          href="https://en-uk.hunterco.de/data-privacy"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: 'inherit' }}
                        >
                          {t('auth:registration_termsagreement_body_4')}
                        </a>{' '}
                        {t('auth:registration_termsagreement_body_5')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Field>
              </Grid>
            </Grid>
          </Grid>

          {/* BUTTON */}
          <Grid item>
            <FormSubmitButton
              className={classes.doneButton}
              submitting={vm.isBusy}
            >
              {t('auth:registration_button')}
            </FormSubmitButton>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
});
