import { action, computed, makeObservable, observable } from 'mobx';
import { GeoUtil } from '../../util/GeoUtil';
import { IGeoLocation } from '../core/IGeoLocation';
import { MementoManager } from '../memento/MementoManager';

export class MeshModel {

  @observable
  public points: IGeoLocation[] = [];

  @observable
  public selectedMeshPoint: IGeoLocation | null = null;

  @observable
  private mementoManager = new MementoManager();

  @observable
  public selectedPair: IGeoLocation[] | null = null;

  constructor(points: IGeoLocation[]) {
    makeObservable(this);
    this.points = points;
  }

  public clone = () => {
    return new MeshModel([...this.points]);
  }

  @computed
  public get firstPointSet() {
    return Boolean(this.points[0]);
  }

  @computed
  public get isMeshClosed() {
    if (this.points.length < 3) {
      return false;
    }

    const lastIndex = this.points.length - 1;
    return this.points[0].longitude === this.points[lastIndex].longitude &&
      this.points[0].latitude === this.points[lastIndex].latitude;
  }

  @computed
  public get canUndo(): boolean {
    return this.mementoManager.commands.length > 0 || this.points.length > 0;
  }

  @action
  public selectPair = (pair: IGeoLocation[]) => {
    this.selectedPair = pair;
    this.selectedMeshPoint = null;
  }

  @action
  public resetPair = () => {
    this.selectedPair = null;
  }

  @action
  public updatePointLocation = (index: number, location: IGeoLocation, captureUndo: boolean) => {
    // if index out of bounds, do nothing
    if (index > (this.points.length - 1)) {
      return;
    }

    if (captureUndo) {
      const originalLocation = this.points[index];

      this.mementoManager.do(() => {
        this.points[index] = location;
      }, () => {
        this.points[index] = originalLocation;
        this.points = [...this.points];
      });
    } else {
      this.points[index] = location;
    }

    this.points = [...this.points];
  }

  @action
  public setMesh = (mesh: IGeoLocation[]) => {
    this.points = mesh;
  }

  @action
  public closeMesh = () => {
    if (this.points.length < 3 || this.isMeshClosed) {
      return;
    }

    this.addMeshPoint(this.points[0]);
  }

  @action
  public selectMeshPoint = (location: IGeoLocation) => {
    this.selectedMeshPoint = location;
    this.resetPair();
  }

  @action
  public addMeshPoint = (point: IGeoLocation) => {
    if (this.selectedPair) {
      this.mementoManager.do(this.insertBetweenPoints(point, this.selectedPair), this.removePointFromMesh(point));
    } else {
      this.mementoManager.do(this.pushPointToMesh(point), this.popPointFromMesh());
    }

    this.resetPair();
  }

  @action
  private insertBetweenPoints = (point: IGeoLocation, points: IGeoLocation[]) => () => {
    if (points.length < 2) {
      return;
    }

    const index = this.points.findIndex((p) => p === points[0] || p === points[1]);
    if (index !== -1) {
      this.points.splice(index + 1, 0, point);
    }
  }

  @action
  public removeSelection = () => {
    if (this.selectedMeshPoint) {
      this.removeMeshPoint(this.selectedMeshPoint);
    } else if (this.selectedPair) {
      this.selectedPair.forEach(this.removeMeshPoint);
    }

    this.resetPair();
  }

  @action
  public removeMeshPoint = (point: IGeoLocation) => {
    const idx = GeoUtil.getPointIndexInPath(point, this.points);
    this.mementoManager.do(this.removePointFromMesh(point), this.insertPointAtIndex(point, idx));
  }

  @action
  public undoLastMeshOperation = () => {
    const done = this.mementoManager.undo();

    // when we start editing district, we should remove one point from the mesh
    // so district can be editable again
    if (!done) {
      this.points.pop();
    }
  }

  @action
  private pushPointToMesh = (point: IGeoLocation) => () => {
    this.points.push(point);
  }

  @action
  public openMesh = () => {
    this.points.pop();
  }

  @action
  private popPointFromMesh = () => () => {
    this.points.pop();
  }

  @action
  private removePointFromMesh = (point: IGeoLocation) => () => {
    const idx = GeoUtil.getPointIndexInPath(point, this.points);
    if (idx >= 0) {
      this.points.splice(idx, 1);
    }
  }

  @action
  private insertPointAtIndex = (point: IGeoLocation, idx: number) => () => {
    this.points.splice(idx, 0, point);
  }
}
