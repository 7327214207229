import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { IGeoLocation } from '../../../../domain/core/IGeoLocation';
import { ViewModel } from '../../../../domain/core/ViewModel';
import { SessionStore } from '../../../../domain/store/SessionStore';
import { scoped } from '../../../../inversify/decorator';
import { FEATURE } from '../../../../shared/enum';
import { GeoUtil } from '../../../../util/GeoUtil';
import { deepEqual } from '../../../../util/MapUtils';
import { IMapVm } from '../../MapVm.interface';

export interface IMapBounds {
  northEast: google.maps.LatLngLiteral | null,
  southEast: google.maps.LatLngLiteral | null,
  northWest: google.maps.LatLngLiteral | null,
  southWest: google.maps.LatLngLiteral | null
}

@scoped()
export class GoogleMapVm extends ViewModel implements IMapVm {

  @observable
  public map: google.maps.Map | null = null;

  @observable
  public lastMouseLocation: IGeoLocation | null = null;

  @observable
  public lastZoomLevel: number | null = null;

  @observable
  public mapBounds: IMapBounds = {
    northEast: null,
    southEast: null,
    northWest: null,
    southWest: null
  };

  private mapReadyPromise: Promise<void>;

  private resolveMap: () => void;

  constructor(
    @inject(SessionStore) public readonly session: SessionStore,
  ) {
    super();
    makeObservable(this);

    this.mapReadyPromise = new Promise((resolve) => {
      this.resolveMap = resolve;
    });
  }

  @action
  public handleMouseMove = (location: IGeoLocation) => {
    this.lastMouseLocation = location;
  }

  @action
  public handleZoomChange = (zoom: number | undefined) => {
    this.lastZoomLevel = zoom ?? null;
  }

  @action
  public setMap = (map: google.maps.Map) => {
    this.map = map;
    this.lastZoomLevel = map.getZoom() ?? null;

    this.resolveMap();

    // hack to lower z-index of google logo container so "Satellite" map type dropdown does not overflow with google logo
    // if at any point we implement our custom selector, we should remove this hack
    setTimeout(() => {
      const googleLogoContainer = document.querySelector('img[alt="Google"]')?.parentElement?.parentElement?.parentElement;
      if (googleLogoContainer) {
        (googleLogoContainer.style as unknown as { zIndex: number }).zIndex = 1;
      }
    }, 1000);
  }

  @computed
  public get language() {
    return this.session.session?.user.language;
  }

  @computed
  public get hasProPathsEnabled(): boolean {
    return this.session.hasFeatureEnabled(FEATURE.MAP_PATH);
  }

  @computed
  public get hasProSubzones(): boolean {
    return this.session.hasFeatureEnabled(FEATURE.SUBZONES);
  }

  @action
  public setBounds = (
    northWest: google.maps.LatLngLiteral,
    northEast: google.maps.LatLngLiteral,
    southEast: google.maps.LatLngLiteral,
    southWest: google.maps.LatLngLiteral
  ) => {
    const newBounds = {
      northWest,
      northEast,
      southEast,
      southWest
    };

    if (!deepEqual(newBounds, this.mapBounds)) {
      const initialBoundsAreNull = Object.values(this.mapBounds).every(value => value === null);

      if (initialBoundsAreNull) {
        this.mapBounds = newBounds;
        return;
      }

      //* Use GeoUtil function once the initial bounds are set
      const isBeyondOffset = GeoUtil.boundsChangedBeyondOffset(this.mapBounds, newBounds);

      if (isBeyondOffset) {
        this.mapBounds = newBounds;
      }
    }

  };

  public flyTo = async (point: IGeoLocation, zoom: number | undefined = undefined) => {
    await this.mapReadyPromise;

    this.map?.setCenter({
      lat: point.latitude,
      lng: point.longitude,
    });

    if (zoom) {
      this.map?.setZoom(zoom);
    }
  }

  public centerMesh = async (mesh: IGeoLocation[], onlyCenter?: boolean | undefined) => {
    await this.mapReadyPromise;

    if (!mesh.length || !this.map) {
      return;
    }

    if (onlyCenter) {
      const bounds = GeoUtil.getBounds(mesh);
      this.map?.setCenter(bounds.getCenter());
      return;
    }

    this.map?.fitBounds(GeoUtil.getBounds(mesh), {
      top: 50,
      left: 50,
      right: 50,
      bottom: 50,
    });
  }

  /** Related to haversine distance @see GeoUtil.boundsChangedBeyondOffset */
  public calculateBounds = (maps: typeof google.maps | null) => {
    if (!this.map) return;

    const bounds: google.maps.LatLngBounds | undefined = this.map.getBounds();
    if (!bounds) return;

    if (!maps || !maps.geometry) return;

    const ne: google.maps.LatLng = bounds.getNorthEast();
    const sw: google.maps.LatLng = bounds.getSouthWest();
    const nw: google.maps.LatLng = new maps.LatLng(ne.lat(), sw.lng());
    const se: google.maps.LatLng = new maps.LatLng(sw.lat(), ne.lng());

    // Move NE point 45 km to the northeast
    const newNE = maps.geometry.spherical.computeOffset(ne, 40_000, 45);

    // Move NW point 45 km to the northwest
    const newNW = maps.geometry.spherical.computeOffset(nw, 40_000, 315);

    // Move SW point 45 km to the southwest
    const newSW = maps.geometry.spherical.computeOffset(sw, 40_000, 225);

    // Move SE point 45 km to the southeast
    const newSE = maps.geometry.spherical.computeOffset(se, 40_000, 135);

    this.setBounds(newNW.toJSON(), newNE.toJSON(), newSE.toJSON(), newSW.toJSON());
  }
}
