import Avatar from 'react-avatar';

import { Grid, Typography, useMediaQuery } from '@mui/material';

import {
  GroupSubscriptionUserModel
} from '../../../../../../domain/model/GroupSubscriptionUserModel';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';

export interface UserColumnProps {
  user: GroupSubscriptionUserModel;
}

export function UserColumn(props: UserColumnProps) {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <Grid container direction={'row'} gap={2}>
      <Grid item flexGrow={0} alignSelf={'center'}>
        <Avatar name={props.user.fullName} round size={'24'} src={props.user.avatar?.thumbnailUrl || ''} />
      </Grid>
      <Grid item container direction={'column'} alignSelf={'center'} sx={{
        flexGrow: 1,
        flexBasis: 0,
        minWidth: 0,
      }}>
        <Grid item>
          <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>{props.user.fullName}</Typography>
        </Grid>
        <Grid item>
          <Typography
            variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'}
            color={theme.palette.text.secondary}
            sx={{ lineBreak: 'anywhere' }}>{props.user.email}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
