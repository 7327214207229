import { useEffect, useMemo, useRef } from 'react';

import { IGeoLocation } from '../../../../../domain/core/IGeoLocation';
import { DistrictModel } from '../../../../../domain/model/DistrictModel';
import { MeshModel } from '../../../../../domain/model/MeshModel';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { palette } from '../../../../../theme/options/palette/palette';
import { convertGeolocationToPath } from './useDrawDistrictMesh';

export interface IPolylineConfig {
  map: google.maps.Map | null;
  mapType: google.maps.MapTypeId.HYBRID | google.maps.MapTypeId.ROADMAP | google.maps.MapTypeId.TERRAIN;
  selectedDistrict: DistrictModel | null;
  districts: DistrictModel[];
  strokeWeight?: number;
  onLineHover?: (location: IGeoLocation | null) => void;
  onLineClick: (location: IGeoLocation) => void;
}

interface IPath {
  lat: number;
  lng: number;
}

interface IPathType {
  path: Array<IPath>;
  district: DistrictModel;
}

export function useDrawDistrictsMesh(config: IPolylineConfig) {
  // Maintain references to Polyline objects
  const polylineRefs = useRef<google.maps.Polyline[]>([]);

  const paths: IPathType[] = useMemo(() => {
    return config.districts
      .filter(district => district.id !== config.selectedDistrict?.id)
      .map(district => {
        return {
          path: convertGeolocationToPath((district.mesh ?? new MeshModel([])).points),
          district,
        };
      });
    // it is necessary to have both points and points.length
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, config.districts, config.selectedDistrict]);

  // draw district mesh
  useEffect(() => {
    // Clear existing polylines from the map before creating new ones
    polylineRefs.current.forEach(polyline => polyline.setMap(null));
    polylineRefs.current = [];

    if (!config.map) {
      return;
    }

    if (!paths.length) {
      return;
    }

    paths.forEach(pathType => {
      const polyline = new google.maps.Polyline({
        path: pathType.path,
        strokeColor: (config.mapType === google.maps.MapTypeId.ROADMAP) || (config.mapType === google.maps.MapTypeId.TERRAIN) ? colors.darkFont : palette.background?.default,
        strokeOpacity: 0.8,
        strokeWeight: config.strokeWeight ?? 3,
        map: config.map,
        clickable: true,
        zIndex: 1,
        visible: true,
      });

      // Save reference to the Polyline
      polylineRefs.current.push(polyline);

      return () => {
        pathType.district.mesh.resetPair();
      };
    });
  }, [config.map, config.strokeWeight, config, config.districts, config.selectedDistrict?.id, paths]);

}
