import React from 'react';
import { useTranslation } from 'react-i18next';

interface DownloadImageProps {
  pathKey: string;
}

export default function DownloadImage({ pathKey }: DownloadImageProps) {
  const { t } = useTranslation();

  return (
    <img
      src={t(pathKey)}
      style={{
        maxWidth: '80%',
        maxHeight: '80%',
        paddingBottom: '20px',
      }}
    />
  );
}
