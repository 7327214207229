import { makeObservable, observable } from 'mobx';

import { createUUID } from '../../../__test__/uuid';
import { WeatherValueUnitItemResponseDto } from '../../../shared/dto/weather/weatherValueUnitItem.response.dto';

export class PrecipitationAmountModel {
  @observable
  public id: string;

  @observable
  public value: number | null;

  @observable
  public units: string;

  constructor() {
    makeObservable(this);
  }

  public formatUnit() {
    if (this.units === 'mm/hr') {
      return 'mm';
    }

    return this.units;
  }

  public static fromDto = (dto: WeatherValueUnitItemResponseDto) => {
    const model = new PrecipitationAmountModel();

    model.id = createUUID();
    model.value = dto.value;
    model.units = dto.units;

    return model;
  }
}
