import React from 'react';

interface IProps {
  color: string;
}

export function MapPathTypeIcon(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g id="Group_19550" data-name="Group 19550" transform="translate(-58 -246)">
        <g id="Ellipse_2600" data-name="Ellipse 2600" transform="translate(58 246)" fill={props.color} stroke="#fff" strokeWidth="1">
          <circle cx="18" cy="18" r="18" stroke="none" />
          <circle cx="18" cy="18" r="17.5" fill="none" />
        </g>
        <g id="Group_19486" data-name="Group 19486" transform="translate(92 257)">
          <g id="Group_19483" data-name="Group 19483" transform="translate(2 3)">
            <path id="Path_31508" data-name="Path 31508" d="M-16.481,11.553l2.066-8.035-.307-.362L-20.4-3.534" fill="none" stroke="#fff" strokeWidth="1" />
            <g id="Ellipse_2565" data-name="Ellipse 2565" transform="translate(-19.001 9)" fill={props.color} stroke="#fff" strokeWidth="1">
              <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
              <circle cx="2.5" cy="2.5" r="2" fill="none" />
            </g>
            <g id="Ellipse_2566" data-name="Ellipse 2566" transform="translate(-17.001 1)" fill={props.color} stroke="#fff" strokeWidth="1">
              <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
              <circle cx="2.5" cy="2.5" r="2" fill="none" />
            </g>
            <g id="Ellipse_2554" data-name="Ellipse 2554" transform="translate(-23.001 -6)" fill={props.color} stroke="#fff" strokeWidth="1">
              <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
              <circle cx="2.5" cy="2.5" r="2" fill="none" />
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
}
