import { observable } from 'mobx';
import { DiseaseResponseDto } from '../../shared/dto/disease.response.dto';

export class DiseaseModel {

  @observable
  public id: string;

  @observable
  public key: string;

  @observable
  public category: string;

  @observable
  public name: string;

  @observable
  public notifyAuthorities: boolean;

  @observable
  public partsEatable: boolean;

  public static fromDto(dto: DiseaseResponseDto): DiseaseModel {
    const model = new DiseaseModel();
    model.id = dto.id;
    model.category = dto.category;
    model.name = dto.name;
    model.notifyAuthorities = dto.notifyAuthorities;
    model.partsEatable = dto.partsEatable;
    model.key = dto.key;

    return model;
  }

  public clone = (): DiseaseModel => {
    const model = new DiseaseModel();
    model.id = this.id;
    model.category = this.category;
    model.key = this.key;
    model.name = this.name;
    model.notifyAuthorities = this.notifyAuthorities;
    model.partsEatable = this.partsEatable;

    return model;
  }
}
