import { inject } from 'inversify';
import { GearSetModel } from '../domain/model/GearsetModel';
import { I18nService, ITranslatable } from '../domain/service/I18nService';
import { singleton } from '../inversify/decorator';


@singleton()
export class GearSetHelper {

  constructor(
    @inject(I18nService) public readonly i18n: I18nService,
  ) {
  }

  public getWeaponAndManufacturer = (gearSet: GearSetModel): string => {
    const weapon = gearSet.weapons.length ? gearSet.weapons[0] : null;
    if (!weapon) {
      return '';
    }

    const weaponTypeLabel = weapon.typeLabel as ITranslatable;
    return `${this.i18n.translate(weaponTypeLabel)}, ${weapon?.manufacturer?.name}, ${weapon?.name}`;
  }

  public getAmmunitionDescription = (gearSet: GearSetModel): string => {
    const ammu = gearSet.ammunition.length ? gearSet.ammunition[0] : null;
    if (!ammu) {
      return '';
    }

    return `${ammu.manufacturerAndCaliber}, ${ammu.name}`;
  }
}
