import { inject } from 'inversify';
import { computed, makeObservable } from 'mobx';

import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { DistrictModel } from '../../../../../../domain/model/DistrictModel';
import { EntryModel } from '../../../../../../domain/model/EntryModel';
import { I18nService } from '../../../../../../domain/service/I18nService';
import { transient } from '../../../../../../inversify/decorator';
import { GeoUtil } from '../../../../../../util/GeoUtil';

export interface IEntryAttributedToProps {
  entry: EntryModel;
  districts: DistrictModel[];
  onSelectDistrict: (district: DistrictModel) => void;
  canEditAndDeleteEntries: boolean;
  isHarvestVisibleAndEditable: boolean;
}

@transient()
export class EntryAttributedToVm extends ViewModel<IEntryAttributedToProps> {

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get isAttributedToDistrict(): boolean {
    return Boolean(this.props.entry.districtId);
  }

  @computed
  public get possibleDistricts(): DistrictModel[] {
    const location = this.props.entry.location;
    if (!location) {
      return [];
    }

    return this.props.districts.filter(d => GeoUtil.isPointInMesh(location, d.mesh.points));
  }

  @computed
  public get possibleDistrictIncludingPrivateMap(): DistrictModel[] {
    return this.possibleDistricts.concat(DistrictModel.privateMapDistrict(this.i18n.t('map:world_map')));
  }

  @computed
  public get attributedDistrict(): DistrictModel {
    const selectedDistrict = this.props.districts.find(d => d.id === this.props.entry.districtId) ?? DistrictModel.privateMapDistrict(this.i18n.t('map:world_map'));
    return selectedDistrict;
  }

}
