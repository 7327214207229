import i18n from 'i18next';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import { Suspense, useEffect, useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';

import { CssBaseline, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { ComingFromLocationContextProvider } from './domain/context/ComingFromLocationContext';
import { BugsnagService } from './domain/service/BugsnagService';
import { I18nService } from './domain/service/I18nService';
import { NotificationService } from './domain/service/NotificationService';
import { env } from './env';
import { ErrorFallback } from './ErrorFallback';
import { container, ContainerContext } from './inversify/container';
import { LoadingFallback } from './LoadingFallback';
import { PinSizeContextProvider } from './modules/map/PinSizeContext';
import { Router } from './router/Router';
import { oldDesignTheme } from './theme/oldDesignTheme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

function setSnackbar(provider: SnackbarProvider) {
  container.get(NotificationService).setSnackbar(provider);
}

container.get(BugsnagService).startBugsnag();
const ErrorBoundary = container.get(BugsnagService).createErrorBoundary();

export function App() {
  // const { theme } = useThemeContext();
  const anchorOrigin = useMemo((): SnackbarOrigin => {
    return {
      vertical: 'top',
      horizontal: 'right'
    };
  }, []);

  useEffect(() => {
    container.get(I18nService).init();
  }, []);

  return (
    // ! include this when redesign is done, so the same theme is through the whole app
    // <ThemeProvider theme={theme}>
    <ThemeProvider theme={oldDesignTheme}>
      <GoogleOAuthProvider clientId={env.google.clientId}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <StyledEngineProvider injectFirst>
            <CssBaseline />
            <ContainerContext.Provider value={container}>
              <I18nextProvider i18n={i18n}>
                <Suspense fallback={<LoadingFallback />}>
                  <PinSizeContextProvider>
                    <SnackbarProvider
                      anchorOrigin={anchorOrigin}
                      ref={setSnackbar}
                      maxSnack={3}
                    >
                      <ComingFromLocationContextProvider>
                        <Router />
                      </ComingFromLocationContextProvider>
                    </SnackbarProvider>
                  </PinSizeContextProvider>
                </Suspense>
              </I18nextProvider>
            </ContainerContext.Provider>
          </StyledEngineProvider>
        </ErrorBoundary>
      </GoogleOAuthProvider>
    </ThemeProvider>
  );
}

export default App;
