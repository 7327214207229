import { Assets } from '../assets';
import { WEATHER_CODE } from '../shared/enum/weather/weatherCode.enum';

export const getEntryWeatherIcon = (weatherCode: WEATHER_CODE) => {
  switch (weatherCode) {
    case WEATHER_CODE.FREEZING_RAIN_HEAVY:
      return Assets.weather.grey.freezing_rain_heavy;
    case WEATHER_CODE.FREEZING_RAIN:
      return Assets.weather.grey.freezing_rain;
    case WEATHER_CODE.FREEZING_RAIN_LIGHT:
      return Assets.weather.grey.freezing_rain_light;
    case WEATHER_CODE.FREEZING_DRIZZLE:
      return Assets.weather.grey.freezing_drizzle;
    case WEATHER_CODE.ICE_PELLETS_HEAVY:
      return Assets.weather.grey.ice_pellets_heavy;
    case WEATHER_CODE.ICE_PELLETS:
      return Assets.weather.grey.ice_pellets;
    case WEATHER_CODE.ICE_PELLETS_LIGHT:
      return Assets.weather.grey.ice_pellets_light;
    case WEATHER_CODE.SNOW_HEAVY:
      return Assets.weather.grey.snow_heavy;
    case WEATHER_CODE.SNOW:
      return Assets.weather.grey.snow;
    case WEATHER_CODE.SNOW_LIGHT:
      return Assets.weather.grey.snow_light;
    case WEATHER_CODE.FLURRIES:
      return Assets.weather.grey.flurries;
    case WEATHER_CODE.TSTORM:
      return Assets.weather.grey.tstorm;
    case WEATHER_CODE.RAIN_HEAVY:
      return Assets.weather.grey.rain_heavy;
    case WEATHER_CODE.RAIN:
      return Assets.weather.grey.rain;
    case WEATHER_CODE.RAIN_LIGHT:
      return Assets.weather.grey.rain_light;
    case WEATHER_CODE.DRIZZLE:
      return Assets.weather.grey.drizzle;
    case WEATHER_CODE.FOG:
      return Assets.weather.grey.fog;
    case WEATHER_CODE.FOG_LIGHT:
      return Assets.weather.grey.fog_light;
    case WEATHER_CODE.CLOUDY:
      return Assets.weather.grey.cloudy;
    case WEATHER_CODE.MOSTLY_CLOUDY:
      return Assets.weather.grey.mostly_cloudy;
    case WEATHER_CODE.PARTLY_CLOUDY:
      return Assets.weather.grey.partly_cloudy;
    case WEATHER_CODE.CLEAR:
      return Assets.weather.grey.clear;
    case WEATHER_CODE.MOSTLY_CLEAR:
      return Assets.weather.grey.mostly_clear;
    case WEATHER_CODE.FIRST_LIGHT:
      return Assets.weather.grey.first_light;
    case WEATHER_CODE.LAST_LIGHT:
      return Assets.weather.grey.last_light;
    case WEATHER_CODE.UNKNOWN:
      return Assets.weather.grey.unknown;
    case WEATHER_CODE.LIGHT_WIND:
      return Assets.weather.grey.light_wind;
    case WEATHER_CODE.WIND:
      return Assets.weather.grey.wind;
    case WEATHER_CODE.STRONG_WIND:
      return Assets.weather.grey.strong_wind;
  }
};

export const getWeatherIcon = (weatherCode: WEATHER_CODE, isNight: boolean = false) => {
  if (isNight) {
    switch (weatherCode) {
      case WEATHER_CODE.CLEAR:
        return Assets.weather.night_clear;
      case WEATHER_CODE.MOSTLY_CLEAR:
        return Assets.weather.night_mostly_clear;
      case WEATHER_CODE.PARTLY_CLOUDY:
        return Assets.weather.night_partly_cloudy;
    }
  }

  switch (weatherCode) {
    case WEATHER_CODE.FREEZING_RAIN_HEAVY:
      return Assets.weather.freezing_rain_heavy;
    case WEATHER_CODE.FREEZING_RAIN:
      return Assets.weather.freezing_rain;
    case WEATHER_CODE.FREEZING_RAIN_LIGHT:
      return Assets.weather.freezing_rain_light;
    case WEATHER_CODE.FREEZING_DRIZZLE:
      return Assets.weather.freezing_drizzle;
    case WEATHER_CODE.ICE_PELLETS_HEAVY:
      return Assets.weather.ice_pellets_heavy;
    case WEATHER_CODE.ICE_PELLETS:
      return Assets.weather.ice_pellets;
    case WEATHER_CODE.ICE_PELLETS_LIGHT:
      return Assets.weather.ice_pellets_light;
    case WEATHER_CODE.SNOW_HEAVY:
      return Assets.weather.snow_heavy;
    case WEATHER_CODE.SNOW:
      return Assets.weather.snow;
    case WEATHER_CODE.SNOW_LIGHT:
      return Assets.weather.snow_light;
    case WEATHER_CODE.FLURRIES:
      return Assets.weather.flurries;
    case WEATHER_CODE.TSTORM:
      return Assets.weather.tstorm;
    case WEATHER_CODE.RAIN_HEAVY:
      return Assets.weather.rain_heavy;
    case WEATHER_CODE.RAIN:
      return Assets.weather.rain;
    case WEATHER_CODE.RAIN_LIGHT:
      return Assets.weather.rain_light;
    case WEATHER_CODE.DRIZZLE:
      return Assets.weather.drizzle;
    case WEATHER_CODE.FOG:
      return Assets.weather.fog;
    case WEATHER_CODE.FOG_LIGHT:
      return Assets.weather.fog_light;
    case WEATHER_CODE.CLOUDY:
      return Assets.weather.cloudy;
    case WEATHER_CODE.MOSTLY_CLOUDY:
      return Assets.weather.mostly_cloudy;
    case WEATHER_CODE.PARTLY_CLOUDY:
      return Assets.weather.partly_cloudy;
    case WEATHER_CODE.CLEAR:
      return Assets.weather.clear;
    case WEATHER_CODE.MOSTLY_CLEAR:
      return Assets.weather.mostly_clear;
    case WEATHER_CODE.FIRST_LIGHT:
      return Assets.weather.first_light;
    case WEATHER_CODE.LAST_LIGHT:
      return Assets.weather.last_light;
    case WEATHER_CODE.UNKNOWN:
      return Assets.weather.unknown;
    case WEATHER_CODE.LIGHT_WIND:
      return Assets.weather.light_wind;
    case WEATHER_CODE.WIND:
      return Assets.weather.wind;
    case WEATHER_CODE.STRONG_WIND:
      return Assets.weather.strong_wind;
  }
};
