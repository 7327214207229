export enum REGION {
    africa = 'africa',
    asia = 'asia',
    western_europe = 'western_europe',
    british_isles = 'british_isles',
    dach = 'dach',
    bulgaria = 'bulgaria',
    eastern_europe = 'eastern_europe',
    nordics = 'nordics',
    north_america = 'north_america',
    oceania = 'oceania',
    south_america = 'south_america',
    southern_europe = 'southern_europe',
    malta = 'malta'
}

