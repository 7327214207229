import { IsNotEmpty, IsNumberString, IsOptional } from 'class-validator';

import { GroupMiniDto } from './group.mini.dto';

export class DeprecatedPostPostRequestDto {

  @IsNotEmpty()
  public groups: string|GroupMiniDto[];

  @IsOptional()
  public photoId?: string;

  @IsOptional()
  public meta?: string;

  @IsOptional()
  public description?: string;

  @IsOptional()
  @IsNumberString()
  public lat?: string;

  @IsOptional()
  @IsNumberString()
  public long?: string;
}
