import { observer } from 'mobx-react-lite';
import React from 'react';

import { DistrictModel } from '../../../../../../../domain/model/DistrictModel';
import { PoiModel } from '../../../../../../../domain/model/PoiModel';
import { TaskModel } from '../../../../../../../domain/model/TaskModel';
import { ViewTasks } from '../../../../poi/view-poi/tasks/ViewTasks';

export interface ViewDistrictTasksProps {
  pois: PoiModel[];
  tasks: TaskModel[];
  district: DistrictModel;
}

export const ViewDistrictTasks = observer(function ViewDistrictTasks(props: ViewDistrictTasksProps) {

  return (
    <ViewTasks
      pois={props.pois}
      district={props.district}
      tasks={props.tasks}
    />
  );
});
