import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CreateNewAreaOldIcon, ImportIcon, JoinAreaOldIcon
} from '@HunterCompanion/hunter-design/icons';
import { Grid, Typography } from '@mui/material';

import { PoiModel } from '../../../../../domain/model/PoiModel';
import { IGeoLocation } from '../../../../../shared/interfaces/IGeoLocation';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import {
  JoinAreaModal, JoinAreaModalRef
} from '../../district/view-district/components/district-join-area/JoinAreaModal';
import { KmlImportModal } from '../../district/view-district/components/kml-upload/KmlImportModal';
import {
  KmlImportModalRef
} from '../../district/view-district/components/kml-upload/KmlImportModalVm';
import { IMapMenuItemObject, MapMenuItemChild, StyledMapMenuItem } from '../MapMenuUtilComponents';

interface IAreaMenuProps {
  onNewDistrict: (name: string, points: IGeoLocation[], pois: PoiModel[], kmlImport: boolean) => void;
  closeMenu: () => void;
  importMultiplePois?: (pois: PoiModel[]) => void;
}

export const AreaMenu = function AreaMenu(props: IAreaMenuProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const joinAreaModalRef: React.RefObject<JoinAreaModalRef> = useMemo(() => React.createRef(), []);
  const kmlImportModalRef: React.RefObject<KmlImportModalRef> = useMemo(() => React.createRef(), []);

  const handleNewDistrictCreation = useCallback(() => {
    props.closeMenu();
    props.onNewDistrict('', [], [], false);
  }, [props]);

  const areaItems: IMapMenuItemObject[] = useMemo(() => {
    return [{
      text: t('district:create_area'),
      icon: CreateNewAreaOldIcon,
    }, {
      text: t('district:upload_area'),
      icon: ImportIcon,
    }, {
      text: t('district:join_area'),
      icon: JoinAreaOldIcon,
    }];
  }, [t]);

  return (
    <Grid container direction={'column'} spacing={1} paddingY={1}>
      {/* TITLE */}
      <Grid item>
        <Typography variant='text_xs_medium' color={theme.palette.text.tertiary}>{t('map:new_area_btn_text')}</Typography>
      </Grid>

      {/* NEW AREA */}
      <Grid item>
        <StyledMapMenuItem onClick={handleNewDistrictCreation}>
          <MapMenuItemChild item={areaItems[0]} />
        </StyledMapMenuItem>
      </Grid>

      {/* UPLOAD AREA */}
      <Grid item>
        <StyledMapMenuItem onClick={() => kmlImportModalRef.current?.open()}>
          <MapMenuItemChild item={areaItems[1]} />
          <KmlImportModal ref={kmlImportModalRef} onDistrictSelected={props.onNewDistrict} importMultiplePois={props.importMultiplePois} />
        </StyledMapMenuItem>
      </Grid>

      {/* JOIN AREA */}
      <Grid item>
        <StyledMapMenuItem onClick={() => joinAreaModalRef.current?.open()}>
          <MapMenuItemChild item={areaItems[2]} />
          <JoinAreaModal ref={joinAreaModalRef} />
        </StyledMapMenuItem>
      </Grid>

    </Grid >
  );
};
