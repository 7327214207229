import { computed, makeObservable, observable } from 'mobx';
import { MapPathTypeResponseDto } from '../../shared/dto';


export class MapPathTypeModel {

  @observable
  public id: string = '';

  @observable
  public key: string = '';

  @observable
  public color: string | null;

  constructor() {
    makeObservable(this);
  }

  @computed
  public get i18nKey() {
    if (this.key === 'stalking') {
      return 'paths:type_stalking_short';
    }

    if (this.key === 'game') {
      return 'paths:type_game_short';
    }

    if (this.key === 'hiking') {
      return 'paths:type_hiking_short';
    }

    if (this.key === 'custom') {
      return 'paths:type_custom_short';
    }

    return this.key;
  }

  public static fromDto = (dto: MapPathTypeResponseDto) => {
    const type = new MapPathTypeModel();

    type.id = dto.id;
    type.key = dto.key;
    type.color = dto.color;

    return type;
  }
}
