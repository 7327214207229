import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useCallback } from 'react';
import { AndroidView, BrowserView, IOSView, isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import DownloadAndroid from '../../assets/img/Download_Android.png';
import DownloadApple from '../../assets/img/Download_Apple.png';

const useStyles = makeStyles(() => ({
  img: {
    width: '220px',
    height: '64px',
    objectFit: 'contain',
    cursor: 'pointer',
  },
}));

interface IStoreButtonsProps extends React.PropsWithChildren<unknown> {
  className?: string;
}

export const StoreButtons: React.FC<IStoreButtonsProps> = ({ className }: IStoreButtonsProps) => {

  const classes = useStyles();
  const { t } = useTranslation();
  const imgClass = className || classes.img;

  const goToGoogleStore = useCallback(() => {
    window.open(`${t('links:google-play')}`);
  }, [t]);

  const goToAppleStore = useCallback(() => {
    window.open(`${t('links:apple-appstore')}`);
  }, [t]);

  return (
    <Grid container alignItems="flex-start">
      <IOSView>
        <Grid item>
          <img
            className={imgClass}
            src={DownloadApple}
            onClick={goToAppleStore}
          />
        </Grid>
      </IOSView>

      <AndroidView>
        <Grid item>
          <img
            className={imgClass}
            src={DownloadAndroid}
            onClick={goToGoogleStore}
          />
        </Grid>
      </AndroidView>

      {!isMobile && !isTablet && <BrowserView>
        <Grid item>
          <img
            className={imgClass}
            src={DownloadApple}
            onClick={goToAppleStore}
          />
        </Grid>
        <Grid item>
          <img
            className={imgClass}
            src={DownloadAndroid}
            onClick={goToGoogleStore}
          />
        </Grid>
      </BrowserView>}
    </Grid>
  );
};
