import { ApiProperty } from '@nestjs/swagger';

import { MOON_AGE } from '../../enum/weather/moonAge.enum';
import { WEATHER_CODE } from '../../enum/weather/weatherCode.enum';
import { AnimalActivityResponseDto } from './animalActivity.response.dto';
import { MoonLightResponseDto } from './moonLight.response.dto';
import { PrecipitationResponseDto } from './precipitation.response.dto';
import { SolunarPeriodResponseDto } from './solunarPeriod.response.dto';
import { WeatherHourlyForecastResponseDto } from './weatherHourlyForecast.response.dto';
import { WeatherValueUnitItemResponseDto } from './weatherValueUnitItem.response.dto';
import { WindResponseDto } from './wind.response.dto';

export class WeatherDailyForecastResponseDto {
  @ApiProperty()
  public hourly: WeatherHourlyForecastResponseDto[];
  @ApiProperty()
  public date: string;
  @ApiProperty()
  public maxTemperature: WeatherValueUnitItemResponseDto;
  @ApiProperty()
  public minTemperature: WeatherValueUnitItemResponseDto;
  @ApiProperty()
  public sunHours: WeatherValueUnitItemResponseDto;
  @ApiProperty()
  public sunRise: string;
  @ApiProperty()
  public sunSet: string;
  @ApiProperty()
  public moonRise: string;
  @ApiProperty()
  public moonSet: string;
  @ApiProperty()
  public moonLight: MoonLightResponseDto;
  @ApiProperty()
  public moonAge: MOON_AGE;
  @ApiProperty()
  public precipitation: PrecipitationResponseDto;
  @ApiProperty()
  public visibility: WeatherValueUnitItemResponseDto;
  @ApiProperty()
  public wind: WindResponseDto;
  @ApiProperty()
  public firstShootingLight: string;
  @ApiProperty()
  public lastShootingLight: string;
  @ApiProperty()
  public minorSolunarPeriod: SolunarPeriodResponseDto[];
  @ApiProperty()
  public majorSolunarPeriod: SolunarPeriodResponseDto[];
  @ApiProperty()
  public animalActivity: AnimalActivityResponseDto;
  @ApiProperty()
  public forecastText: string;
  @ApiProperty()
  public weatherCode: WEATHER_CODE;
}
