import React from 'react';

export const LogoIconWhite: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="85.358" viewBox="0 0 80 85.358">
      <defs>
        <linearGradient id="linear-gradient-logo_white" x1="0.168" y1="0.012" x2="0.789" y2="1" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#00674d" />
          <stop offset="1" stopColor="#00503c" />
        </linearGradient>
        <filter id="Path_26329" x="6.48" y="0.636" width="73.04" height="84.721" filterUnits="userSpaceOnUse">
          <feOffset dx="3" dy="3" />
          <feGaussianBlur stdDeviation="5" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="Group_17484" data-name="Group 17484" transform="translate(0)">
        <rect id="Rectangle_5742" data-name="Rectangle 5742" width="80" height="79.994" rx="9" transform="translate(0 0)" fill="url(#linear-gradient-logo_white)" />
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_26329)">
          <path id="Path_26329-2" data-name="Path 26329" d="M235.04,54.621l-4.667-3.558-2.887.519V44.754a23.669,23.669,0,0,1-3.716-13.541c.743.038,1.487.1,2.23.135-5.013-3.866-10.008-7.251-14.917-5.982-2.351.615-7.208-.481-4.805-3.366,1.521.885,4.269,1.962,5.237-.423-1.262-1.039-2.506-2.077-3.751-3.135-2.126-1.866-3.872-4-1.936-9.021a5.335,5.335,0,0,0-2.074,4.462c-1.815,1.75-5.79-2.77-3.353-6.674a5.1,5.1,0,0,0-1.988,2.847c-2.23-2.4-.57-5.77,3.561-9.367A5.224,5.224,0,0,0,197.791,3.5,5.48,5.48,0,0,1,197.5-.1a4.871,4.871,0,0,0-.536,5.289c-2.385,4.232,1.694,8.867,6.119,11.752-.052.038-.1.1-.156.135-6.43,5.02-6.983,11.541,1.988,20.465-.086,3.135-.173,6.251-.259,9.386l-1.625-1.019.415-.692c-.8-1.712-1.573-3.4-2.368-5.116.4-1.039.8-2.058,1.175-3.1a20.1,20.1,0,0,1-2.42-3.058l-.778,6c.83,2.077,1.677,4.155,2.506,6.232L192,54.564ZM206.554,40.888a9.174,9.174,0,0,0,3.146-7.674,9.107,9.107,0,0,1,.83,4,11.144,11.144,0,0,0,8-.577A15.109,15.109,0,0,0,225.81,46c-.225,2-.449,4-.657,6L224.1,52.2l-5.808-2.154c.536-2.52,1.072-5.039,1.59-7.578L218.273,38.6a13.048,13.048,0,0,1-3.664.577c1.02,1.308,2.04,2.635,3.059,3.943-.467,2.058-.933,4.116-1.4,6.155l-.57-.212-4.6,2-4.529-2.885C206.554,45.754,206.554,43.331,206.554,40.888Z" transform="translate(-173.52 12.74)" fill="#fff" fillRule="evenodd" />
        </g>
      </g>
    </svg>

  );
};
