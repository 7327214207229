import { autorun, reaction } from 'mobx';
import { useEffect } from 'react';

import { MapPathModel } from '../../../../../domain/model/MapPathModel';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { GeoUtil } from '../../../../../util/GeoUtil';
import { convertGeolocationToPath, IMapClickEvent } from './useDrawDistrictMesh';

export interface IPolylineConfig {
  map: google.maps.Map | null;
  mapPaths: MapPathModel[];
  enabled: boolean;
  highlightedId?: string;
  currentMapPath: MapPathModel | null;
}

export function useDrawMapPaths(config: IPolylineConfig) {
  useEffect(() => {
    if (!config.enabled) {
      return;
    }

    if (!config.map) {
      return;
    }

    if (!config.mapPaths.length) {
      return;
    }

    const removeFromMap = (polylines: google.maps.Polyline[]) => {
      polylines.map((p) => p.setMap(null));
    };

    let polylines: google.maps.Polyline[] = [];
    let selectedPolyline: google.maps.Polyline | null = null;

    // if mesh pair is not selected anymore but we have selectedPolyline, remove it
    const selectedPairDisposer = reaction(
      () => config.mapPaths.find((p) => p.mesh.selectedPair),
      (path) => {
        if (!path && selectedPolyline) {
          selectedPolyline.setMap(null);
          selectedPolyline = null;
        }
      }
    );

    const autoRunDisposer = autorun(() => {
      // remove existing polylines on the map
      removeFromMap(polylines);

      // draw polylines
      polylines = config.mapPaths.map((path) => {
        const polyline = new google.maps.Polyline({
          path: path.mesh.points.map((p) => ({ lat: p.latitude, lng: p.longitude })),
          strokeColor: path.color,
          strokeOpacity: (config.highlightedId == null || config.highlightedId === path.id) ? 1 : .5,
          strokeWeight: config.currentMapPath?.id === path.id ? 4 : 3,
          map: config.map,
          zIndex: 2
        });

        // detect click event on polyline so we can edit it
        polyline.addListener('click', function (event: IMapClickEvent) {
          const pair = GeoUtil.getClosestPointPair({
            latitude: event.latLng.lat(),
            longitude: event.latLng.lng(),
          }, path.mesh.points);

          path.mesh.selectPair(pair);

          // remove old polyline
          selectedPolyline?.setMap(null);

          // create new polyline
          selectedPolyline = new google.maps.Polyline({
            path: convertGeolocationToPath(pair),
            strokeColor: colors.warning,
            strokeOpacity: 2,
            strokeWeight: 4,
            map: config.map,
            zIndex: 2,
          });
        });

        return polyline;
      });
    });

    return () => {
      autoRunDisposer();
      selectedPairDisposer();

      removeFromMap(polylines);
      selectedPolyline?.setMap(null);
      selectedPolyline = null;
    };
  }, [config.enabled, config.map, config.mapPaths, config.mapPaths.length, config.highlightedId, config.currentMapPath]);
}
