import { t } from 'i18next';
import { computed, makeObservable, observable } from 'mobx';

import {
  SubscriptionUserResponseDto
} from '../../shared/dto/subscription/groupSubscriptionUser.response.dto';
import { MEMBER_SUBSCRIPTION_STATUS, USER_ROLE_IN_GROUP } from '../../shared/enum';
import { PhotoModel } from './PhotoModel';
import { SubscriptionReceiptResponseModel } from './SubscriptionReceiptResponseModel';

export class GroupSubscriptionUserModel {

  @observable
  public id: string = '';

  @observable
  public firstName: string = '';

  @observable
  public lastName: string = '';

  @observable
  public email: string = '';

  @observable
  public avatar: PhotoModel | null = null;

  @observable
  public userRoleInGroup: USER_ROLE_IN_GROUP = USER_ROLE_IN_GROUP.MEMBER;

  @observable
  public districts: string[] = [];

  @observable
  public subscription: SubscriptionReceiptResponseModel | null = null;

  @observable
  public isPro: boolean = false;

  @observable
  public isYearlySubscription: boolean = false;

  @observable
  public duplicateSubscription: SubscriptionReceiptResponseModel | null = null;

  @observable
  public status: MEMBER_SUBSCRIPTION_STATUS | undefined = undefined;

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: SubscriptionUserResponseDto) => {
    const model = new GroupSubscriptionUserModel();
    model.id = dto.id;
    model.firstName = dto.firstName;
    model.lastName = dto.lastName;
    model.email = dto.email;
    model.avatar = dto.avatar ? PhotoModel.fromDto(dto.avatar) : null;
    model.userRoleInGroup = dto.userRoleInGroup;
    model.districts = dto.districts;
    model.status = dto.status;
    model.isPro = dto.isPro;
    model.isYearlySubscription = dto.isYearlySubscription;
    model.subscription = dto.subscription ? SubscriptionReceiptResponseModel.fromDto(dto.subscription) : null;
    model.duplicateSubscription = dto.duplicateSubscription ? SubscriptionReceiptResponseModel.fromDto(dto.duplicateSubscription) : null;
    return model;
  }

  @computed
  public get key(): string {
    return this.id ? this.id : `${this.id}${Math.random()}`;
  }

  @computed
  public get fullName(): string {
    return `${this.firstName ?? ''} ${this.lastName ?? ''}`.trim();
  }

  @computed
  public get displayName(): string {
    // Check if the seat is unassigned
    if (!this.id) {
      return t('group_subscription:table.table_row.seat.unassigned');
    }

    /**
     * If both firstName and lastName are default placeholders, return the email instead. This indicates that the user was created when they were invited to a group subscription,
     * and after accepting the invitation, they can update their first and last names.
    */
    if (this.firstName === 'firstName' && this.lastName === 'lastName') {
      return this.email;
    }

    return this.fullName;
  }

  @computed
  public get invitationPending(): boolean {
    return this.status === MEMBER_SUBSCRIPTION_STATUS.INVITATION_PENDING;
  }

  @computed
  public get invitationExpired(): boolean {
    return this.status === MEMBER_SUBSCRIPTION_STATUS.INVITATION_EXPIRED;
  }

  @computed
  public get districtsNames(): string {
    return this.districts.join(', ');
  }

  @computed
  public get isPartOfGroup(): boolean {
    return this.userRoleInGroup === USER_ROLE_IN_GROUP.MEMBER || this.userRoleInGroup === USER_ROLE_IN_GROUP.OWNER;
  }

  @computed
  public get firstYearForFree(): boolean {
    return Boolean(this.isYearlySubscription) && !this.isPartOfGroup;
  }

  @computed
  public get proUserNotEligibleForDiscount(): boolean {
    return this.isPro && !this.isYearlySubscription && !this.isPartOfGroup;
  }

}
