import { Assets } from '../../../assets';

export interface IAssetContainer {
  [key: string]: string;
}

export const PinsOrange: IAssetContainer = {
  BLACK_WILDEBEEST: Assets.entry.orange.pin_black_wildebeest,
  ALLIGATOR: Assets.entry.orange.alligator,
  ALPINE_IBEX: Assets.entry.orange.alpine_ibex,
  ANTELOPE: Assets.entry.orange.antelope,
  BABOON: Assets.entry.orange.baboon,
  BANTENG: Assets.entry.orange.banteng,
  BARBARY_SHEEP: Assets.entry.orange.barbary_sheep,
  BIGHORN_SHEEP: Assets.entry.orange.bighorn_sheep,
  BISON: Assets.entry.orange.bison,
  BLACK_BEAR: Assets.entry.orange.black_bear,
  BLACK_TAILED_DEER: Assets.entry.orange.black_tailed_deer,
  BLACKBUCK: Assets.entry.orange.blackbuck,
  BLUE_SHEEP: Assets.entry.orange.blue_sheep,
  BROWN_BEAR: Assets.entry.orange.brown_bear,
  BROWN_HARE: Assets.entry.orange.brown_hare,
  BUSHPIG: Assets.entry.orange.bushpig,
  CAPE_BUFFALO: Assets.entry.orange.cape_buffalo,
  CAUCASIAN_TUR: Assets.entry.orange.caucasian_tur,
  CHAMOIS: Assets.entry.orange.chamois,
  CHINESE_WATER_DEER: Assets.entry.orange.chinese_water_deer,
  CHITAL: Assets.entry.orange.chital,
  COMBINATION_GUN: Assets.entry.orange.combination_gun,
  CROCODILE: Assets.entry.orange.crocodile,
  CROSS_BIRD: Assets.entry.orange.cross_bird,
  DALL_SHEEP: Assets.entry.orange.dall_sheep,
  DUCK: Assets.entry.orange.duck,
  ELAND: Assets.entry.orange.eland,
  EMU: Assets.entry.orange.emu,
  EUROPEAN_BISON: Assets.entry.orange.european_bison,
  FALLOW_DEER: Assets.entry.orange.fallow_deer,
  FOX: Assets.entry.orange.fox,
  GALLIFORMES: Assets.entry.orange.galliformes,
  GOOSE: Assets.entry.orange.goose,
  GRIZZLY_BEAR: Assets.entry.orange.grizzly_bear,
  HOG_DEER: Assets.entry.orange.hog_deer,
  IBERIAN_IBEX: Assets.entry.orange.iberian_ibex,
  KANGAROO: Assets.entry.orange.kangaroo,
  KILLING: Assets.entry.orange.killing,
  MOOSE: Assets.entry.orange.moose,
  MOUFLON: Assets.entry.orange.mouflon,
  MOUNTAIN_GOAT: Assets.entry.orange.mountain_goat,
  MULE_DEER: Assets.entry.orange.mule_deer,
  MUNTJAC: Assets.entry.orange.muntjac,
  MUSKOX: Assets.entry.orange.muskox,
  OSTRICH: Assets.entry.orange.ostrich,
  OTHER_FEATHERED_GAME: Assets.entry.orange.other_feathered_game,
  OTHER_FURRED_GAME: Assets.entry.orange.other_furred_game,
  PHEASANT: Assets.entry.orange.pheasant,
  PISTOL: Assets.entry.orange.pistol,
  PLAINS_ZEBRA: Assets.entry.orange.plains_zebra,
  PRONGHORN_SHEEP: Assets.entry.orange.pronghorn_sheep,
  RACCOON: Assets.entry.orange.raccoon,
  RED_DEER: Assets.entry.orange.red_deer,
  REINDEER: Assets.entry.orange.reindeer,
  REVOLVER: Assets.entry.orange.revolver,
  RIFLE: Assets.entry.orange.rifle,
  ROE_DEER: Assets.entry.orange.roe_deer,
  RUSA_DEER: Assets.entry.orange.rusa_deer,
  SAMBA_DEER: Assets.entry.orange.samba_deer,
  SHOTGUN: Assets.entry.orange.shotgun,
  SIBIRIAN_IBEX: Assets.entry.orange.sibirian_ibex,
  SIKA_DEER: Assets.entry.orange.sika_deer,
  SNOW_SHEEP: Assets.entry.orange.snow_sheep,
  STONE_SHEEP: Assets.entry.orange.stone_sheep,
  TAHR: Assets.entry.orange.tahr,
  TARGET: Assets.entry.orange.target,
  TURTLE_DOVE: Assets.entry.orange.turtle_dove,
  WAPITI: Assets.entry.orange.wapiti,
  WARTHOG: Assets.entry.orange.warthog,
  WATER_BUFFALO: Assets.entry.orange.water_buffalo,
  WHITE_TAILED_DEER: Assets.entry.orange.white_tailed_deer,
  WILD_BOAR: Assets.entry.orange.wild_boar,
  WILD_GOAT: Assets.entry.orange.wild_goat,
  WILD_YAK: Assets.entry.orange.wild_yak,
  WILDEBEEST: Assets.entry.orange.wildebeest,
  WOLF: Assets.entry.orange.wolf,
};

export const PinsGreen: IAssetContainer = {
  ALLIGATOR: Assets.entry.green.alligator,
  BLACK_WILDEBEEST: Assets.entry.green.pin_black_wildebeest,
  ALPINE_IBEX: Assets.entry.green.alpine_ibex,
  ANTELOPE: Assets.entry.green.antelope,
  BABOON: Assets.entry.green.baboon,
  BANTENG: Assets.entry.green.banteng,
  BARBARY_SHEEP: Assets.entry.green.barbary_sheep,
  BIGHORN_SHEEP: Assets.entry.green.bighorn_sheep,
  BISON: Assets.entry.green.bison,
  BLACK_BEAR: Assets.entry.green.black_bear,
  BLACK_TAILED_DEER: Assets.entry.green.black_tailed_deer,
  BLACKBUCK: Assets.entry.green.blackbuck,
  BLUE_SHEEP: Assets.entry.green.blue_sheep,
  BROWN_BEAR: Assets.entry.green.brown_bear,
  BROWN_HARE: Assets.entry.green.brown_hare,
  BUSHPIG: Assets.entry.green.bushpig,
  CAPE_BUFFALO: Assets.entry.green.cape_buffalo,
  CAUCASIAN_TUR: Assets.entry.green.caucasian_tur,
  CHAMOIS: Assets.entry.green.chamois,
  CHINESE_WATER_DEER: Assets.entry.green.chinese_water_deer,
  CHITAL: Assets.entry.green.chital,
  COMBINATION_GUN: Assets.entry.green.combination_gun,
  CROCODILE: Assets.entry.green.crocodile,
  CROSS_BIRD: Assets.entry.green.cross_bird,
  DALL_SHEEP: Assets.entry.green.dall_sheep,
  DUCK: Assets.entry.green.duck,
  ELAND: Assets.entry.green.eland,
  EMU: Assets.entry.green.emu,
  EUROPEAN_BISON: Assets.entry.green.european_bison,
  FALLOW_DEER: Assets.entry.green.fallow_deer,
  FOX: Assets.entry.green.fox,
  GALLIFORMES: Assets.entry.green.galliformes,
  GOOSE: Assets.entry.green.goose,
  GRIZZLY_BEAR: Assets.entry.green.grizzly_bear,
  HOG_DEER: Assets.entry.green.hog_deer,
  IBERIAN_IBEX: Assets.entry.green.iberian_ibex,
  KANGAROO: Assets.entry.green.kangaroo,
  MOOSE: Assets.entry.green.moose,
  MOUFLON: Assets.entry.green.mouflon,
  MOUNTAIN_GOAT: Assets.entry.green.mountain_goat,
  MULE_DEER: Assets.entry.green.mule_deer,
  MUNTJAC: Assets.entry.green.muntjac,
  MUSKOX: Assets.entry.green.muskox,
  OSTRICH: Assets.entry.green.ostrich,
  OTHER_FEATHERED_GAME: Assets.entry.green.other_feathered_game,
  OTHER_FURRED_GAME: Assets.entry.green.other_furred_game,
  PHEASANT: Assets.entry.green.pheasant,
  PISTOL: Assets.entry.green.pistol,
  PLAINS_ZEBRA: Assets.entry.green.plains_zebra,
  PRONGHORN_SHEEP: Assets.entry.green.pronghorn_sheep,
  RACCOON: Assets.entry.green.raccoon,
  RED_DEER: Assets.entry.green.red_deer,
  REINDEER: Assets.entry.green.reindeer,
  REVOLVER: Assets.entry.green.revolver,
  RIFLE: Assets.entry.green.rifle,
  ROE_DEER: Assets.entry.green.roe_deer,
  RUSA_DEER: Assets.entry.green.rusa_deer,
  SAMBA_DEER: Assets.entry.green.samba_deer,
  SHOTGUN: Assets.entry.green.shotgun,
  SIBIRIAN_IBEX: Assets.entry.green.sibirian_ibex,
  SIGHTING: Assets.entry.green.sighting,
  SIKA_DEER: Assets.entry.green.sika_deer,
  SNOW_SHEEP: Assets.entry.green.snow_sheep,
  STONE_SHEEP: Assets.entry.green.stone_sheep,
  TAHR: Assets.entry.green.tahr,
  TURTLE_DOVE: Assets.entry.green.turtle_dove,
  WAPITI: Assets.entry.green.wapiti,
  WARTHOG: Assets.entry.green.warthog,
  WATER_BUFFALO: Assets.entry.green.water_buffalo,
  WHITE_TAILED_DEER: Assets.entry.green.white_tailed_deer,
  WILD_BOAR: Assets.entry.green.wild_boar,
  WILD_GOAT: Assets.entry.green.wild_goat,
  WILD_YAK: Assets.entry.green.wild_yak,
  WILDEBEEST: Assets.entry.green.wildebeest,
  WOLF: Assets.entry.green.wolf,
};

export const PinsGrey: IAssetContainer = {
  FALLBACK: Assets.entry.grey.fallback,
  MISC: Assets.entry.grey.misc,
};

export const WhiteIcons: IAssetContainer = {
  CHI: Assets.whiteIcons.alligator,
  ALLIGATOR: Assets.whiteIcons.alligator,
  ALPINE_IBEX: Assets.whiteIcons.alpine_ibex,
  ANTELOPE: Assets.whiteIcons.antelope,
  BABOON: Assets.whiteIcons.baboon,
  BANTENG: Assets.whiteIcons.banteng,
  BLACK_WILDEBEEST: Assets.whiteIcons.black_wildebeest,
  BARBARY_SHEEP: Assets.whiteIcons.barbary_sheep,
  BIGHORN_SHEEP: Assets.whiteIcons.bighorn_sheep,
  BISON: Assets.whiteIcons.bison,
  BLACK_TAILED_DEER: Assets.whiteIcons.black_tailed_deer,
  BLACK_BEAR: Assets.whiteIcons.black_bear,
  BLACKBUCK: Assets.whiteIcons.blackbuck,
  BLUE_SHEEP: Assets.whiteIcons.blue_sheep,
  BROWN_HARE: Assets.whiteIcons.brown_hare,
  BROWN_BEAR: Assets.whiteIcons.brown_bear,
  BUSHPIG: Assets.whiteIcons.bushpig,
  CAPE_BUFFALO: Assets.whiteIcons.cape_buffalo,
  CAUCASIAN_TUR: Assets.whiteIcons.caucasian_tur,
  CHAMOIS: Assets.whiteIcons.chamois,
  CHINESE_WATER_DEER: Assets.whiteIcons.chinese_waterdeer,
  CHITAL: Assets.whiteIcons.chital,
  CROCODILE: Assets.whiteIcons.crocodile,
  CROSS_BIRD: Assets.whiteIcons.cross_bird,
  DALL_SHEEP: Assets.whiteIcons.dall_sheep,
  DUCK: Assets.whiteIcons.duck,
  ELAND: Assets.whiteIcons.eland,
  EMU: Assets.whiteIcons.emu,
  EUROPEAN_BISON: Assets.whiteIcons.european_bison,
  FALLBACK: Assets.whiteIcons.fallback,
  FALLOW_DEER: Assets.whiteIcons.fallow_deer,
  FOX: Assets.whiteIcons.fox,
  GALLIFORMES: Assets.whiteIcons.galliformes,
  GOOSE: Assets.whiteIcons.goose,
  GRIZZLY_BEAR: Assets.whiteIcons.grizzly_bear,
  HOG_DEER: Assets.whiteIcons.hog_deer,
  IBERIAN_IBEX: Assets.whiteIcons.iberian_ibex,
  KANGAROO: Assets.whiteIcons.kangaroo,
  KILLING: Assets.whiteIcons.killing,
  MISC: Assets.whiteIcons.misc,
  MOOSE: Assets.whiteIcons.moose,
  MOUFLON: Assets.whiteIcons.mouflon,
  MOUNTAIN_GOAT: Assets.whiteIcons.mountain_goat,
  MULE_DEER: Assets.whiteIcons.mule_deer,
  MUNTJAC: Assets.whiteIcons.muntjac,
  MUSKOX: Assets.whiteIcons.muskox,
  OSTRICH: Assets.whiteIcons.ostrich,
  OTHER_FEATHERED_GAME: Assets.whiteIcons.other_feathered_game,
  OTHER_FURRED_GAME: Assets.whiteIcons.other_furred_game,
  PHEASANT: Assets.whiteIcons.pheasant,
  PLAINS_ZEBRA: Assets.whiteIcons.plains_zebra,
  PRONGHORN: Assets.whiteIcons.pronghorn,
  RACCOON: Assets.whiteIcons.raccoon,
  RACCOON_1: Assets.whiteIcons.raccoon_1,
  RED_DEER: Assets.whiteIcons.red_deer,
  REINDEER: Assets.whiteIcons.reindeer,
  ROE_DEER: Assets.whiteIcons.roe_deer,
  RUSA_DEER: Assets.whiteIcons.rusa_deer,
  SAMBA_DEER: Assets.whiteIcons.samba_deer,
  SIBERIAN_IBEX: Assets.whiteIcons.siberian_ibex,
  SIGHTING: Assets.whiteIcons.sighting,
  SIKA_DEER: Assets.whiteIcons.sika_deer,
  SNOW_SHEEP: Assets.whiteIcons.snow_sheep,
  STONE_SHEEP: Assets.whiteIcons.stone_sheep,
  TAHR: Assets.whiteIcons.tahr,
  TURTLE_DOVE: Assets.whiteIcons.turtle_dove,
  WAPITI: Assets.whiteIcons.wapiti,
  WARTHOG: Assets.whiteIcons.warthog,
  WATER_BUFFALO: Assets.whiteIcons.water_buffalo,
  WHITE_TAILED_DEER: Assets.whiteIcons.white_tailed_deer,
  WILD_BOAR: Assets.whiteIcons.wild_boar,
  WILD_GOAT: Assets.whiteIcons.wild_goat,
  WILD_YAK: Assets.whiteIcons.wild_yak,
  WILDEBEEST: Assets.whiteIcons.wildebeest,
  WOLF: Assets.whiteIcons.wolf,
};

export const GrayIcons: IAssetContainer = {
  ALLIGATOR: Assets.grayIcons.alligator,
  ALPINE_IBEX: Assets.grayIcons.alpine_ibex,
  ANTELOPE: Assets.grayIcons.antelope,
  BABOON: Assets.grayIcons.baboon,
  BANTENG: Assets.grayIcons.banteng,
  BLACK_WILDEBEEST: Assets.grayIcons.black_wildebeest,
  BARBARY_SHEEP: Assets.grayIcons.barbary_sheep,
  BIGHORN_SHEEP: Assets.grayIcons.bighorn_sheep,
  BISON: Assets.grayIcons.bison,
  BLACK_TAILED_DEER: Assets.grayIcons.black_tailed_deer,
  BLACK_BEAR: Assets.grayIcons.black_bear,
  BLACKBUCK: Assets.grayIcons.blackbuck,
  BLUE_SHEEP: Assets.grayIcons.blue_sheep,
  BROWN_HARE: Assets.grayIcons.brown_hare,
  BROWN_BEAR: Assets.grayIcons.brown_bear,
  BUSHPIG: Assets.grayIcons.bushpig,
  CAPE_BUFFALO: Assets.grayIcons.cape_buffalo,
  CAUCASIAN_TUR: Assets.grayIcons.caucasian_tur,
  CHAMOIS: Assets.grayIcons.chamois,

  CHINESE_WATER_DEER: Assets.grayIcons.chinese_waterdeer,
  CHITAL: Assets.grayIcons.chital,
  CROCODILE: Assets.grayIcons.crocodile,
  CROSS_BIRD: Assets.grayIcons.cross_bird,
  DALL_SHEEP: Assets.grayIcons.dall_sheep,
  DUCK: Assets.grayIcons.duck,
  ELAND: Assets.grayIcons.eland,
  EMU: Assets.grayIcons.emu,
  EUROPEAN_BISON: Assets.grayIcons.european_bison,
  FALLOW_DEER: Assets.grayIcons.fallow_deer,
  FOX: Assets.grayIcons.fox,
  GALLIFORMES: Assets.grayIcons.galliformes,
  GOOSE: Assets.grayIcons.goose,
  GRIZZLY_BEAR: Assets.grayIcons.grizzly_bear,
  HOG_DEER: Assets.grayIcons.hog_deer,
  IBERIAN_IBEX: Assets.grayIcons.iberian_ibex,
  KANGAROO: Assets.grayIcons.kangaroo,
  MOOSE: Assets.grayIcons.moose,
  MOUFLON: Assets.grayIcons.mouflon,
  MOUNTAIN_GOAT: Assets.grayIcons.mountain_goat,
  MULE_DEER: Assets.grayIcons.mule_deer,
  MUNTJAC: Assets.grayIcons.muntjac,
  MUSKOX: Assets.grayIcons.muskox,
  OSTRICH: Assets.grayIcons.ostrich,
  OTHER_FEATHERED_GAME: Assets.grayIcons.other_feathered_game,
  OTHER_FURRED_GAME: Assets.grayIcons.other_furred_game,
  PHEASANT: Assets.grayIcons.pheasant,
  PLAINS_ZEBRA: Assets.grayIcons.plains_zebra,
  PRONGHORN: Assets.grayIcons.pronghorn,
  RACCOON: Assets.grayIcons.raccoon,
  RACCOON_1: Assets.grayIcons.raccoon_1,
  RED_DEER: Assets.grayIcons.red_deer,
  REINDEER: Assets.grayIcons.reindeer,
  ROE_DEER: Assets.grayIcons.roe_deer,
  RUSA_DEER: Assets.grayIcons.rusa_deer,
  SAMBA_DEER: Assets.grayIcons.samba_deer,
  SIBERIAN_IBEX: Assets.grayIcons.siberian_ibex,
  SIKA_DEER: Assets.grayIcons.sika_deer,
  SNOW_SHEEP: Assets.grayIcons.snow_sheep,
  STONE_SHEEP: Assets.grayIcons.stone_sheep,
  TAHR: Assets.grayIcons.tahr,
  TURTLE_DOVE: Assets.grayIcons.turtle_dove,
  WAPITI: Assets.grayIcons.wapiti,
  WARTHOG: Assets.grayIcons.warthog,
  WATER_BUFFALO: Assets.grayIcons.water_buffalo,
  WHITE_TAILED_DEER: Assets.grayIcons.white_tailed_deer,
  WILD_BOAR: Assets.grayIcons.wild_boar,
  WILD_GOAT: Assets.grayIcons.wild_goat,
  WILD_YAK: Assets.grayIcons.wild_yak,
  WILDEBEEST: Assets.grayIcons.wildebeest,
  WOLF: Assets.grayIcons.wolf,
};
