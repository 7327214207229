import { ApiProperty } from '@nestjs/swagger';
import { ShootingAngleType, ShootingDirectionType } from '../types/shootingDirection';

import { BookingResponseDto } from './booking.response.dto';
import { CustomMarkResponseDto } from './customMark.response.dto';
import { PhotoResponseDto } from './photo.response.dto';
import { CheckInPoiLogResponseDto } from './poi/checkinpoilog.response.dto';
import { PoiFeatureResponseDto } from './poiFeature.response.dto';
import { TaskResponseDto } from './task.response.dto';
import { UserResponseDto } from './user.response.dto';

export class PoiResponseDto {

  @ApiProperty()
  public id: string;

  @ApiProperty()
  public name: string;

  @ApiProperty()
  public type: string;

  @ApiProperty()
  public lat: number;

  @ApiProperty()
  public long: number;

  @ApiProperty()
  public districtId?: string;

  @ApiProperty({ type: () => PhotoResponseDto, required: false })
  public photo?: PhotoResponseDto;

  @ApiProperty({ type: () => PhotoResponseDto, isArray: true })
  public photos: PhotoResponseDto[];

  @ApiProperty({ type: TaskResponseDto, isArray: true })
  public tasks: TaskResponseDto[];

  @ApiProperty({ type: BookingResponseDto })
  public booking?: BookingResponseDto;

  @ApiProperty()
  public blocked: boolean;

  @ApiProperty()
  public lastBooking?: string; // ISO string

  @ApiProperty({ type: CustomMarkResponseDto })
  public customMark?: CustomMarkResponseDto;

  @ApiProperty({ type: PoiFeatureResponseDto })
  public features: PoiFeatureResponseDto;

  @ApiProperty()
  public meta: any;

  @ApiProperty()
  public description?: string;

  @ApiProperty({ description: 'event ID happenning in POIs district or worldmap events that overlaps with POIs location. Currently only populated in GET api/event/:eventId/pois' })
  public eventId?: string;

  @ApiProperty()
  public ownerId: string;

  @ApiProperty()
  public shootingDirection?: ShootingDirectionType;

  @ApiProperty()
  public shootingAngle?: ShootingAngleType;

  @ApiProperty()
  public reserved: boolean;

  @ApiProperty({ description: 'User who checked out this particular POI' })
  public userReservedPoi?: UserResponseDto;

  @ApiProperty({ description: 'Array of users who are checked in on this POI' })
  public usersCheckedIn?: CheckInPoiLogResponseDto[];

}
