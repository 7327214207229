import { format } from 'date-fns';
import Avatar from 'react-avatar';

import { Grid, Typography } from '@mui/material';

import { PoiCheckinHistoryModel } from '../../../../../../domain/model/PoiCheckinHistoryModel';
import { useTranslation } from 'react-i18next';

interface CheckinLineItemProps {
  poiCheckin: PoiCheckinHistoryModel;
}

export const CheckinLineItem = function CheckinLineItem({ poiCheckin }: CheckinLineItemProps) {
  const { t } = useTranslation();

  return (
    <>
      <Grid container alignItems="center">
        <Grid item>
          {<Avatar
            name={poiCheckin.user.fullName}
            src={poiCheckin.user.photo?.url || ''}
            round
            size="40px"
          />}
        </Grid>
        <Grid item marginLeft={1} flex={1}>
          <Typography variant="subtitle1" fontWeight="bold">{poiCheckin.user.fullName}</Typography>
          {!poiCheckin.to
            ? <Typography variant="body1">{t('check_ins:checked_since')}{format(new Date(poiCheckin.from), 'dd/MM/yyyy')}</Typography>
            : <Typography variant="body1">{t('check_ins:last_checked')}{format(new Date(poiCheckin.to), 'dd/MM/yyyy')}</Typography>
          }
        </Grid>
      </Grid>
    </>
  );
};
