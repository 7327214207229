import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem,
  Tooltip, Typography
} from '@mui/material';

import { useInstance } from '../../../../../../../domain/hooks/useInstance';
import { EntryModel } from '../../../../../../../domain/model/EntryModel';
import { EntryHelper } from '../../../../../../../util/EntryHelper';
import { DistrictViewListItem } from '../DistrictViewListItem';
import { MenuItemTooltip } from '../MenuItemTooltip';

interface IProps {
  entry: EntryModel;
  onEdit: (entry: EntryModel) => void;
  onDelete: (entry: EntryModel) => void;
  canEditOrDeleteEntry: boolean;
  lastItem: boolean;
}

export function DistrictEntryListItem(props: IProps) {
  const entryHelper = useInstance(EntryHelper);
  const { t } = useTranslation();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const [openDialog, setOpenDialog] = React.useState(false);

  const showMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const edit = useCallback((event: React.MouseEvent) => {
    closeMenu(event);
    props.onEdit(props.entry);
  }, [closeMenu, props]);

  const handleOpenDeleteDialog = useCallback(() => setOpenDialog(true), []);

  const handleCloseDeleteDialog = useCallback((event: React.MouseEvent) => {
    setOpenDialog(false);
    closeMenu(event);
  }, [closeMenu]);

  return (
    <DistrictViewListItem
      image={entryHelper.getEntryIcon(props.entry, false)}
      firstLine={props.entry.owner?.fullName ?? ''}
      secondLine={entryHelper.getEntryTitle(props.entry)}
      thirdLine={props.entry.dateTime}
      withDivider={!props.lastItem}
      rightComponent={<>
        <Button
          onClick={showMenu}
          color="secondary"
        >
          {t('district:options_dropdown')}
        </Button>
        <Menu
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={closeMenu}
        >
          {/* EDIT ENTRY */}
          {props.canEditOrDeleteEntry ?
            <MenuItem
              onClick={edit}
              disabled={!props.canEditOrDeleteEntry}
            >{t('entry:menu_option.edit_entry')}</MenuItem>
            : <Tooltip placement="right" title={<MenuItemTooltip message={t('entry:menu_option.edit_entry_tooltip')} />}>
              <div>
                <MenuItem
                  onClick={edit}
                  disabled={!props.canEditOrDeleteEntry}
                >{t('entry:menu_option.edit_entry')}</MenuItem>
              </div>
            </Tooltip>}

          {/* DELETE ENTRY */}
          {props.canEditOrDeleteEntry ?
            <MenuItem
              onClick={handleOpenDeleteDialog}
              disabled={!props.canEditOrDeleteEntry}
            >{t('entry:menu_option.delete_entry')}</MenuItem>
            : <Tooltip placement="right" title={<MenuItemTooltip message={t('entry:menu_option.delete_entry_tooltip')} />}>
              <div>
                <MenuItem
                  onClick={handleOpenDeleteDialog}
                  disabled={!props.canEditOrDeleteEntry}
                >{t('entry:menu_option.delete_entry')}</MenuItem>
              </div>
            </Tooltip>}

          <Dialog open={openDialog} onClose={handleCloseDeleteDialog}>
            <DialogTitle sx={{ fontSize: '1.125rem', fontWeight: '500' }}>
              {t('entry:delete.title')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText variant="subtitle1">{t('entry:delete.description')}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog}>
                <Typography variant="subtitle2">{t('entry:delete.disagree')}</Typography>
              </Button>
              <Button
                onClick={(event: React.MouseEvent) => {
                  props.onDelete(props.entry);
                  handleCloseDeleteDialog(event);
                }}
                autoFocus
              ><Typography variant="subtitle2" color={'text.hunter_green'}>{t('entry:delete.agree')}</Typography>
              </Button>
            </DialogActions>
          </Dialog>
        </Menu>
      </>}
    />
  );
}
