export enum POI_TYPE {
  HIDEOUT = 'HIDEOUT',
  FEEDER = 'FEEDER',
  BAIT_SEAT = 'BAIT_SEAT',
  BAIT_SEAT_MEAT = 'BAIT_SEAT_MEAT',
  TRAP = 'TRAP',
  EARTHWORK = 'EARTHWORK',
  CAMERA = 'CAMERA',
  WEATHER = 'WEATHER',
  CUSTOM = 'CUSTOM',
  SPOT = 'SPOT',
  BLIND = 'BLIND',
  NEST = 'NEST',
  UNKNOWN = 'UNKNOWN'
}
