import { Box } from '@mui/material';
import React from 'react';
import { InfoComponent } from '../../../../../toolkit/components/InfoComponent';

export function MapPathPointsSelection() {
  return (
    <Box width="100%">
      <InfoComponent
        locizePath="paths:path_points_help_text"
      />
    </Box>
  );
}
