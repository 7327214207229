import { inject } from 'inversify';
import { computed, makeObservable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import { ViewModel } from '../../../../domain/core/ViewModel';
import { AuthProxy } from '../../../../domain/proxy/AuthProxy';
import { I18nService } from '../../../../domain/service/I18nService';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { TrackingEvent } from '../../../../domain/service/tracking/TrackingEvent';
import { RegistrationVia } from '../../../../domain/service/tracking/TrackingEventParams';
import { TrackingService } from '../../../../domain/service/tracking/TrackingService';
import { transient } from '../../../../inversify/decorator';
import { Types } from '../../../../inversify/types';
import { AppRoutes } from '../../../../router/AppRoutesEnum';
import { LANGUAGE } from '../../../../shared/enum';
import { AuthService } from '../../AuthService';
import {
  OAuthType
} from '../../register/components/social-media-registration/SocialMediaRegistrationRoute';
import { IAppleLoginButtonProps } from './AppleLoginButton';

interface IAppleLoginResponse {
  authorization: {
    code: string;
    id_token: string;
  },
  user?: {
    email: string;
    name?: {
      firstName: string;
      lastName: string;
    }
  }
}

@transient()
export class AppleLoginButtonVm extends ViewModel<IAppleLoginButtonProps> {

  constructor(
    @inject(AuthService) private readonly authService: AuthService,
    @inject(AuthProxy) private readonly authProxy: AuthProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get fromLocation(): string {
    const existingRoute = Object.values(AppRoutes).find(val => val === this.props.fromLocation);
    return existingRoute ?? AppRoutes.Map;
  }

  public appleLogin = async (data: IAppleLoginResponse) => {
    try {
      /* Check if user already exists. */
      const response = await this.authService.startLoginRequest(OAuthType.Apple, data.authorization.code);
      const event = !response?.ok ? TrackingEvent.REGISTRATION_STARTED : TrackingEvent.LOGIN_STARTED;

      if (!response?.ok) {
        return this.navigate(AppRoutes.SocialMediaRegister, {
          state: {
            access_token: data.authorization.code,
            firstName: data.user?.name?.firstName,
            lastName: data.user?.name?.lastName,
            email: data.user?.email,
            language: this.i18n.language as LANGUAGE,
            type: OAuthType.Apple,
          }
        });
      }

      await this.tracking.track(event, { registrationType: RegistrationVia.APPLE });

      await this.tracking.track(TrackingEvent.LOGIN_COMPLETED, { registrationType: RegistrationVia.APPLE });

      return this.navigate(event === TrackingEvent.REGISTRATION_STARTED ? AppRoutes.Map : this.fromLocation);
    } catch (e) {
      console.error(`unexpected exception. ${e}`);
      this.notification.error(this.i18n.t('auth:socialmedia_button.apple_error'));
    }
  }

  @computed
  public get isBusy() {
    return this.authProxy.loginAuthViaApple.isBusy;
  }

  public appleLoginError = () => {
    this.notification.error(this.i18n.t('auth:socialmedia_button.apple_failure'));
  }
}
