import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

interface IPriceDisplayProps {
  isDiscounted: boolean;
  originalPrice: number;
  finalPrice: number;
  currency: string;
}

export const PriceDisplay: React.FC<IPriceDisplayProps> = function PriceDisplay({ isDiscounted, originalPrice, finalPrice, currency }: IPriceDisplayProps) {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));

  return isDiscounted ? (
    <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'}>
      <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.secondary} sx={{ textDecoration: 'line-through' }}>
        {originalPrice.toFixed(2)}{currency}
      </Typography>&nbsp;
      <Typography variant={isMobileSize ? 'text_md_semibold' : 'text_sm_semibold'} color={theme.palette.text.primary}>
        {finalPrice.toFixed(2)}{currency}
      </Typography>&nbsp;
    </Box>
  ) : (
    <Typography variant={isMobileSize ? 'text_md_semibold' : 'text_sm_semibold'} color={theme.palette.text.primary}>
      {originalPrice.toFixed(2)}{currency}
    </Typography>
  );
};
