import { ApiProperty } from '@nestjs/swagger';
import { AmmunitionResponseDto } from './ammunition.response.dto';
import { GunPodResponseDto } from './gunPod.response.dto';
import { WeaponResponseDto } from './weapon.response.dto';

export class GearSetResponseDto {
  @ApiProperty()
  public id: string;

  @ApiProperty()
  public name: string;

  @ApiProperty()
  public notes: string | undefined;

  @ApiProperty()
  public isPublic: boolean;

  @ApiProperty({ type: WeaponResponseDto, isArray: true })
  public weapons: WeaponResponseDto[] = [];

  @ApiProperty({ type: AmmunitionResponseDto, isArray: true })
  public ammunition: AmmunitionResponseDto[] = [];

  @ApiProperty({ type: GunPodResponseDto })
  public gunPod: GunPodResponseDto | undefined;
}
