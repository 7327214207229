import DeleteIcon from '@mui/icons-material/Delete';
import {
  Avatar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, Grid, Typography
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Assets } from '../../../../../../../assets';
import { useVm } from '../../../../../../../domain/hooks/useVm';
import { DistrictModel } from '../../../../../../../domain/model/DistrictModel';
import { PoiModel } from '../../../../../../../domain/model/PoiModel';
import { TaskModel } from '../../../../../../../domain/model/TaskModel';
import { palette } from '../../../../../../../theme/options/palette/palette';
import { PoiMarker } from '../../../marker/PoiMarker';
import { TaskListItemVm } from './TaskListItemVm';



export interface ITaskListItemProps {
  task: TaskModel;
  district: DistrictModel | null;
  poi?: PoiModel | undefined;
  pois: PoiModel[];
  onEditTask: (task: TaskModel) => void;
  onDeleteTask: (task: TaskModel) => void;
  markAsCompleted: (task: TaskModel) => void;
}

export const TaskListItem = observer(function TaskListItem(props: ITaskListItemProps) {
  const { t } = useTranslation();
  const vm = useVm(TaskListItemVm, props);
  const [open, setOpen] = React.useState(false);

  const handleOpenDeleteDialog = useCallback(() => setOpen(true), []);

  const handleCloseDeleteDialog = useCallback(() => setOpen(false), []);

  const renderDueDate = useCallback(() => (
    props.task.dueDate
      ? (vm.hasCustomDueDateText
        ? <>
          <Typography variant="subtitle2" color="red" sx={{ display: 'inline-block' }}>
            {`${vm.getDueDateText}`} &nbsp;
          </Typography>
          <Typography variant="subtitle2" color={palette.text?.details} sx={{ display: 'inline-block' }}>
            {` / ${vm.dateUntil}`}
          </Typography>
        </>
        :
        <Typography variant="subtitle2" color={palette.text?.details}>
          {`${t('task:date_until')}: ${vm.dateUntil}`}
        </Typography>)
      : null
  ), [props.task.dueDate, t, vm.dateUntil, vm.getDueDateText, vm.hasCustomDueDateText]);

  return (
    <Box display="flex" justifyContent={'space-between'} marginY={1}>
      <Grid container display="flex" sx={{ cursor: 'pointer' }} onClick={() => props.onEditTask(props.task)} rowGap={1}>
        <Grid item container spacing={2} alignItems="center" flexWrap="nowrap">
          <Grid item>
            {vm.linkedPoi && <PoiMarker
              poi={vm.linkedPoi}
              lng={0}
              lat={0}
              opacity={1}
              skiptransform={true}
              hideTooltip
              hideShootingDirection
            />}
          </Grid>
          <Grid item>
            <Typography variant="h6">{props.task.description}</Typography>
            <Typography variant="subtitle1">{props.task.details}</Typography>
            <Typography variant="subtitle2" color={palette.text?.details}>{vm.linkedPoiName}</Typography>

            {/* NO ASSIGNEE */}
            {vm.assigneesList.length === 0 && renderDueDate()}
          </Grid>
        </Grid>

        {/* ONE ASSIGNEE */}
        {vm.assigneesList.length === 1 &&
          <Grid item container spacing={2} alignItems="center">
            <Grid item>
              <Avatar
                src={vm.assignee?.photo ? vm.assignee?.photo?.thumbnailUrl : Assets.poi.task.unassigned}
                sx={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" color={palette.text?.details}>
                {vm.assignee?.fullName}
              </Typography>
              {renderDueDate()}
            </Grid>
          </Grid>}

        {/* MULTIPLE ASSIGNEES */}
        {vm.assigneesList.length > 1 &&
          <Grid item container spacing={2} alignItems="center">
            <Grid item>
              <Avatar
                src={Assets.multiple_users}
                sx={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid item>
              <Typography variant="subtitle2" color={palette.text?.details}>
                {t('task:multiple_assignees')}
              </Typography>
              {renderDueDate()}
            </Grid>
          </Grid>}
      </Grid>

      <Box display="flex" flexDirection="column" alignItems="center">
        <Checkbox
          size="medium"
          defaultChecked={props.task.done}
          value={props.task.done}
          onChange={() => props.markAsCompleted(props.task)}
          color="secondary"
        />
        <DeleteIcon cursor="pointer" fontSize='small' onClick={handleOpenDeleteDialog} />
        <Dialog
          open={open}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{ fontSize: '1.125rem', fontWeight: '500' }}>
            {t('task:delete.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant="subtitle1" id="alert-dialog-description">{t('task:delete.description')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog}>
              <Typography variant="subtitle2">{t('task:delete.disagree')}</Typography>
            </Button>
            <Button
              onClick={() => {
                props.onDeleteTask(props.task);
                handleCloseDeleteDialog();
              }}
              autoFocus
            ><Typography variant="subtitle2" color={'text.hunter_green'}>{t('task:delete.agree')}</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
});
