import { observable } from 'mobx';

import { DistanceShotResponseDto } from '../../shared/dto/distance.shot.response.dto';
import { MEASUREMENT_SYSTEM } from '../../shared/enum';

export class ShotDistanceModel {

  @observable
  public unit: MEASUREMENT_SYSTEM;

  @observable
  public amount: number | null;

  public static fromDto = (dto: DistanceShotResponseDto) => {
    const instance = new ShotDistanceModel();
    instance.amount = dto.amount;
    instance.unit = dto.unit;

    return instance;
  }
}
