import html2canvas from 'html2canvas';
import { inject } from 'inversify';
import { jsPDF } from 'jspdf';
import { action, computed, makeObservable, observable } from 'mobx';
import { NumberSize } from 're-resizable';
import { Direction } from 're-resizable/lib/resizer';

import Logo from '../../../../src/assets/img/print_map_logo.png';
import QR_code from '../../../../src/assets/img/print_map_qr_code.png';
import { AsyncTask } from '../../../domain/async/AsyncTask';
import { ViewModel } from '../../../domain/core/ViewModel';
import { DistrictModel } from '../../../domain/model/DistrictModel';
import { SessionStore } from '../../../domain/store/SessionStore';
import { scoped } from '../../../inversify/decorator';
import { SCREENSHOT_ORIENTATION_ENUM } from '../../../shared/enum/screenshot.orientation.enum';
import { colors } from '../../../theme/options/palette/const/colors';
import { GoogleMapVm } from '../components/google-map/GoogleMapVm';
import { PaperSizeEnum } from './components/PaperSizeTabs';
import { aspectRatio, ElementDimensions, resizerElementDefaults } from './constants/constants';
import { getPaperDimensionsInMM } from './helpers/helpers';

interface IOverlayCoordinates {
  north: number;
  west: number;
  south: number;
  east: number;
}

@scoped()
export class PrintMapVm extends ViewModel {

  @observable
  public isPrintMapActive: boolean = false;

  @observable
  public orientation: SCREENSHOT_ORIENTATION_ENUM = SCREENSHOT_ORIENTATION_ENUM.PORTRAIT;

  @observable
  public paperSize: PaperSizeEnum = PaperSizeEnum.A4;

  @observable
  public overlayCoordinates: IOverlayCoordinates | null = null;

  @observable
  public resizerElementDimensions: ElementDimensions = {
    width: resizerElementDefaults[SCREENSHOT_ORIENTATION_ENUM.PORTRAIT].width,
    height: resizerElementDefaults[SCREENSHOT_ORIENTATION_ENUM.PORTRAIT].height,
  };

  @observable
  public hideElements: boolean = false;

  constructor(
    @inject(GoogleMapVm) private readonly googleMapVm: GoogleMapVm,
    @inject(SessionStore) public readonly session: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get multiplier(): number {
    switch (this.paperSize) {
      case PaperSizeEnum.A0:
      case PaperSizeEnum.A1:
        return 2.5;
      case PaperSizeEnum.A2:
        return 1.5;
      case PaperSizeEnum.A3:
      case PaperSizeEnum.A4:
        return 1;
      default:
        return 1;
    }
  }

  @computed
  private get isLandscape(): boolean {
    return this.orientation === SCREENSHOT_ORIENTATION_ENUM.LANDSCAPE;
  }

  @computed
  public get mapContainerDimensions(): ElementDimensions | undefined {
    const mapContainer = document.getElementById('map-wrapper');

    if (!mapContainer) return;

    return {
      width: mapContainer.getBoundingClientRect().width,
      height: mapContainer.getBoundingClientRect().height,
    };
  }

  @computed
  public get resizerMaxDimensions(): ElementDimensions | undefined {
    if (!this.mapContainerDimensions) return;
    return {
      width: this.mapContainerDimensions.width - 200,
      height: this.mapContainerDimensions.height - 100
    };
  }

  @computed
  public get defaultDimensions(): Record<SCREENSHOT_ORIENTATION_ENUM, ElementDimensions> {
    if (!this.mapContainerDimensions) {
      return resizerElementDefaults;
    }

    const portraitHeight = this.mapContainerDimensions.height - (this.mapContainerDimensions.height * 0.1);
    const portraitWidth = portraitHeight / aspectRatio;

    const landscapeHeight = this.mapContainerDimensions.height - (this.mapContainerDimensions.height * 0.2);
    const landscapeWidth = landscapeHeight * aspectRatio;

    return {
      [SCREENSHOT_ORIENTATION_ENUM.LANDSCAPE]: {
        height: landscapeHeight,
        width: landscapeWidth,
      },
      [SCREENSHOT_ORIENTATION_ENUM.PORTRAIT]: {
        height: portraitHeight,
        width: portraitWidth,
      }
    };
  }

  @computed
  public get resizerElementBounds() {
    const resizerElement = document.getElementById('resizer');

    if (resizerElement && this.mapContainerDimensions) {
      const { width, height } = resizerElement.getBoundingClientRect();
      this.setResizerElementDimensions({ width, height });

      // * offset values in relation to 'map-wrapper'
      return {
        offsetTop: resizerElement.offsetTop,
        offsetLeft: resizerElement.offsetLeft,
        offsetRight: this.mapContainerDimensions.width - (resizerElement.offsetLeft + resizerElement.offsetWidth),
        offsetBottom: this.mapContainerDimensions.height - (resizerElement.offsetHeight + resizerElement.offsetTop),
      };
    }

    return null;
  }

  // u stvarnosti, ovo nista ne radi :D
  public setBoundsForScreenshot = () => {
    if (this.resizerElementBounds && this.googleMapVm.map) {

      const overlay = new google.maps.OverlayView();
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      overlay.draw = function () { };
      overlay.setMap(this.googleMapVm.map);
      const ne = overlay.getProjection().fromContainerPixelToLatLng(new google.maps.Point(this.resizerElementBounds.offsetRight, this.resizerElementBounds.offsetTop));
      const sw = overlay.getProjection().fromContainerPixelToLatLng(new google.maps.Point(this.resizerElementBounds.offsetLeft, this.resizerElementBounds.offsetBottom));
      const resizerLatLngBounds = new google.maps.LatLngBounds(sw, ne);

      // Fit the map to the resizable box
      this.googleMapVm.map.fitBounds(resizerLatLngBounds);

    }

  }

  @action
  public setResizerElementDimensions = (dim: ElementDimensions) => {
    this.resizerElementDimensions = dim;
  }

  @action
  public toggleHideElements = () => {
    if (this.isPrintMapActive) {
      this.hideElements = !this.hideElements;
    }
  }

  @action
  public toggleIsPrintMapActive = () => {
    this.isPrintMapActive = !this.isPrintMapActive;
    this.setOrientation(SCREENSHOT_ORIENTATION_ENUM.PORTRAIT);
    this.setPaperSize(PaperSizeEnum.A4);
  }

  @action
  public setPaperSize = (paperSize: PaperSizeEnum) => {
    this.paperSize = paperSize;
  }

  @action
  public setOrientation = (orientation: SCREENSHOT_ORIENTATION_ENUM) => {
    this.orientation = orientation;

    this.setResizerElementDimensions(this.defaultDimensions[this.orientation]);
  }

  @action
  public setOverlayCoordinates = (overlayCoordinates: IOverlayCoordinates) => {
    this.overlayCoordinates = overlayCoordinates;
  }

  @action
  private turnOffMapPreview = () => {
    this.isPrintMapActive = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public handleOverlayChange = (_event: MouseEvent | TouchEvent, _direction: Direction, _elementRef: HTMLElement, _delta: NumberSize) => {
    if (!this.resizerElementBounds) return;

    this.toggleHideElements();

    this.setOverlayCoordinates({
      north: this.resizerElementBounds.offsetTop,
      west: this.resizerElementBounds.offsetLeft,
      south: this.resizerElementDimensions.height + this.resizerElementBounds.offsetTop,
      east: this.resizerElementDimensions.width + this.resizerElementBounds.offsetLeft,
    });

  }

  public onGeneratePDF = new AsyncTask(async (district: DistrictModel | null) => {
    const mapElement = document.getElementById('map-wrapper');
    if (!mapElement) return;

    const paperSize = getPaperDimensionsInMM(this.paperSize);

    await html2canvas(mapElement, {
      scrollY: -window.scrollY,
      windowWidth: document.documentElement.offsetWidth,
      windowHeight: document.documentElement.offsetHeight,
      x: this.resizerElementBounds?.offsetLeft,
      y: this.resizerElementBounds?.offsetTop,
      width: this.resizerElementDimensions.width,
      height: this.resizerElementDimensions.height,
      scale: 5,
      allowTaint: true,
      useCORS: true,
    }).then((canvas: HTMLCanvasElement) => {
      const pdf = new jsPDF({
        orientation: this.isLandscape ? 'landscape' : 'portrait',
        unit: 'mm',
        format: [paperSize.width, paperSize.height]
      });
      const imageData = canvas.toDataURL('image/jpeg', 1.0);
      pdf.addImage(imageData, 'JPEG', 0, 0, this.isLandscape ? paperSize.height : paperSize.width, this.isLandscape ? paperSize.width : paperSize.height);

      // SET TITLE
      pdf.setFont('helvetica', 'normal', 'normal');
      const fontSize = this.multiplier === 1 ? 25 : (this.multiplier * 25);
      pdf.setFontSize(fontSize);
      pdf.setTextColor(colors.paper);
      const paddingTop = this.multiplier === 1 ? 12 : (this.multiplier * 12 * 0.8);
      pdf.text(district?.name || 'Map', 5, paddingTop);

      // SET STICKERS (LOGO, QR CODE)
      const stickerSize = 20 * this.multiplier;
      const pageWidth = pdf.internal.pageSize.getWidth(); //* paperSize.width
      const pageHeight = pdf.internal.pageSize.getHeight(); //* paperSize.height
      const xPos = pageWidth - stickerSize - 25 * this.multiplier;
      const yPos = pageHeight - stickerSize - 5 * this.multiplier;
      pdf.addImage(Logo, 'png', xPos, yPos, stickerSize, stickerSize);
      pdf.addImage(QR_code, 'png', xPos + 22 * this.multiplier, yPos, stickerSize, stickerSize);

      pdf.save(`${district?.name || 'map'}.pdf`);

      this.turnOffMapPreview();
    });
  });

}
