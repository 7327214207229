import { computed, makeObservable, observable } from 'mobx';

import { createUUID } from '../../../__test__/uuid';
import { WeatherValueUnitItemResponseDto } from '../../../shared/dto/weather/weatherValueUnitItem.response.dto';

export class TemperatureModel {
  @observable
  public id: string;

  @observable
  public value: number | null;

  @observable
  public units: string;

  constructor() {
    makeObservable(this);
  }

  @computed
  public get formattedTemperature() {
    return this.format();
  }

  public format() {
    const value = Math.round(this.value ?? 0);

    if (this.units === 'C') {
      return `${value}°`;
    }

    return `${value}${this.units}`;
  }

  public static fromDto = (dto: WeatherValueUnitItemResponseDto) => {
    const model = new TemperatureModel();

    model.id = createUUID();
    model.value = dto.value;
    model.units = dto.units;

    return model;
  }

  public toDto = () => {
    const dto = new WeatherValueUnitItemResponseDto(this.value, this.units);

    return dto;
  }
}
