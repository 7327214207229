import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Grid, TextField, Typography } from '@mui/material';

import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import { AssignAreaMembersModalVm } from '../assign-area-members-modal/AssignAreaMembersModalVm';

interface IAssignMemberViaEmailProps {
  vm: AssignAreaMembersModalVm;
}

export const AssignMemberViaEmail = observer(function AssignMemberViaEmail({ vm }: IAssignMemberViaEmailProps) {
  const { theme } = useThemeContext();
  const { t } = useTranslation();
  const emailError = vm.emailErrorMsg;

  const {
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
    }
  });

  const validationRules = useMemo(() => ({
    required: t('group_subscription:modal.input.email_required'),
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: t('group_subscription:modal.input.email_invalid'),
    }
  }), [t]);


  useEffect(() => {
    vm.setIsEmailValid(isValid);
  }, [isValid, vm]);

  return (
    <Grid container justifyContent={'flex-start'} gap={2} paddingBottom={2}>
      <Grid item container>
        <Grid item xs={12}>
          <Typography variant='text_sm_regular' color={theme.palette.text.primary}>
            {t('group_subscription:modal.input.email')}
          </Typography>
        </Grid>
        <Grid item xs={12} marginTop={0.5}>
          <Controller
            name="email"
            control={control}
            rules={validationRules}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{
                  '& input': {
                    padding: '10px 0',
                    height: '100%',
                    boxSizing: 'border-box',
                  },
                  '& input::placeholder': {
                    lineHeight: 'normal',
                  },
                  marginBottom: emailError || errors.email ? 1 : 0,
                }}
                fullWidth
                placeholder={t('group_subscription:modal.input.email')}
                InputLabelProps={{ style: { textTransform: 'none', } }}
                error={!!errors.email || !!emailError}
                helperText={emailError || (errors.email ? errors.email.message : '')}
                onChange={(event) => {
                  field.onChange(event);
                  vm.setUserEmail(event.target.value);
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
});
