import { inject } from 'inversify';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { Photo } from 'react-photo-album';

import { AsyncTask } from '../../domain/async/AsyncTask';
import { ViewModel } from '../../domain/core/ViewModel';
import { EntryModel } from '../../domain/model/EntryModel';
import { PhotoModel } from '../../domain/model/PhotoModel';
import { EntryProxy } from '../../domain/proxy/EntryProxy';
import { NotificationService } from '../../domain/service/NotificationService';
import { SessionStore } from '../../domain/store/SessionStore';
import { transient } from '../../inversify/decorator';
import { FEATURE } from '../../shared/enum';

export interface IGalleryImage extends Photo {
  photo: PhotoModel;
  isHiddenForNonProUsers?: boolean;
  data?: EntryModel;
}

interface ImageDimensions {
  imageHeight: number;
  imageWidth: number;
}

@transient()
export class ImageGalleryVm extends ViewModel {

  @observable
  public allEntries: EntryModel[] = [];

  @observable
  public filterByOwner: boolean = false;

  @observable
  public slides: IGalleryImage[] = [];

  @observable
  public index: number = -1;

  constructor(
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(EntryProxy) private readonly entryProxy: EntryProxy,
    @inject(SessionStore) public readonly session: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  public override async onInit() {
    this.getAllEntries.run();
    reaction(
      () => this.entriesWithPhotos,
      () => this.setDimensionsOnPhotos(),
    );
  }

  @action
  public setIndex = (index: number) => {
    this.index = index;
  }

  @action
  public setAllEntries = (entries: EntryModel[]) => {
    this.allEntries = entries;
  }

  @computed
  private get canSeeAllEntryImages() {
    return this.session.hasFeatureEnabled(FEATURE.MULTIPLE_IMAGES_VIEW);
  }

  @computed
  public get entries(): EntryModel[] {
    return this.allEntries
      // if we didn't sort array we would have to slice to get array from observableArray
      .slice()
      .filter((item) => !this.filterByOwner || !item.owner || item.owner.id === this.session.userId)
      .sort((item1, item2) => {
        if (item1.userDate && item2.userDate) {
          return item2.userDate!.valueOf() - item1.userDate!.valueOf();
        }

        return 0;
      });
  }

  @computed
  public get entriesWithPhotos(): EntryModel[] {
    return this.entries.filter((entry: EntryModel) => entry.photos.length);
  }

  @computed
  public get entryImages(): IGalleryImage[] {
    /**  because of reaction in onInit(), each entry in `this.entriesWithPhotos` have `photos` updated with height and width  */
    const images = this.entriesWithPhotos.map((entry: EntryModel) => {
      return entry.photos.map((photo: PhotoModel, index: number) => ({
        src: photo.url!,
        width: photo.width,
        height: photo.height,
        photo: photo,
        isHiddenForNonProUsers: index !== 0 && !this.canSeeAllEntryImages,
        data: entry,
      }));
    });

    let retImages: IGalleryImage[] = [];
    retImages = retImages.concat(...images);
    return retImages;
  }

  public getAllEntries = new AsyncTask(async () => {
    try {
      const result = await this.entryProxy.getAllEntries();

      if (result.ok) {
        return this.setAllEntries(result.data);
      }

      this.notification.error('gallery:error.fetching_entries');
    } catch (error) {
      this.notification.error('gallery:error.fetching_entries');
    }
  });

  private setDimensionsOnPhotos = () => {
    this.entriesWithPhotos.forEach(entry => {
      entry.photos.forEach(async (photo: PhotoModel) => {
        const imgDim = await this.imageDimensions(photo.url!);

        runInAction(() => {
          photo.width = imgDim.imageWidth;
          photo.height = imgDim.imageHeight;
        });
      });
    });
  }

  private imageDimensions = (imageUrl: string) => {
    return new Promise((resolve: (imgDimension: ImageDimensions) => void, reject) => {
      const img = new Image();
      img.onload = () => resolve({
        imageHeight: img.height,
        imageWidth: img.width,
      });
      img.onerror = reject;
      img.src = imageUrl;
    });
  }

}
