import { ApiProperty } from '@nestjs/swagger';
import { UserResponseDto } from './user.response.dto';

export class TaskResponseDto {

  @ApiProperty()
  public id: string;

  @ApiProperty()
  public description: string;

  @ApiProperty()
  public details?: string;

  @ApiProperty()
  public done: boolean;

  @ApiProperty({ type: () => UserResponseDto })
  public owner: UserResponseDto;

  @ApiProperty({ type: () => UserResponseDto })
  public assignee?: UserResponseDto;

  @ApiProperty()
  public createdAt: string; // ISO string

  @ApiProperty()
  public updateAt?: string; // ISO string

  @ApiProperty()
  public dueDate?: string;

  @ApiProperty({ required: false })
  public districtId?: string;

  @ApiProperty({ required: false })
  public subzoneId?: string;

  @ApiProperty({ required: false })
  public poiId?: string;

  @ApiProperty({ required: false })
  public statusTs?: string;

  @ApiProperty({ isArray: true })
  public assigneesIds?: string[];
}
