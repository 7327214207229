import * as React from 'react';

import { Grid, IconButton, Popover, TableCell, Typography, useMediaQuery } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';

import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import {
  shouldEnableDistrictsModal, truncateDistrictsNames
} from '../../../../../util/ManageGroupHelper';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';

interface IAreaCellProps {
  row: GroupSubscriptionUserModel;
}

export const AreaCell: React.FC<IAreaCellProps> = function AreaCell({ row }: IAreaCellProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const shouldEnablePopover = shouldEnableDistrictsModal(row.districtsNames);

  return (
    <TableCell>
      <Grid container direction={'row'}>
        <Grid item>
          <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
            {shouldEnablePopover ? truncateDistrictsNames(row.districtsNames) : row.districtsNames}
          </Typography>
        </Grid>
        {shouldEnablePopover &&
          <Grid item width={'10%'} alignSelf={'center'}>
            <IconButton onClick={handleClick} sx={{ p: 0 }}>
              <ArrowDropDownIcon />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography sx={{ p: 2, maxWidth: 350 }} variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
                {row.districtsNames}
              </Typography>
            </Popover>
          </Grid>}
      </Grid>
    </TableCell>
  );
};
