import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Assets } from '../../../../../../../assets';
import { useVm } from '../../../../../../../domain/hooks/useVm';
import { colors } from '../../../../../../../theme/options/palette/const/colors';
import { CustomDatePicker } from '../../../../../../../toolkit/components/CustomDatePicker';
import { ProCtaButton } from '../../../../../../../toolkit/components/ProCtaButton';
import { SubmitButton } from '../../../../../../../toolkit/components/SubmitButton';
import { appLocaleList } from '../../../../../../../toolkit/constants/LanguageList';
import {
  ViewDistrictCheckinListProps, ViewDistrictCheckinListVm
} from './ViewDistrictCheckinListVm';

export const ViewDistrictCheckinList = observer(function ViewDistrictCheckinList(props: ViewDistrictCheckinListProps) {
  const vm = useVm(ViewDistrictCheckinListVm, props);
  const { t } = useTranslation();

  return (
    <Box px={4} py={2}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={appLocaleList[vm.i18n.language]}>
        <Box mb={1} display="flex" flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="body2">
            {t('district:harvest_filter_date_range')}
          </Typography>
          {vm.hasDateChanged && <Typography sx={{ cursor: 'pointer' }} onClick={vm.resetDates} color={colors.green[800]} variant="subtitle2">
            {t('district:reset_harvest_filter')}
          </Typography>}
        </Box>
        <Grid container spacing={2}>
          <CustomDatePicker
            label={t('district:harvest_filter_from_date')}
            selectedDate={vm.from}
            onChange={vm.setFrom}
          />
          <CustomDatePicker
            label={t('district:harvest_filter_to_date')}
            selectedDate={vm.to}
            onChange={vm.setTo}
          />
        </Grid>
      </LocalizationProvider>
      <Box my={2}>
        <Divider />
      </Box>
      {!vm.getCheckinCount.isBusy && (
        vm.numberOfCheckins > 0
          ? <Box width={'100%'} display="flex" justifyContent={'center'} mt={5}>
            {vm.hasProCheckinList
              ? <Grid container flexDirection={'column'} alignItems={'center'} rowSpacing={2}>
                <Grid item>
                  <Typography variant='subtitle1' textAlign={'center'}>{vm.i18n.t('district:checkin_count_info', { count: vm.numberOfCheckins })}</Typography>
                </Grid>
                <Grid item>
                  <SubmitButton sx={{ paddingX: 8 }} onClick={vm.onDownloadClick}>
                    {t('district:export_checkin_list')}
                  </SubmitButton>
                </Grid>
              </Grid>
              : <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems={'center'}>
                <img style={{ width: '85px' }} src={Assets.harvest.pro_export} />
                <Typography textAlign={'center'} color={colors.blueGrey[900]} mt={2} mb={4}>
                  {t('district:activate_pro_checkin_export')}
                </Typography>
                <ProCtaButton />
              </Box>}
          </Box>
          : (vm.hasProCheckinList
            ? <Box display={'flex'} flexDirection="column" justifyContent={'center'} alignItems="center" mt={4}>
              <img style={{ width: '52px' }} src={Assets.harvest.no_results} />
              <Typography textAlign={'center'} color={colors.blueGrey[900]} mt={2}>
                {t('district:checkin_no_results')}
              </Typography>
            </Box>
            : <Box display="flex" flexDirection={'column'} justifyContent="center" alignItems={'center'}>
              <img style={{ width: '85px' }} src={Assets.harvest.pro_export} />
              <Typography textAlign={'center'} color={colors.blueGrey[900]} mt={2} mb={4}>
                {t('district:activate_pro_checkin_export')}
              </Typography>
              <ProCtaButton />
            </Box>)
      )}
    </Box>
  );
});
