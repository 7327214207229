import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { MapPathModel } from '../../../../../../../domain/model/MapPathModel';
import { MapPathTypeModel } from '../../../../../../../domain/model/MapPathType';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { SessionStore } from '../../../../../../../domain/store/SessionStore';
import { transient } from '../../../../../../../inversify/decorator';
import { FEATURE } from '../../../../../../../shared/enum';

export interface IViewDistrictPathsProps {
  paths: MapPathModel[];
  types: MapPathTypeModel[];
  onEdit: (path: MapPathModel) => void;
  onDelete: (path: MapPathModel) => void;
}

interface IDistrictViewPoiTab {
  typeId: string | null;
  count: number;
  text: string;
}

@transient()
export class ViewDistrictPathsVm extends ViewModel<IViewDistrictPathsProps> {

  @observable
  public activeTypeId: string | null = null;

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(SessionStore) private readonly session: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get proPathsEnabled() {
    return this.session.hasFeatureEnabled(FEATURE.MAP_PATH);
  }

  @computed
  public get tabs(): IDistrictViewPoiTab[] {
    const typeIds = this.props.paths.reduce((set, path) => {
      set.add(path.typeId);
      return set;
    }, new Set<string>());

    const tabs: IDistrictViewPoiTab[] = Array.from(typeIds.values()).map((id) => {
      const type = this.props.types.find((type) => type.id === id);

      return {
        typeId: id,
        count: this.props.paths.filter((p) => p.typeId === type?.id).length,
        text: this.i18n.t(`paths:type_${type?.key}`),
      };
    });

    tabs.unshift({
      typeId: null,
      count: this.props.paths.length,
      text: this.i18n.t('district:district_details_show_all_items'),
    });

    return tabs;
  }

  public getTypeText = (typeId: string) => {
    const type = this.props.types.find((t) => t.id === typeId);
    return this.i18n.t(`paths:type_${type?.key}`);
  }

  @computed
  public get paths(): MapPathModel[] {
    return this.props.paths.filter((path) => {
      if (!this.activeTypeId) {
        return true;
      }

      return path.typeId === this.activeTypeId;
    });
  }

  @action
  public changeTab = (_: unknown, value: string) => {
    this.activeTypeId = value;
  }
}
