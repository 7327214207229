import { Box, Typography } from '@mui/material';

import { useContainer } from '../../../../../../../domain/hooks/useContainer';
import { EntryHelper } from '../../../../../../../util/EntryHelper';
import { IClassificationButton } from '../EntryClassificationVm';
import { useCallback } from 'react';

interface IProps {
  info: IClassificationButton;
  canEditAndDeleteEntries?: boolean;
  isHarvestVisibleAndEditable?: boolean;
}

export function ClassificationButton({ info, canEditAndDeleteEntries = undefined, isHarvestVisibleAndEditable = undefined }: IProps) {
  const entryHelper = useContainer().get(EntryHelper);

  const checkPermissions = useCallback(() => {
    if (canEditAndDeleteEntries === false) {
      if (isHarvestVisibleAndEditable === false) {
        return entryHelper.notifyWrongPermission('entry:harvest_readonly_permission');
      }
      return entryHelper.notifyWrongPermission();
    }
  }, [entryHelper, canEditAndDeleteEntries, isHarvestVisibleAndEditable]);

  const handleClassificationButtonChange = useCallback(() => {
    checkPermissions();
    info.callback && info.callback();
  }, [checkPermissions, info]);

  return (
    <Box
      sx={
        canEditAndDeleteEntries ? {
          cursor: canEditAndDeleteEntries ? 'pointer' : 'not-allowed'
        } : { cursor: 'pointer' }
      }
      display={'inline-flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      onClick={handleClassificationButtonChange}
      minWidth={'100px'}
      width={'100%'}
      height={'100%'}
      maxHeight={'116px'}
      borderRadius={'4px'}
      bgcolor={info.selected ? 'secondary.main' : 'background.info'}
      paddingY={2}
      paddingX={1}
    >
      {info.icon && <img style={{
        width: '60px',
        height: '60px',
        marginBottom: '4px',
      }}
        src={info.icon}
        alt={info.name}
      />}
      <Typography sx={{ wordBreak: 'break-word' }} fontSize={'14px'} textAlign="center" color={info.selected ? 'white' : 'primary.main'}>
        {info.name}
      </Typography>
    </Box>
  );
}
