import { observer } from 'mobx-react-lite';
import React, { BaseSyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Divider, FormControl,
  Grid, TextField, Typography
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Assets } from '../../../../../../../assets';
import { useVm } from '../../../../../../../domain/hooks/useVm';
import { palette } from '../../../../../../../theme/options/palette/palette';
import { CustomDatePicker } from '../../../../../../../toolkit/components/CustomDatePicker';
import { ValidatedForm } from '../../../../../../../toolkit/components/form/ValidatedForm';
import {
  ValidatedFormControl
} from '../../../../../../../toolkit/components/form/ValidatedFormControl';
import { SubmitButton } from '../../../../../../../toolkit/components/SubmitButton';
import { appLocaleList } from '../../../../../../../toolkit/constants/LanguageList';
import { IAssigneeListItem } from '../../../../../../../util/PoiHelper';
import { LinkTask } from '../link-task/LinkTask';
import { ITaskProps, TaskVm } from './TaskVm';

export const Task = observer(function Task(props: ITaskProps) {
  const { t } = useTranslation();
  const vm = useVm(TaskVm, props);

  const handleTimeChange = useCallback((e) => {
    props.task?.setDueTime(e.target.value ?? '');
  }, [props.task]);


  return (
    <React.Fragment>
      {props.task &&
        <Box height="100%" display="flex" flexDirection="column" position='relative'>
          <ValidatedForm onSubmit={vm.handleSave.run} styleProps={{ height: '95%', }}>
            <Grid container direction="column" my={2} rowGap={3} alignItems={'stretch'} height="97%" flex={'1 0 auto'}>
              {/* TITLE */}
              <Grid container>
                <Grid item xs={12}>
                  <Typography fontSize={16} fontWeight="bold" color={palette.text?.details}>{t('task:description.title')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ValidatedFormControl
                    fullWidth
                    value={props.task.description}
                    schema={
                      yup
                        .string()
                        .required(t('task:description.placeholder'))
                    }>
                    <TextField
                      variant="standard"
                      type="text"
                      placeholder={t('task:description.placeholder')}
                      value={props.task.description}
                      onChange={(event: BaseSyntheticEvent) => props.task?.setDescription(event.target.value)}
                      sx={{
                        input: {
                          '&::placeholder': {
                            fontSize: 14,
                            color: palette.text?.details
                          },
                        }
                      }}
                    />
                  </ValidatedFormControl>
                </Grid>
              </Grid>
              {/* DETAILS */}
              <Grid container>
                <Grid item xs={12}>
                  <Typography fontSize={16} fontWeight="bold" color={palette.text?.details}>{t('task:details.title')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <ValidatedFormControl
                    fullWidth
                    value={props.task.details}
                    schema={
                      yup
                        .string()
                        .max(150, t('task:details.length'))
                    }>
                    <TextField
                      variant="standard"
                      multiline
                      placeholder={t('task:details.placeholder')}
                      value={props.task.details}
                      onChange={(event: BaseSyntheticEvent) => props.task?.setDetails(event.target.value)}
                      sx={{
                        input: {
                          '&::placeholder': {
                            fontSize: 14,
                            color: palette.text?.details
                          },
                        }
                      }}
                    />
                  </ValidatedFormControl>
                </Grid>
              </Grid>
              {/* DATE & TIME */}
              <Grid container marginY={1} justifyContent="space-between">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography fontSize={16} fontWeight="bold" color={palette.text?.details}>{t('task:due_date.title')}</Typography>
                  </Grid>
                  <Grid item onClick={props.task.resetDueDate} sx={{ cursor: 'pointer' }}>
                    <Typography fontSize={14} fontWeight="normal" color={palette.text?.hunter_green}>{t('task:due_date.reset')}</Typography>
                  </Grid>
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={appLocaleList[vm.i18n.language]}>
                  {/* DATE */}
                  <Grid item xs={12} md={7} container spacing={1} direction="column">
                    <CustomDatePicker
                      label={t('task:due_date.date_picker')}
                      selectedDate={props.task.dueDate}
                      onChange={(date) => {
                        props.task?.setDueDate(date ?? null);
                      }}
                    />
                  </Grid>

                  {/* TIME */}
                  <Grid item xs={12} md={4} container spacing={1} direction="column">
                    <Grid item>
                      <Typography fontSize={17} color="primary">{t('task:due_date.time_picker')}</Typography>
                    </Grid>
                    <Grid item>
                      <TextField
                        type="time"
                        value={props.task.time}
                        onChange={handleTimeChange}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{
                          step: 300, // 5 min
                        }}
                        sx={{ width: 'inherit' }}
                      />
                    </Grid>
                  </Grid>
                </LocalizationProvider>
              </Grid>
              {/* LINK THE TASK */}
              <Grid item container paddingBottom={vm.isExpanded ? 3 : 0}>
                <Divider />
                <Grid item xs>
                  <Accordion sx={{ width: 'inherit', boxShadow: 'none' }} onChange={(_e, expanded) => vm.setIsExpanded(expanded)}>
                    <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon />}>
                      <Grid container direction="column" rowGap={1}>
                        <Grid item>
                          <Typography fontSize={16} fontWeight="bold" color={palette.text?.details}>
                            {t('task:link.title')}
                          </Typography>
                        </Grid>
                        {vm.linkedPoi?.name && <Grid item>
                          <Typography variant="subtitle2" color={palette.text?.details}>
                            {vm.linkedPoi.name}
                          </Typography>
                        </Grid>}
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{ padding: 0 }}>
                      <Divider />
                      <FormControl fullWidth sx={{ paddingTop: 1 }}>
                        <LinkTask poi={props.poi} pois={props.pois} task={props.task} />
                      </FormControl>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
                {!vm.isExpanded && <Divider />}
              </Grid>
              {/* ASSIGNED */}
              <Grid item container>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection="row" alignItems="center" marginBottom={1}>
                    <Typography fontSize={16} fontWeight="bold" color={palette.text?.details}>
                      {t('task:assigned.title')}
                    </Typography>
                    <img src={Assets.shield} width={16} style={{ marginLeft: 8 }} />
                  </Box>
                  <Typography fontSize={14} fontWeight="normal" textAlign="center" color={palette.text?.details}>{t('task:assigned.subtitle')}</Typography>
                </Grid>
                {vm.assigneesListItems.map((assignee: IAssigneeListItem) => {
                  return (
                    <React.Fragment key={`assigneItem-${assignee.id}`}>
                      <Grid container justifyContent="space-between" alignItems="center" minHeight="35px">
                        <Grid item>
                          <Box display="flex" flexDirection="row" alignItems="center">
                            <Typography fontSize={14} fontWeight="500" color={palette.text?.details}>
                              {assignee.name}
                            </Typography>
                            {!assignee.disabled && <img src={Assets.shield} width={16} style={{ marginLeft: 8 }} />}
                          </Box>
                        </Grid>
                        {assignee.isPro && <Grid item>
                          <FormControl>
                            <Checkbox color="secondary" size="small"
                              checked={props.task?.assigneesIds.includes(assignee.id)}
                              onChange={() => vm.handleAssignees(assignee)}
                            />
                          </FormControl>
                        </Grid>}
                      </Grid>
                      <Divider />
                    </React.Fragment>
                  );
                })}
              </Grid>

            </Grid>

            {/* BUTTONS */}
            <Box
              marginTop={'auto'}
              position="sticky"
              bgcolor={'white'}
              borderTop={1}
              bottom={0}
              display="flex"
              justifyContent={'space-between'}
              alignItems={'center'}
              width="100%"
              minHeight={'70px'}
              paddingX={4}
            >
              <Button onClick={props.onClose} sx={{ paddingY: 1, width: 156 }} variant="outlined" color="error">
                <Typography>{t('entry:entry_cancel')}</Typography>
              </Button>
              <SubmitButton sx={{ paddingY: 1, width: 156 }} variant="contained" color="secondary">
                <Typography>
                  {t('entry:entry_save')}
                </Typography>
              </SubmitButton>
            </Box>

          </ValidatedForm>

        </Box>
      }

    </React.Fragment>
  );
});
