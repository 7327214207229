import { action, makeObservable, observable } from 'mobx';

import { MapPathPutRequestDto, MapPathResponseDto } from '../../shared/dto';
import { DistrictModel } from './DistrictModel';
import { MapPathTypeModel } from './MapPathType';
import { MeshModel } from './MeshModel';
import { UserModel } from './UserModel';

export class MapPathModel {

  @observable
  public id: string = '';

  @observable
  public color: string | null = null;

  @observable
  public typeId: string = '';

  @observable
  public title: string = '';

  @observable
  public districtId?: string = undefined;

  @observable
  public owner: UserModel | undefined = undefined;

  @observable
  public mesh: MeshModel = new MeshModel([]);

  constructor() {
    makeObservable(this);
  }

  @action
  public setTitle = (title: string) => {
    this.title = title;
  }

  @action
  public setType = (type: MapPathTypeModel, color: string) => {
    this.typeId = type.id;
    this.color = color;
  }

  @action
  public setColor = (color: string) => {
    this.color = color;
  }

  public static fromDto = (dto: MapPathResponseDto) => {
    const path = new MapPathModel();

    path.id = dto.id;
    path.color = dto.color;
    path.typeId = dto.typeId;
    path.title = dto.title ?? '';
    path.districtId = dto.districtId ? dto.districtId : DistrictModel.worldMapId;
    path.owner = UserModel.fromDto(dto.owner);
    path.mesh = new MeshModel(dto.points);

    return path;
  }

  public clone = () => {
    const cloned = new MapPathModel();

    cloned.id = this.id;
    cloned.color = this.color;
    cloned.typeId = this.typeId;
    cloned.title = this.title;
    cloned.districtId = this.districtId;
    cloned.owner = this.owner?.clone();
    cloned.mesh = this.mesh.clone();

    return cloned;
  }

  public toDto = () => {
    const dto = new MapPathPutRequestDto();

    dto.id = this.id || undefined!;
    dto.title = this.title;
    dto.color = this.color ?? undefined;
    dto.typeId = this.typeId;
    dto.districtId = this.districtId !== DistrictModel.worldMapId ? this.districtId : undefined;
    dto.points = this.mesh.points;

    return dto;
  }
}
