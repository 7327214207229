import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip, Grid, Link, Tab, Tabs, Typography } from '@mui/material';

import { Assets } from '../../../../../assets';
import { useQuotaPlanContext } from '../../../../../domain/context/QuotaPlanContext';
import { useVm } from '../../../../../domain/hooks/useVm';
import { REGION } from '../../../../../shared/enum/region.enum';
import { colors } from '../../../../../theme/options/palette/const/colors';
import {
  ClassificationRegion
} from '../../entry/view-entry/classification/components/classification-region/ClassificationRegion';
import {
  ViewDistrictCheckinList
} from './components/district-checkin-list/ViewDistrictCheckinList';
import { ViewDistrictEntries } from './components/district-entries/ViewDistrictEntries';
import {
  ViewDistrictHarvestList
} from './components/district-harvest-list/ViewDistrictHarvestList';
import { ViewDistrictMembers } from './components/district-members/ViewDistrictMembers';
import { ViewDistrictPaths } from './components/district-paths/ViewDistrictPaths';
import { ViewDistrictPois } from './components/district-pois/ViewDistrictPois';
import {
  ViewDistrictQuotaPlanningTab
} from './components/district-quota-planning/ViewDistrictQuotaPlanningTab';
import {
  QuotaPlanningScreens
} from './components/district-quota-planning/ViewDistrictQuotaPlanningTabVm';
import { ViewDistrictTasks } from './components/district-tasks/ViewDistrictTasks';
import { ViewDistrictZones } from './components/district-zones/ViewDistrictZones';
import { DistrictSelector } from './components/DistrictSelector';
import {
  DistrictDetailsTabEnum, DistrictTab, IDistrictDetailsTab, IViewDistrictProps, ViewDistrictVm
} from './ViewDistrictVm';

export const ViewDistrict = observer(function ViewDistrict(props: IViewDistrictProps) {
  const vm = useVm(ViewDistrictVm, props);
  const { t } = useTranslation();
  const { setStep, displayChangeRegionButton, setDisplayChangeRegionButton, region, setRegion } = useQuotaPlanContext();

  /**
   * Region selection and other related logic is tied to QuotaPlan animal classification, but because of code structure, we need some of the information here
   * so we can render Region selection sidebar
   */
  const openRegionSelection = useCallback(() => vm.openRegionSelection(), [vm]);

  const closeRegionSelection = useCallback(() => {
    vm.closeRegionSelection();
    setDisplayChangeRegionButton(false);
    setStep(QuotaPlanningScreens.EDIT_QUOTA_PLAN);
  }, [setDisplayChangeRegionButton, setStep, vm]);

  const handleSetRegion = useCallback((region: REGION) => {
    setRegion(region);
    closeRegionSelection();
  }, [closeRegionSelection, setRegion]);

  const classification = useMemo(() =>
    <ClassificationRegion
      headerColor={'secondary.main'}
      region={region}
      onClose={closeRegionSelection}
      onRegionChange={handleSetRegion}
    />, [closeRegionSelection, handleSetRegion, region]);

  useEffect(() => {
    if (vm.currentDetailsTab?.name !== DistrictDetailsTabEnum.QuotaPlanning) {
      setStep(QuotaPlanningScreens.QUOTA_PLANS_LIST);
    }
  }, [setStep, vm.currentDetailsTab?.name]);

  if (vm.regionSelection) {
    return classification;
  }

  return (
    <Box display={'flex'} flexDirection={'column'} height="100%">

      {/* HEADER */}
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: 'secondary.main',
          color: colors.paper,
          paddingX: 2,
          paddingTop: 1,
        }}
      >
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            {displayChangeRegionButton && <Link
              onClick={openRegionSelection}
              href="#"
              color="inherit"
            >{t('entry:change_region')}</Link>}
          </Grid>
          <Grid item>
            <CloseIcon sx={{ cursor: 'pointer', alignSelf: 'flex-end' }} onClick={props.onClose} />
          </Grid>
        </Grid>
        <Box marginY={2}>
          <Typography variant="subtitle2">
            {vm.role}
          </Typography>
          <Typography variant="h4" sx={{ textShadow: '1px 1px 3px #00000029' }}>
            {props.selectedDistrict.name}
          </Typography>
        </Box>

        {/* AREA TABS */}
        <Tabs
          value={vm.currentTab}
          onChange={(e, value) => vm.setTab(value)}
          sx={{
            '.Mui-selected': {
              backgroundColor: '#0E9177',
            },
            '.MuiTabs-indicator': {
              backgroundColor: 'white',
            }
          }}
          variant="fullWidth"
          textColor="inherit"
        >
          <Tab value={DistrictTab.Area} label={t(DistrictTab.Area)} />
          <Tab value={DistrictTab.SelectArea} label={t(DistrictTab.SelectArea)} />
        </Tabs>
      </Box>

      {/* AREA MENU TAB */}
      {vm.currentTab === DistrictTab.Area && <>
        <Grid container columnSpacing={1} alignItems="center"
          paddingX={2}
          paddingY={1}
          sx={{
            maxWidth: 'inherit',
            boxShadow: '0 8px 3px -6px #00000033',
          }} >
          {vm.tabs.map((tab: IDistrictDetailsTab) => {
            return (
              <Grid position="relative" paddingY={1} item key={tab.name}>
                <Chip
                  clickable
                  variant="outlined"
                  label={t(tab.name)}
                  onClick={() => vm.setSelectedDistrictTab(tab)}
                  style={vm.currentDetailsTab?.name === tab.name
                    ? {
                      borderColor: '#02654E',
                      color: '#02654E',
                      backgroundColor: '#DFF1EE',
                    }
                    : {}
                  }
                />
                {!tab.enabled && <img src={Assets.shield} style={{
                  position: 'absolute',
                  right: -10,
                  top: 0,
                  width: '24px'
                }} />}
              </Grid>
            );
          })}
        </Grid>

        {vm.currentDetailsTab?.name === DistrictDetailsTabEnum.Members && <ViewDistrictMembers onDistrictLeft={props.onDistrictLeft} district={props.selectedDistrict} />}
        {vm.currentDetailsTab?.name === DistrictDetailsTabEnum.HarvestList && <ViewDistrictHarvestList districtId={props.selectedDistrict.id} />}
        {vm.currentDetailsTab?.name === DistrictDetailsTabEnum.QuotaPlanning && <ViewDistrictQuotaPlanningTab district={props.selectedDistrict} />}
        {vm.currentDetailsTab?.name === DistrictDetailsTabEnum.CheckinList && <ViewDistrictCheckinList districtId={props.selectedDistrict.id} />}
        {vm.currentDetailsTab?.name === DistrictDetailsTabEnum.Tasks && <ViewDistrictTasks pois={props.pois} tasks={props.tasks} district={props.selectedDistrict} />}
        {vm.currentDetailsTab?.name === DistrictDetailsTabEnum.Pois && <ViewDistrictPois
          pois={props.pois}
          onEdit={props.onPoiEdit}
          onDelete={props.onPoiDelete}
          canEditAndDeletePois={props.selectedDistrict.canEditAndDeletePois}
        />}
        {vm.currentDetailsTab?.name === DistrictDetailsTabEnum.Entries && <ViewDistrictEntries
          entries={props.entries}
          onEdit={props.onEntryEdit}
          onDelete={props.onEntryDelete}
          selectedDistrict={props.selectedDistrict}
          districts={props.districts}
        />}
        {vm.currentDetailsTab?.name === DistrictDetailsTabEnum.Paths && <ViewDistrictPaths
          paths={props.paths}
          types={props.pathTypes}
          onEdit={props.onMapPathEdit}
          onDelete={props.onMapPathDelete}
        />}
        {vm.currentDetailsTab?.name === DistrictDetailsTabEnum.Zones && <ViewDistrictZones
          subzones={props.subzones}
          onEdit={props.onSubzoneEdit}
          onDelete={props.onSubzoneDelete}
        />}

      </>}

      {/* SELECT AREA TAB */}
      {vm.currentTab === DistrictTab.SelectArea && <DistrictSelector
        selectedDistrict={props.selectedDistrict}
        districts={props.districts}
        districtsSelectorFilter={props.districtsSelectorFilter}
        onSelect={props.onSelectDistrict}
        onEdit={props.onEditDistrict}
        onDelete={props.onDeleteDistrict}
        onNewDistrict={vm.startNewDistrict}
        importMultiplePois={props.importMultiplePois!}
      />}
    </Box>
  );
});
