export enum ENTITY_TYPE {
  DISTRICT = 1,
  GROUP = 2,
  EVENT = 3,
  PATH = 4,
  POI = 5,
  ENTRY = 6,
  GEAR_SET = 7,
  SUBZONE = 8
}
