interface LegalLink {
  to: string;
  label: string;
}

export const legalLinksList: LegalLink[] = [
  {
    to: 'links:data-privacy',
    label: 'profile:info.links_2',
  },
  {
    to: 'links:terms-of-use',
    label: 'profile:info.links_3',
  },
  {
    to: 'links:impressum',
    label: 'profile:info.links_4',
  },
];
