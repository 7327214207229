import { IsEnum, IsIn, IsUUID } from 'class-validator';

import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';

import { ENTITY_TYPE, ROLE, ROLE_TYPE } from '../enum';

export class UserRolePutRequestDto {

  @IsUUID()
  @ApiProperty()
  userId: string;

  @IsUUID()
  @ApiProperty()
  entityId: string;

  @IsEnum(ENTITY_TYPE)
  @ApiProperty({ enum: ENTITY_TYPE })
  entityType: ENTITY_TYPE;

  @IsIn([ROLE.DISTRICT.OWNER, ROLE.DISTRICT.ADMIN, ROLE.DISTRICT.USER, ROLE.DISTRICT.GUEST, ROLE.GROUP.ADMIN, ROLE.GROUP.USER, ROLE.EVENT.ADMIN, ROLE.EVENT.USER])
  @ApiPropertyOptional({ enum: ROLE })
  role: ROLE_TYPE;
}
