import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

import { oldDesignTheme } from '../../theme/oldDesignTheme';

const drawerWidth = 70;
const bgColor = oldDesignTheme.palette.background.paper;

const useStyles = makeStyles({
  root: {
    width: drawerWidth,
    minHeight: drawerWidth,
    display: 'flex',
  },
  active: {
    borderRight: `3px solid ${oldDesignTheme.palette.secondary.main}`,
  },
  container: {
    backgroundColor: bgColor,
    color: oldDesignTheme.palette.text.secondary,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

interface IProps extends NavLinkProps {
  activeClassName?: string;
}

export const SidebarMenuItem = ({ children, activeClassName, ...linkProps }: IProps) => {
  const classes = useStyles();

  return (
    <Box paddingY="3px">
      <NavLink className={({ isActive }) => isActive ? clsx(classes.root, activeClassName ?? classes.active) : classes.root} {...linkProps} style={{ textDecoration: 'none' }}>
        <div className={classes.container}>{children}</div>
      </NavLink>
    </Box>
  );
};
