import {
  differenceInCalendarDays, format, formatISO, parseISO, setHours, setMinutes
} from 'date-fns';
import { action, computed, makeObservable, observable } from 'mobx';

import { TaskPostRequestDto, TaskPutRequestDto } from '../../shared/dto';
import { TaskResponseDto } from '../../shared/dto/task.response.dto';
import { UserModel } from './UserModel';

export class TaskModel {

  @observable
  public id?: string = undefined;

  /** Description is actually a title.  */
  @observable
  public description: string = '';

  @observable
  public details?: string = undefined;

  @observable
  public done: boolean = false;

  @observable
  public poiId?: string | undefined = undefined;

  @observable
  public owner: UserModel | null = null;

  @observable
  public assigneesIds: string[] = [];

  @observable
  public districtId: string | undefined = undefined;

  @observable
  public dueDate: Date | null = null;

  @observable
  public dueTime: Date | null = null;

  @observable
  public createdAt: Date = new Date();

  constructor(poiId: string | undefined = undefined, districtId: string | undefined = undefined) {
    makeObservable(this);
    this.poiId = poiId;
    this.districtId = districtId;
  }

  @computed
  public get time(): string {
    if (!this.dueDate) return '';

    return format(this.dueDate, 'HH:mm');
  }

  @computed
  public get dueInDays(): number | undefined {
    if (!this.dueDate) {
      return;
    }

    const diff = differenceInCalendarDays(this.dueDate, new Date);
    return diff;
  }

  @action
  public setDetails = (details: string) => {
    this.details = details;
  }

  @action
  public setDescription = (description: string) => {
    this.description = description;
  }

  @action
  public setDueDate = (selectedDate: Date | null) => {
    const newDate = this.dueDate ? new Date(this.dueDate.valueOf()) : selectedDate;

    if (selectedDate && newDate) {
      newDate.setFullYear(selectedDate.getFullYear());
      newDate.setMonth(selectedDate.getMonth());
      newDate.setDate(selectedDate.getDate());

      this.dueDate = newDate;
    }
  }

  @action
  public setDueTime = (time: string): void => {
    if (!this.dueDate) {
      return;
    }

    const [hour, minute] = time.split(':');

    this.dueDate = setHours(this.dueDate, parseInt(hour));
    this.dueDate = setMinutes(this.dueDate, parseInt(minute));
  }

  @action
  public resetDueDate = () => {
    this.dueDate = null;
  }

  @action
  public toggleDone = () => {
    this.done = !this.done;
  }

  @action
  public setPoiId = (poiId: string | undefined) => {
    this.poiId = poiId;
  }

  @action
  public setAssigneesIds = (assignedUsersIds: string[]) => {
    this.assigneesIds = assignedUsersIds;
  }

  public static fromDto(dto: TaskResponseDto): TaskModel {
    const model = new TaskModel();
    model.id = dto.id;
    model.description = dto.description;
    model.owner = UserModel.fromDto(dto.owner);
    if (dto.assigneesIds) {
      model.assigneesIds = dto.assigneesIds;
    }
    model.done = dto.done;
    model.districtId = dto.districtId;
    model.poiId = dto.poiId;
    model.details = dto.details;
    model.dueDate = dto.dueDate ? parseISO(dto.dueDate) : null;
    model.createdAt = parseISO(dto.createdAt);

    return model;
  }

  public clone = (): TaskModel => {
    const newTask = new TaskModel();
    newTask.description = this.description;
    newTask.id = this.id;
    newTask.owner = this.owner;
    newTask.assigneesIds = this.assigneesIds;
    newTask.done = this.done;
    newTask.poiId = this.poiId;
    newTask.districtId = this.districtId;
    newTask.dueDate = this.dueDate;
    newTask.details = this.details;
    newTask.createdAt = this.createdAt;

    return newTask;
  }

  public toPostDto = () => {
    const requestDto = new TaskPostRequestDto();
    requestDto.description = this.description;
    requestDto.done = this.done;
    requestDto.poiId = this.poiId;
    requestDto.districtId = this.districtId || undefined;
    requestDto.dueDate = this.dueDate ? formatISO(this.dueDate) : undefined;
    requestDto.details = this.details;
    requestDto.assigneesId = this.assigneesIds;

    return requestDto;
  }

  public toPutDto = () => {
    const requestDto = new TaskPutRequestDto();
    requestDto.id = this.id!;
    requestDto.description = this.description;
    requestDto.done = this.done;
    requestDto.poiId = this.poiId;
    requestDto.districtId = this.districtId;
    requestDto.dueDate = this.dueDate ? formatISO(this.dueDate) : null;
    requestDto.details = this.details;
    requestDto.assigneesId = this.assigneesIds;

    return requestDto;
  }
}
