import { computed, makeObservable, observable } from 'mobx';

import { UserResponseDto } from '../../shared/dto';
import { COUNTRY_CODE } from '../../shared/enum/countryCode.enum';
import { LANGUAGE } from '../../shared/enum/language.enum';
import { MEASUREMENT_SYSTEM } from '../../shared/enum/measurementSystem.enum';
import { GearSetModel } from './GearsetModel';
import { PhotoModel } from './PhotoModel';
import { SUBSCRIPTION_TYPE } from '../../shared/enum';

export class UserModel {

  @observable
  public id: string = '';

  @observable
  public firstName: string = '';

  @observable
  public lastName: string = '';

  @observable
  public email: string = '';

  @observable
  public language: LANGUAGE;

  @observable
  public subscriptionType: SUBSCRIPTION_TYPE;

  @observable
  public country?: COUNTRY_CODE;

  @observable
  public distanceUnit: MEASUREMENT_SYSTEM = MEASUREMENT_SYSTEM.METRIC;

  @observable
  public photo?: PhotoModel | null = null;

  @observable
  public gearSets?: GearSetModel[] = [];

  @observable
  public isPro?: boolean = false;

  @observable
  public isYearlySubscription: boolean = false;

  /** TRUE if user is a group owner or a group member */
  @observable
  public isPartOfGroupSubscription?: boolean = false;

  @observable
  public trialPeriodUsed?: boolean = undefined;

  @observable
  public trialLength: number | undefined = undefined;

  constructor() {
    makeObservable(this);
  }

  @computed
  public get fullName(): string {
    let name = '';

    if (this.firstName) {
      name += `${this.firstName} `;
    }

    if (this.lastName) {
      name += this.lastName;
    }

    return name;
  }

  public clone = () => {
    const cloned = new UserModel();

    cloned.id = this.id;
    cloned.firstName = this.firstName;
    cloned.lastName = this.lastName;
    cloned.email = this.email;
    cloned.language = this.language;
    cloned.country = this.country;
    cloned.distanceUnit = this.distanceUnit;
    cloned.gearSets = this.gearSets?.map((gs) => gs.clone());
    cloned.photo = this.photo?.clone();
    cloned.isPro = this.isPro;
    cloned.isYearlySubscription = this.isYearlySubscription;
    cloned.isPartOfGroupSubscription = this.isPartOfGroupSubscription;
    cloned.trialPeriodUsed = this.trialPeriodUsed;
    cloned.trialLength = this.trialLength;
    cloned.subscriptionType = this.subscriptionType;

    return cloned;
  }

  public static fromDto = (dto: UserResponseDto) => {
    const user = new UserModel();

    user.id = dto.id;
    user.firstName = dto.firstName;
    user.lastName = dto.lastName;
    user.email = dto.email;
    // * force users with selected bulgarian language to display english language because we will not support bulgarian anymore
    user.language = dto.language === LANGUAGE.bg ? LANGUAGE.en : dto.language;
    user.country = dto.country;
    user.distanceUnit = dto.distanceUnit;
    user.gearSets = dto.gearSets?.map(GearSetModel.fromDto);
    user.isPro = dto.isPro;
    user.isYearlySubscription = dto.isYearlySubscription ?? false;
    user.subscriptionType = dto.subscriptionType;
    user.isPartOfGroupSubscription = dto.isPartOfGroupSubscription ?? false;
    user.trialPeriodUsed = dto.trialPeriodUsed;
    user.trialLength = dto.trialLength;
    if (dto.photo) {
      user.photo = PhotoModel.fromDto(dto.photo);
    }

    return user;
  }

  public toDto = () => {
    const dto = new UserResponseDto();

    dto.id = this.id;
    dto.firstName = this.firstName;
    dto.lastName = this.lastName;
    dto.language = this.language;
    dto.email = this.email;
    dto.country = this.country;
    dto.distanceUnit = this.distanceUnit;

    return dto;
  }
}
