import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Menu,
  MenuItem, ThemeProvider, Typography
} from '@mui/material';

import { useQuotaPlanContext } from '../../../../../../../../../domain/context/QuotaPlanContext';
import { useVm } from '../../../../../../../../../domain/hooks/useVm';
import { QuotaPlanModel } from '../../../../../../../../../domain/model/QuotaPlanModel';
import { QuotaPlanStatus } from '../../../../../../../../../shared/enum/quotaPlanStatus.enum';
import { oldDesignTheme } from '../../../../../../../../../theme/oldDesignTheme';
import { useThemeContext } from '../../../../../../../../../theme/ThemeContextProvider';
import { QuotaPlanningScreens } from '../../ViewDistrictQuotaPlanningTabVm';
import { QuotaPlanListItem } from './QuotaPlanListItem';
import { ViewDistrictQuotaPlanVm } from './ViewDistrictQuotaPlanVm';

export interface IViewDistrictQuotaPlanProps {
  quotaPlan: QuotaPlanModel;
  deleteQuotaPlan: (quotaPlan: QuotaPlanModel) => void;
}

export const ViewDistrictQuotaPlan = observer(function ViewQuotaPlan(props: IViewDistrictQuotaPlanProps) {
  const { theme } = useThemeContext();
  const { t } = useTranslation();
  const { setStep, setCurrentQuotaPlan } = useQuotaPlanContext();
  const vm = useVm(ViewDistrictQuotaPlanVm, props);
  const [iconColor, setIconColor] = useState(theme.palette.icons?.secondary);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const [deleteQuotaPlanDialogOpen, setDeleteQuotaPlanDialogOpen] = useState(false);

  const showMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const closeDeleteDialog = useCallback(() => {
    setDeleteQuotaPlanDialogOpen(false);
  }, []);

  const deleteQuotaPlan = useCallback((event: React.MouseEvent) => {
    closeMenu(event);
    vm.deleteQuotaPlan.run();
    closeDeleteDialog();
  }, [closeDeleteDialog, closeMenu, vm.deleteQuotaPlan]);

  const onEditQuotaPlan = useCallback(() => {
    setMenuAnchorEl(null);

    const clonedQuotaPlan = props.quotaPlan.clone();
    setCurrentQuotaPlan(clonedQuotaPlan);

    setStep(QuotaPlanningScreens.EDIT_QUOTA_PLAN);

  }, [props.quotaPlan, setCurrentQuotaPlan, setStep]);

  useEffect(() => {
    if (vm.isActivePlan) {
      return setIconColor(theme.palette.brand?.brand_color);
    }
    if (vm.isPastPlan) {
      return setIconColor(theme.palette.icons?.tertiary);
    }
    if (vm.isFuturePlan) {
      return setIconColor(theme.palette.icons?.secondary);
    }
  }, [theme.palette.brand?.brand_color, theme.palette.icons?.secondary, theme.palette.icons?.tertiary, vm.isActivePlan, vm.isFuturePlan, vm.isPastPlan]);

  return (
    <Grid item xs={12}>
      <QuotaPlanListItem
        quotaPlan={props.quotaPlan}
        iconColor={iconColor}
        rightComponent={<React.Fragment>
          {/* TODO: Remove this theme provider after redesign */}
          <ThemeProvider theme={oldDesignTheme}>
            <Button
              onClick={showMenu}
              color="secondary"
            >
              {t('district:options_dropdown')}
            </Button>
          </ThemeProvider>
          <Menu
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={closeMenu}
          >
            <MenuItem onClick={onEditQuotaPlan}>
              <Typography>
                {props.quotaPlan.status === QuotaPlanStatus.PAST ? t('quota_plan:plan_details.menu_option') : t('quota_plan:edit_plan.menu_option')}
              </Typography>
            </MenuItem>
            <MenuItem onClick={() => {
              setDeleteQuotaPlanDialogOpen(true);
              setMenuAnchorEl(null);
            }}>
              <Typography color={'error'}>
                {t('quota_plan:delete_plan.menu_option')}
              </Typography>
            </MenuItem>
          </Menu>

          {/* Dialog for deleting quota plan */}
          <Dialog open={deleteQuotaPlanDialogOpen} onClose={closeDeleteDialog}>
            <DialogTitle sx={{ fontSize: '1.125rem', fontWeight: '500' }}>
              {t('quota_plan:delete_plan.modal.title')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText variant="subtitle1">
                {t('quota_plan:delete_plan.modal.message')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDeleteDialog}>
                <Typography variant="subtitle2">
                  {t('common:button.cancel')}
                </Typography>
              </Button>
              <Button
                onClick={deleteQuotaPlan}
                autoFocus
              ><Typography variant="subtitle2" color={'text.hunter_green'}>
                  {t('quota_plan:delete_plan.menu_option')}
                </Typography>
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>}
      />
    </Grid>
  );
});
