import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useContainer } from '../../domain/hooks/useContainer';
import { TrackingEvent } from '../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../domain/store/SessionStore';
import { LANGUAGE } from '../../shared/enum';
import { oldDesignTheme } from '../../theme/oldDesignTheme';
import { Background } from '../../toolkit/components/Background';
import { IconAndTitle } from '../../toolkit/components/IconAndTitle';
import { StoreButtons } from '../../toolkit/components/StoreButtons';
import DownloadImage from './components/DownloadImage';

const useStyles = makeStyles({
  root: {
    '& .MuiTypography-h1': {
      color: '#535C68',
      fontSize: '46px',
      fontWeight: 'bold',
      [oldDesignTheme.breakpoints.down('lg')]: {
        fontSize: '36px',
      },
    },
    '& .MuiTypography-h3': {
      color: oldDesignTheme.palette.text.secondary,
      fontSize: '30px',
      fontWeight: '400',
      [oldDesignTheme.breakpoints.down('lg')]: {
        fontSize: '25px',
      },
    },
  },
  img: {
    maxWidth: '100%',
    maxHeight: 64,
    [oldDesignTheme.breakpoints.down('lg')]: {
      maxWidth: '60%',
      maxHeight: '80%',
    },
    objectFit: 'contain',
    cursor: 'pointer',
  },
  textWrapper: {
    background: oldDesignTheme.palette.background.paper,
    width: '460px',
    [oldDesignTheme.breakpoints.down('md')]: {
      width: '350px',
    },
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: oldDesignTheme.spacing(2),
  },
});

export const DownloadAppRoute: React.FC = () => {

  const classes = useStyles();
  const { i18n, t } = useTranslation();

  const tracking = useContainer().get(TrackingService);
  const sessionStore = useContainer().get(SessionStore);

  useEffect(() => {
    tracking.track(TrackingEvent.DOWNLOAD_PAGE_VIEW);
  }, [sessionStore.userId, tracking]);

  return (
    <Background>
      {/* PAGE GRID = COLUMN */}
      <Grid container direction="column">

        <IconAndTitle title="profile:title" subtitle="profile:subtitle" />

        {/* STEPS ROW */}
        <Grid container item direction="row" justifyContent={{ xs: 'center', lg: 'flex-start' }} paddingY={2} spacing={6}>

          {/* STEP ONE */}
          <Grid item container xs={12} sm={6} md={6} lg={4} marginBottom="15px" position="relative">
            <Grid item container justifyContent="center">
              {
                i18n.language === LANGUAGE.en && sessionStore.isCountryUK
                  ? <DownloadImage pathKey="links:join_hunt_UK" />
                  : <DownloadImage pathKey="links:join_hunt_step_1" />
              }
              <Grid item className={clsx(classes.textWrapper)} textAlign="center">
                <Typography variant="h3">{t('profile:body_step_1')}</Typography>
              </Grid>
              <Grid item
                sx={{
                  position: 'absolute',
                  top: '55%',
                  left: '33%',
                }}
              >
                <StoreButtons className={classes.img} />
              </Grid>
            </Grid>
          </Grid>

          {/* STEP TWO */}
          <Grid item container xs={12} sm={6} md={6} lg={4} marginBottom="15px">
            <Grid item container justifyContent="center">
              <DownloadImage pathKey="links:join_hunt_step_2" />
              <Grid item container direction="column" textAlign="center" className={clsx(classes.textWrapper)}>
                <Typography variant="h3">{t('profile:body_step_2_1')}</Typography>
                <Typography variant="h3" sx={{ fontWeight: 500 }}>{t('profile:body_step_2_2')}</Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* STEP THREE */}
          <Grid item container xs={12} sm={6} md={6} lg={4} marginBottom="15px">
            <Grid item container justifyContent="center" textAlign="center">
              <DownloadImage pathKey="links:join_hunt_step_3" />
              <Grid item className={clsx(classes.textWrapper)}>
                <Typography variant="h3">{t('profile:body_step_3')}</Typography>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

      </Grid>
    </Background>
  );
};
