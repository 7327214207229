import { inject } from 'inversify';
import { computed, makeObservable } from 'mobx';

import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { PoiModel } from '../../../../../../../domain/model/PoiModel';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { transient } from '../../../../../../../inversify/decorator';
import { POI_TYPE } from '../../../../../../../shared/enum';
import { PoiHelper } from '../../../../../../../util/PoiHelper';
import { LinkTaskProps } from './LinkTask';

export interface ILinkTaskListItem {
  poiId: string,
  label: string,
}

interface ISectionListObject {
  [key: string]: SectionListProps;
}
export interface SectionListProps {
  id: string,
  type: POI_TYPE;
  title: string;
  poi: PoiModel;
  data: ILinkTaskListItem[];
}

@transient()
export class LinkTaskVm extends ViewModel<LinkTaskProps> {

  constructor(
    @inject(I18nService) public readonly i18n: I18nService,
    @inject(PoiHelper) public readonly poiHelper: PoiHelper,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get poiTypes(): POI_TYPE[] {
    const removedDuplicatePoiTypes = new Set<POI_TYPE>(this.props.pois.map(poi => poi.type!));
    return Array.from(removedDuplicatePoiTypes);
  }

  @computed
  public get linkTaskList(): SectionListProps[] {

    /* CUSTOM POIS */
    const filteredCustomPois: PoiModel[] = this.props.pois.filter(poi => poi.type === POI_TYPE.CUSTOM);

    /**
     * This reducer uses customMarkForeground for separating custom pois, so eg:
     *    every poi with foreground "BEETLE" will be in a section of BEETLEs, no matter which background color it has.
     *
     * If you use customMarkId for separating custom pois, you will have as many sections as custom pois because customMarkId
     * is different for every combination of foreground icon and background color, even if the same combination already exists.
    */
    const customMarkForeground = filteredCustomPois.map((poiModel: PoiModel) => poiModel.customMark!.foreground as string);

    const sectionListObject: ISectionListObject = customMarkForeground.reduce(
      (acc: Record<string, SectionListProps>, key: string) => (acc[key] = {} as SectionListProps, acc),
      {}
    );

    filteredCustomPois.forEach((customPoi: PoiModel) => {
      if (!customPoi.customMark || !customPoi.customMark.foreground || !customPoi.type) return;

      const element = sectionListObject[customPoi.customMark.foreground];
      if (this.isNotEmptyObject(element)) {
        return element.data.push({
          poiId: customPoi.id,
          label: customPoi.name,
        });
      }

      sectionListObject[customPoi.customMark.foreground] = {
        id: customPoi.id,
        type: customPoi.type,
        title: this.poiHelper.getPoiUIModelCustomTranslation(customPoi),
        poi: customPoi,
        data: [{
          poiId: customPoi.id,
          label: customPoi.name,
        }],
      };
    });

    /* PREDEFINED POIS */
    const filteredPredefinedPois = this.props.pois.filter((poi: PoiModel) => poi.type !== POI_TYPE.CUSTOM && poi.type !== POI_TYPE.WEATHER);
    filteredPredefinedPois.forEach((poi: PoiModel) => {
      if (!poi.type) return;
      const element = sectionListObject[poi.type];

      if (this.isNotEmptyObject(element)) {
        return element.data.push({
          poiId: poi.id,
          label: poi.name,
        });
      }

      sectionListObject[poi.type] = {
        id: poi.id,
        type: poi.type,
        title: this.poiHelper.getPoiUIModelCustomTranslation(poi),
        poi: poi,
        data: [{
          poiId: poi.id,
          label: poi.name,
        }],
      };
    });

    return Object.values(sectionListObject);
  }

  @computed
  public get selectedLinkTask(): ILinkTaskListItem | undefined {

    let selectedItem: ILinkTaskListItem | undefined = undefined;

    this.linkTaskList.forEach(list => {
      const item = list.data.find(listTask => listTask.poiId === this.props.poi?.id);
      if (item) {
        selectedItem = item;
      }
    });

    return selectedItem;
  }

  private isNotEmptyObject = (element: SectionListProps) => {
    return element && Object.keys(element).length && Object.getPrototypeOf(element) === Object.prototype;
  }

}
