import { computed, makeObservable, observable } from 'mobx';
import { RoleResponseDto } from '../../shared/dto';
import { RIGHT } from '../../shared/enum/right.enum';
import { DISTRICT_ROLE } from '../../shared/enum/role.enum';


export class RoleModel {

  @observable
  public name: DISTRICT_ROLE = DISTRICT_ROLE.USER;

  @observable
  public rights: RIGHT[] = [];

  constructor() {
    makeObservable(this);
  }

  @computed
  public get roleNameI18n() {
    if (this.name === DISTRICT_ROLE.OWNER) {
      return 'district:role_owner';
    }

    if (this.name === DISTRICT_ROLE.ADMIN) {
      return 'district:role_admin';
    }

    if (this.name === DISTRICT_ROLE.USER) {
      return 'district:role_user';
    }

    return 'district:role_guest';
  }

  public clone = () => {
    const cloned = new RoleModel();

    cloned.name = this.name;
    cloned.rights = this.rights.slice();

    return cloned;
  }

  public static fromRole = (districtRole: DISTRICT_ROLE) => {
    const role = new RoleModel();

    role.name = districtRole;
    role.rights = [];

    return role;
  }

  public static fromDto = (dto: RoleResponseDto | undefined) => {
    if (!dto) {
      return null;
    }

    const role = new RoleModel();

    role.name = dto.name as DISTRICT_ROLE;
    role.rights = dto.rights as RIGHT[];

    return role;
  }
}
