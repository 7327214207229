import { computed, makeObservable, observable } from 'mobx';

import { createUUID } from '../../../__test__/uuid';
import { WindResponseDto } from '../../../shared/dto/weather/wind.response.dto';
import { SpeedUnit } from '../../../shared/enum/weather/speedUnit.enum';
import { WIND_DIRECTION } from '../../../shared/enum/weather/windDirection.enum';
import { WeatherUnitValueModel } from './WeatherUnitValue';

export class WindModel {

  @observable
  public id: string;

  @observable
  public direction: WIND_DIRECTION;

  @observable
  public speed: number;

  @observable
  public unit: SpeedUnit;

  @observable
  public degree: WeatherUnitValueModel;

  constructor() {
    makeObservable(this);
  }

  @computed
  public get localizedUnit(): string {
    if (this.unit === 'kph') {
      return 'km/h';
    }

    return this.unit;
  }

  @computed
  public get speedRounded(): number {
    return Math.round(this.speed);
  }

  @computed
  public get localized(): string {
    return `${Math.round(this.speed)} ${this.localizedUnit}`;
  }

  public static fromDto = (dto: WindResponseDto) => {
    const model = new WindModel();

    model.id = createUUID();
    model.direction = dto.direction;
    model.speed = dto.speed;
    model.unit = dto.unit;
    model.degree = WeatherUnitValueModel.fromDto(dto.degree);

    return model;
  }

  public toDto = () => {
    const dto = new WindResponseDto();

    dto.direction = this.direction;
    dto.speed = this.speed;
    dto.unit = this.unit;
    dto.degree = this.degree.toDto();

    return dto;
  }
}
