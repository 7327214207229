import { inject } from 'inversify';
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import React from 'react';

import { Loader } from '@googlemaps/js-api-loader';

import { Assets } from '../../assets';
import { AsyncTask } from '../../domain/async/AsyncTask';
import { ViewModel } from '../../domain/core/ViewModel';
import { DistrictModel } from '../../domain/model/DistrictModel';
import { EntryModel } from '../../domain/model/EntryModel';
import { MapPathModel } from '../../domain/model/MapPathModel';
import { PoiModel } from '../../domain/model/PoiModel';
import { SubzoneModel } from '../../domain/model/SubzoneModel';
import { TaskModel } from '../../domain/model/TaskModel';
import { GeolocationService } from '../../domain/service/GeolocationService';
import { I18nService } from '../../domain/service/I18nService';
import { TrackingEvent } from '../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../domain/service/tracking/TrackingService';
import { WsService } from '../../domain/service/WsService';
import { SessionStore } from '../../domain/store/SessionStore';
import { env } from '../../env';
import { transient } from '../../inversify/decorator';
import {
  DistrictResponseDto, MapPathResponseDto, PoiResponseDto, TaskResponseDto
} from '../../shared/dto';
import { EntryResponseDto } from '../../shared/dto/entry.response.dto';
import { SubzoneResponseDto } from '../../shared/dto/subzone/subzone.response.dto';
import { DeletedEntityDto } from '../../shared/dto/websockets/deleted.entity.dto';
import { ENTRY_TYPE, FEATURE, POI_TYPE } from '../../shared/enum';
import { IGeoLocation } from '../../shared/interfaces/IGeoLocation';
import { WsEvent } from '../../shared/ws/ws.event';
import { DrawerOpenRef } from '../../toolkit/components/SideBar';
import { GeoUtil } from '../../util/GeoUtil';
import { DistrictSelectorDialogRef } from './components/district/DistrictSelectorDialog';
import { EntryTabTypeEnum } from './components/entry/view-entry/ViewEntryVm';
import { GoogleMapVm, IMapBounds } from './components/google-map/GoogleMapVm';
import { MapPathTabType } from './components/map-path/view-map-path/ViewMapPathVm';
import { MapboxMapVm } from './components/mapbox-map/MapboxMapVm';
import { MapFilterVm } from './components/MapFilterVm';
import { IPoiTab, PoiTabType } from './components/poi/new-poi/NewPoiVm';
import { SubzoneTabType } from './components/subzone/view-subzone/ViewSubzoneVm';
import { DistrictFilterVm } from './filters/DistrictFilterVm';
import { MapDistrictsVm } from './MapDistrictsVm';
import { MapEntryVm } from './MapEntryVm';
import { MapPathsVm } from './MapPathsVm';
import { MapPoiVm } from './MapPoiVm';
import { MapSubzonesVm } from './MapSubzonesVm';
import { MapVm } from './MapVm';
import { PrintMapVm } from './print-map/PrintMapVm';
import { KmlImportModalRef } from './components/district/view-district/components/kml-upload/KmlImportModalVm';

@transient()
export class MapRouteVm extends ViewModel {

  @observable
  public googleLoaded = false;

  @observable
  public showPoiLabels: boolean = false;

  /**
   * Once you place the first point within a district, any subsequent points of that subzone or path will be associated with that district.
   * This prevents constant switching between districts when clicking in overlapping areas.
  */
  @observable
  private firstPointAlreadyAssignedToDistrict: boolean = false;

  public sidebarRef: React.RefObject<DrawerOpenRef> = React.createRef();

  public kmlImportModalRef: React.RefObject<KmlImportModalRef> = React.createRef();

  public districtSelectorDialogRef: React.RefObject<DistrictSelectorDialogRef> = React.createRef();

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(MapDistrictsVm) public readonly districtsVm: MapDistrictsVm,
    @inject(MapPathsVm) public readonly mapPathsVm: MapPathsVm,
    @inject(MapEntryVm) public readonly entryVm: MapEntryVm,
    @inject(GoogleMapVm) public readonly googleMapVm: GoogleMapVm,
    @inject(MapboxMapVm) public readonly mapboxMapVm: MapboxMapVm,
    @inject(MapVm) public readonly mapVm: MapVm,
    @inject(PrintMapVm) public readonly printMapVm: PrintMapVm,
    @inject(MapPoiVm) public readonly poiVm: MapPoiVm,
    @inject(MapSubzonesVm) public readonly subzoneVm: MapSubzonesVm,
    @inject(GeolocationService) private readonly geolocationService: GeolocationService,
    @inject(SessionStore) public readonly session: SessionStore,
    @inject(WsService) private readonly wsService: WsService,
    @inject(MapFilterVm) public readonly mapFilterVm: MapFilterVm,
    @inject(TrackingService) private readonly tracking: TrackingService,
  ) {
    super();
    makeObservable(this);
  }

  public override async onInit() {
    this.subzoneVm.onInit();

    this.loadGoogleMapLib();

    this.districtsVm.getAllDistricts.run();
    this.geolocationService.getCurrentLocation(false);

    // * Reaction to fetch data specific to the selected district
    reaction(
      () => this.districtsVm.selectedDistrict,
      this.loadDistrictData.run
    );

    // * Reaction when mapBounds changes
    reaction(
      () => this.googleMapVm.mapBounds,
      bounds => {
        if (this.districtsVm.selectedDistrict?.id) {
          /**
            * Load data within the map bounds only when a districtId is available,
            * avoiding the initial load of the Private map
            * (prevents loading a large amount of data unless the user explicitly selects the Private Map).
          */
          this.loadDataInMapBounds.run(bounds);
        }
      }
    );

    this.listenDistrictWsEvents();
    this.listenSubzoneWsEvents();
    this.listenEntryWsEvents();
    this.listenPoiWsEvents();
    this.listenTaskWsEvents();
    this.listenMapPathWsEvents();
  }

  public listenDistrictWsEvents = () => {
    this.wsService.registerHandler(WsEvent.DistrictCreate, (dto: DistrictResponseDto) => {
      const district = DistrictModel.fromDto(dto);
      this.districtsVm.upsert(district);
    });

    this.wsService.registerHandler(WsEvent.DistrictUpdate, (dto: DistrictResponseDto) => {
      const district = DistrictModel.fromDto(dto);
      this.districtsVm.upsert(district);

      if (this.districtsVm.selectedDistrict?.id === district.id) {
        this.districtsVm.selectDistrict(district);
      }
    });

    this.wsService.registerHandler(WsEvent.DistrictDelete, (dto: DeletedEntityDto) => {
      this.districtsVm.deleteDistrictId(dto.id);
    });
  }

  public listenEntryWsEvents = () => {
    this.wsService.registerHandler(WsEvent.EntryCreate, (dto: EntryResponseDto) => {
      if (dto.districtId !== this.districtsVm.selectedDistrict?.id) {
        return;
      }

      const entry = EntryModel.fromDto(dto);
      this.entryVm.upsert(entry);
    });

    this.wsService.registerHandler(WsEvent.EntryUpdate, (dto: EntryResponseDto) => {
      if (dto.districtId !== this.districtsVm.selectedDistrict?.id) {
        return;
      }

      const entry = EntryModel.fromDto(dto);
      this.entryVm.upsert(entry);
    });

    this.wsService.registerHandler(WsEvent.EntryDelete, (dto: DeletedEntityDto) => {
      this.entryVm.deleteEntryId(dto.id);
    });
  }

  public listenSubzoneWsEvents = () => {
    this.wsService.registerHandler(WsEvent.SubzoneCreate, (dto: SubzoneResponseDto) => {
      if (dto.districtId !== this.districtsVm.selectedDistrict?.id) {
        return;
      }

      this.subzoneVm.upsert(SubzoneModel.fromDto(dto));
    });

    this.wsService.registerHandler(WsEvent.SubzoneUpdate, (dto: SubzoneResponseDto) => {
      if (dto.districtId !== this.districtsVm.selectedDistrict?.id) {
        return;
      }

      this.subzoneVm.upsert(SubzoneModel.fromDto(dto));
    });

    this.wsService.registerHandler(WsEvent.SubzoneDelete, (dto: DeletedEntityDto) => {
      this.subzoneVm.deleteSubzoneId(dto.id);
    });
  }

  public listenPoiWsEvents = () => {
    this.wsService.registerHandler(WsEvent.PoiCreate, (dto: PoiResponseDto) => {
      if (dto.districtId !== this.districtsVm.selectedDistrict?.id) {
        return;
      }

      this.poiVm.upsert(PoiModel.fromDto(dto));
    });

    this.wsService.registerHandler(WsEvent.PoiUpdate, (dto: PoiResponseDto) => {
      if (dto.districtId !== this.districtsVm.selectedDistrict?.id) {
        return;
      }

      this.poiVm.upsert(PoiModel.fromDto(dto));
    });

    this.wsService.registerHandler(WsEvent.PoiDelete, (dto: DeletedEntityDto) => {
      this.poiVm.deletePoiId(dto.id);
    });
  }

  public listenTaskWsEvents = () => {
    this.wsService.registerHandler(WsEvent.TaskCreate, (dto: TaskResponseDto) => {
      if (dto.districtId !== this.districtsVm.selectedDistrict?.id) {
        return;
      }

      this.poiVm.upsertTask(TaskModel.fromDto(dto));
    });

    this.wsService.registerHandler(WsEvent.TaskUpdate, (dto: TaskResponseDto) => {
      if (dto.districtId !== this.districtsVm.selectedDistrict?.id) {
        return;
      }

      this.poiVm.upsertTask(TaskModel.fromDto(dto));
    });

    this.wsService.registerHandler(WsEvent.TaskDelete, (dto: DeletedEntityDto) => {
      this.poiVm.deleteTaskId(dto.id);
    });
  }

  public listenMapPathWsEvents = () => {
    this.wsService.registerHandler(WsEvent.MapPathCreate, (dto: MapPathResponseDto) => {
      if (dto.districtId !== this.districtsVm.selectedDistrict?.id) {
        return;
      }

      this.mapPathsVm.upsert(MapPathModel.fromDto(dto));
    });

    this.wsService.registerHandler(WsEvent.MapPathUpdate, (dto: MapPathResponseDto) => {
      if (dto.districtId !== this.districtsVm.selectedDistrict?.id) {
        return;
      }

      this.mapPathsVm.upsert(MapPathModel.fromDto(dto));
    });

    this.wsService.registerHandler(WsEvent.MapPathDelete, (dto: DeletedEntityDto) => {
      this.mapPathsVm.deleteMapPathId(dto.id);
    });
  }

  public override async onDestroy() {
    this.subzoneVm.onDestroy();
  }

  public closeFilter = () => {
    this.mapFilterVm.closeFilter();
    this.sidebarRef.current?.close();
  }

  @computed
  public get districtFilterVm() {
    return new DistrictFilterVm(
      this.session,
      this.i18n,
      this.selectedDistrict,
      this.districtsVm.allDistricts,
      this.poiVm.poisImportInProgress ? [] : this.entryVm.entries,  // * hide entries when import POIs from KML file is in progress
      this.printMapVm.isPrintMapActive ? this.poiVm.pois.filter((p) => p.type !== POI_TYPE.WEATHER) : this.poiVm.pois,
      this.poiVm.poisImportInProgress ? [] : this.mapPathsVm.mapPaths,  // * hide mapPaths when import POIs from KML file is in progress
      this.poiVm.poisImportInProgress ? [] : this.mapPathsVm.mapPathTypes,  // * hide mapPathTy[es] when import POIs from KML file is in progress
      this.poiVm.poisImportInProgress ? [] : this.subzoneVm.subzones,   // * hide subzones when import POIs from KML file is in progress
      this.currentEntry,
      this.poiVm.currentPoi,
      this.currentMapPath,
      this.currentSubzone,
    );
  }

  @computed
  public get selectedDistrict() {
    return this.districtsVm.selectedDistrict;
  }

  @computed
  public get currentEntry() {
    return this.entryVm.currentEntry;
  }

  @computed
  public get currentPoi() {
    return this.poiVm.currentPoi;
  }

  @computed
  public get currentMapPath() {
    return this.mapPathsVm.currentMapPath;
  }

  @computed
  public get currentSubzone() {
    return this.subzoneVm.currentSubzone;
  }

  @computed
  public get setupMode() {
    if (this.districtsVm.setupModeActive) {
      return true;
    }

    if (this.currentEntry) {
      return true;
    }

    if (this.currentPoi) {
      return true;
    }

    if (this.currentMapPath) {
      return true;
    }

    if (this.currentSubzone) {
      return true;
    }

    if (this.poiVm.poisImportInProgress) {
      return true;
    }

    return false;
  }


  @computed
  public get highlightedId(): string | undefined {
    if (this.currentEntry) {
      return this.currentEntry.id;
    }

    if (this.currentPoi) {
      return this.currentPoi.id;
    }

    if (this.currentMapPath) {
      return this.currentMapPath.id;
    }

    if (this.currentSubzone) {
      return this.currentSubzone.id;
    }
  }

  @computed
  public get draggableCursor() {
    // do not show crosshair in entry setup model when position tab not selected
    if (this.currentEntry && this.entryVm.lastEntryTab !== EntryTabTypeEnum.POSITION) {
      return;
    }

    // do not show crosshair in poi setup model when position tab not selected
    if (this.currentPoi && (this.poiVm.viewMode || this.poiVm.newPoiTab.type !== PoiTabType.POSITION)) {
      return;
    }

    // do not show crosshair in map path setup model when path tab not selected
    if (this.currentMapPath && this.mapPathsVm.lastTab !== MapPathTabType.PATH) {
      return;
    }

    if (this.currentSubzone) {
      // do not show crosshair in subzone setup model when borders tab not selected
      if (this.subzoneVm.lastTab !== SubzoneTabType.BORDERS) {
        return;
      }

      // if mouse is outside of district mesh, show blocked cursor
      if (this.selectedDistrict && this.googleMapVm.lastMouseLocation && !GeoUtil.isPointInMesh(this.googleMapVm.lastMouseLocation, this.selectedDistrict.mesh.points)) {
        return `url(${Assets.cursor.blocked}) 10 10, crosshair`;
      }
    }

    if (this.poiVm.poisImportInProgress) {
      return;
    }

    // if we are currently in any setup mode, show crosshair
    return this.setupMode ? 'crosshair' : undefined;
  }

  @action
  public toggleShowPoiLabels = async () => {
    this.showPoiLabels = !this.showPoiLabels;
    if (this.showPoiLabels) {
      await this.tracking.track(TrackingEvent.LABELS_ACTIVATED);
    }
  }

  public closeSidebars = (continueEditing: boolean = false) => {
    if (continueEditing) {
      return;
    }

    runInAction(() => {
      this.firstPointAlreadyAssignedToDistrict = false;
    });

    this.closeDistrictDetails();
    this.closeEntryDetails();
    this.closePoi();
    this.closeMapPath();
    this.closeSubzone();
    this.closeFilter();
  }

  public openDistrictDetails = () => {
    if (this.printMapVm.isPrintMapActive) {
      return;
    }

    this.closeSidebars();
    this.districtsVm.setViewMode(true);
    this.sidebarRef.current?.open();
  }

  public handleUserLeftDistrict = async (district: DistrictModel) => {
    this.districtsVm.removeDistrict(district);
    await this.loadDataInMapBounds.run(this.googleMapVm.mapBounds);
    this.closeDistrictDetails();
  }

  public editDistrict = (district: DistrictModel, points?: IGeoLocation[]) => {
    if (this.printMapVm.isPrintMapActive) {
      return;
    }

    this.districtsVm.editDistrict(district, points);
    this.closeDistrictDetails();
  }

  public closeDistrictDetails = () => {
    this.districtsVm.setViewMode(false);
    this.sidebarRef.current?.close();
  }

  @computed
  public get setupDrawingMode(): boolean {
    if (this.currentMapPath && this.mapPathsVm.lastTab === MapPathTabType.PATH) {
      return true;
    }

    if (this.currentSubzone && this.subzoneVm.lastTab === SubzoneTabType.BORDERS) {
      return true;
    }

    return false;
  }

  private shouldChangeDistrict = (districtId: string | undefined) => {
    const changeToDistrict = this.districtsVm.allDistricts.find(district => district.id === districtId);
    if (changeToDistrict) {
      this.districtsVm.setSelectedDistrict(changeToDistrict);
    }
  }

  public openPoiDetails = (poi: PoiModel) => {
    if (this.printMapVm.isPrintMapActive) {
      return;
    }

    this.closeSidebars();
    this.poiVm.showPoi(poi);

    this.shouldChangeDistrict(poi.districtId);
    this.sidebarRef.current?.open();

    if (poi.location) {
      this.mapVm.flyTo(poi.location);
    }
  }

  public openMapPathDetails = (mapPath: MapPathModel) => {
    if (this.setupDrawingMode || this.printMapVm.isPrintMapActive) {
      return;
    }

    this.closeSidebars();
    this.mapPathsVm.showMapPath(mapPath);
    this.shouldChangeDistrict(mapPath.districtId);
    this.sidebarRef.current?.open();
    this.mapVm.centerMesh(mapPath.mesh.points, true);
  }

  public openSubzoneDetails = (subzone: SubzoneModel) => {
    if (this.setupDrawingMode || this.printMapVm.isPrintMapActive) {
      return;
    }

    this.closeSidebars();
    this.subzoneVm.showSubzone(subzone);
    this.shouldChangeDistrict(subzone.districtId);
    this.sidebarRef.current?.open();
    this.mapVm.centerMesh(subzone.mesh.points, true);
  }

  public deleteMapPath = async (mapPath: MapPathModel) => {
    await this.mapPathsVm.delete(mapPath);

    if (this.currentMapPath) {
      this.closeMapPath();
    }
  }

  public deleteSubzone = async (subzone: SubzoneModel) => {
    await this.subzoneVm.delete(subzone);

    if (this.currentSubzone) {
      this.closeSubzone();
    }
  }

  public closeMapPath = () => {
    this.mapPathsVm.closeMapPath();
    this.sidebarRef.current?.close();
  }

  public closeSubzone = () => {
    this.subzoneVm.closeSubzone();
    this.sidebarRef.current?.close();
  }

  public closePoi = () => {
    this.poiVm.closePoi();
    this.sidebarRef.current?.close();
  }

  public startNewMapPath = () => {
    if (this.printMapVm.isPrintMapActive) {
      return;
    }

    this.closeSidebars();

    this.mapPathsVm.startNewMapPath(this.selectedDistrict);
    this.sidebarRef.current?.open();
  }

  public startNewSubzone = () => {
    if (!this.selectedDistrict || this.selectedDistrict.isWorldMap || this.printMapVm.isPrintMapActive) {
      return;
    }

    this.closeSidebars();
    this.subzoneVm.startNewMapSubzone(this.selectedDistrict);
    this.sidebarRef.current?.open();

    // * To make sure that subzone is assigned to correct district
    if (this.selectedDistrict && this.currentSubzone) {
      reaction(
        () => this.selectedDistrict!.id,
        this.currentSubzone.setDistrictId
      );
    }
  }

  public startNewEntry = (type: ENTRY_TYPE) => {
    if (this.printMapVm.isPrintMapActive) {
      return;
    }

    this.closeSidebars();
    this.entryVm.startNewEntry(this.selectedDistrict, type);
    this.sidebarRef.current?.open();

    // * To make sure that entry is assigned to correct district
    if (this.selectedDistrict && this.currentEntry) {
      reaction(
        () => this.selectedDistrict!.id,
        this.currentEntry.setDistrictId
      );
    }
  }

  public openFilters = () => {
    if (this.session.isProUser) {
      this.closeSidebars();
      this.mapFilterVm.openFilter();
      this.sidebarRef.current?.open();
    }
  }

  @action
  public startNewPoi = () => {
    if (this.printMapVm.isPrintMapActive) {
      return;
    }

    this.closeSidebars();
    this.poiVm.startNewPoi();
    this.sidebarRef.current?.open();

    // * To make sure that POI is assigned to correct district
    if (this.selectedDistrict && this.currentPoi) {
      reaction(
        () => this.selectedDistrict!.id,
        this.currentPoi.setDistrictId
      );
    }
  }

  public startEntryEdit = (entry: EntryModel) => {
    if (this.printMapVm.isPrintMapActive) {
      return;
    }

    this.closeSidebars();

    this.entryVm.startEntryEdit(entry);
    this.sidebarRef.current?.open();

    if (entry.location) {
      this.mapVm.flyTo(entry.location);
    }
  }

  public saveDistrict = async () => {
    const pois = (this.districtsVm.selectedDistrict?.id || this.districtsVm.kmlImport) ? this.poiVm.pois : [];
    await this.districtsVm.saveDistrict.run(pois);
    /**
     * Load fresh POIs within the map bounds because when importing district with POIs, we are only displaying POIs present in the import file.
     * However, after saving, they become un-clickable. Therefore, we need to reload them.
    */
    await this.poiVm.getPoisInMapBounds.run(this.googleMapVm.mapBounds);
  }

  public startNewDistrict = (name: string, points: IGeoLocation[], pois: PoiModel[], kmlImport: boolean) => {
    if (this.printMapVm.isPrintMapActive) {
      return;
    }

    this.districtsVm.setKmlImport(kmlImport);

    if (kmlImport) {
      this.poiVm.setPoisInMapBounds(pois);
    } else {
      this.poiVm.setPoisInMapBounds(this.poiVm.poisInMapBounds.concat(pois));
    }

    this.districtsVm.startNewDistrict(name, points, kmlImport);
  }

  public handleDeleteDistrict = async (district: DistrictModel) => {
    await this.districtsVm.deleteDistrict(district);
    await this.loadDataInMapBounds.run(this.googleMapVm.mapBounds);
  }

  public deleteEntry = async (entry: EntryModel) => {
    await this.entryVm.deleteEntry.run(entry);

    if (this.currentEntry) {
      this.closeSidebars();
    }
  }

  public importMultiplePois = (pois: PoiModel[]) => {
    this.closeSidebars();
    this.mapVm.flyTo(pois[0].location!, 15);
    this.poiVm.setPoisImportInProgress(true);
    this.poiVm.setPoisInMapBounds(pois);  // * display only pois from import file
  }

  public cancelPoisImport = async () => {
    this.poiVm.setPoisImportInProgress(false);
    await this.poiVm.getPoisInMapBounds.run(this.googleMapVm.mapBounds);
  }

  public saveMultiplePoisImport = async () => {
    await Promise.all([
      await this.poiVm.saveMultiplePois.run(this.poiVm.poisInMapBounds),
      await this.poiVm.getPoisInMapBounds.run(this.googleMapVm.mapBounds),  // * load all pois in that map bounds because when importing pois we are displaying only the ones that are in import file
    ]);
  }

  public startPoiEdit = (poi: PoiModel, tab: IPoiTab) => {
    if (this.printMapVm.isPrintMapActive) {
      return;
    }

    this.closeSidebars();

    this.poiVm.startPoiEdit(poi, tab);
    this.sidebarRef.current?.open();

    if (poi.location) {
      this.mapVm.flyTo(poi.location);
    }
  }

  public deletePoi = async (poi: PoiModel) => {
    await this.poiVm.deletePoi.run(poi);

    if (this.poiVm.viewMode) {
      this.closeSidebars();
    }
  }

  public openEntryDetails = (entry: EntryModel) => {
    if (this.printMapVm.isPrintMapActive) {
      return;
    }

    this.closeSidebars();
    this.entryVm.startEntryEdit(entry);
    this.shouldChangeDistrict(entry.districtId);
    this.sidebarRef.current?.open();

    if (entry.location) {
      this.mapVm.flyTo(entry.location);
    }
  }

  public closeEntryDetails = () => {
    this.entryVm.setEntry(null);
    this.sidebarRef.current?.close();
  }

  public handleDistrictLineClick = (location: IGeoLocation) => {
    if (this.currentSubzone && this.selectedDistrict) {
      this.subzoneVm.mapClick(location, this.selectedDistrict, true);
    }
  }

  public handleMapClick = (location: IGeoLocation) => {
    if (this.districtsVm.setupModeActive) {
      this.districtsVm.selectedDistrict?.mesh.addMeshPoint(location);
    } else {
      // * handle selection of district via click on a map rendered district
      const districtsWhereThisPointExists = this.districtFilterVm.districtsToRender
        .filter(district => GeoUtil.isPointInMesh(location, district.mesh.points));

      const pathOrSubzoneInCreation = !this.currentMapPath?.mesh.firstPointSet || !this.currentSubzone?.mesh.firstPointSet;

      if (pathOrSubzoneInCreation && !this.firstPointAlreadyAssignedToDistrict) {
        if (districtsWhereThisPointExists.length === 1) {
          this.districtsVm.setSelectedDistrict(districtsWhereThisPointExists[0]);
        } else if (districtsWhereThisPointExists.length > 1) {
          this.districtSelectorDialogRef.current?.open(this.districtsVm.selectedDistrict, districtsWhereThisPointExists);
        } else if (districtsWhereThisPointExists.length === 0) {
          this.districtsVm.setSelectedDistrict(DistrictModel.privateMapDistrict(this.i18n.t('map:world_map')));
        }
      }
    }

    if (this.currentEntry) {
      this.entryVm.mapClick(location, this.selectedDistrict);
      this.mapVm.flyTo(location);
      /**
       * In entry view mode, clicking on an area that the entry doesn't belong to will close the view mode. Note that this behavior doesn't apply in creation mode.
       * For creation mode, @see MapEntryVm.mapClick
      */
      if (this.currentEntry.id && this.currentEntry.districtId !== this.selectedDistrict?.id) {
        this.closeEntryDetails();
      }
    }

    if (this.currentPoi) {
      this.poiVm.mapClick(location, this.selectedDistrict);
      this.mapVm.flyTo(location);
      // * In poi view mode, clicking on an area that the POI doesn't belong to will close the view mode. Note that this behavior doesn't apply in creation mode.
      if (this.currentPoi.id && this.currentPoi.districtId !== this.selectedDistrict?.id) {
        this.closePoi();
      }
    }

    if (this.currentMapPath) {
      this.mapPathsVm.mapClick(location, this.selectedDistrict);
      runInAction(() => {
        this.firstPointAlreadyAssignedToDistrict = true;
      });

      //  * In mapPath view mode, clicking on an area that the mapPath doesn't belong to will close the view mode. Note that this behavior doesn't apply in creation mode.
      if (this.currentMapPath.id && this.currentMapPath.districtId !== this.selectedDistrict?.id) {
        this.closeMapPath();
      }
    }

    if (this.currentSubzone && this.selectedDistrict) {
      this.subzoneVm.mapClick(location, this.selectedDistrict);
      runInAction(() => {
        this.firstPointAlreadyAssignedToDistrict = true;
      });

      // * In subzone view mode, clicking on an area that the subzone doesn't belong to will close the view mode. Note that this behavior doesn't apply in creation mode.
      if (this.currentSubzone.id && this.currentSubzone.districtId !== this.selectedDistrict?.id) {
        this.closeSubzone();
      }
    }
  }

  public saveMapPath = new AsyncTask(async () => {
    const saved = await this.mapPathsVm.save();
    if (saved) {
      this.closeSidebars();
      this.sidebarRef.current?.close();
    }

    return saved;
  });

  public saveEntry = new AsyncTask(async () => {
    const saved = await this.entryVm.save();
    if (saved) {
      this.closeSidebars();
      this.sidebarRef.current?.close();
    }

    return saved;
  });

  public savePoi = new AsyncTask(async () => {
    const saved = await this.poiVm.save();
    if (saved) {
      this.closeSidebars();
      this.sidebarRef.current?.close();
    }

    return saved;
  });

  public saveSubzone = new AsyncTask(async () => {
    const saved = await this.subzoneVm.save();
    if (saved) {
      this.closeSidebars();
      this.sidebarRef.current?.close();
    }

    return saved;
  });

  public loadDataInMapBounds = new AsyncTask(async (mapBounds: IMapBounds) => {
    if (!mapBounds) {
      return;
    }

    if (this.districtsVm.kmlImport) {
      return;
    }

    try {
      await Promise.all([
        this.districtsVm.getDistrictsInMapBounds.run(mapBounds),
        this.poiVm.getPoisInMapBounds.run(mapBounds),
        this.entryVm.getEntriesInMapBounds.run(mapBounds),
        this.mapPathsVm.getMapPathsInMapBounds.run(mapBounds),
      ]);

      // * Used solely for fetching subzones within the districts of the given map bounds
      if (this.districtsVm.districtsInMapBounds.length) {
        this.subzoneVm.getSubzonesInMapBounds(this.districtsVm.districtsInMapBounds);
      }
    } catch (e) {
      console.error(`error while loading district data. ${e}`);
    }
  })

  // * Logic for when only ONE district is loaded, enabling data display in a district view sidebar.
  private loadDistrictData = new AsyncTask(async (district: DistrictModel | null) => {
    if (!district) {
      return;
    }

    if (!district.id) {
      return;
    }

    try {
      await Promise.all([
        this.poiVm.getDistrictPois.run(district),
        this.mapPathsVm.getDistrictMapPaths.run(district),
        this.mapPathsVm.getMapPathTypes.run(),
        this.subzoneVm.getDistrictSubzones.run(district),
        this.poiVm.getDistrictTasks.run(district),
      ]);

      if (district.isWorldMap) {
        return await this.entryVm.getAllEntries.run(district, this.districtsVm.allDistricts);
      }

      // * This is related the PRIVATE/PUBLIC property of the entry in some district. From now on, NEW entries will always be PUBLIC. But leave this for backward compatibility.
      await Promise.all([
        this.entryVm.getPublicEntries.run(district),
        this.entryVm.getPrivateEntries.run(district),
      ]);
    } catch (e) {
      console.error(`error while loading district data. ${e}`);
    }
  })

  @computed
  public get hasProPaths() {
    return this.session.hasFeatureEnabled(FEATURE.MAP_PATH);
  }

  @computed
  public get hasProSubzones() {
    return this.session.hasFeatureEnabled(FEATURE.SUBZONES);
  }

  @action
  private loadGoogleMapLib = () => {

    if (window.google && window.google.maps && window.google.maps.geometry) {
      this.googleLoaded = true;
      return;
    }

    new Loader({
      apiKey: env.google.mapApiKey,
      language: this.session.session?.user.language,
      libraries: ['geometry'],
    }).loadCallback((err) => {
      if (err) {
        console.error('error while loading google maps', err);
        return;
      }

      runInAction(() => {
        this.googleLoaded = true;
      });
    });
  }
}
