import { inject } from 'inversify';

import { singleton } from '../../inversify/decorator';
import { EntryPostRequestDto, EntryPutRequestDto, EntryResponseDto } from '../../shared/dto';
import { AreaPostRequestDto } from '../../shared/dto/area.post.request.dto';
import { PhotoDeleteMultipleRequestDto } from '../../shared/dto/photo.delete.multiple.request.dto';
import { AsyncTask } from '../async/AsyncTask';
import { EntryModel } from '../model/EntryModel';
import { PhotoModel } from '../model/PhotoModel';
import {
  AjaxService, IHttpNotOkResponse, IHttpOkResponse, IHttpResponse
} from '../service/AjaxService';
import { Proxy } from './Proxy';

@singleton()
export class EntryProxy extends Proxy {

  constructor(@inject(AjaxService) private readonly ajax: AjaxService) {
    super();
  }

  public getPublicEntries = new AsyncTask(async (districtId: string | undefined) => {
    const result = await this.ajax.get<EntryResponseDto[]>(
      `/entry/v2/${districtId === 'worldmap' ? districtId : `district/${districtId}`}`
    );

    if (result.ok) {
      return this.withData(result,
        result.data.map(EntryModel.fromDto));
    }

    return result;
  })

  public getPrivateEntries = new AsyncTask(async (districtId: string | undefined) => {
    const result = await this.ajax.get<EntryResponseDto[]>(
      `/entry/v2/private/${districtId === 'worldmap' ? districtId : `district/${districtId}`}`
    );

    if (result.ok) {
      return this.withData(result,
        result.data.map(EntryModel.fromDto));
    }

    return result;
  })

  public getAllEntries = async (): Promise<IHttpNotOkResponse | IHttpOkResponse<EntryModel[]>> => {
    const result = await this.ajax.get<EntryResponseDto[]>('/entry/v2');

    if (result.ok) {
      return this.withData(result,
        result.data.map(EntryModel.fromDto));
    }

    return result;
  }

  public getEntriesInMapBounds = async (requestDto: AreaPostRequestDto) => {
    const result = await this.ajax.post<EntryResponseDto[]>('/entry/v2/area', requestDto);
    if (result.ok) {
      return this.withData(result, result.data.map(EntryModel.fromDto));
    }

    return result;
  }

  public createEntry = async (data: EntryPostRequestDto): Promise<IHttpNotOkResponse | IHttpOkResponse<EntryModel>> => {
    const result = await this.ajax.post<EntryResponseDto>(
      '/entry/v2',
      data
    );

    if (result.ok) {
      return this.withData(result,
        EntryModel.fromDto(result.data));
    }

    return result;
  }

  public updateEntry = async (data: EntryPutRequestDto): Promise<IHttpNotOkResponse | IHttpOkResponse<EntryModel>> => {
    const result = await this.ajax.put<EntryResponseDto>(
      '/entry/v2',
      data
    );

    if (result.ok) {
      return this.withData(result,
        EntryModel.fromDto(result.data));
    }

    return result;
  }

  public deleteEntry = async (entry: EntryModel): Promise<IHttpResponse<void>> => {
    return this.ajax.delete<void>(`entry/v2/${entry.id}`);
  }

  public uploadPhoto = async (id: string, file: File): Promise<IHttpNotOkResponse | IHttpOkResponse<EntryModel>> => {
    const fd = new FormData();

    fd.append('file', file);

    const httpResult = await this.ajax.upload<EntryResponseDto>(`entry/v2/photo/${id}`, fd, { method: 'PUT' });

    if (httpResult.ok) {
      return this.withData(httpResult,
        EntryModel.fromDto(httpResult.data));
    }

    return httpResult;
  }

  public deletePhoto = async (photo: PhotoModel) => {
    if (!photo.id) return;

    const requestDto = new PhotoDeleteMultipleRequestDto();
    requestDto.ids = [photo.id];

    return await this.ajax.delete<void>('photo/multiple', requestDto);
  }

}
