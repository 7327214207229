import { makeObservable, observable } from 'mobx';

import { StripeInvoiceResponseDto } from '../../../shared/dto/stripe/stripe.invoice.response.dto';
import { ViewModel } from '../../core/ViewModel';
import { StripeInvoiceLineItemModel } from './StripeInvoiceLineItemModel';

export class StripeInvoiceModel extends ViewModel {

  @observable
  public created: number = 0;

  @observable
  public currency: string = '';

  @observable
  public amount: number = 0;

  @observable
  public tax: number | null = null;

  @observable
  public startingBalance: number = 0;

  @observable
  public coupon: string | null = null;

  @observable
  public taxRate: number | null = null;

  @observable
  public items: StripeInvoiceLineItemModel[] = [];

  constructor() {
    super();
    makeObservable(this);
  }

  public static fromDto = (dto: StripeInvoiceResponseDto) => {
    const model = new StripeInvoiceModel();

    model.created = dto.created;
    model.currency = dto.currency;
    model.amount = dto.amount;
    model.tax = dto.tax;
    model.startingBalance = dto.startingBalance;
    model.coupon = dto.coupon;
    model.taxRate = dto.taxRate;
    model.items = dto.items.map(item => StripeInvoiceLineItemModel.fromDto(item));

    return model;
  }

}
