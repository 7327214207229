import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { BaseSyntheticEvent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Box, Button, Grid, TextField, ThemeProvider, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useQuotaPlanContext } from '../../../../../../../../domain/context/QuotaPlanContext';
import { useVm } from '../../../../../../../../domain/hooks/useVm';
import { QuotaPlanModel } from '../../../../../../../../domain/model/QuotaPlanModel';
import { QuotaPlanStatus } from '../../../../../../../../shared/enum/quotaPlanStatus.enum';
import { oldDesignTheme } from '../../../../../../../../theme/oldDesignTheme';
import { colors } from '../../../../../../../../theme/options/palette/const/colors';
import { useThemeContext } from '../../../../../../../../theme/ThemeContextProvider';
import { CustomDatePicker } from '../../../../../../../../toolkit/components/CustomDatePicker';
import { ValidatedForm } from '../../../../../../../../toolkit/components/form/ValidatedForm';
import {
  ValidatedFormControl
} from '../../../../../../../../toolkit/components/form/ValidatedFormControl';
import {
  NewSubmitButton
} from '../../../../../../../../toolkit/components/redesign-components/NewSubmitButton';
import { appLocaleList } from '../../../../../../../../toolkit/constants/LanguageList';
import { toLanguageRegion } from '../../../../../../../../util/classification/ClassificationUtils';
import { QuotaPlanList } from '../../district-harvest-list/components/QuotaPlanList';
import { QuotaPlanningScreens } from '../ViewDistrictQuotaPlanningTabVm';
import { QuotaClassification } from './classification/QuotaClassification';
import { IViewQuotaPlanVmProps, ViewQuotaPlanVm } from './ViewQuotaPlanVm';

export interface IViewQuotaPlanProps {
  quotaPlan: QuotaPlanModel;
  quotaPlans: QuotaPlanModel[];
}

export const ViewQuotaPlan = observer(function ViewQuotaPlan(props: IViewQuotaPlanProps) {
  const { currentQuotaPlan, setStep, setDisplayChangeRegionButton, region } = useQuotaPlanContext();
  const { theme } = useThemeContext();
  const { t } = useTranslation();
  const vm = useVm(ViewQuotaPlanVm, { setDisplayChangeRegionButton, ...props } as IViewQuotaPlanVmProps);
  const isSaveButtonDisabled = useMemo(() => vm.isPublishDisabled, [vm.isPublishDisabled]);

  const handleCancelCreation = useCallback(() => {
    setStep(QuotaPlanningScreens.QUOTA_PLANS_LIST);
    setDisplayChangeRegionButton(false);
  }, [setDisplayChangeRegionButton, setStep]);

  const openAnimalClassification = useCallback(() => {
    if (currentQuotaPlan.status === QuotaPlanStatus.PAST) {
      return;
    }
    runInAction(() => {
      vm.isAnimalClassificationOpened = !vm.isAnimalClassificationOpened;
      setDisplayChangeRegionButton(vm.isAnimalClassificationOpened);
    });
  }, [currentQuotaPlan.status, setDisplayChangeRegionButton, vm]);

  const onPublishPlan = useCallback(async () => {
    try {
      const success = await vm.publishQuotaPlan.run();

      if (success) {
        setStep(QuotaPlanningScreens.QUOTA_PLANS_LIST);
        setDisplayChangeRegionButton(false);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  }, [setDisplayChangeRegionButton, setStep, vm.publishQuotaPlan]);

  return (
    <ValidatedForm onSubmit={() => { return; }} styleProps={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}>
      <Grid container direction={'column'} height={'inherit'} flexGrow={1}>
        {/* FORM */}
        <Grid item container direction={'column'} rowGap={2}>
          {/* NAME */}
          <Grid item container rowGap={1}>
            <Grid item xs={12}>
              <Typography variant='text_md_semibold' color={theme.palette.text.tertiary}>{t('quota_plan:form.title')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <ValidatedFormControl
                fullWidth
                value={vm.tempQuotaPlan.name}
                schema={
                  yup
                    .string()
                    .required(t('quota_plan:form.name.placeholder'))
                }>
                <TextField
                  variant="standard"
                  type="text"
                  placeholder={t('quota_plan:form.name.placeholder')}
                  value={vm.tempQuotaPlan.name}
                  disabled={vm.isPastPlan}
                  InputProps={{
                    disableUnderline: false,
                    readOnly: vm.isPastPlan
                  }}
                  onChange={(event: BaseSyntheticEvent) => vm.tempQuotaPlan.setName(event.target.value)}
                  sx={{
                    '& .MuiInput-underline:after': { borderBottomColor: theme.palette.brand?.selection },
                    backgroundColor: theme.palette.system?.white,
                    border: 'none',
                    paddingLeft: 0,
                    input: {
                      '&::placeholder': {
                        fontSize: 16,
                        color: theme.palette.text.tertiary
                      },
                    }
                  }}
                />
              </ValidatedFormControl>
            </Grid>
          </Grid>
          <ThemeProvider theme={oldDesignTheme}>
            <Grid container>
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={appLocaleList[vm.i18n.language]}>
                <Box mb={1} display="flex" flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                  {vm.hasDateChanged && <Typography sx={{ cursor: 'pointer' }} onClick={vm.resetDates} color={colors.green[800]} variant="subtitle2">
                    {t('quota_plan:date.reset')}
                  </Typography>}
                </Box>
                <Grid container spacing={2}>
                  <CustomDatePicker
                    label={t('quota_plan:date.from')}
                    selectedDate={vm.from}
                    onChange={vm.setFrom}
                    readOnly={vm.isPastPlan}
                  />
                  <CustomDatePicker
                    label={t('quota_plan:date.to')}
                    selectedDate={vm.to}
                    onChange={vm.setTo}
                    readOnly={vm.isPastPlan}
                  />
                </Grid>
              </LocalizationProvider>
            </Grid>
          </ThemeProvider>

          {/* GAME */}
          <Grid item container direction={'column'} rowGap={2}>
            <Grid item container justifyContent={'space-between'}>
              <Grid item xs={6}>
                <Typography variant='text_md_semibold' color={theme.palette.text.secondary}>
                  {t('quota_plan:classification.title')}
                </Typography>
              </Grid>
              <Grid item xs={6} textAlign={'end'}>
                <Typography
                  variant='text_sm_semibold'
                  color={theme.palette.brand?.brand_color}
                  onClick={openAnimalClassification}
                  sx={{ cursor: currentQuotaPlan.status === QuotaPlanStatus.PAST ? 'not-allowed' : 'pointer' }}
                >
                  {t('quota_plan:classification.add_animal')}
                </Typography>
              </Grid>
              {(vm.tempQuotaPlan.species.length !== 0)
                && vm.tempQuotaPlan.species.filter(p => p.species !== null).length > 0
                && <Grid item xs={12} rowGap={2} mt={2}>
                  <Grid item textAlign='end'>
                    <Typography variant='subtitle2' color={theme.palette.text.secondary}>
                      {t('district:quota')}
                    </Typography>
                  </Grid>
                  <QuotaPlanList quotaPlanSpecies={vm.tempQuotaPlan.species} showGame={false} onDelete={!vm.isPastPlan ? vm.removeAnimalFromQuotaPlan : undefined} />
                </Grid>
              }
            </Grid>
            {vm.isAnimalClassificationOpened && <ThemeProvider theme={oldDesignTheme}>
              <QuotaClassification
                languageRegion={toLanguageRegion(vm.i18n.language, region)}
                tempQuotaPlanSpecie={vm.tempQuotaPlanSpecie}
              />
            </ThemeProvider>}
          </Grid>
        </Grid>
        {/* EMPTY SPACE */}
        <Grid item flexGrow={1} marginBottom={2} />
        {vm.isAnimalClassificationOpened && vm.enableAddAnimalButton && <Grid item container paddingBottom={1} justifyContent={'center'} >
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={vm.addAnimalToQuotaPlan}
            >
              {t('quota_plan:classification.button')}
            </Button>
          </Grid>
        </Grid>}
        {/* BUTTONS */}
        {!vm.isAnimalClassificationOpened && <Grid item container columnGap={2} paddingTop={2} justifyContent={'center'}>
          <Grid item xs={5}>
            <Button
              color='secondary'
              onClick={handleCancelCreation}
              fullWidth
            >{t('common:button.cancel')}</Button>
          </Grid>
          <Grid item xs={5}>
            <NewSubmitButton
              fullWidth
              disabled={isSaveButtonDisabled || currentQuotaPlan.status === QuotaPlanStatus.PAST} // Enable/disable the "Save" button based on form dirty status and submission state
              submitting={vm.publishQuotaPlan.isBusy}
              onClick={onPublishPlan}
            >{t('quota_plan:button.publish')}</NewSubmitButton>
          </Grid>
        </Grid>}
      </Grid>
    </ValidatedForm>
  );
});
