import { inject } from 'inversify';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { Assets } from '../../../../../assets';
import { IGoogleMapProps } from '../../../../../domain/core/IGoogleMapProps';
import { ViewModel } from '../../../../../domain/core/ViewModel';
import { PoiModel } from '../../../../../domain/model/PoiModel';
import { WeatherProxy } from '../../../../../domain/proxy/WeatherProxy';
import { transient } from '../../../../../inversify/decorator';
import { WeatherRealtimeResponseDto } from '../../../../../shared/dto/weather/weatherRealtime.response.dto';
import { getWeatherIcon } from '../../../../../util/WeatherUtil';


// eslint-disable-next-line @typescript-eslint/no-var-requires
const ts = require('@mapbox/timespace');
export interface IPoiWeatherMarkerProps extends IGoogleMapProps {
  poi: PoiModel;
  opacity: number;
}

@transient()
export class PoiWeatherMarkerVm extends ViewModel<IPoiWeatherMarkerProps> {

  @observable
  private weather: WeatherRealtimeResponseDto | null = null;

  constructor(
    @inject(WeatherProxy) private readonly weatherProxy: WeatherProxy,
  ) {
    super();
    makeObservable(this);
  }

  public override async onInit() {
    this.loadWeather(this.props.poi);
  }

  public isNight = (): boolean => {
    if (!this.props.poi.location) {
      return false;
    }

    const hours = ts.getFuzzyLocalTimeFromPoint(
      new Date(),
      [this.props.poi.location?.longitude, this.props.poi.location?.latitude],
    )?.hours();

    if (hours > 6 && hours < 20) {
      return false;
    }

    return true;
  }

  @computed
  public get background() {
    if (!this.weather) {
      return Assets.weather.poi_weather_loading;
    }

    return this.props.poi.icon;
  }

  @computed
  public get icon() {
    if (!this.weather) {
      return;
    }

    return getWeatherIcon(this.weather.weatherCode, this.isNight());
  }

  @computed
  public get temperature() {
    if (this.weather?.temperature.value) {
      return Math.round(this.weather.temperature.value);
    }
  }

  @computed
  public get unit() {
    if (this.weather) {
      if (this.weather.temperature.units === 'C') {
        return '°';
      }

      return this.weather.temperature.units;
    }
  }

  @computed
  public get windDegree() {
    if (!this.weather) {
      return 0;
    }

    return Math.round(this.weather.wind.degree.value ?? 0);
  }

  @computed
  public get windIcon() {
    if (!this.weather) {
      return;
    }

    return this.imageForWindSpeed(
      Math.round(this.weather.wind.speed),
      this.weather.wind.unit
    );
  }

  public imageForWindSpeed = (speed: number, unit: string) => {
    const kmh = this.convertUnitToKmh(speed, unit);

    if (kmh === 0) {
      return;
    }

    if (speed < 10) {
      return Assets.weather.wind_direction.wind_1_3;
    }

    if (speed < 20) {
      return Assets.weather.wind_direction.wind_4_7;
    }

    return Assets.weather.wind_direction.wind_8_10;
  };

  private convertUnitToKmh = (speed: number, unit: string) => {
    let kmhSpeed = speed;

    if (unit === 'm/s') {
      kmhSpeed = speed * 3.6;
    }

    if (unit === 'mph') {
      kmhSpeed = speed * 1.609344;
    }

    return kmhSpeed;
  };

  private loadWeather = async (poi: PoiModel) => {
    try {
      if (!poi.location) {
        return;
      }

      const result = await this.weatherProxy.getRealtimeWeather.run(poi.location);
      if (result.ok) {
        return runInAction(() => {
          this.weather = result.data;
        });
      }

      console.warn(`error while loading weather for poi ${poi.id}. status: ${result.status}`);
    } catch (e) {
      console.error(`exception while loading weather for poi ${poi.id}. ${e}`);
    }
  }
}
