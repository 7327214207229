import { ApiProperty } from '@nestjs/swagger';

import { MOON_AGE } from '../../enum/weather/moonAge.enum';
import { WEATHER_CODE } from '../../enum/weather/weatherCode.enum';
import { PrecipitationResponseDto, WeatherValueUnitItemResponseDto, WindResponseDto } from './';

export class WeatherRealtimeResponseDto {
  @ApiProperty()
  public date: string;

  @ApiProperty({ type: () => WeatherValueUnitItemResponseDto })
  public temperature: WeatherValueUnitItemResponseDto;

  @ApiProperty({ type: () => WeatherValueUnitItemResponseDto })
  public feelsLike: WeatherValueUnitItemResponseDto;

  @ApiProperty({ type: () => WindResponseDto })
  public wind: WindResponseDto;

  @ApiProperty({ type: () => WeatherValueUnitItemResponseDto })
  public baroPressure: WeatherValueUnitItemResponseDto;

  @ApiProperty({ type: () => WeatherValueUnitItemResponseDto })
  public visibility: WeatherValueUnitItemResponseDto;

  @ApiProperty({ type: () => WeatherValueUnitItemResponseDto })
  public humidity: WeatherValueUnitItemResponseDto;

  @ApiProperty()
  public precipitation: PrecipitationResponseDto;

  @ApiProperty()
  public weatherCode: WEATHER_CODE;

  @ApiProperty()
  public sunRise: string;

  @ApiProperty()
  public sunSet: string;

  @ApiProperty()
  public moonAge: MOON_AGE;

  @ApiProperty({ type: () => WeatherValueUnitItemResponseDto })
  public cloudCover: WeatherValueUnitItemResponseDto;
}
