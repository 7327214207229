import { inject } from 'inversify';
import { computed, makeObservable } from 'mobx';

import { SxProps, Theme } from '@mui/system';

import { IGeoLocation } from '../../../../../domain/core/IGeoLocation';
import { ViewModel } from '../../../../../domain/core/ViewModel';
import { DistrictModel } from '../../../../../domain/model/DistrictModel';
import { PoiModel } from '../../../../../domain/model/PoiModel';
import { I18nService } from '../../../../../domain/service/I18nService';
import { transient } from '../../../../../inversify/decorator';
import { DISTRICT_ROLE } from '../../../../../shared/enum/role.enum';

export interface IDistrictInfoProps {
  sx?: SxProps<Theme>;
  district: DistrictModel | null;
  districts: DistrictModel[];
  onEdit: (district: DistrictModel, points?: IGeoLocation[]) => void;
  onDelete: (district: DistrictModel) => void;
  onSelected: (district: DistrictModel) => void;
  importMultiplePois: (pois: PoiModel[]) => void
}

@transient()
export class DistrictInfoVm extends ViewModel<IDistrictInfoProps> {

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  public selectDistrict = (district: DistrictModel) => {
    this.props.onSelected(district);
  }

  @computed
  public get subtitle(): string | undefined {
    if (!this.props.district) {
      return;
    }

    const self = this.props.district?.self;

    if (self?.role?.name === DISTRICT_ROLE.OWNER) {
      return this.i18n.t('map:user_role_owner');
    }

    if (self?.role?.name === DISTRICT_ROLE.ADMIN) {
      return this.i18n.t('map:user_role_admin');
    }

    if (self?.role?.name === DISTRICT_ROLE.USER) {
      return this.i18n.t('map:user_role_member');
    }

    return this.i18n.t('map:entries_without_district');
  }
}
