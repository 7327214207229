import { Box, Typography } from '@mui/material';
import React from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';

import { Assets } from '../../../../../../assets';
import { useInstance } from '../../../../../../domain/hooks/useInstance';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { FEATURE } from '../../../../../../shared/enum/feature.enum';
import { palette } from '../../../../../../theme/options/palette/palette';
import { InfoComponent } from '../../../../../../toolkit/components/InfoComponent';

export function ViewPoiBlocked() {
  const { t } = useTranslation();
  const session = useInstance(SessionStore);
  const bookingsEnabled = session.hasFeatureEnabled(FEATURE.BOOKINGS);

  return (
    <Box bgcolor={bookingsEnabled ? palette.background?.info : 'white'} width="100%">
      {bookingsEnabled
        ? <>
          <InfoComponent
            locizePath="booking:booking_readonly"
          />
          <Box display="flex" paddingX={4} paddingY={2} flexDirection="row" alignItems="center" boxShadow="inset 0px 3px 6px #00000029">
            <Box position="relative">
              <Avatar size="40px" round src={Assets.poi.task.unassigned} />
              <Avatar size="16px" round src={Assets.poi.blocked} style={{
                position: 'absolute',
                top: -4,
                right: -4,
              }} />
            </Box>
            <Box marginLeft={2}>
              <Typography variant="h6">
                {t('booking:reserved')}
              </Typography>
            </Box>
          </Box>
        </>
        : <>
          <InfoComponent
            locizePath="poi:pro_only_feature"
          />
          <img src={Assets.poi.pro.bookings} style={{ width: '100%', marginTop: '30px' }} />
        </>
      }
    </Box >
  );
}
