import React from 'react';

import { Grid } from '@mui/material';

import { Assets } from '../../../../assets';
import { FeaturesModal } from '../../../pricing/components/FeaturesModal';

export function UserProfileMapWrapper({ children }: React.PropsWithChildren<unknown>) {
  return (
    <Grid container position="relative" paddingY={5}>
      <Grid item container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ position: 'absolute', top: '-70px' }}
      >
        <img src={Assets.shield} height="80px" width="80px" />
        <FeaturesModal />
      </Grid>
      {children}
    </Grid>
  );
}
