import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { Assets } from '../../../../../../assets';
import { useVm } from '../../../../../../domain/hooks/useVm';
import { EntryModel } from '../../../../../../domain/model/EntryModel';
import { MapPathModel } from '../../../../../../domain/model/MapPathModel';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { SubzoneModel } from '../../../../../../domain/model/SubzoneModel';
import { DeleteDialog } from '../../../../../../toolkit/components/DeleteDialog';
import { AddImage } from '../../../../../../toolkit/components/image-add/AddImage';
import { MenuItemTooltip } from '../../../district/view-district/components/MenuItemTooltip';
import { CustomizedMenuVm } from './CustomizedMenuVm';

export interface CustomizedMenuProps<T> {
  locizeNamespace: 'entry' | 'poi' | 'paths' | 'subzone';
  item: T;
  onUpsert: (item: T) => void;
  onDelete?: () => void;
  canEditAndDeleteEntries?: boolean;
  canEditAndDeletePoi?: boolean;
  canEditAndDeleteSubzone?: boolean;
  canEditAndDeleteMapPath?: boolean;
}

export type CustomizedMenuItemType = PoiModel | EntryModel | MapPathModel | SubzoneModel;

export const CustomizedMenu = observer(function CustomizedMenu(props: CustomizedMenuProps<CustomizedMenuItemType>) {

  const { t } = useTranslation();
  const vm = useVm(CustomizedMenuVm, props);

  const onItemDelete = useCallback(() => {
    if (props.onDelete) {
      props.onDelete();
    }
  }, [props]);

  const renderAddImageOption = useMemo(() => {
    return (
      <React.Fragment>
        {(props.item instanceof MapPathModel || props.item instanceof SubzoneModel)
          ? null
          : (vm.canEditAndDeleteItem
            ?
            // * canEditAndDeleteItem=true
            (
              vm.session.isProUser
                ?
                // * PRO user and canEditAndDeleteItem=true
                <MenuItem onClick={vm.handleAddImage}>
                  {t(`${props.locizeNamespace}:menu_option.add_image`)}
                </MenuItem>
                :
                // * FREE user and canEditAndDeleteItem=true
                (props.item.photos.length === 1
                  ?
                  // * FREE user, canEditAndDeleteItem=true and already have uploaded 1 image
                  <Tooltip placement="right" title={<MenuItemTooltip message={t(`${props.locizeNamespace}:menu_option.add_image_not_pro_tooltip`)} />}>
                    <div>
                      <MenuItem disabled onClick={vm.handleClickOnDisabledMenuItem}>
                        <Box display="flex" flexDirection={'row'} gap={1} sx={{ cursor: props.item.photos.length === 1 ? 'not-allowed' : 'pointer', pointerEvents: 'all' }}>
                          <Typography>{t(`${props.locizeNamespace}:menu_option.add_image`)}</Typography>
                          <img src={Assets.shield} width={14} />
                        </Box>
                      </MenuItem>
                    </div>
                  </Tooltip>
                  :
                  // * FREE user, canEditAndDeleteItem=true and 0 image is uploaded
                  <MenuItem onClick={vm.handleAddImage}>
                    <Box display="flex" flexDirection={'row'} gap={1} sx={{ cursor: !vm.canEditAndDeleteItem ? 'not-allowed' : 'pointer', pointerEvents: 'all' }}>
                      <Typography>{t(`${props.locizeNamespace}:menu_option.add_image`)}</Typography>
                      <img src={Assets.shield} width={14} />
                    </Box>
                  </MenuItem>)
            )
            :
            // * PRO/FREE user and canEditAndDeleteItem=false
            <Tooltip placement="right" title={<MenuItemTooltip message={t(`${props.locizeNamespace}:menu_option.add_image_tooltip`)} />}>
              <div>
                <MenuItem disabled onClick={vm.handleClickOnDisabledMenuItem}>
                  <Box display="flex" flexDirection={'row'} gap={1} sx={{ cursor: 'not-allowed', pointerEvents: 'all' }}>
                    <Typography>{t(`${props.locizeNamespace}:menu_option.add_image`)}</Typography>
                    {!vm.session.isProUser && <img src={Assets.shield} width={14} />}
                  </Box>
                </MenuItem>
              </div>
            </Tooltip>
          )}
        {!(props.item instanceof MapPathModel || props.item instanceof SubzoneModel) && <AddImage ref={vm.addImageRef} item={props.item} closeMenu={vm.closeMenu} upsert={props.onUpsert} />}
      </React.Fragment>
    );
  }, [props.item, props.locizeNamespace, props.onUpsert, t, vm.addImageRef, vm.canEditAndDeleteItem, vm.closeMenu, vm.handleAddImage, vm.handleClickOnDisabledMenuItem, vm.session.isProUser]);

  const renderDeleteOption = useMemo(() => {
    const isDeletable = props.item.id && props.onDelete && vm.canEditAndDeleteItem;

    const onDeleteClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      vm.closeMenu(event);
      vm.deleteItemDialogRef.current?.handleOpenDeleteDialog(event);
    };

    const deleteMenuItem = (
      <MenuItem onClick={onDeleteClick}>
        {t(`${props.locizeNamespace}:menu_option.delete_${props.locizeNamespace}`)}
      </MenuItem>
    );

    const disabledDeleteMenuItem = (
      <Tooltip placement="right" title={<MenuItemTooltip message={t(`${props.locizeNamespace}:menu_option.delete_${props.locizeNamespace}_tooltip`)} />}>
        <div>
          <MenuItem disabled onClick={vm.handleClickOnDisabledMenuItem}>
            <Box display="flex" flexDirection={'row'} gap={1} sx={{ cursor: 'not-allowed', pointerEvents: 'all' }}>
              <Typography>{t(`${props.locizeNamespace}:menu_option.delete_${props.locizeNamespace}`)}</Typography>
            </Box>
          </MenuItem>
        </div>
      </Tooltip>
    );

    return (
      <React.Fragment>
        {isDeletable ? deleteMenuItem : disabledDeleteMenuItem}
      </React.Fragment>
    );
  }, [props.item, props.locizeNamespace, props.onDelete, t, vm]);


  const renderMenu = useMemo(() => (
    <div>
      {/* ADD IMAGE */}
      {renderAddImageOption}

      {/* DELETE ITEM */}
      {renderDeleteOption}
    </div>
  ), [renderAddImageOption, renderDeleteOption]);

  return (
    <React.Fragment>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={vm.popoverOpen ? 'long-menu' : undefined}
        aria-expanded={vm.popoverOpen ? 'true' : undefined}
        aria-haspopup="true"
        onClick={vm.showMenu}
        color="inherit"
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={vm.anchorEl}
        open={vm.popoverOpen}
        keepMounted
        onClose={vm.closeMenu}
      >
        {renderMenu}
      </Menu>

      <DeleteDialog
        ref={vm.deleteItemDialogRef}
        locizeNamespace={props.locizeNamespace}
        onDelete={onItemDelete}
        closeMenu={vm.closeMenu} />
    </React.Fragment>
  );
});
