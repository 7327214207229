import { observer } from 'mobx-react-lite';
import React from 'react';

import { Box } from '@mui/system';

import { QuotaPlanContextProvider } from '../../domain/context/QuotaPlanContext';
import { useVm } from '../../domain/hooks/useVm';
import { LoadingFallback } from '../../LoadingFallback';
import { SideBar } from '../../toolkit/components/SideBar';
import { ProBlockerModal } from '../pro-blocker/ProBlockerModal';
import { DistrictInfo } from './components/district/district-info/DistrictInfo';
import { DistrictSelectorDialog } from './components/district/DistrictSelectorDialog';
import { ViewDistrict } from './components/district/view-district/ViewDistrict';
import { ViewEntry } from './components/entry/view-entry/ViewEntry';
import { FilterSidebar } from './components/filter-sidebar/FilterSidebar';
import { GoogleMap } from './components/google-map/GoogleMap';
import { ViewMapPath } from './components/map-path/view-map-path/ViewMapPath';
import { MapPathTabType } from './components/map-path/view-map-path/ViewMapPathVm';
import { MapboxMap } from './components/mapbox-map/MapboxMap';
import { NewPoi } from './components/poi/new-poi/NewPoi';
import { ViewPoi } from './components/poi/view-poi/ViewPoi';
import { ViewSubzone } from './components/subzone/view-subzone/ViewSubzone';
import { MapOverlay } from './MapOverlay';
import { MapRouteVm } from './MapRouteVm';

export const MapRoute = observer(function MapRoute() {
  const vm = useVm(MapRouteVm);
  const paddingValue = 20;

  if (!vm.googleLoaded) {
    return <LoadingFallback />;
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          height: '100vh',
          padding: `0px ${paddingValue}px ${paddingValue}px`,
        }}>
        <DistrictInfo
          sx={{ paddingTop: '35px', paddingBottom: '8px' }}
          district={vm.selectedDistrict}
          districts={vm.districtsVm.allDistricts}
          onSelected={vm.openDistrictDetails}
          onEdit={vm.districtsVm.editDistrict}
          onDelete={vm.handleDeleteDistrict}
          importMultiplePois={vm.importMultiplePois}
        />
        <SideBar
          anchor="left"
          ref={vm.sidebarRef}
        >
          {(vm.selectedDistrict && vm.districtsVm.viewMode) && <QuotaPlanContextProvider>
            <ViewDistrict
              selectedDistrict={vm.selectedDistrict}
              districts={vm.districtsVm.allDistricts}
              districtsSelectorFilter={vm.districtFilterVm.districtsSelectorFilter}
              pois={vm.poiVm.selectedDistrictPois}
              entries={vm.entryVm.selectedDistrictEntries}
              paths={vm.mapPathsVm.selectedDistrictMapPaths}
              subzones={vm.subzoneVm.selectedDistrictSubzones}
              tasks={vm.poiVm.tasks}
              pathTypes={vm.mapPathsVm.mapPathTypes}
              onPoiEdit={vm.startPoiEdit}
              onPoiDelete={vm.deletePoi}
              onEntryEdit={vm.startEntryEdit}
              onEntryDelete={vm.deleteEntry}
              onMapPathEdit={vm.openMapPathDetails}
              onMapPathDelete={vm.deleteMapPath}
              onSubzoneEdit={vm.openSubzoneDetails}
              onSubzoneDelete={vm.deleteSubzone}
              onClose={vm.closeDistrictDetails}
              onSelectDistrict={vm.districtsVm.selectDistrict}
              onEditDistrict={vm.editDistrict}
              onDeleteDistrict={vm.handleDeleteDistrict}
              onNewDistrict={vm.startNewDistrict}
              onDistrictLeft={vm.handleUserLeftDistrict}
              importMultiplePois={vm.importMultiplePois}
            />
          </QuotaPlanContextProvider>}
          {vm.currentEntry && <ViewEntry
            entry={vm.currentEntry}
            districtsVm={vm.districtsVm}
            upsertEntry={vm.entryVm.upsert}
            onClose={vm.closeEntryDetails}
            onSave={vm.saveEntry}
            onDelete={vm.deleteEntry}
            onTabChange={vm.entryVm.saveTabChange}
          />}
          {vm.currentPoi && (vm.poiVm.viewMode
            ? <ViewPoi
              poi={vm.currentPoi}
              upsertPoi={vm.poiVm.upsert}
              tasks={vm.poiVm.tasks}
              pois={vm.poiVm.selectedDistrictPois}
              onClose={vm.closePoi}
              onPoiEdit={vm.startPoiEdit}
              onPoiSave={vm.savePoi.run}
              onPoiDelete={vm.deletePoi}
              districtsVm={vm.districtsVm}
            />
            : <NewPoi
              poi={vm.currentPoi}
              upsertPoi={vm.poiVm.upsert}
              onClose={vm.closePoi}
              onSave={vm.savePoi}
              onTabChange={vm.poiVm.setCurrentTab}
              currentTab={vm.poiVm.newPoiTab}
              district={vm.selectedDistrict}
              weatherPoisCount={vm.poiVm.weatherPoisCount}
            />
          )}
          {vm.mapPathsVm.currentMapPath && <ViewMapPath
            mapPath={vm.mapPathsVm.currentMapPath}
            types={vm.mapPathsVm.mapPathTypes}
            onClose={vm.closeMapPath}
            onDelete={vm.deleteMapPath}
            upsertPath={vm.mapPathsVm.upsert}
            onSave={vm.saveMapPath}
            onTabChange={vm.mapPathsVm.setTab}
            districtsVm={vm.districtsVm}
          />}
          {vm.subzoneVm.currentSubzone && <ViewSubzone
            subzone={vm.subzoneVm.currentSubzone}
            onClose={vm.closeSubzone}
            onSave={vm.saveSubzone}
            onDelete={vm.deleteSubzone}
            upsertSubzone={vm.subzoneVm.upsert}
            onTabChange={vm.subzoneVm.setTab}
            districtsVm={vm.districtsVm}
          />}
          {vm.mapFilterVm.filterShown && <FilterSidebar
            onClose={vm.closeFilter}
            districtFilterVm={vm.districtFilterVm}
          />}
        </SideBar>
        {vm.mapVm.mapProvider === 'google' && <GoogleMap
          vm={vm.googleMapVm}
          onDragStart={vm.printMapVm.toggleHideElements}
          onDragEnd={vm.printMapVm.toggleHideElements}
          selectedDistrict={vm.selectedDistrict}
          kmlImport={vm.districtsVm.kmlImport}
          districts={vm.districtFilterVm.districtsToRender}
          onDistrictLabelClick={vm.districtsVm.setSelectedDistrict}
          entries={vm.districtFilterVm.entries}
          pois={vm.districtFilterVm.pois}
          currentMapPath={vm.mapPathsVm.currentMapPath}
          mapPaths={vm.districtFilterVm.mapPaths}
          subzones={vm.districtFilterVm.subzones}
          onEntryClick={vm.openEntryDetails}
          onPoiClick={vm.openPoiDetails}
          onMapPathClick={vm.openMapPathDetails}
          onSubzoneClick={vm.openSubzoneDetails}
          onMapClick={vm.handleMapClick}
          onDistrictLineClick={vm.handleDistrictLineClick}
          draggableCursor={vm.draggableCursor}
          highlightedId={vm.highlightedId}
          showMapPathsTooltip={vm.mapPathsVm.lastTab === MapPathTabType.PATH}
          subzoneTmpCircle={vm.subzoneVm.subzoneTmpCircleLocation}
          setSubzoneTmpCircle={vm.subzoneVm.setSubzoneTmpCircle}
          mapType={vm.mapVm.mapType}
          showPoiLabels={vm.showPoiLabels}
          isPrintMapActive={vm.printMapVm.isPrintMapActive}
        >
          <MapOverlay vm={vm} />
        </GoogleMap>}
        {(vm.mapVm.styleUrl && vm.mapVm.mapProvider === 'mapbox') && <MapboxMap
          vm={vm.mapboxMapVm}
          district={vm.selectedDistrict}
          onMapClick={vm.handleMapClick}
          cursor={vm.draggableCursor}
          styleUrl={vm.mapVm.styleUrl}
        >
          <MapOverlay vm={vm} />
        </MapboxMap>}
        <DistrictSelectorDialog
          ref={vm.districtSelectorDialogRef}
          onSelect={vm.districtsVm.setSelectedDistrict}
          onClose={vm.closeSidebars}
        />
      </Box>
      {!vm.session.isProUser && <ProBlockerModal />}
    </React.Fragment>
  );
});

