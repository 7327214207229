import { Box, Typography } from '@mui/material';

interface IProps {
  icon: string;
  title: string;
  color: string;
  active: boolean;
  onClick: () => void;
}

export function FilterPinType(props: IProps) {
  return (
    <Box
      display="grid"
      gridAutoRows={'1fr'}
      justifyItems="center"
      alignContent="center"
      borderRadius={'4px'}
      bgcolor={props.color}
      sx={{ cursor: 'pointer' }}
      onClick={props.onClick}
      py={1}
    >
      <img style={{ width: '40px', alignSelf: 'end' }} src={props.icon} />
      <Typography textAlign={'center'} variant="caption" color="white">{props.title}</Typography>
    </Box>
  );
}
