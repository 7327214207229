import { Tooltip, TooltipProps } from '@mui/material';
import React from 'react';

interface IProps extends TooltipProps {
  enabled: boolean;
}

export function OptionalTooltip(props: IProps) {
  const { enabled, ...tooltipProps } = props;

  if (!enabled) {
    return props.children;
  }

  return <Tooltip {...tooltipProps} />;
}
