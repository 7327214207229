import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useVm } from '../../../../domain/hooks/useVm';
import { UserPostForgotPasswordDto } from '../../../../shared/dto';
import { oldDesignTheme } from '../../../../theme/oldDesignTheme';
import { SubmitButton } from '../../../../toolkit/components/SubmitButton';
import { VerifiedIcon } from '../../../../toolkit/icons/VerifiedIcon';
import { EmailStepProps } from './EmailStep';
import { PasswordResetVm } from './PasswordResetVm';

const useStyles = makeStyles({
  bodyPadding: {
    padding: '0px 20px 0px 50px',
    [oldDesignTheme.breakpoints.down('sm')]: {
      padding: '0px 10px 10px 10px',
    }
  },
  btnDimensions: {
    minWidth: '228px',
    height: '48px',
  },
  sendAgainBtn: {
    color: oldDesignTheme.palette.text.tertiary,
    backgroundColor: 'transparent',
    border: '1px solid #0D654E',
    boxShadow: 'none'
  },
});

export const SuccessStep: React.FC<UserPostForgotPasswordDto> = observer(function PasswordResetSucces({ email }: UserPostForgotPasswordDto) {

  // casting to EmailStepProps as tmp solution because PasswordResetVm is reused between SuccessStep and EmailStep components
  const vm = useVm(PasswordResetVm, {} as EmailStepProps);
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    vm.startTimer();
    return () => {
      vm.resetTimer();
    };
  });

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item container direction="column" alignItems="center">
          <Grid item>
            <VerifiedIcon />
          </Grid>
          <Box marginTop={3} className={classes.bodyPadding}>
            <Grid item container spacing={1} direction="column" alignItems="flex-start">
              <Grid item>
                <Typography variant="h6" color="textPrimary">
                  {t('auth:reset_step2_title')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h1" color="textPrimary">
                  {email}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" color="textPrimary">
                  {t('auth:reset_step2_message')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item>
          <SubmitButton
            disabled={vm.seconds > 0}
            submitting={vm.isBusy}
            type="button"
            className={clsx(classes.sendAgainBtn, classes.btnDimensions)}
            onClick={() => {
              vm.resetTimer();
              vm.sendResetPasswordEmail({ email });
            }}
          >
            {vm.seconds > 0
              ? t('auth:reset_step2_tryagain_resend', { seconds: vm.seconds })
              : t('auth:reset_step2_tryagain')}
          </SubmitButton>
        </Grid>
      </Grid>
    </Box >
  );
});
