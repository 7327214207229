import { Box } from '@mui/material';
import * as React from 'react';
import { InfoComponent } from '../../../../../toolkit/components/InfoComponent';

export function ViewSubzoneBorders() {
  return (
    <Box width="100%">
      <InfoComponent
        locizePath="subzone:border_points_help_text"
      />
    </Box>
  );
}
