import { Box, List } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SubzoneModel } from '../../../../../domain/model/SubzoneModel';
import { SubzoneFilter } from '../../../filters/SubzoneFilter';
import { FilterListItem } from '../components/FilterListItem';
import { NoEntitiesToFilter } from '../components/NoEntitiesToFilter';
import { SelectDeselectAll } from '../components/SelectDeselectAll';

interface IProps {
  subzoneFilter: SubzoneFilter;
}

export const FilterSidebarSubzones = observer(function FilterSidebarSubzones(props: IProps) {
  const { t } = useTranslation();

  return (
    <Box mx={3} my={1}>
      <Box display="flex" justifyContent={'flex-end'}>
        <SelectDeselectAll filter={props.subzoneFilter} />
      </Box>
      {props.subzoneFilter.conditions.length > 0 ? <List>
        {props.subzoneFilter.conditions.map((condition) => {
          return (
            <FilterListItem
              key={`subzone-condition-${condition.id}`}
              title={t(`subzone:type_${condition.type}`)}
              iconUrl={SubzoneModel.typeToIcon(condition.type!)}
              onSelectOnlyThis={() => props.subzoneFilter.selectOnlyCondition(condition)}
              checked={condition.active}
              onClick={() => props.subzoneFilter.toggle(condition)}
            />
          );
        })}
      </List> : <NoEntitiesToFilter text={t('filter:no_subzones_to_filter')} />}
    </Box>
  );
});
