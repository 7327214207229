import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import {
  Button, Menu, MenuItem, MenuProps, styled, Typography, useMediaQuery
} from '@mui/material';

import { GroupSubscriptionUserModel } from '../../../../domain/model/GroupSubscriptionUserModel';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { AssignSeatLocationState } from '../../manage-group/components/AssignEmptySeat';
import {
  AssignAreaMembersModal, AssignSeatOptionType
} from '../assign-area-members/assign-area-members-modal/AssignAreaMembersModal';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.surface,
    borderRadius: 6,
    marginTop: 8,
    boxShadow: 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
  },
}));

interface IAddSeatsDropdownProps {
  candidatesForAssignment: GroupSubscriptionUserModel[];
  assignedMembers: Set<GroupSubscriptionUserModel>;
  setAssignedMembers: (assignedMembers: Set<GroupSubscriptionUserModel>) => void;
  canceledSubscriptionEmptySeats?: number;  // use it only on manage subscription, represents unassigned seats on Canceled Subscription
}

export const AddSeatsDropdown = function AddSeatsDropdown({ candidatesForAssignment, assignedMembers, setAssignedMembers, canceledSubscriptionEmptySeats = 0 }: IAddSeatsDropdownProps) {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();
  const location = useLocation();
  const locationState = location.state as AssignSeatLocationState | undefined;
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [assignOption, setAssignOption] = useState<AssignSeatOptionType>('existing_members');

  const handleOpenModal = useCallback((option: AssignSeatOptionType) => {
    setAssignOption(option);
    setModalOpen(true);
    handleCloseMenu();
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (locationState?.assignOption) {
      handleOpenModal(locationState.assignOption);

      // Clear the state after handling it
      navigate('.', { replace: true, state: null });
    }
  }, [handleOpenModal, locationState, navigate]);

  return (
    <React.Fragment>
      <Button
        size={isMobileSize ? 'large' : 'small'}
        variant="contained"
        disableElevation
        onClick={handleClick}
        startIcon={<PersonOutlineOutlinedIcon htmlColor={theme.palette.system?.white} />}
        endIcon={open ? <KeyboardArrowUpIcon htmlColor={theme.palette.system?.white} /> : <KeyboardArrowDownIcon htmlColor={theme.palette.system?.white} />}
        sx={{
          minWidth: '145px',
          backgroundColor: open ? theme.palette.brand?.dark : '',
        }}
      >
        {t('group_subscription:table.button.cta.label')}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        {/* EXISTING MEMBERS */}
        <MenuItem onClick={() => handleOpenModal('existing_members')} disableRipple>
          <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
            {t('group_subscription:table.button.cta.option.existing_member')}
          </Typography>
        </MenuItem>

        {/* EMAIL INVITE */}
        <MenuItem onClick={() => handleOpenModal('email_invite')} disableRipple>
          <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
            {t('group_subscription:table.button.cta.option.member')}
          </Typography>
        </MenuItem>

        {/* <MenuItem onClick={handleClose} disableRipple>
          <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
            {t('group_subscription:table.button.cta.option.empty_seat')}
          </Typography>
        </MenuItem>
        */}
      </StyledMenu>

      <AssignAreaMembersModal
        open={modalOpen}
        onCloseModal={handleCloseModal}
        assignOption={assignOption}
        candidatesForAssignment={candidatesForAssignment}
        assignedMembers={assignedMembers}
        setAssignedMembers={setAssignedMembers}
        canceledSubscriptionEmptySeats={canceledSubscriptionEmptySeats}
      />
    </React.Fragment>
  );
};
