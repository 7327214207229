import { ApiProperty } from '@nestjs/swagger';

import { ROLE_TYPE } from '../enum';
import { UserResponseDto } from './user.response.dto';

export class EntityMemberResponseDto {

  @ApiProperty({ type: UserResponseDto })
  public user: UserResponseDto;

  @ApiProperty({ type: 'string' })
  public role: ROLE_TYPE;
}
