import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';

import { Assets } from '../../../../../../assets';
import { useInstance } from '../../../../../../domain/hooks/useInstance';
import { BookingModel } from '../../../../../../domain/model/BookingModel';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { FEATURE } from '../../../../../../shared/enum';
import { palette } from '../../../../../../theme/options/palette/palette';
import { InfoComponent } from '../../../../../../toolkit/components/InfoComponent';
import { ProCtaButton } from '../../../../../../toolkit/components/ProCtaButton';

interface IProps {
  booking: BookingModel | undefined;
}

export const ViewPoiBooking = observer(function ViewPoiBooking(props: IProps) {
  const { t } = useTranslation();
  const session = useInstance(SessionStore);
  const bookingsEnabled = session.hasFeatureEnabled(FEATURE.BOOKINGS);

  return (
    <Box bgcolor={bookingsEnabled ? palette.background?.info : 'white'} width="100%">
      {
        bookingsEnabled ? <>
          <InfoComponent
            locizePath="booking:booking_readonly"
          />
          {props.booking && <Box display="flex" paddingX={4} paddingY={2} flexDirection="row" alignItems="center" boxShadow="inset 0px 3px 6px #00000029">
            <Avatar size="40px" round src={props.booking.owner.photo?.thumbnailUrl ?? undefined} />
            <Box marginLeft={2}>
              <Typography variant="h6">{props.booking.owner.fullName}</Typography>
              <Typography variant="body2">{t('booking:booked_till')} {format(props.booking.bookedUntil, 'PP p')}</Typography>
            </Box>
          </Box>
          }
        </>
          : <>
            <InfoComponent
              locizePath="poi:pro_only_feature"
            />
            <Box display="flex" flexDirection={'column'} alignItems="center">
              <img src={Assets.poi.pro.bookings} style={{ width: '100%', marginTop: '30px' }} />
              <ProCtaButton />
            </Box>
          </>}
    </Box >
  );
});
