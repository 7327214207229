import React from 'react';

import { Box, Grid, IconButton, Snackbar, SxProps, Theme, Typography } from '@mui/material';

import CloseIcon from '../../../../assets/img/icon-navigation-close_24px.svg';
import { colors } from '../../../../theme/options/palette/const/colors';

interface IProps {
  open: boolean;
  onClose: () => void;
  icon?: React.ReactNode;
  title?: string;
  description?: string;
  content?: React.ReactNode;
  sx?: SxProps<Theme>;
}

export function MapSnackbar(props: IProps) {
  return (
    <Snackbar
      open={props.open}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      sx={{
        maxWidth: '280px',
        borderRadius: '4px',
        backgroundColor: colors.paper,
        marginRight: .5,
        marginBottom: .5,
        ...props.sx,
      }}
    >
      <Box>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={props.onClose}
            >
              <img src={CloseIcon} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container
          display="flex"
          flexDirection="column"
          alignItems="center"
          spacing={1}
          paddingX={3}
          marginBottom={3}
          textAlign="center"
        >
          {props.icon && <Grid item>
            {props.icon}
          </Grid>}
          {props.title && <Grid item>
            <Typography sx={{ color: '#000000DE', fontSize: '16px', fontWeight: 'normal' }}>
              {props.title}
            </Typography>
          </Grid>}
          {props.description && <Grid item>
            {props.description && <Typography sx={{ color: '#00000099', fontSize: '14px', fontWeight: 'normal' }}>
              {props.description}
            </Typography>}
          </Grid>}
          {props.content && <Grid item>
            {props.content}
          </Grid>}
        </Grid>
      </Box>
    </Snackbar>
  );
}
