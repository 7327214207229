import { Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Assets } from '../../../../../../../assets';
import { useVm } from '../../../../../../../domain/hooks/useVm';
import { colors } from '../../../../../../../theme/options/palette/const/colors';
import { ProCtaButton } from '../../../../../../../toolkit/components/ProCtaButton';
import { DistrictZoneListItem } from './DistrictZoneListItem';
import { IViewDistrictZonesProps, ViewDistrictZonesVm } from './ViewDistrictZonesVm';

export const ViewDistrictZones = observer(function ViewDistrictZones(props: IViewDistrictZonesProps) {
  const vm = useVm(ViewDistrictZonesVm, props);
  const { t } = useTranslation();

  return (
    <Box width="100%">
      {vm.hasProSubzones && <Box sx={{ maxWidth: '100%', boxShadow: 1, }}>
        <Tabs
          value={vm.activeTab}
          indicatorColor="secondary"
          onChange={vm.changeTab}
          variant={vm.tabs.length > 2 ? 'scrollable' : 'fullWidth'}
          scrollButtons
          sx={{
            '.MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0.3,
            }
          }}
        >
          {vm.tabs.map((t) => <Tab
            key={`subzone-tab-${t.key}`}
            value={t.key}
            label={`${t.text} (${t.count})`}
          />)}
        </Tabs>
      </Box>}
      <Box paddingX={2}>
        {vm.hasProSubzones && vm.subzones.map((subzone, index) => <DistrictZoneListItem
          key={`district-subzone-${subzone.id}`}
          subzone={subzone}
          lastItem={index >= (vm.subzones.length - 1)}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
        />)}
        {!vm.hasProSubzones && <Box display="flex" flexDirection={'column'} alignItems="center">
          <img src={Assets.subzone.pro_teaser} style={{ width: '100%', marginTop: '20px' }} />
          <Typography color={colors.primary} mb={5} variant="h6" textAlign={'center'}>{t('subzone:pro_subzones_cta_text')}</Typography>
          <ProCtaButton />
        </Box>}
      </Box>
    </Box>
  );
});
