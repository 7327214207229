import { Pair } from './utility.types';

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE'
}

export const GenderValues = Object.freeze(Object.keys(Gender).sort().map((key) => {
    const k = key as keyof typeof Gender;
    return Gender[k];
}));

export const GenderEntries: ReadonlyArray<Pair<keyof typeof Gender, string>> = Object.freeze(Object.keys(Gender).sort().map((key) => {
    const k = key as keyof typeof Gender;
    return Object.freeze({
        key: k,
        value: Gender[k]
    });
}));
