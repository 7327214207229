import makeStyles from '@mui/styles/makeStyles';

import { oldDesignTheme } from '../../../../../theme/oldDesignTheme';
import { colors } from '../../../../../theme/options/palette/const/colors';

const registrationUseStyles = makeStyles({
  root: {
    '& .MuiFilledInput-root': {
      backgroundColor: colors.paper,
    },
    '& .MuiInputBase-input': {
      color: oldDesignTheme.palette.text.secondary,
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },
  privacyField: {
    [oldDesignTheme.breakpoints.down('sm')]: {
      marginLeft: '20px',
    },
    color: '#2a263e',
    fontSize: '13px',
    fontWeight: 'bold',
    display: 'inline-block',
  },
  doneButton: {
    minWidth: '204px',
    minHeight: '36px',
  },
  checkboxPadding: {
    paddingLeft: '0px',
  },
});

export { registrationUseStyles };
