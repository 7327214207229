import { IsAlphanumeric, IsNotEmpty, Length } from 'class-validator';

import { ApiProperty } from '@nestjs/swagger';

export class RedeemInvitationPostRequestDto {

  @IsNotEmpty()
  @Length(6, 6)
  @IsAlphanumeric()
  @ApiProperty()
  code: string;
}
