import { QuotaPlanModel } from '../domain/model/QuotaPlanModel';

export const sortQuotaPlansByDatesDescending = (quotaPlans: QuotaPlanModel[]): QuotaPlanModel[] => {
  return quotaPlans.sort((a, b) => {
    const startDateComparison = b.startDate.getTime() - a.startDate.getTime();
    if (startDateComparison !== 0) {
      return startDateComparison;
    }
    return b.endDate.getTime() - a.endDate.getTime();
  });
};
