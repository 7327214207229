import { observer } from 'mobx-react-lite';
import React from 'react';

import { useVm } from '../../../domain/hooks/useVm';
import { ForgotPasswordVm } from './ForgotPasswordVm';
import { EmailStep } from './password-reset/EmailStep';
import { SuccessStep } from './password-reset/SuccessStep';
import { PasswordResetScreens } from './password-reset/PasswordResetVm';

export const ForgotPassword = observer(function ForgotPassword() {
  const vm = useVm(ForgotPasswordVm);

  return (
    <div>
      {vm.step === PasswordResetScreens.Form && <EmailStep onEmail={vm.setEmail} />}

      {vm.step === PasswordResetScreens.Success && <SuccessStep email={vm.email} />}
    </div>
  );
});
