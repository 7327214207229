import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, IconButton, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Assets } from '../../../../assets';
import { MapSnackbar } from './MapSnackbar';

interface ITutorialItem {
  image: string;
  title: string;
  description: string;
}

export function MapPathTutorialSnackbar() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [currentIndex, setIndex] = useState(0);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const steps: ITutorialItem[] = useMemo(() => {
    return [{
      image: Assets.path.tutorial.step_1,
      title: t('paths:how_to_place_new_points_title'),
      description: t('paths:how_to_place_new_points_description'),
    }, {
      image: Assets.path.tutorial.step_2,
      title: t('paths:how_to_delete_point_title'),
      description: t('paths:how_to_delete_point_description'),
    }, {
      image: Assets.path.tutorial.step_3,
      title: t('paths:how_to_edit_point_title'),
      description: t('paths:how_to_edit_point_description'),
    }, {
      image: Assets.path.tutorial.step_4,
      title: t('paths:how_to_move_point_title'),
      description: t('paths:how_to_move_point_description'),
    }];
  }, [t]);

  const next = useCallback(() => {
    let nextIndex = currentIndex + 1;
    if (nextIndex > steps.length - 1) {
      nextIndex = 0;
    }

    setIndex(nextIndex);
  }, [setIndex, steps, currentIndex]);

  const prev = useCallback(() => {
    let nextIndex = currentIndex - 1;
    if (nextIndex < 0) {
      nextIndex = steps.length - 1;
    }

    setIndex(nextIndex);
  }, [setIndex, steps, currentIndex]);

  const currentStep = steps[currentIndex];

  return <MapSnackbar
    open={open}
    sx={{ width: '300px', maxWidth: '300px' }}
    content={<Box display="flex" flexDirection={'column'} justifyContent={'space-between'} sx={{ minHeight: '470px' }}>
      <Box>
        <Box display={'flex'} minHeight={'60px'} justifyContent="center" alignItems="center">
          <Typography textAlign={'center'} fontWeight={'500'}>{currentStep.title}</Typography>
        </Box>
        <Box mb={1}>
          <img style={{ width: '100%', height: '180px' }} src={currentStep.image} />
        </Box>
        <Typography textAlign={'center'} mb={1}>{currentStep.description}</Typography>
      </Box>
      <Box>
        <IconButton onClick={prev}>
          <ChevronLeftIcon />
        </IconButton>
        {steps.map((_, index) => <IconButton onClick={() => setIndex(index)} key={`step-${index}`} size="small">
          <Box
            bgcolor={currentIndex === index ? 'secondary.main' : 'white'}
            width={'10px'}
            height={'10px'}
            borderRadius={'50%'}
            border={1}
          />
        </IconButton>)}
        <IconButton onClick={next}>
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>}
    onClose={onClose}
  />;
}
