export const redesignColors = {
  grey: {
    50: '#F5F7F6',
    100: '#E6EDEB',
    200: '#C4CBC9',
    300: '#9EAAA7',
    400: '#7A8784',
    500: '#5F6D6A',   // main
    600: '#4B5653',
    700: '#434C4A',
    800: '#343B39',
    900: '#2E3332',
    950: '#181B1A',
  },
  brand: {
    50: '#EBFEF6',
    100: '#CFFCE7',
    200: '#A4F6D4',
    300: '#69ECBE',
    400: '#2DDAA3',
    500: '#08C18D',
    600: '#009D73',
    700: '#007D5F',
    800: '#02654E',   // main
    900: '#035141',
    950: '#002E25',
  },
  cta: {
    50: '#FFF8EB',
    100: '#FFEBC6',
    200: '#FFD388',
    300: '#FFB648',   // main
    400: '#FF9C20',
    500: '#F97707',
    600: '#DD5302',
    700: '#B73606',
    800: '#94290C',
    900: '#7A230D',
    950: '#460F02',
  },
  error: {
    50: '#FFEFEF',
    100: '#FFDCDC',
    200: '#FFBFBF',
    300: '#FF9292',
    400: '#FF5454',
    500: '#FF1F1F',
    600: '#FF0000',
    700: '#DB0000',
    800: '#B50000',   // main
    900: '#940808',
    950: '#520000',
  },
  system: {
    white: '#FFFFFF',
    black: '#000000'
  },
  marketingOrange: {
    100: '#FFEDE5',
    200: '#FFCAAE',
    300: '#FF9300',
    400: '#C97300',
    500: '#955400',   // main
    600: '#653700',
    700: '#391C00'
  },
  pin: {
    sighting: 'linear-gradient(180deg, #02654E 0%, #02513F 100%)',
    harvest: 'linear-gradient(180deg, #E6670A 0%, #FDB95B 100%)',
    misc: 'linear-gradient(180deg, #798CA4 0%, #4D6585 100%)',
    highseat: 'linear-gradient(180deg, #4979E9 0%, #3867D6 100%)',
    weather: 'linear-gradient(0deg, #00619D 0%, #002B58 100%)',
    camera: 'linear-gradient(180deg, #A05CE6 0%, #8954D1 100%)',
    feeding: 'linear-gradient(180deg, #23D77C 0%, #20C26C 100%)',
    trap: 'linear-gradient(180deg, #EF795D 0%, #DD7562 100%)',
    nest: 'linear-gradient(180deg, #12939A 0%, #0F7D8B 100%)',
  },
  misc: {
    border_selected: '#1AF09A',
    border_drawing: '#F08C1A',
  }
};
