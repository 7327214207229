import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { TabContext, TabList } from '@mui/lab';
import { Grid, Tab, Typography } from '@mui/material';

import { colors } from '../../../../theme/options/palette/const/colors';
import { PrintMapVm } from '../PrintMapVm';
import { PinSizeContext } from '../../PinSizeContext';

export interface PaperSizeTabsProps {
  vm: PrintMapVm;
}

export enum PaperSizeEnum {
  A4 = 'a4',
  A3 = 'a3',
  A2 = 'a2',
  A1 = 'a1',
  A0 = 'a0',
}

export const PaperSizeTabs = observer(function PaperSizeTabs({ vm }: PaperSizeTabsProps) {

  const { t } = useTranslation();
  const pinSizeContext = useContext(PinSizeContext);

  const handleChange = useCallback((_event: React.SyntheticEvent, newValue: PaperSizeEnum) => {
    vm.setPaperSize(newValue);
    pinSizeContext.setPaperSize(newValue);
  }, [pinSizeContext, vm]);

  return (
    <Grid item container rowGap={1}>
      <Grid item xs={12}>
        <Typography variant='h4' color={colors.darkFont}>{t('print_preview:modal.section_one.title')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <TabContext value={vm.paperSize}>
          <TabList onChange={handleChange}
            TabIndicatorProps={{
              hidden: true,
            }}
            sx={{
              '& div.MuiTabs-scroller': {
                borderRadius: 4,
                border: `1px solid ${colors.primary}`
              },
              display: 'flex',
              flexGrow: 1,
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            {Object.entries(PaperSizeEnum).map(([key, value]) => (
              <Tab key={key} label={key} value={value}
                sx={{
                  '&.Mui-selected': {
                    color: colors.paper,
                    bgcolor: colors.green.hunterGreen,
                  },
                  flexGrow: 1,
                  flexBasis: 0,
                  minWidth: 0,
                }}
              />
            ))}
          </TabList>
        </TabContext>
      </Grid>
    </Grid>
  );
});
