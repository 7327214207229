import React, { createContext, useState } from 'react';
import { SCREENSHOT_ORIENTATION_ENUM } from '../../shared/enum/screenshot.orientation.enum';
import { PaperSizeEnum } from './print-map/components/PaperSizeTabs';

type PinSizeContextType = {
  zoomLevel: number | null;
  setZoomLevel: React.Dispatch<React.SetStateAction<number | null>>;
  paperSize: PaperSizeEnum;
  setPaperSize: React.Dispatch<React.SetStateAction<PaperSizeEnum>>;
  paperOrientation: SCREENSHOT_ORIENTATION_ENUM;
  setPaperOrientation: React.Dispatch<React.SetStateAction<SCREENSHOT_ORIENTATION_ENUM>>;
  isPrintMapOverlayActive: boolean;
  setIsPrintMapOverlayActive: React.Dispatch<React.SetStateAction<boolean>>;
}

type PinSizeContextProviderProps = {
  children: React.ReactNode;
}

export const PinSizeContext = createContext({} as PinSizeContextType);

export const PinSizeContextProvider = ({ children }: PinSizeContextProviderProps) => {
  const [zoomLevel, setZoomLevel] = useState<number | null>(null);
  const [isPrintMapOverlayActive, setIsPrintMapOverlayActive] = useState<boolean>(false);
  const [paperSize, setPaperSize] = useState<PaperSizeEnum>(PaperSizeEnum.A4);
  const [paperOrientation, setPaperOrientation] = useState<SCREENSHOT_ORIENTATION_ENUM>(SCREENSHOT_ORIENTATION_ENUM.PORTRAIT);

  return (
    <PinSizeContext.Provider value={{ zoomLevel, setZoomLevel, paperSize, setPaperSize, paperOrientation, setPaperOrientation, isPrintMapOverlayActive, setIsPrintMapOverlayActive }}>
      {children}
    </PinSizeContext.Provider>
  );
};
