import { observable } from 'mobx';
import { CheckInPoiCountResponseDto } from '../../shared/dto/poi/checkinPoiCount.response.dto';

export class PoiCheckinCountModel {

  @observable
  public count: number = 0;

  public static fromDto = (dto: CheckInPoiCountResponseDto) => {
    const model = new PoiCheckinCountModel();

    model.count = dto.count;

    return model;
  }

}
