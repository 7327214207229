import { inject } from 'inversify';

import { Assets } from '../assets';
import { PoiModel } from '../domain/model/PoiModel';
import { UserModel } from '../domain/model/UserModel';
import { I18nService } from '../domain/service/I18nService';
import { SessionStore } from '../domain/store/SessionStore';
import { singleton } from '../inversify/decorator';
import { FEATURE, POI_TYPE } from '../shared/enum';
import { IPoiUIModel, poiUIModels } from './PoiUIModels';

export interface IAssigneeListItem {
  isPro: boolean;
  id: string;
  name: string;
  icon: number;
  disabled: boolean;
}

@singleton()
export class PoiHelper {

  constructor(
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
  }

  public getPoiUIModelForType(poiType: POI_TYPE): IPoiUIModel {
    let poiUIModel = poiUIModels[poiType];
    if (poiUIModel == null) {
      poiUIModel = {
        type: poiType,
        i18nKey: 'other',
        color: 'grey',
        icon: Assets.poi.fallback,
        frameIcon: Assets.poi.fallback,
      };
    }
    return poiUIModel;
  }

  public getTypeText = (poi: PoiModel) => {
    if (!poi.type) {
      return;
    }

    if (poi.type === POI_TYPE.CUSTOM && poi.customMark && poi.customMark.name) {
      return poi.customMark.name;
    }

    return this.i18n.t(this.getPoiUIModelForType(poi.type).i18nKey);
  }

  public getBackgroundColor = (poi: PoiModel): string | undefined => {
    const isCustomPoi = poi.type === POI_TYPE.CUSTOM;
    const hasCustomMarksEnabled = this.session.hasFeatureEnabled(FEATURE.CUSTOM_MARKS);

    if (!poi.type) {
      return;
    }

    if (!hasCustomMarksEnabled && isCustomPoi) {
      return '#EA771A';
    }

    if (isCustomPoi && poi.customMark) {
      return poi.customMark.background ?? undefined;
    }

    return this.getPoiUIModelForType(poi.type).color;
  }

  public getPoiUIModelCustomTranslation(poi: PoiModel) {
    if (poi.customMark?.foreground) {
      return `poi:custom_poi_section.labels.${poi.customMark?.foreground?.toLowerCase()}`;
    }

    return this.getPoiUIModelForType(poi.type!).i18nKey;
  }

  public toAssigneeListItem = (user: UserModel): IAssigneeListItem => {
    return {
      id: user.id,
      name: user.fullName,
      isPro: user.isPro || false,
      disabled: !user.isPro,
      icon: user.isPro && Assets.shield,
    };
  };

  public truncateString = (name: string, maxLength: number) => name.length > maxLength ? name.slice(0, maxLength > 3 ? maxLength - 3 : maxLength) + '...' : name;

}
