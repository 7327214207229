import React, { SyntheticEvent, useCallback } from 'react';

import { Box } from '@mui/system';

import { IGoogleMapProps } from '../../../domain/core/IGoogleMapProps';
import { EmptyMarker } from './EmptyMarker';
import { usePinSizeContext } from './google-map/hooks/usePinSizeContext';

interface IProps extends IGoogleMapProps {
  icon: string | undefined;
  children?: React.ReactNode;
  color?: string;
  opacity?: number;
  onClick?: () => void;
  skiptransform?: boolean;
  width?: number;
  zIndex?: number;
}

/**
 * extract just skiptransform from props -> fix for:
      * Warning: Received `true` for a non-boolean attribute `skiptransform`.
      * If you want to write it to the DOM, pass a string instead: skiptransform="true" or skiptransform={value.toString()}.
*/
export const Marker = React.forwardRef(function Marker({ skiptransform, ...props }: IProps, ref) {

  const onClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();
    props.onClick?.();
  }, [props]);

  const { pinWidth } = usePinSizeContext();

  return (
    <Box
      ref={ref}
      {...props}
      onClick={onClick}
      sx={{
        position: 'relative',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        // reference: https://github.com/google-map-react/google-map-react/issues/956
        transform: skiptransform ? undefined : 'translate(-50%, -100%)',
        zIndex: props.zIndex,
      }}>
      {props.color
        ? <EmptyMarker color={props.color} opacity={props.opacity} width={props.width ?? pinWidth} zIndex={props.zIndex} />
        : <img style={{
          width: props.width ? `${props.width}px` : pinWidth,
          opacity: props.opacity,
          zIndex: props.zIndex,
        } as React.CSSProperties} src={props.icon}
        />}
      {props.children}
    </Box>
  );
});
