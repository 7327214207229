import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { NavigateFunction } from 'react-router';

import { ViewModel } from '../../../../../domain/core/ViewModel';
import { AuthProxy } from '../../../../../domain/proxy/AuthProxy';
import { I18nService } from '../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import {
  appendToppleGoalTagRegisterConversionPixel
} from '../../../../../domain/service/tracking/ToppleTrackingUtil';
import { TrackingEvent } from '../../../../../domain/service/tracking/TrackingEvent';
import { RegistrationVia } from '../../../../../domain/service/tracking/TrackingEventParams';
import { TrackingService } from '../../../../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { transient } from '../../../../../inversify/decorator';
import { Types } from '../../../../../inversify/types';
import { AppRoutes } from '../../../../../router/AppRoutesEnum';
import { UserPostRegisterRequestDto } from '../../../../../shared/dto';

export type RegistrationForm = UserPostRegisterRequestDto & {
  privacyAccepted: boolean;
};

@transient()
export class EmailRegistrationRouteVm extends ViewModel {

  @observable
  public comingFrom: string = AppRoutes.Map;

  constructor(
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(AuthProxy) private readonly authProxy: AuthProxy,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(Types.Navigate) public readonly navigate: NavigateFunction
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setComingFrom = (comingFromLocation: string) => {
    this.comingFrom = comingFromLocation;
  }

  public normalRegister = async (data: RegistrationForm) => {
    try {
      const registerBody: UserPostRegisterRequestDto = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        language: this.i18n.language,
      };

      const result = await this.session.normalRegister(registerBody);
      if (result.ok) {
        await this.tracking.track(TrackingEvent.REGISTRATION_COMPLETED, { registrationType: RegistrationVia.EMAIL });
        appendToppleGoalTagRegisterConversionPixel();

        this.notification.success(this.i18n.t('auth:welcome_message'));
        return this.navigate({ pathname: this.comingFrom });
      }

      if (result.status === 409) {
        return this.notification.error(this.i18n.t('auth:email_already_registered'));
      }

      this.notification.warning(this.i18n.t('auth:register_email_form_warning'));
    } catch (e) {
      console.error(`exception while doing login. ${e}`);
      this.notification.error(this.i18n.t('auth:register_email_form_error'));
    }
  };

  @computed
  public get isBusy() {
    return this.authProxy.register.isBusy;
  }
}
