import { ApiProperty } from '@nestjs/swagger';
import { AmmunitionCaliberResponseDto } from './ammoCaliber.response.dto';
import { GearManufacturerResponseDto } from './gearManufacturer.response.dto';

export class AmmunitionResponseDto {
  @ApiProperty()
  public id: string;

  @ApiProperty({ type: () => GearManufacturerResponseDto})
  public manufacturer?: GearManufacturerResponseDto;

  @ApiProperty({ type: () => AmmunitionCaliberResponseDto})
  public caliber?: AmmunitionCaliberResponseDto;

  @ApiProperty()
  public name?: string;
}
