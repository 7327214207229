import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Grid, Typography, useTheme } from '@mui/material';

import { Assets } from '../../../assets';
import DeerSvg from '../../../assets/img/deer.svg';
import { StripeProductModel } from '../../../domain/model/stripe/StripeProductModel';
import { AppRoutes } from '../../../router/AppRoutesEnum';
import { colors } from '../../../theme/options/palette/const/colors';
import { currencySymbol } from '../../../util/CurrencyHelper';

interface SubscriptionBoxProps {
  product: StripeProductModel;
}

export function GroupSubscriptionBox(props: SubscriptionBoxProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const cheapestVersion = useMemo(() => props.product.price.tiers.slice(-1)[0], [props.product.price.tiers]);
  const formattedValue = useMemo(
    () => `${cheapestVersion.unitAmount?.toFixed(2)}${currencySymbol(props.product.price?.currency)}`,
    [cheapestVersion.unitAmount, props.product.price?.currency]
  );

  return (
    <Grid item container margin="1%"
      sx={{
        background: `url(${DeerSvg}), linear-gradient(180deg, #02654E 0%, #02513F 100%)`,
        backgroundRepeat: 'no-repeat',
        boxShadow: '0px 6px 12px #00000029',
        borderRadius: '20px',
        width: '300px',
        '&:hover': {
          boxShadow: '0 0 0 3pt #F59F20',
        }
      }}
    >
      <Grid item container direction="column" alignItems="center">
        <Grid item container justifyContent="center" alignItems="center" minHeight={110}>
          <Grid item>
            <img src={Assets.multi_users_group_discount} />
          </Grid>
        </Grid>
        <Grid item container direction="column" alignItems="center" rowGap={1} minHeight={140}>
          <Grid item>
            <Typography variant="subtitle1" color="rgba(255, 255, 255, 0.8)">{t('payment:multi_user')}</Typography>
          </Grid>
          <Grid item>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minWidth="130px"
              height="44px"
              borderRadius="22px"
              sx={{ background: colors.paper }}
              py={1}
              px={3}
            >
              <Typography variant="h2" color={theme.palette.text.tertiary}>
                {`${t('payment:price_text')} ${formattedValue}`}
              </Typography>
            </Box>
          </Grid>
          <Grid item textAlign="center">
            <Typography variant="subtitle1" color="rgba(255, 255, 255, 0.8)">
              {t('payment:per_user')}
            </Typography>
            <Typography variant="subtitle1" color="rgba(255, 255, 255, 0.8)">
              {t('payment:subscription_yearly')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container direction="column" alignItems="center" justifyContent="flex-end" p={2}>
          <Grid item>
            <Button sx={{
              backgroundColor: colors.orange[400],
              color: colors.paper,
              width: '269px',
              height: '60px',
              borderRadius: '9px',
              '&:hover': {
                backgroundColor: colors.orange[500],
              }
            }}
              onClick={() => navigate(AppRoutes.GroupSubscription)}
            >{t('payment:button.pro_version_group')}</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
