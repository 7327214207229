import { inject } from 'inversify';
import { makeObservable, observable, runInAction } from 'mobx';

import { AsyncTask } from '../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../domain/core/ViewModel';
import { MapboxProxy } from '../../../../domain/proxy/MapboxProxy';
import { SessionStore } from '../../../../domain/store/SessionStore';
import { transient } from '../../../../inversify/decorator';
import { IGeoLocation } from '../../../../shared/interfaces/IGeoLocation';

export interface ISearchResult {
  label: string;
  location: IGeoLocation;
}

@transient()
export class LocationSearchVm extends ViewModel {

  @observable
  public results: ISearchResult[] = [];

  constructor(
    @inject(MapboxProxy) private readonly mapboxProxy: MapboxProxy,
    @inject(SessionStore) private readonly session: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  public search = new AsyncTask(async (term: string) => {
    const result = await this.mapboxProxy.locationSearch(term, this.session.session?.user.language);

    runInAction(() => {
      this.results = result.features.map((f) => ({
        label: f.place_name,
        location: { latitude: f.center[1], longitude: f.center[0] },
      }));
    });
  });
}
