import { IsString, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';
import { InviteGroupRequestDto } from './invite.group.request.dto';

export class GroupPostRequestDto {
  @IsString({ context: { errorCode: 422 } })
  public name: string;

  @ValidateNested({ each: true, context: { errorCode: 422 } })
  @Type(() => InviteGroupRequestDto)
  public users: InviteGroupRequestDto[];
}
