import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import BulbIcon from '../../assets/img/icon-light_on.svg';
import { palette } from '../../theme/options/palette/palette';

interface InfoComponentProps {
  locizePath: string;
}

export function InfoComponent({ locizePath }: InfoComponentProps) {
  const { t } = useTranslation();

  return (
    <Box bgcolor={palette.background?.info} width='inherit' padding={2} boxShadow="inset 0px 3px 6px #00000029">
      <Grid container justifyContent="space-around" alignItems="center">
        <Grid item>
          <img src={BulbIcon} />
        </Grid>
        <Grid item xs={9}>
          <Typography fontSize={13} color={palette.text?.info}>
            {t(locizePath)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
