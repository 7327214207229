import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { InformationIcon } from '@HunterCompanion/hunter-design/icons';
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';

import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { SvgWrapper } from '../../../../toolkit/components/SvgWrapper';

interface IMemberPriceProps {
  firstYearForFree: boolean;
  priceForEveryNextUser: number;
  currency: string;
  assignToEmptySeat: boolean;
  proratedPricePerUser?: number | undefined;
}

export const MemberPrice: React.FC<IMemberPriceProps> = React.memo(function MemberPrice({
  firstYearForFree,
  priceForEveryNextUser,
  currency,
  assignToEmptySeat,
  proratedPricePerUser = 0
}: IMemberPriceProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));

  const discountAfterProration = useMemo(() => priceForEveryNextUser - proratedPricePerUser, [priceForEveryNextUser, proratedPricePerUser]);

  if (assignToEmptySeat) {
    return (
      <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'}>
        <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary} sx={{ textDecoration: 'line-through' }}>
          {priceForEveryNextUser.toFixed(2)}{currency}
        </Typography>&nbsp;
        <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.brand?.brand_color}>
          {t('group_subscription:table.table_row.price.assigned_to_unused_seat')}
        </Typography>&nbsp;
      </Box>
    );
  }

  return firstYearForFree ? (
    <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'}>
      <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary} sx={{ textDecoration: 'line-through' }}>
        {priceForEveryNextUser.toFixed(2)}{currency}
      </Typography>&nbsp;
      <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.brand?.brand_color}>
        {t('group_subscription:table.table_row.price.free')}
      </Typography>&nbsp;
      <Tooltip title={t('group_subscription:table.table_row.price.tooltip_discount.pro_member', { additionalMemberPrice: `${currency}${priceForEveryNextUser}` })} placement='top'>
        <span>
          <SvgWrapper icon={InformationIcon} fill={theme.palette.brand?.brand_color} width={16} height={16} />
        </span>
      </Tooltip>
    </Box>
  ) : (
    proratedPricePerUser ?
      <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'}>
        <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary} sx={{ textDecoration: 'line-through' }}>
          {priceForEveryNextUser.toFixed(2)}{currency}
        </Typography>&nbsp;
        <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.brand?.brand_color}>
          {proratedPricePerUser.toFixed(2)}{currency}
        </Typography>&nbsp;
        <Tooltip title={t('manage_group:table.table_row.price.tooltip_discount.proration_discount', { discount: `${discountAfterProration.toFixed(2)}${currency}` })} placement='top'>
          <span>
            <SvgWrapper icon={InformationIcon} fill={theme.palette.brand?.brand_color} width={16} height={16} />
          </span>
        </Tooltip>
      </Box>
      : <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
        {priceForEveryNextUser.toFixed(2)}{currency}
      </Typography>)
    ;
});
