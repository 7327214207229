import { Observer, observer } from 'mobx-react-lite';
import FacebookLogin, { FacebookLoginButtonRenderProps } from 'react-facebook-login-typed';
import { useTranslation } from 'react-i18next';

import FacebookIcon from '@mui/icons-material/Facebook';
import { SxProps, Theme } from '@mui/material';

import { useVm } from '../../../../domain/hooks/useVm';
import { env } from '../../../../env';
import { colors } from '../../../../theme/options/palette/const/colors';
import { SubmitButton } from '../../../../toolkit/components/SubmitButton';
import { FacebookLoginButtonVm } from './FacebookLoginButtonVm';

export interface IFacebookLoginButtonProps {
  sx?: SxProps<Theme>;
  fromLocation?: string;
}

export const FacebookLoginButton = observer(function FacebookLoginButton(props: IFacebookLoginButtonProps) {

  const vm = useVm(FacebookLoginButtonVm, props);
  const { t } = useTranslation();

  return (
    <FacebookLogin
      appId={env.facebookAppId}
      callback={vm.facebookLogin}
      onFailure={vm.failureFacebookResponse}
      fields="name,email"
      render={(renderProps: FacebookLoginButtonRenderProps) => (
        <Observer>
          {() => <SubmitButton
            submitting={vm.isBusy}
            size="large"
            onClick={renderProps.onClick}
            startIcon={<FacebookIcon />}
            sx={{
              backgroundColor: colors.facebook_btn.basic,
              '&:hover': {
                backgroundColor: colors.facebook_btn.hover,
              },
              ...props.sx,
            }}
          >
            {t('auth:socialmedia_button.facebook')}
          </SubmitButton>}
        </Observer>
      )}
    />
  );
});
