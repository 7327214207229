export const colors = {
  default: '#f0f0f0',
  subscriptionBoxText: 'rgba(255, 255, 255, 0.8)',
  primary: '#535c68',
  secondary: '#0d654e',
  success: '#4bae50',
  warning: '#ef962f',
  error: '#ff0000',
  grapple: '#1FEFAA',
  paper: '#fff',
  darkFont: '#161616',
  google_btn: {
    basic: '#df4930',
    hover: '#ba3d29',
  },
  facebook_btn: {
    basic: '#3b5998',
    hover: '#2f4574',
  },
  apple_btn: {
    basic: '#000',
    hover: '#414141',
  },
  green: {
    900: '#004934',
    hunterGreen: '#02654E',
    800: '#02654E',
    700: '#07755C',
    600: '#0B856B',
    500: '#0E9177',
    400: '#2BA289',
    300: '#4FB39D',
    200: '#80C9B8',
    100: '#B2DED4',
    50: '#DFF1EE',
  },
  orange: {
    900: '#E74E00',
    800: '#EF6A00',
    700: '#F57A00',
    600: '#FB8A00',
    500: '#FF9600',
    400: '#FFA51C',
    300: '#FFB648',
    200: '#FFCB7D',
    100: '#FFE0B0',
    50: '#FFF3DF',
  },
  blueGrey: {
    900: '#263238',
    800: '#37474F',
    700: '#455A64',
    600: '#546E7A',
    500: '#607D8B',
    400: '#78909C',
    300: '#90A4AE',
    200: '#B0BEC5',
    100: '#CFD8DC',
    50: '#ECEFF1',
  },
  yellow: {
    700: '#FFC400',
    800: '#EBB500',
  },
  blue: '#1a53ff'
};
