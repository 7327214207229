import { endOfDay, isAfter, isBefore, startOfDay, sub } from 'date-fns';
import { action, computed, makeObservable, observable } from 'mobx';
import { date, serializable } from 'serializr';
import { DistrictModel } from '../../../domain/model/DistrictModel';
import { EntryModel } from '../../../domain/model/EntryModel';
import { I18nService } from '../../../domain/service/I18nService';
import { AbstractDistrictFilter } from './AbstractDistrictFilter';
import { IEntryFilter } from './IEntryFilter';

export enum DateFilterType {
  LAST_WEEK = 'last_week',
  LAST_MONTH = 'last_month',
  LAST_YEAR = 'last_year',
  CUSTOM = 'custom'
}

export class DateFilterCondition {

  @observable
  public id: string = '';

  @observable
  public text: string | null = null;

  @observable
  public active: boolean = false;

  @observable
  public hits: number = 0;

  @observable
  public from: Date;

  @observable
  public to?: Date;

  @observable
  public type: DateFilterType;

  constructor(i18nKey: string | null, type: DateFilterType, i18nProxy: I18nService, from: Date, to?: Date) {
    this.id = i18nKey != null ? i18nKey : 'custom';
    this.type = type;
    this.text = i18nKey != null ? i18nProxy.t(i18nKey) : null;
    this.from = from;
    this.to = to ? to : undefined;
    this.active = false;
    this.hits = 0;

    makeObservable(this);
  }
}

export class DateFilter extends AbstractDistrictFilter implements IEntryFilter {

  @observable
  @serializable(date())
  public customFrom: Date | null;

  @observable
  @serializable(date())
  public customTo: Date | null;

  constructor(
    public readonly district: DistrictModel | null,
    public readonly entries: EntryModel[] | null,
    public readonly i18nProxy: I18nService,
  ) {
    super((district && district.id) ? `DistrictFilterVm-DateFilter-district-${district!.id!}` : 'DistrictFilterVm-DateFilter', 2);
    this.customFrom = this.customFrom || null;
    this.customTo = this.customTo || null;

    makeObservable(this);
  }

  @computed
  public get conditions() {
    const conditions = [new DateFilterCondition(
      'filter:filter_preset_last_week',
      DateFilterType.LAST_WEEK,
      this.i18nProxy,
      sub(this.now(), { days: 7 }),
    ), new DateFilterCondition(
      'filter:filter_preset_last_month',
      DateFilterType.LAST_MONTH,
      this.i18nProxy,
      sub(this.now(), { months: 1 }),
    ), new DateFilterCondition(
      'filter:filter_preset_last_year',
      DateFilterType.LAST_YEAR,
      this.i18nProxy,
      sub(this.now(), { years: 1 }),
    ), new DateFilterCondition(
      null,
      DateFilterType.CUSTOM,
      this.i18nProxy,
      this.customFrom || new Date(this.defaultStartDate()),
      this.customTo || this.now(),
    )];

    conditions.forEach((c) => this.calculateFilterHits(c));
    return this.loadCache(conditions);
  }

  public now() {
    return endOfDay(new Date());
  }

  public defaultStartDate() {
    const now = this.now();
    const startOfHuntingSeason = new Date(this.now().getFullYear(), 3, 1);

    if (isAfter(startOfHuntingSeason, now)) {
      return sub(startOfHuntingSeason, { years: 1 });
    }

    return startOfHuntingSeason;
  }

  @computed
  public get predefinedConditions(): DateFilterCondition[] {
    return this.conditions.filter((d) => !!d.text);
  }

  @computed
  public get customCondition(): DateFilterCondition | undefined {
    return this.conditions.find((d) => !d.text);
  }

  @action
  public activate = (condition: DateFilterCondition): void => {
    this.conditions.forEach((f) => {
      f.active = f.id === condition.id;
    });

    this.persistState();
  }

  @action
  public calculateFilterHits = (filter: DateFilterCondition) => {
    filter.hits = this.entries!.reduce((hits, entry) => {
      return this.entryInRange(entry, filter.from, filter.to) ? ++hits : hits; // NOSONAR
    }, 0);
  }

  @action
  public filter = (): EntryModel[] => {
    if (!this.entries) {
      return [];
    }

    const activeFilter = this.conditions.find((f) => f.active);
    if (!activeFilter) {
      return this.entries;
    }

    return this.entries.filter((e) => {
      return this.entryInRange(e, activeFilter.from, activeFilter.to);
    });
  }

  public check = (entry: EntryModel): boolean => {
    const activeCondition = this.conditions.find((f) => f.active);

    if (!activeCondition) {
      return true;
    }

    return this.entryInRange(entry, activeCondition.from, activeCondition.to);
  }

  public override deactivate = () => {
    this.customFrom = null;
    this.customTo = null;

    this.conditions.forEach((c) => {
      c.active = false;

      // for custom date condition reset from-to ranges as well
      if (!c.text) {
        c.from = new Date(this.defaultStartDate());
        c.to = this.now();
      }
    });

    this.persistState();
  }

  public override get hasActive(): boolean {
    return this.conditions.some((filter) => filter.active);
  }

  @action
  public onChangeFromDate = (from: Date | null) => {
    if (!from) {
      this.customFrom = from;
    } else {
      this.customFrom = startOfDay(from);
      this.customCondition!.from = this.customFrom;
    }

    this.reverseDatesIfNeeded();
    this.activate(this.customCondition!);
  }

  @action
  public onChangeToDate = (to: Date | null) => {
    if (!to) {
      this.customTo = to;
    } else {
      this.customTo = endOfDay(to);
      this.customCondition!.to = this.customTo;
    }

    this.reverseDatesIfNeeded();
    this.activate(this.customCondition!);
  }

  @action
  private reverseDatesIfNeeded = () => {
    if (this.customCondition!.to && isBefore(this.customCondition!.to, this.customCondition!.from)) {
      const tempStartDate = new Date(this.customCondition!.from);
      this.customCondition!.from = this.customCondition!.to;
      this.customCondition!.to = tempStartDate;
    }
  }

  public entryInRange = (entry: EntryModel, from: Date, to?: Date) => {
    if (!entry.userDate) {
      return false;
    }

    if (from && to) {
      return isAfter(entry.userDate, from) && isBefore(entry.userDate, to);
    }

    return isAfter(entry.userDate, from);
  }
}
