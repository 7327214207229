import { ApiProperty } from '@nestjs/swagger';
import { IsEmail, IsEnum, IsNotEmpty, IsOptional, IsString, MinLength } from 'class-validator';
import { LANGUAGE } from '../enum';



export class UserPostRegisterRequestDto {

  @IsString()
  @IsNotEmpty()
  @ApiProperty()
  public firstName!: string;

  @IsString()
  @IsNotEmpty()
  @ApiProperty()
  public lastName!: string;

  @IsEmail({}, {context: {errorCode: 422}})
  @ApiProperty({ description: 'should be a valid email'})
  public email!: string;

  @IsOptional()
  @IsEnum(LANGUAGE)
  @ApiProperty()
  public language?: LANGUAGE;

  @IsString()
  @MinLength(8, {context: {errorCode: 422}})
  @ApiProperty({ description: 'minimum 8 characters length'})
  public password!: string;
}
