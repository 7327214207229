import { ApiProperty } from '@nestjs/swagger';
import { LOGIN_PROVIDER } from '../enum/loginProvider.enum';
import { STRIPE_SUBSCRIPTION_STATUS } from '../enum/stripe/stripeSubscriptionStatus.enum';
import { SubscriptionResponseDto } from './subscription.response.dto';
import { SubscriptionReceiptResponseDto } from './subscription/subscription.receipt.response.dto';
import { UserResponseDto } from './user.response.dto';


export class UserLoginResponseDto {

  @ApiProperty()
  public sessionToken!: string;

  @ApiProperty({ type: UserResponseDto })
  public user!: UserResponseDto;

  @ApiProperty()
  public shouldWipeCache!: boolean;

  @ApiProperty({ type: SubscriptionResponseDto })
  public subscriptions: SubscriptionResponseDto[];

  @ApiProperty({ type: SubscriptionReceiptResponseDto })
  public receipts: SubscriptionReceiptResponseDto[];

  @ApiProperty()
  public displayOnboarding: boolean;

  @ApiProperty()
  public loginProvider: LOGIN_PROVIDER;

  @ApiProperty()
  public hasRedeemedCode: boolean;

  @ApiProperty({ type: UserResponseDto })
  public groupSubscriptionOwner?: UserResponseDto;

  @ApiProperty()
  public isGroupOwner: boolean;

  @ApiProperty()
  public stripeSubscriptionStatus?: STRIPE_SUBSCRIPTION_STATUS | undefined
}
