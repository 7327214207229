import { ApiProperty } from '@nestjs/swagger';

import { GeoLocationResponseDto } from './geolocation.response.dto';
import { UserResponseDto } from './user.response.dto';

export class MapPathResponseDto {
  @ApiProperty()
  public id: string;

  @ApiProperty()
  public title: string | null;

  @ApiProperty()
  public color: string | null;

  @ApiProperty()
  public typeId: string;

  @ApiProperty()
  public districtId?: string;

  @ApiProperty({ type: () => UserResponseDto })
  public owner: UserResponseDto;

  @ApiProperty()
  public points: GeoLocationResponseDto[];

  @ApiProperty({ description: 'event ID happenning in paths district or worldmap events that overlaps with paths location. Currently only populated in GET api/event/:eventId/paths' })
  public eventId?: string;

  @ApiProperty()
  public gpsTracked: boolean;
}
