import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, InputAdornment, TextField } from '@mui/material';

import { useVm } from '../../../../domain/hooks/useVm';
import { IGeoLocation } from '../../../../shared/interfaces/IGeoLocation';
import { LocationSearchVm } from './LocationSearchVm';

interface IProps {
  onSelect: (location: IGeoLocation) => void;
  isDisabled?: boolean;
}

export const LocationSearch = observer(function LocationSearch(props: IProps) {
  const { t } = useTranslation();
  const vm = useVm(LocationSearchVm);

  return (
    <Box width="100%">
      <Autocomplete
        disabled={props.isDisabled}
        disablePortal
        autoComplete
        filterOptions={(x) => x}
        options={vm.results}
        loading={vm.search.isBusy}
        noOptionsText={t('district:location_search_helper_text')}
        loadingText={t('district:location_search_loading')}
        onChange={(e, value) => value && props.onSelect(value.location)}
        sx={{
          width: 250,
          backgroundColor: 'white',
          borderRadius: '100px',
        }}
        popupIcon={null}
        renderInput={(params) => <TextField
          {...params}
          hiddenLabel
          size="small"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            placeholder: t('district:location_search_placeholder'),
            sx: {
              cursor: props.isDisabled ? 'not-allowed' : 'pointer',
              borderRadius: '100px',
              paddingX: '26px !important',
              'input': {
                paddingLeft: '0px !important'
              },
            },
            style: { cursor: props.isDisabled ? 'not-allowed' : 'pointer' },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => vm.search.run(e.target.value)}
        />}
      />
    </Box>
  );
});
