import React, { useCallback, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Container, Grid, Modal, Typography } from '@mui/material';

import { Assets } from '../../assets';
import { useContainer } from '../../domain/hooks/useContainer';
import { SessionStore } from '../../domain/store/SessionStore';
import { env } from '../../env';
import { AppRoutes } from '../../router/AppRoutesEnum';
import { oldDesignTheme } from '../../theme/oldDesignTheme';
import { colors } from '../../theme/options/palette/const/colors';

export type ProBlockerModalRef = {
  open: () => void,
  close: () => void,
}

export const ProBlockerModal = React.forwardRef<ProBlockerModalRef, React.PropsWithChildren<unknown>>(function ProBlockerModal(props: React.PropsWithChildren<unknown>, ref) {
  const session = useContainer().get(SessionStore);
  const { t } = useTranslation();

  // * Disabled for local development -> use this to display the blocker in other environments
  // const [open, setOpen] = useState(env.isDev && env.isLocalhost ? false : true);

  // * Turn it off for each env except production
  const [open, setOpen] = useState(env.isProduction ? true : false);
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    open() {
      setOpen(true);
    },
    close() {
      setOpen(false);
    }
  }));

  const handleClose = useCallback((event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }
    setOpen(false);
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      disableAutoFocus
      disableEscapeKeyDown
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box position="relative" display="flex" justifyContent="center"
        sx={{
          minHeight: '31.75rem',
          width: '37.5rem',
          bgcolor: colors.default,
          borderRadius: '10px',
          [oldDesignTheme.breakpoints.down('lg')]: {
            marginX: 3
          },
        }}
      >
        <Box position={'absolute'} top={'-13%'} left={'3%'}>
          <img src={Assets.logo} width={140} style={{ position: 'relative' }} />
          <img src={Assets.shield} width={45} style={{ position: 'absolute', top: '28%', right: '3%' }} />
        </Box>

        <Box alignSelf={'flex-start'} position={'absolute'} right={'2%'} top={'2%'}>
          <CloseIcon sx={{ cursor: 'pointer' }} onClick={() => handleClose(event, 'escapeClick')} />
        </Box>

        <Container sx={{
          backgroundColor: colors.default,
          minHeight: '370px',
          height: 'auto',
          marginTop: 7,
          marginBottom: 2,
        }}>
          <Grid container direction={'column'} alignItems={'center'} justifyContent={'space-between'} textAlign={'center'} px={{ xs: 1, md: 3 }} rowGap={2}>
            <Grid item>
              <Typography fontSize={{ xs: 26, sm: 30, md: 34 }} fontWeight={'bold'}>{t('pro_blocker:modal.title')}</Typography>
            </Grid>
            <Grid item>
              <Typography fontSize={{ xs: 22, sm: 24, md: 28 }}>
                {session.allowTrialPeriod ? t('pro_blocker:modal.body_trial', { count: session.currentUser?.trialLength }) : t('pro_blocker:modal.body')}
              </Typography>
            </Grid>
            <Grid item flexGrow={1} />
            <Grid item>
              <Button onClick={() => navigate(AppRoutes.Pricing)} variant="contained" color="secondary" sx={{ position: 'relative', minWidth: 250, height: 48 }}>
                {t('pro_blocker:modal.button.text')}
                <Box sx={{
                  position: 'absolute',
                  top: -15,
                  right: -36,
                  bgcolor: colors.orange[600],
                  borderRadius: 2,
                  minWidth: 76,
                }}>
                  <Typography fontSize={20} fontWeight={'bold'}>
                    {t('pro_blocker:modal.button.version_pro')}
                  </Typography>
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Box position={'absolute'} bottom={'-3%'} mt={2}>
          <img src={Assets.teaser_image} />
        </Box>
      </Box>
    </Modal>
  );
});
