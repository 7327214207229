import { inject } from 'inversify';

import { singleton } from '../../inversify/decorator';
import { AjaxService } from '../service/AjaxService';
import { Proxy } from './Proxy';
import { QuotaPlanResponseDto } from '../../shared/dto/quotaPlan.response.dto';
import { QuotaPlanModel } from '../model/QuotaPlanModel';
import { QuotaPlanPostRequestDto } from '../../shared/dto/quotaPlan.post.request.dto';
import { QuotaPlanPutRequestDto } from '../../shared/dto/quotaPlan.put.request.dto';

@singleton()
export class QuotaPlanProxy extends Proxy {

  constructor(@inject(AjaxService) private readonly ajax: AjaxService) {
    super();
  }

  public getQuotaPlans = async (districtId: string) => {
    const result = await this.ajax.get<QuotaPlanResponseDto[]>(`quota-plan/${districtId}`);
    if (result.ok) {
      return this.withData(result, result.data.map(QuotaPlanModel.fromDto));
    }

    return result;
  };

  public updateQuotaPlan = async (quotaPlan: QuotaPlanPutRequestDto) => {
    const result = await this.ajax.put<QuotaPlanResponseDto>('quota-plan', quotaPlan);
    if (result.ok) {
      return this.withData(result, QuotaPlanModel.fromDto(result.data));
    }

    return result;
  };

  public createQuotaPlan = async (quotaPlan: QuotaPlanPostRequestDto) => {
    const result = await this.ajax.post<QuotaPlanResponseDto>('quota-plan', quotaPlan);
    if (result.ok) {
      return this.withData(result, QuotaPlanModel.fromDto(result.data));
    }

    return result;
  };

  public deleteQuotaPlan = async (quotaPlanId: string) => {
    return this.ajax.delete<void>(`quota-plan/${quotaPlanId}`);
  }

}
