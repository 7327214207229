import { IDiseasesRegionNode } from '../types/classification.types';

export const diseases: { [region: string]: IDiseasesRegionNode } = {
  western_europe: {
    children: [
      {
        species: 'PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'BLACKHEAD_DISEASE',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'GAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GREY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_BOAR',
        diseases: [
          {
            category: 'VIRAL',
            name: 'PSEUDORABIES',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'HEPATITIS_E', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'SWINEFLU', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'AFRICAN_SWINE_FEVER',
            notifyAuthorities: 'YES',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ALARIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'TRICHINOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'FALLOW_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'RED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ONCHOCERCIASIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'CHAMOIS',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'ROE_DEER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'MOOSE',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'REINDEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'BROWN_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_WHITE_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RABBIT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'BIRD', diseases: [] },
      {
        species: 'ANDEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ANDEAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AUK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARNACLE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAR_HEADED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBIRD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRANT_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRAZILIAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUZZARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANADA_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANVASBACK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPERCAILLIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_TURTLEDOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARRION_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHACHALACA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHESTNUT_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHILOE_WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHUKAR_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CINNAMON_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COLLARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_RAVEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COOT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CURLEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EGYPTIAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EMU',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FALCON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FIELDFARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FRANCOLIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GADWALL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GARGANEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_EYE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_PLOVER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOOSANDER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOSHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAYLAG',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_HERON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREAT_GREY_OWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREEN_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GUINEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARDHEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HAZEL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_MERGANSERS',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACKDAW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACK_SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JAY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LAPWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LITTLE_BUSTARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MALLARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MANED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MISTLE_TRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOORHEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOTTLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOURNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUTE_SWAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OSTRICH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PACIFIC_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PARADISE_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PICAZURO_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINK_EARED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LESSER_PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PUFFIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'QUAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACKELHAHN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDSHANK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BILLED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BREASTED_MERGANSER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_CRESTED_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_EYED_MORNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_HEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_LEGGED_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REEVES_PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_NECKED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSS_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSYBILL_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUDDY_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFF',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFFED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDGROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDHILL_CRANE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SCAUP',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SEAGULL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHARPTAIL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SILVER_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKUA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKYLARK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SMEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOWCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_THRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SORA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROWHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRUCE_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPUR_WING_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STARLING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STOCK_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SWAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TEAL_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TUFTED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURKEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURTLE_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VELVET_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_RAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_CHEEKED_PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FACED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FRONTED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WINGED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILLOW_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_PIDGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_BILLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HEN_HARRIER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROOK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODPECKER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'MAMMAL', diseases: [] },
      {
        species: 'AARDWOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_WILD_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARCTIC_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARMADILLO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BABOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BANTENG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAT_EARED_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAVER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BIGHORN_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLESBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BROWN_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHPIG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPYBARA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARACAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAUCASIAN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EASTERN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHINESE_WATER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'CHITAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CIVET_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYPU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DALL_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DAMARA_DIK_DIK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DORMOUSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DUIKER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ELAND',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ERMINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GEMSBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GENET',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_JACKAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRANADA_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREATER_KUDU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRIZZLY_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOG_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HONEY_BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HYENA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IMPALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYOTE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KANGAROO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KLIPSPRINGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LYNX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MARMOT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AMERICAN_MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MONGOOSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUFLON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MOUNTAIN_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUNTAIN_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MULE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUNTJAC',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MUSKOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUSKRAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'NYALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OLD_WORLD_OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORIBI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORYX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PLAINS_ZEBRA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PORCUPINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRONGHORN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON_DOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_TAILED_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROAN_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RUSA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SAMBA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SERVAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIKA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'SKUNK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRINGBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STEENBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STONE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TOLAI_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VERVET_MONKEY',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WAPITI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WARTHOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATERBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WEASEL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDCAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLVERINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_THORATED_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_YAK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_POLECAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: '_ROE_DEER',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITETAIL_DEER',
        diseases: [
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      { species: 'REPTILE', diseases: [] },
      {
        species: 'ALLIGATOR',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CROCODILE',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
    ],
  },
  british_isles: {
    children: [
      {
        species: 'PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'BLACKHEAD_DISEASE',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'GAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GREY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_BOAR',
        diseases: [
          {
            category: 'VIRAL',
            name: 'PSEUDORABIES',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'HEPATITIS_E', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'SWINEFLU', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'AFRICAN_SWINE_FEVER',
            notifyAuthorities: 'YES',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ALARIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'TRICHINOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'FALLOW_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'RED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ONCHOCERCIASIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'CHAMOIS',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'ROE_DEER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'MOOSE',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'REINDEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'BROWN_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_WHITE_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RABBIT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'BIRD', diseases: [] },
      {
        species: 'ANDEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ANDEAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AUK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARNACLE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAR_HEADED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBIRD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRANT_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRAZILIAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUZZARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANADA_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANVASBACK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPERCAILLIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_TURTLEDOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARRION_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHACHALACA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHESTNUT_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHILOE_WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHUKAR_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CINNAMON_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COLLARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_RAVEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COOT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CURLEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EGYPTIAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EMU',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FALCON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FIELDFARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FRANCOLIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GADWALL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GARGANEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_EYE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_PLOVER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOOSANDER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOSHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAYLAG',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_HERON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREAT_GREY_OWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREEN_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GUINEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARDHEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HAZEL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_MERGANSERS',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACKDAW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACK_SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JAY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LAPWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LITTLE_BUSTARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MALLARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MANED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MISTLE_TRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOORHEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOTTLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOURNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUTE_SWAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OSTRICH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PACIFIC_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PARADISE_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PICAZURO_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINK_EARED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LESSER_PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PUFFIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'QUAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACKELHAHN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDSHANK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BILLED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BREASTED_MERGANSER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_CRESTED_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_EYED_MORNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_HEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_LEGGED_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REEVES_PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_NECKED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSS_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSYBILL_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUDDY_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFF',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFFED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDGROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDHILL_CRANE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SCAUP',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SEAGULL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHARPTAIL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SILVER_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKUA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKYLARK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SMEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOWCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_THRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SORA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROWHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRUCE_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPUR_WING_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STARLING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STOCK_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SWAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TEAL_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TUFTED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURKEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURTLE_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VELVET_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_RAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_CHEEKED_PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FACED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FRONTED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WINGED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILLOW_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_PIDGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_BILLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HEN_HARRIER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROOK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODPECKER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'MAMMAL', diseases: [] },
      {
        species: 'AARDWOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_WILD_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARCTIC_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARMADILLO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BABOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BANTENG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAT_EARED_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAVER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BIGHORN_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLESBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BROWN_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHPIG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPYBARA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARACAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAUCASIAN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EASTERN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHINESE_WATER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'CHITAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CIVET_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYPU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DALL_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DAMARA_DIK_DIK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DORMOUSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DUIKER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ELAND',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ERMINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GEMSBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GENET',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_JACKAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRANADA_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREATER_KUDU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRIZZLY_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOG_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HONEY_BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HYENA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IMPALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYOTE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KANGAROO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KLIPSPRINGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LYNX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MARMOT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AMERICAN_MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MONGOOSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUFLON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MOUNTAIN_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUNTAIN_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MULE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUNTJAC',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MUSKOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUSKRAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'NYALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OLD_WORLD_OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORIBI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORYX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PLAINS_ZEBRA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PORCUPINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRONGHORN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON_DOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_TAILED_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROAN_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RUSA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SAMBA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SERVAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIKA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'SKUNK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRINGBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STEENBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STONE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TOLAI_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VERVET_MONKEY',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WAPITI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WARTHOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATERBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WEASEL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDCAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLVERINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_THORATED_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_YAK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_POLECAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: '_ROE_DEER',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITETAIL_DEER',
        diseases: [
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      { species: 'REPTILE', diseases: [] },
      {
        species: 'ALLIGATOR',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CROCODILE',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
    ],
  },
  bulgaria: {
    children: [
      {
        species: 'PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'BLACKHEAD_DISEASE',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'GAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GREY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_BOAR',
        diseases: [
          {
            category: 'VIRAL',
            name: 'PSEUDORABIES',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'HEPATITIS_E', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'SWINEFLU', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'AFRICAN_SWINE_FEVER',
            notifyAuthorities: 'YES',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ALARIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'TRICHINOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'FALLOW_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'RED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ONCHOCERCIASIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'CHAMOIS',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'ROE_DEER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'MOOSE',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'REINDEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'BROWN_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_WHITE_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RABBIT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'BIRD', diseases: [] },
      {
        species: 'ANDEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ANDEAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AUK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARNACLE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAR_HEADED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBIRD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRANT_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRAZILIAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUZZARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANADA_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANVASBACK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPERCAILLIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_TURTLEDOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARRION_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHACHALACA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHESTNUT_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHILOE_WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHUKAR_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CINNAMON_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COLLARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_RAVEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COOT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CURLEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EGYPTIAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EMU',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FALCON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FIELDFARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FRANCOLIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GADWALL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GARGANEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_EYE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_PLOVER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOOSANDER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOSHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAYLAG',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_HERON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREAT_GREY_OWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREEN_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GUINEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARDHEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HAZEL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_MERGANSERS',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACKDAW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACK_SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JAY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LAPWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LITTLE_BUSTARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MALLARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MANED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MISTLE_TRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOORHEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOTTLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOURNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUTE_SWAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OSTRICH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PACIFIC_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PARADISE_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PICAZURO_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINK_EARED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LESSER_PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PUFFIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'QUAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACKELHAHN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDSHANK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BILLED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BREASTED_MERGANSER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_CRESTED_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_EYED_MORNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_HEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_LEGGED_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REEVES_PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_NECKED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSS_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSYBILL_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUDDY_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFF',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFFED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDGROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDHILL_CRANE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SCAUP',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SEAGULL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHARPTAIL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SILVER_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKUA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKYLARK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SMEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOWCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_THRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SORA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROWHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRUCE_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPUR_WING_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STARLING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STOCK_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SWAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TEAL_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TUFTED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURKEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURTLE_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VELVET_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_RAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_CHEEKED_PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FACED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FRONTED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WINGED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILLOW_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_PIDGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_BILLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HEN_HARRIER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROOK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODPECKER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'MAMMAL', diseases: [] },
      {
        species: 'AARDWOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_WILD_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARCTIC_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARMADILLO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BABOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BANTENG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAT_EARED_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAVER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BIGHORN_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLESBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BROWN_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHPIG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPYBARA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARACAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAUCASIAN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EASTERN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHINESE_WATER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'CHITAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CIVET_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYPU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DALL_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DAMARA_DIK_DIK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DORMOUSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DUIKER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ELAND',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ERMINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GEMSBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GENET',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_JACKAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRANADA_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREATER_KUDU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRIZZLY_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOG_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HONEY_BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HYENA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IMPALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYOTE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KANGAROO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KLIPSPRINGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LYNX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MARMOT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AMERICAN_MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MONGOOSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUFLON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MOUNTAIN_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUNTAIN_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MULE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUNTJAC',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MUSKOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUSKRAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'NYALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OLD_WORLD_OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORIBI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORYX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PLAINS_ZEBRA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PORCUPINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRONGHORN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON_DOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_TAILED_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROAN_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RUSA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SAMBA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SERVAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIKA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'SKUNK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRINGBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STEENBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STONE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TOLAI_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VERVET_MONKEY',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WAPITI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WARTHOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATERBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WEASEL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDCAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLVERINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_THORATED_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_YAK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_POLECAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: '_ROE_DEER',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITETAIL_DEER',
        diseases: [
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      { species: 'REPTILE', diseases: [] },
      {
        species: 'ALLIGATOR',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CROCODILE',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
    ],
  },
  dach: {
    children: [
      {
        species: 'PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'BLACKHEAD_DISEASE',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'GAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GREY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_BOAR',
        diseases: [
          {
            category: 'VIRAL',
            name: 'PSEUDORABIES',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'HEPATITIS_E', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'SWINEFLU', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'AFRICAN_SWINE_FEVER',
            notifyAuthorities: 'YES',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ALARIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'TRICHINOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'FALLOW_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'RED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ONCHOCERCIASIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'CHAMOIS',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'ROE_DEER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'MOOSE',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'REINDEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'BROWN_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_WHITE_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RABBIT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'BIRD', diseases: [] },
      {
        species: 'ANDEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ANDEAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AUK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARNACLE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAR_HEADED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBIRD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRANT_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRAZILIAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUZZARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANADA_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANVASBACK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPERCAILLIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_TURTLEDOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARRION_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHACHALACA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHESTNUT_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHILOE_WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHUKAR_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CINNAMON_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COLLARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_RAVEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COOT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CURLEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EGYPTIAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EMU',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FALCON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FIELDFARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FRANCOLIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GADWALL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GARGANEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_EYE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_PLOVER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOOSANDER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOSHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAYLAG',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_HERON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREAT_GREY_OWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREEN_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GUINEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARDHEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HAZEL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_MERGANSERS',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACKDAW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACK_SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JAY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LAPWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LITTLE_BUSTARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MALLARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MANED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MISTLE_TRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOORHEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOTTLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOURNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUTE_SWAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OSTRICH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PACIFIC_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PARADISE_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PICAZURO_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINK_EARED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LESSER_PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PUFFIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'QUAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACKELHAHN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDSHANK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BILLED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BREASTED_MERGANSER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_CRESTED_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_EYED_MORNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_HEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_LEGGED_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REEVES_PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_NECKED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSS_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSYBILL_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUDDY_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFF',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFFED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDGROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDHILL_CRANE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SCAUP',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SEAGULL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHARPTAIL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SILVER_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKUA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKYLARK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SMEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOWCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_THRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SORA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROWHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRUCE_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPUR_WING_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STARLING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STOCK_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SWAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TEAL_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TUFTED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURKEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURTLE_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VELVET_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_RAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_CHEEKED_PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FACED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FRONTED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WINGED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILLOW_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_PIDGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_BILLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HEN_HARRIER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROOK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODPECKER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'MAMMAL', diseases: [] },
      {
        species: 'AARDWOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_WILD_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARCTIC_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARMADILLO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BABOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BANTENG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAT_EARED_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAVER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BIGHORN_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLESBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BROWN_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHPIG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPYBARA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARACAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAUCASIAN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EASTERN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHINESE_WATER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'CHITAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CIVET_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYPU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DALL_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DAMARA_DIK_DIK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DORMOUSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DUIKER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ELAND',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ERMINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GEMSBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GENET',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_JACKAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRANADA_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREATER_KUDU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRIZZLY_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOG_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HONEY_BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HYENA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IMPALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYOTE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KANGAROO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KLIPSPRINGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LYNX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MARMOT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AMERICAN_MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MONGOOSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUFLON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MOUNTAIN_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUNTAIN_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MULE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUNTJAC',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MUSKOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUSKRAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'NYALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OLD_WORLD_OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORIBI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORYX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PLAINS_ZEBRA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PORCUPINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRONGHORN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON_DOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_TAILED_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROAN_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RUSA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SAMBA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SERVAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIKA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'SKUNK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRINGBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STEENBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STONE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TOLAI_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VERVET_MONKEY',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WAPITI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WARTHOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATERBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WEASEL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDCAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLVERINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_THORATED_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_YAK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_POLECAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: '_ROE_DEER',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITETAIL_DEER',
        diseases: [
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      { species: 'REPTILE', diseases: [] },
      {
        species: 'ALLIGATOR',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CROCODILE',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
    ],
  },
  eastern_europe: {
    children: [
      {
        species: 'PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'BLACKHEAD_DISEASE',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'GAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GREY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_BOAR',
        diseases: [
          {
            category: 'VIRAL',
            name: 'PSEUDORABIES',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'HEPATITIS_E', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'SWINEFLU', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'AFRICAN_SWINE_FEVER',
            notifyAuthorities: 'YES',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ALARIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'TRICHINOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'FALLOW_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'RED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ONCHOCERCIASIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'CHAMOIS',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'ROE_DEER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'MOOSE',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'REINDEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'BROWN_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_WHITE_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RABBIT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'BIRD', diseases: [] },
      {
        species: 'ANDEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ANDEAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AUK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARNACLE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAR_HEADED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBIRD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRANT_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRAZILIAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUZZARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANADA_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANVASBACK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPERCAILLIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_TURTLEDOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARRION_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHACHALACA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHESTNUT_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHILOE_WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHUKAR_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CINNAMON_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COLLARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_RAVEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COOT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CURLEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EGYPTIAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EMU',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FALCON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FIELDFARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FRANCOLIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GADWALL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GARGANEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_EYE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_PLOVER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOOSANDER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOSHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAYLAG',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_HERON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREAT_GREY_OWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREEN_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GUINEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARDHEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HAZEL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_MERGANSERS',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACKDAW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACK_SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JAY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LAPWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LITTLE_BUSTARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MALLARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MANED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MISTLE_TRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOORHEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOTTLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOURNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUTE_SWAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OSTRICH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PACIFIC_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PARADISE_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PICAZURO_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINK_EARED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LESSER_PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PUFFIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'QUAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACKELHAHN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDSHANK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BILLED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BREASTED_MERGANSER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_CRESTED_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_EYED_MORNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_HEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_LEGGED_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REEVES_PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_NECKED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSS_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSYBILL_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUDDY_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFF',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFFED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDGROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDHILL_CRANE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SCAUP',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SEAGULL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHARPTAIL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SILVER_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKUA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKYLARK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SMEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOWCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_THRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SORA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROWHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRUCE_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPUR_WING_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STARLING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STOCK_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SWAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TEAL_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TUFTED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURKEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURTLE_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VELVET_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_RAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_CHEEKED_PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FACED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FRONTED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WINGED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILLOW_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_PIDGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_BILLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HEN_HARRIER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROOK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODPECKER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'MAMMAL', diseases: [] },
      {
        species: 'AARDWOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_WILD_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARCTIC_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARMADILLO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BABOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BANTENG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAT_EARED_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAVER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BIGHORN_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLESBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BROWN_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHPIG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPYBARA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARACAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAUCASIAN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EASTERN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHINESE_WATER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'CHITAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CIVET_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYPU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DALL_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DAMARA_DIK_DIK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DORMOUSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DUIKER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ELAND',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ERMINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GEMSBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GENET',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_JACKAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRANADA_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREATER_KUDU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRIZZLY_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOG_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HONEY_BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HYENA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IMPALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYOTE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KANGAROO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KLIPSPRINGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LYNX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MARMOT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AMERICAN_MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MONGOOSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUFLON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MOUNTAIN_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUNTAIN_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MULE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUNTJAC',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MUSKOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUSKRAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'NYALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OLD_WORLD_OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORIBI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORYX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PLAINS_ZEBRA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PORCUPINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRONGHORN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON_DOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_TAILED_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROAN_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RUSA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SAMBA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SERVAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIKA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'SKUNK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRINGBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STEENBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STONE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TOLAI_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VERVET_MONKEY',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WAPITI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WARTHOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATERBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WEASEL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDCAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLVERINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_THORATED_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_YAK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_POLECAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: '_ROE_DEER',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITETAIL_DEER',
        diseases: [
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      { species: 'REPTILE', diseases: [] },
      {
        species: 'ALLIGATOR',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CROCODILE',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
    ],
  },
  nordics: {
    children: [
      {
        species: 'PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'BLACKHEAD_DISEASE',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'GAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GREY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_BOAR',
        diseases: [
          {
            category: 'VIRAL',
            name: 'PSEUDORABIES',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'HEPATITIS_E', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'SWINEFLU', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'AFRICAN_SWINE_FEVER',
            notifyAuthorities: 'YES',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ALARIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'TRICHINOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'FALLOW_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'RED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ONCHOCERCIASIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'CHAMOIS',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'ROE_DEER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'MOOSE',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'REINDEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'BROWN_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_WHITE_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RABBIT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'BIRD', diseases: [] },
      {
        species: 'ANDEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ANDEAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AUK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARNACLE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAR_HEADED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBIRD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRANT_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRAZILIAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUZZARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANADA_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANVASBACK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPERCAILLIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_TURTLEDOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARRION_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHACHALACA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHESTNUT_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHILOE_WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHUKAR_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CINNAMON_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COLLARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_RAVEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COOT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CURLEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EGYPTIAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EMU',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FALCON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FIELDFARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FRANCOLIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GADWALL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GARGANEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_EYE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_PLOVER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOOSANDER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOSHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAYLAG',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_HERON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREAT_GREY_OWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREEN_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GUINEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARDHEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HAZEL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_MERGANSERS',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACKDAW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACK_SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JAY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LAPWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LITTLE_BUSTARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MALLARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MANED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MISTLE_TRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOORHEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOTTLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOURNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUTE_SWAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OSTRICH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PACIFIC_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PARADISE_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PICAZURO_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINK_EARED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LESSER_PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PUFFIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'QUAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACKELHAHN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDSHANK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BILLED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BREASTED_MERGANSER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_CRESTED_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_EYED_MORNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_HEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_LEGGED_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REEVES_PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_NECKED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSS_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSYBILL_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUDDY_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFF',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFFED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDGROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDHILL_CRANE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SCAUP',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SEAGULL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHARPTAIL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SILVER_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKUA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKYLARK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SMEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOWCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_THRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SORA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROWHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRUCE_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPUR_WING_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STARLING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STOCK_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SWAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TEAL_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TUFTED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURKEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURTLE_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VELVET_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_RAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_CHEEKED_PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FACED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FRONTED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WINGED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILLOW_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_PIDGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_BILLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HEN_HARRIER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROOK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODPECKER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'MAMMAL', diseases: [] },
      {
        species: 'AARDWOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_WILD_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARCTIC_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARMADILLO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BABOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BANTENG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAT_EARED_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAVER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BIGHORN_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLESBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BROWN_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHPIG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPYBARA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARACAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAUCASIAN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EASTERN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHINESE_WATER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'CHITAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CIVET_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYPU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DALL_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DAMARA_DIK_DIK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DORMOUSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DUIKER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ELAND',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ERMINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GEMSBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GENET',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_JACKAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRANADA_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREATER_KUDU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRIZZLY_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOG_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HONEY_BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HYENA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IMPALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYOTE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KANGAROO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KLIPSPRINGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LYNX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MARMOT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AMERICAN_MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MONGOOSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUFLON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MOUNTAIN_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUNTAIN_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MULE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUNTJAC',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MUSKOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUSKRAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'NYALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OLD_WORLD_OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORIBI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORYX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PLAINS_ZEBRA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PORCUPINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRONGHORN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON_DOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_TAILED_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROAN_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RUSA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SAMBA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SERVAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIKA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'SKUNK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRINGBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STEENBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STONE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TOLAI_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VERVET_MONKEY',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WAPITI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WARTHOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATERBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WEASEL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDCAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLVERINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_THORATED_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_YAK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_POLECAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: '_ROE_DEER',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITETAIL_DEER',
        diseases: [
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      { species: 'REPTILE', diseases: [] },
      {
        species: 'ALLIGATOR',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CROCODILE',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
    ],
  },
  southern_europe: {
    children: [
      {
        species: 'PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'BLACKHEAD_DISEASE',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'GAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GREY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_BOAR',
        diseases: [
          {
            category: 'VIRAL',
            name: 'PSEUDORABIES',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'HEPATITIS_E', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'SWINEFLU', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'AFRICAN_SWINE_FEVER',
            notifyAuthorities: 'YES',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ALARIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'TRICHINOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'FALLOW_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'RED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ONCHOCERCIASIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'CHAMOIS',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'ROE_DEER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'MOOSE',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'REINDEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'BROWN_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_WHITE_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RABBIT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'BIRD', diseases: [] },
      {
        species: 'ANDEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ANDEAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AUK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARNACLE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAR_HEADED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBIRD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRANT_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRAZILIAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUZZARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANADA_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANVASBACK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPERCAILLIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_TURTLEDOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARRION_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHACHALACA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHESTNUT_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHILOE_WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHUKAR_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CINNAMON_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COLLARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_RAVEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COOT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CURLEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EGYPTIAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EMU',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FALCON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FIELDFARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FRANCOLIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GADWALL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GARGANEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_EYE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_PLOVER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOOSANDER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOSHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAYLAG',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_HERON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREAT_GREY_OWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREEN_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GUINEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARDHEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HAZEL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_MERGANSERS',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACKDAW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACK_SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JAY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LAPWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LITTLE_BUSTARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MALLARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MANED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MISTLE_TRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOORHEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOTTLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOURNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUTE_SWAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OSTRICH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PACIFIC_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PARADISE_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PICAZURO_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINK_EARED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LESSER_PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PUFFIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'QUAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACKELHAHN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDSHANK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BILLED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BREASTED_MERGANSER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_CRESTED_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_EYED_MORNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_HEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_LEGGED_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REEVES_PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_NECKED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSS_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSYBILL_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUDDY_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFF',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFFED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDGROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDHILL_CRANE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SCAUP',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SEAGULL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHARPTAIL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SILVER_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKUA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKYLARK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SMEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOWCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_THRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SORA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROWHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRUCE_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPUR_WING_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STARLING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STOCK_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SWAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TEAL_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TUFTED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURKEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURTLE_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VELVET_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_RAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_CHEEKED_PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FACED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FRONTED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WINGED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILLOW_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_PIDGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_BILLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HEN_HARRIER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROOK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODPECKER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'MAMMAL', diseases: [] },
      {
        species: 'AARDWOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_WILD_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARCTIC_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARMADILLO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BABOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BANTENG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAT_EARED_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAVER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BIGHORN_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLESBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BROWN_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHPIG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPYBARA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARACAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAUCASIAN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EASTERN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHINESE_WATER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'CHITAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CIVET_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYPU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DALL_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DAMARA_DIK_DIK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DORMOUSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DUIKER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ELAND',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ERMINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GEMSBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GENET',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_JACKAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRANADA_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREATER_KUDU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRIZZLY_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOG_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HONEY_BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HYENA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IMPALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYOTE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KANGAROO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KLIPSPRINGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LYNX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MARMOT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AMERICAN_MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MONGOOSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUFLON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MOUNTAIN_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUNTAIN_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MULE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUNTJAC',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MUSKOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUSKRAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'NYALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OLD_WORLD_OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORIBI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORYX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PLAINS_ZEBRA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PORCUPINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRONGHORN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON_DOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_TAILED_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROAN_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RUSA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SAMBA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SERVAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIKA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'SKUNK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRINGBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STEENBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STONE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TOLAI_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VERVET_MONKEY',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WAPITI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WARTHOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATERBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WEASEL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDCAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLVERINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_THORATED_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_YAK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_POLECAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: '_ROE_DEER',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITETAIL_DEER',
        diseases: [
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      { species: 'REPTILE', diseases: [] },
      {
        species: 'ALLIGATOR',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CROCODILE',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
    ],
  },
  malta: {
    children: [
      {
        species: 'PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'BLACKHEAD_DISEASE',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'GAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GREY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'VIRULENT_NEWCASTLE_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_BOAR',
        diseases: [
          {
            category: 'VIRAL',
            name: 'PSEUDORABIES',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'HEPATITIS_E', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'SWINEFLU', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'AFRICAN_SWINE_FEVER',
            notifyAuthorities: 'YES',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ALARIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'TRICHINOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'FALLOW_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'RED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'CATTLE_EPIDEMIC',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ONCHOCERCIASIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'CHAMOIS',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'ROE_DEER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'MOOSE',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'REINDEER',
        diseases: [
          { category: 'VIRAL', name: 'BLUETONGUE', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'VIRAL',
            name: 'FOOT_HOOF_AND_MOUTH_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PATHOGENIC_ESCHERICHIA_COLI_INFECION',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'FASCIOLA_HEPATICA',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'BROWN_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_WHITE_HARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'LEPTOSPIROSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RABBIT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'EUROPEAN_BROWN_HARE_SYNDROME',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'VIRAL', name: 'MYXOMATOSIS', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PASTEURELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'STREPTOCOCCOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'COCCIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'BIRD', diseases: [] },
      {
        species: 'ANDEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ANDEAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AUK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARNACLE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAR_HEADED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBIRD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRANT_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BRAZILIAN_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUZZARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANADA_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CANVASBACK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPERCAILLIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_TURTLEDOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARRION_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHACHALACA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHESTNUT_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHILOE_WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHUKAR_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CINNAMON_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COLLARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_RAVEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COMMON_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COOT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CURLEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EARED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EGYPTIAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EMU',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FALCON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FIELDFARE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FRANCOLIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GADWALL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GARGANEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_EYE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_PLOVER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOOSANDER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOSHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAYLAG',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_HERON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRAY_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREAT_GREY_OWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREEN_WINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GUINEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARDHEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HAZEL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_CROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOODED_MERGANSERS',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACKDAW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JACK_SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'JAY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LAPWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LITTLE_BUSTARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MAGPIE_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MALLARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MANED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MISTLE_TRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOORHEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOTTLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOURNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUTE_SWAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OSTRICH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PACIFIC_BLACK_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PARADISE_SHELDUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PEAFOWL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PICAZURO_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINK_EARED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LESSER_PRAIRIE_CHICKEN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PUFFIN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'QUAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACKELHAHN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDSHANK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REDWING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BILLED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_BREASTED_MERGANSER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_CRESTED_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_EYED_MORNING_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_HEAD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_LEGGED_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RED_SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'REEVES_PHEASANT',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RINGED_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_NECKED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PARTRIDGE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROCK_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSS_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROSYBILL_POCHARD',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUDDY_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFF',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RUFFED_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDGROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SANDHILL_CRANE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SCAUP',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SEAGULL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHARPTAIL_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SHOVELER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SILVER_TEAL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKUA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SKYLARK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SMEW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNIPE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOWCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_THRUSH',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SORA',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SONG_SPARROW',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPARROWHAWK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRUCE_GROUSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPUR_WING_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STARLING',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STOCK_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SWAN_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TEAL_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TUFTED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURKEY',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TURTLE_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VELVET_SCOTER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_RAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_CHEEKED_PINTAIL',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FACED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_FRONTED_GOOSE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WINGED_DOVE',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WIGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILLOW_PTARMIGAN',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODCOCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOOD_PIDGEON',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_BILLED_DUCK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HEN_HARRIER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROOK',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WOODPECKER',
        diseases: [
          {
            category: 'VIRAL',
            name: 'AVIAN_INFLUEZA',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'ORF', notifyAuthorities: 'NO', partsEatable: 'NO' },
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'INFECTIOUS_KERATOCONJUNCTIVITIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      { species: 'MAMMAL', diseases: [] },
      {
        species: 'AARDWOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AFRICAN_WILD_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ALPINE_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARCTIC_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ARMADILLO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BABOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BANTENG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BARBARY_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BAT_EARED_FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BEAVER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BIGHORN_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_BISON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACKBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLACK_WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLESBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BLUE_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BROWN_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'BUSHPIG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPE_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAPYBARA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CARACAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CAUCASIAN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EASTERN_TUR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CHINESE_WATER_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'CHITAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CIVET_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYPU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DALL_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DAMARA_DIK_DIK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DORMOUSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'DUIKER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ELAND',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ERMINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'FOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'SCABIES',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'GEMSBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GENET',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GOLDEN_JACKAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRANADA_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREATER_KUDU',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GRIZZLY_BEAR',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HOG_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HONEY_BADGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'HYENA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'IMPALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'COYOTE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KANGAROO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'KLIPSPRINGER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'LYNX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MARMOT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'AMERICAN_MINK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MONGOOSE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUFLON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MOUNTAIN_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MOUNTAIN_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MULE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUNTJAC',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'MUSKOX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'MUSKRAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'NYALA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OLD_WORLD_OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORIBI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ORYX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'OTTER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PINE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PLAINS_ZEBRA',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PORCUPINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'PRONGHORN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RACCOON_DOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'RING_TAILED_CAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROAN_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'ROE_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'ACTINOBACILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'BACTERIAL',
            name: 'LISTERIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PARATUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'ERYSIPELOID',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TUBERCULOSIS',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'WARBLE_FLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'BOTFLY',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'PARASITIC_INFECTION_OF_THE_SPINAL_CORD',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
          {
            category: 'OTHER',
            name: 'ASPERGILLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'RUSA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SABLE_ANTELOPE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SAMBA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SERVAL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIBERIAN_IBEX',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SIKA_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      {
        species: 'SKUNK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SNOW_SHEEP',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SPRINGBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STEENBOK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'STONE_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'TOLAI_HARE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'PSEUDOTUBERCULOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'BACTERIAL',
            name: 'TULARAEMIA',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'TOXOPLASMOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'VERVET_MONKEY',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WAPITI',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WARTHOG',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATERBUCK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WATER_BUFFALO',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_TAILED_DEER',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITE_WEASEL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDCAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILDEBEEST',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_GOAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'LUNGWORMS',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLF',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'UNICELLULAR PARASITES',
            name: 'SARCOSPORIDIOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'ECHINOCOCCUS',
            notifyAuthorities: ' ',
            partsEatable: 'NO',
          },
          {
            category: 'MULTICELLULAR PARASITES',
            name: 'STOMACH_AND_GUT_TAPEWORM',
            notifyAuthorities: 'NO',
            partsEatable: 'YES',
          },
        ],
      },
      {
        species: 'WOLVERINE',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'YELLOW_THORATED_MARTEN',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WILD_YAK',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'EUROPEAN_POLECAT',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'GREY_SQUIRREL',
        diseases: [
          { category: 'VIRAL', name: 'RABIES', notifyAuthorities: 'YES ', partsEatable: 'NO' },
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: '_ROE_DEER',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'BRUCELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'WHITETAIL_DEER',
        diseases: [
          { category: 'BACTERIAL', name: 'ANTHRAX', notifyAuthorities: 'NO', partsEatable: 'NO' },
          {
            category: 'OTHER',
            name: 'CHRONIC_WASTING_DISEASE',
            notifyAuthorities: 'YES ',
            partsEatable: 'NO',
          },
          { category: 'OTHER', name: 'TRICHOPHYTON', notifyAuthorities: 'NO', partsEatable: 'YES' },
        ],
      },
      { species: 'REPTILE', diseases: [] },
      {
        species: 'ALLIGATOR',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
      {
        species: 'CROCODILE',
        diseases: [
          {
            category: 'BACTERIAL',
            name: 'SALMONELLOSIS',
            notifyAuthorities: 'NO',
            partsEatable: 'NO',
          },
        ],
      },
    ],
  },
};
