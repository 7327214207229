import { inject } from 'inversify';
import { computed, makeObservable, runInAction } from 'mobx';
import { AsyncTask } from '../../../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../../../domain/core/ViewModel';
import { DistrictModel } from '../../../../../../../../domain/model/DistrictModel';
import { MemberModel } from '../../../../../../../../domain/model/MemberModel';
import { ShareProxy } from '../../../../../../../../domain/proxy/ShareProxy';
import { I18nService } from '../../../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../../../domain/service/NotificationService';
import { SessionStore } from '../../../../../../../../domain/store/SessionStore';
import { transient } from '../../../../../../../../inversify/decorator';
import { DISTRICT_ROLE, ENTITY_TYPE } from '../../../../../../../../shared/enum';

export interface ViewDistrictMemberProps {
  district: DistrictModel;
  member: MemberModel;
  showDivider: boolean;
  onMemberRemoved: (member: MemberModel) => void;
  currentUserRole: DISTRICT_ROLE | null;
}

@transient()
export class ViewDistrictMemberVm extends ViewModel<ViewDistrictMemberProps> {
  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(ShareProxy) private readonly shareProxy: ShareProxy,
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(NotificationService) private readonly notification: NotificationService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get canSeeMenu() {
    if (this.isMe) {
      return this.props.currentUserRole !== DISTRICT_ROLE.OWNER;
    }

    // owner can see menus of other people
    if (this.props.currentUserRole === DISTRICT_ROLE.OWNER) {
      return true;
    }

    // admin can see menu of other members
    if (this.props.currentUserRole === DISTRICT_ROLE.ADMIN) {
      return this.props.member.role?.name === DISTRICT_ROLE.USER || this.props.member.role?.name === DISTRICT_ROLE.GUEST;
    }

    return false;
  }

  @computed
  public get isMe() {
    return this.session.session?.user.id === this.props.member.user?.id;
  }

  @computed
  public get isOwner() {
    return this.props.member.role?.name === DISTRICT_ROLE.OWNER;
  }

  @computed
  public get isAdmin() {
    return this.props.member.role?.name === DISTRICT_ROLE.ADMIN;
  }

  @computed
  public get isMember() {
    return this.props.member.role?.name === DISTRICT_ROLE.USER;
  }

  @computed
  public get isGuest() {
    return this.props.member.role?.name === DISTRICT_ROLE.GUEST;
  }

  @computed
  public get canBePromotedToOwner() {
    return this.isAdmin && this.props.currentUserRole === DISTRICT_ROLE.OWNER;
  }

  @computed
  public get canBePromotedToAdmin() {
    return this.isMember && this.props.currentUserRole === DISTRICT_ROLE.OWNER;
  }

  @computed
  public get canBePromotedToMember() {
    return this.isGuest && (this.props.currentUserRole === DISTRICT_ROLE.OWNER || this.props.currentUserRole === DISTRICT_ROLE.ADMIN);
  }

  @computed
  public get canBeRemoved() {
    if (this.props.currentUserRole === DISTRICT_ROLE.OWNER) {
      return !this.isOwner;
    }

    return !this.isOwner && !this.isAdmin;
  }

  @computed
  public get canAdminRoleBeDemoted() {
    return this.isAdmin && this.props.currentUserRole === DISTRICT_ROLE.OWNER;
  }

  @computed
  public get canMemberRoleBeDemoted() {
    return this.isMember && (this.props.currentUserRole === DISTRICT_ROLE.OWNER || this.props.currentUserRole === DISTRICT_ROLE.ADMIN);
  }

  public changeRole = new AsyncTask(async (role: DISTRICT_ROLE) => {
    try {
      const result = await this.shareProxy.changeRole({
        entityId: this.props.district.id,
        userId: this.props.member.user?.id ?? '',
        entityType: ENTITY_TYPE.DISTRICT,
        role,
      });

      if (result.ok) {
        return runInAction(() => {
          if (this.props.member.role) {
            this.props.member.role.name = role;
          }
        });
      }

      this.notification.error(this.i18n.t('share:error_changing_role'));
    } catch (e) {
      console.error(e);
      this.notification.error(this.i18n.t('share:error_changing_role'));
    }
  });

  public removeMember = new AsyncTask(async () => {
    try {
      const result = await this.shareProxy.removeMember({
        entityId: this.props.district.id,
        userId: this.props.member.user?.id ?? '',
        entityType: ENTITY_TYPE.DISTRICT,
      });

      if (result.ok) {
        return this.props.onMemberRemoved(this.props.member);
      }

      this.notification.error(this.i18n.t('share:error_removing_member'));
    } catch (e) {
      console.error(e);
      this.notification.error(this.i18n.t('share:error_removing_member'));
    }
  });
}
