import { IsEnum, IsOptional } from 'class-validator';

import { ApiProperty } from '@nestjs/swagger';

import { ENTITLEMENTS, LANGUAGE, MEASUREMENT_SYSTEM, SUBSCRIPTION_TYPE } from '../enum';
import { COUNTRY_CODE } from '../enum/countryCode.enum';
import { AddressDto } from './address.dto';
import { PhotoResponseDto } from './photo.response.dto';
import { GearSetResponseDto } from './gearSet.response.dto';
import { LicenceResponseDto } from './licence.response.dto';
import { UserAchievementResponseDto } from './userAchievement.response.dto';

export interface ISortType {
  [key: string]: string[] | undefined;
}

export interface ITutorialDisplayedType {
  name: string;
  ts: string;
}

// For swagger only
export class SortType {
  @ApiProperty()
  public key: string[];
}

// For swagger only
// tslint:disable-next-line: max-classes-per-file
export class TutorialDisplayedType {
  @ApiProperty()
  public name: string;

  @ApiProperty()
  public ts: string;
}

// tslint:disable-next-line: max-classes-per-file
export class UserResponseDto {
  @ApiProperty()
  public id: string;

  @ApiProperty()
  public firstName: string;

  @ApiProperty()
  public lastName: string;

  @ApiProperty()
  public email: string;

  @ApiProperty()
  public status: string;

  @ApiProperty()
  public language: LANGUAGE;

  @ApiProperty()
  public distanceUnit: MEASUREMENT_SYSTEM;

  @ApiProperty({ type: () => PhotoResponseDto, required: false })
  public photo?: PhotoResponseDto;

  @IsOptional()
  @ApiProperty({ type: () => SortType })
  public sorting?: ISortType;

  @IsOptional()
  @ApiProperty({ type: () => TutorialDisplayedType, isArray: true })
  public tutorialDisplayed?: ITutorialDisplayedType[];

  @IsOptional()
  @ApiProperty({ type: () => AddressDto, required: false })
  public defaultAddress?: AddressDto;

  @ApiProperty()
  @IsOptional()
  @IsEnum(COUNTRY_CODE)
  public country?: COUNTRY_CODE;

  @IsOptional()
  @ApiProperty({ type: () => GearSetResponseDto, required: false })
  public gearSets?: GearSetResponseDto[];

  @ApiProperty()
  @IsOptional()
  public licences?: LicenceResponseDto[];

  @ApiProperty()
  @IsOptional()
  public entitlements?: ENTITLEMENTS[];

  @ApiProperty()
  @IsOptional()
  public achievements?: UserAchievementResponseDto[];

  @ApiProperty()
  @IsOptional()
  public lastSeen?: string;

  @ApiProperty()
  @IsOptional()
  public isPro?: boolean;

  @ApiProperty()
  public subscriptionType: SUBSCRIPTION_TYPE;

  @ApiProperty()
  @IsOptional()
  public isYearlySubscription?: boolean;

  @ApiProperty()
  @IsOptional()
  public isPartOfGroupSubscription?: boolean;

  @ApiProperty()
  @IsOptional()
  public trialPeriodUsed?: boolean;

  @ApiProperty()
  @IsOptional()
  public trialLength?: number;
}
