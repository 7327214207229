import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import * as yup from 'yup';

import { Box, Checkbox, Grid, Typography } from '@mui/material';

import { useVm } from '../../../../../domain/hooks/useVm';
import { Field } from '../../../../../toolkit/components/form/Field';
import { Form } from '../../../../../toolkit/components/form/Form';
import { FormSubmitButton } from '../../../../../toolkit/components/form/FormSubmitButton';
import { registrationUseStyles } from '../styles/registrationUseStyles';
import { SocialMediaRegistrationRouteVm } from './SocialMediaRegistrationRouteVm';

export enum OAuthType {
  Google = 'google',
  Facebook = 'facebook',
  Apple = 'apple',
}

export interface IOAuthUser {
  access_token: string;
  type: OAuthType;
  email?: string;
  firstName?: string;
  lastName?: string;
}

export type SocialMediaRegistrationForm = IOAuthUser & {
  privacyAccepted: boolean;
}

export const SocialMediaRegistrationRoute: React.FC = observer(function SocialMediaRegistrationRoute() {

  const { t } = useTranslation();
  const vm = useVm(SocialMediaRegistrationRouteVm);
  const classes = registrationUseStyles();

  const location = useLocation();
  const state = location.state as IOAuthUser;

  const defaultValues: SocialMediaRegistrationForm = useMemo(() => {
    return {
      privacyAccepted: false,
      ...state
    };
  }, [state]);

  return (
    <Form onSubmit={vm.socialMediaRegister} defaultValues={defaultValues}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} px={4}>
        <Grid container direction={'column'} justifyContent="center" alignItems={'center'} pb={4} rowSpacing={4}>
          <Grid item>
            <Typography color="textPrimary" variant="h1">
              {t('auth:registration_privacy_header')}
            </Typography>
          </Grid>

          <Grid item>
            <Typography color="textPrimary" variant="h6" fontWeight={'bold'}>
              {t('auth:registration_privacy_tile')}
            </Typography>
          </Grid>

          {/* TERMS OF AGREAMENT */}
          <Grid item container alignItems="center">
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight={'bold'}>
                {t('auth:register_privacy_accept')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field name="privacyAccepted"
                fullWidth
                schema={yup
                  .boolean()
                  .oneOf([true], t('auth:invalid_privacy_check'))
                  .required('auth:validations.register_form.required.privacy')
                }
              >
                <Grid container alignItems="center">
                  <Grid item xs={1}>
                    <Checkbox
                      className={classes.checkboxPadding}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography
                      variant="subtitle2"
                      className={classes.privacyField}
                    >
                      {t('auth:registration_termsagreement_body_1')}{' '}
                      <a
                        href="https://en-uk.hunterco.de/en-us/terms-of-use"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'inherit' }}
                      >
                        {t('auth:registration_termsagreement_body_2')}
                      </a>{' '}
                      {t('auth:registration_termsagreement_body_3')}{' '}
                      <a
                        href="https://en-uk.hunterco.de/data-privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'inherit' }}
                      >
                        {t('auth:registration_termsagreement_body_4')}
                      </a>{' '}
                      {t('auth:registration_termsagreement_body_5')}
                    </Typography>
                  </Grid>
                </Grid>
              </Field>
            </Grid>
          </Grid>
        </Grid>

        {/* BUTTON */}
        <Box alignSelf={'center'}>
          <FormSubmitButton
            className={classes.doneButton}
            submitting={vm.isBusy}
          >
            {t('common:button.done')}
          </FormSubmitButton>
        </Box>

      </Box >
    </Form>
  );
});
