import { makeObservable, observable } from 'mobx';

import { StripePriceTierDto } from '../../../shared/dto/stripe/stripe.price.tier.dto';

export class StripePriceTierModel {

  @observable
  public unitAmount: number | null = null;

  @observable
  public flatAmount: number | null = null;

  @observable
  public upTo: number | null = null;

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: StripePriceTierDto) => {
    const price = new StripePriceTierModel();

    price.unitAmount = dto.unitAmount;
    price.flatAmount = dto.flatAmount;
    price.upTo = dto.upTo;

    return price;
  }
}
