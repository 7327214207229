import { action, makeObservable, observable } from 'mobx';

import { ViewModel } from '../../../domain/core/ViewModel';
import { singleton } from '../../../inversify/decorator';
import { PasswordResetScreens } from './password-reset/PasswordResetVm';

@singleton()
export class ForgotPasswordVm extends ViewModel {

  @observable
  public email: string = '';

  @observable
  public step: PasswordResetScreens = PasswordResetScreens.Form;

  constructor() {
    super();
    makeObservable(this);
  }

  @action
  public setEmail = async (email: string, nextStep: boolean): Promise<void> => {
    this.email = email;
    if (nextStep) {
      this.step = PasswordResetScreens.Success;
    }
  }
}
