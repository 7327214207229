import { autorun } from 'mobx';
import { useEffect } from 'react';

import { SubzoneModel } from '../../../../../domain/model/SubzoneModel';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { GeoUtil } from '../../../../../util/GeoUtil';
import { convertGeolocationToPath, IMapClickEvent } from './useDrawDistrictMesh';

export function useDrawSubzones(enabled: boolean, subzones: SubzoneModel[], map: google.maps.Map | null) {
  useEffect(() => {
    if (!map) {
      return;
    }

    if (!enabled) {
      return;
    }

    let subzonePolygons: google.maps.Polygon[] = [];
    let selectedPolyline: google.maps.Polyline | null = null;
    let polyline: google.maps.Polyline | null = null;
    let closingPolyline: google.maps.Polyline | null = null;

    function resetPolylines() {
      // reset polyline
      polyline?.setMap(null);
      polyline = null;

      // reset selected polyline
      selectedPolyline?.setMap(null);
      selectedPolyline = null;

      // reset closing polyline
      closingPolyline?.setMap(null);
      closingPolyline = null;
    }

    function resetPolygons() {
      subzonePolygons.forEach((subzone) => subzone.setMap(null));
      subzonePolygons = [];
    }

    const autorunDisposer = autorun(() => {
      resetPolygons();

      subzonePolygons = subzones.filter(s => s.mesh.isMeshClosed).map((subzone) => {
        return new google.maps.Polygon({
          paths: convertGeolocationToPath(subzone.mesh.points),
          clickable: false,
          strokeColor: subzone.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: subzone.fillColor,
          fillOpacity: 0.2,
          map,
          zIndex: 2,
        });
      });

      const wipSubzone = subzones.find(s => !s.mesh.isMeshClosed);
      if (!wipSubzone) {
        return;
      }
      resetPolylines();

      polyline = new google.maps.Polyline({
        path: wipSubzone.mesh.points.map((p) => ({ lat: p.latitude, lng: p.longitude })),
        strokeColor: wipSubzone.color,
        strokeOpacity: 2,
        strokeWeight: 4,
        map,
        zIndex: 2,
      });

      // detect click event on polyline so we can edit it
      polyline.addListener('click', function (event: IMapClickEvent) {
        const pair = GeoUtil.getClosestPointPair({
          latitude: event.latLng.lat(),
          longitude: event.latLng.lng(),
        }, wipSubzone.mesh.points);

        wipSubzone.mesh.selectPair(pair);

        // remove old polyline
        selectedPolyline?.setMap(null);

        // create new polyline
        selectedPolyline = new google.maps.Polyline({
          path: convertGeolocationToPath(pair),
          strokeColor: colors.secondary,
          strokeOpacity: 2,
          strokeWeight: 4,
          map,
          zIndex: 2,
        });
      });

      // closing polyline
      if (wipSubzone.mesh.points.length >= 3) {
        const path = wipSubzone.mesh.points;
        closingPolyline = new google.maps.Polyline({
          path: convertGeolocationToPath([
            path[path.length - 1],
            path[0],
          ]),
          strokeOpacity: 0,
          zIndex: 2,
          icons: [
            {
              icon: {
                path: 'M 0,-1 0,1',
                strokeOpacity: 1,
                strokeWeight: 4,
                strokeColor: wipSubzone.color,
              },
              offset: '0',
              repeat: '15px',
            },
          ],
          map,
        });
      }
    });

    return () => {
      autorunDisposer();

      resetPolygons();
      resetPolylines();
    };
  }, [enabled, subzones, map]);
}
