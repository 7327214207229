import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Input, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { Assets } from '../../../../../assets';
import { useContainer } from '../../../../../domain/hooks/useContainer';
import { TrackingEvent } from '../../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../../domain/service/tracking/TrackingService';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { ProCtaButton } from '../../../../../toolkit/components/ProCtaButton';

interface IProps {
  label: string;
  onClose: () => void;
}

export function ProMapPath(props: IProps) {
  const { t } = useTranslation();
  const tracking = useContainer().get(TrackingService);

  useEffectOnce(() => {
    async () =>
      await tracking.track(TrackingEvent.FREE_TRAIL_VIEW);
  });

  return (
    <Box>
      {/* HEADER */}
      <Grid container direction="column" justifyContent="center"
        sx={{
          background: colors.blueGrey[600],
          color: colors.paper,
          paddingX: 2,
          paddingY: 1,
        }}
      >
        <Grid item container direction={'row'} justifyContent={'flex-end'} paddingLeft={4}>
          <IconButton color="inherit" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item paddingX={2} marginTop={3} paddingBottom={1}>
          <Input
            fullWidth
            value={props.label}
            disableUnderline
            readOnly
            sx={{
              fontSize: '24px',
              color: 'white',
              fontWeight: '600',
              borderBottom: '1px solid transparent',
            }}
          />
        </Grid>
      </Grid>
      <Box paddingX={4} display="flex" flexDirection={'column'} alignItems="center">
        <img src={Assets.path.proTeaser} style={{ width: '100%', marginTop: '20px' }} />
        <Typography color={colors.primary} mb={5} variant="h6" textAlign={'center'}>{t('district:paths_cta_text')}</Typography>
        <ProCtaButton />
      </Box>
    </Box>
  );
}
