import { StripeProductMetadataDto } from '../../../shared/dto/stripe/stripe.product.metadata.dto';
import { LANGUAGE } from '../../../shared/enum';

export class StripeProductMetadataModel {

  /**
   * This metadata property is where we assign the correct locize key, which will provide the accurate translation on a Stripe checkout page based on the language selected by the user.
  */
  public locizeKey: string | undefined = undefined;

  /**
   * This metadata property is used to differentiate between volume and graduated pricing models.
  */
  public pricingModel: string | undefined = undefined;

  /**
    * Metadata property which we use to determine which group product should we use (depending on a user's language).
  */
  public language: LANGUAGE | undefined = LANGUAGE.en;

  public static fromDto = (dto: StripeProductMetadataDto | undefined) => {
    if (!dto) {
      return null;
    }

    const metadata = new StripeProductMetadataModel();
    metadata.locizeKey = dto.locizeKey;
    metadata.pricingModel = dto.pricingModel;
    metadata.language = dto.language;

    return metadata;
  }
}
