import { MEASUREMENT_SYSTEM } from '../shared/enum';

export function distanceUnitSuffix(unit: MEASUREMENT_SYSTEM | undefined): string {
  if (!unit) {
    return 'unknown';
  }

  if (unit === MEASUREMENT_SYSTEM.IMPERIAL) {
    return 'y';
  }

  return 'm';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepEqual<T extends Record<string, any>>(obj1: T, obj2: T): boolean {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  const keys1 = Object.keys(obj1).filter(key => obj1[key] !== undefined);
  const keys2 = Object.keys(obj2).filter(key => obj2[key] !== undefined);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}
