import {
  GroupSubscriptionUserModel
} from '../../../../../../domain/model/GroupSubscriptionUserModel';
import { ManageGroupVm } from '../../../../manage-group/ManageGroupVm';
import { MenuCellCancelSeat } from './MenuCellCancelSeat';
import { MenuCellRemoveMember } from './MenuCellRemoveMember';

interface IMenuCellProps {
  index: number;
  vm: ManageGroupVm;
  row: GroupSubscriptionUserModel;
}

export const MenuCell: React.FC<IMenuCellProps> = ({ index, row, vm }: IMenuCellProps) => {
  if (row.id) {
    return <MenuCellRemoveMember index={index} row={row} vm={vm} />;
  } else {
    return <MenuCellCancelSeat index={index} vm={vm} />;
  }
};
