import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography
} from '@mui/material';

import { Assets } from '../../../assets';
import { useInstance } from '../../../domain/hooks/useInstance';
import { SessionStore } from '../../../domain/store/SessionStore';
import { AppRoutes } from '../../../router/AppRoutesEnum';
import { FEATURE } from '../../../shared/enum';
import { colors } from '../../../theme/options/palette/const/colors';
import { MapProvider, MapType } from '../MapVm';

interface MapLayerProps {
  mapType: MapType;
  mapProvider: MapProvider;
  onClick: (mapProvider: MapProvider, mapType: MapType, styleUrl: string | undefined) => void;
  styleUrl?: string;
  inverted?: boolean;
  title?: string;
  icon?: string;
  extended?: boolean;
}

export function MapLayerOption(props: MapLayerProps) {
  const { t } = useTranslation();
  const session = useInstance(SessionStore);
  const navigate = useNavigate();
  const mapboxMapsEnabled = session.hasFeatureEnabled(FEATURE.HYBRID_MAP);
  const [confirmOpened, setOpenConfirmModal] = useState(false);

  const openConfirmModal = useCallback(() => {
    setOpenConfirmModal(true);
  }, [setOpenConfirmModal]);

  const closeConfirmModal = useCallback(() => {
    setOpenConfirmModal(false);
  }, [setOpenConfirmModal]);

  const onSelectConfirmed = useCallback(() => {
    closeConfirmModal();
    return navigate(AppRoutes.Pricing);
  }, [closeConfirmModal, navigate]);

  const onClick = useCallback(() => {
    if (!mapboxMapsEnabled && props.mapProvider === 'mapbox') {
      return openConfirmModal();
    }

    props.onClick(props.mapProvider, props.mapType, props.styleUrl);
  }, [props, mapboxMapsEnabled, openConfirmModal]);

  const typeToIcon = useCallback((type: MapType) => {
    if (type === 'satellite') {
      return Assets.map.layers.satellite;
    }

    if (type === 'terrain') {
      return Assets.map.layers.terrain;
    }

    if (type === 'roadmap') {
      return Assets.map.layers.roadmap;
    }
  }, []);

  return (
    <>
      <Box
        position="relative"
        maxWidth={'68px'}
        height={'94px'}
        style={{ cursor: 'pointer' }}
        mr={1}
        display="flex"
        flexDirection={'column'}
        alignItems="center"
        bgcolor={props.inverted ? colors.secondary : 'white'}
        px={.5}
        pt={.4}
        borderRadius={1}
        onClick={onClick}
      >
        <img style={{ width: '60px', height: '60px' }} src={props.icon ?? typeToIcon(props.mapType)} />
        <Box display="flex" justifyContent={'center'} alignItems="center" minHeight={props.extended ? 40 : 26}>
          <Typography color={props.inverted ? 'white' : 'unset'} textAlign={'center'} variant="caption" lineHeight={1.3}>
            {props.title ?? t(`map:layer_${props.mapType}`)}
          </Typography>
        </Box>
        {props.mapProvider === 'mapbox' && !mapboxMapsEnabled && <img style={{
          position: 'absolute',
          right: -10,
          top: -10,
          width: '30px',
          height: '30px',
        }} src={Assets.shield} />}
      </Box>
      <Dialog
        open={confirmOpened}
        onClose={closeConfirmModal}
      >
        <DialogTitle>
          {t('map:modal_switch_to_subscription_title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('map:modal_switch_to_subscription_message')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmModal}>{t('common:button.cancel')}</Button>
          <Button onClick={onSelectConfirmed} autoFocus>
            {t('common:button.continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
