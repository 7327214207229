import { RIGHT } from '../enum';

export type ICheckRights = (rights: string[]) => boolean;

interface IRightsManager {
  [key: string]: ICheckRights;
}

const createCheckRightsFunction = (right: string): ICheckRights => {
  return (rights: string[]) => rights.some((r) => r === right);
};

export const RightsManager: IRightsManager = {
  canDeleteDistrict: createCheckRightsFunction(RIGHT.CAN_DELETE_DISTRICT),
  canUpdateDistrict: createCheckRightsFunction(RIGHT.CAN_UPDATE_DISTRICT),
  canUnshareEntries: createCheckRightsFunction(RIGHT.CAN_UNSHARE_ENTRIES),
  canCreatePois: createCheckRightsFunction(RIGHT.CAN_CREATE_POIS),
  canDeletePois: createCheckRightsFunction(RIGHT.CAN_DELETE_POIS),
  canInviteUsers: createCheckRightsFunction(RIGHT.CAN_INVITE_USERS),
  canUninviteUsers: createCheckRightsFunction(RIGHT.CAN_UNINVITE_USERS),
  canPostEntries: createCheckRightsFunction(RIGHT.CAN_POST_ENTRIES),
  canSeeHarvestTag: createCheckRightsFunction(RIGHT.CAN_SEE_HARVEST_TAG),
  canCreateTasks: createCheckRightsFunction(RIGHT.CAN_CREATE_TASKS),
  canUpdateTasks: createCheckRightsFunction(RIGHT.CAN_UPDATE_TASKS),
  canDeleteTasks: createCheckRightsFunction(RIGHT.CAN_DELETE_TASKS),
  canCreateCustomMarks: createCheckRightsFunction(RIGHT.CAN_CREATE_CUSTOM_MARKS),
  canUpdateCustomMarks: createCheckRightsFunction(RIGHT.CAN_UPDATE_CUSTOM_MARKS),
  canDeleteCustomMarks: createCheckRightsFunction(RIGHT.CAN_DELETE_CUSTOM_MARKS),
  canDeleteGroup: createCheckRightsFunction(RIGHT.CAN_DELETE_GROUP),
  canUpdateGroup: createCheckRightsFunction(RIGHT.CAN_UPDATE_GROUP),
  canPostPosts: createCheckRightsFunction(RIGHT.CAN_POST_POSTS),
  canDeletePosts: createCheckRightsFunction(RIGHT.CAN_DELETE_POSTS),
  canCreateBookings: createCheckRightsFunction(RIGHT.CAN_CREATE_BOOKING),
  canDeleteBookings: createCheckRightsFunction(RIGHT.CAN_DELETE_BOOKING),
  canBlockPoi: createCheckRightsFunction(RIGHT.CAN_CREATE_BLOCKING),
  canUnblockPoi: createCheckRightsFunction(RIGHT.CAN_DELETE_BLOCKING),
  canCreateMapPath: createCheckRightsFunction(RIGHT.CAN_CREATE_MAP_PATHS),
  canUpdateMapPath: createCheckRightsFunction(RIGHT.CAN_UPDATE_MAP_PATHS),
  canDeleteMapPath: createCheckRightsFunction(RIGHT.CAN_DELETE_MAP_PATHS),
  canUpdateEvents: createCheckRightsFunction(RIGHT.CAN_UPDATE_EVENTS),
  canSeeMembers: createCheckRightsFunction(RIGHT.CAN_SEE_MEMBERS),
  canChangeRole: createCheckRightsFunction(RIGHT.CAN_CHANGE_ROLE),
  canDeleteEvents: createCheckRightsFunction(RIGHT.CAN_DELETE_EVENTS),
  canUpdateEventStatus: createCheckRightsFunction(RIGHT.CAN_UPDATE_EVENT_STATUS),
  canUpdatePoi: createCheckRightsFunction(RIGHT.CAN_UPDATE_POI),
  canCreateSubzone: createCheckRightsFunction(RIGHT.CAN_CREATE_SUBZONE),
  canUpdateSubzone: createCheckRightsFunction(RIGHT.CAN_UPDATE_SUBZONE),
  canDeleteSubzone: createCheckRightsFunction(RIGHT.CAN_DELETE_SUBZONE),
  canUpdateEntries: createCheckRightsFunction(RIGHT.CAN_UPDATE_ENTRIES),
  canDeleteEntries: createCheckRightsFunction(RIGHT.CAN_DELETE_ENTRIES),
  canCreateQuotaPlan: createCheckRightsFunction(RIGHT.CAN_CREATE_QUOTA_PLAN),
  canUpdateQuotaPlan: createCheckRightsFunction(RIGHT.CAN_UPDATE_QUOTA_PLAN),
  canDeleteQuotaPlan: createCheckRightsFunction(RIGHT.CAN_DELETE_QUOTA_PLAN)
};
