import { inject } from 'inversify';

import { singleton } from '../../inversify/decorator';
import { isAuthUserResponseDto } from '../../modules/auth/helpers';
import {
  ApplePostRequestDto, SocialPostRequestDto, UserLoginResponseDto, UserPatchResetPasswordDto,
  UserPostForgotPasswordDto, UserPostLoginRequestDto, UserPostRegisterRequestDto
} from '../../shared/dto';
import { AuthUserResponseDto } from '../../shared/dto/authUser.response.dto';
import {
  GroupSubscriptionInvitationRequestDto
} from '../../shared/dto/subscription/groupSubscriptionInvitation.post.request.dto';
import { AsyncTask } from '../async/AsyncTask';
import { UserLoginResponseModel } from '../model/UserLoginResponseModel';
import { AjaxService, IHttpResponse } from '../service/AjaxService';
import { Proxy } from './Proxy';

@singleton()
export class AuthProxy extends Proxy {

  constructor(@inject(AjaxService) private readonly ajax: AjaxService) {
    super();
  }

  public login = new AsyncTask(async (data: UserPostLoginRequestDto) => {
    const result = await this.ajax.post<UserLoginResponseDto>('auth/login', data);
    return this.processLoginResponse(result);
  });

  public loginAuthViaFacebook = new AsyncTask(async (data: SocialPostRequestDto) => {
    data.web = true;
    const result = await this.ajax.post<UserLoginResponseDto>('auth/facebook', data);
    return this.processLoginResponse(result);
  });

  public loginAuthViaApple = new AsyncTask(async (data: ApplePostRequestDto) => {
    data.web = true;
    const result = await this.ajax.post<UserLoginResponseDto>('auth/apple', data);
    return this.processLoginResponse(result);
  });

  public loginAuthViaGoogle = new AsyncTask(async (data: SocialPostRequestDto) => {
    data.web = true;
    const result = await this.ajax.post<UserLoginResponseDto | AuthUserResponseDto>('auth/google', data);
    return this.processLoginResponse(result);
  });

  public recover = async () => {
    const result = await this.ajax.get<UserLoginResponseDto>('auth/recover');
    return this.processLoginResponse(result);
  }

  public logout = new AsyncTask(async (): Promise<IHttpResponse<void>> => {
    return this.ajax.get('auth/logout');
  });

  public register = new AsyncTask(async (body: UserPostRegisterRequestDto) => {
    const result = await this.ajax.post<UserLoginResponseDto>('auth/register', body);
    return this.processLoginResponse(result);
  });

  public acceptGroupInvitation = new AsyncTask(async (body: GroupSubscriptionInvitationRequestDto) => {
    const result = await this.ajax.post<UserLoginResponseDto>('auth/accept-invitation', body);
    return this.processLoginResponse(result);
  })

  public forgotPassword = new AsyncTask(async (body: UserPostForgotPasswordDto): Promise<IHttpResponse<void>> => {
    const response = await this.ajax.post<void>('auth/forgot-password', body);
    return response;
  });

  public resetPassword = new AsyncTask(async (tokenId: string, body: UserPatchResetPasswordDto): Promise<IHttpResponse<void>> => {
    return this.ajax.patch<void>(`auth/reset-password/${tokenId}`, body);
  });

  private processLoginResponse = (result: IHttpResponse<UserLoginResponseDto | AuthUserResponseDto>): IHttpResponse<UserLoginResponseModel | AuthUserResponseDto> => {
    if (result.ok) {

      if (isAuthUserResponseDto(result.data)) {
        return this.withData(result, { ...result.data });
      }

      return this.withData(result, UserLoginResponseModel.fromDto(result.data));
    }

    return result;
  }
}
