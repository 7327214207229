import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, Grid, IconButton, Input, Typography } from '@mui/material';

import { useVm } from '../../../../../domain/hooks/useVm';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { OptionalTooltip } from '../../../../../toolkit/components/OptionalTooltip';
import { CustomizedMenu } from '../../entry/view-entry/components/CustomizedMenu';
import { ProSubzone } from '../ProSubzone';
import { SubzoneTypeSelection } from '../SubzoneTypeSelection';
import { ViewSubzoneBorders } from './ViewSubzoneBorders';
import { ViewSubzoneInfo } from './ViewSubzoneInfo';
import { ISubzoneTab, SubzoneTabType, ViewSubzoneProps, ViewSubzoneVm } from './ViewSubzoneVm';
import { ViewSubzoneAttributedTo } from './attributed-to/ViewSubzoneAttributedTo';

export const ViewSubzone = observer(function ViewSubzone(props: ViewSubzoneProps) {
  const vm = useVm(ViewSubzoneVm, props);
  const { t } = useTranslation();

  const belongingDistrict = useMemo(() => {
    return props.districtsVm.allDistricts.find(d => d.id === props.subzone.districtId);
  }, [props.districtsVm.allDistricts, props.subzone.districtId]);

  const onPathDelete = useCallback(() => {
    props.onDelete(props.subzone);
  }, [props]);

  const onPathUpsert = useCallback(() => {
    props.upsertSubzone(props.subzone);
  }, [props]);

  const handleOnChangeInput = useCallback((e) => {
    if (!vm.canEditAndDeleteSubzone) {
      return vm.notification.info(t('subzone:wrong_permission'));
    }
    props.subzone.setTitle(e.target.value);
  }, [props.subzone, t, vm.canEditAndDeleteSubzone, vm.notification]);

  if (!vm.hasProSubzones) {
    return <ProSubzone
      onClose={props.onClose}
      label={props.subzone.id
        ? t('subzone:subzone_label')
        : t('subzone:create_subzone_label')
      }
    />;
  }

  return (
    <Box height="inherit" display="flex" flexDirection="column" position={'relative'}>
      <Box sx={{ overflowY: 'auto', paddingBottom: '70px' }}>
        {/* HEADER */}
        <Grid container direction="column" justifyContent="center"
          sx={{
            background: vm.backgroundColor,
            color: colors.paper,
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Grid item container direction={'row'} justifyContent={'flex-end'} paddingLeft={4}>
            <IconButton color="inherit" onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item container paddingX={2} marginTop={3} paddingBottom={1}>
            <Grid item xs={11}>
              <Input
                fullWidth
                value={props.subzone.title}
                onChange={handleOnChangeInput}
                placeholder={t('subzone:subzone_header_placeholder')}
                disableUnderline
                autoFocus
                readOnly={vm.currentTab.type === SubzoneTabType.TYPE}
                sx={{
                  fontSize: '24px',
                  color: 'white',
                  fontWeight: '600',
                  borderBottom: `1px solid ${vm.currentTab.type !== SubzoneTabType.TYPE ? 'white' : 'transparent'}`,
                }}
              />
              <Typography variant='caption'>{belongingDistrict?.name}</Typography>
            </Grid>
            {props.subzone.id && <Grid item xs={1} alignSelf={'center'}>
              <CustomizedMenu
                locizeNamespace="subzone"
                onDelete={onPathDelete}
                onUpsert={onPathUpsert}
                item={props.subzone}
                canEditAndDeleteSubzone={vm.canEditAndDeleteSubzone}
              />
            </Grid>}
          </Grid>
        </Grid>
        {/* TABS */}
        <Grid
          container
          alignItems="center"
          spacing={1}
          paddingX={2}
          paddingY={2}
          sx={{
            maxWidth: 'inherit',
            boxShadow: '0 8px 3px -6px #00000033',
          }}>
          {vm.tabs.map((tab: ISubzoneTab) => {
            return (
              <Grid item key={`subzone-tab-${tab.type}`}>
                <Chip
                  clickable
                  variant="outlined"
                  label={t(`subzone:${tab.type}`)}
                  onClick={() => vm.setCurrentTab(tab)}
                  style={vm.currentTab.type === tab.type
                    ? {
                      borderColor: colors.green[800],
                      color: colors.green[800],
                      backgroundColor: colors.green[50],
                    }
                    : {}} />
              </Grid>
            );
          })}
        </Grid>
        <Grid container>
          {vm.currentTab.type === SubzoneTabType.TYPE && <SubzoneTypeSelection subzone={props.subzone} canEditAndDeleteSubzone={vm.canEditAndDeleteSubzone} />}
          {vm.currentTab.type === SubzoneTabType.BORDERS && <ViewSubzoneBorders />}
          {vm.currentTab.type === SubzoneTabType.INFO && <ViewSubzoneInfo subzone={props.subzone} canEditAndDeleteSubzone={vm.canEditAndDeleteSubzone} />}
          {vm.currentTab.type === SubzoneTabType.ATTRIBUTED_TO && <ViewSubzoneAttributedTo
            subzone={props.subzone}
            districts={props.districtsVm.allDistricts}
            onSelectDistrict={props.districtsVm.setSelectedDistrict}
            canEditAndDeleteSubzone={vm.canEditAndDeleteSubzone}
          />}
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent={'center'}
        alignItems={'center'}
        position={'absolute'}
        bottom={0}
        bgcolor="white"
        width="100%"
        height={'70px'}
        borderTop={1}
        borderColor={'text.details'}
        paddingX={2}
      >
        <Button onClick={props.onClose} sx={{ paddingX: 7, paddingY: 1, marginRight: 4, }} variant="outlined" color="error">
          <Typography variant="subtitle1">{t('entry:entry_cancel')}</Typography>
        </Button>
        <OptionalTooltip
          enabled={!vm.canBeSaved}
          title={<Typography
            bgcolor={'primary.main'}
            color="white"
            textAlign={'center'}
            borderRadius={1}
            paddingX={2}
            paddingY={1}
          >
            {vm.canEditAndDeleteSubzone ? t('subzone:subzone_data_incomplete') : t('subzone:wrong_permission')}
          </Typography>}
        >
          <span>
            <LoadingButton
              loading={props.onSave.isBusy}
              disabled={!vm.hasNextTab && !vm.canBeSaved}
              onClick={vm.nextOrSave}
              sx={{ paddingX: 7, paddingY: 1, }} variant="contained" color="secondary"
            >
              <Typography variant="subtitle1">
                {(vm.hasNextTab) ? t('entry:entry_next_tab') : t('entry:entry_save')}
              </Typography>
            </LoadingButton>
          </span>
        </OptionalTooltip>
      </Box>
    </Box>
  );
});
