import { IsBoolean, IsEnum, IsNotEmpty, IsOptional, IsString, IsUUID } from 'class-validator';

import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';

import { CUSTOM_MARK_COLOR, CUSTOM_MARK_ICON } from '../enum';

export class CustomMarkPostRequestDto {

  @IsString()
  @IsNotEmpty()
  @ApiProperty()
  public name: string;

  @IsOptional()
  @IsUUID()
  @ApiProperty()
  public districtId?: string;

  @IsEnum(CUSTOM_MARK_ICON)
  @ApiProperty()
  public foreground: string;

  @IsEnum(CUSTOM_MARK_COLOR)
  @ApiProperty()
  public background: string;

  @IsOptional()
  @IsBoolean()
  @ApiPropertyOptional()
  public isBookable?: boolean;

  @IsOptional()
  @IsBoolean()
  @ApiPropertyOptional()
  public canHaveTasks?: boolean;

  @IsOptional()
  @IsBoolean()
  @ApiPropertyOptional()
  public hasShootingDirection?: boolean;
}
