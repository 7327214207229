import { observer } from 'mobx-react-lite';
import React from 'react';

import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import { useTablePagination } from '../../../../../domain/hooks/useTablePagination';
import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import { USER_ROLE_IN_GROUP } from '../../../../../shared/enum';
import { TableLayout } from '../../../../../toolkit/components/table/TableLayout';
import { AssignAreaMembersModalVm } from '../assign-area-members-modal/AssignAreaMembersModalVm';
import { AssignAreaMembersTableHead } from './AssignAreaMembersTableHead';
import { AssignAreaMembersTableRow } from './AssignAreaMembersTableRow';

interface IAssignAreaMembersTableProps {
  vm: AssignAreaMembersModalVm;
  candidatesForAssignment: GroupSubscriptionUserModel[];
}

export const AssignAreaMembersTable = observer(function AssignAreaMembersTable({ vm, candidatesForAssignment }: IAssignAreaMembersTableProps) {
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, emptyRows, visibleRows } = useTablePagination<GroupSubscriptionUserModel>(5);

  const selectableMembers = React.useMemo(() =>
    candidatesForAssignment.filter(member =>
      member.userRoleInGroup !== USER_ROLE_IN_GROUP.MEMBER &&
      member.userRoleInGroup !== USER_ROLE_IN_GROUP.OWNER
    ), [candidatesForAssignment]);


  const handleSelectAllClick = React.useCallback(() => {
    const isAllSelected = selected.length === selectableMembers.length;

    const newSelected = isAllSelected ? [] : selectableMembers.map(n => n.id);

    setSelected(newSelected);
    vm.setTempUsers(new Set(candidatesForAssignment.filter(member => newSelected.includes(member.id))));
  }, [candidatesForAssignment, selectableMembers, selected.length, vm]);


  const handleClick = React.useCallback((_event: React.MouseEvent<unknown>, id: string) => {
    const newSelected = new Set(selected);
    if (newSelected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }

    const selectedIds = Array.from(newSelected);
    setSelected(selectedIds);
    vm.setTempUsers(
      new Set(candidatesForAssignment.filter(member => newSelected.has(member.id)))
    );
  }, [candidatesForAssignment, selected, vm]);

  return (
    <TableLayout
      count={candidatesForAssignment.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    >
      <AssignAreaMembersTableHead
        numSelected={selected.length}
        onSelectAllClick={handleSelectAllClick}
        rowCount={candidatesForAssignment.length}
      />
      <TableBody>
        {visibleRows(candidatesForAssignment).map((row) => {
          const isSelected = selected.indexOf(row.id) !== -1;
          return (
            <AssignAreaMembersTableRow
              key={row.id}
              row={row}
              isSelected={isSelected}
              handleClick={handleClick}
            />
          );
        })}
        {emptyRows(candidatesForAssignment.length) > 0 && (
          <TableRow style={{ height: 53 * emptyRows(candidatesForAssignment.length) }}>
            <TableCell colSpan={6} />
          </TableRow>
        )}
      </TableBody>
    </TableLayout>
  );
});
