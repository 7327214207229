import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';
import { IsBoolean, IsDateString, IsEnum, IsNumber, IsOptional, IsString, IsUUID } from 'class-validator';
import { FALLWILD_TYPE } from '../enum';


export class DeprecatedEntryPutRequestDto {
  @IsUUID()
  @ApiProperty({ required: true })
  public id: string;

  @IsOptional()
  @IsBoolean()
  @ApiPropertyOptional()
  public isPrivate?: boolean;

  @IsOptional()
  @IsNumber()
  @ApiPropertyOptional()
  public lat?: number;

  @IsOptional()
  @IsNumber()
  @ApiPropertyOptional()
  public long?: number;

  @IsOptional()
  @ApiPropertyOptional()
  public meta?: any;

  @IsOptional()
  @IsString()
  @ApiPropertyOptional()
  public classification?: string;

  @IsOptional()
  @IsEnum(FALLWILD_TYPE)
  @ApiPropertyOptional()
  public fallwildType?: string;

  @IsOptional()
  @IsNumber()
  @ApiPropertyOptional()
  public count?: number;

  @IsOptional()
  @IsString()
  @ApiPropertyOptional()
  public description?: string;

  @IsOptional()
  @IsDateString()
  @ApiPropertyOptional()
  public userDate?: string;
}
