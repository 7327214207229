import { IsEmail, IsEnum, IsOptional } from 'class-validator';

import { ApiProperty } from '@nestjs/swagger';

import { LANGUAGE } from '../enum';

export class UserPostForgotPasswordDto {

  @IsEmail({}, {context: {errorCode: 422}})
  @ApiProperty({ description: 'should be a valid email'})
  public email!: string;

  @IsOptional()
  @IsEnum(LANGUAGE)
  @ApiProperty()
  public language?: LANGUAGE;
}
