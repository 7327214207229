import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AcceptGroupInvite } from '../modules/auth/accept-group-invite/AcceptGroupInvite';
import {
  AnonymousSubscriptionPurchaseRoute
} from '../modules/auth/anonymous-subscription-purchase/AnonymousSubscriptionPurchaseRoute';
import { AnonymousRoute } from '../modules/auth/AnonymousRoute';
import { ForgotPassword } from '../modules/auth/forgot-password/ForgotPassword';
import { LoginRoute } from '../modules/auth/login/LoginRoute';
import { NewPassword } from '../modules/auth/new-password/NewPassword';
import {
  EmailRegistrationRoute
} from '../modules/auth/register/components/email-registration/EmailRegistrationRoute';
import {
  SocialMediaRegistrationRoute
} from '../modules/auth/register/components/social-media-registration/SocialMediaRegistrationRoute';
import { RegistrationRoute } from '../modules/auth/register/RegistrationRoute';
import { DownloadAppRoute } from '../modules/download-app/DownloadAppRoute';
import { ImageGalleryRoute } from '../modules/image-gallery/ImageGalleryRoute';
import { MapRoute } from '../modules/map/MapRoute';
import {
  SuccessfulPaymentEmbeddedRoute
} from '../modules/pricing/components/successful-payment/SuccessfulPaymentEmbedded';
import {
  SuccessfulPaymentRoute
} from '../modules/pricing/components/successful-payment/SuccessfulPaymentRoute';
import { GroupSubscription } from '../modules/pricing/group-subscription/GroupSubscription';
import { ManageGroup } from '../modules/pricing/manage-group/ManageGroup';
import { PricingRoute } from '../modules/pricing/PricingRoute';
import {
  UpdateGroupSubscription
} from '../modules/pricing/update-group-subscription/UpdateGroupSubscription';
import { UserProfileRoute } from '../modules/user-profile/UserProfileRoute';
import { Analytics } from './Analytics';
import { AppRoutes } from './AppRoutesEnum';
import { AuthenticatedRoute } from './AuthenticatedRoute';

export function Router() {

  return (
    <BrowserRouter>
      <Analytics>
        <Routes>
          <Route path={AppRoutes.Login} element={
            <AnonymousRoute>
              <LoginRoute />
            </AnonymousRoute>}
          />
          <Route path={AppRoutes.Register} element={
            <AnonymousRoute>
              <RegistrationRoute />
            </AnonymousRoute>}
          />
          <Route path={AppRoutes.EmailRegister} element={
            <AnonymousRoute>
              <EmailRegistrationRoute />
            </AnonymousRoute>}
          />
          <Route path={AppRoutes.SocialMediaRegister} element={
            <AnonymousRoute>
              <SocialMediaRegistrationRoute />
            </AnonymousRoute>}
          />
          <Route path={AppRoutes.AcceptGroupInvite} element={
            <AnonymousRoute>
              <AcceptGroupInvite />
            </AnonymousRoute>}
          />
          <Route path={AppRoutes.ForgotPassword} element={
            <AnonymousRoute>
              <ForgotPassword />
            </AnonymousRoute>}
          />
          <Route path={AppRoutes.NewPassword} element={
            <AnonymousRoute>
              <NewPassword />
            </AnonymousRoute>}
          />
          <Route path={AppRoutes.SubscriptionPurchase} element={<AnonymousSubscriptionPurchaseRoute />} />

          <Route path={AppRoutes.DownloadAppRoute} element={
            <AuthenticatedRoute>
              <DownloadAppRoute />
            </AuthenticatedRoute>}
          />
          <Route path={AppRoutes.UserProfile} element={
            <AuthenticatedRoute>
              <UserProfileRoute />
            </AuthenticatedRoute>}
          />
          <Route path={AppRoutes.Pricing} element={
            <AuthenticatedRoute>
              <PricingRoute />
            </AuthenticatedRoute>}
          />
          <Route path={AppRoutes.PricingEmbedded} element={
            <AuthenticatedRoute>
              <PricingRoute />
            </AuthenticatedRoute>}
          />
          <Route path={AppRoutes.GroupSubscription} element={
            <AuthenticatedRoute>
              <GroupSubscription />
            </AuthenticatedRoute>}
          />
          <Route path={AppRoutes.SuccessfulPayment} element={
            <AuthenticatedRoute>
              <SuccessfulPaymentRoute />
            </AuthenticatedRoute>
          } />
          <Route path={AppRoutes.SuccessfulPaymentUnauthorized} element={
            <SuccessfulPaymentRoute />
          } />
          <Route path={AppRoutes.SuccessfulPaymentEmbedded} element={
            <AuthenticatedRoute>
              <SuccessfulPaymentEmbeddedRoute />
            </AuthenticatedRoute>}
          />
          <Route path={AppRoutes.Map} element={
            <AuthenticatedRoute>
              <MapRoute />
            </AuthenticatedRoute>}
          >
            <Route path=":districtId" element={null} />
          </Route>
          <Route path={AppRoutes.Gallery} element={
            <AuthenticatedRoute>
              <ImageGalleryRoute />
            </AuthenticatedRoute>}
          />
          <Route path={AppRoutes.ManageGroup} element={
            <AuthenticatedRoute>
              <ManageGroup />
            </AuthenticatedRoute>}
          />
          <Route path={AppRoutes.AddMembersToGroup} element={
            <AuthenticatedRoute>
              <UpdateGroupSubscription />
            </AuthenticatedRoute>}
          />

          <Route path="*" element={
            <AuthenticatedRoute>
              <Navigate to={AppRoutes.Map} />
            </AuthenticatedRoute>}
          />
        </Routes>
      </Analytics>
    </BrowserRouter>
  );
}
