import { observer } from 'mobx-react-lite';

import { Assets } from '../../../../../assets';
import { useVm } from '../../../../../domain/hooks/useVm';
import { OptionalTooltip } from '../../../../../toolkit/components/OptionalTooltip';
import { usePinSizeContext } from '../../google-map/hooks/usePinSizeContext';
import { Marker } from '../../Marker';
import { MarkerTopLeftIcon } from '../../poi/MarkerTopLeftIcon';
import { EntryTooltip } from '../EntryTooltip';
import { EntryMarkerProps, EntryMarkerVm } from './EntryMarkerVm';

export const EntryMarker = observer(function EntryView(props: EntryMarkerProps) {
  const vm = useVm(EntryMarkerVm, props);
  const { pinWidth } = usePinSizeContext();

  return (
    <OptionalTooltip enabled={props.tooltipEnabled} arrow placement='top' title={<EntryTooltip vm={vm} />}>
      <Marker
        onClick={() => props.onClick(props.entry)}
        lat={props.lat}
        lng={props.lng}
        icon={vm.entryIcon}
        opacity={props.opacity}
      >
        {props.entry.fallwildType && <MarkerTopLeftIcon opacity={props.opacity} width={(pinWidth * 0.50)}>
          <img
            style={{
              width: '100%',
              opacity: props.opacity,
              zIndex: 4,
            }}
            src={Assets.poi.blocked}
          />
        </MarkerTopLeftIcon>}
      </Marker>
    </OptionalTooltip>
  );
});
