import { IsAlphanumeric, IsEmail, IsEnum, IsNumberString, IsString } from 'class-validator';

import { ApiProperty } from '@nestjs/swagger';

import { COUNTRY_CODE } from '../enum/countryCode.enum';
import { GENDER } from '../enum/gender.enum';

export class AddressDto {
  @ApiProperty()
  @IsEnum(GENDER)
  public gender: GENDER;

  @ApiProperty()
  @IsString()
  public firstName: string;

  @ApiProperty()
  @IsString()
  public lastName: string;

  @ApiProperty()
  @IsEmail()
  public email: string;

  @ApiProperty()
  @IsString()
  public streetName: string;

  @ApiProperty()
  @IsAlphanumeric()
  public houseNumber: string;

  @ApiProperty()
  @IsString()
  public postalCode: string;

  @ApiProperty()
  @IsString()
  public city: string;

  @ApiProperty()
  @IsEnum(COUNTRY_CODE)
  public country: COUNTRY_CODE;

  @ApiProperty()
  @IsNumberString()
  public birthdayDay: string;

  @ApiProperty()
  @IsNumberString()
  public birthdayMonth: string;

  @ApiProperty()
  @IsNumberString()
  public birthdayYear: string;

}
