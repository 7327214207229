import { AppBar, Grid, MenuItem, Select, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { languageList } from '../constants/LanguageList';

const useStyles = makeStyles(() => ({
  removeBoxShadow: {
    boxShadow: '0px 0px 0px 0px',
  },
  styleSelect: {
    borderRadius: '7px',
    padding: '8px',
    minWidth: '128px',
    backgroundColor: 'white',
  },
}));

export function LanguageSwitcher() {

  const classes = useStyles();
  const { i18n, t } = useTranslation();

  return (
    <AppBar
      position="static"
      color="transparent"
      className={classes.removeBoxShadow}
    >
      <Toolbar>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Select
              sx={{ height: '48px' }}
              value={i18n.language}
              onChange={(event) =>
                i18n.changeLanguage(event.target.value as string)
              }
              className={classes.styleSelect}
            >
              {languageList.map((language, i) => {
                return (
                  <MenuItem key={i} value={language.value}>
                    {t(language.label)}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );

}
