import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { InformationIcon } from '@HunterCompanion/hunter-design/icons';
import { Box, Divider, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material';

import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { NewSubmitButton } from '../../../toolkit/components/redesign-components/NewSubmitButton';
import { SvgWrapper } from '../../../toolkit/components/SvgWrapper';
import { PriceDisplay } from '../group-subscription/overview/PriceDisplay';
import { UpdateGroupSubscriptionVm } from './UpdateGroupSubscriptionVm';

interface IUpdateGroupPriceOverviewProps {
  updateGroupSubscriptionVm: UpdateGroupSubscriptionVm;
}

export const UpdateGroupPriceOverview = observer(function UpdateGroupPriceOverview({ updateGroupSubscriptionVm }: IUpdateGroupPriceOverviewProps) {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();
  const {
    currency,
    assignedMembers,
    emptySeatDiscount,
    assignedMembersPriceWithoutDiscount,
    assignedMembersDiscount,
    groupTotalPriceAfterDiscount,
    groupTotalPriceWithoutDiscount,
    updateGroupSubscription,
    taxRate,
    endDate,
    prepaidSeatsFilled,
    additionalAssignedUsers,
    finalAmountDue,
    startingBalance,
    adjustedBalance,
  } = updateGroupSubscriptionVm;

  const priceDisplayForEmptySeats = useMemo(() => (
    <PriceDisplay
      isDiscounted={true}
      originalPrice={emptySeatDiscount}
      finalPrice={0.00}
      currency={currency}
    />
  ), [emptySeatDiscount, currency]);

  const priceDisplayForAdditionalUsers = useMemo(() => (
    <PriceDisplay
      isDiscounted={assignedMembersDiscount !== 0}
      originalPrice={assignedMembersPriceWithoutDiscount}
      finalPrice={groupTotalPriceAfterDiscount}
      currency={currency}
    />
  ), [assignedMembersDiscount, assignedMembersPriceWithoutDiscount, groupTotalPriceAfterDiscount, currency]);

  const prepaidSeatsDisplay = prepaidSeatsFilled !== 0 && (
    <Grid item container justifyContent={'space-between'}>
      <Grid item>
        <Typography variant={isMobileSize ? 'text_md_semibold' : 'text_sm_semibold'} color={theme.palette.text.primary}>
          {prepaidSeatsFilled}x
        </Typography>
        &nbsp;
        <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
          {t('group_subscription:price.overview.empty_seat')}
        </Typography>
      </Grid>
      <Grid item>
        {priceDisplayForEmptySeats}
      </Grid>
    </Grid>
  );

  const additionalAssignedUsersDisplay = additionalAssignedUsers !== 0 && (
    <Grid item container justifyContent={'space-between'}>
      <Grid item>
        <Typography variant={isMobileSize ? 'text_md_semibold' : 'text_sm_semibold'} color={theme.palette.text.primary}>
          {additionalAssignedUsers}x
        </Typography>
        &nbsp;
        <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
          {t('group_subscription:table.table_row.role.member')}
        </Typography>
      </Grid>
      <Grid item>
        {priceDisplayForAdditionalUsers}
      </Grid>
    </Grid>
  );

  const discountTooltip = useMemo(() => {
    if (startingBalance !== 0 && groupTotalPriceAfterDiscount !== 0) {
      return (
        <Tooltip
          title={t('manage_group:table.table_row.price.tooltip_discount.starting_balance', {
            startingBalance: `${currency}${Math.abs(startingBalance)}`,
            additionalDiscount: `${currency}${Math.abs(startingBalance)}`,
            adjustedBalance: `${currency}${adjustedBalance < 0 ? Math.abs(adjustedBalance) : 0}`,
          })}
          aria-label={t('manage_group:table.table_row.price.tooltip_discount.starting_balance')}
          placement='top'
        >
          <span>
            <SvgWrapper icon={InformationIcon} fill={theme.palette.brand?.brand_color} width={20} height={20} />
          </span>
        </Tooltip>
      );
    }
    return null;
  }, [startingBalance, groupTotalPriceAfterDiscount, adjustedBalance, currency, t, theme]);

  const renewalInfoDisplay = useMemo(() => (
    <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.secondary}>
      <Trans
        i18nKey={'group_subscription:price.overview.renew_info'}
        values={{
          amount: `${groupTotalPriceWithoutDiscount}${currency}`,
          endDate: endDate,
        }}
      />
    </Typography>
  ), [isMobileSize, theme.palette.text.secondary, groupTotalPriceWithoutDiscount, currency, endDate]);

  const payButton = useMemo(() => (
    <Grid item xs textAlign={'center'}>
      <NewSubmitButton
        fullWidth
        color="secondary"
        size={isMobileSize ? 'large' : 'small'}
        onClick={updateGroupSubscription.run}
        disabled={assignedMembers.size === 0}
        submitting={updateGroupSubscription.isBusy}
      >
        {t('group_subscription:price.overview.cta')}
      </NewSubmitButton>
    </Grid>
  ), [isMobileSize, updateGroupSubscription.run, updateGroupSubscription.isBusy, assignedMembers.size, t]);

  return (
    <Grid item container gap={4} justifyContent={'space-between'} rowGap={2}>
      {/* PRICE OVERVIEW */}
      <Grid item xs={12} sm={6} container direction={'column'} rowGap={2}>
        <Grid item>
          <Typography variant={isMobileSize ? 'headingSubtitleMobile' : 'text_md_semibold'} color={theme.palette.text.secondary}>
            {t('group_subscription:price.overview.title')}
          </Typography>
        </Grid>
        <Grid item container rowGap={1}>
          {prepaidSeatsDisplay}
          {additionalAssignedUsersDisplay}
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container gap={2} paddingBottom={2}>
          {/* FINAL PRICE FOR CURRENT UPGRADE */}
          <Grid item container justifyContent={'space-between'}>
            <Grid item>
              <Typography variant='text_md_semibold' color={theme.palette.text.primary}>{t('group_subscription:price.overview.total_label')}</Typography>
            </Grid>
            <Grid item textAlign={'right'}>
              <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'} justifyContent={'flex-end'}>
                {/* If a discount is applied, show the original price with a strikethrough */}
                {finalAmountDue !== groupTotalPriceAfterDiscount &&
                  <Typography
                    variant={'text_md_regular'}
                    color={theme.palette.text.secondary}
                    sx={{ textDecoration: 'line-through' }}
                  >
                    {groupTotalPriceAfterDiscount}{currency}
                  </Typography>
                }
                &nbsp;
                {/* Show the final amount the user needs to pay*/}
                <Typography variant={'text_md_semibold'} color={theme.palette.text.primary}>
                  {finalAmountDue}{currency}
                </Typography>
                &nbsp;
                {/* Display the discount tooltip (e.g., information about the applied discount or credits) */}
                {discountTooltip}
              </Box>
              <Box>
                {/* Display the applicable tax rate for the final amount */}
                <Typography variant='text_xs_medium' color={theme.palette.text.secondary}>
                  {t('group_subscription:price.overview.vat', { taxRate: taxRate })}
                </Typography>
              </Box>
            </Grid>

            {/* RENEWAL FULL PRICE BILLED ON A DATE */}
            <Grid item xs={12}>
              {renewalInfoDisplay}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='text_sm_regular' color={theme.palette.text.tertiary}>
              {t('group_subscription:price.overview.terms')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* PAY BUTTON */}
      {payButton}

    </Grid>
  );
});
