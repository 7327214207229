import { reaction } from 'mobx';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useContainer } from '../domain/hooks/useContainer';
import { TrackingService } from '../domain/service/tracking/TrackingService';
import { SessionStore } from '../domain/store/SessionStore';

interface IProps {
  children: JSX.Element;
}

export function Analytics(props: IProps) {
  const container = useContainer();
  const tracking = container.get(TrackingService);
  const session = container.get(SessionStore);

  const location = useLocation();
  useEffect(() => {
    tracking.screenChanged(location.pathname);
  }, [tracking, location.pathname]);

  useEffect(() => {
    return reaction(() => session.userId, (id) => {
      tracking.setFirebaseUserId(id ?? '');
    }, { fireImmediately: true });
  }, [tracking, session]);

  return props.children;
}
