import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { appleAuthHelpers, useScript } from 'react-apple-signin-auth';
import { useTranslation } from 'react-i18next';

import AppleIcon from '@mui/icons-material/Apple';
import { SxProps, Theme } from '@mui/material';

import { useVm } from '../../../../domain/hooks/useVm';
import { colors } from '../../../../theme/options/palette/const/colors';
import { SubmitButton } from '../../../../toolkit/components/SubmitButton';
import { AppleLoginButtonVm } from './AppleLoginButtonVm';

export interface IAppleLoginButtonProps {
  sx?: SxProps<Theme>;
  fromLocation?: string;
}

export const AppleLoginButton = observer(function AppleLoginButton(props: IAppleLoginButtonProps) {

  const vm = useVm(AppleLoginButtonVm, props);
  const { t } = useTranslation();
  useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

  const login = useCallback(() => {
    appleAuthHelpers.signIn({
      authOptions: {
        clientId: 'de.hunterco.web',
        scope: 'email name',
        redirectURI: window.location.origin,
        state: 'state',
        usePopup: true,
      },
      onSuccess: vm.appleLogin,
      onError: vm.appleLoginError,
    });
  }, [vm]);

  return (
    <SubmitButton
      size="large"
      onClick={login}
      submitting={vm.isBusy}
      startIcon={<AppleIcon />}
      sx={{
        backgroundColor: colors.apple_btn.basic,
        '&:hover': {
          backgroundColor: colors.apple_btn.hover,
        },
        ...props.sx,
      }}
    >
      {t('auth:socialmedia_button.apple')}
    </SubmitButton>
  );
});
