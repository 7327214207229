

import { observer } from 'mobx-react-lite';

import { Box, Typography } from '@mui/material';

import { IGoogleMapProps } from '../../../../domain/core/IGoogleMapProps';
import { colors } from '../../../../theme/options/palette/const/colors';
import { Marker } from '../Marker';

interface IProps extends IGoogleMapProps {
  name: string;
  onClick: () => void;
}

export const GoogleMapDistrictLabel = observer((props: IProps) => {
  return <Marker
    lat={props.lat}
    lng={props.lng}
    icon={undefined}
    width={0}
    onClick={props.onClick}
    zIndex={4}
  >
    <Box
      sx={{
        bgcolor: 'rgba(0, 0, 0, 0.55)',
        padding: 1,
        borderRadius: 5,
        cursor: 'pointer',
      }}
    >
      <Typography variant='subtitle2' lineHeight={1.15} noWrap color={colors.paper}>{props.name}</Typography>
    </Box>
  </Marker>;
});
