import { ApiPropertyOptional } from '@nestjs/swagger';
import { IsBooleanString, IsDateString, IsEnum, IsNumberString, IsOptional, IsString, IsUUID, ValidateIf } from 'class-validator';
import { FALLWILD_TYPE } from '../enum';


export class DeprecatedEntryPutRequestDtoTmp {
  @IsUUID()
  @ApiPropertyOptional()
  public id: string;

  @IsOptional()
  @ApiPropertyOptional()
  @ValidateIf((o) => o.lat !== '')
  @IsUUID()
  public districtId?: string;

  @IsOptional()
  @ApiPropertyOptional()
  @IsBooleanString()
  public isPrivate?: string;

  @IsOptional()
  @ApiPropertyOptional()
  @ValidateIf((o) => o.lat !== '')
  @IsNumberString()
  public lat?: string;

  @IsOptional()
  @ApiPropertyOptional()
  @ValidateIf((o) => o.lat !== '')
  @IsNumberString()
  public long?: string;

  @IsOptional()
  @ApiPropertyOptional()
  public meta?: string;

  @IsOptional()
  @ApiPropertyOptional()
  @IsString()
  public classification?: string;

  @IsOptional()
  @IsEnum(FALLWILD_TYPE)
  @ApiPropertyOptional()
  public fallwildType?: string;

  @IsOptional()
  @ApiPropertyOptional()
  @IsNumberString()
  public count?: string;

  @IsOptional()
  @ApiPropertyOptional()
  @IsString()
  public description?: string;

  @IsOptional()
  @ApiPropertyOptional()
  @IsDateString()
  public userDate?: string;
}
