import { makeObservable, observable } from 'mobx';

import {
  StripePaymentMethodResponseDto
} from '../../../shared/dto/stripe/stripe.payment.method.response.dto';
import {
  STRIPE_PAYMENT_METHOD_TYPE
} from '../../../shared/enum/stripe/stripePaymentMethodType.enum';

export class StripePaymentMethodModel {

  @observable
  public type: STRIPE_PAYMENT_METHOD_TYPE = STRIPE_PAYMENT_METHOD_TYPE.CARD;

  @observable
  public billingEmail: string | null = null;

  @observable
  public billingName: string | null = null;

  @observable
  public brand: string | null = null;

  @observable
  public country: string | null = null;

  @observable
  public expiresMonth: number | null = null;

  @observable
  public expiresYear: number | null = null;

  @observable
  public funding: string | null = null;

  @observable
  public last4: string | null = null;

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: StripePaymentMethodResponseDto) => {
    const model = new StripePaymentMethodModel();
    model.type = dto.type;
    model.billingEmail = dto.billingEmail;
    model.billingName = dto.billingName;
    model.brand = dto.brand;
    model.country = dto.country;
    model.expiresMonth = dto.expiresMonth;
    model.expiresYear = dto.expiresYear;
    model.funding = dto.funding;
    model.last4 = dto.last4;

    return model;
  }
}
