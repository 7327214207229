import React from 'react';

export const VerifiedIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="108.082"
      height="108.082"
      viewBox="0 0 108.082 108.082"
    >
      <path
        id="icons8-checked"
        d="M57.041,3a54.041,54.041,0,1,0,54.041,54.041,47.282,47.282,0,0,0-2.858-17.018l-6.755,6.625a42.07,42.07,0,0,1,1.3,10.392A45.727,45.727,0,1,1,57.041,11.314,44.524,44.524,0,0,1,88.608,24.175l5.846-5.846A52.732,52.732,0,0,0,57.041,3Zm46.9,17.8-46.9,46.9-17.8-17.8-5.976,5.976L54.053,76.657l2.988,2.858,2.988-2.858,49.884-49.884Z"
        transform="translate(-3 -3)"
        fill="#0f7761"
      />
    </svg>
  );
};
