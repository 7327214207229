import { Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useVm } from '../../../../../../../domain/hooks/useVm';
import { DistrictPoiListItem } from './DistrictPoiListItem';
import { IViewDistrictPoisProps, ViewDistrictPoisVm } from './ViewDistrictPoisVm';

export const ViewDistrictPois = observer(function ViewDistrictPois(props: IViewDistrictPoisProps) {
  const vm = useVm(ViewDistrictPoisVm, props);

  return (
    <Box width="100%">
      <Box sx={{ maxWidth: '100%', boxShadow: 1, }}>
        <Tabs
          value={vm.activeTab}
          indicatorColor="secondary"
          onChange={vm.changeTab}
          variant={vm.tabs.length > 2 ? 'scrollable' : 'fullWidth'}
          scrollButtons
          sx={{
            '.MuiTabs-scrollButtons.Mui-disabled': {
              opacity: 0.3,
            }
          }}
        >
          {vm.tabs.map((t) => <Tab
            key={`poi-tab-${t.key}`}
            value={t.key}
            label={`${t.text} (${t.count})`}
          />)}
        </Tabs>
      </Box>
      <Box paddingX={2}>
        {vm.pois.map((poi, index) => <DistrictPoiListItem
          key={`district-poi-${poi.id}`}
          poi={poi}
          lastItem={index >= (vm.pois.length - 1)}
          onEdit={props.onEdit}
          onDelete={props.onDelete}
          canEditAndDeletePoi={vm.canEditAndDeletePoi(poi)}
        />)}
      </Box>
    </Box>
  );
});
