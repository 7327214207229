import { inject } from 'inversify';
import { computed, makeObservable } from 'mobx';
import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { I18nService } from '../../../../../../domain/service/I18nService';
import { transient } from '../../../../../../inversify/decorator';
import { FALLWILD_TYPE, HARVEST_TYPE } from '../../../../../../shared/enum';
import { EntryHelper } from '../../../../../../util/EntryHelper';
import { EntryFallwildProps } from './EntryFallwild';


export interface IFallwildListItem {
  key: string,
  value: string
}

export interface SectionList<ItemT> {
  data: ReadonlyArray<ItemT>;
  title: string;
}

@transient()
export class EntryFallwildVm extends ViewModel<EntryFallwildProps> {

  constructor(
    @inject(I18nService) public readonly i18n: I18nService,
    @inject(EntryHelper) private readonly entryHelper: EntryHelper,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get fallWildTranslation(): string {
    if (this.props.entry.fallwildType) {
      return this.entryHelper.getFallwildTranslation(this.props.entry.fallwildType) ?? '';
    }

    return 'entry:cause_of_death.harvest';
  }

  @computed
  public get fallwildList(): SectionList<IFallwildListItem>[] {
    const sectionList: SectionList<IFallwildListItem>[] = [];

    const harvestTypeList = Object.values(HARVEST_TYPE).map<IFallwildListItem>(harvestType => {
      return {
        key: harvestType,
        value: this.i18n.t(this.entryHelper.getFallwildTranslation(harvestType) ?? '')
      };
    });

    sectionList.push({
      title: this.i18n.t('entry:cause_of_death_list_section_harvest') as string,
      data: harvestTypeList
    });

    const fallwildList = Object.values(FALLWILD_TYPE).map<IFallwildListItem>(fallwildKey => {
      return {
        key: fallwildKey,
        value: this.i18n.t(this.entryHelper.getFallwildTranslation(fallwildKey) ?? '')
      };
    });

    sectionList.push({
      title: this.i18n.t('entry:cause_of_death_list_section_other') as string,
      data: fallwildList
    });

    return sectionList;
  }

  @computed
  public get selectedFallwild(): IFallwildListItem | undefined {

    // Set default fallwild type
    let selectedItem: IFallwildListItem | undefined = {
      key: HARVEST_TYPE.HARVEST,
      value: this.i18n.t('entry:cause_of_death.harvest')
    };

    this.fallwildList.forEach(list => {
      const item = list.data.find(fallwild => fallwild.key === this.props.entry.fallwildType);
      if (item) {
        selectedItem = item;
      }
    });

    return selectedItem;
  }
}
