import { ApiProperty } from '@nestjs/swagger';
import { PURCHASE_STATUS } from '../enum';
import { SubscriptionResponseDto } from './subscription.response.dto';


// tslint:disable:max-classes-per-file
export class SubscriptionStatus {

  @ApiProperty()
  public subscription: SubscriptionResponseDto;

  @ApiProperty()
  public status: PURCHASE_STATUS;
}

export class PaymentStatusResponseDto {
  @ApiProperty({ type: SubscriptionStatus })
  public subscriptions: SubscriptionStatus[];
}
