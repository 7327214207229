import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';
import { IsBoolean, IsDateString, IsOptional, IsString, IsUUID } from 'class-validator';

export class TaskPutRequestDto {

  @IsUUID()
  @ApiProperty()
  public id: string;

  @IsOptional()
  @IsString()
  @ApiProperty()
  public description?: string;

  @IsOptional()
  @IsString()
  @ApiProperty()
  public details?: string | null;

  @IsOptional()
  @IsBoolean()
  @ApiProperty()
  public done?: boolean;

  @IsOptional()
  @IsUUID()
  @ApiPropertyOptional()
  public assigneeId?: string;

  @IsOptional()
  @IsUUID()
  @ApiProperty()
  public poiId?: string | null;

  @IsOptional()
  @IsUUID()
  @ApiProperty()
  public districtId?: string | null;

  @IsUUID()
  @IsOptional()
  @ApiProperty()
  public subzoneId?: string;

  @IsOptional()
  @IsDateString()
  @ApiProperty()
  public dueDate?: string | null;

  @IsOptional()
  @IsUUID(undefined, { each: true })
  @ApiPropertyOptional()
  public assigneesId?: string[];
}
