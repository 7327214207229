import { ApiProperty } from '@nestjs/swagger';
import { DISTRICT_ROLE, EVENT_ROLE, GROUP_ROLE } from '../enum';

export class InvitationCodeResponseDto {

  @ApiProperty()
  code: string;

  @ApiProperty()
  role: DISTRICT_ROLE | EVENT_ROLE | GROUP_ROLE
}
