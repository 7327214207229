import { useState } from 'react';

import { Grid, ThemeProvider, Typography } from '@mui/material';

import { useThemeContext } from '../../theme/ThemeContextProvider';
import { useTranslation } from 'react-i18next';

interface IExpandableTextProps {
  text: string;
  maxLength?: number;
}

export const ExpandableText = function ExpandableText({ text, maxLength = 91 }: IExpandableTextProps) {
  const { theme } = useThemeContext();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container rowGap={1}>
        <Grid item xs={12}>
          <Typography variant='text_sm_regular' color={theme.palette.text.secondary} component="div">
            {expanded ? text : `${text.slice(0, maxLength)}...`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {text.length > maxLength && (
            <Typography variant='text_sm_regular' color={theme.palette.text.secondary} onClick={toggleExpanded} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
              {expanded ? t('common:show.less') : t('common:show.more')}
            </Typography>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
