import {
  IsBoolean, IsDateString, IsEnum, IsNumber, IsObject, IsOptional, IsString, IsUUID
} from 'class-validator';

import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';

import { ENTRY_TYPE } from '../enum';
import { ClassificationObjectResponseDto } from './classificationObject.response.dto';
import { WeatherRealtimeResponseDto } from './weather';
import { WEIGHT_UNIT } from '../enum/entryWeightUnit.enum';
import { ShotPostRequestDto } from './shot/shot.post.request.dto';
import { REGION } from '../enum/region.enum';

export class EntryPostRequestDto {

  @IsOptional()
  @IsUUID()
  @ApiProperty()
  public id?: string;

  @IsOptional()
  @IsUUID()
  @ApiProperty()
  public districtId?: string;

  @IsOptional()
  @IsUUID()
  @ApiProperty()
  public eventId?: string;

  @IsBoolean()
  @ApiProperty()
  public isPrivate: boolean;

  @IsOptional()
  @IsNumber()
  @ApiPropertyOptional()
  public lat?: number;

  @IsOptional()
  @IsNumber()
  @ApiPropertyOptional()
  public long?: number;

  @IsOptional()
  @IsString()
  @ApiPropertyOptional()
  public classification?: string;

  @IsOptional()
  @IsEnum(ENTRY_TYPE)
  @ApiProperty()
  public entryType?: ENTRY_TYPE;

  @IsOptional()
  @IsObject()
  @ApiProperty()
  public classificationObject?: ClassificationObjectResponseDto;

  @IsOptional()
  @ApiPropertyOptional()
  public region?: REGION;

  @IsOptional()
  @ApiPropertyOptional()
  public fallwildType?: string | null;

  @IsOptional()
  @IsNumber()
  @ApiPropertyOptional()
  public count?: number;

  @IsOptional()
  @IsString()
  @ApiPropertyOptional()
  public description?: string;

  @IsOptional()
  @IsDateString()
  @ApiPropertyOptional({ description: 'Date as a string' })
  public userDate?: string;

  @IsOptional()
  @ApiPropertyOptional({ type: () => WeatherRealtimeResponseDto })
  public weather?: WeatherRealtimeResponseDto;

  @IsOptional()
  @IsNumber()
  @ApiPropertyOptional()
  public specificAge?: number | null;

  @IsOptional()
  @IsString()
  @ApiPropertyOptional()
  public weightUnit?: WEIGHT_UNIT;

  @IsOptional()
  @IsNumber()
  @ApiPropertyOptional()
  public weightAmount?: number | null;

  @IsOptional()
  @ApiPropertyOptional()
  public shot?: ShotPostRequestDto | null;

  @IsOptional()
  @ApiPropertyOptional()
  public disease?: string | null;
}
