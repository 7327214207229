import { Box, Typography } from '@mui/material';
import { Assets } from '../../../../../assets';

interface IProps {
  text: string;
}

export function NoEntitiesToFilter(props: IProps) {
  return (
    <Box display="flex" flexDirection={'column'} alignItems="center" mt={4}>
      <img style={{ width: '50px' }} src={Assets.harvest.no_results} />
      <Typography mt={2} variant="subtitle1" textAlign={'center'}>
        {props.text}
      </Typography>
    </Box>
  );
}
