import { observer } from 'mobx-react-lite';
import { BaseSyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup
} from '@mui/material';

import { useContainer } from '../../../../../../domain/hooks/useContainer';
import { useVm } from '../../../../../../domain/hooks/useVm';
import { EntryModel } from '../../../../../../domain/model/EntryModel';
import { palette } from '../../../../../../theme/options/palette/palette';
import { EntryHelper } from '../../../../../../util/EntryHelper';
import { ItemAccordion } from '../../../../../../toolkit/components/ItemAccordion';
import { EntryDiseaseVm, IDiseaseListItem } from './EntryDiseaseVm';

export interface EntryDiseaseProps {
  entry: EntryModel;
  canEditAndDeleteEntries: boolean;
  isHarvestVisibleAndEditable: boolean;
  diseasesForSpecies: IDiseaseListItem[];
}

export const EntryDisease = observer(function EntryDisease(props: EntryDiseaseProps) {
  const { t } = useTranslation();
  const vm = useVm(EntryDiseaseVm, props);
  const entryHelper = useContainer().get(EntryHelper);

  const checkPermissions = useCallback(() => {
    if (!props.canEditAndDeleteEntries) {
      if (!props.isHarvestVisibleAndEditable) {
        return entryHelper.notifyWrongPermission('entry:harvest_readonly_permission');
      }
      return entryHelper.notifyWrongPermission();
    }
  }, [entryHelper, props.canEditAndDeleteEntries, props.isHarvestVisibleAndEditable]);

  const handleDiseaseClick = useCallback((event: BaseSyntheticEvent) => {
    checkPermissions();
    props.entry.diseaseKey === event.target.value ? props.entry.setDiseaseKey(null) : props.entry.setDiseaseKey(event.target.value);
  }, [checkPermissions, props.entry]);


  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="inherit">
      <ItemAccordion selectedItem={!vm.hasDisease ? t('entry:label.disease_select') : vm.diseaseName}>
        <Grid container direction="column" rowSpacing={4}>
          <Grid item>
            <FormControl fullWidth>
              <FormLabel sx={{
                color: palette.text?.details,
                fontSize: '1.125rem'
              }}
              >{t('entry:view.disease.title')}</FormLabel>
              <RadioGroup
                value={props.entry.diseaseKey ?? ''}
                name="disease-radio-buttons-group"
              >
                {props.diseasesForSpecies.map((disease: IDiseaseListItem) => {
                  return (
                    <Box marginRight={2} key={`disease-${disease.key}`}>
                      <FormControlLabel
                        value={disease.key}
                        control={
                          <Radio
                            color='secondary'
                            inputProps={{
                              readOnly: !props.canEditAndDeleteEntries
                            }}
                            onClick={handleDiseaseClick}
                          />}
                        label={disease.value}
                        labelPlacement="start"
                        sx={{
                          '&.MuiFormControlLabel-root': {
                            display: 'flex',
                            justifyContent: 'space-between',
                          },
                          '& .MuiTypography-root': {
                            color: '#000'
                          }
                        }}
                      />
                      <Divider />
                    </Box>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </ItemAccordion>

    </Box>
  );
});
