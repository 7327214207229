import { load } from './loader';

export const Assets = {
  loading_logo: load('./img/loading_logo.gif'),
  logo: load('./img/logo.svg'),
  roles_modal_1: load('./img/roles_modal_1.png'),
  roles_modal_2: load('./img/roles_modal_2.png'),
  shield: load('./img/icon_shield.svg'),
  shield_grey: load('./img/icon_shield_grey.svg'),
  teaser_image: load('./img/teaser_image.svg'),
  icon_free_user: load('./img/icon_free_user.svg'),
  icon_delete_account: load('./img/icon-delete.svg'),
  mobile_navigator: load('./img/mobile_navigator.svg'),
  image_gallery: load('./img/photo_gallery.svg'),
  image_gallery_dummy: load('./img/image_gallery_dummy.png'),
  search: load('./img/search.svg'),
  discount_icon: load('./img/discount_icon.svg'),
  group_discount_icon: load('./img/group_discount_icon.svg'),
  counter_minus: load('./img/counter_minus.svg'),
  counter_plus: load('./img/counter_plus.svg'),
  multiple_users: load('./img/multiple_users.svg'),
  multi_users_group_discount: load('./img/multi_users_group_discount.svg'),
  multiple_users_transparent: load('./img/multiple_users_transparent.svg'),
  kml_gpx_upload: load('./img/KML_GPX_icon.svg'),
  resize_icon: load('./img/resize_icon.svg'),
  harvest: {
    no_results: load('./img/harvest/search_icon.svg'),
    pro_export: load('./img/harvest/harvestlist_pro_icon.svg'),
  },
  cursor: {
    blocked: load('./img/blocked_cursor.svg'),
  },
  filter: {
    icon: load('./img/filter/filter_menu_icon.svg'),
    icon_white: load('./img/filter/filter_menu_icon_white.svg'),
  },
  district: {
    zoom_to_district: load('./img/district/zoom_to_district.svg'),
    tutorial: {
      web_tutorial_1: load('./img/district/tutorial/web_tutorial_1.png'),
      web_tutorial_2: load('./img/district/tutorial/web_tutorial_2.png'),
      web_tutorial_3: load('./img/district/tutorial/web_tutorial_3.png'),
      web_tutorial_4: load('./img/district/tutorial/web_tutorial_4.png'),
    }
  },
  map: {
    zone_path_menu_icon: load('./img/zone_path_menu_icon.svg'),
    map_icon: load('./img/map_icon.svg'),
    pin_drop_icon: load('./img/pin_drop_icon.svg'),
    upload_icon: load('./img/upload_icon.svg'),
    icons_add_user_group_man_man: load('./img/icons_add_user_group_man_man.svg'),
    icons_create_new_area: load('./img/icons_create_new_area.svg'),
    print: load('./img/icons8-print.svg'),
    layers: {
      parcels_de: load('./img/map/DE_parcel.svg'),
      parcels_es: load('./img/map/ES_parcel.svg'),
      parcels_at: load('./img/map/AT_parcel.svg'),
      parcels_fr: load('./img/map/FR_parcel.svg'),
      parcels_ch: load('./img/map/CH_parcel.svg'),
      satellite: load('./img/map/icon_satellite.svg'),
      terrain: load('./img/map/icon_terrain.svg'),
      roadmap: load('./img/map/icon_roadmap.png'),
    }
  },
  path: {
    icon: load('./img/path/icon_path.svg'),
    proPath: load('./img/path/pin_path.svg'),
    proTeaser: load('./img/path/pro_teaser_path.png'),
    menuIcon: load('./img/path/menu_icon_path.svg'),
    tutorial: {
      step_1: load('./img/path/tutorial/MyHunt_WebApp_Path_Tutorial_01.png'),
      step_2: load('./img/path/tutorial/MyHunt_WebApp_Path_Tutorial_02.png'),
      step_3: load('./img/path/tutorial/MyHunt_WebApp_Path_Tutorial_03.png'),
      step_4: load('./img/path/tutorial/MyHunt_WebApp_Path_Tutorial_04.png'),
    }
  },
  subzone: {
    pro_teaser: load('./img/subzone/Pro_Teaser_Subzone.png'),
    tutorial: {
      step_1: load('./img/subzone/tutorial/subzone_tutorial_image_1.svg'),
      step_2: load('./img/subzone/tutorial/subzone_tutorial_image_2.svg'),
      step_3: load('./img/subzone/tutorial/subzone_tutorial_image_3.svg'),
      step_4: load('./img/subzone/tutorial/subzone_tutorial_image_4.svg'),
    },
    menuIcon: load('./img/subzone/menu_icon.svg'),
    subzone: load('./img/subzone/subzone.svg'),
    subzone_closed_area: load('./img/subzone/subzone_closed_area.svg'),
    subzone_crop_field: load('./img/subzone/subzone_crop_field.svg'),
    subzone_custom: load('./img/subzone/subzone_custom.svg'),
    subzone_field: load('./img/subzone/subzone_field.svg'),
    subzone_forest: load('./img/subzone/subzone_forest.svg'),
    subzone_other: load('./img/subzone/subzone_other.svg'),
    subzone_swamp: load('./img/subzone/subzone_swamp.svg'),
  },
  poi: {
    shooting_direction: {
      360: load('./img/poi/shooting-direction/360.svg'),
      315: load('./img/poi/shooting-direction/315.svg'),
      270: load('./img/poi/shooting-direction/270.svg'),
      225: load('./img/poi/shooting-direction/225.svg'),
      180: load('./img/poi/shooting-direction/180.svg'),
      135: load('./img/poi/shooting-direction/135.svg'),
      90: load('./img/poi/shooting-direction/90.svg'),
      45: load('./img/poi/shooting-direction/45.svg'),
      pro: load('./img/poi/shooting-direction/pro_shooting_angle.png'),
    },
    multiple_checkin_icon: load('./img/poi/multiple_checkin_icon.png'),
    menu_icon: load('./img/poi/menu_icon_poi.svg'),
    icons: {
      bait_site_meat: load('./img/poi/icons/Bait_site_meat_icon.svg'),
      bait_site_veg: load('./img/poi/icons/Bait_site_veg_icon.svg'),
      blind: load('./img/poi/icons/blind_icon.svg'),
      camera: load('./img/poi/icons/camera_icon_grey.svg'),
      earth_work: load('./img/poi/icons/Earth_work_icon.svg'),
      feeding_site: load('./img/poi/icons/Feeding_site_icon.svg'),
      high_seat: load('./img/poi/icons/High_seat_icon.svg'),
      high_seat_white: load('./img/poi/icons/High_seat_icon_white.svg'),
      nest: load('./img/poi/icons/nest_icon.svg'),
      trap: load('./img/poi/icons/Trap_icon.svg'),
      weather: load('./img/poi/icons/weather_icon.svg'),
      custom: load('./img/poi/icons/custom_icon.svg'),
    },
    pro: {
      bookings: load('./img/poi/pro/Pro_Teaser_Booking.png'),
      tasks: load('./img/poi/pro/Pro_Teaser_Task.png'),
    },
    burrow: load('./img/poi/poi_burrow.svg'),
    camera: load('./img/poi/poi_camera.svg'),
    baitsiteMeat: load('./img/poi/poi_baitsiteM.svg'),
    baitsiteVege: load('./img/poi/poi_baitsiteV.svg'),
    custom: load('./img/poi/custom/poi_custom.svg'),
    custom_pro: load('./img/pro_pin.svg'),
    feedingsite: load('./img/poi/poi_feedingsite.svg'),
    highseat: load('./img/poi/poi_highseat.svg'),
    homebase: load('./img/poi/poi_homebase.svg'),
    nest: load('./img/poi/poi_nest.svg'),
    trap: load('./img/poi/poi_trap.svg'),
    trapblind: load('./img/poi/poi_trapblind.svg'),
    blocked: load('./img/poi/blocked.svg'),
    blocked_non_pro: load('./img/poi/blocked_non_pro.svg'),
    fallback: load('./img/poi/pin_fallback.svg'),
    task: {
      unassigned: load('./img/poi/task/unassigned_task_photo.svg'),
    }
  },
  customMark: {
    white: {
      beehive_icon_white: load('./img/poi/custom/white/beehive_icon.svg'),
      beetle_icon_white: load('./img/poi/custom/white/beetle_icon.svg'),
      biotope_icon_white: load('./img/poi/custom/white/biotope_icon.svg'),
      blind_icon_white: load('./img/poi/custom/white/blind_icon.svg'),
      bridge_icon_white: load('./img/poi/custom/white/bridge_icon.svg'),
      closed_highseat_icon_white: load('./img/poi/custom/white/closed_highseat_icon.svg'),
      favourite_star_icon_white: load('./img/poi/custom/white/favourite_star_icon.svg'),
      heart_icon_white: load('./img/poi/custom/white/heart_icon.svg'),
      hide_buck_icon_white: load('./img/poi/custom/white/hide_buck_icon.svg'),
      highstand_ladder_icon_white: load('./img/poi/custom/white/highstand_ladder_icon.svg'),
      hut_icon_white: load('./img/poi/custom/white/hut_icon.svg'),
      information_icon_white: load('./img/poi/custom/white/information_icon.svg'),
      ladder_icon_white: load('./img/poi/custom/white/ladder_icon.svg'),
      meeting_point_icon_white: load('./img/poi/custom/white/meeting_point_icon.svg'),
      mobile_highstand_icon_white: load('./img/poi/custom/white/mobile_highstand_icon.svg'),
      no_entry_icon_white: load('./img/poi/custom/white/no_entry_icon.svg'),
      open_seat_icon_white: load('./img/poi/custom/white/open_seat_icon.svg'),
      open_highseat_icon_white: load('./img/poi/custom/white/open_highseat_icon.svg'),
      parking_icon_white: load('./img/poi/custom/white/parking_icon.svg'),
      poi_custom: load('./img/poi/custom/poi_custom.svg'),
      puddle_icon_white: load('./img/poi/custom/white/puddle_icon.svg'),
      rack_icon_white: load('./img/poi/custom/white/rack_icon.svg'),
      rut_icon_white: load('./img/poi/custom/white/rut_icon.svg'),
      salt_icon_white: load('./img/poi/custom/white/salt_icon_white.svg'),
      scissor_ladder_icon_white: load('./img/poi/custom/white/scissor_ladder_icon.svg'),
      sos_icon_white: load('./img/poi/custom/white/sos_icon.svg'),
      warning_icon_white: load('./img/poi/custom/white/warning_icon.svg'),
      water_supply_icon_white: load('./img/poi/custom/white/water_supply_icon.svg'),
      nesting_box: load('./img/poi/custom/white/nesting_box.svg'),
    },
    gray: {
      beehive_icon: load('./img/poi/custom/gray/beehive_icon.svg'),
      beetle_icon: load('./img/poi/custom/gray/beetle_icon.svg'),
      biotope_icon: load('./img/poi/custom/gray/biotope_icon.svg'),
      blind_icon: load('./img/poi/custom/gray/blind_icon.svg'),
      bridge_icon: load('./img/poi/custom/gray/bridge_icon.svg'),
      closed_highseat_icon: load('./img/poi/custom/gray/closed_highseat_icon.svg'),
      favourite_star_icon: load('./img/poi/custom/gray/favourite_star_icon.svg'),
      heart_icon: load('./img/poi/custom/gray/heart_icon.svg'),
      hide_buck_icon: load('./img/poi/custom/gray/hide_buck_icon.svg'),
      highstand_ladder_icon: load('./img/poi/custom/gray/highstand_ladder_icon.svg'),
      hut_icon: load('./img/poi/custom/gray/hut_icon.svg'),
      information_icon: load('./img/poi/custom/gray/information_icon.svg'),
      ladder_icon: load('./img/poi/custom/gray/ladder_icon.svg'),
      meeting_point_icon: load('./img/poi/custom/gray/meeting_point_icon.svg'),
      mobile_highstand_icon: load('./img/poi/custom/gray/mobile_highseat_icon.svg'),
      no_entry_icon: load('./img/poi/custom/gray/no_entry_icon.svg'),
      open_seat_icon: load('./img/poi/custom/gray/open_seat_icon.svg'),
      open_highseat_icon: load('./img/poi/custom/gray/open_highseat_icon.svg'),
      parking_icon: load('./img/poi/custom/gray/parking_icon.svg'),
      puddle_icon: load('./img/poi/custom/gray/puddle_icon.svg'),
      rack_icon: load('./img/poi/custom/gray/rack_icon.svg'),
      rut_icon: load('./img/poi/custom/gray/rut_icon.svg'),
      salt_icon: load('./img/poi/custom/gray/salt_icon_grey.svg'),
      scissor_ladder_icon: load('./img/poi/custom/gray/scissor_ladder_icon.svg'),
      sos_icon: load('./img/poi/custom/gray/sos_icon.svg'),
      warning_icon: load('./img/poi/custom/gray/warning_icon.svg'),
      water_supply_icon: load('./img/poi/custom/gray/water_supply_icon.svg'),
      nesting_box: load('./img/poi/custom/gray/nesting_box.svg'),
    }
  },
  region: {
    africa: load('./img/region/africa.png'),
    asia: load('./img/region/asia.png'),
    british_isles: load('./img/region/british_isles.png'),
    bulgaria: load('./img/region/bulgaria.png'),
    dach: load('./img/region/dach.png'),
    eastern_europe: load('./img/region/eastern_europe.png'),
    malta: load('./img/region/malta.png'),
    nordics: load('./img/region/nordics.png'),
    north_america: load('./img/region/north_america.png'),
    oceania: load('./img/region/oceania.png'),
    south_america: load('./img/region/south_america.png'),
    southern_europe: load('./img/region/southern_europe.png'),
    western_europe: load('./img/region/western_europe.png'),
    world: load('./img/region/world.png'),
  },
  entry: {
    other: {
      sighting: load('./img/entry/other/menu_icon_sighting.svg'),
      harvest: load('./img/entry/other/menu_icon_harvest.svg'),
      misc: load('./img/entry/other/menu_icon_other.svg'),
    },
    orange: {
      pin_black_wildebeest: load('./img/entry/pins_orange/pin_black_wildebeest.svg'),
      alligator: load('./img/entry/pins_orange/pin_alligator.svg'),
      alpine_ibex: load('./img/entry/pins_orange/pin_alpine_ibex.svg'),
      antelope: load('./img/entry/pins_orange/pin_antelope.svg'),
      baboon: load('./img/entry/pins_orange/pin_baboon.svg'),
      banteng: load('./img/entry/pins_orange/pin_banteng.svg'),
      barbary_sheep: load('./img/entry/pins_orange/pin_barbary_sheep.svg'),
      bighorn_sheep: load('./img/entry/pins_orange/pin_bighorn_sheep.svg'),
      bison: load('./img/entry/pins_orange/pin_bison.svg'),
      black_bear: load('./img/entry/pins_orange/pin_black_bear.svg'),
      black_tailed_deer: load('./img/entry/pins_orange/pin_black_tailed_deer.svg'),
      blackbuck: load('./img/entry/pins_orange/pin_blackbuck.svg'),
      blue_sheep: load('./img/entry/pins_orange/pin_blue_sheep.svg'),
      brown_bear: load('./img/entry/pins_orange/pin_brown_bear.svg'),
      brown_hare: load('./img/entry/pins_orange/pin_brown_hare.svg'),
      bushpig: load('./img/entry/pins_orange/pin_bushpig.svg'),
      cape_buffalo: load('./img/entry/pins_orange/pin_cape_buffalo.svg'),
      caucasian_tur: load('./img/entry/pins_orange/pin_caucasian_tur.svg'),
      chamois: load('./img/entry/pins_orange/pin_chamois.svg'),
      chinese_water_deer: load('./img/entry/pins_orange/pin_chinese_water_deer.svg'),
      chital: load('./img/entry/pins_orange/pin_chital.svg'),
      combination_gun: load('./img/entry/pins_orange/pin_combination_gun.svg'),
      crocodile: load('./img/entry/pins_orange/pin_crocodile.svg'),
      cross_bird: load('./img/entry/pins_orange/pin_cross_bird.svg'),
      dall_sheep: load('./img/entry/pins_orange/pin_dall_sheep.svg'),
      duck: load('./img/entry/pins_orange/pin_duck.svg'),
      eland: load('./img/entry/pins_orange/pin_eland.svg'),
      emu: load('./img/entry/pins_orange/pin_emu.svg'),
      european_bison: load('./img/entry/pins_orange/pin_european_bison.svg'),
      fallow_deer: load('./img/entry/pins_orange/pin_fallow_deer.svg'),
      fox: load('./img/entry/pins_orange/pin_fox.svg'),
      galliformes: load('./img/entry/pins_orange/pin_galliformes.svg'),
      goose: load('./img/entry/pins_orange/pin_goose.svg'),
      grizzly_bear: load('./img/entry/pins_orange/pin_grizzly_bear.svg'),
      hog_deer: load('./img/entry/pins_orange/pin_hog_deer.svg'),
      iberian_ibex: load('./img/entry/pins_orange/pin_iberian_ibex.svg'),
      kangaroo: load('./img/entry/pins_orange/pin_kangaroo.svg'),
      killing: load('./img/entry/pins_orange/pin_killing.svg'),
      moose: load('./img/entry/pins_orange/pin_moose.svg'),
      mouflon: load('./img/entry/pins_orange/pin_mouflon.svg'),
      mountain_goat: load('./img/entry/pins_orange/pin_mountain_goat.svg'),
      mule_deer: load('./img/entry/pins_orange/pin_mule_deer.svg'),
      muntjac: load('./img/entry/pins_orange/pin_muntjac.svg'),
      muskox: load('./img/entry/pins_orange/pin_muskox.svg'),
      ostrich: load('./img/entry/pins_orange/pin_ostrich.svg'),
      other_feathered_game: load('./img/entry/pins_orange/pin_other_feathered_game.svg'),
      other_furred_game: load('./img/entry/pins_orange/pin_other_furred_game.svg'),
      pheasant: load('./img/entry/pins_orange/pin_pheasant.svg'),
      pistol: load('./img/entry/pins_orange/pin_pistol.svg'),
      plains_zebra: load('./img/entry/pins_orange/pin_plains_zebra.svg'),
      pronghorn_sheep: load('./img/entry/pins_orange/pin_pronghorn_sheep.svg'),
      raccoon: load('./img/entry/pins_orange/pin_raccoon.svg'),
      red_deer: load('./img/entry/pins_orange/pin_red_deer.svg'),
      reindeer: load('./img/entry/pins_orange/pin_reindeer.svg'),
      revolver: load('./img/entry/pins_orange/pin_revolver.svg'),
      rifle: load('./img/entry/pins_orange/pin_rifle.svg'),
      roe_deer: load('./img/entry/pins_orange/pin_roe_deer.svg'),
      rusa_deer: load('./img/entry/pins_orange/pin_rusa_deer.svg'),
      samba_deer: load('./img/entry/pins_orange/pin_samba_deer.svg'),
      shotgun: load('./img/entry/pins_orange/pin_shotgun.svg'),
      sibirian_ibex: load('./img/entry/pins_orange/pin_sibirian_ibex.svg'),
      sika_deer: load('./img/entry/pins_orange/pin_sika_deer.svg'),
      snow_sheep: load('./img/entry/pins_orange/pin_snow_sheep.svg'),
      stone_sheep: load('./img/entry/pins_orange/pin_stone_sheep.svg'),
      tahr: load('./img/entry/pins_orange/pin_tahr.svg'),
      target: load('./img/entry/pins_orange/pin_target.svg'),
      turtle_dove: load('./img/entry/pins_orange/pin_turtle_dove.svg'),
      wapiti: load('./img/entry/pins_orange/pin_wapiti.svg'),
      warthog: load('./img/entry/pins_orange/pin_warthog.svg'),
      water_buffalo: load('./img/entry/pins_orange/pin_water_buffalo.svg'),
      white_tailed_deer: load('./img/entry/pins_orange/pin_white_tailed_deer.svg'),
      wild_boar: load('./img/entry/pins_orange/pin_wild_boar.svg'),
      wild_goat: load('./img/entry/pins_orange/pin_wild_goat.svg'),
      wild_yak: load('./img/entry/pins_orange/pin_wild_yak.svg'),
      wildebeest: load('./img/entry/pins_orange/pin_wildebeest.svg'),
      wolf: load('./img/entry/pins_orange/pin_wolf.svg'),
    },
    green: {
      pin_black_wildebeest: load('./img/entry/pins_green/pin_black_wildebeest.svg'),
      alligator: load('./img/entry/pins_green/pin_alligator.svg'),
      alpine_ibex: load('./img/entry/pins_green/pin_alpine_ibex.svg'),
      antelope: load('./img/entry/pins_green/pin_antelope.svg'),
      baboon: load('./img/entry/pins_green/pin_baboon.svg'),
      banteng: load('./img/entry/pins_green/pin_banteng.svg'),
      barbary_sheep: load('./img/entry/pins_green/pin_barbary_sheep.svg'),
      bighorn_sheep: load('./img/entry/pins_green/pin_bighorn_sheep.svg'),
      bison: load('./img/entry/pins_green/pin_bison.svg'),
      black_bear: load('./img/entry/pins_green/pin_black_bear.svg'),
      black_tailed_deer: load('./img/entry/pins_green/pin_black_tailed_deer.svg'),
      blackbuck: load('./img/entry/pins_green/pin_blackbuck.svg'),
      blue_sheep: load('./img/entry/pins_green/pin_blue_sheep.svg'),
      brown_bear: load('./img/entry/pins_green/pin_brown_bear.svg'),
      brown_hare: load('./img/entry/pins_green/pin_brown_hare.svg'),
      bushpig: load('./img/entry/pins_green/pin_bushpig.svg'),
      cape_buffalo: load('./img/entry/pins_green/pin_cape_buffalo.svg'),
      caucasian_tur: load('./img/entry/pins_green/pin_caucasian_tur.svg'),
      chamois: load('./img/entry/pins_green/pin_chamois.svg'),
      chinese_water_deer: load('./img/entry/pins_green/pin_chinese_water_deer.svg'),
      chital: load('./img/entry/pins_green/pin_chital.svg'),
      combination_gun: load('./img/entry/pins_green/pin_combination_gun.svg'),
      crocodile: load('./img/entry/pins_green/pin_crocodile.svg'),
      cross_bird: load('./img/entry/pins_green/pin_cross_bird.svg'),
      dall_sheep: load('./img/entry/pins_green/pin_dall_sheep.svg'),
      duck: load('./img/entry/pins_green/pin_duck.svg'),
      eland: load('./img/entry/pins_green/pin_eland.svg'),
      emu: load('./img/entry/pins_green/pin_emu.svg'),
      european_bison: load('./img/entry/pins_green/pin_european_bison.svg'),
      fallow_deer: load('./img/entry/pins_green/pin_fallow_deer.svg'),
      fox: load('./img/entry/pins_green/pin_fox.svg'),
      galliformes: load('./img/entry/pins_green/pin_galliformes.svg'),
      goose: load('./img/entry/pins_green/pin_goose.svg'),
      grizzly_bear: load('./img/entry/pins_green/pin_grizzly_bear.svg'),
      hog_deer: load('./img/entry/pins_green/pin_hog_deer.svg'),
      iberian_ibex: load('./img/entry/pins_green/pin_iberian_ibex.svg'),
      kangaroo: load('./img/entry/pins_green/pin_kangaroo.svg'),
      moose: load('./img/entry/pins_green/pin_moose.svg'),
      mouflon: load('./img/entry/pins_green/pin_mouflon.svg'),
      mountain_goat: load('./img/entry/pins_green/pin_mountain_goat.svg'),
      mule_deer: load('./img/entry/pins_green/pin_mule_deer.svg'),
      muntjac: load('./img/entry/pins_green/pin_muntjac.svg'),
      muskox: load('./img/entry/pins_green/pin_muskox.svg'),
      ostrich: load('./img/entry/pins_green/pin_ostrich.svg'),
      other_feathered_game: load('./img/entry/pins_green/pin_other_feathered_game.svg'),
      other_furred_game: load('./img/entry/pins_green/pin_other_furred_game.svg'),
      pheasant: load('./img/entry/pins_green/pin_pheasant.svg'),
      pistol: load('./img/entry/pins_green/pin_pistol.svg'),
      plains_zebra: load('./img/entry/pins_green/pin_plains_zebra.svg'),
      pronghorn_sheep: load('./img/entry/pins_green/pin_pronghorn_sheep.svg'),
      raccoon: load('./img/entry/pins_green/pin_raccoon.svg'),
      red_deer: load('./img/entry/pins_green/pin_red_deer.svg'),
      reindeer: load('./img/entry/pins_green/pin_reindeer.svg'),
      revolver: load('./img/entry/pins_green/pin_revolver.svg'),
      rifle: load('./img/entry/pins_green/pin_rifle.svg'),
      roe_deer: load('./img/entry/pins_green/pin_roe_deer.svg'),
      rusa_deer: load('./img/entry/pins_green/pin_rusa_deer.svg'),
      samba_deer: load('./img/entry/pins_green/pin_samba_deer.svg'),
      shotgun: load('./img/entry/pins_green/pin_shotgun.svg'),
      sibirian_ibex: load('./img/entry/pins_green/pin_sibirian_ibex.svg'),
      sighting: load('./img/entry/pins_green/pin_sighting.svg'),
      sika_deer: load('./img/entry/pins_green/pin_sika_deer.svg'),
      snow_sheep: load('./img/entry/pins_green/pin_snow_sheep.svg'),
      stone_sheep: load('./img/entry/pins_green/pin_stone_sheep.svg'),
      tahr: load('./img/entry/pins_green/pin_tahr.svg'),
      turtle_dove: load('./img/entry/pins_green/pin_turtle_dove.svg'),
      wapiti: load('./img/entry/pins_green/pin_wapiti.svg'),
      warthog: load('./img/entry/pins_green/pin_warthog.svg'),
      water_buffalo: load('./img/entry/pins_green/pin_water_buffalo.svg'),
      white_tailed_deer: load('./img/entry/pins_green/pin_white_tailed_deer.svg'),
      wild_boar: load('./img/entry/pins_green/pin_wild_boar.svg'),
      wild_goat: load('./img/entry/pins_green/pin_wild_goat.svg'),
      wild_yak: load('./img/entry/pins_green/pin_wild_yak.svg'),
      wildebeest: load('./img/entry/pins_green/pin_wildebeest.svg'),
      wolf: load('./img/entry/pins_green/pin_wolf.svg'),
    },
    grey: {
      fallback: load('./img/entry/pins_grey/pin_fallback.svg'),
      misc: load('./img/entry/pins_grey/pin_misc.svg'),
    },
    detailsIcons: {
      gray_position: load('./img/entry/details-icons/Gray_Position.svg'),
      gray_pins: load('./img/entry/details-icons/Grey_Pins.svg'),
      gray_time_back: load('./img/entry/details-icons/Grey_TimeBack.svg'),
      gray_weight: load('./img/entry/details-icons/Grey_Weight.svg'),
      grey_light: load('./img/entry/details-icons/Grey_Light.svg'),
      grey_wind: load('./img/entry/details-icons/Grey_Wind.svg'),
      pressure_icon: load('./img/entry/details-icons/pressure_icon.svg'),
    },
  },
  whiteIcons: {
    alligator: load('./img/entry/icons-white/alligator.svg'),
    alpine_ibex: load('./img/entry/icons-white/alpine_ibex.svg'),
    antelope: load('./img/entry/icons-white/antelope.svg'),
    baboon: load('./img/entry/icons-white/baboon.svg'),
    banteng: load('./img/entry/icons-white/banteng.svg'),
    barbary_sheep: load('./img/entry/icons-white/barbary_sheep.svg'),
    bighorn_sheep: load('./img/entry/icons-white/bighorn_sheep.svg'),
    bison: load('./img/entry/icons-white/bison.svg'),
    black_tailed_deer: load('./img/entry/icons-white/black-tailed_deer.svg'),
    black_bear: load('./img/entry/icons-white/black_bear.svg'),
    blackbuck: load('./img/entry/icons-white/blackbuck.svg'),
    blue_sheep: load('./img/entry/icons-white/blue_sheep.svg'),
    brown_hare: load('./img/entry/icons-white/brown_hare.svg'),
    brown_bear: load('./img/entry/icons-white/brown_bear.svg'),
    bushpig: load('./img/entry/icons-white/bushpig.svg'),
    cape_buffalo: load('./img/entry/icons-white/cape_buffalo.svg'),
    caucasian_tur: load('./img/entry/icons-white/caucasian_tur.svg'),
    chamois: load('./img/entry/icons-white/chamois.svg'),
    chinese_waterdeer: load('./img/entry/icons-white/chinese_waterdeer.svg'),
    chital: load('./img/entry/icons-white/chital.svg'),
    crocodile: load('./img/entry/icons-white/crocodile.svg'),
    cross_bird: load('./img/entry/icons-white/cross_bird.svg'),
    dall_sheep: load('./img/entry/icons-white/dall_sheep.svg'),
    duck: load('./img/entry/icons-white/duck.svg'),
    eland: load('./img/entry/icons-white/eland.svg'),
    emu: load('./img/entry/icons-white/emu.svg'),
    european_bison: load('./img/entry/icons-white/european_bison.svg'),
    fallback: load('./img/entry/icons-white/fallback.svg'),
    fallow_deer: load('./img/entry/icons-white/fallow_deer.svg'),
    fox: load('./img/entry/icons-white/fox.svg'),
    galliformes: load('./img/entry/icons-white/galliformes.svg'),
    goose: load('./img/entry/icons-white/goose.svg'),
    grizzly_bear: load('./img/entry/icons-white/grizzly_bear.svg'),
    hog_deer: load('./img/entry/icons-white/hog_deer.svg'),
    iberian_ibex: load('./img/entry/icons-white/iberian_ibex.svg'),
    kangaroo: load('./img/entry/icons-white/kangaroo.svg'),
    killing: load('./img/entry/icons-white/killing.svg'),
    misc: load('./img/entry/icons-white/misc.svg'),
    moose: load('./img/entry/icons-white/moose.svg'),
    mouflon: load('./img/entry/icons-white/mouflon.svg'),
    mountain_goat: load('./img/entry/icons-white/mountain_goat.svg'),
    mule_deer: load('./img/entry/icons-white/mule_deer.svg'),
    muntjac: load('./img/entry/icons-white/muntjac.svg'),
    muskox: load('./img/entry/icons-white/muskox.svg'),
    ostrich: load('./img/entry/icons-white/ostrich.svg'),
    other_feathered_game: load('./img/entry/icons-white/other_feathered_game.svg'),
    other_furred_game: load('./img/entry/icons-white/other_furred_game.svg'),
    pheasant: load('./img/entry/icons-white/pheasant.svg'),
    plains_zebra: load('./img/entry/icons-white/plains_zebra.svg'),
    pronghorn: load('./img/entry/icons-white/pronghorn.svg'),
    raccoon: load('./img/entry/icons-white/raccoon.svg'),
    raccoon_1: load('./img/entry/icons-white/raccoon_1.svg'),
    red_deer: load('./img/entry/icons-white/red_deer.svg'),
    reindeer: load('./img/entry/icons-white/reindeer.svg'),
    roe_deer: load('./img/entry/icons-white/roe_deer.svg'),
    rusa_deer: load('./img/entry/icons-white/rusa_deer.svg'),
    samba_deer: load('./img/entry/icons-white/samba_deer.svg'),
    siberian_ibex: load('./img/entry/icons-white/siberian_ibex.svg'),
    sighting: load('./img/entry/icons-white/sighting.svg'),
    sika_deer: load('./img/entry/icons-white/sika_deer.svg'),
    snow_sheep: load('./img/entry/icons-white/snow_sheep.svg'),
    stone_sheep: load('./img/entry/icons-white/stone_sheep.svg'),
    tahr: load('./img/entry/icons-white/tahr.svg'),
    turtle_dove: load('./img/entry/icons-white/turtle_dove.svg'),
    wapiti: load('./img/entry/icons-white/wapiti.svg'),
    warthog: load('./img/entry/icons-white/warthog.svg'),
    water_buffalo: load('./img/entry/icons-white/water_buffalo.svg'),
    white_tailed_deer: load('./img/entry/icons-white/white_tailed_deer.svg'),
    wild_boar: load('./img/entry/icons-white/wild_boar.svg'),
    wild_goat: load('./img/entry/icons-white/wild_goat.svg'),
    wild_yak: load('./img/entry/icons-white/wild_yak.svg'),
    wildebeest: load('./img/entry/icons-white/wildebeest.svg'),
    wolf: load('./img/entry/icons-white/wolf.svg'),
    black_wildebeest: load('./img/entry/icons-white/black_wildebeest.svg'),
  },
  grayIcons: {
    black_wildebeest: load('./img/entry/icons-gray/black_wildebeest.svg'),
    alligator: load('./img/entry/icons-gray/alligator.svg'),
    alpine_ibex: load('./img/entry/icons-gray/alpine_ibex.svg'),
    antelope: load('./img/entry/icons-gray/antelope.svg'),
    baboon: load('./img/entry/icons-gray/baboon.svg'),
    banteng: load('./img/entry/icons-gray/banteng.svg'),
    barbary_sheep: load('./img/entry/icons-gray/barbary_sheep.svg'),
    bighorn_sheep: load('./img/entry/icons-gray/bighorn_sheep.svg'),
    bison: load('./img/entry/icons-gray/bison.svg'),
    black_tailed_deer: load('./img/entry/icons-gray/black-tailed_deer.svg'),
    black_bear: load('./img/entry/icons-gray/black_bear.svg'),
    blackbuck: load('./img/entry/icons-gray/blackbuck.svg'),
    blue_sheep: load('./img/entry/icons-gray/blue_sheep.svg'),
    brown_hare: load('./img/entry/icons-gray/brown_hare.svg'),
    brown_bear: load('./img/entry/icons-gray/brown_bear.svg'),
    bushpig: load('./img/entry/icons-gray/bushpig.svg'),
    cape_buffalo: load('./img/entry/icons-gray/cape_buffalo.svg'),
    caucasian_tur: load('./img/entry/icons-gray/caucasian_tur.svg'),
    chamois: load('./img/entry/icons-gray/chamois.svg'),
    chinese_waterdeer: load('./img/entry/icons-gray/chinese_waterdeer.svg'),
    chital: load('./img/entry/icons-gray/chital.svg'),
    crocodile: load('./img/entry/icons-gray/crocodile.svg'),
    cross_bird: load('./img/entry/icons-gray/cross_bird.svg'),
    dall_sheep: load('./img/entry/icons-gray/dall_sheep.svg'),
    duck: load('./img/entry/icons-gray/duck.svg'),
    eland: load('./img/entry/icons-gray/eland.svg'),
    emu: load('./img/entry/icons-gray/emu.svg'),
    european_bison: load('./img/entry/icons-gray/european_bison.svg'),
    fallow_deer: load('./img/entry/icons-gray/fallow_deer.svg'),
    fox: load('./img/entry/icons-gray/fox.svg'),
    galliformes: load('./img/entry/icons-gray/galliformes.svg'),
    goose: load('./img/entry/icons-gray/goose.svg'),
    grizzly_bear: load('./img/entry/icons-gray/grizzly_bear.svg'),
    hog_deer: load('./img/entry/icons-gray/hog_deer.svg'),
    iberian_ibex: load('./img/entry/icons-gray/iberian_ibex.svg'),
    kangaroo: load('./img/entry/icons-gray/kangaroo.svg'),
    moose: load('./img/entry/icons-gray/moose.svg'),
    mouflon: load('./img/entry/icons-gray/mouflon.svg'),
    mountain_goat: load('./img/entry/icons-gray/mountain_goat.svg'),
    mule_deer: load('./img/entry/icons-gray/mule_deer.svg'),
    muntjac: load('./img/entry/icons-gray/muntjac.svg'),
    muskox: load('./img/entry/icons-gray/muskox.svg'),
    ostrich: load('./img/entry/icons-gray/ostrich.svg'),
    other_feathered_game: load('./img/entry/icons-gray/other_feathered_game.svg'),
    other_furred_game: load('./img/entry/icons-gray/other_furred_game.svg'),
    pheasant: load('./img/entry/icons-gray/pheasant.svg'),
    plains_zebra: load('./img/entry/icons-gray/plains_zebra.svg'),
    pronghorn: load('./img/entry/icons-gray/pronghorn.svg'),
    raccoon: load('./img/entry/icons-gray/raccoon.svg'),
    raccoon_1: load('./img/entry/icons-gray/raccoon.svg'),
    red_deer: load('./img/entry/icons-gray/red_deer.svg'),
    reindeer: load('./img/entry/icons-gray/reindeer.svg'),
    roe_deer: load('./img/entry/icons-gray/roe_deer.svg'),
    rusa_deer: load('./img/entry/icons-gray/rusa_deer.svg'),
    samba_deer: load('./img/entry/icons-gray/samba_deer.svg'),
    siberian_ibex: load('./img/entry/icons-gray/siberian_ibex.svg'),
    sika_deer: load('./img/entry/icons-gray/sika_deer.svg'),
    snow_sheep: load('./img/entry/icons-gray/snow_sheep.svg'),
    stone_sheep: load('./img/entry/icons-gray/stone_sheep.svg'),
    tahr: load('./img/entry/icons-gray/tahr.svg'),
    turtle_dove: load('./img/entry/icons-gray/turtle_dove.svg'),
    wapiti: load('./img/entry/icons-gray/wapiti.svg'),
    warthog: load('./img/entry/icons-gray/warthog.svg'),
    water_buffalo: load('./img/entry/icons-gray/water_buffalo.svg'),
    white_tailed_deer: load('./img/entry/icons-gray/white_tailed_deer.svg'),
    wild_boar: load('./img/entry/icons-gray/wild_boar.svg'),
    wild_goat: load('./img/entry/icons-gray/wild_goat.svg'),
    wild_yak: load('./img/entry/icons-gray/wild_yak.svg'),
    wildebeest: load('./img/entry/icons-gray/wildebeest.svg'),
    wolf: load('./img/entry/icons-gray/wolf.svg'),
  },
  weather: {
    grey: {
      clear: load('./img/weather/grey/clear.svg'),
      cloudy: load('./img/weather/grey/cloudy.svg'),
      drizzle: load('./img/weather/grey/drizzle.svg'),
      first_light: load('./img/weather/grey/first_light.svg'),
      flurries: load('./img/weather/grey/flurries.svg'),
      fog: load('./img/weather/grey/fog.svg'),
      fog_light: load('./img/weather/grey/fog_light.svg'),
      freezing_drizzle: load('./img/weather/grey/freezing_drizzle.svg'),
      freezing_rain: load('./img/weather/grey/freezing_rain.svg'),
      freezing_rain_heavy: load('./img/weather/grey/freezing_rain_heavy.svg'),
      freezing_rain_light: load('./img/weather/grey/freezing_rain_light.svg'),
      ice_pellets: load('./img/weather/grey/ice_pellets.svg'),
      ice_pellets_heavy: load('./img/weather/grey/ice_pellets_heavy.svg'),
      ice_pellets_light: load('./img/weather/grey/ice_pellets_light.svg'),
      last_light: load('./img/weather/grey/last_light.svg'),
      light_wind: load('./img/weather/grey/light_wind.svg'),
      mostly_clear: load('./img/weather/grey/mostly_clear.svg'),
      mostly_cloudy: load('./img/weather/grey/mostly_cloudy.svg'),
      night_clear: load('./img/weather/grey/night_clear.svg'),
      night_mostly_clear: load('./img/weather/grey/night_mostly_clear.svg'),
      night_partly_cloudy: load('./img/weather/grey/night_partly_cloudy.svg'),
      partly_cloudy: load('./img/weather/grey/partly_cloudy.svg'),
      rain: load('./img/weather/grey/rain.svg'),
      rain_heavy: load('./img/weather/grey/rain_heavy.svg'),
      rain_light: load('./img/weather/grey/rain_light.svg'),
      snow: load('./img/weather/grey/snow.svg'),
      snow_heavy: load('./img/weather/grey/snow_heavy.svg'),
      snow_light: load('./img/weather/grey/snow_light.svg'),
      strong_wind: load('./img/weather/grey/strong_wind.svg'),
      tstorm: load('./img/weather/grey/tstorm.svg'),
      unknown: load('./img/weather/grey/unknown.svg'),
      wind: load('./img/weather/grey/wind.svg'),
    },
    clear: load('./img/weather/clear.svg'),
    cloudy: load('./img/weather/cloudy.svg'),
    drizzle: load('./img/weather/drizzle.svg'),
    first_light: load('./img/weather/first_light.svg'),
    flurries: load('./img/weather/flurries.svg'),
    fog_light: load('./img/weather/fog_light.svg'),
    fog: load('./img/weather/fog.svg'),
    freezing_drizzle: load('./img/weather/freezing_drizzle.svg'),
    freezing_rain: load('./img/weather/freezing_rain.svg'),
    freezing_rain_heavy: load('./img/weather/freezing_rain_heavy.svg'),
    freezing_rain_light: load('./img/weather/freezing_rain_light.svg'),
    ice_pellets_heavy: load('./img/weather/ice_pellets_heavy.svg'),
    ice_pellets_light: load('./img/weather/ice_pellets_light.svg'),
    ice_pellets: load('./img/weather/ice_pellets.svg'),
    last_light: load('./img/weather/last_light.svg'),
    light_wind: load('./img/weather/light_wind.svg'),
    mostly_clear: load('./img/weather/mostly_clear.svg'),
    mostly_cloudy: load('./img/weather/mostly_cloudy.svg'),
    night_clear: load('./img/weather/night_clear.svg'),
    night_mostly_clear: load('./img/weather/night_mostly_clear.svg'),
    night_partly_cloudy: load('./img/weather/night_partly_cloudy.svg'),
    partly_cloudy: load('./img/weather/partly_cloudy.svg'),
    rain_heavy: load('./img/weather/rain_heavy.svg'),
    rain_light: load('./img/weather/rain_light.svg'),
    rain: load('./img/weather/rain.svg'),
    snow_heavy: load('./img/weather/snow_heavy.svg'),
    snow_light: load('./img/weather/snow_light.svg'),
    snow: load('./img/weather/snow.svg'),
    strong_wind: load('./img/weather/strong_wind.svg'),
    tstorm: load('./img/weather/tstorm.svg'),
    unknown: load('./img/weather/unknown.svg'),
    wind: load('./img/weather/wind.svg'),
    poi_weather_background: load('./img/weather/poi_weather_background.svg'),
    poi_weather_loading: load('./img/weather/poi_weather_loading.svg'),
    wind_direction: {
      wind_1_3: load('./img/weather/wind/wind_on_poi_1-3.svg'),
      wind_4_7: load('./img/weather/wind/wind_on_poi_4-7.svg'),
      wind_8_10: load('./img/weather/wind/wind_on_poi_8-10.svg'),
    }
  }
};
