import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';

export class GeoLocationResponseDto {

  @ApiProperty()
  public latitude: number;

  @ApiProperty()
  public longitude: number;

  @ApiPropertyOptional()
  public accuracy?: number;
}
