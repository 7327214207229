import React, { useCallback } from 'react';
import { useEffectOnce } from 'react-use';

import { PaletteMode } from '@mui/material';

import { getDesignTokens } from './themeUtil';

export const useColorTheme = () => {
  const persistedState = localStorage.getItem('theme');
  const [mode, setMode] = React.useState<PaletteMode>((persistedState as PaletteMode) ?? 'light');

  useEffectOnce(() => {
    localStorage.setItem('theme', mode);
  });

  const toggleColorMode = useCallback(() => {
    setMode((prevMode) => {
      const newMode = prevMode === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', newMode);
      return newMode;
    });
  }, []);

  const modifiedTheme = React.useMemo(() => getDesignTokens(mode), [mode]);

  return {
    theme: modifiedTheme,
    mode,
    toggleColorMode,
  };
};
