import { inject } from 'inversify';
import { singleton } from '../../inversify/decorator';
import {
  HarvestTagPostRequestDto,
  HarvestTagResponseDto,
} from '../../shared/dto';
import { QuotaPlanResponseDto } from '../../shared/dto/quotaPlan.response.dto';
import { AjaxService } from '../service/AjaxService';
import { Proxy } from './Proxy';

@singleton()
export class HarvestTagProxy extends Proxy {
  constructor(@inject(AjaxService) private readonly ajax: AjaxService) {
    super();
  }

  public getHarvestList = (body: HarvestTagPostRequestDto) => {
    return this.ajax.post<HarvestTagResponseDto[]>('harvest-tag/v2', body);
  };

  public getDistrictQuotaPlans = (districtId: string) => {
    return this.ajax.get<QuotaPlanResponseDto[]>(`quota-plan/${districtId}`);
  };
}
