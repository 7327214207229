import { observer } from 'mobx-react-lite';
import { BaseSyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Divider, FormControl, FormControlLabel, Grid, Radio, RadioGroup, Typography
} from '@mui/material';

import { useContainer } from '../../../../../../domain/hooks/useContainer';
import { useVm } from '../../../../../../domain/hooks/useVm';
import { EntryModel } from '../../../../../../domain/model/EntryModel';
import { GearSetModel } from '../../../../../../domain/model/GearsetModel';
import { palette } from '../../../../../../theme/options/palette/palette';
import { InfoComponent } from '../../../../../../toolkit/components/InfoComponent';
import { EntryHelper } from '../../../../../../util/EntryHelper';
import { GearSetHelper } from '../../../../../../util/GearSetHelper';
import { ItemAccordion } from '../../../../../../toolkit/components/ItemAccordion';
import { EntryGearsetVm } from './EntryGearsetVm';
import { GearSetAccordion } from './GearSetAccordion';

export interface EntryGearsetProps {
  entry: EntryModel;
  canEditAndDeleteEntries: boolean;
  isHarvestVisibleAndEditable: boolean;
}

export const EntryGearset = observer(function EntryGearset(props: EntryGearsetProps) {
  const { t } = useTranslation();
  const vm = useVm(EntryGearsetVm, props);
  const entryHelper = useContainer().get(EntryHelper);
  const gearSetHelper = useContainer().get(GearSetHelper);

  const checkPermissions = useCallback(() => {
    if (!props.canEditAndDeleteEntries) {
      if (!props.isHarvestVisibleAndEditable) {
        return entryHelper.notifyWrongPermission('entry:harvest_readonly_permission');
      }
      return entryHelper.notifyWrongPermission();
    }
  }, [entryHelper, props.canEditAndDeleteEntries, props.isHarvestVisibleAndEditable]);

  const handleGearSetClick = useCallback((event: BaseSyntheticEvent) => {
    checkPermissions();
    props.entry.shot?.gearSetId === event.target.value ? vm.setEntryGearSet(undefined) : vm.setEntryGearSet(event.target.value);
  }, [checkPermissions, props.entry.shot?.gearSetId, vm]);


  return (
    <Box display="flex" flexDirection="column" width="inherit" alignItems="center">
      {!props.entry.shot?.gearSet ? <InfoComponent locizePath="entry:label.gearset_empty" /> : null}

      <ItemAccordion
        selectedItem={
          !props.entry.shot?.gearSet
            ? t('entry:label.gearset_select')
            : gearSetHelper.getWeaponAndManufacturer(props.entry.shot.gearSet)
        }
      >
        <Grid container direction="column" rowSpacing={2}>
          <Grid item>
            <FormControl fullWidth>
              <RadioGroup
                value={props.entry.shot?.gearSetId || null}
                name="gearSet-radio-buttons-group"
              >
                {vm.gearSets.map((gearSet: GearSetModel) => {
                  return (
                    <Box key={`gearset-${gearSet.id}`}>
                      <FormControlLabel
                        value={gearSet.id}
                        control={<Radio
                          color='secondary'
                          inputProps={{
                            readOnly: !props.canEditAndDeleteEntries
                          }}
                          onClick={handleGearSetClick}
                        />}
                        sx={{
                          '& .MuiTypography-root': {
                            color: '#000'
                          },
                          '&.MuiFormControlLabel-root': {
                            marginRight: 0
                          },
                        }}
                        label={
                          gearSet.ammunition.length < 2 ?
                            <Box display="flex" flexDirection="column" padding={2}>
                              <Typography fontSize={14} color={palette.text?.info}>
                                {gearSetHelper.getWeaponAndManufacturer(gearSet)}
                              </Typography>
                              <Typography fontSize={17} fontWeight="bold" color="#000">{gearSet.name}</Typography>
                              <Typography fontSize={14} color="#000">{gearSetHelper.getAmmunitionDescription(gearSet)}</Typography>
                            </Box>
                            : <GearSetAccordion entry={props.entry} canEditAndDeleteEntries={props.canEditAndDeleteEntries} isHarvestVisibleAndEditable={props.isHarvestVisibleAndEditable} gearSet={gearSet} />
                        }
                      />
                      <Divider />
                    </Box>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </ItemAccordion>
    </Box>
  );
});
