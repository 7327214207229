import { useCallback, useEffect, useState } from 'react';

import { Box, FormControlLabel, FormGroup, Switch } from '@mui/material';

import { colors } from '../../../../theme/options/palette/const/colors';
import { useContainer } from '../../../../domain/hooks/useContainer';
import { SessionStore } from '../../../../domain/store/SessionStore';

/**
 * ONLY FOR DEVELOPING AND TESTING PURPOSE!
 * ProBlockerModal is blocking free user to load and create any data in the app.
 * So this will be a switch in DEV mode where free user can turn off pro blocker just for testing purposes.
 */
export const FreeOrProSwitch = function FreeOrProSwitch() {

  const session = useContainer().get(SessionStore);

  // * Initialize blocker as true unless the localStorage value exists and is explicitly 'false'.
  const [blocker, showBlocker] = useState(localStorage.getItem(`FREE_OR_PRO-${session.userId}`) === 'false' ? false : true);

  // * Set the initial value in localStorage when the component mounts.
  useEffect(() => {
    if (!localStorage.getItem(`FREE_OR_PRO-${session.userId}`)) {
      localStorage.setItem(`FREE_OR_PRO-${session.userId}`, 'true');
    }
  }, [session.userId]);

  const toggleBlocker = useCallback(() => {
    const newBlockerState = !blocker;
    localStorage.setItem(`FREE_OR_PRO-${session.userId}`, newBlockerState ? 'true' : 'false');
    showBlocker(newBlockerState);
  }, [blocker, session.userId]);

  return (
    <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
      <FormGroup>
        <FormControlLabel
          labelPlacement='start'
          control={
            <Switch
              sx={{
                color: colors.paper,
              }}
            />}
          checked={blocker}
          onChange={toggleBlocker}
          label='Enable PRO stuff' />
      </FormGroup>
    </Box>
  );
};
