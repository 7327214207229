export enum RIGHT {
  // District rights
  CAN_DELETE_DISTRICT = 'CanDeleteDistrict',
  CAN_UPDATE_DISTRICT = 'CanUpdateDistrict',
  CAN_CREATE_POIS = 'CanCreatePois',
  CAN_DELETE_POIS = 'CanDeletePois',
  CAN_POST_ENTRIES = 'CanPostEntries',
  CAN_UNSHARE_ENTRIES = 'CanUnshareEntries',
  CAN_SEE_HARVEST_TAG = 'CanSeeHarvestTag',
  CAN_CREATE_TASKS = 'CanCreateTasks',
  CAN_UPDATE_TASKS = 'CanUpdateTasks',
  CAN_DELETE_TASKS = 'CanDeleteTasks',
  CAN_CREATE_CUSTOM_MARKS = 'CanCreateCustomMarks',
  CAN_UPDATE_CUSTOM_MARKS = 'CanUpdateCustomMarks',
  CAN_DELETE_CUSTOM_MARKS = 'CanDeleteCustomMarks',
  CAN_CREATE_BOOKING = 'CanCreateBooking',
  CAN_DELETE_BOOKING = 'CanDeleteBooking',
  CAN_CREATE_BLOCKING = 'CanCreateBlocking',
  CAN_DELETE_BLOCKING = 'CanDeleteBlocking',
  CAN_CREATE_MAP_PATHS = 'CanCreateMapPaths',
  CAN_UPDATE_MAP_PATHS = 'CanUpdateMapPaths',
  CAN_DELETE_MAP_PATHS = 'CanDeleteMapPaths',
  CAN_SEEN_ENTRIES = 'CanSeeEntries',
  CAN_UPDATE_ENTRIES = 'CanUpdateEntries',
  CAN_DELETE_ENTRIES = 'CanDeleteEntries',
  CAN_CREATE_QUOTA_PLAN = 'CanCreateQuotaPlan',
  CAN_UPDATE_QUOTA_PLAN = 'CanUpdateQuotaPlan',
  CAN_DELETE_QUOTA_PLAN = 'CanDeleteQuotaPlan',
  // Group rights
  CAN_DELETE_GROUP = 'CanDeleteGroup',
  CAN_UPDATE_GROUP = 'CanUpdateGroup',
  CAN_POST_POSTS = 'CanPostPosts',
  CAN_DELETE_POSTS = 'CanDeletePosts',
  // Mixed rights
  CAN_INVITE_USERS = 'CanInviteUsers',
  CAN_UNINVITE_USERS = 'CanUninviteUsers',
  CAN_SEE_MEMBERS = 'CanSeeMembers',
  CAN_CHANGE_ROLE = 'CanChangeRole',
  // Event rights
  CAN_UPDATE_EVENTS = 'CanUpdateEvents',
  CAN_DELETE_EVENTS = 'CanDeleteEvents',
  CAN_UPDATE_EVENT_STATUS = 'CanUpdateEventStatus',
  // Poi rights
  CAN_UPDATE_POI = 'CanUpdatePoi',
  // Subzone rights
  CAN_CREATE_SUBZONE = 'CanCreateSubzone',
  CAN_UPDATE_SUBZONE = 'CanUpdateSubzone',
  CAN_DELETE_SUBZONE = 'CanDeleteSubzone'
}
