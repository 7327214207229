import { makeObservable, observable } from 'mobx';

import { UserLocationDto } from '../../shared/dto/user.location.dto';
import { IGeoLocation } from '../core/IGeoLocation';

export class UserLocationModel {

  @observable
  public location: IGeoLocation = {
    latitude: 45.80217266848719,
    longitude: 15.985956417434863
  }

  constructor() {
    makeObservable(this);
  }

  public static fromDto(dto: UserLocationDto): UserLocationModel {
    const userLocationModel = new UserLocationModel();

    userLocationModel.location = dto.location;

    return userLocationModel;
  }

  public toDto = (): UserLocationDto | void => {
    if (!this.location) return;

    const dto = new UserLocationDto();
    dto.location = this.location;

    return dto;
  }
}
