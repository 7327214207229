import React from 'react';
import { useFormContext } from 'react-hook-form';

import { SubmitButton, SubmitButtonProps } from '../SubmitButton';

export function FormSubmitButton(props: SubmitButtonProps) {
  const form = useFormContext();

  return <SubmitButton
    disabled={!form.formState.isValid || !form.formState.isDirty}
    {...props}
  />;
}
