import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import { useCallback } from 'react';
import { MapboxEvent, Marker } from 'react-map-gl';
import { IGeoLocation } from '../../../../domain/core/IGeoLocation';
import { palette } from '../../../../theme/options/palette/palette';
import { OptionalTooltip } from '../../../../toolkit/components/OptionalTooltip';

interface IProps {
  point: IGeoLocation;
  selected: boolean;
  onDelete: (location: IGeoLocation) => void;
  onMeshPointClick: (point: IGeoLocation) => void;
  updatePointLocation: (point: IGeoLocation) => void;
}

export function MapboxDistrictMeshPoint(props: IProps) {
  const { point } = props;

  const onMeshPointClick = useCallback((e: MapboxEvent<MouseEvent>) => {
    e.originalEvent.stopPropagation();
    props.onMeshPointClick(props.point);
  }, [props]);

  const onDelete = useCallback(() => {
    props.onDelete(props.point);
  }, [props]);

  return (
    <Marker
      longitude={point.longitude}
      latitude={point.latitude}
      draggable
      onClick={onMeshPointClick}
      onDrag={(e) => props.updatePointLocation({
        latitude: e.lngLat.lat,
        longitude: e.lngLat.lng,
      })}
    >
      <OptionalTooltip
        enabled
        leaveDelay={200}
        placement="top"
        title={<Box
          display="flex"
          flexDirection="row"
          justifyContent={'space-between'}
          onClick={onDelete}
        >
          <DeleteIcon />
        </Box>}
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              minWidth: 'auto',
              paddingX: 1,
              paddingY: 1,
              cursor: 'pointer',
            }
          }
        }}
      >
        <Box
          border={`4px solid ${props.selected ? 'white' : palette.common?.districtMesh}`}
          width={20}
          height={20}
          borderRadius="50%"
          bgcolor={props.selected ? palette.common?.districtMesh : 'white'}
        />
      </OptionalTooltip>
    </Marker>
  );
}
