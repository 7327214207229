import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';
import { IsBooleanString, IsDateString, IsEnum, IsNumberString, IsOptional, IsString, IsUUID } from 'class-validator';
import { FALLWILD_TYPE } from '../enum';



export class DeprecatedEntryPostRequestDto {

  @IsOptional()
  @IsUUID()
  @ApiProperty()
  public districtId?: string;

  @IsOptional()
  @IsBooleanString()
  @ApiProperty({ description: 'boolean as a string' })
  public isPrivate?: string; // boolean string instead of string because multipart form supports only string values

  @IsOptional()
  @IsNumberString()
  @ApiPropertyOptional()
  public lat?: string;

  @IsOptional()
  @IsNumberString()
  @ApiPropertyOptional()
  public long?: string;

  @IsOptional()
  @ApiPropertyOptional()
  public meta?: string;

  @IsOptional()
  @IsString()
  @ApiPropertyOptional()
  public classification?: string;

  @IsOptional()
  @IsEnum(FALLWILD_TYPE)
  @ApiPropertyOptional()
  public fallwildType?: string;

  @IsOptional()
  @IsNumberString()
  @ApiPropertyOptional()
  public count?: number;

  @IsOptional()
  @IsString()
  @ApiPropertyOptional()
  public description?: string;

  @IsOptional()
  @IsDateString()
  @ApiPropertyOptional({ description: 'Date as a string' })
  public userDate?: string;
}
