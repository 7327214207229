
import { COUNTRY_CODE } from '../../shared/enum/countryCode.enum';

export interface CountryListItem {
  code: COUNTRY_CODE;
  label: string;
}

export const countryList = (): CountryListItem[] => {
  return Object.values(COUNTRY_CODE).map((countryCode: COUNTRY_CODE) => ({
    code: countryCode,
    label: `countries:${countryCode}`
  }));
};
