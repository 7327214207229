import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

import { Box, Grid } from '@mui/material';

import { useVm } from '../../domain/hooks/useVm';
import { TrackingEvent } from '../../domain/service/tracking/TrackingEvent';
import { env } from '../../env';
import { LoadingFallback } from '../../LoadingFallback';
import { colors } from '../../theme/options/palette/const/colors';
import { Background } from '../../toolkit/components/Background';
import { IconAndTitle } from '../../toolkit/components/IconAndTitle';
import { FreeOrProSwitch } from './components/user-profile-button-part/FreeOrProSwitch';
import { UserProfileButtonPart } from './components/user-profile-button-part/UserProfileButtonPart';
import { UserProfileForm } from './components/user-profile-form-part/UserProfileFormPart';
import { UserProfileLegal } from './components/user-profile-legal-part/UserProfileLegal';
import {
  UserProfileFreeUserRoute
} from './components/user-profile-map-wrapper/user-profile-free-user/UserProfileFreeUserRoute';
import {
  UserProfileProUserRoute
} from './components/user-profile-map-wrapper/user-profile-pro-user/UserProfileProUserRoute';
import { UserProfileRouteVm } from './UserProfileRouteVm';

export interface UserProfileProps {
  userProfileVm: UserProfileRouteVm;
}

export const UserProfileRoute: React.FC = observer(function UserProfileRoute() {

  const vm = useVm(UserProfileRouteVm);

  useEffect(() => {
    vm.tracking.track(TrackingEvent.PROFILE_USER_VIEW);
  }, [vm, vm.sessionStore.userId, vm.tracking, vm.isSubscribedViaStripe]);

  window.history.replaceState({}, document.title);

  if (vm.getOfferings.isBusy || vm.getProducts.isBusy) {
    return <LoadingFallback />;
  }

  return (
    <Background height="100%">
      <Grid container direction="column" height="inherit">
        <Grid item>
          <IconAndTitle title="profile:info.title_1" subtitle="profile:info.title_2" />
        </Grid>
        <Grid container item direction="row" justifyContent={{ xs: 'center', lg: 'flex-start' }} paddingY={2} rowSpacing={2}>

          {/* BUTTON PART */}
          <Grid item xs={12} sm={8} lg={3}>
            {!vm.sessionStore.isProUser && !env.isProduction && <FreeOrProSwitch />}
            <UserProfileButtonPart userProfileVm={vm} />
          </Grid>

          {/* FORM PART */}
          <Grid item xs={12} sm={8} lg={4} marginY={{ xs: 6, lg: 0 }}>
            <UserProfileForm />
          </Grid>

          {/* PRO FEATURES PART */}
          <Grid item xs={12} sm={8} lg={4} marginY={{ xs: 4, lg: 0 }}>
            <Box
              sx={{
                width: '100%',
                backgroundColor: colors.paper,
                paddingY: 2,
                borderRadius: 5,
              }}
            >
              {vm.sessionStore.isProUser ? <UserProfileProUserRoute userProfileVm={vm} /> : <UserProfileFreeUserRoute enableManageStripeSubscription={vm.enableManageStripeSubscription} />}
            </Box>
          </Grid>

        </Grid>
        <Grid item container justifyContent="center" alignItems="flex-end" flexGrow={1}>
          <UserProfileLegal />
        </Grid>
      </Grid>
    </Background>
  );

});
