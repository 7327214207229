import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';

import { Assets } from '../../../../assets';
import { colors } from '../../../../theme/options/palette/const/colors';
import { Background } from '../../../../toolkit/components/Background';
import { IconAndTitle } from '../../../../toolkit/components/IconAndTitle';
import { appendToppleGoalTagForPROConversionPixel } from '../../../../domain/service/tracking/ToppleTrackingUtil';

export const SuccessfulPaymentEmbeddedRoute = observer(function SuccessfulPaymentEmbeddedRoute() {

  const { t } = useTranslation();
  const [shieldIconSize, setShieldSize] = useState(54);

  const isSmallToMediumScreen = useMediaQuery('(max-width:1024px)');
  const isLargeScreen = useMediaQuery('(min-width:1025px)');

  useEffect(() => {
    if (isSmallToMediumScreen) {
      setShieldSize(50);
    } else if (isLargeScreen) {
      setShieldSize(70);
    }

    appendToppleGoalTagForPROConversionPixel();
  }, [isLargeScreen, isSmallToMediumScreen]);

  return (
    <Background paddingTop={{ xs: 7 }}>

      <Grid container direction="column" height="100%">

        <Grid item container paddingLeft={{ xs: 2 }} >
          <IconAndTitle title="payment:success.header_title" subtitle="payment:success.header_subtitle" />
        </Grid>

        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center"
          paddingTop={{ xs: '3%', md: '10%' }}
        >
          <Grid
            item container
            xs={11} sm={9} md={8}
            position="relative"
            sx={{ background: colors.paper, borderRadius: '20px', boxShadow: '0px 3px 6px #00000029' }}
          >
            <Grid item container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item sx={{ position: 'absolute', top: { xs: '-30px', md: '-40px' } }}>
                <img src={Assets.shield} width={shieldIconSize} />
              </Grid>
            </Grid>
            <Grid item container
              justifyContent="center"
              alignItems="center"
              marginY="43px"
            >
              <Grid item textAlign="center" paddingX={2}>
                <Typography lineHeight={1.2} fontSize={{ xs: 34, md: 48 }} fontWeight="bold" color="secondary">{t('payment:success.embedded.body_title')}</Typography>
                <Typography fontSize={{ xs: 22, md: 28 }} color="secondary">{t('payment:success.embedded.body_subtitle')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

      </Grid>
    </Background>
  );
});
