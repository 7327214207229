import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';
import { IsOptional, IsUUID } from 'class-validator';


export class BookingPostRequestDto {

  @IsUUID()
  @ApiProperty()
  public poiId: string;

  @IsOptional()
  @IsUUID()
  @ApiPropertyOptional()
  public userId?: string;
}
