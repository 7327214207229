import { inject } from 'inversify';
import { action, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { QuotaPlanModel } from '../../../../../../../domain/model/QuotaPlanModel';
import { QuotaPlanProxy } from '../../../../../../../domain/proxy/QuotaPlanProxy';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../../domain/service/NotificationService';
import { transient } from '../../../../../../../inversify/decorator';
import { sortQuotaPlansByDatesDescending } from '../../../../../../../util/QuotaPlanUtil';
import { IViewDistrictQuotaPlanningTabProps } from './ViewDistrictQuotaPlanningTab';

export enum QuotaPlanningScreens {
  QUOTA_PLANS_LIST = 'QUOTA_PLANS_LIST',
  EDIT_QUOTA_PLAN = 'EDIT_QUOTA_PLAN',
}

@transient()
export class ViewDistrictQuotaPlanningTabVm extends ViewModel<IViewDistrictQuotaPlanningTabProps> {

  @observable
  public quotaPlans: QuotaPlanModel[] = [];

  constructor(
    @inject(QuotaPlanProxy) private readonly quotaProxy: QuotaPlanProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  public override onInit = async () => {
    await this.getQuotaPlans.run();
  }

  @action
  public setQuotaPlans = (quotaPlans: QuotaPlanModel[]) => {
    this.quotaPlans = quotaPlans;
  }

  public getQuotaPlans = new AsyncTask(async () => {
    try {
      const result = await this.quotaProxy.getQuotaPlans(this.props.district.id);

      if (result.ok) {
        return this.setQuotaPlans(sortQuotaPlansByDatesDescending(result.data));
      }

      this.notification.error(this.i18n.t('quota_plan:error.get_quota_plans'));
    } catch (error) {
      console.error('Something went wrong while trying to fetch quota plans: ', error);
      this.notification.error(this.i18n.t('quota_plan:error.get_quota_plans'));
    }
  })

}
