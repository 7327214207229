import { inject } from 'inversify';
import { action, computed, makeObservable } from 'mobx';
import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { GearSetModel } from '../../../../../../domain/model/GearsetModel';
import { ShotModel } from '../../../../../../domain/model/ShotModel';
import { I18nService } from '../../../../../../domain/service/I18nService';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { transient } from '../../../../../../inversify/decorator';
import { GearSetHelper } from '../../../../../../util/GearSetHelper';
import { EntryGearsetProps } from './EntryGearset';

@transient()
export class EntryGearsetVm extends ViewModel<EntryGearsetProps> {

  constructor(
    @inject(I18nService) public readonly i18n: I18nService,
    @inject(SessionStore) public readonly sessionStore: SessionStore,
    @inject(GearSetHelper) private readonly gearSetHelper: GearSetHelper,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get gearSets(): GearSetModel[] {
    return this.sessionStore.currentUser?.gearSets ?? [];
  }

  @computed
  public get weaponAndManufacturer(): string {
    if (this.props.entry.shot?.gearSet) {
      return this.gearSetHelper.getWeaponAndManufacturer(this.props.entry.shot?.gearSet);
    }
    return '';
  }

  @action
  public setEntryGearSet = (gearSetId: string | undefined) => {
    const gearSet = this.gearSets.find(gs => gs.id === gearSetId);

    if (!this.props.entry.shot) {
      this.props.entry.shot = new ShotModel();
    }

    if (!this.props.entry.shot.shotDate) {
      this.props.entry.shot.shotDate = this.props.entry.userDate ?? new Date();
    }

    if (gearSet?.ammunition && gearSet?.ammunition.length === 1) {
      this.props.entry.shot.setAmmunitionId(gearSet.ammunition[0].id);
    } else {
      this.props.entry.shot.setAmmunitionId(undefined);
    }

    this.props.entry.shot.setGearsetId(gearSetId);
  };
}
