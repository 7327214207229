import { SnackbarProps, SnackbarProvider, VariantType } from 'notistack';
import { singleton } from '../../inversify/decorator';

@singleton()
export class NotificationService {
  private snackbar: SnackbarProvider | null = null;

  public setSnackbar = (snackbar: SnackbarProvider) => {
    this.snackbar = snackbar;
  }

  private show = (msg: string, variant: VariantType, opts: SnackbarProps = {}) => {
    this.snackbar?.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: 3000,
      ...opts,
    });
  }

  public success = (msg: string) => {
    this.show(msg, 'success');
  }

  public info = (msg: string) => {
    this.show(msg, 'info');
  }

  public warning = (msg: string, opts: SnackbarProps = {}) => {
    this.show(msg, 'warning', opts);
  }

  public error = (msg: string, opts: SnackbarProps = {}) => {
    this.show(msg, 'error', opts);
  }
}
