import { ApiProperty } from '@nestjs/swagger';
import { CUSTOM_MARK_COLOR } from '../../enum/customMarkColor.enum';
import { SUBZONE_TYPE } from '../../enum/subzoneType.enum';
import { IGeoLocation } from '../../interfaces/IGeoLocation';
import { IsEnum, IsNotEmpty, IsOptional, IsString, IsUUID } from 'class-validator';
import { SYNC_STATE } from '../../../shared/enum/syncState.enum';

export class SubzonePutRequestDto {

    @IsUUID()
    @IsNotEmpty()
    @ApiProperty()
    public id: string;

    @IsString()
    @IsOptional()
    @ApiProperty()
    public title?: string;

    @IsString()
    @ApiProperty()
    @IsOptional()
    public notes?: string;

    @IsEnum(CUSTOM_MARK_COLOR)
    @IsOptional()
    @ApiProperty()
    public background?: CUSTOM_MARK_COLOR | null;

    @IsEnum(SUBZONE_TYPE)
    @IsOptional()
    @ApiProperty({ enum: SUBZONE_TYPE })
    public subzoneType?: SUBZONE_TYPE;

    @IsOptional()
    @ApiProperty()
    public mesh?: IGeoLocation[];

    @IsOptional()
    @ApiProperty({ required: false })
    public state?: SYNC_STATE;

    @IsUUID()
    @IsOptional()
    @ApiProperty()
    public districtId?: string;
}