import { inject } from 'inversify';
import { action, makeObservable, observable, runInAction } from 'mobx';

import { AsyncTask } from '../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { DistrictModel } from '../../../../../../domain/model/DistrictModel';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { TaskModel } from '../../../../../../domain/model/TaskModel';
import { TaskProxy } from '../../../../../../domain/proxy/TaskProxy';
import { I18nService } from '../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../domain/service/NotificationService';
import { transient } from '../../../../../../inversify/decorator';

export enum TasksScreens {
  TASKS_LIST = 'TASKS_LIST',
  EDIT_TASK = 'EDIT_TASK',
}

export interface ViewTasksProps {
  pois: PoiModel[];
  district: DistrictModel | null;
  poi?: PoiModel | undefined;
  tasks: TaskModel[];
}

@transient()
export class ViewTasksVm extends ViewModel<ViewTasksProps> {

  @observable
  public step: TasksScreens = TasksScreens.TASKS_LIST;

  @observable
  public currentTask: TaskModel | null = null;

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TaskProxy) private readonly taskProxy: TaskProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
  ) {
    super();
    makeObservable(this);
  }

  @action
  public setStep = (screen: TasksScreens) => {
    this.step = screen;
  }

  @action
  public setTask = (task: TaskModel | null) => {
    const poiId = this.props.poi?.id;
    const districtId = this.props.district?.id;

    this.currentTask = task ? task.clone() : new TaskModel(poiId, districtId);
    return this.setStep(TasksScreens.EDIT_TASK);
  }

  public toggleTaskDone = new AsyncTask(async (task: TaskModel) => {
    try {
      if (!task) return;

      task.toggleDone();
      return await this.taskProxy.updateTask(task.toPutDto());
    } catch (e) {
      console.error(`error while handling task. ${e}`);
    }
  })

  public deleteTask = new AsyncTask(async (task: TaskModel) => {
    try {

      const response = await this.taskProxy.deleteTask(task);

      if (response.ok && response.status === 204) {
        const index = this.props.tasks.findIndex((t) => t.id === task.id);
        if (index !== -1) {
          runInAction(() => {
            this.props.tasks.splice(index, 1);
          });
        }
        return this.notification.success(this.i18n.t('task:delete.success'));
      }
      this.notification.error(this.i18n.t('task:delete.error'));

    } catch (e) {
      console.error(`error while handling task. ${e}`);
      this.notification.error(this.i18n.t('task:delete.error'));
    }
  });

}
