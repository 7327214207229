import { action, makeObservable, observable } from 'mobx';

import {
  QuotaPlanPostRequestDto,
  QuotaPlanSpeciePostRequestDto,
} from '../../shared/dto/quotaPlan.post.request.dto';
import { QuotaPlanPutRequestDto } from '../../shared/dto/quotaPlan.put.request.dto';
import {
  QuotaPlanResponseDto,
  QuotaPlanSpecieResponseDto,
} from '../../shared/dto/quotaPlan.response.dto';
import { QuotaPlanStatus } from '../../shared/enum/quotaPlanStatus.enum';
import { IClassification } from '../../util/classification/types/classification.types';
import { endOfDay, startOfDay } from 'date-fns';

export class QuotaPlanSpecieModel {
  @observable
  public group: string | null = null;

  @observable
  public species: string | null = null;

  @observable
  public gender: string | null = null;

  @observable
  public age: string | null = null;

  @observable
  public class: string | null = null;

  @observable
  public amount: number = 1;

  @observable
  public harvestAmount: number = 0;

  @observable
  public classificationObject: IClassification = {};

  constructor() {
    makeObservable(this);
  }

  @action
  public changeAmount = (amount: number): void => {
    if (amount < 1) {
      this.amount = 1;
    } else {
      this.amount = amount;
    }
  };

  @action
  public setClassification = (classification: IClassification) => {
    this.classificationObject = classification;

    this.group = classification.group ?? null;
    this.species = classification.species ?? null;
    this.gender = classification.gender ?? null;
    this.age = classification.age ?? null;
    this.class = classification.class ?? null;
  };

  public clone = () => {
    const cloned = new QuotaPlanSpecieModel();
    cloned.group = this.group;
    cloned.species = this.species;
    cloned.gender = this.gender;
    cloned.age = this.age;
    cloned.class = this.class;
    cloned.amount = this.amount;
    cloned.harvestAmount = this.harvestAmount;
    cloned.classificationObject = { ...this.classificationObject };

    return cloned;
  };

  public static fromDto(dto: QuotaPlanSpecieResponseDto) {
    const model = new QuotaPlanSpecieModel();
    model.species = dto.specie;
    model.group = dto.group;
    model.gender = dto.gender;
    model.age = dto.age;
    model.class = dto.class;
    model.amount = dto.amount;
    model.harvestAmount = dto.harvestAmount;
    model.classificationObject = {
      group: dto.group ?? undefined,
      species: dto.specie ?? undefined,
      gender: dto.gender ?? undefined,
      age: dto.age ?? undefined,
      class: dto.class ?? undefined,
    };

    return model;
  }

  public toPostDto = (
    quotaPlanSpecie: QuotaPlanSpecieModel
  ): QuotaPlanSpeciePostRequestDto => {
    const dto: QuotaPlanSpeciePostRequestDto = {
      specie: quotaPlanSpecie.species,
      group: quotaPlanSpecie.group ?? undefined,
      gender: quotaPlanSpecie.gender ?? undefined,
      age: quotaPlanSpecie.age ?? undefined,
      class: quotaPlanSpecie.class ?? undefined,
      amount: quotaPlanSpecie.amount,
    };

    return dto;
  };
}

export class QuotaPlanModel {
  @observable
  public id: string = '';

  @observable
  public districtId: string = '';

  @observable
  public name: string = '';

  @observable
  public startDate: Date;

  @observable
  public endDate: Date;

  @observable
  public status: QuotaPlanStatus = QuotaPlanStatus.FUTURE;

  @observable
  public species: QuotaPlanSpecieModel[] = [];

  constructor(districtId?: string) {
    makeObservable(this);
    if (districtId) {
      this.districtId = districtId;
    }
  }

  @action
  public setName = (name: string) => {
    this.name = name;
  };

  @action
  public setStartDate = (startDate: Date) => {
    this.startDate = startOfDay(startDate);
  };

  @action
  public setEndDate = (endDate: Date) => {
    this.endDate = endOfDay(endDate);
  };

  @action
  public setSpeciesList = (species: QuotaPlanSpecieModel[]) => {
    this.species = species;
  };

  public clone = () => {
    const cloned = new QuotaPlanModel();
    cloned.id = this.id;
    cloned.districtId = this.districtId;
    cloned.name = this.name;
    cloned.status = this.status;
    cloned.startDate = this.startDate;
    cloned.endDate = this.endDate;
    cloned.species = this.species.map((s) => s.clone());

    return cloned;
  };

  public static fromDto(dto: QuotaPlanResponseDto) {
    const model = new QuotaPlanModel();
    model.id = dto.id;
    model.districtId = dto.districtId;
    model.name = dto.name;
    model.status = dto.status;
    model.startDate = new Date(dto.startDate);
    model.endDate = new Date(dto.endDate);
    model.species = dto.species.map((s) => QuotaPlanSpecieModel.fromDto(s));

    return model;
  }

  public toPutDto = (quotaPlan: QuotaPlanModel): QuotaPlanPutRequestDto => {
    const dto: QuotaPlanPutRequestDto = {
      id: quotaPlan.id,
      districtId: quotaPlan.districtId,
      name: quotaPlan.name,
      startDate: quotaPlan.startDate.toISOString(),
      endDate: quotaPlan.endDate.toISOString(),
      species: quotaPlan.species.map((s) => s.toPostDto(s)),
    };

    return dto;
  };

  public toPostDto = (quotaPlan: QuotaPlanModel): QuotaPlanPostRequestDto => {
    const dto: QuotaPlanPostRequestDto = {
      districtId: quotaPlan.districtId,
      name: quotaPlan.name,
      startDate: quotaPlan.startDate.toISOString(),
      endDate: quotaPlan.endDate.toISOString(),
      species: quotaPlan.species.map((s) => s.toPostDto(s)),
    };

    return dto;
  };
}
