import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Chip, Grid, IconButton, Typography } from '@mui/material';

import { Assets } from '../../../../assets';
import { useVm } from '../../../../domain/hooks/useVm';
import { TrackingEvent } from '../../../../domain/service/tracking/TrackingEvent';
import { colors } from '../../../../theme/options/palette/const/colors';
import { FilterSidebarAnimalType } from './animal-type/FilterSidebarAnimalType';
import { FilterActive } from './components/FilterActive';
import {
  FilterSidebarProps, FilterSidebarTab, FilterSidebarTabEnum, FilterSidebarVm
} from './FilterSidebarVm';
import { FilterSidebarGeneral } from './general/FilterSidebarGeneral';
import { FilterSidebarMapPaths } from './map-paths/FilterSidebarMapPaths';
import { FilterSidebarPois } from './pois/FilterSidebarPois';
import { FilterSidebarSubzones } from './subzones/FilterSidebarSubzones';
import { FilterSidebarUser } from './user/FilterSidebarUser';

export const FilterSidebar = observer(function FilterSidebar(props: FilterSidebarProps) {
  const vm = useVm(FilterSidebarVm, props);
  const { t } = useTranslation();

  const renderHeader = useCallback(() => {
    return (
      <Box paddingLeft={4} width={'100%'}>
        {/* CLOSE ICON */}
        <Grid item container justifyContent={'flex-end'}>
          <IconButton color="inherit" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>

        {/* TITLE AND ICON */}
        <Grid item container direction="column" paddingBottom={1} marginTop={3}>
          <Grid item container alignItems={'center'}>
            <Grid item container justifyContent={'space-between'} alignItems="center">
              <Grid item xs={11} textAlign="start">
                <Typography variant="h4" sx={{ textShadow: '1px 1px 3px #00000029' }}>
                  {t('filter:filter_header')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }, [props, t]);

  const renderHeaderNoImages = useCallback(() => (
    <Grid container direction="column" justifyContent="center"
      sx={{
        background: colors.blueGrey[500],
        color: '#fff',
        paddingX: 2,
        paddingY: 1,
        minHeight: 150,
      }}
    >
      {renderHeader()}
    </Grid>
  ), [renderHeader]);

  useEffect(() => {
    if (props.districtFilterVm.hasActiveFilter) {
      vm.tracking.track(TrackingEvent.FILTER_ACTIVATED);
    }
  }, [props.districtFilterVm.hasActiveFilter, vm.tracking]);

  return (
    <Box height="100%" display="flex" flexDirection="column" pb={2}>

      {/* HEADER */}
      {renderHeaderNoImages()}

      <Box width={'100%'}>
        <Typography
          onClick={props.districtFilterVm.deactivateAll}
          sx={{ cursor: 'pointer' }}
          variant='subtitle2'
          color={colors.green[800]}
          textAlign={'right'}
          pr={3}
          pt={1}>
          {t('filter:reset_filter')}
        </Typography>
      </Box>

      {/* TABS */}
      <Grid container columnSpacing={1} alignItems="center"
        paddingX={4}
        paddingY={1}
        sx={{
          maxWidth: 'inherit',
          boxShadow: '0 8px 3px -6px #00000033',
        }}>
        {vm.poiTabs.map((tab: FilterSidebarTab) => {
          return (
            <Grid position="relative" item key={tab.name} paddingY={1}>
              <Chip
                clickable
                variant="outlined"
                label={t(tab.name)}
                onClick={() => vm.setTab(tab)}
                style={vm.tab?.name === tab.name
                  ? {
                    borderColor: colors.green[800],
                    color: colors.green[800],
                    backgroundColor: colors.green[50],
                  }
                  : {}
                }
              />
              {tab.hasFilterEnabled && <FilterActive />}
              {!tab.enabled && <img src={Assets.shield} style={{
                position: 'absolute',
                left: 2,
                top: -2,
                width: '24px'
              }} />}
            </Grid>
          );
        })}
      </Grid>

      <Box>
        {vm.tab?.name === FilterSidebarTabEnum.GENERAL && <FilterSidebarGeneral
          dateFilter={props.districtFilterVm.dateFilter}
          l1ClassificationFilter={props.districtFilterVm.l1ClassificationFilter}
          poiFilter={props.districtFilterVm.poiFilter}
          totalEntities={props.districtFilterVm.allMapBoundsEntries.length}
        />}
        {vm.tab?.name === FilterSidebarTabEnum.USER && <FilterSidebarUser
          userFilter={props.districtFilterVm.userFilter}
        />}
        {vm.tab?.name === FilterSidebarTabEnum.POI && <FilterSidebarPois
          poiFilter={props.districtFilterVm.poiFilter}
        />}
        {vm.tab?.name === FilterSidebarTabEnum.ANIMAL_TYPE && <FilterSidebarAnimalType
          animalTypeFilter={props.districtFilterVm.animalTypeFilter}
        />}
        {vm.tab?.name === FilterSidebarTabEnum.MAP_PATHS && <FilterSidebarMapPaths
          mapPathFilter={props.districtFilterVm.mapPathFilter}
        />}
        {vm.tab?.name === FilterSidebarTabEnum.SUBZONES && <FilterSidebarSubzones
          subzoneFilter={props.districtFilterVm.subzoneFilter}
        />}
      </Box>
    </Box>
  );
});
