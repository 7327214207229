import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ImageCarousel.css';

import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import { Box, Grid } from '@mui/material';

import { EntryModel } from '../../../domain/model/EntryModel';
import { PhotoModel } from '../../../domain/model/PhotoModel';
import { PoiModel } from '../../../domain/model/PoiModel';
import { colors } from '../../../theme/options/palette/const/colors';
import { ImagePreview } from '../image-preview/ImagePreview';

export interface ImageCarouselProps<T> extends React.PropsWithChildren<unknown> {
  item: T;
  onUpsert: (item: T) => void;
  locizeNamespace: 'entry' | 'poi';
  canDeleteImage: boolean;
  onClose?: () => void;
}

export function ImageCarousel(props: ImageCarouselProps<PoiModel | EntryModel>) {
  const [index, setIndex] = useState(-1);

  return (
    <Box>
      <Carousel
        infiniteLoop={true}
        emulateTouch
        showStatus={false}
        showThumbs={false}
        showArrows={false}
      >
        {props.item.photos.map((photo: PhotoModel, index: number) => {
          return (
            <Grid key={photo.id} container direction="column" justifyContent="center" alignItems="flex-start"
              sx={{
                backgroundImage: `url(${photo.url})`,
                backgroundPositionX: 'center',
                backgroundPositionY: 'center',
                backgroundSize: 'cover',
                color: colors.paper,
                paddingX: 2,
                minHeight: 150
              }}
              onClick={() => setIndex(index)}
            >
              {props.children}
            </Grid>
          );
        })}
      </Carousel>

      <ImagePreview
        index={index}
        item={props.item}
        canDeleteImage={props.canDeleteImage}
        locizeNamespace="entry"
        onUpsert={() => props.onUpsert(props.item)}
        onSetIndex={setIndex}
      />

    </Box>
  );
}
