import React, { useCallback } from 'react';

import { Grid, SxProps, Theme, Typography } from '@mui/material';
import { DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

interface ICustomDatePickerProps {
  label: string;
  selectedDate: Date | null;
  onChange: (value: Date) => void;
  format?: string;
  readOnly?: boolean;
  gridItemSx?: SxProps<Theme>;
}

export const CustomDatePicker: React.FC<ICustomDatePickerProps> = ({ label, selectedDate, onChange, format = 'dd. MMMM yyyy', readOnly = false, gridItemSx }) => {
  const handleChange = useCallback((value: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
    if (!value || context.validationError) {
      return;
    }
    onChange(value);
  }, [onChange]);

  return (
    <Grid item xs={6} sx={gridItemSx}>
      <Typography fontSize={17} my={0.5}>
        {label}
      </Typography>
      <DesktopDatePicker
        format={format}
        value={selectedDate}
        onChange={handleChange}
        readOnly={readOnly}
      />
    </Grid>
  );
};
