import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Assets } from './assets';

const useStyles = makeStyles(() => ({
  container: {
    height: '100vh',
  },
  image: {
    width: '80px',
  }
}));

export function LoadingFallback() {
  const styles = useStyles();

  return (
    <Grid className={styles.container} container justifyContent="center" alignItems="center">
      <Grid item zeroMinWidth>
        <img className={styles.image} src={Assets.loading_logo} />
      </Grid>
    </Grid>
  );
}
