import { IsEmail, IsEnum, IsOptional, IsString, MinLength } from 'class-validator';

import { ApiProperty } from '@nestjs/swagger';

import { LANGUAGE } from '../enum';

export class UserPostLoginRequestDto {

  @IsEmail({}, { context: { errorCode: 422 } })
  @ApiProperty({ description: 'should be a valid email' })
  public email!: string;

  @IsOptional()
  @IsEnum(LANGUAGE)
  @ApiProperty()
  public language?: LANGUAGE;

  @IsString({ context: { errorCode: 422 } })
  @MinLength(8, { context: { errorCode: 422 } })
  @ApiProperty({ description: 'minimum 8 characters length' })
  public password!: string;
}
