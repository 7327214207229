import { ApiProperty } from '@nestjs/swagger';
import { IsNotEmpty, IsOptional, IsString, IsUUID } from 'class-validator';
import { IGeoLocation } from '../interfaces/IGeoLocation';
import { PoiPostRequestDto } from './poi.post.request.dto';

export class DistrictPostRequestDto {

  @IsOptional()
  @IsUUID()
  @ApiProperty({ required: false })
  public id?: string;

  @IsString()
  @IsNotEmpty()
  @ApiProperty()
  public name: string;

  @ApiProperty({ required: false })
  public mesh: any;

  @ApiProperty()
  public geoMesh: IGeoLocation[];

  @ApiProperty()
  public pois?: PoiPostRequestDto[];
}
