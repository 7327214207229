import { Box } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useMemo } from 'react';
import Map, { MapLayerMouseEvent } from 'react-map-gl';

// https://github.com/visgl/react-map-gl/issues/1266
// eslint-disable-next-line @typescript-eslint/no-var-requires, @typescript-eslint/no-explicit-any
(mapboxgl as any).workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

import { useKeyPress } from 'react-use';
import { IGeoLocation } from '../../../../domain/core/IGeoLocation';
import { useInstance } from '../../../../domain/hooks/useInstance';
import { DistrictModel } from '../../../../domain/model/DistrictModel';
import { GeolocationService } from '../../../../domain/service/GeolocationService';
import { env } from '../../../../env';
import { MapboxDistrictMeshLines } from './MapboxDistrictMeshLines';
import { MapboxDistrictMeshPoint } from './MapboxDistrictMeshPoint';
import { MapboxMapVm } from './MapboxMapVm';

interface IProps {
  vm: MapboxMapVm;
  styleUrl: string;
  district: DistrictModel | null;
  onMapClick: (location: IGeoLocation) => void;
  cursor: string | undefined;
}

export const MapboxMap = observer(function MapboxMap(props: React.PropsWithChildren<IProps>) {
  const geolocationService = useInstance(GeolocationService);

  const [backspacePressed] = useKeyPress(
    useCallback((event: KeyboardEvent) => event.key === 'Backspace', [])
  );

  const defaultProps = useMemo(() => {
    return {
      center: {
        lat: geolocationService.defaultLocation.location.latitude,
        lng: geolocationService.defaultLocation.location.longitude,
      },
      zoom: geolocationService.defaultLocation.zoom,
    };
  }, [geolocationService]);

  const onMapClick = useCallback((e: MapLayerMouseEvent) => {
    const features = props.vm.mapRef.current?.queryRenderedFeatures([
      [e.point.x - 5, e.point.y - 5],
      [e.point.x + 5, e.point.y + 5]
    ]);

    const meshPointLayer = features?.find((f) => {
      if (f.source.startsWith('district-mesh-point-')) {
        return f;
      }
    });

    if (meshPointLayer) {
      const startIndex = parseInt(meshPointLayer.source.replace('district-mesh-point-', ''));
      props.district?.mesh.selectPair([
        props.district?.mesh.points[startIndex],
        props.district?.mesh.points[startIndex + 1],
      ]);

      e.originalEvent.stopPropagation();
      return;
    }

    props.onMapClick({ latitude: e.lngLat.lat, longitude: e.lngLat.lng });
  }, [props]);

  useEffect(() => {
    if (backspacePressed) {
      props.district?.mesh.removeSelection();
    }
  }, [props.district?.mesh, backspacePressed]);

  const onMapReady = useCallback(() => {
    props.vm.onMapReady();

    if (props.district?.mesh.points.length) {
      props.vm.centerMesh(props.district?.mesh.points);
    }

    if (props.vm.mapRef.current) {
      props.vm.mapRef.current.getCanvas().style.cursor = props.cursor ?? 'default';
      props.vm.mapRef.current.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    }
  }, [props.vm, props.district?.mesh, props.cursor]);

  return (
    <Box
      position={'relative'}
      width={'100%'}
      height={'100%'}
      borderRadius={'16px'}
      overflow={'hidden'}
    >
      <Map
        ref={props.vm.mapRef}
        onLoad={onMapReady}
        initialViewState={{
          longitude: defaultProps.center.lng,
          latitude: defaultProps.center.lat,
          zoom: defaultProps.zoom,
        }}
        onClick={onMapClick}
        mapboxAccessToken={env.mapbox.key}
        mapStyle={props.styleUrl}
      >
        {props.district?.mesh && <MapboxDistrictMeshLines mesh={props.district.mesh} />}
        {!props.district?.mesh.isMeshClosed && props.district?.mesh.points.map((point, index) => (
          <MapboxDistrictMeshPoint
            key={`district-${props.district?.id}-point-${index}`}
            point={point}
            onDelete={(point) => props.district?.mesh.removeMeshPoint(point)}
            selected={props.district?.mesh.selectedMeshPoint?.latitude === point?.latitude &&
              props.district.mesh.selectedMeshPoint?.longitude === point?.longitude}
            onMeshPointClick={(point) => props.district?.mesh.selectMeshPoint(point)}
            updatePointLocation={(point) => props.district?.mesh.updatePointLocation(index, point, false)}
          />
        ))}
      </Map>
      {props.children}
    </Box>
  );
});
