import { Type } from 'class-transformer';
import {
  IsBoolean,
    IsHexColor, IsNotEmpty, IsOptional, IsString, IsUUID, ValidateNested
} from 'class-validator';

import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';

import { GeoLocationRequestDto } from './geolocation.request.dto';

export class MapPathPostRequestDto {

  @IsUUID()
  @ApiProperty()
  @IsOptional()
  public id?: string;

  @IsString()
  @IsNotEmpty()
  @ApiProperty()
  public title: string;

  @IsHexColor()
  @IsOptional()
  @ApiProperty()
  public color?: string;

  @IsUUID()
  @ApiProperty()
  public typeId: string;

  @IsUUID()
  @IsOptional()
  @ApiPropertyOptional()
  public districtId?: string;

  @ValidateNested({ each: true })
  @Type(() => GeoLocationRequestDto)
  @IsNotEmpty()
  @ApiProperty()
  public points: GeoLocationRequestDto[];

  @IsBoolean()
  @IsOptional()
  @ApiProperty()
  public gpsTracked?: boolean;
}
