import { useCallback } from 'react';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Box, colors, IconButton, Typography } from '@mui/material';

import { useContainer } from '../../../../../../../../domain/hooks/useContainer';
import { useInstance } from '../../../../../../../../domain/hooks/useInstance';
import { QuotaPlanSpecieModel } from '../../../../../../../../domain/model/QuotaPlanModel';
import { ClassificationHelper } from '../../../../../../../../util/classification/ClassificationHelper';
import { ClassificationTranslationService } from '../../../../../../../../util/classification/ClassificationTranslationService';
import { IClassification } from '../../../../../../../../util/classification/types/classification.types';
import { Translations } from '../../../../../../../../util/translations/translations';

interface IProps {
  quotaPlanSpecies: QuotaPlanSpecieModel[];
  showGame: boolean;
  onDelete?: (specie: QuotaPlanSpecieModel) => void;
}

const renameAndCleanObject = (obj: QuotaPlanSpecieModel): IClassification => {
  const newObj = { ...obj };

  return newObj as IClassification;
};

export function QuotaPlanList({ quotaPlanSpecies, showGame = true, onDelete }: IProps) {
  const classificationHelper = useInstance(ClassificationHelper);
  const classificationTranslationService = useContainer().get(
    ClassificationTranslationService
  );

  const buildAnimalName = useCallback(
    (quotaPlanSpecie: QuotaPlanSpecieModel) => {
      const t = (key: string) =>
        classificationTranslationService.sharedTranslations.translate(key);
      const ct = (key: string) =>
        classificationTranslationService.sharedTranslations.canTranslate(key);

      const classification = renameAndCleanObject(quotaPlanSpecie);
      const name = [];
      const specieTKey = Translations.getKeyForSpecies(classification.species!);
      name.push(t(specieTKey));

      if (classification.age && classification.gender === null) {
        const ageTKey = Translations.getKeyForAge(classification.age);
        if (ct(`${specieTKey}.${ageTKey}`)) {
          name.push(t(`${specieTKey}.${ageTKey}`));
        } else {
          name.push(t(ageTKey));
        }
      }
      if (classification.age && classification.gender) {
        const ageTKey = Translations.getKeyForAge(classification.age);
        const genderTKey = Translations.getKeyForGender(classification.gender);
        if (ct(`${specieTKey}.${ageTKey}.${genderTKey}`)) {
          name.push(t(`${specieTKey}.${ageTKey}.${genderTKey}`));
        } else if (ct(`${specieTKey}.${ageTKey}`)) {
          name.push(t(`${specieTKey}.${ageTKey}`));
          name.push(t(genderTKey));
        } else {
          name.push(t(ageTKey));
          name.push(t(genderTKey));
        }
      }
      if (classification.class) {
        name.push(t(Translations.getKeyForClass(classification.class)));
      }
      return name.join(' ');
    },
    [classificationTranslationService]
  );

  return (
    <Box display="flex" flexDirection={'column'} width="100%">
      {quotaPlanSpecies.map((specie, index) => {
        const imgUrl = classificationHelper.getClassificationIcon(
          {
            group: specie.group || undefined,
            species: specie.species || undefined,
          },
          true
        );
        return (
          <Box
            key={`quota-plan-item-${index}`}
            borderBottom={1}
            borderColor={colors.blueGrey[100]}
            minHeight={'50px'}
            display="flex"
            flex={1}
            width="100%"
            alignItems={'center'}
            flexDirection={'row'}
          >
            {imgUrl && (
              <img
                style={{ width: '40px', marginRight: '12px' }}
                src={imgUrl}
              />
            )}
            <Typography paddingRight={1}>{buildAnimalName(specie)}</Typography>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="end"
              flexGrow={1}
            >
              {showGame && (
                <>
                  <Typography variant="subtitle1" color={specie.harvestAmount > specie.amount ? colors.red[500] : colors.orange[600]}>
                    {specie.harvestAmount !== 0 ? specie.harvestAmount : '-'}
                  </Typography>
                  <Typography variant="subtitle1">&nbsp;/&nbsp;</Typography>
                </>
              )}
              <Typography variant="subtitle1">{specie.amount}</Typography>
            </Box>
            {onDelete && (
              <IconButton
                onClick={() => onDelete!(specie)}
                sx={{ paddingLeft: 2 }}
                disableRipple={true}
              >
                <DeleteForeverOutlinedIcon />
              </IconButton>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
