import { ApiProperty } from '@nestjs/swagger';
import { MemberResponseDto } from './districtMember.response.dto';
import { PoiResponseDto } from './poi.response.dto';


export class DeprecatedDistrictResponseDto {

  @ApiProperty()
  public id: string;

  @ApiProperty()
  public name: string;

  @ApiProperty({ type: MemberResponseDto })
  public self: MemberResponseDto;

  @ApiProperty({ type: MemberResponseDto })
  public members: MemberResponseDto[];

  @ApiProperty({ type: PoiResponseDto})
  public pois: PoiResponseDto[];

  @ApiProperty()
  public mesh: any;
}
