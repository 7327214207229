import { ApiProperty } from '@nestjs/swagger';
import { IsOptional } from 'class-validator';

export class GunPodResponseDto {
  @ApiProperty()
  public id: string;

  @ApiProperty()
  public deviceId: string;

  @ApiProperty()
  public gunPodCounter: number;

  @ApiProperty()
  @IsOptional()
  public deviceName?: string | undefined;
}
