import UndoIcon from '@mui/icons-material/Undo';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  onCancel?: () => void;
  onUndo: () => void;
  onSave?: () => void;
  isSaving?: boolean;
  saveable?: boolean;
}

export function MeshSetupControls(props: IProps) {
  const { t } = useTranslation();

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Stack direction={'row'} spacing={4}>
        {props.onCancel && <Button sx={{
          paddingX: 3,
          backgroundColor: 'white !important',
          borderWidth: '2px !important'
        }}
          variant="outlined"
          color="error"
          onClick={props.onCancel}
        >
          {t('district:district_setup_cancel')}
        </Button>}
        <Button sx={{
          paddingX: 3,
          backgroundColor: 'white !important',
          borderWidth: '2px !important'
        }}
          variant="outlined"
          startIcon={<UndoIcon />}
          onClick={props.onUndo}
        >
          {t('district:district_setup_undo')}
        </Button>
        {props.onSave && (props.saveable ? <LoadingButton
          loading={props.isSaving}
          variant="outlined"
          color="secondary"
          sx={{
            paddingX: 3,
            backgroundColor: 'white !important',
            borderWidth: '2px !important',
          }}
          onClick={props.onSave}
        >
          {t('district:district_setup_save')}
        </LoadingButton> : <Tooltip title={<Typography paddingX={2} paddingY={1}>
          {t('district:district_setup_cannot_save_message')}
        </Typography>}>
          <span>
            <LoadingButton
              disabled
              variant="outlined"
              color="secondary"
              sx={{
                paddingX: 3,
                backgroundColor: 'white !important',
                borderWidth: '2px !important',
              }}
              onClick={props.onSave}
            >
              {t('district:district_setup_save')}
            </LoadingButton>
          </span>
        </Tooltip>)}
      </Stack>
    </Box>
  );
}
