import { TypographyOptions } from '@mui/material/styles/createTypography';

export const typography: TypographyOptions = {
  fontFamily: [
    'Roboto',
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  allVariants: {
    fontWeight: 'normal',
  },
  h1: {
    fontWeight: 'bold',
    fontSize: '2rem', // 32px
  },
  h2: {
    fontWeight: 500,
    fontSize: '1.875rem', // 30px
  },
  h3: {
    fontWeight: 700,
    fontSize: '1.75rem',  // 28px
  },
  h4: {
    fontWeight: 500,
    fontSize: '1.5rem', // 24px
  },
  h5: {
    fontWeight: 300,
    fontSize: '1.375rem', // 22px
  },
  h6: {
    fontWeight: 500,
    fontSize: '1.125rem', // 18px
  },
  subtitle1: {
    fontWeight: 400,
    fontSize: '1rem', // 16px
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: '0.875rem', // 14px
  },
  body1: {
    fontWeight: 400,
    // 16px
    size: '1rem',
  },
  body2: {
    fontWeight: 400,
    // 14px
    tabSize: '0.875rem',
  },
  button: {
    fontWeight: '500',
    fontSize: '0.9375rem',  // 15px
  },
  caption: {
    fontWeight: 500,
    fontSize: '0.75rem',  // 12px
  },
  overline: {
    fontWeight: 500,
    fontSize: '0.625rem', // 10px
  },

  // =============== REDESIGN VALUES==================
  // HEADINGS
  headingTitleDesktop: {
    fontSize: '2rem', // 32px
    fontWeight: 600,
    lineHeight: 1.15,
  },
  headingTitleMobile: {
    fontSize: '1.5rem', // 24px
    fontWeight: 600,
    lineHeight: 1.25,
  },
  headingSubtitleDesktop: {
    fontSize: '1.5rem', // 24px
    fontWeight: 600,
    lineHeight: 1.25,
  },
  headingSubtitleMobile: {
    fontSize: '1.25rem', // 20px
    fontWeight: 600,
    lineHeight: 1.25,
  },
  // TEXT
  text_sm_regular: {
    fontSize: '0.875rem',   // 14px
    lineHeight: 1.35,
  },
  text_sm_semibold: {
    fontSize: '0.875rem',   // 14px
    fontWeight: 600,
    lineHeight: 1.35,
  },
  text_md_regular: {
    fontSize: '1rem',   // 16px
    lineHeight: 1.35,
  },
  text_md_semibold: {
    fontSize: '1rem',   // 16px
    fontWeight: '600',
    lineHeight: 1.35,
  },
  text_xs_regular: {
    fontSize: '0.75rem',   // 12px
    lineHeight: 1.15,
  },
  text_xs_medium: {
    fontSize: '0.75rem',   // 12px
    fontWeight: 500,
    lineHeight: 1.15,
  },
  text_xxs_medium: {
    fontSize: '0.625rem',   // 10px
    fontWeight: 500,
    lineHeight: 1,
  },
};
