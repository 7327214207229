import React, { PropsWithChildren, useEffect } from 'react';
import {
  FieldValues, FormProvider, Mode, SubmitHandler, useForm, UseFormProps, UseFormReturn
} from 'react-hook-form';
import { useEffectOnce } from 'react-use';

interface IProps<T> extends UseFormProps<T> {
  onSubmit?: SubmitHandler<T>;
  onBlur?: SubmitHandler<T>;
  autoReset?: boolean;
  validateOnMount?: boolean;
  onForm?: (form: UseFormReturn<T>) => void;
  mode?: Mode;
}

export function Form<T extends FieldValues>(props: PropsWithChildren<IProps<T>>) {
  const { autoReset = true } = props;
  const form = useForm<T>({
    defaultValues: props.defaultValues,
    mode: props.mode ?? 'onChange',
  });

  useEffectOnce(() => {
    if (props.validateOnMount) {
      form.trigger();
    }

    props.onForm?.(form);
  });

  useEffect(() => {
    if (autoReset && form.formState.isDirty) {
      form.reset(props.defaultValues);
    }
  }, [autoReset, form, props.defaultValues]);

  return (
    <FormProvider {...form}>
      {props.onSubmit && <form onSubmit={form.handleSubmit(props.onSubmit)}>
        {props.children}
      </form>}
      {props.onBlur && <form onBlur={form.handleSubmit(props.onBlur)}>
        {props.children}
      </form>}

    </FormProvider>
  );
}
