import Bugsnag, { BreadcrumbType } from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import React from 'react';

import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { UserModel } from '../model/UserModel';

@singleton()
export class BugsnagService {

  private readonly apiKey: string | undefined = process.env.REACT_APP_BUGSNAG_API_KEY;

  private readonly enabledReleaseStages = env.bugsnag.enabledReleaseStages;

  private readonly enabledBreadcrumbTypes: BreadcrumbType[] = env.bugsnag.enabledBreadcrumbTypes;

  public readonly startBugsnag = () => {
    if (!this.apiKey) {
      throw new Error('Bugsnag api key missing!');
    }

    Bugsnag.start({
      apiKey: this.apiKey,
      plugins: [new BugsnagPluginReact()],
      enabledReleaseStages: this.enabledReleaseStages,
      enabledBreadcrumbTypes: this.enabledBreadcrumbTypes,
      logger: null,
    });
  }

  public readonly createErrorBoundary = (): BugsnagErrorBoundary => {
    return Bugsnag.getPlugin('react')!.createErrorBoundary(React);
  }

  public setUser = (user: UserModel | null): void => {
    if (user) {
      Bugsnag.setUser(user.id, user.email, `${user.firstName} ${user.lastName}`);
    } else {
      Bugsnag.setUser('unknown');
    }
  };
}
