import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { colors } from '../../../../theme/options/palette/const/colors';
import { EntryMarkerVm } from './marker/EntryMarkerVm';

interface EntryTooltipProps {
  vm: EntryMarkerVm;
}

export function EntryTooltip({ vm }: EntryTooltipProps) {
  return (
    <React.Fragment>
      <Box>
        <Grid container direction="column" justifyContent="center" sx={{ borderRadius: 10 }}>
          <Grid item padding={1}
            sx={{
              background: vm.entryHoverBackground,
              color: colors.paper,
              borderTopLeftRadius: 4,
              borderTopRightRadius: 4,
            }}
          >
            <Typography fontSize={13} fontWeight="regular">
              {vm.classificationDescription}
            </Typography>
          </Grid>
          <Grid item padding={1} sx={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }} >
            <Typography fontSize={13} fontWeight="regular">
              {vm.entryDate('PP')}
            </Typography>
            <Typography fontSize={13} fontWeight="regular">
              {vm.entryDate('HH:mm')}
            </Typography>
            <Typography fontSize={13} fontWeight="regular">
              {vm.ownerName}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
