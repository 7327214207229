import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';

export interface ItemAccordionProps extends React.PropsWithChildren<unknown> {
  selectedItem: string;
}

export function ItemAccordion(props: ItemAccordionProps) {
  return (
    <Box mt={0.5}>
      <Accordion sx={{ width: 'inherit', boxShadow: 'none', }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            '& .MuiAccordionSummary-content': {
              display: 'flex',
              justifyContent: 'center',
            },
          }}
        >
          <Box
            my={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="#000"
            sx={{
              fontSize: 13,
              background: '#F2F2F7 0% 0% no-repeat padding-box',
              borderRadius: 23,
              minWidth: '306px',
              height: '46px'
            }}
          >{props.selectedItem}</Box>
        </AccordionSummary>
        <AccordionDetails>
          {props.children}
        </AccordionDetails>
      </Accordion>
    </Box>

  );
}
