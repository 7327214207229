import React, { useCallback, useImperativeHandle, useState } from 'react';

type ValidationMode = 'onBlur' | 'onSubmit';
type SubmitMode = 'onBlur' | 'onSubmit';
type ValidationFn = () => Promise<boolean>;

interface IValidatedFormProps {
  onSubmit: () => void;
  validationMode?: ValidationMode;
  submitMode?: SubmitMode;
  revalidateOnChange?: boolean;
  styleProps?: React.CSSProperties;
}

interface IValidatedFormContext {
  registerField: (field: string, validate: ValidationFn) => void;
  deregisterField: (field: string) => void;
  setValid: (field: string, valid: boolean) => void;
  submit: () => Promise<void>;
  submitMode: SubmitMode;
  validationMode: ValidationMode;
  isValid: boolean;
  revalidateOnChange: boolean;
}

interface IFieldsState {
  [field: string]: { valid: boolean, validate: ValidationFn }
}

export type ValidatedFormImperativeRef = {
  submit: () => Promise<void>;
}

export const ValidatedFormContext = React.createContext<IValidatedFormContext>({} as IValidatedFormContext);

export const ValidatedForm = React.forwardRef<ValidatedFormImperativeRef, React.PropsWithChildren<IValidatedFormProps>>((props, ref) => {
  const {
    validationMode = 'onSubmit',
    submitMode = 'onSubmit',
    revalidateOnChange = true
  } = props;

  const [fields] = useState<IFieldsState>({});
  const [isValid, setIsValid] = useState(false);

  useImperativeHandle(ref, () => ({
    submit
  }));

  const validateForm = useCallback(async () => {
    for (const id in fields) {
      fields[id].valid = await fields[id].validate();
    }

    const hasError = Object.values(fields).some((f) => !f.valid);
    setIsValid(!hasError);

    return hasError;
  }, [fields]);

  const submit = useCallback(async () => {
    const hasError = await validateForm();

    if (!hasError) {
      props.onSubmit();
    }
  }, [props, validateForm]);

  const onSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submit();
  }, [submit]);

  const onBlur = useCallback(() => {
    if (submitMode === 'onBlur') {
      submit();
    }
  }, [submit, submitMode]);

  const setValid = useCallback((field: string, valid: boolean) => {
    fields[field].valid = valid;
    setIsValid(Object.values(fields).every((f) => f.valid));
  }, [fields]);

  const registerField = useCallback((field: string, validate: ValidationFn) => {
    fields[field] = fields[field] ?? {
      valid: false,
      validate,
    };

    fields[field].validate = validate;
  }, [fields]);

  const deregisterField = useCallback((field: string) => {
    delete fields[field];
  }, [fields]);

  return (
    <form onSubmit={onSubmit} onBlur={onBlur} style={{ ...props.styleProps }}>
      <ValidatedFormContext.Provider value={{
        validationMode,
        revalidateOnChange,
        isValid,
        submitMode,
        submit,
        setValid,
        registerField,
        deregisterField,
      }}>
        {props.children}
      </ValidatedFormContext.Provider>
    </form>
  );
});

ValidatedForm.displayName = 'ValidatedForm';
