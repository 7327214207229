import './EntryDetails.css';

import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';

import {
  Box, Divider, FormControl, Grid, Input, InputAdornment, Switch, TextareaAutosize, TextField,
  Typography
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Assets } from '../../../../../../assets';
import { useContainer } from '../../../../../../domain/hooks/useContainer';
import { useVm } from '../../../../../../domain/hooks/useVm';
import { EntryModel } from '../../../../../../domain/model/EntryModel';
import { ENTRY_TYPE } from '../../../../../../shared/enum/entryType.enum';
import { palette } from '../../../../../../theme/options/palette/palette';
import { CustomDatePicker } from '../../../../../../toolkit/components/CustomDatePicker';
import { appLocaleList } from '../../../../../../toolkit/constants/LanguageList';
import { EntryHelper } from '../../../../../../util/EntryHelper';
import { EntryDetailsVm } from './EntryDetailsVm';

export interface EntryDetailsProps {
  entry: EntryModel;
  canEditAndDeleteEntries: boolean;
  isHarvestVisibleAndEditable: boolean;
  isBusy?: boolean;
}

export const EntryDetails = observer(function EntryDetails(props: EntryDetailsProps) {
  const { t } = useTranslation();
  const vm = useVm(EntryDetailsVm, props);
  const entryHelper = useContainer().get(EntryHelper);

  const checkPermissions = useCallback(() => {
    if (!props.canEditAndDeleteEntries) {
      if (!props.isHarvestVisibleAndEditable) {
        return entryHelper.notifyWrongPermission('entry:harvest_readonly_permission');
      }
      return entryHelper.notifyWrongPermission();
    }
  }, [entryHelper, props.canEditAndDeleteEntries, props.isHarvestVisibleAndEditable]);

  const handleDescriptionChange = useCallback((e) => {
    props.entry.setDescription(e.target.value);
  }, [props.entry]);

  const handleTimeChange = useCallback((e) => {
    props.entry.setUserTime(e.target.value);
  }, [props.entry]);

  const handleWeightChange = useCallback((e) => {
    vm.weightModel?.setAmount(e.target.value ? parseFloat(e.target.value) : null);
  }, [vm.weightModel]);

  const handleAgeChange = useCallback((e) => {
    props.entry.setSpecificAge(e.target.value ? parseInt(e.target.value) : null);
  }, [props.entry]);


  return (
    <Box color={palette.text?.primary} width="inherit">
      {/* NOTES */}
      <Box paddingX="18px" marginY={1} display="flex" alignItems="center">
        <FormControl fullWidth>
          <TextareaAutosize
            readOnly={!props.canEditAndDeleteEntries}
            value={props.entry.description}
            onFocus={checkPermissions}
            onChange={handleDescriptionChange}
            placeholder={t('entry:view.details.notes_placeholder')}
            style={{
              fontFamily: 'roboto',
              fontWeight: 'normal',
              fontSize: '16px',
              color: palette.text?.entryForm,
              width: '100%',
              minWidth: '100%',
              maxWidth: '100%',
              border: 'none',
            }}
          />
        </FormControl>
      </Box>
      <Divider />

      {/* VISIBLE TO ALL PARTICIPANTS -> HIDE until given different instruction */}
      {vm.showPublicVisibilitySwitch && <React.Fragment>
        <Box paddingX="18px">
          <FormControl fullWidth>
            <Grid container justifyContent="space-between" alignItems="center">
              {t('entry:visibility')}
              <Switch
                color='warning'
                checked={!props.entry.isPrivate}
                onChange={(e, visible) => {
                  checkPermissions();
                  props.entry.setIsPrivate(!visible);
                }}
                disabled={!props.canEditAndDeleteEntries}
              />
            </Grid>
          </FormControl>
        </Box>
        <Divider />
      </React.Fragment>}
      <Grid container paddingX={4} alignItems="center" direction="column" width='inherit'>
        {/* OWNER */}
        <Grid item container marginY={1} spacing={1} direction="column">
          <Grid item>
            <Typography fontSize={17} color="primary">{t('entry:view.details.subtitle_1')}</Typography>
          </Grid>
          <Grid item container alignItems="center" spacing={1}>
            <Grid item>
              <Avatar name={props.entry.ownerName} round size="40px" src={props.entry.owner?.photo?.thumbnailUrl || ''} />
            </Grid>
            <Grid item>
              <Typography fontSize={17} color={palette.text?.entryForm} fontWeight="bold">{props.entry.ownerName}</Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
        </Grid>
        {/* DATE & TIME */}
        <Grid container marginY={1} justifyContent="space-between">
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={appLocaleList[vm.i18n.language]}>
            {/* DATE */}
            <Grid item xs={12} md={7} container spacing={1} direction="column" onFocus={checkPermissions}>
              <CustomDatePicker
                label={t('entry:view.details.subtitle_2')}
                selectedDate={props.entry.userDate}
                onChange={props.entry.setUserDate}
                readOnly={!props.canEditAndDeleteEntries}
              />
            </Grid>
            {/* TIME */}
            <Grid item xs={12} md={4} container spacing={1} direction="column">
              <Grid item>
                <Typography fontSize={17} color="primary">{t('entry:view.details.subtitle_3')}</Typography>
              </Grid>
              <Grid item>
                <TextField
                  type="time"
                  value={props.entry.time}
                  onFocus={checkPermissions}
                  onChange={handleTimeChange}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                  InputProps={{
                    readOnly: !props.canEditAndDeleteEntries,
                  }}
                  sx={{ width: 'inherit' }}
                />
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Grid>
        {/* WEIGHT */}
        {
          vm.renderWeightAndAge && <Grid item container marginY={1} direction="column">
            <FormControl fullWidth>
              <Grid item>
                <Typography fontSize={17} color="primary">{t('entry:view.details.subtitle_4')}</Typography>
              </Grid>
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <img src={Assets.entry.detailsIcons.gray_weight} width={40} height={40} />
                </Grid>
                {/**
               *  Input text was getting cut off so this is solution:
               *    <Grid item xs> -> takes the remaining space of Grid container
               *    <Input fullwidth> -> self explanatory
               */}
                <Grid item xs>
                  <Input
                    onFocus={checkPermissions}
                    type="number"
                    placeholder={t('entry:view.details.weight_empty')}
                    sx={{
                      fontSize: 17,
                      fontWeight: 500,
                      color: vm.weightAmount ? palette.text?.entryForm : palette.text?.details,
                      input: {
                        '&::placeholder': {
                          color: palette.text?.details
                        },
                      }
                    }}
                    readOnly={!props.canEditAndDeleteEntries}
                    value={vm.weightAmount}
                    onChange={handleWeightChange}
                    disableUnderline
                    endAdornment={
                      vm.weightModel?.amount && <InputAdornment position="start">
                        <Typography
                          fontSize={17}
                          fontWeight={500}
                          color={vm.weightAmount ? palette.text?.entryForm : palette.text?.details}
                        >
                          {vm.weightUnit}
                        </Typography>
                      </InputAdornment>}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Divider />
              </Grid>
            </FormControl>
          </Grid>
        }

        {/* AGE */}
        {
          vm.renderWeightAndAge && <Grid item container marginY={1} direction="column">
            <FormControl fullWidth>
              <Grid item>
                <Typography fontSize={17} color="primary">{t('entry:view.details.subtitle_5')}</Typography>
              </Grid>
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <img src={Assets.entry.detailsIcons.gray_time_back} width={40} height={40} />
                </Grid>
                <Grid item xs>
                  <Input
                    onFocus={checkPermissions}
                    type="number"
                    placeholder={t('entry:view.details.age_empty')}
                    sx={{
                      fontSize: 17,
                      fontWeight: 500,
                      color: props.entry.specificAge ? palette.text?.entryForm : palette.text?.details,
                      input: {
                        '&::placeholder': {
                          color: palette.text?.details
                        },
                      }
                    }}
                    readOnly={!props.canEditAndDeleteEntries}
                    value={props.entry.specificAge ?? ''}
                    onChange={handleAgeChange}
                    disableUnderline
                    endAdornment={
                      props.entry.specificAge && <InputAdornment position="start">
                        <Typography
                          fontSize={17}
                          fontWeight={500}
                          color={props.entry.specificAge ? palette.text?.entryForm : palette.text?.details}
                        >
                          {t('entry:view.details.age_unit')}
                        </Typography>
                      </InputAdornment>}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <Divider />
              </Grid>
            </FormControl>
          </Grid>
        }

        {/* POSITION */}
        {props.entry.entryType !== ENTRY_TYPE.MISC && <>
          <Grid item container marginY={1} direction="column">
            <Grid item>
              <Typography fontSize={17} color="primary">{t('entry:view.details.subtitle_6')}</Typography>
            </Grid>
            <Grid item container alignItems="center" spacing={1}>
              <Grid item>
                <img src={Assets.entry.detailsIcons.gray_position} width={40} height={40} />
              </Grid>
              <Grid item xs>
                <Input
                  fullWidth
                  sx={{
                    fontSize: 17,
                    fontWeight: 500,
                    color: props.entry.shot?.poi?.name ? palette.text?.entryForm : palette.text?.details,
                  }}
                  value={props.entry.shot?.poi?.name || t('entry:view.details.position_empty')}
                  disableUnderline
                  readOnly
                />
              </Grid>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
          </Grid>
          {/* DISTANCE */}
          <Grid item container marginY={1} direction="column">
            <Grid item>
              <Typography fontSize={17} color="primary">{t('entry:view.details.subtitle_7')}</Typography>
            </Grid>
            <Grid item container alignItems="center" spacing={1}>
              <Grid item>
                <img src={Assets.entry.detailsIcons.gray_pins} width={40} height={40} />
              </Grid>
              <Grid item xs>
                <Input
                  fullWidth
                  sx={{
                    fontSize: 17,
                    fontWeight: 500,
                    color: props.entry.formattedShotDistance ? palette.text?.entryForm : palette.text?.details,
                  }}
                  value={props.entry.formattedShotDistance || t('entry:view.details.distance_empty')}
                  disableUnderline
                  readOnly
                />
              </Grid>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
          </Grid>
        </>}
        {/* WEATHER */}
        <Grid item container marginY={1} direction="column">
          <Grid item>
            <Typography fontSize={17} color="primary">{t('entry:view.details.subtitle_8')}</Typography>
          </Grid>
          <Grid container direction="column" rowSpacing={2} justifyContent="center" alignItems="center">
            {/* NO WEATHER DATA */}
            {!props.entry.weather && <Grid item marginY={2}>
              <Typography variant="h6" color={palette.text?.details}>{t('entry:view.details.weather_no_data')}</Typography>
            </Grid>}
            {/* WEATHER DATA */}
            {props.entry.weather && <React.Fragment>
              <Grid item position="relative">
                <img src={vm.weatherIcon} width="120px" height="120px" />
                <Typography fontSize={34} fontWeight="bold" color={palette.text?.details} component="span" position="absolute">{props.entry.weather.temperature.formattedTemperature}</Typography>
              </Grid>
              <Grid item container justifyContent="space-evenly" alignItems="center">
                {/* WIND */}
                <Box>
                  <Grid item container alignItems="center">
                    <Grid item>
                      <img src={Assets.entry.detailsIcons.grey_wind} />
                    </Grid>
                    <Box>
                      <Grid item>
                        <Typography fontSize={12} fontWeight="light" color={palette.text?.entryForm}>{props.entry.weather?.wind.direction}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography fontSize={17} fontWeight="bold" color={palette.text?.entryForm}>{props.entry.weather?.wind.localized}</Typography>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
                {/* PRESSURE */}
                <Box>
                  <Grid item container alignItems="center">
                    <Grid item>
                      <img src={Assets.entry.detailsIcons.pressure_icon} />
                    </Grid>
                    <Box>
                      <Grid item>
                        <Typography fontSize={17} fontWeight="bold" color={palette.text?.entryForm}>{props.entry.weather?.baroPressure.localizedValue}</Typography>
                      </Grid>
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </React.Fragment>}
          </Grid>
        </Grid>
      </Grid >
    </Box >
  );
});
