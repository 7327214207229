import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Input, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { Assets } from '../../../../../assets';
import { IGeoLocation } from '../../../../../domain/core/IGeoLocation';
import { useContainer } from '../../../../../domain/hooks/useContainer';
import { useVm } from '../../../../../domain/hooks/useVm';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { typography } from '../../../../../theme/options/typography';
import { DistrictControls } from './components/DistrictControls';
import { DistrictInfoVm, IDistrictInfoProps } from './DistrictInfoVm';
import NightModeToggle from '../../../../../toolkit/components/NightModeToggle';

const useStyles = makeStyles({
  '@keyframes rollout': {
    '0%': { transform: 'translateY(-100px)' },
    '100%': { transform: 'none' },
  },

  rollOut: {
    animation: '$rollout 1s',
  },
});

export const DistrictInfo = observer(function DistrictInfo(props: IDistrictInfoProps) {
  const classes = useStyles();

  const vm = useVm(DistrictInfoVm, props);
  const inputRef = useRef<HTMLInputElement>();
  const { t } = useTranslation();
  const session = useContainer().get(SessionStore);

  useEffect(() => {
    if (props.district?.setupActive) {
      inputRef.current?.focus();
    }
  }, [props.district?.setupActive]);

  const onEdit = useCallback((points?: IGeoLocation[]) => {
    if (props.district) {
      props.onEdit(props.district, points);
    }
  }, [props]);

  const onDelete = useCallback(() => {
    if (props.district) {
      props.onDelete(props.district);
    }
  }, [props]);

  const handleClick = useCallback((event: React.MouseEvent) => {
    const freeOrPro = localStorage.getItem(`FREE_OR_PRO-${session.userId}`);

    // * If the user is a Pro user or `FREE_OR_PRO` is either true or not set (null), allow district selection.
    if (session.isProUser || freeOrPro === null || freeOrPro === 'true') {
      return vm.selectDistrict(props.district!);
    }
    event.preventDefault();
  }, [props.district, session.isProUser, session.userId, vm]);

  return (
    <Box sx={props.sx} position="relative" display="flex" flexDirection={'row'} alignItems={'flex-start'}>
      <Box marginTop={.5} marginRight={2} onClick={handleClick} sx={{ cursor: session.isProUser ? 'pointer' : 'not-allowed' }}>
        <MenuIcon fontSize="large" />
      </Box>
      <Box minHeight={'65px'} width="100%">
        {props.district ? <>
          <Box display="flex" justifyContent={'flex-start'} alignItems={'center'} width="100%">
            {props.district.setupActive && <Input
              fullWidth
              inputRef={inputRef}
              placeholder={t('district:district_title_placeholder')}
              value={props.district.name}
              onChange={(e) => {
                props.district?.setName(e.target.value);
              }}
              disableUnderline
              readOnly={!props.district.setupActive}
              sx={{
                color: props.district.name ? 'black' : 'error.main',
                fontSize: typography.h1?.fontSize,
                fontWeight: typography.h1?.fontWeight,
                maxHeight: '100%',
                marginBottom: '2px',
                '& .MuiInput-input': {
                  paddingY: 0,
                },
              }}
            />}
            {!props.district.setupActive && <Typography
              key={props.district.name}
              sx={{
                color: props.district.name ? 'black' : 'error.main',
                fontSize: typography.h1?.fontSize,
                fontWeight: typography.h1?.fontWeight,
                marginRight: '10px',
              }}
              className={classes.rollOut}
            >
              {props.district.name}
            </Typography>}
            {(props.district.mesh.isMeshClosed && props.district.canModify) && <DistrictControls
              onEdit={onEdit}
              onDelete={onDelete}
              importMultiplePois={props.importMultiplePois}
            />}
            {/* TODO: always remove for a production */}
            {process.env.REACT_APP_ENV !== 'production' && <Box>
              <NightModeToggle />
            </Box>}
          </Box>

          <Typography variant="subtitle1">
            {vm.subtitle}
          </Typography>
        </> : <img style={{ width: '50px' }} src={Assets.loading_logo} />}
      </Box>
    </Box>
  );
});
