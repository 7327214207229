import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import LanguageIcon from '@mui/icons-material/Language';
import { InputAdornment, TextField } from '@mui/material';
import { Box } from '@mui/system';

import { useContainer } from '../../../../../../domain/hooks/useContainer';
import { EntryModel } from '../../../../../../domain/model/EntryModel';
import { InfoComponent } from '../../../../../../toolkit/components/InfoComponent';
import { EntryHelper } from '../../../../../../util/EntryHelper';
import { useCallback } from 'react';

interface IProps {
  canEditAndDeleteEntries: boolean;
  isHarvestVisibleAndEditable: boolean;
  entry: EntryModel;
}

export const EntryPosition = observer(function EntryPosition(props: IProps) {
  const { t } = useTranslation();
  const entryHelper = useContainer().get(EntryHelper);

  const checkPermissions = useCallback(() => {
    if (!props.canEditAndDeleteEntries) {
      if (!props.isHarvestVisibleAndEditable) {
        return entryHelper.notifyWrongPermission('entry:harvest_readonly_permission');
      }
      return entryHelper.notifyWrongPermission();
    }
  }, [entryHelper, props.canEditAndDeleteEntries, props.isHarvestVisibleAndEditable]);

  const handleLatitudeChange = useCallback((e) => {
    props.entry.setLatitude(parseFloat(e.target.value));
  }, [props.entry]);

  const handleLongitudeChange = useCallback((e) => {
    props.entry.setLongitude(parseFloat(e.target.value));
  }, [props.entry]);



  return (
    <Box width="100%">
      <InfoComponent
        locizePath="entry:position_description"
      />
      <Box display="flex" justifyContent={'center'} alignItems={'center'} paddingY={4} paddingX={4} onFocus={checkPermissions}>
        <TextField
          label={t('entry:position_latitude')}
          placeholder={t('entry:position_latitude')}
          onChange={handleLatitudeChange}
          value={props.entry.lat ?? ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LanguageIcon />
              </InputAdornment>
            ),
          }}
        />
        <Box width={'20px'} />
        <TextField
          label={t('entry:position_longitude')}
          placeholder={t('entry:position_longitude')}
          onChange={handleLongitudeChange}
          value={props.entry.long ?? ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LanguageIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
});
