import { Box } from '@mui/material';
import { HarvestTagResponseDto } from '../../../../../../../../shared/dto/harvestTag.response.dto';
import { HarvestListItem } from './HarvestListItem';
import { QuotaPlanSpecieModel } from '../../../../../../../../domain/model/QuotaPlanModel';

interface IProps {
  items: HarvestTagResponseDto[];
  offset: number;
  level: number;
  quotas?: QuotaPlanSpecieModel[];
  showQuotas: boolean;
}

export function HarvestListItems(props: IProps) {
  return (
    <Box display="flex" flexDirection={'column'} width="100%" pl={props.offset}>
      {props.items.map((item, index) => (
        <HarvestListItem
          key={`harvest-item-${index}-${props.offset}`}
          item={item}
          offset={2}
          level={props.level}
          showQuotas={props.showQuotas}
          quotas={props.quotas}
        />
      ))}
    </Box>
  );
}
