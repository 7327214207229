import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Box, Divider, Tab, Tabs, Typography } from '@mui/material';

import { useVm } from '../../../../../../../domain/hooks/useVm';
import { palette } from '../../../../../../../theme/options/palette/palette';
import { TaskListItem } from '../task-list-item/TaskListItem';
import { ITaskListProps, ITaskTab, TaskListVm } from './TaskListVm';

export const TaskList = observer(function TaskList(props: ITaskListProps) {
  const vm = useVm(TaskListVm, props);

  return (
    <React.Fragment>
      <Box>
        <Tabs
          value={vm.activeTab}
          onChange={vm.changeTab}
          variant="fullWidth"
          centered
        >
          {vm.tabs.map((tab: ITaskTab) => <Tab
            key={`entry-tab-${tab.key}`}
            value={tab.key}
            label={tab.text}
          />)}
        </Tabs>
      </Box>
      <Box paddingTop={2} textAlign="right" onClick={vm.toggleCompletedTasks} sx={{ cursor: 'pointer' }}>
        <Typography fontSize={14} fontWeight="normal" color={palette.text?.hunter_green}>
          {vm.showCompletedTasks ? t('task:hide_completed') : t('task:show_completed')}
        </Typography>
      </Box>

      {vm.showDividerLine && <Divider />}

      <Box height={'97%'}>
        {(vm.showCompletedTasks ? vm.tasks : vm.withoutCompletedTasks).map((task, index) => <React.Fragment key={`poi-task-${task.id}`}>
          <TaskListItem
            district={props.district}
            task={task}
            poi={props.poi}
            pois={props.pois}
            onEditTask={props.onEditTask}
            onDeleteTask={props.onDeleteTask}
            markAsCompleted={props.markAsCompleted}
          />
          {index < vm.tasks.length - 1 && <Divider />}
        </React.Fragment>)}
      </Box>
    </React.Fragment>
  );
});
