interface IOfflineKeys {
  list: string[];
}

export class OfflineCache {

  public readonly offlineCacheKeysKey = 'hunter-offline-cache-keys';

  private appendKey = (key: string) => {
    const rawKeys = localStorage.getItem(this.offlineCacheKeysKey);
    let keys: IOfflineKeys = { list: [] };

    if (rawKeys) {
      keys = JSON.parse(rawKeys);
    }

    // if we have correct data types
    if (keys.list && Array.isArray(keys.list)) {
      if (!keys.list.includes(key)) {
        keys.list.push(key);
      }

      localStorage.setItem(this.offlineCacheKeysKey, JSON.stringify(keys));
    }
  }

  private deleteKey = (key: string) => {
    const rawKeys = localStorage.getItem(this.offlineCacheKeysKey);
    let keys: IOfflineKeys = { list: [] };

    if (rawKeys) {
      keys = JSON.parse(rawKeys);
    }

    // if we have correct data types
    if (keys.list && Array.isArray(keys.list)) {
      const keyIndex = keys.list.indexOf(key);

      if (keyIndex !== -1) {
        keys.list.splice(keyIndex, 1);
        localStorage.setItem(this.offlineCacheKeysKey, JSON.stringify(keys));
      }
    }
  }

  public get = (key: string): unknown | null => {
    const item = localStorage.getItem(key);
    if (!item) {
      return null;
    }

    return JSON.parse(item);
  }

  public set = (key: string, value: unknown): void => {
    const stringified = JSON.stringify(value);

    localStorage.setItem(key, stringified);
    this.appendKey(key);
  }

  public delete = (key: string): void => {
    localStorage.removeItem(key);
    this.deleteKey(key);
  }
}
