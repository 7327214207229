import { computed, makeObservable, observable } from 'mobx';
import { DistrictModel } from '../../../domain/model/DistrictModel';
import { EntryModel } from '../../../domain/model/EntryModel';
import { ENTRY_TYPE } from '../../../shared/enum/entryType.enum';

import { AbstractDistrictFilter } from './AbstractDistrictFilter';
import { IEntryFilter } from './IEntryFilter';

export class L1ClassificationFilterCondition {

  @observable
  public id: string;

  @observable
  public active: boolean;

  @observable
  public type: ENTRY_TYPE;

  constructor(type: ENTRY_TYPE) {
    this.id = type;
    this.type = type;
    this.active = true;

    makeObservable(this);
  }
}

export class L1ClassificationFilter extends AbstractDistrictFilter implements IEntryFilter {

  constructor(public readonly district: DistrictModel | null) {
    super((district && district.id) ? `DistrictFilterVm-L1ClassificationFilter-district-${district!.id!}` : 'DistrictFilterVm-L1ClassificationFilter', 1);
    makeObservable(this);
  }

  @computed
  public get conditions() {
    const conditions = Object.values(ENTRY_TYPE).map((t) => new L1ClassificationFilterCondition(t));
    return this.loadCache(conditions);
  }

  public check = (entry: EntryModel): boolean => {
    const activeConditions = this.conditions.filter((f) => f.active);

    // there is no active condition
    if (!activeConditions.length) {
      return false;
    }

    return activeConditions.some((c) => c.type === entry.entryType);
  }
}
