import 'vanilla-cookieconsent/dist/cookieconsent.js';
import './cookieconsent.css';

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import makeStyles from '@mui/styles/makeStyles';

import { useContainer } from '../../../domain/hooks/useContainer';
import { TrackingService } from '../../../domain/service/tracking/TrackingService';
import { oldDesignTheme } from '../../../theme/oldDesignTheme';

type LevelType = 'necessary' | 'analytics' | 'marketing';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum CookieLevelType {
  NECESSARY = 'necessary',
  ANALYTICS = 'analytics',
  MARKETING = 'marketing',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface AcceptData {
  data: unknown;
  categories: LevelType[];
  level: LevelType[];
  revision: number;
  rfc_cookie: boolean;
}

const useStyles = makeStyles({
  link: {
    color: oldDesignTheme.palette.text.tertiary,
    textDecoration: 'none !important',
  }
});

export function CookieConsent() {

  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const tracking = useContainer().get(TrackingService);

  useEffect(() => {
    const callCookieConsent = () => {

      /* this is because of not translating cookie consent (because it is alreay attached, so we need to remove it first) */
      const element = document.getElementById('cc--main');
      if (element) {
        element.remove();
      }

      let cookieConsent;
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        cookieConsent = (window as any).initCookieConsent();
      } catch (error) {
        console.error(error);
      }

      if (cookieConsent) {
        cookieConsent.run({
          current_lang: i18n.language,
          onAccept: function () {
            tracking.startTracking();
          },
          languages: {
            [i18n.language]: {
              consent_modal: {
                title: t('cookie:consent_modal.title'),
                description: `${t('cookie:consent_modal.description_1')} <a class=${classes.link} href=${t('links:data-privacy')} target="_blank">${t('cookie:consent_modal.description_2')}</a>`,
                primary_btn: {
                  text: t('cookie:consent_modal.btn_accept_all'),
                  role: 'accept_all'  //'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                  text: t('cookie:consent_modal.btn_preferences'),
                  role: 'settings'   //'settings' or 'accept_necessary'
                }
              },
              settings_modal: {
                title: t('cookie:settings_modal.title'),
                save_settings_btn: t('cookie:settings_modal.btn_save_settings'),
                accept_all_btn: t('cookie:settings_modal.btn_accept_all'),
                reject_all_btn: t('cookie:settings_modal.btn_reject_all'), // optional, [v.2.5.0 +]
                close_btn_label: t('cookie:settings_modal.btn_close_label'),
                blocks: [
                  {
                    title: t('cookie:settings_modal.blocks.block-1.title'),
                    description: t('cookie:settings_modal.blocks.block-1.description'),
                  }, {
                    title: t('cookie:settings_modal.blocks.block-2.title'),
                    description: t('cookie:settings_modal.blocks.block-2.description'),
                    toggle: {
                      value: 'necessary',
                      enabled: true,
                      readonly: true
                    }
                  }, {
                    title: t('cookie:settings_modal.blocks.block-3.title'),
                    description: t('cookie:settings_modal.blocks.block-3.description'),
                    toggle: {
                      value: 'marketing',
                      enabled: true,
                      readonly: false
                    }
                  }, {
                    title: t('cookie:settings_modal.blocks.block-4.title'),
                    description: t('cookie:settings_modal.blocks.block-4.description'),
                    toggle: {
                      value: 'analytics',
                      enabled: true,
                      readonly: false
                    }
                  },
                ]
              }
            }
          },
          gui_options: {
            consent_modal: {
              layout: 'bar',               // box/cloud/bar
              position: 'bottom center',     // bottom/middle/top + left/right/center
              transition: 'slide'             // zoom/slide
            },
            settings_modal: {
              layout: 'box',                 // box/bar
              transition: 'slide'             // zoom/slide
            }
          },

        });

      }
    };

    const script = document.createElement('script');
    script.src = 'vanilla-cookieconsent/dist/cookieconsent.js';
    script.defer = true;
    script.type = 'text/jsx';
    document.body.appendChild(script);

    callCookieConsent();

  }, [classes.link, i18n.language, t, tracking]);
}
