import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useEffectOnce } from 'react-use';

import { Box, Grid, Typography } from '@mui/material';

import { useComingFromLocationContext } from '../../../domain/context/ComingFromLocationContext';
import { useVm } from '../../../domain/hooks/useVm';
import { LocationProps } from '../AnonymousRoute';
import { AppleLoginButton } from '../components/apple-login/AppleLoginButton';
import { FacebookLoginButton } from '../components/facebook-login/FacebookLoginButton';
import { GoogleLoginButton } from '../components/google-login/GoogleLoginButton';
import { LoginForm } from './components/LoginForm';
import { LoginRouteVm } from './LoginRouteVm';

export const LoginRoute = observer(function LoginRoute() {

  const [prefilledEmail, setPrefilledEmail] = useState('');
  const loginButtonDimensions = { width: '278px', height: '48px' };
  const { t } = useTranslation();
  const { comingFromLocation, setComingFromLocation } = useComingFromLocationContext();

  const vm = useVm(LoginRouteVm, { fromLocation: comingFromLocation });

  // eslint-disable-next-line
  const location = useLocation() as any;

  useEffect(() => {
    if (location.state) {
      const isLocationProps = Object.keys(location.state).find(key => key === 'from');
      if (isLocationProps) {
        /* https://github.com/reach/router/issues/414#issuecomment-1056839570 */
        setComingFromLocation((location as unknown as LocationProps).state.from.pathname);
      } else {
        setPrefilledEmail(location.state);
      }
    }
  }, [comingFromLocation, location, setComingFromLocation]);

  useEffectOnce(() => {
    const searchParams = new URLSearchParams(location.search);
    const tokenFromUrl = searchParams.get('token');

    if (tokenFromUrl) {
      vm.session.ajax.setAuthToken(tokenFromUrl);
      vm.session.recoverSession.run();
    }
  });

  // * clear state on page reload
  window.history.replaceState({}, document.title);

  return (
    <Box>
      <Grid container rowGap={{ xs: 1, sm: 2 }} justifyContent="center">
        <Grid item container spacing={{ xs: 1, sm: 2 }} direction="column" alignItems="center">
          <Grid item>
            <Box mb={{ xs: 0, sm: 2 }}>
              <Typography color="textPrimary" textAlign={'center'} variant="h1">
                {t('auth:login_title')}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <GoogleLoginButton
              fromLocation={comingFromLocation}
              sx={loginButtonDimensions}
            />
          </Grid>
          <Grid item>
            <FacebookLoginButton
              fromLocation={comingFromLocation}
              sx={loginButtonDimensions}
            />
          </Grid>
          <Grid item>
            <AppleLoginButton
              fromLocation={comingFromLocation}
              sx={loginButtonDimensions}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Typography color="textPrimary" fontSize={13} fontWeight='bold' padding={'8px 16px'}>
            {t('auth:intro_alternative_login')}
          </Typography>
        </Grid>
        <Grid item>
          <LoginForm
            isBusy={vm.isBusy}
            onLogin={vm.login}
            prefilledEmail={prefilledEmail}
          />
        </Grid>
      </Grid>
    </Box>
  );
});
