import { FormControl, FormControlProps, FormHelperText } from '@mui/material';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import * as yup from 'yup';
import { ValidatedFormContext } from './ValidatedForm';

interface IProps extends FormControlProps {
  value: unknown;
  schema?: yup.AnySchema;
}

export function ValidatedFormControl(props: React.PropsWithChildren<IProps>) {
  const [validationError, setValidationError] = useState<string | null>(null);
  const { registerField, deregisterField, setValid, submitMode, validationMode, revalidateOnChange, submit } = useContext(ValidatedFormContext);
  const { schema, ...formControlProps } = props;
  const value = props.schema ? props.value : null;

  const componentId = useMemo(() => {
    return v4();
  }, []);

  const validate = useCallback((): Promise<boolean> => {
    if (!schema) {
      setValidationError('');
      setValid(componentId, true);

      return Promise.resolve(true);
    }

    return schema.validate(value)
      .then(() => {
        setValidationError('');
        setValid(componentId, true);

        return true;
      })
      .catch((e) => {
        setValidationError(e.message);
        setValid(componentId, false);

        return false;
      });
  }, [schema, componentId, setValid, value]);

  const onBlur = useCallback((e: React.FormEvent) => {
    e.stopPropagation();

    if (submitMode === 'onBlur') {
      return submit();
    }

    if (validationMode === 'onBlur') {
      validate();
    }
  }, [submitMode, validationMode, submit, validate]);

  useEffect(() => {
    // validations not yet run, skip revalidation
    if (validationError === null) {
      return;
    }

    if (revalidateOnChange) {
      validate();
    }
  }, [validationError, validate, revalidateOnChange]);

  useEffect(() => {
    if (props.schema) {
      registerField(componentId, () => {
        return validate();
      });
    }
  }, [registerField, deregisterField, componentId, validate, props.schema]);

  useEffect(() => {
    return () => {
      deregisterField(componentId);
    };
  }, [deregisterField, componentId]);

  return (
    <FormControl {...formControlProps} onBlur={onBlur} error={!!validationError}>
      {props.children}
      <FormHelperText>{validationError}</FormHelperText>
    </FormControl>
  );
}
