import { createContext, useContext, useMemo, useState } from 'react';

import { AppRoutes } from '../../router/AppRoutesEnum';

interface IComingFromLocationContextProviderProps {
  children: React.ReactNode;
}

type ComingFromLocationContextType = {
  comingFromLocation: string;
  setComingFromLocation: React.Dispatch<React.SetStateAction<string>>;
}

export const ComingFromLocationContext = createContext<ComingFromLocationContextType | null>(null);

export function ComingFromLocationContextProvider({ children }: IComingFromLocationContextProviderProps) {
  const [comingFromLocation, setComingFromLocation] = useState<string>(AppRoutes.Map);

  const value = useMemo(() => ({
    comingFromLocation, setComingFromLocation
  }), [comingFromLocation]);

  return (
    <ComingFromLocationContext.Provider value={value}>{children}</ComingFromLocationContext.Provider>
  );
}

export function useComingFromLocationContext() {
  const context = useContext(ComingFromLocationContext);
  if (!context) {
    throw new Error('useComingFromLocationContext must be used withing a ComingFromLocationContextProvider');
  }

  return context;
}
