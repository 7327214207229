import { inject } from 'inversify';

import { transient } from '../../inversify/decorator';
import { WeatherRealtimeResponseDto } from '../../shared/dto/weather/weatherRealtime.response.dto';
import { IGeoLocation } from '../../shared/interfaces/IGeoLocation';
import { AsyncTask } from '../async/AsyncTask';
import { AjaxService } from '../service/AjaxService';
import { Proxy } from './Proxy';

@transient()
export class WeatherProxy extends Proxy {

  constructor(@inject(AjaxService) private readonly ajax: AjaxService) {
    super();
  }

  public getRealtimeWeather = new AsyncTask((location: IGeoLocation) => {
    return this.ajax.get<WeatherRealtimeResponseDto>(`weather/realtime/${location.latitude}/${location.longitude}`);
  })
}
