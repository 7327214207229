import { Analytics, getAnalytics, logEvent, setCurrentScreen, setUserId } from 'firebase/analytics';
import * as firebase from 'firebase/app';
import { inject } from 'inversify';
import mixpanel from 'mixpanel-browser';
import { computed } from 'mobx';

import { env } from '../../../env';
import { singleton } from '../../../inversify/decorator';
import * as CookieUtil from '../../../util/CookieUtil';
import { SessionStore } from '../../store/SessionStore';
import { TrackingEvent } from './TrackingEvent';
import { IEventParams } from './TrackingEventParams';

@singleton()
export class TrackingService {

  private firebaseAnalytics?: Analytics = undefined;

  constructor(@inject(SessionStore) private readonly session: SessionStore) { }

  @computed
  public get userAcceptedTracking(): boolean {
    const cookie = CookieUtil.getCookie('cc_cookie');
    return cookie.includes('analytics');
  }

  private initFirebaseApp = () => {
    const firebaseApp = firebase.initializeApp(env.firebase, {
      automaticDataCollectionEnabled: true,
    });
    this.firebaseAnalytics = getAnalytics(firebaseApp);
  }

  private initMixpanelApp = (): Promise<void> => {
    return new Promise<void>((resolve) => {
      if (!env.mixpanel.projectToken) {
        throw new Error('Mixpanel not initialized');
      }

      mixpanel.init(env.mixpanel.projectToken, {
        // debug: true,
      });

      /** https://developer.mixpanel.com/docs/javascript#identify  */
      mixpanel.identify(this.session.userId);

      /**
       * With every track event, 'userId' will be sent because of this 'register' method;
       * other option is to put it in a mixpanel.track() options down below
       */
      mixpanel.register({ userId: this.session.userId });

      resolve();
    });
  }

  public startTracking = async () => {
    if (!this.userAcceptedTracking || env.isDev) {
      return;
    }

    await this.initMixpanelApp();
    this.initFirebaseApp();

    fbq('init', '306796283377242');
    fbq('track', 'PageView');
  }

  public screenChanged = (screen: string) => {
    if (!this.firebaseAnalytics) return;

    setCurrentScreen(this.firebaseAnalytics, screen);
    logEvent(this.firebaseAnalytics, 'screen_view');
  }

  public track = async (event: TrackingEvent, params: IEventParams | undefined = {}) => {
    if (!this.userAcceptedTracking || env.isDev) {
      return;
    }

    if (this.firebaseAnalytics) {
      logEvent(this.firebaseAnalytics, event, {
        userId: this.session.userId,
        accountUuid: this.session.userId,
        ...params,
      });
    }

    if (window.gtag) {
      window.gtag('event', event, {
        userId: this.session.userId,
        accountUuid: this.session.userId,
        ...params,
      });
    }

    await this.initMixpanelApp();

    mixpanel.track(event, {
      accountUuid: this.session.userId,
      ...params,
    });
  }

  public setFirebaseUserId = (userId: string) => {
    if (!this.firebaseAnalytics) return;
    setUserId(this.firebaseAnalytics, userId);
  }
}
