import { inject } from 'inversify';
import { computed, makeObservable, runInAction } from 'mobx';
import { Slide } from 'yet-another-react-lightbox/*';

import { AsyncTask } from '../../../domain/async/AsyncTask';
import { ViewModel } from '../../../domain/core/ViewModel';
import { EntryModel } from '../../../domain/model/EntryModel';
import { PhotoModel } from '../../../domain/model/PhotoModel';
import { PoiModel } from '../../../domain/model/PoiModel';
import { EntryProxy } from '../../../domain/proxy/EntryProxy';
import { PoiProxy } from '../../../domain/proxy/PoiProxy';
import { I18nService } from '../../../domain/service/I18nService';
import { NotificationService } from '../../../domain/service/NotificationService';
import { transient } from '../../../inversify/decorator';
import { ImagePreviewProps } from './ImagePreview';

@transient()
export class ImagePreviewVm extends ViewModel<ImagePreviewProps<EntryModel | PoiModel>> {

  constructor(
    @inject(EntryProxy) private readonly entryProxy: EntryProxy,
    @inject(PoiProxy) private readonly poiProxy: PoiProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get slides(): Slide[] {
    return this.props.item.photos.map((photo: PhotoModel) => {
      return {
        src: photo.url!,
        type: 'image',
      };
    });
  }

  @computed
  private get isEntry(): boolean {
    return this.props.item instanceof EntryModel;
  }

  public deletePhoto = new AsyncTask(async (): Promise<boolean> => {
    const photoForDeletion = this.props.item.photos[this.props.index];

    try {
      const response = this.isEntry
        ? await this.entryProxy.deletePhoto(photoForDeletion)
        : await this.poiProxy.deletePhoto(photoForDeletion);

      if (response?.ok) {
        requestAnimationFrame(() => {
          runInAction(() => {
            const indexToRemove = this.props.item.photos.findIndex((p: PhotoModel) => p.id === photoForDeletion.id);
            this.props.onSetIndex(indexToRemove - 1);

            this.props.item.photos.splice(indexToRemove, 1);
            this.props.onUpsert(this.props.item);

            this.notification.success(this.i18n.t('photo:delete.success'));
          });
        });

        return true;
      }

      this.notification.error(this.i18n.t('photo:delete.error'));
      return false;
    } catch (e) {
      console.error(`error while handling photo delete. ${e}`);
      this.notification.error(this.i18n.t('photo:delete.error'));
      return false;
    }
  });

}
