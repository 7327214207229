import { ApiProperty } from '@nestjs/swagger';

import { PRECIPITATION_TYPE } from '../../enum/weather/precipitationType.enum';
import { WeatherValueUnitItemResponseDto } from './weatherValueUnitItem.response.dto';

export class PrecipitationResponseDto {
  @ApiProperty({ type: () => WeatherValueUnitItemResponseDto })
  public amount: WeatherValueUnitItemResponseDto;
  @ApiProperty({ type: () => WeatherValueUnitItemResponseDto })
  public percentage: WeatherValueUnitItemResponseDto;
  @ApiProperty({ type: () => WeatherValueUnitItemResponseDto })
  public probability?: WeatherValueUnitItemResponseDto;
  @ApiProperty()
  public type?: PRECIPITATION_TYPE;
}
