import { ApiProperty } from '@nestjs/swagger';
import { MemberResponseDto } from './districtMember.response.dto';
import { PhotoResponseDto } from './photo.response.dto';


export class GroupResponseDto {

  @ApiProperty()
  public id: string;

  @ApiProperty()
  public name: string;

  @ApiProperty({ type: MemberResponseDto })
  public self: MemberResponseDto;

  @ApiProperty({ type: [MemberResponseDto] })
  public members: MemberResponseDto[];

  @ApiProperty({ required: false })
  public districtId?: string;

  @ApiProperty()
  public photo?: PhotoResponseDto;

  @ApiProperty({ type: () => PhotoResponseDto, isArray: true })
  public photos: PhotoResponseDto[];

  @ApiProperty({ example: '2019-02-13T14:23:17.683Z' })
  public createdAt: string; // ISO string

  @ApiProperty({ example: '2019-02-13T14:23:17.683Z' })
  public updatedAt?: string; // ISO string
}
