import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Grid, Paper, Typography } from '@mui/material';

import { Assets } from '../../../assets';
import BgImageTransparent from '../../../assets/img/background_image_transparent.png';
import { useVm } from '../../../domain/hooks/useVm';
import { StripeProductModel } from '../../../domain/model/stripe/StripeProductModel';
import { IconAndTitle } from '../../../toolkit/components/IconAndTitle';
import {
  PaymentFeature, PaymentFeaturesList
} from '../../../toolkit/constants/PaymentFeaturesList';
import { FeaturesModal } from '../../pricing/components/FeaturesModal';
import { SubscriptionBox } from '../../pricing/components/SubscriptionBox';
import { SubscriptionInfoModal } from '../../pricing/components/SubscriptionInfoModal';
import { AnonymousSubscriptionPurchaseRouteVm } from './AnonymousSubscriptionPurchaseRouteVm';
import { LoadingFallback } from '../../../LoadingFallback';
import { colors } from '../../../theme/options/palette/const/colors';

export const AnonymousSubscriptionPurchaseRoute = observer(function AnonymousSubscriptionPurchaseRoute() {

  const { t, i18n } = useTranslation();
  const vm = useVm(AnonymousSubscriptionPurchaseRouteVm);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const userId = searchParams.get('userId');
    const email = searchParams.get('email');
    const language = searchParams.get('lang');

    if (userId && email && language) {
      i18n.changeLanguage(language);
      vm.setUserId(userId);
      vm.setEmail(email);
    }
  }, [i18n, searchParams, vm]);

  useEffect(() => {
    vm.getOfferings.run().then((productsIds: string[] | undefined) => {
      if (!productsIds || !productsIds.length) return;
      vm.getProducts.run(productsIds);
    });
  }, [vm]);

  const features = useMemo(() => PaymentFeaturesList, []);

  if (vm.isLoading) {
    return <LoadingFallback />;
  }

  return (
    <Paper
      sx={{
        position: 'relative',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        minHeight: '100vh',
        overflow: 'hidden',
        width: '100%',
        backgroundImage: `url(${BgImageTransparent})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* PAGE GRID = COLUMN */}
      <Grid container direction="column" paddingY={{ xs: 7 }}>
        <Grid item paddingLeft={{ xs: '3%', md: '8%' }} paddingRight={'3%'}>
          <IconAndTitle title="payment:title" subtitle="payment:subtitle" />
        </Grid>
        <Grid container justifyContent="center">
          {/* SUBSCRIPTION BOXES */}
          <Grid item container
            wrap="wrap"
            justifyContent={{ xs: 'center', xl: 'space-around' }}
            xs={11} sm={9}
          >
            {vm.products.map((product: StripeProductModel, index: number) => (
              <React.Fragment key={product.id}>
                {!product.tiered && <SubscriptionBox product={product} index={index} createCheckoutSession={vm.createCheckoutSession.run} getTranslation={vm.getTranslation} />}
              </React.Fragment>
            ))}
          </Grid>
          {/* PRO FEATURES BOX */}
          <Grid
            item container
            marginTop={6}
            xs={11} sm={9}
            justifyContent="center"
            position="relative"
            sx={{ background: colors.paper, borderRadius: '20px' }}
          >
            <Grid item container
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{ position: 'absolute', top: '-40px' }}
            >
              <Grid item><img src={Assets.shield} height="60px" width="60px" /></Grid>
              <Grid item><FeaturesModal /></Grid>
            </Grid>
            <Grid item container
              justifyContent="space-evenly"
              alignItems="flex-start"
              paddingX={1}
              spacing={3}
              marginTop="45px"
            >
              {features.map((feature: PaymentFeature) => {
                return (
                  <Grid key={feature.title} item container xs={12} sm={6} md={4} marginTop={{ xs: 4, sm: 0 }} justifyContent="center">
                    <Grid item xs={12} container justifyContent="center" alignItems="center">
                      <img src={feature.icon} width="65px" height="78px" />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                      <Typography variant="h4" sx={{
                        '&.MuiTypography-h4': {
                          color: '#0F7761',
                        },
                      }}>{t(`${feature.title}`)}</Typography>
                    </Grid>
                    <Grid item container xs={12} textAlign="left" justifyContent="center">
                      <ul style={{ fontSize: '18px', color: '#02654E', }}>
                        {feature.description.map((description: string) => {
                          return (
                            <li key={description}>
                              <span>{t(`${description}`)}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item container justifyContent="center" xs={12} paddingY={2}>
            <SubscriptionInfoModal />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
});
