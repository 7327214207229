import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, Switch, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Assets } from '../../../../../../../assets';
import { useVm } from '../../../../../../../domain/hooks/useVm';
import { TrackingEvent } from '../../../../../../../domain/service/tracking/TrackingEvent';
import { FitContainerLoadingFallback } from '../../../../../../../FitContainerLoadingFallback';
import { colors } from '../../../../../../../theme/options/palette/const/colors';
import { useThemeContext } from '../../../../../../../theme/ThemeContextProvider';
import { CustomDatePicker } from '../../../../../../../toolkit/components/CustomDatePicker';
import { ProCtaButton } from '../../../../../../../toolkit/components/ProCtaButton';
import { SubmitButton } from '../../../../../../../toolkit/components/SubmitButton';
import { appLocaleList } from '../../../../../../../toolkit/constants/LanguageList';
import { HarvestListItems } from './components/HarvestListItems';
import { QuotaPlanList } from './components/QuotaPlanList';
import {
  ViewDistrictHarvestListProps,
  ViewDistrictHarvestListVm,
} from './ViewDistrictHarvestListVm';

export const ViewDistrictHarvestList = observer(
  function ViewDistrictHarvestList(props: ViewDistrictHarvestListProps) {
    const { theme } = useThemeContext();
    const vm = useVm(ViewDistrictHarvestListVm, props);
    const { t } = useTranslation();

    useEffect(() => {
      vm.tracking.track(vm.showActiveHarvestQuota
        ? TrackingEvent.QUOTA_TOGGLE_ACTIVE
        : TrackingEvent.QUOTA_TOGGLE_DEACTIVATED
      );
    }, [vm.showActiveHarvestQuota, vm.tracking]);

    if (vm.isBusy) {
      return <FitContainerLoadingFallback />;
    }

    return (
      <Box px={4} py={2}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={appLocaleList[vm.i18n.language]}
        >
          <Box
            mb={1}
            display="flex"
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant="body2">
              {t('district:harvest_filter_date_range')}
            </Typography>
            {vm.hasDateChanged && (
              <Typography
                sx={{ cursor: 'pointer' }}
                onClick={vm.resetDates}
                color={colors.green[800]}
                variant="subtitle2"
              >
                {t('district:reset_harvest_filter')}
              </Typography>
            )}
          </Box>
          <Grid container spacing={2}>
            <CustomDatePicker
              label={t('district:harvest_filter_from_date')}
              selectedDate={
                vm.showActiveHarvestQuota
                  ? vm.activeQuotaPlan!.startDate
                  : vm.from
              }
              onChange={vm.setFrom}
              readOnly={vm.showActiveHarvestQuota}
            />
            <CustomDatePicker
              label={t('district:harvest_filter_to_date')}
              selectedDate={
                vm.showActiveHarvestQuota ? vm.activeQuotaPlan!.endDate : vm.to
              }
              onChange={vm.setTo}
              readOnly={vm.showActiveHarvestQuota}
            />
          </Grid>
        </LocalizationProvider>
        <Grid container my={2}>
          <Grid item container alignItems={'center'} columnGap={1}>
            <Grid item xs={2}>
              <Switch
                onClick={() => vm.toggleShowHarvestQuota()}
                checked={vm.showActiveHarvestQuota}
                disabled={vm.shouldDisableQuotaSwitch}
                sx={{
                  '&.MuiSwitch-root .Mui-checked': {
                    color: colors.green[600],
                    '& + .MuiSwitch-track': {
                      backgroundColor: colors.green[400],
                    },
                  },
                }}
              />
            </Grid>
            <Grid item flexGrow={1}>
              <Typography variant="body1" color={theme.palette.text.primary}>
                {t('district:show_quota_plan_toggle_label')}
              </Typography>
            </Grid>
          </Grid>
          {vm.shouldDisableQuotaSwitch && (
            <Grid item container alignItems={'center'} columnGap={1}>
              <Grid item xs={2} />
              <Grid item xs>
                <Typography
                  variant="text_sm_regular"
                  color={theme.palette.text.secondary}
                >
                  {t('district:disabled_quota_plan_toggle_msg')}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Box my={2}>
          <Divider />
        </Box>
        {vm.showActiveHarvestQuota && vm.activeQuotaPlan && (
          <>
            <Box display="flex" flexDirection="row" justifyContent="end">
              <Typography
                variant="subtitle2"
                textAlign={'end'}
                color={colors.orange[600]}
              >
                {t('district:harvest_filter_game')}
              </Typography>
              <Typography variant="subtitle2" textAlign={'end'}>
                &nbsp;/&nbsp;{t('district:quota')}
              </Typography>
            </Box>
            <QuotaPlanList
              quotaPlanSpecies={vm.activeQuotaPlan.species.flat()}
              showGame={true}
            />
          </>
        )}
        {!vm.getHarvestList.isBusy &&
          !vm.showActiveHarvestQuota &&
          (vm.harvestList.length > 0 ? (
            <>
              <Box display="flex" flexDirection="row" justifyContent="end">
                <Typography
                  variant="subtitle2"
                  color={
                    vm.showActiveHarvestQuota
                      ? colors.orange[600]
                      : colors.green[600]
                  }
                  textAlign={'end'}
                >
                  {t('district:harvest_filter_game')}
                </Typography>
              </Box>
              <HarvestListItems
                items={vm.harvestList}
                offset={0}
                level={0}
                showQuotas={vm.showActiveHarvestQuota}
                quotas={vm.activeQuotaPlan?.species.flat()}
              />
              <Box
                width={'100%'}
                display="flex"
                justifyContent={'center'}
                mt={5}
              >
                {vm.hasProHarvestList ? (
                  <SubmitButton
                    sx={{ paddingX: 8 }}
                    onClick={vm.onDownloadClick}
                  >
                    {t('district:export_harvest_list')}
                  </SubmitButton>
                ) : (
                  <Box
                    display="flex"
                    flexDirection={'column'}
                    justifyContent="center"
                    alignItems={'center'}
                  >
                    <img
                      style={{ width: '85px' }}
                      src={Assets.harvest.pro_export}
                    />
                    <Typography
                      textAlign={'center'}
                      color={colors.blueGrey[900]}
                      mt={2}
                      mb={4}
                    >
                      {t('district:activate_pro_harvest_export')}
                    </Typography>
                    <ProCtaButton />
                  </Box>
                )}
              </Box>
            </>
          ) : vm.hasProHarvestList ? (
            <Box
              display={'flex'}
              flexDirection="column"
              justifyContent={'center'}
              alignItems="center"
              mt={4}
            >
              <img style={{ width: '52px' }} src={Assets.harvest.no_results} />
              <Typography
                textAlign={'center'}
                color={colors.blueGrey[900]}
                mt={2}
              >
                {t('district:harvest_no_results')}
              </Typography>
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection={'column'}
              justifyContent="center"
              alignItems={'center'}
            >
              <img style={{ width: '85px' }} src={Assets.harvest.pro_export} />
              <Typography
                textAlign={'center'}
                color={colors.blueGrey[900]}
                mt={2}
                mb={4}
              >
                {t('district:activate_pro_harvest_export')}
              </Typography>
              <ProCtaButton />
            </Box>
          ))}
      </Box>
    );
  }
);
