import { useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Grid, IconButton, Popover, Typography, useMediaQuery } from '@mui/material';

import {
  GroupSubscriptionUserModel
} from '../../../../../../domain/model/GroupSubscriptionUserModel';
import {
  shouldEnableDistrictsModal, truncateDistrictsNames
} from '../../../../../../util/ManageGroupHelper';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';

interface AreasColumnProps {
  participant: GroupSubscriptionUserModel;
}

export function AreasColumn({ participant }: AreasColumnProps) {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const shouldEnablePopover = shouldEnableDistrictsModal(participant.districtsNames);

  return (
    <Grid container direction={'row'}>
      <Grid item>
        <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
          {shouldEnablePopover ? truncateDistrictsNames(participant.districtsNames) : participant.districtsNames}
        </Typography>
      </Grid>
      {shouldEnablePopover &&
        <Grid item width={'10%'} alignSelf={'center'}>
          <IconButton onClick={handleClick} sx={{ p: 0 }}>
            <ArrowDropDownIcon />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography
              variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'}
              color={theme.palette.text.primary}
              sx={{ p: 2, maxWidth: 350 }}
            >{participant.districtsNames}</Typography>
          </Popover>
        </Grid>}
    </Grid>
  );
}
