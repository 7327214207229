import { inject } from 'inversify';
import { action, computed, IReactionDisposer, makeObservable, reaction } from 'mobx';

import { AsyncTask } from '../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../domain/core/ViewModel';
import { DistrictModel } from '../../../../../domain/model/DistrictModel';
import { PoiModel } from '../../../../../domain/model/PoiModel';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { transient } from '../../../../../inversify/decorator';
import { FEATURE, POI_TYPE } from '../../../../../shared/enum';
import { poiUIModels } from '../../../../../util/PoiUIModels';

export enum PoiTabType {
  POSITION = 'poi:tab_position',
  TYPE = 'poi:tab_type',
  DETAILS = 'poi:details_info',
  SHOOTING_DIRECTION = 'poi:shooting_direction_tab',
}

export interface IPoiTab {
  type: PoiTabType;
  enabled: boolean;
}

export interface NewPoiProps {
  poi: PoiModel;
  onClose: () => void;
  onSave: AsyncTask<() => Promise<boolean>>;
  onTabChange: (tab: IPoiTab) => void;
  weatherPoisCount: number;
  currentTab: IPoiTab;
  district: DistrictModel | null;
  upsertPoi: (poi: PoiModel) => void;
}

@transient()
export class NewPoiVm extends ViewModel<NewPoiProps> {

  private tabAutoSwitchDisposer: IReactionDisposer | null = null;

  constructor(
    @inject(SessionStore) private readonly session: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get tabs() {
    const tabs = [{
      type: PoiTabType.POSITION,
      enabled: true,
    }, {
      type: PoiTabType.TYPE,
      enabled: true,
    }, {
      type: PoiTabType.DETAILS,
      enabled: true,
    }];

    if (this.props.poi?.canHaveShootingDirection) {
      tabs.push({
        type: PoiTabType.SHOOTING_DIRECTION,
        enabled: this.hasShootingDirectionEnabled,
      });
    }

    return tabs;
  }

  @computed
  public get hasShootingDirectionEnabled() {
    return this.session.hasFeatureEnabled(FEATURE.SHOOTING_DIRECTION);
  }

  @computed
  public get isNameValid(): boolean {
    return this.props.poi.name.length < 23;
  }

  public override async onInit() {
    this.tabAutoSwitchDisposer = reaction(() => this.props.poi.location, (location) => {
      // when we are on position tab, if user selects location on the map, we should automatically switch to classification tab
      if (location && this.props.currentTab.type === PoiTabType.POSITION && this.props.poi.positionSource === 'map') {
        this.nextTab();
      }
    });

    this.props.onTabChange(this.props.currentTab);
  }

  public override async onDestroy() {
    this.tabAutoSwitchDisposer?.();
  }

  @computed
  public get canBeSaved(): boolean {
    if (!this.props.poi.location) {
      return false;
    }

    if (!this.props.poi.type) {
      return false;
    }

    if (this.props.poi.type === POI_TYPE.CUSTOM) {
      return !!this.props.poi.customMark?.background && !!this.props.poi.customMark.foreground;
    }

    if (!this.isNameValid) {
      return false;
    }

    return true;
  }

  @computed
  public get headerBackgroundColor() {
    const defaultColor = '#798CA4';

    if (!this.props.poi.type) {
      return defaultColor;
    }

    if (this.props.poi.type === POI_TYPE.CUSTOM) {
      return this.props.poi.customMark?.background || defaultColor;
    }

    return poiUIModels[this.props.poi.type].color;
  }

  @computed
  public get hasNextTab() {
    return this.props.currentTab.type === PoiTabType.POSITION || this.props.currentTab.type === PoiTabType.TYPE;
  }

  @computed
  public get canEditAndDeletePoi(): boolean {
    return this.props.poi.ownerId === this.session.currentUser?.id
      ? true
      : !!this.props.district?.canEditAndDeletePois;
  }

  @action
  public nextTab = () => {
    const index = this.tabs.findIndex((t) => t.type === this.props.currentTab.type);
    if (index !== -1) {
      if (this.tabs.length > index) {
        this.props.onTabChange(this.tabs[index + 1]);
      }
    }
  }

  @action
  public saveClick = () => {
    if (this.hasNextTab) {
      this.nextTab();
    } else {
      this.props.onSave.run();
    }
  }
}
