import { computed, makeObservable, observable } from 'mobx';
import { DistrictModel } from '../../../domain/model/DistrictModel';
import { SubzoneModel } from '../../../domain/model/SubzoneModel';
import { SUBZONE_TYPE } from '../../../shared/enum/subzoneType.enum';
import { AbstractDistrictFilter } from './AbstractDistrictFilter';

export class SubzoneFilterCondition {

  @observable
  public id: string = '';

  @observable
  public type: SUBZONE_TYPE | null = null;

  @observable
  public active: boolean = false;

  constructor() {
    makeObservable(this);
  }

  public static fromType(subzoneType: SUBZONE_TYPE): SubzoneFilterCondition {
    const subzoneFilter = new SubzoneFilterCondition();
    subzoneFilter.id = subzoneType;
    subzoneFilter.type = subzoneType;
    subzoneFilter.active = true;

    return subzoneFilter;
  }
}

export class SubzoneFilter extends AbstractDistrictFilter {

  constructor(
    private readonly district: DistrictModel | null,
    private readonly subzones: SubzoneModel[],
  ) {
    super((district && district.id) ? `DistrictFilterVm-SubzoneFilter-district-${district!.id!}` : 'DistrictFilterVm-SubzoneFilter', 1);
    makeObservable(this);
  }

  @computed
  public get conditions() {
    if (!this.district) {
      return [];
    }

    const subzoneTypes = new Set<SUBZONE_TYPE>();
    const conditions: SubzoneFilterCondition[] = [];

    this.subzones.forEach((p) => {
      if (!subzoneTypes.has(p.subzoneType!)) {
        conditions.push(SubzoneFilterCondition.fromType(p.subzoneType!));
      }

      subzoneTypes.add(p.subzoneType!);
    });

    return this.loadCache(conditions);
  }

  public check = (subzone: SubzoneModel): boolean => {
    const activeConditions = this.conditions.filter((f) => f.active);

    // there is no active condition
    if (!activeConditions.length) {
      return false;
    }

    return activeConditions.some((c) => {
      return c.type === subzone.subzoneType;
    });
  }
}
