import { makeObservable, observable } from 'mobx';

import {
  StripeInvoiceLineItemResponseDto
} from '../../../shared/dto/stripe/stripe.invoice.lineitem.response.dto';
import {
  STRIPE_INVOICE_LINE_ITEM
} from '../../../shared/enum/stripe/stripeInvoiceLineItemType.enum';
import { ViewModel } from '../../core/ViewModel';

export type RecurringIntervalType = 'year' | 'month' | 'week' | 'day' | null | undefined;

export class StripeInvoiceLineItemModel extends ViewModel {

  @observable
  public id: string = '';

  @observable
  public amount: number = 0;

  @observable
  public currency: string = '';

  @observable
  public quantity: number | null = null;

  @observable
  public description: string | null = null;

  @observable
  public subscription: string | null = null;

  @observable
  public type: STRIPE_INVOICE_LINE_ITEM | null = null;

  @observable
  public recurringInterval: RecurringIntervalType = 'year';

  @observable
  public recurringCount: number | null | undefined = undefined;

  @observable
  public tax: number | null = null;

  @observable
  public taxRate: number | null = null;

  constructor() {
    super();
    makeObservable(this);
  }

  public static fromDto = (dto: StripeInvoiceLineItemResponseDto) => {
    const model = new StripeInvoiceLineItemModel();
    model.id = dto.id;
    model.amount = dto.amount;
    model.currency = dto.currency;
    model.quantity = dto.quantity;
    model.description = dto.description;
    model.subscription = dto.subscription;
    model.type = dto.type;
    model.recurringInterval = dto.recurringInterval;
    model.recurringCount = dto.recurringCount;
    model.tax = dto.tax;
    model.taxRate = dto.taxRate;

    return model;
  }

}
