import React from 'react';

export const GoogleIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 20 20"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Icon_Placement"
            data-name="Icon Placement"
            width="20"
            height="20"
            transform="translate(22 14)"
            fill="#fff"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_166"
        data-name="Mask Group 166"
        transform="translate(-22 -14)"
      >
        <path
          id="icons8-google"
          d="M12.5,2.5a10,10,0,1,0,0,20c8.342,0,10.221-7.744,9.438-11.667H12.5v3.333h6.449A6.67,6.67,0,1,1,16.87,7.474l2.368-2.367A9.966,9.966,0,0,0,12.5,2.5Z"
          transform="translate(19.698 11.5)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};
