import { ApiProperty } from '@nestjs/swagger';

import { WEATHER_CODE } from '../../enum/weather/weatherCode.enum';
import { AnimalActivityResponseDto } from './animalActivity.response.dto';
import { PrecipitationResponseDto } from './precipitation.response.dto';
import { WeatherValueUnitItemResponseDto } from './weatherValueUnitItem.response.dto';
import { WindResponseDto } from './wind.response.dto';

export class WeatherHourlyForecastResponseDto {
  @ApiProperty()
  public date: string;
  @ApiProperty()
  public temperature?: WeatherValueUnitItemResponseDto;
  @ApiProperty()
  public wind?: WindResponseDto;
  @ApiProperty()
  public weatherCode: WEATHER_CODE;
  @ApiProperty()
  public sun: WeatherValueUnitItemResponseDto;
  @ApiProperty()
  public sunDegree: WeatherValueUnitItemResponseDto;
  @ApiProperty()
  public moon: WeatherValueUnitItemResponseDto;
  @ApiProperty()
  public moonDegree: WeatherValueUnitItemResponseDto;
  @ApiProperty()
  public precipitation: PrecipitationResponseDto;
  @ApiProperty()
  public animalActivity: AnimalActivityResponseDto;
  @ApiProperty()
  public isFirstLight: boolean;
  @ApiProperty()
  public isLastLight: boolean;
}
