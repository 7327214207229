import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { DistrictModel } from '../../../../../../../domain/model/DistrictModel';
import { EntryModel } from '../../../../../../../domain/model/EntryModel';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { SessionStore } from '../../../../../../../domain/store/SessionStore';
import { transient } from '../../../../../../../inversify/decorator';
import { EntryHelper } from '../../../../../../../util/EntryHelper';

export interface IViewDistrictEntriesProps {
  entries: EntryModel[];
  onEdit: (entry: EntryModel) => void;
  onDelete: (entry: EntryModel) => void;
  selectedDistrict: DistrictModel | null;
  districts: DistrictModel[];
}

export type TabKey = 'all' | 'own';

interface IEntryTab {
  key: TabKey;
  count: number;
  text: string;
}

@transient()
export class ViewDistrictEntriesVm extends ViewModel<IViewDistrictEntriesProps> {

  @observable
  public activeTab: TabKey = 'all';

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(EntryHelper) private readonly entryHelper: EntryHelper,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get tabs(): IEntryTab[] {
    const tabs: { [key in TabKey]: IEntryTab } = {
      'all': { key: 'all', count: this.props.entries.length, text: this.i18n.t('district:district_details_show_all_items'), },
      'own': { key: 'own', count: this.props.entries.filter(e => e.owner?.id === this.session.userId).length, text: this.i18n.t('entry:own_entries') },
    };

    return Object.values(tabs);
  }

  @computed
  public get entries(): EntryModel[] {
    return this.props.entries.filter((e) => {
      if (this.activeTab === 'all') {
        return true;
      }

      return e.owner?.id === this.session.userId;
    });
  }

  @action
  public changeTab = (e: unknown, tab: TabKey) => {
    this.activeTab = tab;
  }

  public canEditOrDeleteEntry = (entry: EntryModel) => {
    return this.entryHelper.canEditAndDeleteEntries(
      entry,
      this.props.selectedDistrict,
      this.props.districts,
    );
  }
}
