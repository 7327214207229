import { FileUploadIcon, TrashIcon, XIcon } from '@HunterCompanion/hunter-design/icons';
import { Observer, observer } from 'mobx-react-lite';
import React, { BaseSyntheticEvent, useCallback, useImperativeHandle, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import {
  Box, Divider, Grid, IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  Modal, Radio, ThemeProvider, Typography
} from '@mui/material';
import Button from '@mui/material/Button';

import { useVm } from '../../../../../../../domain/hooks/useVm';
import { useThemeContext } from '../../../../../../../theme/ThemeContextProvider';
import {
  NewSubmitButton
} from '../../../../../../../toolkit/components/redesign-components/NewSubmitButton';
import { SvgWrapper } from '../../../../../../../toolkit/components/SvgWrapper';
import { KmlImportModalProps, KmlImportModalRef, KmlImportModalVm } from './KmlImportModalVm';

export const KmlImportModal = observer(
  React.forwardRef<KmlImportModalRef, KmlImportModalProps>(
    function KmlImportModal(props: KmlImportModalProps, ref) {
      const { theme } = useThemeContext();
      const { t } = useTranslation();
      const vm = useVm(KmlImportModalVm, props);

      useImperativeHandle(ref, () => ({
        open() {
          vm.openModal();
        },
        close() {
          vm.closeModal();
        }
      }));

      const handleCloseMenu = useCallback((e: BaseSyntheticEvent) => {
        vm.handleClose(e);
        vm.setFile(null);
        vm.setPossibleDistricts(null);
      }, [vm]);

      const fileSelection = useMemo(() => (
        <Dropzone maxFiles={1} multiple={false} onDrop={vm.onFileSelected}>
          {({ getRootProps, getInputProps }) => (
            <Observer>
              {() => (
                <Box
                  sx={{
                    borderStyle: 'dashed',
                    border: `1px dashed ${theme.palette.icons?.tertiary}`,
                    borderRadius: 2,
                    cursor: 'pointer',
                    px: 2,
                    py: 4,

                  }}
                >
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Typography textAlign={'center'} color={theme.palette.text.tertiary}>
                      {t('district:select_gpx_kml_file_title')}
                    </Typography>
                  </div>
                </Box>
              )}
            </Observer>
          )}
        </Dropzone>
      ), [t, theme.palette.icons?.tertiary, theme.palette.text.tertiary, vm.onFileSelected]);

      const fileSelected = useMemo(() => (
        <Grid container rowGap={0.5}>
          <Grid item xs={12}>
            <Typography variant='text_sm_semibold' color={theme.palette.text.secondary}>{t('district:file_to_import')}</Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            height={43}
            borderRadius={1}
            bgcolor={theme.palette.background.container}
            alignItems={'center'}
            px={2}
            columnGap={1}
          >
            <Grid item>
              <SvgWrapper icon={FileUploadIcon} fill={theme.palette.icons?.secondary} width={16} height={16} />
            </Grid>
            <Grid item>
              <Typography variant='text_sm_semibold' color={theme.palette.text.primary}>
                {vm.file?.name}
              </Typography>
            </Grid>
            <Grid item flexGrow={1} />
            <Grid item onClick={() => vm.setFile(null)} sx={{ cursor: 'pointer' }}>
              <SvgWrapper icon={TrashIcon} fill={theme.palette.background.error_default} width={16} height={16} />
            </Grid>
          </Grid>
        </Grid>
        // eslint-disable-next-line react-hooks/exhaustive-deps
      ), [theme.palette.background.container, theme.palette.background.error_default, theme.palette.icons?.secondary, theme.palette.text.primary, theme.palette.text.secondary, vm, vm.file]);

      const renderUploadModalSubtitle = useMemo(() => (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='text_sm_regular' color={theme.palette.text.primary}>
              {t('district:upload_modal_subtitle')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link
              variant='caption'
              color={theme.palette.text.primary}
              target={'_blank'}
              href={t('links:learn_about_kml_import')}
              sx={{ textDecoration: 'underline' }}
            >{t('district:upload_modal.read_more')}</Link>
          </Grid>
        </Grid>
      ), [t, theme.palette.text.primary]);

      const multipleDistrictsSelect = useMemo(() => {
        return (
          <Grid container rowGap={0.5}>
            <Grid item xs={12}>
              <Typography variant='text_sm_semibold' color={theme.palette.text.secondary}>{t('district:list_of_detected_districts')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <List dense sx={{ width: '100%', }} disablePadding>
                {vm.possibleDistricts?.map((district, index) => {
                  return (
                    <ListItem
                      key={`district-${index}`}
                      onClick={() => vm.selectDistrict(district)}
                      disablePadding
                    >
                      <ListItemButton style={{ paddingLeft: 8 }}>
                        <ListItemIcon>
                          <Radio
                            onChange={() => vm.selectDistrict(district)}
                            checked={district === vm.selectedDistrict}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant='text_sm_semibold' color={theme.palette.text.primary}>{district.name}</Typography>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          </Grid>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [theme.palette.text.secondary, vm, vm.possibleDistricts, vm.selectedDistrict, vm.selectDistrict]);

      const renderBody = useMemo(() => {
        if (!vm.file) {
          return fileSelection;
        }

        if (vm.possibleDistricts?.length) {
          return multipleDistrictsSelect;
        }

        return fileSelected;
      }, [fileSelected, fileSelection, multipleDistrictsSelect, vm.file, vm.possibleDistricts]);

      return (
        <ThemeProvider theme={theme}>
          <Modal
            open={vm.open}
            onClose={handleCloseMenu}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid
              container
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              bgcolor={theme.palette.background.surface}
              border={`1px solid ${theme.palette.borderDividers?.border}`}
              borderRadius={1}
              width={410}
            >
              {/* TITLE */}
              <Grid container alignItems={'center'} justifyContent={'space-between'} wrap='nowrap' paddingLeft={2} paddingRight={1} paddingTop={1}>
                <Grid item>
                  <Typography variant='text_md_semibold' color={theme.palette.text.primary}>
                    {t('district:upload_modal_title')}
                  </Typography>
                </Grid>
                <Grid flexGrow={1} />
                <Grid item >
                  <IconButton onClick={handleCloseMenu} >
                    <SvgWrapper icon={XIcon} fill={theme.palette.icons?.secondary} width={19} height={19} />
                  </IconButton>
                </Grid>
              </Grid>
              {/* DIVIDER */}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* BODY */}
              <Grid container justifyContent={'flex-start'} padding={2} gap={2}>
                <Grid item xs={12}>
                  {vm.possibleDistricts
                    ? <Typography variant='text_sm_regular' color={theme.palette.text.primary}>
                      {t('district:multiple_districts_detected')}
                    </Typography>
                    : renderUploadModalSubtitle
                  }
                </Grid>
                <Grid item xs={12}>
                  {renderBody}
                </Grid>
                {/* DIVIDER */}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {/* BUTTONS */}
                <Grid container justifyContent={'space-evenly'} columnSpacing={1}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      color='secondary'
                      onClick={handleCloseMenu}
                    >
                      {t('common:button.cancel')}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <NewSubmitButton
                      fullWidth
                      disabled={Boolean(!vm.file)}
                      onClick={vm.import.run}
                      submitting={vm.import.isBusy}
                    >
                      {t('district:import_district')}
                    </NewSubmitButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Modal>
        </ThemeProvider>
      );
    })
);
