
import { PathOldIcon, SubzoneOldIcon } from '@HunterCompanion/hunter-design/icons';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import { IMapMenuItemObject, MapMenuItemChild, StyledMapMenuItem } from '../MapMenuUtilComponents';
import { Grid, Typography } from '@mui/material';

interface IPathsAndZonesMenuProps {
  onNewPath: () => void;
  onNewSubzone: () => void;
  closeMenu: () => void;
}

export const PathsAndZonesMenu = function PathsAndZonesMenu(props: IPathsAndZonesMenuProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const pathsAndZonesItems: IMapMenuItemObject[] = useMemo(() => {
    return [{
      text: t('map:new_map_path'),
      icon: PathOldIcon,
    }, {
      text: t('map:new_subzone'),
      icon: SubzoneOldIcon,
    }];
  }, [t]);

  const onNewPathClick = useCallback(() => {
    props.onNewPath();
    props.closeMenu();
  }, [props]);

  const onNewSubzoneClick = useCallback(() => {
    props.onNewSubzone();
    props.closeMenu();
  }, [props]);

  return (
    <Grid container direction={'column'} spacing={1} paddingY={1}>
      {/* TITLE */}
      <Grid item>
        <Typography variant='text_xs_medium' color={theme.palette.text.tertiary}>{t('map:new_path_or_subzone')}</Typography>
      </Grid>

      <Grid item>
        <StyledMapMenuItem onClick={onNewPathClick} key={pathsAndZonesItems[0].text}>
          <MapMenuItemChild item={pathsAndZonesItems[0]} />
        </StyledMapMenuItem>
        <StyledMapMenuItem onClick={onNewSubzoneClick} key={pathsAndZonesItems[1].text}>
          <MapMenuItemChild item={pathsAndZonesItems[1]} />
        </StyledMapMenuItem>
      </Grid>

    </Grid>
  );
};
