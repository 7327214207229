import { inject } from 'inversify';

import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import {
  GroupSubscriptionDeleteRequestDto
} from '../../shared/dto/subscription/groupSubscription.delete.request.dto';
import {
  GroupSubscriptionPostRequestDto
} from '../../shared/dto/subscription/groupSubscription.post.request.dto';
import {
  GroupSubscriptionPutRequestDto
} from '../../shared/dto/subscription/groupSubscription.put.request.dto';
import {
  SubscriptionAnonymousPostRequestDto
} from '../../shared/dto/subscription/subscription.anonymous.post.request.dto';
import { PurchasesOffering, PurchasesPackage } from '../../typings/revenue-cat';
import { AsyncTask } from '../async/AsyncTask';
import { GroupSubscriptionUserModel } from '../model/GroupSubscriptionUserModel';
import { StripeProductModel } from '../model/stripe/StripeProductModel';
import { RevenueCatProxy } from '../proxy/RevenueCatProxy';
import { StripeProxy } from '../proxy/StripeProxy';
import { I18nService } from '../service/I18nService';
import { NotificationService } from '../service/NotificationService';
import { TrackingEvent } from '../service/tracking/TrackingEvent';
import { TrackingService } from '../service/tracking/TrackingService';
import { SessionStore } from '../store/SessionStore';
import { IHttpNotOkResponse, IHttpOkResponse } from './AjaxService';

@singleton()
export class StripeService {

  constructor(
    @inject(StripeProxy) private readonly stripeProxy: StripeProxy,
    @inject(RevenueCatProxy) private readonly revenueCatProxy: RevenueCatProxy,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(SessionStore) private readonly sessionStore: SessionStore,
  ) { }

  public fetchRevenueCatOfferings = async (): Promise<string[] | undefined> => {
    try {
      const result = await this.revenueCatProxy.getOfferings();

      if (!result.ok || !result.data) {
        this.notification.error(this.i18n.t('payment:errors.offerings_warning'));
        return;
      }

      const { offerings } = result.data;
      const defaultOffering = offerings.find(
        (offering: PurchasesOffering) => offering.identifier === env.revenueCat.offerings
      ) || null;

      const productIds: string[] = defaultOffering?.packages.map((purchasePackage: PurchasesPackage) => purchasePackage.platform_product_identifier) || [];

      return productIds;
    } catch (error) {
      this.notification.error(this.i18n.t('payment:errors.offerings_error'));
    }
  }

  public fetchProducts = async (productsIds: string[] = []): Promise<StripeProductModel[]> => {
    try {
      const result = await this.stripeProxy.getProducts({ userId: this.sessionStore.userId, productsIds });

      if (!result.ok || !result.data) {
        this.notification.error(this.i18n.t('payment:errors.products_warning'));
        return [];
      }

      return result.data;
    } catch (error) {
      this.notification.error(this.i18n.t('payment:errors.products_error'));
      throw error; // rethrow the error if needed
    }
  }

  public createPortalSession = new AsyncTask(async () => {
    await this.tracking.track(TrackingEvent.MANAGE_SUBSCRIPTION);

    try {
      const result = await this.stripeProxy.createPortalSession();

      if (result.ok) {
        window.location.href = result.data.url;
        return;
      }

      this.notification.error(this.i18n.t('payment:errors.portal_session_fail'));
    } catch (error) {
      console.error(`Exception while creating portal session. ${error}`);
      this.notification.error(this.i18n.t('payment:errors.portal_session_error'));
    }
  })

  public createAnonymousCheckoutSession = async (subscriptionAnonymousDto: SubscriptionAnonymousPostRequestDto): Promise<void> => {
    await this.tracking.track(TrackingEvent.SUBSCRIPTION_STARTED);

    try {
      const result = await this.stripeProxy.createAnonymousCheckoutSession(subscriptionAnonymousDto);

      if (result.ok) {
        window.location.href = result.data.url;
        return;
      }

      if (!result.ok && result.status === 400) {
        return this.notification.error(this.i18n.t('payment:errors.errors.missing_data'));
      }

      this.notification.error(this.i18n.t('payment:errors.checkout_session_fail'));
    }
    catch (error) {
      console.error(`exception while doing login. ${error}`);
      this.notification.error(this.i18n.t('payment:errors.checkout_session_error'));
    }
  }

  public createCheckoutSessionForSingleProduct = async (subscriptionPostRequestDto: GroupSubscriptionPostRequestDto): Promise<void> => {
    await this.tracking.track(TrackingEvent.SUBSCRIPTION_STARTED);

    try {
      const result = await this.stripeProxy.createCheckoutSession(subscriptionPostRequestDto);

      if (result.ok) {
        window.location.href = result.data.url;
        return;
      }

      if (!result.ok && result.status === 400) {
        return this.notification.error(this.i18n.t('payment:errors.trial_period_already_used'));
      }

      this.notification.error(this.i18n.t('payment:errors.checkout_session_fail'));
    }
    catch (error) {
      console.error(`exception while doing login. ${error}`);
      this.notification.error(this.i18n.t('payment:errors.checkout_session_error'));
    }
  }

  public createCheckoutSessionForGroupProduct = async (groupSubscriptionPostRequestDto: GroupSubscriptionPostRequestDto): Promise<void> => {
    try {
      const result = await this.stripeProxy.createCheckoutSession(groupSubscriptionPostRequestDto);

      if (result.ok) {
        window.location.href = result.data.url;
        return;
      }

      this.notification.error(this.i18n.t('payment:errors.checkout_session_fail'));
    }
    catch (error) {
      console.error(`exception while doing login. ${error}`);
      this.notification.error(this.i18n.t('payment:errors.checkout_session_error'));
    }
  }

  public removeUserFromGroupSubscription = async (
    userId: string,
    coupon: string | null | undefined
  ) => {
    try {
      const dto: GroupSubscriptionDeleteRequestDto = {
        userId: userId,
        coupon: coupon,
      };
      const result = await this.stripeProxy.removeUserFromGroupSubscription(dto);
      if (result.ok) {
        return this.notification.success(this.i18n.t('manage_group:delete.success'));
      }

      return this.notification.error(this.i18n.t('manage_group:delete.error'));
    } catch (error) {
      console.error(`error while trying to remove user from a group subscription: ${error}`);
    }
  }

  public removeSeatFromGroupSubscription = async () => {
    try {
      const result = await this.stripeProxy.removeSeatFromGroupSubscription();

      if (result.ok) {
        return this.notification.success(this.i18n.t('manage_group:cancel_seat.success'));
      }

      return this.notification.error(this.i18n.t('manage_group:cancel_seat.error'));
    } catch (error) {
      console.error(`Something went wrong while trying to remove seat from a group subscription: ${error}`);
    }
  }

  public getAreasRelatedMembers = async () => {
    try {
      const response = await this.stripeProxy.getRelatedMembers();

      if (response.ok) {
        return response.data;
      }

      this.notification.error(this.i18n.t('manage_group:error.fetching_related_members'));
    } catch (error) {
      console.error(`exception while fetching group subscription info. ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.fetching_related_members'));
    }
  }

  public getPaymentMethods = async () => {
    try {
      const response = await this.stripeProxy.getPaymentMethods();

      if (response.ok) {
        // * Always use [0] because we will only have one payment method in the array.
        return response.data.length ? response.data[0] : null;
      }

      this.notification.error(this.i18n.t('manage_group:error.fetching_payment_methods'));

    } catch (error) {
      console.error(`exception while fetching payment methods. ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.fetching_payment_methods'));
    }
  }

  public getUpcomingInvoiceLineItems = async (quantity?: number | undefined) => {
    try {
      const response = await this.stripeProxy.getUpcomingInvoiceLines(quantity);

      if (response.ok) {
        return response.data;
      }

      this.notification.error(this.i18n.t('manage_group:error.fetching_upcoming_invoice_line_items'));
    } catch (error) {
      console.error(`exception while fetching group subscription info. ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.fetching_upcoming_invoice_line_items'));
    }
  };

  public getUpcomingInvoice = async (quantity?: number | undefined) => {
    try {
      const response = await this.stripeProxy.getUpcomingInvoice(quantity);

      if (response.ok) {
        return response.data;
      }

      this.notification.error(this.i18n.t('manage_group:error.fetching_upcoming_invoice'));

    } catch (error) {
      console.error(`exception while fetching group subscription info. ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.fetching_upcoming_invoice'));
    }
  };

  public validateUserEmail = async (email: string): Promise<IHttpNotOkResponse | IHttpOkResponse<GroupSubscriptionUserModel> | undefined> => {
    try {
      return await this.stripeProxy.validateUserEmail(email);
    } catch (error) {
      console.error(`Exception while validating user's email: ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.validating_user_email'));
    }
  }

  public resendEmailInvitation = async (userId: string) => {
    try {
      const result = await this.stripeProxy.resendEmailInvitation(userId);

      if (result.ok) {
        return this.notification.success(this.i18n.t('manage_group:resend_invitation.success'));
      }

      return this.notification.error(this.i18n.t('manage_group:error.resend_invitation'));
    } catch (error) {
      console.error(`Exception while canceling user's invitation: ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.resend_invitation'));
    }
  }

  public cancelEmailInvitation = async (userId: string) => {
    try {
      const result = await this.stripeProxy.cancelEmailInvitation(userId);

      if (result.ok) {
        return this.notification.success(this.i18n.t('manage_group:success.cancel_invitation'));
      }

      return this.notification.error(this.i18n.t('manage_group:error.cancel_invitation'));
    } catch (error) {
      console.error(`Exception while canceling user's invitation: ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.cancel_invitation'));
    }
  }

  public updateGroupSubscription = async (dto: GroupSubscriptionPutRequestDto) => {
    try {
      const response = await this.stripeProxy.updateGroupSubscription(dto);

      if (response.ok) {
        return response.data;
      }

      this.notification.error(this.i18n.t('manage_group:error.updating_group_subscription'));
    } catch (error) {
      console.error(`Exception while updating group subscription: ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.updating_group_subscription'));
    }
  };

  public getSubscriptionInfo = async () => {
    try {
      const response = await this.stripeProxy.getGroupSubscriptionInfo();

      if (response.ok) {
        return response.data;
      }

      this.notification.error(this.i18n.t('manage_group:error.fetching_group_subscription_info'));
    } catch (error) {
      console.error(`exception while fetching group subscription info. ${error}`);
      this.notification.error(this.i18n.t('manage_group:error.fetching_group_subscription_info'));
    }
  }

}
