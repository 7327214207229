import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/system';
import React, { SyntheticEvent, useCallback } from 'react';
import { IGoogleMapProps } from '../../../domain/core/IGoogleMapProps';
import { IGeoLocation } from '../../../shared/interfaces/IGeoLocation';
import { OptionalTooltip } from '../../../toolkit/components/OptionalTooltip';

interface IProps extends IGoogleMapProps {
  selected: boolean;
  setupInProgress: boolean;
  color: string;
  onClick: (location: IGeoLocation) => void;
  onDelete: (location: IGeoLocation) => void;
  zIndex?: number;
}

export function SubzoneCircle(props: IProps) {
  const { onClick, onDelete, setupInProgress, lat, lng } = props;
  const sizeBig = 12;
  const sizeSmall = 6;

  const internalOnClick = useCallback((e: SyntheticEvent) => {
    if (!setupInProgress) {
      return;
    }

    e.stopPropagation();

    if (lat && lng) {
      onClick({ latitude: lat, longitude: lng });
    }
  }, [onClick, lat, lng, setupInProgress]);

  const internalOnDelete = useCallback(() => {
    if (lat && lng) {
      onDelete({ latitude: lat, longitude: lng });
    }
  }, [onDelete, lat, lng]);

  return (
    <OptionalTooltip
      enabled={props.setupInProgress}
      leaveDelay={200}
      placement="top"
      sx={{ zIndex: props.zIndex ?? 1 }}
      title={<Box
        display="flex"
        flexDirection="row"
        justifyContent={'space-between'}
        onClick={internalOnDelete}
      >
        <DeleteIcon />
      </Box>}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            minWidth: 'auto',
            paddingX: 1,
            paddingY: 1,
            cursor: 'pointer',
          }
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          transform: 'translate(-50%, -50%)',
          width: props.setupInProgress ? `${sizeBig}px` : `${sizeBig}px`,
          height: props.setupInProgress ? `${sizeBig}px` : `${sizeBig}px`,
          backgroundColor: props.selected ? 'white' : props.color,
          cursor: 'pointer',
        }}
        onClick={internalOnClick}
      >
        <Box sx={{
          borderRadius: '50%',
          width: props.setupInProgress ? `${sizeSmall}px` : `${sizeSmall}px`,
          height: props.setupInProgress ? `${sizeSmall}px` : `${sizeSmall}px`,
          backgroundColor: props.selected ? props.color : 'white',
        }} />
      </Box>
    </OptionalTooltip>
  );
}
