import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/system';
import { SyntheticEvent, useCallback } from 'react';
import { IGoogleMapProps } from '../../../domain/core/IGoogleMapProps';
import { IGeoLocation } from '../../../shared/interfaces/IGeoLocation';
import { OptionalTooltip } from '../../../toolkit/components/OptionalTooltip';

interface IProps extends IGoogleMapProps {
  selected: boolean;
  onClick: (location: IGeoLocation) => void;
  onDelete: (location: IGeoLocation) => void;
  circleSize?: number;
}

export function DistrictCircle(props: IProps) {
  const { onClick, onDelete, lat, lng } = props;
  const sizeBig = 16;
  const sizeSmall = sizeBig / 2;

  const internalOnClick = useCallback((e: SyntheticEvent) => {
    e.stopPropagation();

    if (!lat || !lng) {
      return;
    }

    const location: IGeoLocation = { latitude: lat, longitude: lng };
    onClick(location);
  }, [onClick, lat, lng]);

  const internalOnDelete = useCallback(() => {
    if (lat && lng) {
      onDelete({ latitude: lat, longitude: lng });
    }
  }, [onDelete, lat, lng]);

  return (
    <OptionalTooltip
      enabled
      leaveDelay={200}
      placement="top"
      title={<Box
        display="flex"
        flexDirection="row"
        justifyContent={'space-between'}
        onClick={internalOnDelete}
      >
        <DeleteIcon />
      </Box>}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            minWidth: 'auto',
            paddingX: 1,
            paddingY: 1,
            cursor: 'pointer',
          }
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          transform: 'translate(-50%, -50%)',
          width: `${sizeBig}px`,
          height: `${sizeBig}px`,
          backgroundColor: props.selected ? 'white' : 'common.districtMesh',
          cursor: 'pointer',
        }}
        onClick={internalOnClick}
      >
        <Box sx={{
          borderRadius: '50%',
          width: `${sizeSmall}px`,
          height: `${sizeSmall}px`,
          backgroundColor: props.selected ? 'common.districtMesh' : 'white',
        }} />
      </Box>
    </OptionalTooltip>
  );
}
