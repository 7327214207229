import { IsEnum, IsString } from 'class-validator';

import { PLATFORM } from '../enum';

export class PushNotificationPostRequestDto {
  @IsEnum(PLATFORM)
  public platform: PLATFORM;

  @IsString()
  public token: string;
}
