import { Box, List } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Assets } from '../../../../../assets';
import { useInstance } from '../../../../../domain/hooks/useInstance';
import { ENTRY_TYPE } from '../../../../../shared/enum/entryType.enum';
import { ClassificationHelper } from '../../../../../util/classification/ClassificationHelper';
import { ClassificationTranslationService } from '../../../../../util/classification/ClassificationTranslationService';
import { AnimalTypeFilter } from '../../../filters/AnimalTypeFilter';
import { FilterListItem } from '../components/FilterListItem';
import { NoEntitiesToFilter } from '../components/NoEntitiesToFilter';
import { SelectDeselectAll } from '../components/SelectDeselectAll';

interface IProps {
  animalTypeFilter: AnimalTypeFilter;
}

export const FilterSidebarAnimalType = observer(function FilterSidebarAnimalType(props: IProps) {
  const { t } = useTranslation();
  const classificationService = useInstance(ClassificationTranslationService);
  const classificationHelper = useInstance(ClassificationHelper);

  return (
    <Box mx={3} my={1}>
      <Box display="flex" justifyContent={'flex-end'}>
        <SelectDeselectAll filter={props.animalTypeFilter} />
      </Box>
      {props.animalTypeFilter.conditions.length > 0 ? <List>
        {props.animalTypeFilter.conditions.map((condition) => {
          const conditionText = classificationService.getGroupOrSpeciesTranslationForClassificationObject(condition.classification);
          const condictionIcon = classificationHelper.getClassificationOrEntryTypeIcon(condition.classification, ENTRY_TYPE.MISC, true);

          return (
            <FilterListItem
              key={`animal-type-condition-${condition.id}`}
              onSelectOnlyThis={() => props.animalTypeFilter.selectOnlyCondition(condition)}
              iconUrl={condictionIcon ?? Assets.entry.other.misc}
              title={conditionText ?? t('filter:animal_filter_without_class')}
              checked={condition.active}
              onClick={() => props.animalTypeFilter.toggle(condition)}
            />
          );
        })}
      </List> : <NoEntitiesToFilter text={t('filter:no_animals_to_filter')} />}
    </Box>
  );
});
