import { observer } from 'mobx-react-lite';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, FormControl, FormControlLabel, Grid, Radio,
  RadioGroup, Typography
} from '@mui/material';

import { useContainer } from '../../../../../../domain/hooks/useContainer';
import { AmmunitionModel } from '../../../../../../domain/model/AmmunitionModel';
import { EntryModel } from '../../../../../../domain/model/EntryModel';
import { GearSetModel } from '../../../../../../domain/model/GearsetModel';
import { palette } from '../../../../../../theme/options/palette/palette';
import { EntryHelper } from '../../../../../../util/EntryHelper';
import { GearSetHelper } from '../../../../../../util/GearSetHelper';
import { useCallback } from 'react';

export interface EntryAccordionProps {
  entry: EntryModel;
  gearSet: GearSetModel;
  canEditAndDeleteEntries: boolean;
  isHarvestVisibleAndEditable: boolean;
}

export const GearSetAccordion = observer(function GearSetAccordion(props: EntryAccordionProps) {
  const entryHelper = useContainer().get(EntryHelper);
  const gearSetHelper = useContainer().get(GearSetHelper);

  const checkPermissions = useCallback(() => {
    if (!props.canEditAndDeleteEntries) {
      if (!props.isHarvestVisibleAndEditable) {
        return entryHelper.notifyWrongPermission('entry:harvest_readonly_permission');
      }
      return entryHelper.notifyWrongPermission();
    }
  }, [entryHelper, props.canEditAndDeleteEntries, props.isHarvestVisibleAndEditable]);

  const handleAmmoChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    checkPermissions();
    props.entry.shot?.setAmmunitionId(event.target.value);
  }, [checkPermissions, props.entry.shot]);


  return (
    <Accordion sx={{ width: 'inherit', boxShadow: 'none' }}>
      <AccordionSummary
        onClick={(event) => event?.preventDefault()}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container direction="column" justifyContent="center">
          <Grid item>
            <Typography fontSize={14} color={palette.text?.info}>
              {gearSetHelper.getWeaponAndManufacturer(props.gearSet)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography fontSize={17} fontWeight="bold" color="#000">{props.gearSet.name}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl fullWidth>
          <RadioGroup
            value={props.entry.shot?.ammunitionId}
            onChange={handleAmmoChange}
            name="gearSetAmmo-radio-buttons-group"
          >

            {props.gearSet.ammunition.map((ammo: AmmunitionModel) => {
              return (
                <Box key={`ammo-${ammo.id}`}>
                  <FormControlLabel
                    value={ammo.id}
                    control={
                      <Radio color='secondary'
                        inputProps={{
                          readOnly: !props.canEditAndDeleteEntries
                        }}
                      />}
                    label={ammo.manufacturerAndCaliber}
                    labelPlacement="start"
                    sx={{
                      '&.MuiFormControlLabel-root': {
                        display: 'flex',
                        justifyContent: 'space-between',
                      },
                    }}
                  />
                </Box>
              );
            })}
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>

  );
});
