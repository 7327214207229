import { GroupSubscriptionModel } from '../../../domain/model/GroupSubscriptionModel';
import { GroupSubscriptionUserModel } from '../../../domain/model/GroupSubscriptionUserModel';

export enum GroupOverviewTypeEnum {
  MembersOnly = 'membersOnly',
  WithOwner = 'withOwner',
}

interface ICommonGroupProperties {
  assignedMembers: Set<GroupSubscriptionUserModel>;
  groupMembersOverview: Set<GroupSubscriptionUserModel>;
  candidatesForAssignment: GroupSubscriptionUserModel[];
  groupTotalPriceWithoutDiscount: number;
  groupTotalDiscount: number;
  groupTotalPriceAfterDiscount: number;
  currency: string;
  priceForEveryNextUser: number;
}

interface IBaseGroupOverview extends ICommonGroupProperties {
  type: GroupOverviewTypeEnum;
}

export interface IGroupOverviewMembersOnly extends IBaseGroupOverview {
  type: GroupOverviewTypeEnum.MembersOnly;
  groupSubscriptionInfo: GroupSubscriptionModel;
  proratedPricePerUser?: number; // * used on a subscription update
}

export interface IGroupOverviewWithOwner extends IBaseGroupOverview {
  type: GroupOverviewTypeEnum.WithOwner;
  owner: GroupSubscriptionUserModel | undefined;
  ownerPriceWithoutDiscount: number;
  ownerDiscount: number;
  ownerFinalPrice: number;
}

export type GroupOverviewType = IGroupOverviewMembersOnly | IGroupOverviewWithOwner;

export function isGroupOverviewWithOwner(data: GroupOverviewType): data is IGroupOverviewWithOwner {
  return data.type === GroupOverviewTypeEnum.WithOwner;
}

export function isGroupOverviewMembersOnly(data: GroupOverviewType): data is IGroupOverviewMembersOnly {
  return data.type === GroupOverviewTypeEnum.MembersOnly;
}
