import { action, computed, makeObservable, observable } from 'mobx';

import { Assets } from '../../assets';
import { CustomMarkPostRequestDto, CustomMarkResponseDto } from '../../shared/dto';
import { CUSTOM_MARK_ICON } from '../../shared/enum/customMarkIcon.enum';
import { PoiFeatureModel } from './PoiFeatureModel';

export class CustomMarkModel {

  @observable
  public id: string | undefined = undefined;

  @observable
  public name: string = '';

  @observable
  public foreground: CUSTOM_MARK_ICON | null = null;

  @observable
  public background: string | null = null;

  @observable
  public features: PoiFeatureModel = new PoiFeatureModel();

  constructor() {
    makeObservable(this);
  }

  @action
  public setBackground = (background: string | null) => {
    this.background = background;
  }

  @action
  public setForeground = (foreground: CUSTOM_MARK_ICON | null) => {
    this.foreground = foreground;
  }

  @computed
  public get foregroundIcon() {
    switch (this.foreground) {
      case CUSTOM_MARK_ICON.BEEHIVE:
        return Assets.customMark.white.beehive_icon_white;
      case CUSTOM_MARK_ICON.BEETLE:
        return Assets.customMark.white.beetle_icon_white;
      case CUSTOM_MARK_ICON.FAVORITE_STAR:
        return Assets.customMark.white.favourite_star_icon_white;
      case CUSTOM_MARK_ICON.HEART:
        return Assets.customMark.white.heart_icon_white;
      case CUSTOM_MARK_ICON.HIGHSTAND_LADDER:
        return Assets.customMark.white.highstand_ladder_icon_white;
      case CUSTOM_MARK_ICON.HUT:
        return Assets.customMark.white.hut_icon_white;
      case CUSTOM_MARK_ICON.INFO:
        return Assets.customMark.white.information_icon_white;
      case CUSTOM_MARK_ICON.MOBILE_HIGHSTAND:
        return Assets.customMark.white.mobile_highstand_icon_white;
      case CUSTOM_MARK_ICON.NO_ENTRY:
        return Assets.customMark.white.no_entry_icon_white;
      case CUSTOM_MARK_ICON.PUDDLE:
        return Assets.customMark.white.puddle_icon_white;
      case CUSTOM_MARK_ICON.RACK:
        return Assets.customMark.white.rack_icon_white;
      case CUSTOM_MARK_ICON.RUT:
        return Assets.customMark.white.rut_icon_white;
      case CUSTOM_MARK_ICON.SLAT:
        return Assets.customMark.white.salt_icon_white;
      case CUSTOM_MARK_ICON.SOS:
        return Assets.customMark.white.sos_icon_white;
      case CUSTOM_MARK_ICON.WARNING:
        return Assets.customMark.white.warning_icon_white;
      case CUSTOM_MARK_ICON.WATER_SUPPLY:
        return Assets.customMark.white.water_supply_icon_white;
      case CUSTOM_MARK_ICON.BIOTOPE:
        return Assets.customMark.white.biotope_icon_white;
      case CUSTOM_MARK_ICON.BLIND:
        return Assets.customMark.white.blind_icon_white;
      case CUSTOM_MARK_ICON.BRIDGE:
        return Assets.customMark.white.bridge_icon_white;
      case CUSTOM_MARK_ICON.CLOSED_HIGHSEAT:
        return Assets.customMark.white.closed_highseat_icon_white;
      case CUSTOM_MARK_ICON.LADDER:
        return Assets.customMark.white.ladder_icon_white;
      case CUSTOM_MARK_ICON.MEETING_POINT:
        return Assets.customMark.white.meeting_point_icon_white;
      case CUSTOM_MARK_ICON.OPEN_SEAT:
        return Assets.customMark.white.open_seat_icon_white;
      case CUSTOM_MARK_ICON.PARKING:
        return Assets.customMark.white.parking_icon_white;
      case CUSTOM_MARK_ICON.SCISSOR_LADDER:
        return Assets.customMark.white.scissor_ladder_icon_white;
      case CUSTOM_MARK_ICON.HIDE_BUCK:
        return Assets.customMark.white.hide_buck_icon_white;
      case CUSTOM_MARK_ICON.OPEN_HIGHSEAT:
        return Assets.customMark.white.open_highseat_icon_white;
      case CUSTOM_MARK_ICON.NESTING_BOX:
        return Assets.customMark.white.nesting_box;
    }
  }

  public static fromDto = (dto: CustomMarkResponseDto) => {
    const model = new CustomMarkModel();

    model.id = dto.id;
    model.name = dto.name;
    model.foreground = dto.foreground as CUSTOM_MARK_ICON;
    model.background = dto.background;
    model.features = PoiFeatureModel.fromDto({ isBookable: dto.isBookable, canHaveTasks: dto.canHaveTasks, hasShootingDirection: dto.hasShootingDirection });

    return model;
  }

  public static toPostDto(customMark: CustomMarkModel): CustomMarkPostRequestDto {
    const dto = new CustomMarkPostRequestDto();

    dto.background = customMark.background || '';
    dto.foreground = customMark.foreground || '';
    dto.name = customMark.name;
    dto.canHaveTasks = customMark.features.canHaveTasks;
    dto.isBookable = customMark.features.isBookable;
    dto.hasShootingDirection = customMark.features.canHaveShootingDirection;

    return dto;
  }

  public clone = () => {
    const cloned = new CustomMarkModel();

    cloned.id = this.id;
    cloned.name = this.name;
    cloned.background = this.background;
    cloned.foreground = this.foreground;
    cloned.features = this.features.clone();

    return cloned;
  }
}
