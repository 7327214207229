import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import { ViewModel } from '../../../../domain/core/ViewModel';
import { AuthProxy } from '../../../../domain/proxy/AuthProxy';
import { I18nService } from '../../../../domain/service/I18nService';
import { NotificationService } from '../../../../domain/service/NotificationService';
import { transient } from '../../../../inversify/decorator';
import { Types } from '../../../../inversify/types';
import { AppRoutes } from '../../../../router/AppRoutesEnum';
import { UserPostForgotPasswordDto } from '../../../../shared/dto';
import { LANGUAGE } from '../../../../shared/enum';
import { EmailStepProps } from './EmailStep';

export enum PasswordResetScreens {
  Form = 'form',
  Success = 'success',
}

const RESEND_EMAIL_IN_SECONDS = 60;

@transient()
export class PasswordResetVm extends ViewModel<EmailStepProps> {

  @observable
  public seconds: number = RESEND_EMAIL_IN_SECONDS;

  @observable
  public timer: ReturnType<typeof setInterval>;

  constructor(
    @inject(AuthProxy) private readonly authProxy: AuthProxy,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  @action
  private setSeconds = (): void => {
    this.seconds = RESEND_EMAIL_IN_SECONDS;
  }

  public resetTimer = (): void => {
    clearInterval(this.timer);
  }

  public startTimer = (): void => {
    if (this.seconds > 0) {
      runInAction(() => {
        this.timer = setInterval(() => {
          runInAction(() => {
            this.seconds = this.seconds > 0 ? this.seconds - 1 : 0;
          });
        }, 1000);
      });
    }
  }

  public onBackToLogin = (): void => {
    this.navigate(AppRoutes.Login);
  }

  public sendResetPasswordEmail = async ({ email }: UserPostForgotPasswordDto): Promise<void> => {
    this.setSeconds();

    const language = Object.values(LANGUAGE).find((lang: string) => lang === this.i18n.language) || LANGUAGE.en;
    try {
      const response = await this.authProxy.forgotPassword.run({ email, language });
      const nextStep = response.ok;

      /** condition is true only when it's on a EmailStep  */
      if (this.props.onEmail) {
        this.props.onEmail(email, nextStep);
      }

      if (!response.ok) {
        this.notification.error(this.i18n.t('auth:reset_step1_nonexistent_email'));
      }
    } catch (error) {
      this.notification.error(this.i18n.t('auth:reset_step1_error'));
    }
  }

  @computed
  public get isBusy() {
    return this.authProxy.forgotPassword.isBusy;
  }
}
