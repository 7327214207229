import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar, Box, CssBaseline, Drawer, Grid, IconButton, ListItem, ListItemText, Toolbar, Typography,
  useMediaQuery
} from '@mui/material';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import { CSSProperties, makeStyles } from '@mui/styles';

import { useContainer } from '../../domain/hooks/useContainer';
import { useVm } from '../../domain/hooks/useVm';
import { SessionStore } from '../../domain/store/SessionStore';
import { AppRoutes } from '../../router/AppRoutesEnum';
import { oldDesignTheme } from '../../theme/oldDesignTheme';
import { SidebarNavigationItem } from '../../toolkit/constants/SidebarNavigationList';
import { SidebarMenuItem } from './SidebarMenuItem';
import { SidebarMenuVm } from './SidebarMenuVm';

const drawerWidth = 70;

const useStyles = makeStyles({
  root: {
    '& .MuiAppBar-colorPrimary': {
      color: oldDesignTheme.palette.text.tertiary,
      background: oldDesignTheme.palette.background.default,
    },
    display: 'flex',
  },
  drawer: {
    [oldDesignTheme.breakpoints.up('md')]: {
      width: drawerWidth,
      display: 'flex',
      flexDirection: 'column',
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: oldDesignTheme.spacing(2),
  },
  toolbar: oldDesignTheme.mixins.toolbar as CSSProperties,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    height: '100%',
  },
  selectedSidebarItem: {
    borderRight: `3px solid ${oldDesignTheme.palette.secondary.main}`,
  },
  selectedMenuItem: {
    textDecoration: 'none',
    color: oldDesignTheme.palette.text.secondary,
  }
});

interface ISidebarMenuProps extends React.PropsWithChildren<unknown> {
  items?: SidebarNavigationItem[];
}

export const SidebarMenu = ({ children, items }: ISidebarMenuProps) => {
  const classes = useStyles();
  const sessionStore = useContainer().get(SessionStore);
  const theme = useTheme();
  const vm = useVm(SidebarMenuVm);
  const location = useLocation();
  const { t } = useTranslation();
  const isTabletScreen = useMediaQuery('(max-width:1024px)');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [showAppbar, setShowAppbar] = useState(false);

  useEffect(() => {
    setShowAppbar(isTabletScreen);
  }, [theme.breakpoints, isTabletScreen]);

  const handleDrawerToggle = useCallback(() => setMobileOpen(!mobileOpen), [mobileOpen]);

  const hideMenu = useMemo(() => [AppRoutes.SuccessfulPayment, AppRoutes.PricingEmbedded, AppRoutes.SuccessfulPaymentEmbedded].includes(location.pathname as AppRoutes), [location.pathname]);

  const renderDesktopDrawer = useCallback(() => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1} paddingY={1}>
        <List>
          {items?.map(({ to, icon, value, visibleFor }, index) => (
            visibleFor.includes(sessionStore.userType) &&
            <SidebarMenuItem key={`${to}-${value}-${index}`} to={to} activeClassName={location.pathname === to ? classes.selectedSidebarItem : undefined}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item container justifyContent="center"><img src={icon} width="52px" height="50px" /></Grid>
                <Grid item>
                  <Typography variant="subtitle2" fontSize="9px" fontWeight="bold" color="#A3B0C5" textAlign="center" sx={{ overflowWrap: 'break-word' }}>{t(value)}</Typography>
                </Grid>
              </Grid>
            </SidebarMenuItem>
          ))}
        </List>

        <Box flexGrow={1} />

        <LogoutIcon onClick={vm.logout} color="secondary" sx={{ width: '40px', height: '50px', cursor: 'pointer' }} />
      </Box>
    );
  }, [classes.selectedSidebarItem, items, location.pathname, sessionStore.userType, t, vm.logout]);

  const renderMobileDrawer = useCallback(() => (
    <List>
      {items?.map(({ to, value, visibleFor }, index) => (
        visibleFor.includes(sessionStore.userType) &&
        <NavLink key={`${to}-${value}-${index}`} to={to} className={classes.selectedMenuItem}>
          <ListItem selected={to === location.pathname}>
            <ListItemText primary={t(value)} />
          </ListItem>
        </NavLink>
      ))}

      <div className={classes.selectedMenuItem} onClick={vm.logout}>
        <ListItem>
          <ListItemText primary={t('auth:button.logout')} />
        </ListItem>
      </div>
    </List>
  ), [classes.selectedMenuItem, items, location.pathname, sessionStore.userType, t, vm.logout]);

  return (
    <Box className={classes.root}>
      <CssBaseline />
      {
        !hideMenu && showAppbar && <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
              size="large">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
              {t('common:sidebar_mobile_title')}
            </Typography>
            <Box flexGrow={1} />
            {items?.map(({ to, icon, value, visibleFor }, index) => (
              <div key={`${to}-${value}-${index}`}>
                {
                  (location.pathname === to)
                  && (visibleFor.includes(sessionStore.userType))
                  && <img src={icon} width="52px" height="40px" />
                }
              </div>
            ))}
          </Toolbar>
        </AppBar>
      }
      {!hideMenu && <nav className={classes.drawer}>
        {/* tablet/mobile device appbar */}
        <Hidden mdUp>
          <Drawer anchor="top" variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} keepMounted>
            {renderMobileDrawer()}
          </Drawer>
        </Hidden>
        {/* desktop sidebar */}
        {!isTabletScreen && <Hidden>
          <Drawer anchor="left" variant="permanent" open>
            {renderDesktopDrawer()}
          </Drawer>
        </Hidden>}
      </nav>}
      <main className={classes.content}>
        {!hideMenu && showAppbar && <div className={classes.toolbar} />}
        {children}
      </main>
    </Box>
  );
};
