import { makeObservable, observable } from 'mobx';

import { SubscriptionResponseDto } from '../../shared/dto';

export class SubscriptionResponseModel {

  @observable
  public name: string;

  @observable
  public features: string[];

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: SubscriptionResponseDto) => {
    const subscription = new SubscriptionResponseModel();

    subscription.name = dto.name;
    subscription.features = dto.features;

    return subscription;
  }
}
