import React from 'react';

export const MinusIcon = () => {
  return (
    <svg id="Component_240_30" data-name="Component 240 – 30" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <defs>
        <clipPath id="clip-path_minus">
          <rect id="Rectangle_6179" data-name="Rectangle 6179" width="18" height="18" fill="#fc294b" />
        </clipPath>
      </defs>
      <g id="Group_18278" data-name="Group 18278" transform="translate(0 0)">
        <g id="Group_18278-2" data-name="Group 18278" transform="translate(0 0)" clipPath="url(#clip-path_minus)">
          <path id="Path_27867" data-name="Path 27867" d="M0,9A9.052,9.052,0,0,1,8.994,0,9.063,9.063,0,0,1,18,9a9.057,9.057,0,0,1-9,9A9.053,9.053,0,0,1,0,9m12.583.764a.756.756,0,1,0,0-1.508H5.4a.757.757,0,1,0,0,1.508Z" transform="translate(0 0)" fill="#fc294b" />
        </g>
      </g>
    </svg>
  );
};
