export enum CUSTOM_MARK_ICON {
  BEEHIVE = 'BEEHIVE',
  BEETLE = 'BEETLE',
  FAVORITE_STAR = 'FAVORITE_STAR',
  HUT = 'HUT',
  INFO = 'INFO',
  OPEN_HIGHSEAT = 'OPEN_HIGHSEAT',
  HIGHSTAND_LADDER = 'HIGHSTAND_LADDER',
  MOBILE_HIGHSTAND = 'MOBILE_HIGHSTAND',
  HIDE_BUCK = 'HIDE_BUCK',
  CLOSED_HIGHSEAT = 'CLOSED_HIGHSEAT',
  OPEN_SEAT = 'OPEN_SEAT',
  LADDER = 'LADDER',
  SCISSOR_LADDER = 'SCISSOR_LADDER',
  NO_ENTRY = 'NO_ENTRY',
  PUDDLE = 'PUDDLE',
  RACK = 'RACK',
  RUT = 'RUT',
  SLAT = 'SLAT',
  SOS = 'SOS',
  WARNING = 'WARNING',
  WATER_SUPPLY = 'WATER_SUPPLY',
  BLIND = 'BLIND',
  BRIDGE = 'BRIDGE',
  MEETING_POINT = 'MEETING_POINT',
  PARKING = 'PARKING',
  HEART = 'HEART',
  BIOTOPE = 'BIOTOPE',
  NESTING_BOX = 'NESTING_BOX',
  BLOOD_TRAIL = 'BLOOD_TRAIL'
}
