import { observer } from 'mobx-react-lite';
import React, { BaseSyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  CircularProgress, Divider, Grid, IconButton, Tab, Tabs, ThemeProvider, Typography
} from '@mui/material';
import { Box } from '@mui/system';

import { useVm } from '../../../../../../../domain/hooks/useVm';
import { TrackingEvent } from '../../../../../../../domain/service/tracking/TrackingEvent';
import { DISTRICT_ROLE } from '../../../../../../../shared/enum';
import { useThemeContext } from '../../../../../../../theme/ThemeContextProvider';
import { AppModal, AppModalRef } from '../../../../../../../toolkit/components/AppModal';
import { CopyToClipboard } from '../../../../../../../toolkit/components/CopyToClipboard';
import { ExpandableText } from '../../../../../../../toolkit/components/ExpandableText';
import { DistrictRolesStepper } from './DistrictRolesStepper';
import { ViewDistrictMember } from './view-district-member/ViewDistrictMember';
import { IViewDistrictMembersProps, ViewDistrictMembersVm } from './ViewDistrictMembersVm';

interface ITab {
  role: DISTRICT_ROLE;
  label: string;
}

export const ViewDistrictMembers = observer(function ViewDistrictMembers(props: IViewDistrictMembersProps) {
  const vm = useVm(ViewDistrictMembersVm, props);
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const appModalRef: React.RefObject<AppModalRef> = useMemo(() => React.createRef(), []);

  const handleOpenModal = useCallback(() => {
    vm.tracking.track(TrackingEvent.MEMBER_TUTORIAL_CLICKED);
    appModalRef.current?.open();
  }, [appModalRef, vm.tracking]);

  const handleCloseModal = useCallback((e: BaseSyntheticEvent) => {
    appModalRef.current?.close();
    e.stopPropagation();
  }, [appModalRef]);

  const tabs: ITab[] = useMemo(() => [{
    role: DISTRICT_ROLE.USER,
    label: t('share:invitation_code.member_role')
  }, {
    role: DISTRICT_ROLE.GUEST,
    label: t('share:invitation_code.guest_role')
  }], [t]);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleTabChange = useCallback((event: React.ChangeEvent<{}>, newRole: DISTRICT_ROLE) => {
    vm.setSelectedRole(newRole);
    vm.tracking.track(TrackingEvent.MEMBER_SWITCHER_CHANGED);

    const activeTab = tabs.find(tab => tab.role === newRole);
    if (activeTab) {
      setActiveTab(activeTab);
    }
  }, [tabs, vm]);

  const handleNewCode = useCallback(() => {
    vm.regenerateNewCode.run();
  }, [vm.regenerateNewCode]);

  const rolesTab = useMemo(() => {
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <Typography variant='text_sm_regular'>{t('share:invitation_code.title')}</Typography>
        </Grid>
        <Grid item xs={6} textAlign={'end'}>
          <Typography variant='text_sm_regular' sx={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={handleOpenModal}>{t('district:role_modal.button')}</Typography>
        </Grid>
        <AppModal ref={appModalRef}>
          <DistrictRolesStepper closeModal={handleCloseModal} />
        </AppModal>
        <Box paddingY={1} display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'}>
          <Tabs value={vm.selectedRole} onChange={handleTabChange}
            TabIndicatorProps={{ hidden: true }}
            sx={{
              width: '100%',
              borderRadius: 2,
              backgroundColor: theme.palette.background.container_2,
              '& .MuiTab-root': {
                color: theme.palette.text.primary,
                ...theme.typography.text_sm_semibold,
              },
              '& button.Mui-selected': {
                borderRadius: 2,
                backgroundColor: theme.palette.brand?.brand_color,
                color: theme.palette.system?.white,
              }
            }}
          >
            {tabs.map(tab => (
              <Tab key={tab.role} value={tab.role} label={tab.label} sx={{ textTransform: 'none', width: '50%', }} />
            ))}
          </Tabs>
        </Box>
      </React.Fragment>
    );
  }, [appModalRef, handleCloseModal, handleOpenModal, handleTabChange, t, tabs, theme.palette.background.container_2, theme.palette.brand?.brand_color, theme.palette.system?.white, theme.palette.text.primary, theme.typography.text_sm_semibold, vm.selectedRole]);

  const handleCopiedText = useCallback(() => {
    vm.tracking.track(TrackingEvent.MEMBER_CODE_COPIED, { role: vm.trackingEventRole });
  }, [vm.tracking, vm.trackingEventRole]);

  const codeSection = useMemo(() => {
    return (
      <Grid container alignItems={'center'} gap={2} paddingY={1}>
        <Grid item flexGrow={1} />
        <Grid item xs={1}>
          <IconButton onClick={handleNewCode}>
            <AutorenewIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='h5' fontWeight={'bold'} color={theme.palette.text.primary}>
            {vm.invitationCode}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <CopyToClipboard tooltipText={vm.invitationCode} textCopied={handleCopiedText} />
        </Grid>
        <Grid item flexGrow={2} />
      </Grid>
    );
  }, [handleCopiedText, handleNewCode, theme.palette.text.primary, vm.invitationCode]);

  return (
    <Box width="100%">
      {vm.isBusy
        ? <Box display="flex" justifyContent="center" padding={4}>
          <CircularProgress />
        </Box>
        : <Grid container paddingX={2} paddingY={2}>
          {vm.canInviteOtherUsersToArea && <ThemeProvider theme={theme}>
            {rolesTab}
            {codeSection}
            <Grid item>
              <ExpandableText
                text={activeTab.role === DISTRICT_ROLE.USER
                  ? t('share:invitation_code.member_role_explanation_text')
                  : t('share:invitation_code.guest_role_explanation_text')}
                maxLength={91}
              />
            </Grid>
            <Divider />
          </ThemeProvider>}
          {vm.members.map((member, index) => <ViewDistrictMember
            key={`district-member-${member.user?.id}`}
            member={member}
            showDivider={index < vm.members.length - 1}
            district={props.district}
            onMemberRemoved={vm.removeMember}
            currentUserRole={vm.currentUserRole}
          />)}
        </Grid>}
    </Box>
  );
});
