import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEffectOnce } from 'react-use';

import { Box, Grid, Typography, useMediaQuery } from '@mui/material';

import { Assets } from '../../../../assets';
import { useVm } from '../../../../domain/hooks/useVm';
import { colors } from '../../../../theme/options/palette/const/colors';
import { Background } from '../../../../toolkit/components/Background';
import { IconAndTitle } from '../../../../toolkit/components/IconAndTitle';
import { SubmitButton } from '../../../../toolkit/components/SubmitButton';
import { SuccessfulPaymentRouteVm } from './SuccessfulPaymentRouteVm';

export const SuccessfulPaymentRoute = observer(function SuccessfulPaymentRoute() {

  const vm = useVm(SuccessfulPaymentRouteVm);
  const { t } = useTranslation();
  const [shieldIconSize, setShieldSize] = useState(54);

  const isSmallToMediumScreen = useMediaQuery('(max-width:1024px)');
  const isLargeScreen = useMediaQuery('(min-width:1025px)');

  const reloadText = vm.sessionStore.isAuthenticated ? t('payment:button.reload') : t('payment:button.go_to_login');
  const timerText = vm.seconds > 0 ? `(${vm.seconds})` : '';

  const buttonText = useMemo(() => `${reloadText} ${timerText}`.trim(), [reloadText, timerText]);


  useEffect(() => {
    if (isSmallToMediumScreen) {
      setShieldSize(50);
    } else if (isLargeScreen) {
      setShieldSize(70);
    }
  }, [isLargeScreen, isSmallToMediumScreen]);

  useEffectOnce(() => {
    vm.startTimer();
    return () => {
      vm.resetTimer();
    };
  });

  return (
    <Background paddingTop={{ xs: 7 }}>

      <Grid container direction="column" height="100%">

        <Grid item container paddingLeft={{ xs: 2 }} >
          <IconAndTitle title="payment:success.header_title" subtitle="payment:success.header_subtitle" />
        </Grid>

        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center"
          paddingTop={{ xs: '3%', md: '10%' }}
        >
          <Grid
            item container
            xs={11} sm={9} md={8}
            position="relative"
            sx={{ background: colors.paper, borderRadius: '20px', boxShadow: '0px 3px 6px #00000029' }}
          >
            <Grid item container
              justifyContent="center"
              alignItems="center"
            >
              <Grid item sx={{ position: 'absolute', top: { xs: '-30px', md: '-40px' } }}>
                <img src={Assets.shield} width={shieldIconSize} />
              </Grid>
            </Grid>
            <Grid item container
              justifyContent="center"
              alignItems="center"
              marginY="43px"
            >
              <Grid item textAlign="center" paddingX={2}>
                <Typography lineHeight={1.2} fontSize={{ xs: 34, md: 48 }} fontWeight="bold" color="secondary">{t('payment:success.body_title')}</Typography>
                <Typography fontSize={{ xs: 22, md: 28 }} color="secondary">{t('payment:success.body_subtitle')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Grid item textAlign="center">
          <SubmitButton
            disabled={vm.isDisabled}
            variant="contained"
            sx={{
              backgroundColor: '#F59F20',
              borderRadius: '5px',
              color: colors.paper,
            }}
            onClick={vm.handleClick}
          >
            {buttonText}
          </SubmitButton>
        </Grid>

      </Grid>
    </Background>
  );
});
