import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { DistrictModel } from '../../../../../../../domain/model/DistrictModel';
import { PoiModel } from '../../../../../../../domain/model/PoiModel';
import { TaskModel } from '../../../../../../../domain/model/TaskModel';
import { UserModel } from '../../../../../../../domain/model/UserModel';
import { TaskProxy } from '../../../../../../../domain/proxy/TaskProxy';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../../domain/service/NotificationService';
import { transient } from '../../../../../../../inversify/decorator';
import { IAssigneeListItem, PoiHelper } from '../../../../../../../util/PoiHelper';

export interface ITaskProps {
  onClose: () => void;
  district: DistrictModel | null;
  task: TaskModel | null;
  tasks: TaskModel[];
  poi?: PoiModel | undefined;
  pois: PoiModel[];
  returnToTaskList: () => void;
}

@transient()
export class TaskVm extends ViewModel<ITaskProps> {

  @observable
  public isExpanded: boolean = false;

  constructor(
    @inject(I18nService) public readonly i18n: I18nService,
    @inject(PoiHelper) private readonly poiHelper: PoiHelper,
    @inject(TaskProxy) private readonly taskProxy: TaskProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get linkedPoi(): PoiModel | undefined {
    return this.props.pois.find((poi: PoiModel) => poi.id === this.props.task?.poiId);
  }

  @computed
  public get districtUsers(): UserModel[] {
    if (this.props.district && this.props.district.members.length > 0) {
      return this.props.district.members.map(({ user }) => user!);
    }

    return [];
  }

  @computed
  public get assigneesListItems(): IAssigneeListItem[] {
    return this.districtUsers.map(this.poiHelper.toAssigneeListItem).sort((user) => (user.isPro ? -1 : 1));
  }

  @action
  public setIsExpanded = (expanded: boolean) => {
    this.isExpanded = expanded;
  }

  @action
  public handleAssignees = (assignee: IAssigneeListItem) => {
    if (!this.props.task) return;

    /* DESELECT ASSIGNEE */
    if (this.props.task.assigneesIds.includes(assignee.id)) {
      const newAssigneeIds = this.props.task.assigneesIds.filter(assigneesIds => assigneesIds !== assignee.id);
      return this.props.task.setAssigneesIds(newAssigneeIds);
    }

    this.props.task.assigneesIds.push(assignee.id);
  };

  public handleSave = new AsyncTask(async () => {
    try {
      if (!this.props.task) return;

      const result = await (this.props.task.id
        ? this.taskProxy.updateTask(this.props.task.toPutDto())
        : this.taskProxy.createTask(this.props.task.toPostDto())
      );

      if (result.ok) {
        this.notification.success(this.i18n.t(this.props.task.id
          ? 'task:update.success'
          : 'task:creation.success'
        ));
        this.upsert(result.data);
        return this.props.returnToTaskList();
      }

      this.notification.error(this.i18n.t('task:error'));
    } catch (e) {
      console.error(`error while handling task. ${e}`);
      this.notification.error(this.i18n.t('task:error'));
    }
  }
  )

  @action
  public upsert = (task: TaskModel) => {
    const index = this.props.tasks.findIndex((s) => s.id === task.id);
    if (index === -1) {
      this.props.tasks.unshift(task);
    } else {
      this.props.tasks.splice(index, 1, task);
    }
  };

}
