import { computed, makeObservable, observable } from 'mobx';

import { createUUID } from '../../../__test__/uuid';
import { WeatherRealtimeResponseDto } from '../../../shared/dto/weather/weatherRealtime.response.dto';
import { MOON_AGE } from '../../../shared/enum/weather/moonAge.enum';
import { WEATHER_CODE } from '../../../shared/enum/weather/weatherCode.enum';
import { PrecipitationModel } from './PrecipitationModel';
import { TemperatureModel } from './TemperatureModel';
import { WeatherUnitValueModel } from './WeatherUnitValue';
import { WindModel } from './WindModel';
import { getWeatherIcon } from '../../../util/WeatherUtil';

export class WeatherRealtimeModel {

  @observable
  public id: string;

  @observable
  public date: string;

  @observable
  public temperature: TemperatureModel;

  @observable
  public feelsLikeTemperature: TemperatureModel;

  @observable
  public wind: WindModel;

  @observable
  public baroPressure: WeatherUnitValueModel;

  @observable
  public visibility: WeatherUnitValueModel;

  @observable
  public humidity: WeatherUnitValueModel;

  @observable
  public precipitation: PrecipitationModel;

  @observable
  public weatherCode: WEATHER_CODE;

  @observable
  public sunRise: string;

  @observable
  public sunSet: string;

  @observable
  public moonAge: MOON_AGE;

  @observable
  public cloudCover: WeatherUnitValueModel;

  constructor() {
    makeObservable(this);
  }

  @computed
  public get weatherIcon() {
    return getWeatherIcon(this.weatherCode);
  }

  public static fromDto = (dto: WeatherRealtimeResponseDto) => {
    const model = new WeatherRealtimeModel();

    model.id = createUUID();
    model.date = dto.date;
    model.temperature = TemperatureModel.fromDto(dto.temperature);
    model.feelsLikeTemperature = TemperatureModel.fromDto(dto.feelsLike);
    model.wind = WindModel.fromDto(dto.wind);
    model.baroPressure = WeatherUnitValueModel.fromDto(dto.baroPressure);
    model.visibility = WeatherUnitValueModel.fromDto(dto.visibility);
    model.humidity = WeatherUnitValueModel.fromDto(dto.humidity);
    model.precipitation = PrecipitationModel.fromDto(dto.precipitation);
    model.weatherCode = dto.weatherCode;
    model.sunRise = dto.sunRise;
    model.sunSet = dto.sunSet;
    model.moonAge = dto.moonAge;
    model.cloudCover = WeatherUnitValueModel.fromDto(dto.cloudCover);

    return model;
  }

  public toDto = () => {
    const dto = new WeatherRealtimeResponseDto();

    dto.date = this.date;
    dto.temperature = this.temperature.toDto();
    dto.feelsLike = this.feelsLikeTemperature.toDto();
    dto.wind = this.wind.toDto();
    dto.baroPressure = this.baroPressure.toDto();
    dto.visibility = this.visibility.toDto();
    dto.humidity = this.humidity.toDto();
    dto.precipitation = this.precipitation.toDto();
    dto.weatherCode = this.weatherCode;
    dto.sunRise = this.sunRise;
    dto.sunSet = this.sunSet;
    dto.moonAge = this.moonAge;
    dto.cloudCover = this.cloudCover.toDto();

    return dto;
  }
}
