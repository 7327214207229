import React, { BaseSyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Onboarding_01_Icon, Onboarding_02_Icon, Onboarding_03_Icon, Onboarding_04_Icon,
  Onboarding_05_Icon
} from '@HunterCompanion/hunter-design/icons';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';

import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import { AppStepper, IStepperSteps } from '../../../../../toolkit/components/AppStepper';
import { SvgWrapper } from '../../../../../toolkit/components/SvgWrapper';

interface IGroupSubStepperProps {
  closeModal: (e: BaseSyntheticEvent) => void;
  ownerPrice: string;
  memberPrice: string;
}

export const GroupSubStepper = function GroupSubStepper({ closeModal, ownerPrice, memberPrice }: IGroupSubStepperProps) {
  const { theme } = useThemeContext();
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = React.useState(0);

  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));

  const steps: IStepperSteps[] = useMemo(() => ([
    {
      icon: Onboarding_01_Icon,
      title: t('group_discount:stepper.screen_one.title'),
      description_1: t('group_discount:stepper.screen_one.description_1'),
    },
    {
      icon: Onboarding_05_Icon,
      title: t('group_discount:stepper.screen_two.title'),
      description_1: t('group_discount:stepper.screen_two.description_1'),
      description_2: t('group_discount:stepper.screen_two.description_2', { ownerPrice: ownerPrice, memberPrice: memberPrice }),
    },
    {
      icon: Onboarding_04_Icon,
      title: t('group_discount:stepper.screen_three.title'),
      description_1: t('group_discount:stepper.screen_three.description_1'),
      description_2: t('group_discount:stepper.screen_three.description_2'),
    },
    {
      icon: Onboarding_03_Icon,
      title: t('group_discount:stepper.screen_four.title'),
      description_1: t('group_discount:stepper.screen_four.description_1'),
      bulletPoints: [
        t('group_discount:stepper.screen_four.bullet_point_1'),
        t('group_discount:stepper.screen_four.bullet_point_2'),
      ],
    },
    {
      icon: Onboarding_02_Icon,
      title: t('group_discount:stepper.screen_five.title'),
      description_1: t('group_discount:stepper.screen_five.description_1'),
    }
  ]), [memberPrice, ownerPrice, t]);

  const renderBulletPoints = useMemo(() => {
    const bulletPoints = steps[activeStep].bulletPoints;
    if (bulletPoints && bulletPoints.length) {
      return (
        <Grid item textAlign={'justify'}>
          {bulletPoints.map(bp => (
            <ul key={bp} style={{ paddingInlineStart: 20, marginBlock: 10 }}>
              <li>
                <Typography variant='text_md_regular' color={theme.palette.text.secondary}>
                  {t(bp)}
                </Typography>
              </li>
            </ul>
          ))}
        </Grid>
      );
    }
  }, [activeStep, steps, t, theme.palette.text.secondary]);

  return (
    <AppStepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} closeModal={closeModal}>
      {/* STEPPER BODY */}
      <Grid item container direction={'column'} alignItems={'center'} justifyContent={'flex-start'} padding={'0px 16px 16px 16px'} rowGap={2}>
        {/* ICON */}
        <Grid item>
          <SvgWrapper icon={steps[activeStep].icon} fill={theme.palette.icons?.primary} width={isMobileSize ? 80 : 180} height={isMobileSize ? 80 : 180} />
        </Grid>
        {/* SUBTITLE */}
        <Grid item textAlign={'center'}>
          <Typography variant={isMobileSize ? 'headingSubtitleMobile' : 'headingSubtitleDesktop'} color={theme.palette.text.primary}>{t(steps[activeStep].title)}</Typography>
        </Grid>
        <Box width={{ xs: '100%', sm: '90%' }} textAlign={'center'}>
          {/* BODY DESCRIPTION 1 */}
          <Grid item>
            <Typography variant='text_md_regular' color={theme.palette.text.secondary}>
              {t(steps[activeStep].description_1)}
            </Typography>
          </Grid>
          {/* BODY DESCRIPTION 2 */}
          {steps[activeStep].description_2 && <Grid item>
            <Typography variant='text_md_regular' color={theme.palette.text.secondary}>
              {t(steps[activeStep].description_2!)}
            </Typography>
          </Grid>}
          {/* BULLET POINTS IF EXIST */}
          {renderBulletPoints}
        </Box>
      </Grid>
    </AppStepper>
  );
};
