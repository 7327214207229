import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';

import { ListToDoIcon } from '@HunterCompanion/hunter-design/icons';
import { Grid, Typography } from '@mui/material';

import { QuotaPlanModel } from '../../../../../../../../../domain/model/QuotaPlanModel';
import { QuotaPlanStatus } from '../../../../../../../../../shared/enum/quotaPlanStatus.enum';
import { useThemeContext } from '../../../../../../../../../theme/ThemeContextProvider';
import { SvgWrapper } from '../../../../../../../../../toolkit/components/SvgWrapper';
import { useTranslation } from 'react-i18next';

interface IQuotaPlanListItemProps {
  quotaPlan: QuotaPlanModel;
  iconColor: string | undefined;
  rightComponent?: React.ReactNode;
}

export const QuotaPlanListItem = observer(function QuotaPlanListItem(props: IQuotaPlanListItemProps) {

  const { theme } = useThemeContext();
  const { t } = useTranslation();
  const [isActivePlan] = useState(props.quotaPlan.status === QuotaPlanStatus.ACTIVE);
  const [isPastPlan] = useState(props.quotaPlan.status === QuotaPlanStatus.PAST);

  const fromDate = useMemo(() => format(props.quotaPlan.startDate!, 'dd.MM.yy'), [props.quotaPlan.startDate]);
  const toDate = useMemo(() => format(props.quotaPlan.endDate!, 'dd.MM.yy'), [props.quotaPlan.endDate]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item container alignItems={'center'} paddingRight={1} columnSpacing={1} sx={isActivePlan ? {
          backgroundColor: theme.palette.background.container,
          borderRadius: 2
        } : {}}>
          <Grid item>
            <SvgWrapper icon={ListToDoIcon} fill={props.iconColor} />
          </Grid>
          <Grid item container flex={1}>
            <Grid item xs={12}>
              {isActivePlan && <Typography variant='text_xs_medium' color={theme.palette.brand?.brand_color}>{t('quota_plan:active_plan')}</Typography>}
            </Grid>
            <Grid item xs={12}>
              <Typography variant='text_sm_semibold'
                color={isPastPlan ? theme.palette.text.secondary : theme.palette.text.primary}
              >{props.quotaPlan.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='text_sm_regular' color={theme.palette.text.secondary}>
                {t('quota_plan:plan_duration', { from: fromDate, to: toDate })}
              </Typography>
            </Grid>
          </Grid>
          {props.rightComponent && <Grid item>
            {props.rightComponent}
          </Grid>}
        </Grid>
      </Grid>
    </React.Fragment>
  );
});
