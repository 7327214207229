import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Assets } from '../../../../../../../assets';
import { MapPathModel } from '../../../../../../../domain/model/MapPathModel';
import { DistrictViewListItem } from '../DistrictViewListItem';

interface IProps {
  path: MapPathModel;
  lastItem: boolean;
  typeText: string;
  onEdit: (path: MapPathModel) => void;
  onDelete: (path: MapPathModel) => void;
}

export function DistrictPathListItem(props: IProps) {
  const { t } = useTranslation();

  const [alertOpen, setAlertOpen] = React.useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const showMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const edit = useCallback((event: React.MouseEvent) => {
    closeMenu(event);
    props.onEdit(props.path);
  }, [closeMenu, props]);

  const closeAlert = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const cancelDeletion = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setAlertOpen(false);
  }, []);

  const onDelete = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
    setAlertOpen(false);
    props.onDelete(props.path);
  }, [props]);

  const confirmDeletion = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
    setAlertOpen(true);
  }, [setAlertOpen]);

  return (
    <DistrictViewListItem
      key={`district-path-${props.path.id}`}
      component={<Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={'50px'}
        height={'50px'}
        borderRadius={'50%'}
        bgcolor={props.path.color ?? 'primary'}
      >
        <img style={{ height: '30px' }} src={Assets.path.icon} />
      </Box>}
      secondLine={props.path.title}
      thirdLine={props.typeText}
      withDivider={!props.lastItem}
      rightComponent={<>
        <Button
          onClick={showMenu}
          color="secondary"
        >
          {t('district:options_dropdown')}
        </Button>
        <Menu
          anchorEl={menuAnchorEl}
          open={menuOpen}
          onClose={closeMenu}
        >
          <MenuItem onClick={edit}>{t('district:options_dropdown_edit')}</MenuItem>
          <MenuItem onClick={confirmDeletion}>{t('district:options_dropdown_delete')}</MenuItem>
        </Menu>
        <Dialog
          open={alertOpen}
          onClose={closeAlert}
        >
          <DialogTitle>
            {t('paths:delete_path_alert_title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('paths:delete_path_alert_message')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDeletion}>{t('district:delete_district_cancel')}</Button>
            <Button onClick={onDelete} color="error">
              {t('district:delete_district_delete')}
            </Button>
          </DialogActions>
        </Dialog>
      </>}
    />
  );
}
