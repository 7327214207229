import { computed, makeObservable, observable } from 'mobx';
import { DistrictModel } from '../../../domain/model/DistrictModel';
import { EntryModel } from '../../../domain/model/EntryModel';
import { MemberModel } from '../../../domain/model/MemberModel';
import { AbstractDistrictFilter } from './AbstractDistrictFilter';
import { IEntryFilter } from './IEntryFilter';

export class UserFilterCondition {

  @observable
  public id: string = '';

  @observable
  public member: MemberModel | null = null;

  @observable
  public active: boolean = false;

  constructor() {
    makeObservable(this);
  }

  public static from(member: MemberModel): UserFilterCondition {
    const filter = new UserFilterCondition();
    filter.id = member.user?.id ?? '';
    filter.member = member;
    filter.active = true;

    return filter;
  }
}

export class UserFilter extends AbstractDistrictFilter implements IEntryFilter {

  constructor(
    private readonly district: DistrictModel | null,
    private readonly entries: EntryModel[] | null,
  ) {
    super((district && district.id) ? `DistrictFilterVm-UserFilter-district-${district!.id!}` : 'DistrictFilterVm-UserFilter', 1);
    makeObservable(this);
  }

  @computed
  public get conditions(): UserFilterCondition[] {
    if (!this.district || !this.entries) {
      return [];
    }

    // we are looking for all district members who did some entry
    const authors: { [uuid: string]: UserFilterCondition } = {};
    this.entries.forEach((e) => {
      if (e.owner) {
        const member = this.district!.members.find((m) => m.user?.id === e.owner!.id);

        if (member) { // If the owner of this entry is part of the district we can just attach him to the filter
          authors[e.owner.id] = UserFilterCondition.from(member);

        } else { // If the owner of this entry is not part of the district, we are still adding him to the filter
          const newMember = new MemberModel();
          newMember.user = e.owner;
          authors[e.owner.id] = UserFilterCondition.from(newMember);
        }
      }
    });

    const conditions = Object.values(authors);
    return this.loadCache(conditions);
  }

  public check = (entry: EntryModel): boolean => {
    const activeConditions = this.conditions.filter((f) => f.active);

    // there is no active condition
    if (!activeConditions.length) {
      return false;
    }

    if (!entry.owner) {
      return false;
    }

    return activeConditions.some((c) => entry.owner!.id === c.member?.user?.id);
  }
}
