/*
<!-- START Topple Goal Tag | MyHunt PRO Conversion Pixel -->
  <script
    type="application/javascript"
    src="https://delivery.gettopple.com/tag_gen.js"
    data-goal="fc528592c3858f90196fbfacc814f235"
  ></script>
<!-- END Topple Goal Tag | MyHunt PRO Conversion Pixel -->
*/

// * Function to append Topple Goal Tag script for MyHunt PRO Conversion Pixel
export const appendToppleGoalTagForPROConversionPixel = () => {
  const script = document.createElement('script');
  script.type = 'application/javascript';
  script.src = 'https://delivery.gettopple.com/tag_gen.js';
  script.setAttribute('data-goal', 'fc528592c3858f90196fbfacc814f235');
  document.head.appendChild(script);
};

// * Function to append Topple Goal Tag script for MyHunt Register Conversion Pixel
export const appendToppleGoalTagRegisterConversionPixel = () => {
  const script = document.createElement('script');
  script.type = 'application/javascript';
  script.src = 'https://delivery.gettopple.com/tag_gen.js';
  script.setAttribute('data-goal', '6ba3af5d7b2790e73f0de32e5c8c1798');
  document.head.appendChild(script);
};

/*
<!-- START Topple Goal Tag | MyHunt Register Conversion Pixel -->
  <script
    type="application/javascript"
    src="https://delivery.gettopple.com/tag_gen.js"
    data-goal="6ba3af5d7b2790e73f0de32e5c8c1798"
  ></script>
<!-- END Topple Goal Tag | MyHunt Register Conversion Pixel -->
*/
