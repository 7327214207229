import { computed, makeObservable, observable } from 'mobx';

import { createUUID } from '../../../__test__/uuid';
import { WeatherValueUnitItemResponseDto } from '../../../shared/dto/weather/weatherValueUnitItem.response.dto';

export class WeatherUnitValueModel {

  @observable
  public id: string;

  @observable
  public value: number | null;

  @observable
  public units: string;

  constructor() {
    makeObservable(this);
  }

  @computed
  public get roundedValue() {
    return Math.round(this.value ?? 0);
  }

  @computed
  public get unitFormatted() {
    if (this.units === 'C') {
      return '°';
    }

    return this.units;
  }

  @computed
  public get localizedValue(): string {
    return `${this.roundedValue} ${this.unitFormatted}`;
  }

  public static fromDto = (dto: WeatherValueUnitItemResponseDto) => {
    const model = new WeatherUnitValueModel();

    model.id = createUUID();
    model.value = dto.value;
    model.units = dto.units;

    return model;
  }

  public toDto = () => {
    return new WeatherValueUnitItemResponseDto(this.value, this.units);
  }
}
