import { createContext, useContext } from 'react';

import { Theme, createTheme } from '@mui/material';

import { useColorTheme } from './use-color-theme';

type ThemeContextType = {
  mode: string;
  toggleColorMode: () => void;
  theme: Theme;
};

type ThemeContextProviderProps = {
  children: React.ReactNode;
}

export const ThemeContext = createContext<ThemeContextType>({
  mode: 'light',
  toggleColorMode: () => { return; },
  theme: createTheme(),
});

export const ThemeContextProvider = ({ children }: ThemeContextProviderProps) => {
  const value = useColorTheme();

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
};
