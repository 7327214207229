import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';

import { useContainer } from '../../../../../domain/hooks/useContainer';
import { SubzoneModel } from '../../../../../domain/model/SubzoneModel';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import { Marker } from '../../Marker';

interface IProps {
  subzone: SubzoneModel;
  canEditAndDeleteSubzone: boolean;
}

export const ViewSubzoneInfo = observer(function ViewSubzoneInfo(props: IProps) {
  const { t } = useTranslation();
  const notification = useContainer().get(NotificationService);

  const handleOnChange = useCallback((e) => {
    if (!props.canEditAndDeleteSubzone) {
      return notification.info(t('subzone:wrong_permission'));
    }
    props.subzone.setNotes(e.target.value);
  }, [notification, props.canEditAndDeleteSubzone, props.subzone, t]);

  return (
    <Box display="flex" flexDirection={'column'} width="100%">
      <Box width="100%" paddingY={2} paddingX={2}>
        <textarea
          placeholder={t('subzone:notes')}
          rows={5}
          style={{
            width: '100%',
            borderStyle: 'none',
            outline: 'none',
            fontSize: '15px',
            fontWeight: '600',
            color: '#545B68',
          }}
          value={props.subzone.notes}
          onChange={handleOnChange}
        />
      </Box>
      <Divider />
      <Box display="flex" alignSelf={'center'} mt={4}>
        <Marker
          icon={props.subzone.icon}
          lat={0}
          lng={0}
          width={70}
          skiptransform={true}
        />
      </Box>
    </Box>
  );
});
