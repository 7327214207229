import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { SxProps, Theme } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';

import { useVm } from '../../../../domain/hooks/useVm';
import { colors } from '../../../../theme/options/palette/const/colors';
import { SubmitButton } from '../../../../toolkit/components/SubmitButton';
import { GoogleIcon } from '../../../../toolkit/icons/GoogleIcon';
import { GoogleLoginButtonVm } from './GoogleLoginButtonVm';

export interface IGoogleLoginButtonProps {
  sx?: SxProps<Theme>;
  fromLocation?: string;
}

export const GoogleLoginButton = observer(function GoogleLoginButton(props: IGoogleLoginButtonProps) {

  const { t } = useTranslation();
  const vm = useVm(GoogleLoginButtonVm, props);

  const signIn = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: vm.googleLogin.run,
    onError: vm.handleGoogleLoginError,
  });

  return (
    <SubmitButton
      size="large"
      submitting={vm.googleLogin.isBusy}
      startIcon={<GoogleIcon />}
      sx={{
        backgroundColor: colors.google_btn.basic,
        '&:hover': {
          backgroundColor: colors.google_btn.hover,
        },
        ...props.sx,
      }}
      onClick={() => signIn()}
    >
      {t('auth:socialmedia_button.google')}
    </SubmitButton>
  );
});
