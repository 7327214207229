import { inject } from 'inversify';
import { action } from 'mobx';

import { I18nService } from '../../domain/service/I18nService';
import { SessionStore } from '../../domain/store/SessionStore';
import { singleton } from '../../inversify/decorator';
import {
  OAuthType
} from './register/components/social-media-registration/SocialMediaRegistrationRoute';

@singleton()
export class AuthService {

  constructor(
    @inject(SessionStore) private readonly sessionStore: SessionStore,
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
  }

  /*
   * Backend is checking if current user.token exist in DB and based on that auths user.
   * We're sending empty string for email, instead of a user.mail value because we don't want to register user automatically without
   * data privacy accepting and we take care of that in RegistrationVm - method oAuthRegistration
   * AuthProxy social login methods, if email is present, will try to register user with that mail, if not existing.
   */
  @action
  public startLoginRequest = (type: OAuthType, accessToken: string = 'token', code: string = '') => {
    switch (type) {
      case 'facebook':
        return this.sessionStore.loginViaFacebook({ email: '', access_token: accessToken, language: this.i18n.language });
      case 'apple':
        return this.sessionStore.loginViaApple({ firstName: '', lastName: '', email: '', access_token: accessToken, language: this.i18n.language });
      case 'google':
        return this.sessionStore.loginViaGoogle({ email: '', access_token: accessToken, code: code, language: this.i18n.language });
      default:
        return null;
    }
  }

}
