import { useTranslation } from 'react-i18next';

import { Typography, useMediaQuery } from '@mui/material';

import {
  GroupSubscriptionUserModel
} from '../../../../../../domain/model/GroupSubscriptionUserModel';
import { useThemeContext } from '../../../../../../theme/ThemeContextProvider';

interface ISubscriptionStatusInfoColumnProps {
  user: GroupSubscriptionUserModel;
}

export const SubscriptionStatusInfoColumn = function SubscriptionStatusInfoColumn({ user }: ISubscriptionStatusInfoColumnProps) {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();
  const { subscription } = user;

  return (
    subscription === null
      ? <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>{t('manage_group:add_members.table.status.free')}</Typography>
      : <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>{t('manage_group:add_members.table.status.pro')}</Typography>
  );
};
