import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useContainer } from '../../../../../domain/hooks/useContainer';
import { StripeService } from '../../../../../domain/service/StripeService';
import { AppRoutes } from '../../../../../router/AppRoutesEnum';
import { oldDesignTheme } from '../../../../../theme/oldDesignTheme';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { SubmitButton } from '../../../../../toolkit/components/SubmitButton';
import { PaymentFeaturesList } from '../../../../../toolkit/constants/PaymentFeaturesList';
import { UserProfileMapWrapper } from '../UserProfileMapWrapper';

const useStyles = makeStyles({
  root: {
    '&.MuiTypography-h3': {
      color: oldDesignTheme.palette.text.tertiary,
      fontSize: 18,
      fontWeight: 'bold',
      textAlign: 'center',
      maxWidth: 200,
    },
  },
});

interface UserProfileFreeProps {
  enableManageStripeSubscription: boolean;
}

export const UserProfileFreeUserRoute = observer(function UserProfileFreeUserRoute({ enableManageStripeSubscription }: UserProfileFreeProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createPortalSession } = useContainer().get(StripeService);
  const classes = useStyles();
  const features = useMemo(() => PaymentFeaturesList, []);

  return (
    <UserProfileMapWrapper>
      <Grid container marginTop="30px" justifyContent="center" spacing={2} width={'100%'}>
        <Grid item container direction="column" justifyContent="center" alignItems="center" xs={6}>
          <Grid item><img src={features[0].icon} /></Grid>
          <Grid item>
            <Typography variant="h3" className={classes.root}>{t(features[0].title)}</Typography>
          </Grid>
        </Grid>

        <Grid item container direction="column" justifyContent="center" alignItems="center" xs={6}>
          <Grid item><img src={features[1].icon} /></Grid>
          <Grid item>
            <Typography variant="h3" className={classes.root}>{t(features[1].title)}</Typography>
          </Grid>
        </Grid>

        <Grid item container direction="column" justifyContent="center" alignItems="center">
          <Grid item><img src={features[2].icon} /></Grid>
          <Grid item>
            <Typography variant="h3" className={classes.root}>{t(features[2].title)}</Typography>
          </Grid>
        </Grid>

        {enableManageStripeSubscription
          && <Grid item zIndex={2} mt={2}>
            <SubmitButton
              variant="contained"
              onClick={createPortalSession.run}
              sx={{
                backgroundColor: colors.secondary,
                '&:hover': {
                  backgroundColor: colors.green[900],
                  borderColor: colors.orange[400]
                },
                borderRadius: 100,
                color: colors.paper,
                fontSize: 15,
                minWidth: 200,
                height: 50,
                boxShadow: '3px 6px 6px #0000003D',
                border: 1,
                borderColor: colors.secondary,
              }}
            >{t('profile:button.manage_subscription')}</SubmitButton>
          </Grid>}
      </Grid>

      <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
        height="100%"
        bottom={-47}
      >
        <SubmitButton
          variant="contained"
          onClick={() => navigate(AppRoutes.Pricing)}
          sx={{
            backgroundColor: colors.orange[400],
            '&:hover': {
              backgroundColor: colors.orange[600],
            },
            borderRadius: 100,
            color: colors.paper,
            fontSize: 24,
            px: 5,
            minWidth: { xs: 300, md: 350, xl: 450 },
            height: 50,
            boxShadow: '3px 6px 6px #0000003D',
            border: 1,
            borderColor: colors.orange[400],
          }}
        >{t('profile:button.pro_version')}</SubmitButton>
      </Box>
    </UserProfileMapWrapper>
  );
});
