import { Checkbox, ListItemButton, ListItemText } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { DateFilterCondition } from '../../../../filters/DateFilter';

interface IProps {
  condition: DateFilterCondition;
  totalEntities: number;
  onClick: () => void;
}

export const FixedTimeFilter = observer(function FixedTimeFilter(props: IProps) {
  const { t } = useTranslation();

  return (
    <ListItemButton sx={{ paddingY: .5 }} disableGutters onClick={props.onClick} divider>
      <ListItemText
        primary={props.condition.text}
        primaryTypographyProps={{ variant: 'h6' }}
        secondary={t('filter:entries_matched', {
          hits: props.condition.hits,
          totalEntries: props.totalEntities,
        })}
        secondaryTypographyProps={{ variant: 'body2' }}
      />
      <Checkbox checked={props.condition.active} />
    </ListItemButton>
  );
});
