import { ApiProperty } from '@nestjs/swagger';
import { IsNotEmpty, IsOptional, IsString, IsUUID } from 'class-validator';

import { SYNC_STATE } from '../enum/syncState.enum';
import { IGeoLocation } from '../interfaces/IGeoLocation';

export class DistrictPutRequestDto {

  @IsUUID('4', { context: { errorCode: 422 } })
  @ApiProperty()
  public id: string;

  @IsString()
  @IsOptional()
  @IsNotEmpty()
  @ApiProperty({ required: false })
  public name?: string;

  @IsOptional()
  @ApiProperty({ required: false })
  public mesh?: any;

  @IsOptional()
  @ApiProperty()
  public geoMesh?: IGeoLocation[];

  @IsOptional()
  @ApiProperty({ required: false })
  public state?: SYNC_STATE;
}
