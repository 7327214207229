import { inject } from 'inversify';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { AsyncTask } from '../../domain/async/AsyncTask';
import { AreaPostRequestModel } from '../../domain/model/AreaPostRequestModel';
import { DistrictModel } from '../../domain/model/DistrictModel';
import { EntryModel } from '../../domain/model/EntryModel';
import { WeightsEntryModel } from '../../domain/model/WeightsEntryModel';
import { EntryProxy } from '../../domain/proxy/EntryProxy';
import { I18nService } from '../../domain/service/I18nService';
import { NotificationService } from '../../domain/service/NotificationService';
import { TrackingEvent } from '../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../domain/store/SessionStore';
import { transient } from '../../inversify/decorator';
import { WORLD_MAP_ID } from '../../shared/deep-linking/DeepLink.constants';
import { ENTRY_TYPE } from '../../shared/enum/entryType.enum';
import { REGION } from '../../shared/enum/region.enum';
import { IGeoLocation } from '../../shared/interfaces/IGeoLocation';
import { EntryTabTypeEnum } from './components/entry/view-entry/ViewEntryVm';
import { IMapBounds } from './components/google-map/GoogleMapVm';

@transient()
export class MapEntryVm {

  // * for displaying on a map in a current bounds
  @observable
  private entriesInMapBounds: EntryModel[] = [];

  // * for district view sidebar
  @observable
  public selectedDistrictEntries: EntryModel[] = [];

  @observable
  public currentEntry: EntryModel | null = null;

  @observable
  public lastEntryTab: EntryTabTypeEnum | null = null;

  @observable
  public secondMapClick: boolean = false;

  constructor(
    @inject(EntryProxy) private readonly entryProxy: EntryProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(TrackingService) private readonly tracking: TrackingService,
  ) {
    makeObservable(this);
  }

  @computed
  public get entries() {
    /** This pertains to an entry in creation. Mobx recognizes changes in an object's property only when accessed directly.
     * It cannot detect changes in 'currentEntry' -> 'location' if not accessed via 'currentEntry.location'.
    */
    if (this.currentEntry && this.currentEntry.location) {
      return this.entriesInMapBounds
        .filter((e) => e.id !== this.currentEntry?.id)
        .concat(this.currentEntry);
    }

    return this.entriesInMapBounds;
  }

  @action
  public setSecondMapClick = (click: boolean) => {
    this.secondMapClick = click;
  }

  @action
  private setEntriesInMapBounds = (entries: EntryModel[]) => {
    this.entriesInMapBounds = entries;
  }

  @action
  private setSelectedDistrictEntries = (entries: EntryModel[]) => {
    // * remove duplicates based on the 'id' property
    this.selectedDistrictEntries = Array.from(new Map(entries.map(entry => [entry.id, entry])).values());
  }

  @action
  public startNewEntry = (district: DistrictModel | null, type: ENTRY_TYPE) => {
    if (!this.session.session) {
      return;
    }

    this.currentEntry = new EntryModel();
    this.currentEntry.id = '';
    this.currentEntry.entryType = type;
    this.currentEntry.owner = this.session.session.user.clone();
    this.currentEntry.districtId = district?.realId;
    this.currentEntry.userDate = new Date();
    this.currentEntry.weights = [WeightsEntryModel.fromDto({ unit: this.session.currentWeightUnit, amount: null, })];
    this.currentEntry.region = REGION.dach;

    this.startEntryCreationTracking(type);
  }

  @action
  public startEntryEdit = (entry: EntryModel) => {
    this.currentEntry = entry.clone();
  }

  @action
  public setEntry = (entry: EntryModel | null) => {
    this.currentEntry = entry;
  }

  // * Handle entires both for district view and for map viewport
  @action
  public upsert = (entry: EntryModel) => {
    this.updateArrayEntry(this.entriesInMapBounds, entry);
    this.updateArrayEntry(this.selectedDistrictEntries, entry);
  }

  @action
  public deleteEntryId = (id: string) => {
    this.entriesInMapBounds = this.entriesInMapBounds.filter((e) => e.id !== id);
    this.selectedDistrictEntries = this.selectedDistrictEntries.filter((e) => e.id !== id);
  }

  @action
  public saveTabChange = (tab: EntryTabTypeEnum) => {
    this.lastEntryTab = tab;
  }

  @action
  public mapClick = (location: IGeoLocation, selectedDistrict: DistrictModel | null) => {
    if (!this.currentEntry) {
      return;
    }

    if (this.lastEntryTab !== EntryTabTypeEnum.POSITION) {
      return;
    }

    //* Allow position changes only on the initial map click or when at the Position Tab.
    if (!this.secondMapClick || this.lastEntryTab === EntryTabTypeEnum.POSITION) {
      this.currentEntry.setDistrictId(selectedDistrict?.realId);
      this.currentEntry.setPosition(location, 'map');
    }
    this.setSecondMapClick(true);
  }

  private updateArrayEntry = (array: EntryModel[], entry: EntryModel) => {
    const index = array.findIndex((el: EntryModel) => el.id === entry.id);

    if (index === -1) {
      array.unshift(entry);
    } else {
      array.splice(index, 1, entry);
    }
  }

  // * Fetch entries within the specified map bounds, disregarding district boundaries.
  public getEntriesInMapBounds = new AsyncTask(async (mapBounds: IMapBounds) => {
    try {
      this.setEntriesInMapBounds([]);

      if (!this.session.isProUser) {
        return;
      }

      const dto = new AreaPostRequestModel().toDto(mapBounds);
      const result = await this.entryProxy.getEntriesInMapBounds(dto);

      if (result.ok) {
        return this.setEntriesInMapBounds(result.data);
      }

      this.notification.warning(this.i18n.t('map:entries_loading_error'));
    } catch (e) {
      console.error(e);
      this.notification.error(this.i18n.t('map:entries_loading_error'));
    }
  })

  // * Call this only on a Private Map. It fetches all the entries for which you are the owner.
  public getAllEntries = new AsyncTask(async (district: DistrictModel, allDistricts: DistrictModel[]) => {
    if (!district.isWorldMap) {
      return;
    }

    try {
      this.setSelectedDistrictEntries([]);

      if (!this.session.isProUser) {
        return;
      }

      if (district.isUnsaved) {
        return;
      }

      const result = await this.entryProxy.getAllEntries();

      if (result.ok) {
        const privateMapEntries = result.data.filter(e => e.districtId === WORLD_MAP_ID);
        /**
         * Retrieves HARVEST entries created by a district member who left the district.
         * These entries are displayed on their Private Map but are not editable or deletable.
         * If they rejoin the district, the behavior remains consistent with their never having left.
        */
        const additionalEntries = result.data
          .filter(entry =>
            entry.owner?.id === this.session.currentUser?.id
            && entry.entryType === ENTRY_TYPE.KILLING
            && !allDistricts.some(district => district.id === entry.districtId)
          );

        return this.setSelectedDistrictEntries([...privateMapEntries, ...additionalEntries]);
      }

      this.notification.warning(this.i18n.t('map:entries_loading_error'));
    } catch (e) {
      console.error(e);
      this.notification.error(this.i18n.t('map:entries_loading_error'));
    }
  })

  // * per specific district
  public getPublicEntries = new AsyncTask(async (district: DistrictModel) => {
    try {
      this.setSelectedDistrictEntries([]);

      if (!this.session.isProUser) {
        return;
      }

      if (district.isUnsaved) {
        return;
      }

      const result = await this.entryProxy.getPublicEntries.run(district.id);

      if (result.ok) {
        return this.setSelectedDistrictEntries(this.selectedDistrictEntries.concat(result.data));
      }

      this.notification.warning(this.i18n.t('map:entries_loading_error'));
    } catch (e) {
      console.error(e);
      this.notification.error(this.i18n.t('map:entries_loading_error'));
    }
  })

  // * per specific district
  public getPrivateEntries = new AsyncTask(async (district: DistrictModel) => {
    try {
      if (!this.session.isProUser) {
        return;
      }

      if (district.isUnsaved) {
        return;
      }

      if (district.isWorldMap) {
        return;
      }

      const result = await this.entryProxy.getPrivateEntries.run(district.id);
      if (result.ok) {
        return this.setSelectedDistrictEntries(this.selectedDistrictEntries.concat(result.data));
      }

      this.notification.warning(this.i18n.t('map:entries_loading_error'));
    } catch (e) {
      console.error(e);
      this.notification.error(this.i18n.t('map:entries_loading_error'));
    }
  })

  public save = async (): Promise<boolean> => {
    try {
      if (!this.currentEntry) {
        return false;
      }

      const result = this.currentEntry.id
        ? await this.entryProxy.updateEntry(this.currentEntry.toPutDto(this.session.currentWeightUnit))
        : await this.entryProxy.createEntry(this.currentEntry.toPostDto(this.session.currentWeightUnit));

      if (result.ok) {
        this.notification.success(this.i18n.t('entry:view.notification.update_success'));
        this.completeEntryTracking(this.currentEntry.entryType!);
        this.upsert(result.data);
        runInAction(() => {
          this.currentEntry = null;
        });

        return true;
      }

      this.notification.error(this.i18n.t('entry:view.notification.update_error'));
      return false;
    } catch (e) {
      console.error(`error while updating user. ${e}`);
      this.notification.error(this.i18n.t('entry:view.notification.update_error'));
      return false;
    }
  }

  public deleteEntry = new AsyncTask(async (entry: EntryModel): Promise<void> => {
    try {
      const response = await this.entryProxy.deleteEntry(entry);

      if (response.ok && response.status === 204) {
        this.deleteEntryId(entry.id);
        return this.notification.success(this.i18n.t('entry:delete.success'));
      }
      this.notification.error(this.i18n.t('entry:delete.error'));

    } catch (e) {
      console.error(`error while handling entry delete. ${e}`);
      this.notification.error(this.i18n.t('entry:delete.error'));
    }
  });

  private startEntryCreationTracking = async (type: ENTRY_TYPE) => {
    switch (type) {
      case ENTRY_TYPE.MISC:
        await this.tracking.track(TrackingEvent.MISC_CREATION_STARTED);
        break;
      case ENTRY_TYPE.SIGHTING:
        await this.tracking.track(TrackingEvent.SIGHTING_CREATION_STARTED);
        break;
      default:
        await this.tracking.track(TrackingEvent.HARVEST_CREATION_STARTED);
        break;
    }
  }

  private completeEntryTracking = async (type: ENTRY_TYPE) => {
    switch (type) {
      case ENTRY_TYPE.MISC:
        await this.tracking.track(this.currentEntry?.id ? TrackingEvent.MISC_UPDATE_COMPLETED : TrackingEvent.MISC_CREATION_COMPLETED);
        break;
      case ENTRY_TYPE.SIGHTING:
        await this.tracking.track(this.currentEntry?.id ? TrackingEvent.SIGHTING_UPDATE_COMPLETED : TrackingEvent.SIGHTING_CREATION_COMPLETED);
        break;
      default:
        await this.tracking.track(this.currentEntry?.id ? TrackingEvent.HARVEST_UPDATE_COMPLETED : TrackingEvent.HARVEST_CREATION_COMPLETED);
        break;
    }
  }

}
