export enum LANGUAGE {
  en = 'en',
  de = 'de',
  it = 'it',
  fr = 'fr',
  es = 'es',
  bg = 'bg'
}

const LANGUAGE_KEYS = Object.keys(LANGUAGE);
const LANGUAGE_VALUES = LANGUAGE_KEYS.map((key) => (LANGUAGE as { [key: string]: string })[key]);

export function isValidLanguage(language?: LANGUAGE | string): boolean {
  return LANGUAGE_VALUES.find((i) => i === language) != null;
}

export function defaultLanguage(): LANGUAGE {
  return LANGUAGE.en;
}
