import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { DistrictModel } from '../../../domain/model/DistrictModel';
import { EntryModel } from '../../../domain/model/EntryModel';
import { MapPathModel } from '../../../domain/model/MapPathModel';
import { MapPathTypeModel } from '../../../domain/model/MapPathType';
import { PoiModel } from '../../../domain/model/PoiModel';
import { SubzoneModel } from '../../../domain/model/SubzoneModel';
import { I18nService } from '../../../domain/service/I18nService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { ENTRY_TYPE } from '../../../shared/enum';
import { POI_TYPE } from '../../../shared/enum/poiType.enum';
import { IDistrictFilterCondition } from './AbstractDistrictFilter';
import { AnimalTypeFilter } from './AnimalTypeFilter';
import { DateFilter, DateFilterCondition } from './DateFilter';
import { DistrictsSelectorFilter } from './DistrictsSelectorFilter';
import { L1ClassificationFilter } from './L1ClassificationFilter';
import { MapPathsFilter } from './MapPathsFilter';
import { PoiFilter } from './PoiFilter';
import { SubzoneFilter } from './SubzoneFilter';
import { UserFilter } from './UserFilter';

export class DistrictFilterVm {

  @observable
  public dateFilter = new DateFilter(this.district, this.allMapBoundsEntries, this.i18n);

  @observable
  public poiFilter = new PoiFilter(this.district, this.allMapBoundsPois);

  @observable
  public l1ClassificationFilter = new L1ClassificationFilter(this.district);

  @observable
  public animalTypeFilter = new AnimalTypeFilter(this.district, this.allMapBoundsEntries);

  @observable
  public userFilter = new UserFilter(this.district, this.allMapBoundsEntries);

  @observable
  public mapPathFilter = new MapPathsFilter(this.district, this.allMapBoundsPaths, this.allMapBoundsPathTypes);

  @observable
  public subzoneFilter = new SubzoneFilter(this.district, this.allMapBoundsSubzones);

  @observable
  public districtsSelectorFilter = new DistrictsSelectorFilter(this.allDistricts);

  constructor(
    private readonly session: SessionStore,
    private readonly i18n: I18nService,
    public readonly district: DistrictModel | null,
    public readonly allDistricts: DistrictModel[],
    public readonly allMapBoundsEntries: EntryModel[],
    public readonly allMapBoundsPois: PoiModel[],
    public readonly allMapBoundsPaths: MapPathModel[],
    public readonly allMapBoundsPathTypes: MapPathTypeModel[],
    public readonly allMapBoundsSubzones: SubzoneModel[],
    public readonly currentEntry: EntryModel | null,
    public readonly currentPoi: PoiModel | null,
    public readonly currentMapPath: MapPathModel | null,
    public readonly currentSubzone: SubzoneModel | null,
  ) {
    makeObservable(this);
  }

  @computed
  private get entryFilters() {
    // if we are in a user made district
    if (!this.district?.isGeneric) {
      return [
        this.l1ClassificationFilter,
        this.animalTypeFilter,
        this.userFilter,
      ];
    }

    // if we are in the world map
    return [
      this.l1ClassificationFilter,
    ];
  }

  @computed
  private get poiFilters() {
    return [this.poiFilter];
  }

  @computed
  private get districtsSelectorFilters() {
    return [this.districtsSelectorFilter];
  }

  @computed
  public get hasActiveDateFilter() {
    return this.dateFilter.hasActive;
  }

  @computed
  public get hasActiveGeneralFilter() {
    return this.dateFilter.hasActive || this.l1ClassificationFilter.hasActive;
  }

  @computed
  public get hasActivePoiFilter() {
    return this.poiFilter.allHidden || this.poiFilters.some((f) => f.hasActive);
  }

  @computed
  public get hasActiveAnimalTypeFilter() {
    return this.animalTypeFilter.hasActive;
  }

  @computed
  public get hasActiveUserFilter() {
    return this.userFilter.hasActive;
  }

  @computed
  public get hasActiveMapPathsFilter() {
    return this.mapPathFilter.hasActive;
  }

  @computed
  public get hasActiveSubzoneFilter() {
    return this.subzoneFilter.hasActive;
  }

  @computed
  public get hasActiveFilter() {
    return [
      this.hasActiveGeneralFilter,
      this.hasActivePoiFilter,
      this.hasActiveAnimalTypeFilter,
      this.hasActiveUserFilter,
      this.hasActiveMapPathsFilter,
      this.hasActiveSubzoneFilter,
    ].includes(true);
  }

  @action
  public deactivateAll = () => {
    this.dateFilter.deactivate();
    this.poiFilter.deactivate();
    this.l1ClassificationFilter.deactivate();
    this.animalTypeFilter.deactivate();
    this.userFilter.deactivate();
    this.mapPathFilter.deactivate();
    this.subzoneFilter.deactivate();
  }

  @action
  public deactivatePoiFilters = () => {
    this.poiFilters.forEach((f) => f.deactivate());
  }

  @action
  public deactivateEntryFilters = () => {
    this.dateFilter.deactivate();
    this.entryFilters.forEach((f) => f.deactivate());
  }

  public getEntriesCountDescription = (hits: number, totalItems: number) => {

    let hitsEntry = this.i18n.t('districtfilter:entry_number_plural');
    let totalEntry = this.i18n.t('districtfilter:entry_number_plural_reference');

    if (hits === 1) {
      hitsEntry = this.i18n.t('districtfilter:entry_number_singular');
    }

    if (totalItems === 1) {
      totalEntry = this.i18n.t('districtfilter:entry_number_singular');
    }

    return `${hits} ${hitsEntry} ${this.i18n.t('districtfilter:entry_number_from')} ${totalItems} ${totalEntry}`;
  }

  @computed
  public get districtsToRender(): DistrictModel[] {
    return this.allDistricts.filter((district) => this.districtsSelectorFilters.every((d) => d.check(district.id)));
  }

  @computed
  public get subzones(): SubzoneModel[] {
    if (!this.district) {
      return [];
    }

    /**
     * If user is creating a new Subzone with same subzoneType that already exists but it's not displayed on a map because it is not selected in a filter,
     * then set this Subzone's type condition.active to true so user can see them on a map.
    */
    const alreadyExistsInSubzonesFilterConditions = this.subzoneFilter.conditions.find(c => c.type === this.currentSubzone?.subzoneType);

    if (alreadyExistsInSubzonesFilterConditions) {
      this.subzoneFilter.setConditionTo(alreadyExistsInSubzonesFilterConditions, true);
    }

    return this.allMapBoundsSubzones
      .filter(s => this.districtsSelectorFilters.every(f => f.check(s.districtId!)))
      .filter((p) => this.subzoneFilter.check(p));
  }

  @computed
  public get mapPaths(): MapPathModel[] {
    if (!this.district) {
      return [];
    }

    /**
     * If user is creating a new Path with same PathType that already exists but it's not displayed on a map because it is not selected in a filter,
     * then set this Path's type condition.active to true so user can see them on a map.
    */
    const alreadyExistsInMapPathsFilterConditions = this.mapPathFilter.conditions.find(c => c.id === this.currentMapPath?.typeId);

    if (alreadyExistsInMapPathsFilterConditions) {
      this.mapPathFilter.setConditionTo(alreadyExistsInMapPathsFilterConditions, true);
    }

    return this.allMapBoundsPaths
      .filter(mapPath => this.districtsSelectorFilters.every(f => f.check(mapPath.districtId!)))
      .filter((p) => this.mapPathFilter.check(p));
  }

  @computed
  public get pois(): PoiModel[] {
    if (!this.district) {
      return [];
    }

    //* Necessary because when creating new POI, and filter doesn't have included POIs display, forcefully make POI in creation visible.
    if (this.poiFilter.allHidden && this.currentPoi) {
      runInAction(() => {
        this.poiFilter.allHidden = false;
      });
    }

    /**
     * If user is creating a new POI with same PoiType that already exists but it's not displayed on a map because it is not selected in a filter,
     * then set this POI's type condition.active to true so user can see them on a map.
    */
    const alreadyExistsInPoiFilterConditions = this.poiFilter.conditions.find(c => this.currentPoi?.type === POI_TYPE.CUSTOM
      ? c.customMark?.foreground === this.currentPoi?.customMark?.foreground
      : c.type === this.currentPoi?.type
    );

    if (alreadyExistsInPoiFilterConditions) {
      this.poiFilter.setConditionTo(alreadyExistsInPoiFilterConditions, true);
    }

    //* find all pois which satisfies all currently set poi filters
    return this.allMapBoundsPois
      .filter(p => this.districtsSelectorFilters.every(f => f.check(p.districtId!)))
      .filter((p) => this.poiFilters.every((f) => f.check(p)));
  }

  @computed
  public get entries(): EntryModel[] {
    const classificationId = this.animalTypeFilter.classificationId(this.currentEntry?.classificationObject) ?? this.animalTypeFilter.createNoClassCondition();
    const alreadyExistsInAnimalTypeFilterConditions = this.animalTypeFilter.conditions.find(c => c.id === classificationId);

    if (alreadyExistsInAnimalTypeFilterConditions) {
      this.animalTypeFilter.setConditionTo(alreadyExistsInAnimalTypeFilterConditions, true);
    }

    const alreadyExistsClassificationFilterConditions = this.l1ClassificationFilter.conditions.find(c => c.type === this.currentEntry?.entryType);

    if (alreadyExistsClassificationFilterConditions) {
      this.l1ClassificationFilter.setConditionTo(alreadyExistsClassificationFilterConditions, true);
    }

    /** Retrieves all entries that satisfy the currently set district filters. */
    const entriesToRender = this.allMapBoundsEntries.filter(e => this.districtsSelectorFilters.every(f => f.check(e.districtId!)));

    /**
     * Retrieves HARVEST entries created by a district member who left the district.
     * These entries are displayed on their Private Map but are not editable or deletable.
     * If they rejoin the district, the behavior remains consistent with their never having left.
    */
    let retrievedOwnHarvestsForFormerDistrictMember: EntryModel[] = [];
    if (this.district?.isWorldMap) {
      retrievedOwnHarvestsForFormerDistrictMember = this.allMapBoundsEntries.filter(
        entry => entry.owner?.id === this.session.currentUser?.id
          && entry.entryType === ENTRY_TYPE.KILLING
          && !this.districtsToRender.some(district => district.id === entry.districtId)
      );
    }

    // * To avoid duplicates, ensure the retrieved entry is not already in the list
    if (retrievedOwnHarvestsForFormerDistrictMember.length > 0 && entriesToRender.every(e => !retrievedOwnHarvestsForFormerDistrictMember.some(ret => ret.id === e.id))) {

      // * If the district exists in a district list but is turned off for rendering (district selector)
      if (this.allDistricts.find(d => retrievedOwnHarvestsForFormerDistrictMember.some(e => e.districtId === d.id))) {
        return entriesToRender
          .filter(e => this.districtsSelectorFilters.every(f => f.check(e.districtId!)))
          .filter((e) => this.entryFilters.every((f) => f.check(e)))
          .filter((e) => this.dateFilter.check(e));
      }
      // * If the user left the district, their harvest should be rendered on their Private Map
      else {
        entriesToRender.push(...retrievedOwnHarvestsForFormerDistrictMember);
        return entriesToRender
          .filter((e) => this.entryFilters.every((f) => f.check(e)))
          .filter((e) => this.dateFilter.check(e));
      }
    }

    // * Default case without special conditions, return the filtered entries with district, entry and date filters applied
    return entriesToRender
      .filter(e => this.districtsSelectorFilters.every(f => f.check(e.districtId!)))
      .filter((e) => this.entryFilters.every((f) => f.check(e)))
      .filter((e) => this.dateFilter.check(e));
  }

  @computed
  public get totalItems(): number {
    if (!this.district) {
      return 0;
    }

    return this.allMapBoundsEntries.length;
  }

  public activateDateFilter = (condition: DateFilterCondition) => {
    this.dateFilter.activate(condition);
  }

  public togglePoiTypeFilter = (condition: IDistrictFilterCondition) => {
    this.poiFilter.toggle(condition);
  }

  public toggleAllPoiTypeFilter = (active: boolean) => {
    this.poiFilter.setAllTo(active);
  }

  // check if entry is affected by date filter
  public isEntryAffectedByDateFilter = (entryModel: EntryModel) => {
    return !this.dateFilter.check(entryModel);
  }
}
