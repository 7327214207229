import { Box, List } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { UserFilter } from '../../../filters/UserFilter';
import { FilterListItem } from '../components/FilterListItem';
import { NoEntitiesToFilter } from '../components/NoEntitiesToFilter';
import { SelectDeselectAll } from '../components/SelectDeselectAll';

interface IProps {
  userFilter: UserFilter;
}

export const FilterSidebarUser = observer(function FilterSidebarUser(props: IProps) {
  const { t } = useTranslation();

  return (
    <Box mx={3} my={1}>
      <Box display="flex" justifyContent={'flex-end'}>
        <SelectDeselectAll filter={props.userFilter} />
      </Box>
      {props.userFilter.conditions.length > 0 ? <List>
        {props.userFilter.conditions.map((condition) => {
          return (
            <FilterListItem
              key={`user-condition-${condition.id}`}
              onSelectOnlyThis={() => props.userFilter.selectOnlyCondition(condition)}
              iconUrl={condition.member?.user?.photo?.thumbnailUrl ?? undefined}
              title={condition.member?.user?.fullName ?? ''}
              checked={condition.active}
              onClick={() => props.userFilter.toggle(condition)}
            />
          );
        })}
      </List> : <NoEntitiesToFilter text={t('filter:no_members_to_filter')} />}
    </Box>
  );
});
