import { observer } from 'mobx-react-lite';
import { BaseSyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup
} from '@mui/material';

import { useVm } from '../../../../../../domain/hooks/useVm';
import { DistrictModel } from '../../../../../../domain/model/DistrictModel';
import { palette } from '../../../../../../theme/options/palette/palette';
import { ItemAccordion } from '../../../../../../toolkit/components/ItemAccordion';
import { EntryAttributedToVm, IEntryAttributedToProps } from './EntryAttributedToVm';

export const EntryAttributedTo = observer(function EntryAttributedTo(props: IEntryAttributedToProps) {

  const { t } = useTranslation();
  const vm = useVm(EntryAttributedToVm, props);

  const handleClick = useCallback((event: BaseSyntheticEvent) => {
    props.entry.setDistrictId(event.target.value);
    props.onSelectDistrict(vm.attributedDistrict);
  }, [props, vm.attributedDistrict]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="inherit">
      <ItemAccordion selectedItem={vm.attributedDistrict.name}>
        <Grid container direction="column" rowSpacing={4}>
          <Grid item>
            <FormControl fullWidth>
              <FormLabel sx={{
                color: palette.text?.details,
                fontSize: '1.125rem'
              }}
              >{t('entry:view.attributed_to.title')}</FormLabel>
              <RadioGroup
                value={vm.isAttributedToDistrict ? props.entry.districtId : vm.attributedDistrict.id}
                name="attributed_to-radio-buttons-group"
              >
                {(vm.possibleDistricts.length !== 0 ? vm.possibleDistricts : vm.possibleDistrictIncludingPrivateMap).map((district: DistrictModel) => {
                  return (
                    <Box marginRight={2} key={`district-${district.id}`}>
                      <FormControlLabel
                        value={district.id}
                        control={
                          <Radio
                            color='secondary'
                            inputProps={{
                              readOnly: !props.canEditAndDeleteEntries
                            }}
                            onClick={handleClick}
                          />}
                        label={district.name}
                        labelPlacement="start"
                        sx={{
                          '&.MuiFormControlLabel-root': {
                            display: 'flex',
                            justifyContent: 'space-between',
                          },
                          '& .MuiTypography-root': {
                            color: '#000'
                          }
                        }}
                      />
                      <Divider />
                    </Box>
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </ItemAccordion>
    </Box>
  );
});
