import axios from 'axios';
import { singleton } from '../../inversify/decorator';
import { LANGUAGE_REGION } from '../../shared/enum/languageRegion.enum';
import { IClassificationData } from '../../util/classification/types/classification.types';

@singleton()
export class ClassificationProxy {
  public getClassification = (languageRegion: LANGUAGE_REGION) => {
    return axios.get<IClassificationData>(
      `https://hunterco.s3.eu-central-1.amazonaws.com/public/data/web/classification/language_regions/${languageRegion.toUpperCase()}.min.json`
    );
  }
}
