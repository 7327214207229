import {
  HarvestOldIcon, OtherOldIcon, PointOfInterestOldIcon, SightingOldIcon
} from '@HunterCompanion/hunter-design/icons';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

import { ENTRY_TYPE } from '../../../../../shared/enum';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import { IMapMenuItemObject, MapMenuItemChild, StyledMapMenuItem } from '../MapMenuUtilComponents';

interface IPinMenuProps {
  onNewEntry: (type: ENTRY_TYPE) => void;
  onNewPoi: () => void;
  closeMenu: () => void;
  isUserGuestInCurrentDistrict: boolean;
}

export const PinMenu = function PinMenu(props: IPinMenuProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  const pinItems: IMapMenuItemObject[] = useMemo(() => {
    const guestPinItems: IMapMenuItemObject[] = [{
      text: t('map:new_pin_entry_harvest'),
      icon: HarvestOldIcon,
      entryType: ENTRY_TYPE.KILLING,
    }, {
      text: t('map:new_pin_entry_sighting'),
      icon: SightingOldIcon,
      entryType: ENTRY_TYPE.SIGHTING,
    }, {
      text: t('map:new_pin_entry_misc'),
      icon: OtherOldIcon,
      entryType: ENTRY_TYPE.MISC,
    },];

    const additionalItems: IMapMenuItemObject[] = [{
      text: t('map:new_poi'),
      icon: PointOfInterestOldIcon,
    }];

    if (props.isUserGuestInCurrentDistrict) {
      return guestPinItems;
    }

    const generalPinItems = guestPinItems.concat(additionalItems);

    return generalPinItems;
  }, [props.isUserGuestInCurrentDistrict, t]);

  const newEntry = useCallback((type: ENTRY_TYPE) => {
    props.onNewEntry(type);
    props.closeMenu();
  }, [props]);

  const newPoi = useCallback(() => {
    props.onNewPoi();
    props.closeMenu();
  }, [props]);

  const onClick = useCallback((item: IMapMenuItemObject) => {
    if (item.entryType) {
      newEntry(item.entryType);
    } else {
      newPoi();
    }
  }, [newEntry, newPoi]);

  return (
    <Grid container direction={'column'} spacing={1} paddingY={1}>
      {/* TITLE */}
      <Grid item>
        <Typography variant='text_xs_medium' color={theme.palette.text.tertiary}>{t('map:new_pin_btn_text')}</Typography>
      </Grid>

      <Grid item>
        {pinItems.map(item => (
          <StyledMapMenuItem onClick={() => onClick(item)} key={item.text}>
            <MapMenuItemChild item={item} />
          </StyledMapMenuItem>
        )
        )}
      </Grid>

    </Grid>
  );
};
