import { inject } from 'inversify';
import {
  action, computed, IReactionDisposer, makeObservable, observable, reaction, runInAction
} from 'mobx';

import { AsyncTask } from '../../../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { DistrictModel } from '../../../../../../../domain/model/DistrictModel';
import { InvitationCodeModel } from '../../../../../../../domain/model/InvitationCodeModel';
import { MemberModel } from '../../../../../../../domain/model/MemberModel';
import { ShareProxy } from '../../../../../../../domain/proxy/ShareProxy';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../../domain/service/NotificationService';
import { TrackingEvent } from '../../../../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../../../../domain/service/tracking/TrackingService';
import { WsService } from '../../../../../../../domain/service/WsService';
import { SessionStore } from '../../../../../../../domain/store/SessionStore';
import { transient } from '../../../../../../../inversify/decorator';
import { WsEntityMemberDto } from '../../../../../../../shared/dto/websockets/ws.entity.member.dto';
import { DISTRICT_ROLE } from '../../../../../../../shared/enum';
import { ENTITY_TYPE } from '../../../../../../../shared/enum/entityType.enum';
import { WsEvent } from '../../../../../../../shared/ws/ws.event';

export interface IViewDistrictMembersProps {
  district: DistrictModel;
  onDistrictLeft: (district: DistrictModel) => void;
}

export type EventDistrictRoleType = 'member' | 'guest';

@transient()
export class ViewDistrictMembersVm extends ViewModel<IViewDistrictMembersProps> {

  @observable
  public selectedRole: DISTRICT_ROLE = DISTRICT_ROLE.USER;

  @observable
  public members: MemberModel[] = [];

  @observable
  public invitationCodeModels: InvitationCodeModel[] = [];

  private loadDataReaction: IReactionDisposer | null = null;

  constructor(
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(ShareProxy) private readonly shareProxy: ShareProxy,
    @inject(WsService) private readonly wsService: WsService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TrackingService) public readonly tracking: TrackingService,
  ) {
    super();
    makeObservable(this);
  }

  public override async onInit() {
    this.loadDataReaction = reaction(
      () => this.props.district.id,
      () => {
        this.loadData.run();
      },
      { fireImmediately: true },
    );

    this.wsService.registerHandler(WsEvent.MemberAdded, this.onMemberAdded);
    this.wsService.registerHandler(WsEvent.MemberRemoved, this.onMemberRemoved);
    this.wsService.registerHandler(WsEvent.MemberRoleUpdate, this.onMemberUpdated);
  }

  public override async onDestroy() {
    this.loadDataReaction?.();

    this.wsService.deregisterHandler(WsEvent.MemberAdded, this.onMemberAdded);
    this.wsService.deregisterHandler(WsEvent.MemberRemoved, this.onMemberRemoved);
    this.wsService.deregisterHandler(WsEvent.MemberRoleUpdate, this.onMemberUpdated);
  }

  @computed
  public get currentUserRole() {
    return this.members.find((m) => m.user?.id === this.session.session?.user.id)?.role?.name ?? null;
  }

  @computed
  public get trackingEventRole(): EventDistrictRoleType {
    return this.selectedRole === DISTRICT_ROLE.USER ? 'member' : 'guest';
  }

  @computed
  public get canInviteOtherUsersToArea() {
    return this.currentUserRole === DISTRICT_ROLE.OWNER || this.currentUserRole === DISTRICT_ROLE.ADMIN;
  }

  @computed
  private get memberInvitationCode(): string {
    return this.invitationCodeModels.find(icm => icm.role === DISTRICT_ROLE.USER)?.code ?? '';
  }

  @computed
  private get guestInvitationCode(): string {
    return this.invitationCodeModels.find(icm => icm.role === DISTRICT_ROLE.GUEST)?.code ?? '';
  }

  @computed
  public get invitationCode(): string {
    return this.selectedRole === DISTRICT_ROLE.USER ? this.memberInvitationCode : this.guestInvitationCode;
  }

  @action
  public setSelectedRole = (role: DISTRICT_ROLE) => {
    this.selectedRole = role;
  }

  @action
  public setMembers = (members: MemberModel[]) => {
    this.members = members;
  }

  @action
  public setInvitationCodes = (invitationCodes: InvitationCodeModel[]) => {
    this.invitationCodeModels = invitationCodes;
  }

  @action
  private onMemberAdded = (dto: WsEntityMemberDto) => {
    if (this.props.district.id === dto.entityId) {
      this.members.push(MemberModel.fromEntityMember(dto));
    }
  }

  @action
  private onMemberRemoved = (dto: WsEntityMemberDto) => {
    if (this.props.district.id === dto.entityId) {
      this.members = this.members.filter((m) => m.user?.id !== dto.user.id);
    }
  }

  @action
  private onMemberUpdated = (dto: WsEntityMemberDto) => {
    if (this.props.district.id === dto.entityId) {
      const member = this.members.find((m) => m.user?.id === dto.user.id);
      if (member?.role) {
        member.role.name = dto.role as DISTRICT_ROLE;
      }
    }
  }

  @action
  public removeMember = (member: MemberModel) => {
    this.members = this.members.filter((m) => m.user?.id !== member.user?.id);

    if (this.session.currentUser?.id === member.user?.id) {
      this.props.onDistrictLeft(this.props.district);
    }
  }

  @computed
  public get isBusy() {
    return this.loadData.isBusy;
  }

  public loadData = new AsyncTask(async () => {
    const [getMembersResult, getInvitationCodesResult] = await Promise.all([
      this.shareProxy.getMembers.run(this.props.district.id, ENTITY_TYPE.DISTRICT),
      this.shareProxy.getInvitationCodes(this.props.district.id)
    ]);

    if (getMembersResult.ok) {
      this.setMembers(getMembersResult.data ?? []);
    }

    if (getInvitationCodesResult.ok) {
      this.setInvitationCodes(getInvitationCodesResult.data ?? []);

      // * For existing districts, if there is no already generated Guest Role Invitation Code and the current user role is either OWNER or ADMIN,
      // * we need to generate a new code for the GUEST role to allow inviting new members as guests.
      if (this.invitationCodeModels.length === 1 && (this.currentUserRole === DISTRICT_ROLE.OWNER || this.currentUserRole === DISTRICT_ROLE.ADMIN)) {
        await this.generateNewCode.run(DISTRICT_ROLE.GUEST);
      }
    }
  })

  public generateNewCode = new AsyncTask(async (role: DISTRICT_ROLE) => {
    try {
      const result = await this.shareProxy.generateCode({
        entityId: this.props.district.id,
        entityType: ENTITY_TYPE.DISTRICT,
        role: role,
      });

      if (result.ok) {
        return runInAction(() => {
          this.invitationCodeModels.push(result.data);
        });
      }

      return this.notification.error(this.i18n.t('shared:error.generate_new_code'));
    } catch (error) {
      console.error('Something went wrong with generating new code: ', error);
      this.notification.error(this.i18n.t('shared:error.generate_new_code'));
    }
  })

  public regenerateNewCode = new AsyncTask(async () => {
    try {
      this.tracking.track(TrackingEvent.MEMBER_CODE_REFRESHED, { role: this.trackingEventRole });
      const result = await this.shareProxy.regenerateCode({
        entityId: this.props.district.id,
        entityType: ENTITY_TYPE.DISTRICT,
        role: this.selectedRole,
      });

      if (result.ok) {
        return runInAction(() => {
          const index = this.invitationCodeModels.findIndex(model => model.role === result.data.role);
          if (index !== -1) {
            this.invitationCodeModels[index] = result.data;
          } else {
            this.invitationCodeModels.push(result.data);
          }
        });
      }

      return this.notification.error(this.i18n.t('shared:error.regenerate_code'));
    } catch (error) {
      console.error('Something went wrong with regenerating code: ', error);
      this.notification.error(this.i18n.t('shared:error.regenerate_code'));
    }
  })
}
