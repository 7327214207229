import React from 'react';

import { ListItemIcon, ListItemText, MenuItem, styled, Typography } from '@mui/material';

import { ENTRY_TYPE } from '../../../../shared/enum';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { SvgWrapper } from '../../../../toolkit/components/SvgWrapper';

export const StyledMapMenuItem = styled(MenuItem)(({ theme }) => ({
  '&.MuiMenuItem-root': {
    paddingLeft: 0,
  },
  '&:hover': {
    backgroundColor: theme.palette.background.container,
    borderRadius: 8,
  }
}));

export const StyledListItemIcon = styled(ListItemIcon)(() => ({
  '&.MuiListItemIcon-root': {
    minWidth: 10,
    paddingRight: 8,
  }
}));

export interface IMapMenuItemObject {
  text: string;
  icon: string;
  entryType?: ENTRY_TYPE;
}

export interface IMapMenuItemChildProps {
  item: IMapMenuItemObject;
}

export const MapMenuItemChild = function MapMenuItemChild(props: IMapMenuItemChildProps) {
  const { theme } = useThemeContext();

  return (
    <React.Fragment>
      <StyledListItemIcon>
        <SvgWrapper icon={props.item.icon} fill={theme.palette.icons?.secondary} />
      </StyledListItemIcon>
      <ListItemText>
        <Typography variant='text_sm_semibold' color={theme.palette.text.primary}>{props.item.text}</Typography>
      </ListItemText>
    </React.Fragment>
  );
};
