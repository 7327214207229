import React from 'react';

interface IProps {
  color: string;
}

export function MapPathCircleIcon(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
      <g id="Ellipse_2600" data-name="Ellipse 2600" fill={props.color} stroke="#fff" strokeWidth="1">
        <circle cx="18" cy="18" r="18" stroke="none" />
        <circle cx="18" cy="18" r="17.5" fill="none" />
      </g>
    </svg>
  );
}
