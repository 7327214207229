import deLocale from 'date-fns/locale/de';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import frLocale from 'date-fns/locale/fr';
import itLocale from 'date-fns/locale/it';

import { LANGUAGE } from '../../shared/enum/language.enum';

export const languageList: Array<{ value: LANGUAGE, label: string }> = [
  {
    value: LANGUAGE.en,
    label: 'profile:language_en',
  },
  {
    value: LANGUAGE.de,
    label: 'profile:language_de',
  },
  {
    value: LANGUAGE.it,
    label: 'profile:language_it',
  },
  {
    value: LANGUAGE.fr,
    label: 'profile:language_fr',
  },
  {
    value: LANGUAGE.es,
    label: 'profile:language_es',
  },
  // {
  //   value: LANGUAGE.bg,
  //   label: 'profile:language_bg',
  // },
];

export const appLocaleList: Record<string, Locale> = {
  en: enLocale,
  de: deLocale,
  it: itLocale,
  fr: frLocale,
  es: esLocale,
  // bg: bgLocale,
};
