import { observer } from 'mobx-react-lite';

import { Box, Typography } from '@mui/material';

import { useVm } from '../../../../../domain/hooks/useVm';
import { Marker } from '../../Marker';
import { IPoiWeatherMarkerProps, PoiWeatherMarkerVm } from './PoiWeatherMarkerVm';

export const PoiWeatherMarker = observer(function PoiWeatherMarker(props: IPoiWeatherMarkerProps) {
  const vm = useVm(PoiWeatherMarkerVm, props);

  return (
    <Marker
      lat={props.lat}
      lng={props.lng}
      icon={vm.background}
      opacity={props.opacity}
      width={40}
    >
      <Box sx={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <img
          style={{
            width: '25px',
          }}
          src={vm.icon}
        />
        <Typography sx={{
          color: 'white',
          fontWeight: '700',
          fontSize: '0.7rem',
          marginLeft: '2px',
          lineHeight: '1.3'
        }}>{vm.temperature}{vm.unit}</Typography>
      </Box>
      <img
        style={{
          position: 'absolute',
          top: 'calc(-50% - 3px)',
          transform: `rotate(${vm.windDegree}deg)`,
        }}
        src={vm.windIcon}
      />
    </Marker>
  );
});
