import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EditIcon from '@mui/icons-material/Edit';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu,
  MenuItem
} from '@mui/material';

import { IGeoLocation } from '../../../../../../domain/core/IGeoLocation';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { KmlImportModal } from '../../view-district/components/kml-upload/KmlImportModal';
import { KmlImportModalRef } from '../../view-district/components/kml-upload/KmlImportModalVm';

interface IProps {
  onEdit: (points?: IGeoLocation[]) => void;
  onDelete: () => void;
  importMultiplePois?: (pois: PoiModel[]) => void;
}

export function DistrictControls(props: IProps) {
  const { t } = useTranslation();
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const kmlImportModalRef: React.RefObject<KmlImportModalRef> = useMemo(() => React.createRef(), []);

  const showMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
    setAlertOpen(false);
  }, []);

  const onEdit = useCallback((points?: IGeoLocation[]) => {
    props.onEdit(points);
    closeMenu();
  }, [props, closeMenu]);

  const onUpdateBordersViaFile = useCallback(() => {
    kmlImportModalRef.current?.open();
    closeMenu();
  }, [closeMenu, kmlImportModalRef]);

  const onDelete = useCallback(() => {
    closeMenu();
    props.onDelete();
  }, [props, closeMenu]);

  return (
    <>
      <IconButton onClick={showMenu}>
        <EditIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
      >
        <MenuItem onClick={() => onEdit()}>
          {t('district:options_dropdown_edit')}
        </MenuItem>
        <MenuItem onClick={onUpdateBordersViaFile}>
          {t('district:options_dropdown_update_borders')}
        </MenuItem>
        <MenuItem onClick={() => setAlertOpen(true)}>
          {t('district:options_dropdown_delete')}
        </MenuItem>
      </Menu>

      <KmlImportModal ref={kmlImportModalRef} onDistrictSelected={(_name, points) => onEdit(points)} importMultiplePois={props.importMultiplePois} />

      <Dialog
        open={alertOpen}
        onClose={closeMenu}
      >
        <DialogTitle>
          {t('district:delete_district_alert_title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('district:delete_district_alert_message')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeMenu}>{t('district:delete_district_cancel')}</Button>
          <Button onClick={onDelete} color="error">
            {t('district:delete_district_delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
