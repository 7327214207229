import { makeObservable, observable } from 'mobx';

import { InvitationCodeResponseDto } from '../../shared/dto';
import { DISTRICT_ROLE, EVENT_ROLE, GROUP_ROLE } from '../../shared/enum';

export class InvitationCodeModel {

  @observable
  public code: string = '';

  @observable
  public role: DISTRICT_ROLE | EVENT_ROLE | GROUP_ROLE;

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: InvitationCodeResponseDto) => {
    const model = new InvitationCodeModel();

    model.code = dto.code;
    model.role = dto.role;

    return model;
  }
}
