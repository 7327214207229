import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Grid, Link, Typography, useMediaQuery } from '@mui/material';

import { useContainer } from '../../../../domain/hooks/useContainer';
import { TrackingEvent } from '../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../domain/service/tracking/TrackingService';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

export const ManageGroupDescription: React.FC = function ManageGroupDescription() {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();
  const tracking = useContainer().get(TrackingService);

  const handleClickOnALink = useCallback(() => {
    tracking.track(TrackingEvent.PROFILE_MULTIPLE_SUBSCRIPTIONS_CANCEL);
  }, [tracking]);

  return (
    <Grid container justifyContent={'center'} rowGap={1} paddingX={1} maxWidth={{ md: '60%' }}>
      <Grid item textAlign={'center'}>
        <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.secondary}>
          {t('group_subscription:manage_group.description.text')}
        </Typography>
      </Grid>
      <Grid item textAlign={'center'}>
        <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.secondary}>
          <Trans
            i18nKey="group_subscription:manage_group.description.cta"
            components={{
              linkTag: (
                <Link
                  onClickCapture={handleClickOnALink}
                  target="_blank"
                  href={t('links:group_pricing')}
                  style={{
                    textDecoration: 'underline',
                    color: theme.palette.text.secondary,
                  }}
                />
              )
            }}
          />
        </Typography>
      </Grid>
    </Grid>
  );
};
