import React, { useEffect, useMemo } from 'react';
import { IGeoLocation } from '../../../../../domain/core/IGeoLocation';
import { MeshModel } from '../../../../../domain/model/MeshModel';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { palette } from '../../../../../theme/options/palette/palette';
import { GeoUtil } from '../../../../../util/GeoUtil';

export interface IMapClickEvent {
  latLng: {
    lat: () => number,
    lng: () => number,
  },
  domEvent: React.FormEvent;
}
export interface IPolylineConfig {
  map: google.maps.Map | null;
  mesh: MeshModel;
  meshClosed: boolean;
  strokeWeight?: number;
  onLineHover?: (location: IGeoLocation | null) => void;
  onLineClick: (location: IGeoLocation) => void;
}

export function convertGeolocationToPath(points: IGeoLocation[]) {
  return points.map((p) => ({ lat: p.latitude, lng: p.longitude }));
}

export function useDrawDistrictMesh(config: IPolylineConfig) {
  const path = useMemo(() => {
    return convertGeolocationToPath(config.mesh.points);
    // it is necessary to have both points and points.length
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.mesh.points, config.mesh.points.length]);

  // draw district mesh
  useEffect(() => {
    if (!config.map) {
      return;
    }

    if (!path.length) {
      return;
    }

    const polyline = new google.maps.Polyline({
      path,
      strokeColor: palette.common?.districtMesh,
      strokeOpacity: 1,
      strokeWeight: config.strokeWeight ?? 4,
      map: config.map,
      clickable: true,
      zIndex: 2,
    });

    // * edit existing polyline
    let selectedPolyline: google.maps.Polyline | null = null;

    polyline.addListener('mousemove', function (e: IMapClickEvent) {
      config.onLineHover?.({ latitude: e.latLng.lat(), longitude: e.latLng.lng() });
    });

    polyline.addListener('click', function (event: IMapClickEvent) {
      if (config.meshClosed) {
        return config.onLineClick?.({ latitude: event.latLng.lat(), longitude: event.latLng.lng() });
      }

      const pair = GeoUtil.getClosestPointPair({
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
      }, config.mesh.points);

      config.mesh.selectPair(pair);

      selectedPolyline?.setMap(null);
      selectedPolyline = new google.maps.Polyline({
        path: convertGeolocationToPath(pair),
        strokeColor: colors.warning,
        strokeOpacity: 2,
        strokeWeight: 4,
        map: config.map,
        zIndex: 2,
      });
    });

    return () => {
      polyline.setMap(null);
      selectedPolyline?.setMap(null);
      config.mesh.resetPair();
    };
  }, [config.map, config.mesh, config.meshClosed, config.strokeWeight, config, path]);

  // draw mesh closing line
  useEffect(() => {
    let polyline: google.maps.Polyline | null = null;

    if (!config.meshClosed && path.length >= 3) {
      polyline = new google.maps.Polyline({
        path: [
          path[path.length - 1],
          path[0],
        ],
        strokeOpacity: 0,
        zIndex: 2,
        icons: [
          {
            icon: {
              path: 'M 0,-1 0,1',
              strokeOpacity: 1,
              strokeWeight: 3,
              strokeColor: palette.common?.districtMesh,
            },
            offset: '0',
            repeat: '15px',
          },
        ],
        map: config.map,
      });
    }

    return () => {
      polyline?.setMap(null);
    };
  }, [config.map, config.mesh, config.meshClosed, config.strokeWeight, config, path]);
}
