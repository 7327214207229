import React from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { colors } from '../../../../../../theme/options/palette/const/colors';

interface MenuItemTooltipProps {
  message: string;
}

export function MenuItemTooltip(props: MenuItemTooltipProps) {
  return (
    <React.Fragment>
      <Box>
        <Grid container direction="column" justifyContent="center" sx={{ borderRadius: 10 }}>
          <Grid item padding={1}
            textAlign="center"
            sx={{
              background: '#d9d9d9',
              color: colors.paper,
              borderRadius: '4px',
            }}
          >
            <Typography fontSize={14} fontWeight="regular" color={'#000'}>
              {props.message}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}
