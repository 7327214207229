import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';

import { GroupSubscriptionUserModel } from '../../../../domain/model/GroupSubscriptionUserModel';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { AddSeatsDropdown } from './AddSeatsDropdown';

interface IAddSeatsSectionProps {
  candidatesForAssignment: GroupSubscriptionUserModel[];
  assignedMembers: Set<GroupSubscriptionUserModel>;
  setAssignedMembers: (assignedMembers: Set<GroupSubscriptionUserModel>) => void;
  canceledSubscriptionEmptySeats?: number;  // use it only on manage subscription, represents unassigned seats on Canceled Subscription
}

export const AddSeatsSection: React.FC<IAddSeatsSectionProps> = function AddSeatsSection({
  candidatesForAssignment,
  assignedMembers,
  setAssignedMembers,
  canceledSubscriptionEmptySeats = 0
}: IAddSeatsSectionProps) {
  const { theme } = useThemeContext();
  const { t } = useTranslation();

  const addSeatsDropdown = useMemo(() => <AddSeatsDropdown
    candidatesForAssignment={candidatesForAssignment}
    assignedMembers={assignedMembers}
    setAssignedMembers={setAssignedMembers}
    canceledSubscriptionEmptySeats={canceledSubscriptionEmptySeats}
  />, [assignedMembers, canceledSubscriptionEmptySeats, candidatesForAssignment, setAssignedMembers]);

  return (
    <Grid container justifyContent={'space-between'} rowGap={2}>
      <Grid container direction={'column'} item xs={12} sm={6}>
        <Grid item>
          <Typography variant='text_md_semibold' color={theme.palette.text.primary}>
            {t('group_subscription:table.section_heading')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='text_sm_regular' color={theme.palette.text.secondary}>
            {t('group_subscription:table.section_description')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction={'row-reverse'} alignItems={'flex-end'} item xs={12} sm={6}>
        <Grid item>
          {addSeatsDropdown}
        </Grid>
      </Grid>
    </Grid>
  );
};
