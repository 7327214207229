import { Box, SxProps, Typography } from '@mui/material';
import React from 'react';

interface IProps {
  onClick: () => void;
  icon?: string;
  text?: string;
  component?: React.ReactNode;
  sx?: SxProps;
  imgStyle?: React.CSSProperties;
}

export function PoiTypeButton(props: IProps) {
  return (
    <Box
      sx={{ ...props.sx, cursor: 'pointer' }}
      display={'inline-flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      onClick={props.onClick}
      minWidth={'100px'}
      width={'100%'}
      height={'100%'}
      maxHeight={'116px'}
      borderRadius={'4px'}
      bgcolor={'background.info'}
      paddingY={2}
      paddingX={1}
    >
      {props.component}
      {props.icon && <img style={{
        width: '60px',
        height: '60px',
        marginBottom: '4px',
        ...props.imgStyle,
      }}
        src={props.icon}
        alt={props.text}
      />}
      {props.text && <Typography
        sx={{ wordBreak: 'break-word' }}
        fontSize={'0.8rem'}
        fontWeight={500}
        textAlign="center"
        color={'primary.main'}
      >
        {props.text}
      </Typography>}
    </Box>
  );
}
