import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Grid, Typography, useMediaQuery } from '@mui/material';

import { AppRoutes } from '../../../../router/AppRoutesEnum';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';

interface IGroupTitleProps {
  title: string;
  route?: AppRoutes;
  buttonLocizeKey?: string;
}

export const GroupTitle = function GroupTitle({
  title,
  route = AppRoutes.UserProfile,
  buttonLocizeKey,
}: IGroupTitleProps) {
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} rowGap={2} textAlign={'center'}>
      <Grid item xs={12}>
        <Button
          startIcon={<ArrowBackIcon htmlColor={theme.palette.text.primary} />}
          onClick={() => navigate(route)}
          sx={{
            backgroundColor: 'transparent',
            color: 'inherit',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)', // Slight hover effect
            },
            '&:focus': {
              outline: 'none',
            },
          }}
        >
          <Typography variant='text_sm_semibold' color={theme.palette.text.primary}>
            {buttonLocizeKey ? t(`${buttonLocizeKey}`) : t('group_subscription:button.navigation_back')}
          </Typography>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={isMobileSize ? 'headingTitleMobile' : 'headingTitleDesktop'} color={theme.palette.text.primary}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};
