import { CheckCircle } from '@mui/icons-material';
import { createTheme, PaletteMode, responsiveFontSizes } from '@mui/material';

import { breakpoints } from './options/breakpoints';
import { darkThemePalette } from './options/palette/darkThemePalette';
import { lightThemePalette } from './options/palette/lightThemePalette';
import { typography } from './options/typography';

export const getDesignTokens = (mode: PaletteMode) => {
  const themePalette = mode === 'light' ? lightThemePalette : darkThemePalette;

  return responsiveFontSizes(
    createTheme(
      ({
        typography,
        breakpoints,
        palette: {
          mode,
          ...themePalette,
        },
        components: {
          MuiTypography: {
            defaultProps: {
              variantMapping: {
                headingTitleDesktop: 'h1',
                headingTitleMobile: 'h1',
                headingSubtitleDesktop: 'h2',
                headingSubtitleMobile: 'h2',
              }
            }
          },
          MuiButton: {
            defaultProps: {
              variant: 'contained',
              size: 'small',
            },
            styleOverrides: {
              root: {
                borderRadius: 8,
                textTransform: 'none',
                boxShadow: 'none',
              },
              sizeLarge: {
                height: 44,
                ...typography.text_md_semibold,
              },
              sizeSmall: {
                height: 32,
                ...typography.text_sm_semibold,
              },
              containedPrimary: {
                backgroundColor: themePalette.brand.brand_color,
                color: themePalette.system.white,
                ':disabled': {
                  backgroundColor: themePalette.brand.brand_color,
                  opacity: 0.4,
                  color: themePalette.system.white,
                },
                ':hover': {
                  backgroundColor: themePalette.brand.dark,
                  color: themePalette.system.white,
                },
              },
              containedSecondary: {
                backgroundColor: themePalette.buttons.secondary,
                border: `1px solid ${themePalette.borderDividers.lighter_darker}`,
                color: themePalette.text.primary,
                ':disabled': {
                  backgroundColor: themePalette.buttons.secondary,
                  opacity: 0.4,
                  color: themePalette.text.primary,
                },
                ':hover': {
                  backgroundColor: themePalette.background.container_2,
                  color: themePalette.text.primary,
                },
              },
              containedError: {
                backgroundColor: themePalette.background.error_default,
                color: themePalette.system.white,
                ':disabled': {
                  opacity: 0.4,
                },
              },
              textPrimary: {
                backgroundColor: 'none',
                color: themePalette.buttons.primary,
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: themePalette.buttons.secondary,
                },
              }
            }
          },
          MuiTextField: {
            defaultProps: {
              variant: 'standard',
              type: 'text',
              InputProps: {
                disableUnderline: true,
              },
              fullWidth: true,
            },
            styleOverrides: {
              root: {
                height: 44,
                borderRadius: 8,
                backgroundColor: themePalette.background.container,
                border: `1px solid ${themePalette.borderDividers?.border}`,
                ':focus': {
                  border: '1px solid red',
                },
                paddingLeft: 12,
                input: {
                  '&::placeholder': {
                    color: themePalette.text.tertiary,
                    ...typography.text_md_regular,
                  },
                },
              },
            }
          },
          MuiInput: {
            styleOverrides: {
              root: {
                height: 44,
              }
            }
          },
          MuiListItemButton: {
            styleOverrides: {
              root: {
                ':hover': {
                  borderRadius: 8,
                }
              }
            }
          },
          MuiListItemIcon: {
            styleOverrides: {
              root: {
                minWidth: 30
              }
            }
          },
          MuiCheckbox: {
            defaultProps: {
              disableRipple: true,
              disableFocusRipple: true,
            },
            styleOverrides: {
              root: {
                color: themePalette.background.container_2,
                '&.Mui-checked': {
                  color: themePalette.brand.selection,
                },
                '&.MuiCheckbox-indeterminate': {
                  color: themePalette.brand.selection,
                },
              },
            }
          },
          MuiRadio: {
            defaultProps: {
              checkedIcon: <CheckCircle htmlColor={themePalette.brand.selection} />,
              size: 'small',
              disableRipple: true,
            },
            styleOverrides: {
              root: {
                '&.Mui-checked': {
                  color: themePalette.brand.selection
                },
              },
            }
          },
          MuiMobileStepper: {
            defaultProps: {
              variant: 'dots',
            },
            styleOverrides: {
              dot: {
                backgroundColor: themePalette.background.container_2,
              },
              dotActive: {
                backgroundColor: themePalette.brand.brand_color,
              }
            }
          },
          MuiDivider: {
            styleOverrides: {
              root: {
                borderColor: themePalette.borderDividers?.border,
              }
            }
          },
          MuiTooltip: {
            defaultProps: {
              arrow: true,
            },
            styleOverrides: {
              tooltip: {
                backgroundColor: themePalette.background.tooltip,
                borderRadius: 4,
                ...typography.text_xs_medium,
                padding: 4,
                textAlign: 'center',
                color: themePalette.system.white
              },
              arrow: {
                '&::before': {
                  backgroundColor: themePalette.background.tooltip,
                }
              },
            }
          },
          MuiTableContainer: {
            styleOverrides: {
              root: {
                borderRadius: 8,
                borderBottom: `1px solid ${themePalette.borderDividers.border}`
              }
            }
          },
          MuiMenu: {
            styleOverrides: {
              paper: {
                borderRadius: 8,
                backgroundColor: themePalette.background.surface,
                border: `1px solid ${themePalette.borderDividers?.border}`,
                color: themePalette.text.primary,
              },
              list: {
                padding: 0,
              },
            },
          },
          MuiMenuItem: {
            styleOverrides: {
              root: {
                borderRadius: 4,
                ...typography.text_sm_regular,
                padding: '6px 16px',
              },
            },
          },
          MuiDialogTitle: {
            styleOverrides: {
              root: {
                padding: '8px 8px 0px 16px'
              },
            },
          },
          MuiDialogContent: {
            styleOverrides: {
              root: {
                padding: '16px'
              },
            },
          },
          MuiDialogContentText: {
            styleOverrides: {
              root: {
                color: themePalette.text.primary,
              }
            }
          },
          MuiDialogActions: {
            styleOverrides: {
              root: {
                padding: 16
              }
            }
          }
        }
      })
    )
  );
};
