import { inject } from 'inversify';

import { singleton } from '../../inversify/decorator';
import {
  DistrictPostRequestDto, DistrictPutRequestDto, DistrictResponseDto
} from '../../shared/dto';
import { AreaPostRequestDto } from '../../shared/dto/area.post.request.dto';
import { ImportResponseDto } from '../../shared/dto/import.response.dto';
import { CheckInPoiCountResponseDto } from '../../shared/dto/poi/checkinPoiCount.response.dto';
import { DistrictModel } from '../model/DistrictModel';
import { PoiCheckinCountModel } from '../model/PoiCheckinCountModel';
import { AjaxService, IHttpNotOkResponse, IHttpOkResponse } from '../service/AjaxService';
import { Proxy } from './Proxy';

@singleton()
export class DistrictProxy extends Proxy {

  constructor(
    @inject(AjaxService) private readonly ajax: AjaxService,
  ) {
    super();
  }

  public getAllDistricts = async () => {
    const result = await this.ajax.get<DistrictResponseDto[]>('district/v2');

    if (result.ok) {
      return this.withData(result, result.data.map(DistrictModel.fromDto));
    }

    return result;
  }

  public getDistrictsInMapBounds = async (requestDto: AreaPostRequestDto) => {
    const result = await this.ajax.post<DistrictResponseDto[]>('/district/area', requestDto);
    if (result.ok) {
      return this.withData(result, result.data.map(DistrictModel.fromDto));
    }

    return result;
  }

  public importDistrict = async (data: FormData): Promise<IHttpNotOkResponse | IHttpOkResponse<ImportResponseDto>> => {
    const result = await this.ajax.upload<ImportResponseDto>('district/import', data);

    if (result.ok) {
      return this.withData(result, result.data);
    }

    return result;
  }

  public createDistrict = async (body: DistrictPostRequestDto) => {
    const result = await this.ajax.post<DistrictResponseDto>('district/v2', body);

    if (result.ok) {
      return this.withData(result, DistrictModel.fromDto(result.data));
    }

    return result;
  }

  public updateDistrict = async (body: DistrictPutRequestDto) => {
    const result = await this.ajax.put<DistrictResponseDto>('district/v2', body);

    if (result.ok) {
      return this.withData(result, DistrictModel.fromDto(result.data));
    }

    return result;
  }

  public deleteDistrict = async (id: string) => {
    return this.ajax.delete(`district/${id}`);
  }

  public getCheckinsCount = async (districtId: string, token: string, dateFrom: string, dateTo: string, timezone: string) => {
    const response = await this.ajax.get<CheckInPoiCountResponseDto>(`/checkin-list/count/district/${districtId}?token=${token}&dateFrom=${dateFrom}&dateTo=${dateTo}&timezone=${timezone}`);
    if (response.ok) {
      return this.withData(response, PoiCheckinCountModel.fromDto(response.data));
    }

    return response;
  }

  // * there was a problem with exposing response headers in JS code so we don't use this anymore
  /* public downloadCheckinHistory = async (districtId: string, from: Date, to: Date) => {
    return await axios.get(`${env.api}/district/v2/${districtId}/checkins/download?dateFrom=${from.toISOString()}&dateTo=${to.toISOString()}&timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      {
        headers: {
          'authorization': `Bearer ${this.session.session?.sessionToken}`
        },
        responseType: 'arraybuffer'
      },
    );
  } */

}
