import { PaperSizeEnum } from '../components/PaperSizeTabs';

export interface PaperDimensions {
  width: number;
  height: number;
}

export function getPaperDimensionsInPx(paperSize: PaperSizeEnum, dpi: number = 300): PaperDimensions {
  const mmPerInch = 25.4;
  const pixelsPerMm = dpi / mmPerInch;

  switch (paperSize) {
    case PaperSizeEnum.A0:
      return { width: Math.round(841 * pixelsPerMm), height: Math.round(1189 * pixelsPerMm) };
    case PaperSizeEnum.A1:
      return { width: Math.round(594 * pixelsPerMm), height: Math.round(841 * pixelsPerMm) };
    case PaperSizeEnum.A2:
      return { width: Math.round(420 * pixelsPerMm), height: Math.round(594 * pixelsPerMm) };
    case PaperSizeEnum.A3:
      return { width: Math.round(297 * pixelsPerMm), height: Math.round(420 * pixelsPerMm) };
    case PaperSizeEnum.A4:
      return { width: Math.round(210 * pixelsPerMm), height: Math.round(297 * pixelsPerMm) };
    default:
      return { width: Math.round(210 * pixelsPerMm), height: Math.round(297 * pixelsPerMm) };
  }
}

export function getPaperDimensionsInMM(paperSize: PaperSizeEnum): PaperDimensions {
  switch (paperSize) {
    case PaperSizeEnum.A0:
      return { width: 841, height: 1189 };
    case PaperSizeEnum.A1:
      return { width: 594, height: 841 };
    case PaperSizeEnum.A2:
      return { width: 420, height: 594 };
    case PaperSizeEnum.A3:
      return { width: 297, height: 420 };
    case PaperSizeEnum.A4:
      return { width: 210, height: 297 };
    default:
      return { width: 210, height: 297 };
  }
}
