import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { ViewModel } from '../../../../../domain/core/ViewModel';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { transient } from '../../../../../inversify/decorator';
import { FEATURE } from '../../../../../shared/enum';
import { PoiHelper } from '../../../../../util/PoiHelper';
import { PoiTabType } from '../new-poi/NewPoiVm';
import { ViewPoiProps } from './ViewPoi';

export enum PoiTabEnum {
  TASKS = 'poi:details_tasks',
  BOOKING = 'poi:details_booking',
  INFO = 'poi:details_info',
  SHOOTING_DIRECTION = 'poi:shooting_direction_tab',
  CHECK_INS = 'poi:check_ins',
  ATTRIBUTED_TO = 'poi:attributed_to',
}

export interface PoiTab {
  name: PoiTabEnum;
  enabled: boolean;
}

@transient()
export class ViewPoiVm extends ViewModel<ViewPoiProps> {

  @observable
  public tab: PoiTab = this.poiTabs[0];

  constructor(
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(PoiHelper) public readonly poiHelper: PoiHelper,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get poiTabs(): PoiTab[] {
    const tabs = [
      {
        name: PoiTabEnum.TASKS,
        enabled: this.session.hasFeatureEnabled(FEATURE.TASKS),
      },
      {
        name: PoiTabEnum.BOOKING,
        enabled: this.session.hasFeatureEnabled(FEATURE.BOOKINGS),
      },
      {
        name: PoiTabEnum.INFO,
        enabled: true,
      },
      {
        name: PoiTabEnum.ATTRIBUTED_TO,
        enabled: true,
      },
      {
        name: PoiTabEnum.CHECK_INS,
        enabled: this.session.hasFeatureEnabled(FEATURE.CHECKIN_POI),
      },
    ];

    if (this.props.poi?.canHaveShootingDirection) {
      tabs.push({
        name: PoiTabEnum.SHOOTING_DIRECTION,
        enabled: this.session.hasFeatureEnabled(FEATURE.SHOOTING_DIRECTION),
      });
    }

    return tabs;
  }

  @computed
  public get hasDisabledTab() {
    return this.poiTabs.some((t) => !t.enabled);
  }

  @action
  public setTab = (tab: PoiTab) => {
    this.tab = tab;
  }

  public onPoiEdit = () => {
    this.props.onPoiEdit(this.props.poi, { type: PoiTabType.DETAILS, enabled: true, });
  }

  public onShootingDirectionEdit = () => {
    this.props.onPoiEdit(this.props.poi, {
      type: PoiTabType.SHOOTING_DIRECTION,
      enabled: this.session.hasFeatureEnabled(FEATURE.SHOOTING_DIRECTION),
    });
  }

  @computed
  public get typeText() {
    return this.poiHelper.getTypeText(this.props.poi);
  }

  @computed
  public get backgroundColor() {
    return this.poiHelper.getBackgroundColor(this.props.poi);
  }

  @computed
  public get canEditAndDeletePoi(): boolean {
    return this.props.poi.ownerId === this.session.currentUser?.id
      ? true
      : Boolean(this.props.districtsVm.selectedDistrict?.canEditAndDeletePois);
  }

}
