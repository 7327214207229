import { inject } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { scoped } from '../../inversify/decorator';
import { IGeoLocation } from '../../shared/interfaces/IGeoLocation';
import { GoogleMapVm } from './components/google-map/GoogleMapVm';
import { MapboxMapVm } from './components/mapbox-map/MapboxMapVm';
import { IMapVm } from './MapVm.interface';

export type MapProvider = 'google' | 'mapbox';

export type MapType = 'terrain' | 'satellite' | 'roadmap' | 'parcels';

@scoped()
export class MapVm implements IMapVm {

  @observable
  public mapProvider: MapProvider;

  @observable
  public mapType: MapType;

  @observable
  public styleUrl: string | undefined;

  private readonly lastMapTypeKey = 'hunter-last-map-type';

  constructor(
    @inject(MapboxMapVm) private readonly mapboxMapVm: MapboxMapVm,
    @inject(GoogleMapVm) private readonly googleMapVm: GoogleMapVm,
  ) {
    this.mapProvider = 'google';
    this.mapType = this.getStoredMapType() ?? 'satellite';
    this.styleUrl = undefined;

    makeObservable(this);
  }

  private getStoredMapType = (): MapType | null => {
    const lastMapType = window.localStorage.getItem(this.lastMapTypeKey);
    if (lastMapType) {
      return lastMapType as MapType;
    }

    return null;
  }

  public selectDefault = () => {
    this.selectMapType('google', this.getStoredMapType() ?? 'satellite');
  }

  @action
  public selectMapType = (mapProvider: MapProvider, mapType: MapType, styleUrl?: string) => {
    this.mapProvider = mapProvider;
    this.mapType = mapType;
    this.styleUrl = styleUrl;

    if (mapType !== 'parcels') {
      window.localStorage.setItem(this.lastMapTypeKey, this.mapType);
    }
  }

  public flyTo = async (point: IGeoLocation, zoom: number | undefined = undefined) => {
    if (this.mapProvider === 'google') {
      return this.googleMapVm.flyTo(point, zoom);
    } else if (this.mapProvider === 'mapbox') {
      return this.mapboxMapVm.flyTo(point, zoom);
    }

    throw new Error(`unsupported map type ${this.mapProvider}`);
  }

  public centerMesh = async (mesh: IGeoLocation[], onlyCenter?: boolean | undefined) => {
    if (this.mapProvider === 'google') {
      return this.googleMapVm.centerMesh(mesh, onlyCenter);
    } else if (this.mapProvider === 'mapbox') {
      return this.mapboxMapVm.centerMesh(mesh);
    }
  }
}
