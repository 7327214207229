import { IsEnum, IsInt, IsOptional, IsString, Max, Min } from 'class-validator';

import { ApiProperty } from '@nestjs/swagger';

import { TRACK_RATING_ACTION } from '../enum';

export class TrackRatingPostRequestDto {

  @IsEnum(TRACK_RATING_ACTION)
  @ApiProperty()
  public action: TRACK_RATING_ACTION;

  @IsOptional()
  @IsInt()
  @Max(5)
  @Min(1)
  @ApiProperty()
  public rating?: number;

  @IsOptional()
  @IsString()
  @ApiProperty()
  public trigger?: string;
}
