import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../domain/async/AsyncTask';
import { ViewModel } from '../../../domain/core/ViewModel';
import { StripeProductModel } from '../../../domain/model/stripe/StripeProductModel';
import { I18nService } from '../../../domain/service/I18nService';
import { StripeService } from '../../../domain/service/StripeService';
import { TrackingEvent } from '../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { transient } from '../../../inversify/decorator';
import {
  SubscriptionAnonymousPostRequestDto
} from '../../../shared/dto/subscription/subscription.anonymous.post.request.dto';
import { fetchAndFilterProducts } from '../../../util/StripeUtil';

@transient()
export class AnonymousSubscriptionPurchaseRouteVm extends ViewModel {

  @observable
  public products: StripeProductModel[] = [];

  @observable
  public userId: string = '';

  @observable
  public email: string = '';

  constructor(
    @inject(StripeService) private readonly stripeService: StripeService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(SessionStore) public readonly sessionStore: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get isLoading(): boolean {
    return this.getOfferings.isBusy || this.getProducts.isBusy;
  }

  @action
  private setProducts = (products: StripeProductModel[]): void => {
    this.products = products;
  }

  @action
  public setUserId = (userId: string) => {
    this.userId = userId;
  }

  @action
  public setEmail = (email: string) => {
    this.email = email;
  }

  /**
   *  Works only if fullname is exactly like this: "Monthly some other_words"
   *  the first word in fullname is ${name} part of locize key. So, eg:
   *    fullname => "Monthly some other_words"
   *    first word, aka ${name} => "monthly"
   *    locize key => payment:subscription_monthly
   */
  @action
  public getTranslation = (fullname: string) => {
    const name = fullname.toLowerCase().split(' ')[0];
    return this.i18n.t(`payment:subscription_${name}`);
  };

  public getOfferings = new AsyncTask(async (): Promise<string[] | undefined> => {
    return await this.stripeService.fetchRevenueCatOfferings();
  })

  public getProducts = new AsyncTask(async (productsIds: string[] = []): Promise<void> => {
    const products = await fetchAndFilterProducts(this.stripeService.fetchProducts, productsIds);

    this.setProducts(products);
    await this.tracking.track(TrackingEvent.PRICING_PAGE_VIEW);
  })

  /**
   *  A non-logged-in user can only buy SINGLE products, and this createCheckoutSession is used exclusively for NON-group products for non-logged-in users
  */
  public createCheckoutSession = new AsyncTask(async (product: StripeProductModel, comingFromInAppBrowser: boolean = false): Promise<void> => {
    const subscriptionAnonymousDto: SubscriptionAnonymousPostRequestDto = {
      productId: product.id,
      priceId: product.price.priceId,
      userId: this.userId,
      email: this.email,
      comingFromInAppBrowser,
      couponId: product.coupon?.id
    };

    return await this.stripeService.createAnonymousCheckoutSession(subscriptionAnonymousDto);
  })

}
