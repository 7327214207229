import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography } from '@mui/material';

import { useContainer } from '../../../../../../domain/hooks/useContainer';
import { MapPathModel } from '../../../../../../domain/model/MapPathModel';
import { MapPathTypeModel } from '../../../../../../domain/model/MapPathType';
import { NotificationService } from '../../../../../../domain/service/NotificationService';
import { colors } from '../../../../../../theme/options/palette/const/colors';
import { MapPathCircleIcon } from './MapPathCircleIcon';
import { MapPathTypeIcon } from './MapPathTypeIcon';

interface IProps {
  types: MapPathTypeModel[];
  mapPath: MapPathModel;
  canEditAndDeleteMapPath: boolean;
}

export const MapPathTypeSelection = observer(function MapPathTypeSelection(props: IProps) {
  const { t } = useTranslation();

  const notification = useContainer().get(NotificationService);

  const customColors = useMemo(() => {
    return [
      '#DD464F',
      '#DB4E40',
      '#E9693D',
      '#F79F3D',
      '#F7BF3A',
      '#B0C44C',
      '#80C54B',
      '#33BA59',
      '#36C0B2',
      '#3AB2DC',
      '#4D8ADF',
      '#7575D9',
      '#B775DD',
      '#DC71B0',
      '#E1E5E8',
      '#A7AFBA',
    ];
  }, []);

  const selectedType = useMemo(() => {
    if (!props.mapPath.typeId) {
      return null;
    }

    return props.types.find(type => type.id === props.mapPath.typeId);
  }, [props.types, props.mapPath.typeId]);

  const selectType = useCallback((type: MapPathTypeModel) => {
    if (!props.canEditAndDeleteMapPath) {
      return notification.info(t('paths:wrong_permission'));
    }
    props.mapPath.setType(type, type.color ?? customColors[0]);
    props.mapPath.setTitle(t(type.i18nKey));
  }, [props.canEditAndDeleteMapPath, props.mapPath, customColors, t, notification]);

  return (
    <Box width={'100%'} padding={4}>
      <Grid direction={'row'} container spacing={1}>
        {props.types.map((type) => {
          const selected = props.mapPath.typeId === type.id;

          return (
            <Grid item key={`map-path-type-${type.id}`} xs>
              <Box
                display={'flex'}
                sx={{ cursor: 'pointer' }}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                bgcolor={selected ? colors.blueGrey[500] : colors.blueGrey[50]}
                paddingX={2}
                paddingY={2.5}
                borderRadius={1}
                onClick={() => selectType(type)}
              >
                <MapPathTypeIcon color={type.color ?? '#CDD2DE'} />
                <Typography mt={1} variant="subtitle2" color={selected ? 'white' : colors.primary} textAlign="center">
                  {t(type.i18nKey)}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
      {(selectedType && !selectedType.color) && (<Grid container paddingTop={3} spacing={1}>
        {customColors.map((color) => <Grid key={`custom-mark-icon-${color}`} item xs={4} md={3}>
          <Box
            display="flex"
            justifyContent={'center'}
            alignItems="center"
            bgcolor={props.mapPath.color === color ? colors.blueGrey[500] : colors.blueGrey[50]}
            padding={2}
            borderRadius={'4px'}
            sx={{ cursor: 'pointer' }}
            onClick={() => props.mapPath.setColor(color)}
          >
            <MapPathCircleIcon color={color} />
          </Box>
        </Grid>)}
      </Grid>)}
    </Box>
  );
});
