import { ApiProperty } from '@nestjs/swagger';
import { IsEnum } from 'class-validator';
import { WEAPON_TYPE } from '../enum';
import { GearManufacturerResponseDto } from './gearManufacturer.response.dto';
export class WeaponResponseDto {
  @ApiProperty()
  public id: string;

  @IsEnum(WEAPON_TYPE)
  @ApiProperty({ enum: WEAPON_TYPE })
  public type: WEAPON_TYPE;

  @ApiProperty({ type: () => GearManufacturerResponseDto})
  public manufacturer?: GearManufacturerResponseDto;

  @ApiProperty()
  public name?: string;
}
