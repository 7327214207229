import { colors } from '@mui/material';
import { PaletteOptions } from '@mui/material';

/**
 * Customization of the palette
 */
declare module '@mui/material/styles/createPalette' {
  interface TypeText {
    tertiary: string;
    entryForm: string;
    info: string;
    details: string;
    hunter_green: string,
  }

  interface CommonColors {
    districtMesh: string;
  }

  interface TypeBackground {
    info: string,
    entry: {
      hover: {
        killing: string;
        sighting: string;
        misc: string;
      },
      view: {
        killing: string;
        sighting: string;
        misc: string;
      }
    },
    poi: {
      hideout: string;
      camera: string;
      feeder: string;
      bait_seat: string;
      bait_seat_meat: string;
      earthwork: string;
      trap: string;
      blind: string;
      nest: string;
    }
  }
}

export const palette: PaletteOptions = {
  background: {
    default: '#F0F0F0',
    paper: '#FFFFFF',
    info: '#F4F6F7',
    entry: {
      hover: {
        killing: 'transparent linear-gradient(180deg, #E6670A 0%, #FDB95B 100%) 0% 0% no-repeat padding-box',
        sighting: 'transparent linear-gradient(180deg, #02654E 0%, #02513F 100%) 0% 0% no-repeat padding-box',
        misc: '#535C68',
      },
      view: {
        killing: '#F59F20',
        sighting: '#0D654E',
        misc: '#535C68',
      }
    },
    poi: {
      hideout: '#4B7BEC',
      camera: '#A15CE6',
      feeder: '#23D97C',
      bait_seat: '#23D77C',
      bait_seat_meat: '#23D77C',
      earthwork: '#EF795D',
      trap: '#EF795D',
      blind: '#0F9197',
      nest: '#0F9197',
    }
  },
  primary: {
    main: '#535C68',
    contrastText: colors.common.white,
  },
  secondary: {
    main: '#0d654e',
  },
  success: {
    main: '#4bae50',
  },
  warning: {
    main: '#EF962F',
  },
  error: {
    main: '#FF0000',
  },
  text: {
    primary: '#535C68',
    secondary: '#39434D',
    tertiary: '#0D654E',
    hunter_green: '#02654E',
    entryForm: '#3B3B3B',
    info: '#545B68',
    details: '#A5B1C2',
  },
  common: {
    districtMesh: '#00F691',
  }
};
