import { inject } from 'inversify';
import { computed, makeObservable, observable, reaction, runInAction } from 'mobx';

import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { WeightsEntryModel } from '../../../../../../domain/model/WeightsEntryModel';
import { I18nService } from '../../../../../../domain/service/I18nService';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { transient } from '../../../../../../inversify/decorator';
import { ENTRY_TYPE } from '../../../../../../shared/enum';
import { WEIGHT_UNIT } from '../../../../../../shared/enum/entryWeightUnit.enum';
import { getEntryWeatherIcon } from '../../../../../../util/WeatherUtil';
import { EntryDetailsProps } from './EntryDetails';

export interface EntryDetailsForm {
  isPrivate: boolean;
  date: Date;
  time: string;
  specificAge: number | null;
  weightAmount: number | undefined;
}

@transient()
export class EntryDetailsVm extends ViewModel<EntryDetailsProps> {

  @observable
  public isPrivateInitialState: boolean = false;

  constructor(
    @inject(I18nService) public readonly i18n: I18nService,
    @inject(SessionStore) public readonly session: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  public override async onInit() {
    runInAction(() => this.isPrivateInitialState = this.props.entry.isPrivate);
    reaction(
      () => this.props.entry.id,
      () => {
        runInAction(() => {
          this.isPrivateInitialState = this.props.entry.isPrivate;
        });
      });
  }

  @computed
  public get showPublicVisibilitySwitch(): boolean {
    return true;
    // if (this.isPrivateInitialState) {
    //   return true;
    // }

    // if (this.props.entry.entryType !== ENTRY_TYPE.KILLING) {
    //   return true;
    // }

    // return false;
  }

  @computed
  public get weatherIcon() {
    if (!this.props.entry.weather) return;
    return getEntryWeatherIcon(this.props.entry.weather?.weatherCode);
  }

  @computed
  public get weightModel(): WeightsEntryModel | undefined {
    return this.props.entry.weights.find((weight: WeightsEntryModel) => weight.unit === this.session.currentWeightUnit);
  }

  @computed
  public get weightUnit(): string {
    const key = this.weightModel?.unit === WEIGHT_UNIT.KILO ? 'entry:view.details.weight_kg' : 'entry:view.details.weight_lb';
    return `${this.i18n.t(key)}`;
  }

  @computed
  public get weightAmount(): number | string {
    return this.weightModel?.amount ?? '';
  }

  @computed
  public get renderWeightAndAge() {
    return this.props.entry.count === 1 && this.props.entry.entryType !== ENTRY_TYPE.MISC;
  }
}
