import { IClassificationButton } from '../../modules/map/components/entry/view-entry/classification/EntryClassificationVm';
import { LANGUAGE } from '../../shared/enum/language.enum';
import { LANGUAGE_REGION } from '../../shared/enum/languageRegion.enum';
import { REGION } from '../../shared/enum/region.enum';
import { translations } from '../../util/classification/data/translations';
import { ClassificationHelper } from './ClassificationHelper';

import {
  IClassification,
  IClassificationNode,
} from './types/classification.types';

export function toLanguageRegion(
  language: LANGUAGE,
  region: REGION
): LANGUAGE_REGION {
  return `${language}_${region}` as LANGUAGE_REGION;
}

// Checks if one classification object matches another one
export const isClassificationEqual = (
  classification1?: IClassification,
  classification2?: IClassification
) => {
  if (!classification1 || !classification2) {
    return false;
  }

  const classificationValues1: string[] = Object.values(classification1);
  const classificationValues2: string[] = Object.values(classification2);

  if (classificationValues1.length > classificationValues2.length) {
    return false;
  }

  for (let i = 0; i < classificationValues1.length; i++) {
    if (
      classificationValues1[i].toUpperCase() !==
      classificationValues2[i].toUpperCase()
    ) {
      return false;
    }
  }

  return true;
};

// Iterates through nodes and returns a matching one
export const getSelectedNode = (
  nodes: IClassificationNode[],
  classificationObject: IClassification
): IClassificationNode | undefined => {
  return nodes.find((node) => {
    return isClassificationEqual(node.classification, classificationObject);
  });
};

export const getKeySorting = (
  classificationObject: IClassification
): string[] => {
  const sortedClassification: string[] = [
    'group',
    'species',
    'age',
    'gender',
    'class',
  ];
  const keysOfClassificationObject = Object.keys(classificationObject);
  for (let i = 0; i < 1; i++) {
    if (keysOfClassificationObject.indexOf(sortedClassification[i]) < 0) {
      sortedClassification.splice(i, 1);
    }
  }

  return sortedClassification;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const flatDeep = (arr: any[], d = 1): any[] => {
  return d > 0
    ? arr.reduce(
      (acc, val) =>
        acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
      []
    )
    : arr.slice();
};

export const shouldAddPreviousValue = (
  availableClasses: IClassificationNode[],
  currentKey: string,
  parentNode: IClassificationNode | undefined
) => {
  if (availableClasses.length === 1) {
    return false;
  }

  const parentNodeKeysLength = parentNode
    ? Object.keys(parentNode.classification).length
    : 0;
  return (
    availableClasses.find((it) => {
      const itKeys = Object.keys(it);
      itKeys.splice(itKeys.indexOf(currentKey + 1), 1);

      return itKeys.length === parentNodeKeysLength;
    }) == null
  );
};

export const getMissingClassificationData = (
  nodes: IClassificationNode[],
  parentNode: IClassificationNode | undefined,
  classificationObject: IClassification
): string[] => {
  const sortedClassificationKeys = getKeySorting(classificationObject);
  const keysOfClassificationObject = Object.keys(classificationObject);

  const reduceResult = sortedClassificationKeys.reduce(
    (previousVal, currentKey, currentIdx) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const prevValue = (classificationObject as any)[
        sortedClassificationKeys[currentIdx - 1]
      ];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentValue = (classificationObject as any)[currentKey];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const nextValue = (classificationObject as any)[
        sortedClassificationKeys[currentIdx + 1]
      ];

      // check if classification key is in this.classificationObject but in the availableClassifications
      // if not add next value (if exists) to result and return
      // if next value does not exist return
      if (
        keysOfClassificationObject.indexOf(currentKey) < 0 &&
        flatDeep(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          previousVal.availableClasses.map((it: any) => it.classification)
        ).indexOf(currentKey) >= 0
      ) {
        if (nextValue != null) {
          previousVal.result.push(nextValue);
          return previousVal;
        } else {
          return previousVal;
        }
      }

      // filter nodeClassObj by value equals this.classificationObject.value
      // if array is empty, check if prev value should be added to result (shouldAddPreValue is called before filter on the availableClassifications)
      // if array is empty, add this.classificationObject.value to result
      // return
      const shouldAddPreValue = shouldAddPreviousValue(
        previousVal.availableClasses,
        currentKey,
        parentNode
      );

      previousVal.availableClasses = previousVal.availableClasses.filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (it: any) =>
          currentValue
            ? currentValue.toUpperCase() === it[currentKey]
            : currentValue === it[currentKey]
      );

      if (previousVal.availableClasses.length === 0) {
        if (shouldAddPreValue) {
          previousVal.result.push(prevValue);
        }

        previousVal.result.push(currentValue);
        return previousVal;
      }

      return previousVal;
    },
    {
      result: [] as string[],
      availableClasses: flatDeep(nodes.map((node) => node.classification)),
    }
  );

  return Object.values(classificationObject).filter(
    (it) => reduceResult.result.indexOf(it) >= 0
  );
};
// best try to find any translation for our current exception case
export const getFallbackTranslations = (name: string) => {
  try {
    let translatedName = translations[name.toUpperCase()];
    if (!translatedName) {
      translatedName = translations[`GROUP_${name.toUpperCase()}`];
    }
    if (!translatedName) {
      translatedName = translations[`AGE_${name.toUpperCase()}`];
    }
    if (!translatedName) {
      translatedName = translations[`GENDER_${name.toUpperCase()}`];
    }
    if (translatedName) {
      return translatedName;
    }
  } catch (_) {
    return name;
  }
  return name;
};

// create a button for the first element of the missing classification data array
export const getAdditionalButton = (
  missingClassificationData: string[],
  classificationHelper: ClassificationHelper
): IClassificationButton | undefined => {
  if (missingClassificationData.length < 1) {
    return;
  }

  const classificationName = missingClassificationData[0];
  const name = getFallbackTranslations(classificationName); // Trying to get a fallback translation

  return {
    name: name as string,
    icon: classificationHelper.getClassificationIconForString(
      classificationName,
      false
    ), // As we are in row1 there is a chance, that we have an icon for this classification
    selected: true,
  };
};

// create a row with one button each for all elements of the missing classification data array
export const getAdditionalRows = (
  missingClassificationData: string[],
  fromIndex: number = 0
): IClassificationButton[][] | undefined => {
  if (missingClassificationData.length < 1) {
    return;
  }

  const rows: IClassificationButton[][] = [];

  missingClassificationData.forEach((missingClassificationName, index) => {
    const classificationName = missingClassificationName;
    const name = getFallbackTranslations(classificationName); // Trying to get a fallback translation

    if (index >= fromIndex) {
      rows.push([
        {
          name: name as string,
          selected: true,
        },
      ]);
    }
  });

  return rows;
};

export const createTree = (
  nodes: IClassificationNode[],
  tree: IClassificationButton[][] = [],
  parentNode: IClassificationNode | undefined,
  classificationObject: IClassification,
  classificationHelper: ClassificationHelper,
  setClassification: (classification: IClassification) => void
): IClassificationButton[][] => {
  // trying to get the selected node within this row. if there is a node
  // selected, we might need to render it`s children within the next row
  const selectedNode = getSelectedNode(nodes, classificationObject);

  // if we need to render more sections with non existing values, we need to render them in the end
  let additionalRows: IClassificationButton[][] | undefined;

  // if this row should render a non existing classification value we need to add that in the end
  let additionalButtonToRender: IClassificationButton | undefined;

  // iterate through all nodes of this row
  const currentRow: IClassificationButton[] = nodes.map((node, rowIndex) => {
    // if this node is selected
    let isSelected = false;

    // if this node is the last in row
    const lastNode = rowIndex >= nodes.length - 1;

    // if there is a selected node, we want to recheck if this is the exact node which is selected
    if (selectedNode) {
      isSelected = isClassificationEqual(
        node.classification,
        selectedNode.classification
      );
    }

    // if this is the last item in the row we are checking
    // if there is missing classification data we need to render
    if (lastNode) {
      // a string array with data of non existing classification values we need to append to our rendering
      const missingClassificationData = getMissingClassificationData(
        nodes,
        parentNode,
        classificationObject
      );

      // if there is nothing selected within this row
      if (!selectedNode) {
        // the first item in the array will be added as a button in this row
        additionalButtonToRender = getAdditionalButton(
          missingClassificationData,
          classificationHelper
        );

        // the remaining values will be added as a row per item
        additionalRows = getAdditionalRows(missingClassificationData, 1);

        // if there is something selected within this row
      } else {
        // the remaining values will attached as a row per item
        additionalRows = getAdditionalRows(missingClassificationData);
      }
    }

    let icon: string | undefined = undefined;

    // If there is only one item in the classification we might have an icon
    if (Object.keys(node.classification).length === 1) {
      icon = classificationHelper.getClassificationIcon(
        node.classification,
        isSelected ? false : true
      );
    }

    return {
      name: node.name,
      selected: isSelected,
      icon,
      callback: () => {
        if (isSelected) {
          setClassification(parentNode?.classification ?? {});
        } else {
          setClassification(node.classification);
        }
      },
    };
  });

  // if we have an additional button we are appending it to the row
  if (additionalButtonToRender) {
    currentRow.unshift(additionalButtonToRender);
  }

  // append the current row to the tree
  tree.push(currentRow);

  // if there is a selected node in this row and the selected node has children, we need to render the children
  if (selectedNode && selectedNode.children) {
    return createTree(
      selectedNode.children,
      tree,
      selectedNode,
      classificationObject,
      classificationHelper,
      setClassification
    );
  }

  // if we have further additional rows coming from missing classification values, we are rendering them as well
  if (additionalRows) {
    tree = [...tree, ...additionalRows];
  }

  return tree;
};
