import { observer } from 'mobx-react-lite';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, Grid, IconButton, Input, Typography } from '@mui/material';

import { useVm } from '../../../../../domain/hooks/useVm';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { OptionalTooltip } from '../../../../../toolkit/components/OptionalTooltip';
import { CustomizedMenu } from '../../entry/view-entry/components/CustomizedMenu';
import { MapPathAttributedTo } from './attributed-to/MapPathAttributedTo';
import { MapPathPointsSelection } from './MapPathPointsSelection';
import { ProMapPath } from './ProMapPath';
import { MapPathTypeSelection } from './type/MapPathTypeSelection';
import { IMapPathTab, MapPathTabType, ViewMapPathProps, ViewMapPathVm } from './ViewMapPathVm';

export const ViewMapPath = observer(function ViewMapPath(props: ViewMapPathProps) {
  const vm = useVm(ViewMapPathVm, props);
  const { t } = useTranslation();

  const belongingDistrict = useMemo(() => {
    return props.districtsVm.allDistricts.find(d => d.id === props.mapPath.districtId);
  }, [props.districtsVm.allDistricts, props.mapPath.districtId]);

  const onPathDelete = useCallback(() => {
    props.onDelete(props.mapPath);
  }, [props]);

  const onPathUpsert = useCallback(() => {
    props.upsertPath(props.mapPath);
  }, [props]);

  const handleOnChangeInput = useCallback((e) => {
    if (!vm.canEditAndDeleteMapPath) {
      return vm.notification.info(t('paths:wrong_permission'));
    }
    props.mapPath.setTitle(e.target.value);
  }, [props.mapPath, t, vm.canEditAndDeleteMapPath, vm.notification]);

  if (!vm.hasProPaths) {
    return <ProMapPath
      onClose={props.onClose}
      label={props.mapPath.id
        ? t('paths:path_label')
        : t('paths:create_path_label')
      }
    />;
  }

  return (
    <Box height="inherit" display="flex" flexDirection="column" position={'relative'}>
      <Box sx={{ overflowY: 'auto', paddingBottom: '70px' }}>
        {/* HEADER */}
        <Grid container direction="column" justifyContent="center"
          sx={{
            background: vm.backgroundColor,
            color: colors.paper,
            paddingX: 2,
            paddingY: 1,
          }}
        >
          <Grid item container direction={'row'} justifyContent={'flex-end'} paddingLeft={4}>
            <IconButton color="inherit" onClick={props.onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item container paddingX={2} marginTop={3} paddingBottom={1}>
            <Grid item xs={11}>
              <Input
                fullWidth
                value={props.mapPath.title}
                onChange={handleOnChangeInput}
                placeholder={t('paths:title_placeholder')}
                disableUnderline
                autoFocus
                readOnly={vm.currentTab.type === MapPathTabType.TYPE}
                sx={{
                  fontSize: '24px',
                  color: 'white',
                  fontWeight: '600',
                  borderBottom: `1px solid ${vm.currentTab.type !== MapPathTabType.TYPE ? 'white' : 'transparent'}`,
                }}
              />
              <Typography variant='caption'>{belongingDistrict?.name}</Typography>
            </Grid>
            {props.mapPath.id && <Grid item xs={1} alignSelf={'center'}>
              <CustomizedMenu
                locizeNamespace="paths"
                onDelete={onPathDelete}
                onUpsert={onPathUpsert}
                item={props.mapPath}
                canEditAndDeleteMapPath={vm.canEditAndDeleteMapPath}
              />
            </Grid>}
          </Grid>
          {/* MENU */}
        </Grid>
        {/* TABS */}
        <Grid
          container
          alignItems="center"
          spacing={1}
          paddingX={2}
          paddingY={2}
          sx={{
            maxWidth: 'inherit',
            boxShadow: '0 8px 3px -6px #00000033',
          }}>
          {vm.tabs.map((tab: IMapPathTab) => {
            return (
              <Grid item key={`map-path-tab-${tab.type}`}>
                <Chip
                  clickable
                  variant="outlined"
                  label={t(tab.type)}
                  onClick={() => vm.setCurrentTab(tab)}
                  style={vm.currentTab.type === tab.type
                    ? {
                      borderColor: '#02654E',
                      color: '#02654E',
                      backgroundColor: '#DFF1EE',
                    }
                    : {}} />
              </Grid>
            );
          })}
        </Grid>
        <Grid container>
          {vm.currentTab.type === MapPathTabType.TYPE && <MapPathTypeSelection
            types={props.types}
            mapPath={props.mapPath}
            canEditAndDeleteMapPath={vm.canEditAndDeleteMapPath}
          />}
          {vm.currentTab.type === MapPathTabType.PATH && <MapPathPointsSelection />}
          {vm.currentTab.type === MapPathTabType.ATTRIBUTED_TO && <MapPathAttributedTo
            mapPath={props.mapPath}
            districts={props.districtsVm.allDistricts}
            onMapPathSave={props.onSave.run}
            onSelectDistrict={props.districtsVm.setSelectedDistrict}
            canEditAndDeleteMapPath={vm.canEditAndDeleteMapPath}
          />}
        </Grid>
      </Box>
      <Box
        display="flex"
        justifyContent={'center'}
        alignItems={'center'}
        position={'absolute'}
        bottom={0}
        bgcolor="white"
        width="100%"
        height={'70px'}
        borderTop={1}
        borderColor={'text.details'}
        paddingX={2}
      >
        <Button onClick={props.onClose} sx={{ paddingX: 7, paddingY: 1, marginRight: 4, }} variant="outlined" color="error">
          <Typography variant="subtitle1">{t('entry:entry_cancel')}</Typography>
        </Button>
        <OptionalTooltip
          enabled={!vm.canBeSaved}
          title={<Typography
            bgcolor={'primary.main'}
            color="white"
            textAlign={'center'}
            borderRadius={1}
            paddingX={2}
            paddingY={1}
          >
            {vm.canEditAndDeleteMapPath ? t('paths:path_data_incomplete') : t('paths:wrong_permission')}
          </Typography>}
        >
          <span>
            <LoadingButton
              loading={props.onSave.isBusy}
              disabled={vm.currentTab.type === MapPathTabType.PATH && !vm.canBeSaved}
              onClick={vm.nextOrSave}
              sx={{ paddingX: 7, paddingY: 1, }} variant="contained" color="secondary"
            >
              <Typography variant="subtitle1">
                {(vm.hasNextTab) ? t('entry:entry_next_tab') : t('entry:entry_save')}
              </Typography>
            </LoadingButton>
          </span>
        </OptionalTooltip>
      </Box>
    </Box>
  );
});
