import { Divider } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PoiModel } from '../../../../../../domain/model/PoiModel';

interface IProps {
  poi: PoiModel;
}

export const PoiNotes = observer(function PoiNotes(props: IProps) {
  const { t } = useTranslation();

  return (
    <Box width={'100%'}>
      <Box paddingY={2} paddingX={2}>
        <textarea
          placeholder={t('poi:new_poi_description_placeholder')}
          rows={5}
          style={{
            width: '100%',
            borderStyle: 'none',
            outline: 'none',
            fontSize: '15px',
            fontWeight: '600',
            color: '#545B68',
          }}
          value={props.poi.description}
          onChange={(e) => props.poi.setDescription(e.target.value)}
        />
      </Box>
      <Divider />
    </Box>
  );
});
