import React, { useCallback } from 'react';
import { useLightboxState } from 'yet-another-react-lightbox';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box } from '@mui/material';

import { DeleteDialog, DeleteDialogRef } from '../DeleteDialog';

interface DeleteImageButtonProps {
  canDeleteImage: boolean;
  onDelete: () => Promise<boolean>;
}

export const DeleteImageButton = function DeleteImageButton(props: DeleteImageButtonProps) {

  const deletePhotoDialogRef: React.RefObject<DeleteDialogRef> = React.createRef();

  const { slides } = useLightboxState();

  const onDelete = useCallback(() => {
    props.onDelete().then((success) => {
      if (success) {
        if (slides.length - 1 === 0) {
          // * fix for: updating React state of unmounted component
          return;
        }
      }
    });
  }, [props, slides.length]);

  return (
    <React.Fragment>
      <Box key={'delete-icon'}
        sx={{
          padding: '2px',
          mt: 1,
          textAlign: 'center',
          display: props.canDeleteImage ? 'auto' : 'none',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (!props.canDeleteImage) {
            return;
          }
          deletePhotoDialogRef.current?.handleOpenDeleteDialog();
        }}
      >
        <DeleteForeverIcon fontSize='large' htmlColor='white' />
      </Box>
      <DeleteDialog
        ref={deletePhotoDialogRef}
        locizeNamespace="photo"
        onDelete={onDelete}
      />
    </React.Fragment>
  );
};
