import { inject } from 'inversify';
import { computed, makeObservable, observable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import { ViewModel } from '../../../domain/core/ViewModel';
import { AuthProxy } from '../../../domain/proxy/AuthProxy';
import { I18nService } from '../../../domain/service/I18nService';
import { NotificationService } from '../../../domain/service/NotificationService';
import { TrackingEvent } from '../../../domain/service/tracking/TrackingEvent';
import { RegistrationVia } from '../../../domain/service/tracking/TrackingEventParams';
import { TrackingService } from '../../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { transient } from '../../../inversify/decorator';
import { Types } from '../../../inversify/types';
import { AppRoutes } from '../../../router/AppRoutesEnum';
import { UserPostLoginRequestDto } from '../../../shared/dto';

export interface LoginRouteProps {
  fromLocation: string;
}

@transient()
export class LoginRouteVm extends ViewModel<LoginRouteProps> {

  @observable
  public isLoggedIn: boolean = false;

  constructor(
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(AuthProxy) private readonly authProxy: AuthProxy,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(SessionStore) public readonly session: SessionStore,
    @inject(Types.Navigate) public readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get fromLocation(): string {
    const existingRoute = Object.values(AppRoutes).find(val => val === this.props.fromLocation);
    return existingRoute ?? AppRoutes.Map;
  }

  @computed
  public get isBusy(): boolean {
    return this.authProxy.login.isBusy;
  }

  public goToPasswordReset = () => {
    this.navigate(AppRoutes.ForgotPassword);
  };

  public login = async (data: UserPostLoginRequestDto) => {
    try {
      await this.tracking.track(TrackingEvent.LOGIN_STARTED, {
        registrationType: RegistrationVia.EMAIL,
      });

      const { email, password }: UserPostLoginRequestDto = data;
      const result = await this.session.login({
        email,
        password,
      });

      if (result.ok) {
        await this.tracking.track(TrackingEvent.LOGIN_COMPLETED, { registrationType: RegistrationVia.EMAIL });

        return this.navigate(this.fromLocation);
      }

      this.notification.warning(this.i18n.t('auth:login_credentials_warning'));
    } catch (e) {
      console.error(`exception while doing login. ${e}`);
      this.notification.error(this.i18n.t('auth:login_error'));
    }
  };

  public logout = async () => {
    try {
      const result = await this.session.logout();
      if (result.ok) {
        return this.navigate(AppRoutes.Login);
      }

      this.notification.error(this.i18n.t('auth:logout_error'));
    } catch (e) {
      console.error(`exception while doing logout. ${e}`);
      this.notification.error(this.i18n.t('auth:logout_error'));
    }
  };
}
