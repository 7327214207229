import { inject } from 'inversify';

import { singleton } from '../../inversify/decorator';
import { AsyncTask } from '../async/AsyncTask';
import { IGeoLocation } from '../core/IGeoLocation';
import { UserProxy } from '../proxy/UserProxy';
import { SessionStore } from '../store/SessionStore';
import { env } from '../../env';

@singleton()
export class GeolocationService {

  public readonly defaultLocation = {
    location: {
      latitude: 48.14526901343563,
      longitude: 11.513682061214583,
    },
    zoom: 6,
  }

  public lastLocation: IGeoLocation | null = null;

  constructor(
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(UserProxy) private readonly userProxy: UserProxy,
  ) {
  }

  public getCurrentLocation = (useCached: boolean): Promise<IGeoLocation> => {
    if (useCached && this.lastLocation) {
      return Promise.resolve(this.lastLocation);
    }

    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        this.lastLocation = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };

        return resolve(this.lastLocation);
      }, (error: GeolocationPositionError) => {
        console.warn(`navigator geolocation not resolved. ${error.code}`);

        return this.findLocationByIp.run()
          .then(resolve)
          .catch(reject);
      }, { timeout: 2000, maximumAge: Infinity });
    });
  }

  private findLocationByIp = new AsyncTask(async (): Promise<IGeoLocation> => {
    try {
      if (env.isDev) {
        return this.defaultLocation.location;
      }

      const result = await this.userProxy.getUserLocation(this.session.userId);
      if (result.ok) {
        const { latitude, longitude } = result.data.location;
        this.lastLocation = {
          latitude,
          longitude,
        };

        return this.lastLocation;
      }

      return Promise.reject();
    } catch (e) {
      return Promise.reject();
    }
  })
}
