import { Button, ButtonProps, Input, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback } from 'react';

interface IProps {
  value: number;
  onChange: (value: number) => void;
  disabled: boolean;
}

function CounterButton(props: ButtonProps & { text: string }) {
  const { text, ...buttonProps } = props;

  return (
    <Button
      variant="contained"
      sx={{
        borderRadius: '4px',
        boxShadow: 0,
        color: 'text.entryForm',
        backgroundColor: 'background.info',
        '&:hover': {
          backgroundColor: 'background.info',
          boxShadow: 0,
        },
        '&.Mui-disabled': {
          backgroundColor: 'background.info',
        }
      }}
      {...buttonProps}
    >
      <Typography fontWeight={'bold'}>{text}</Typography>
    </Button>
  );
}

export function Counter(props: IProps) {

  const up = useCallback(() => {
    props.onChange(props.value + 1);
  }, [props]);

  const down = useCallback(() => {
    props.onChange(props.value - 1);
  }, [props]);

  const setCounter = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(parseInt(e.target.value));
  }, [props]);

  return (
    <Box display="flex" flexDirection={'row'} width="100%">
      <CounterButton disabled={props.disabled} onClick={down} text={'-'} />
      <Input
        disableUnderline
        componentsProps={{ input: { style: { textAlign: 'center' } } }}
        fullWidth
        disabled={props.disabled}
        value={props.value}
        onChange={setCounter}
        type="number"
        sx={{
          backgroundColor: 'background.info',
          borderRadius: '4px',
          marginX: 1,
          fontWeight: 'bold',
        }}
      />
      <CounterButton disabled={props.disabled} onClick={up} text={'+'} />
    </Box>
  );
}
