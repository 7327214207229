import { Grid } from '@mui/material';

import { Assets } from './assets';

export function FitContainerLoadingFallback() {

  return (
    <Grid container height={'100%'} justifyContent="center" alignItems="center">
      <Grid item zeroMinWidth>
        <img width={'80px'} src={Assets.loading_logo} />
      </Grid>
    </Grid>
  );
}
