import { inject } from 'inversify';
import { action, makeObservable } from 'mobx';
import { NavigateFunction } from 'react-router-dom';

import { ViewModel } from '../../../domain/core/ViewModel';
import { TrackingEvent } from '../../../domain/service/tracking/TrackingEvent';
import { RegistrationVia } from '../../../domain/service/tracking/TrackingEventParams';
import { TrackingService } from '../../../domain/service/tracking/TrackingService';
import { transient } from '../../../inversify/decorator';
import { Types } from '../../../inversify/types';
import { AppRoutes } from '../../../router/AppRoutesEnum';
import { IOAuthUser } from './components/social-media-registration/SocialMediaRegistrationRoute';

@transient()
export class RegistrationRouteVm extends ViewModel {

  constructor(
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(Types.Navigate) private readonly navigate: NavigateFunction,
  ) {
    super();
    makeObservable(this);
  }

  @action
  public onLoginClick(): void {
    this.navigate(AppRoutes.Login);
  }

  @action
  public onFacebookLogin = (data: IOAuthUser) => {
    this.navigate(AppRoutes.SocialMediaRegister, { state: data });
  }

  @action
  public onAppleLogin = (data: IOAuthUser) => {
    this.navigate(AppRoutes.SocialMediaRegister, { state: data });
  }

  @action
  public onRegisterClick = async () => {
    await this.tracking.track(TrackingEvent.REGISTRATION_STARTED, { registrationType: RegistrationVia.EMAIL });
    this.navigate(AppRoutes.EmailRegister);
  }

}
