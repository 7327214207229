import { ApiProperty } from '@nestjs/swagger';
import { IsIn, IsUUID } from 'class-validator';
import { ROLE } from '../enum';

export class GroupChangeRolePatchRequestDto {

  @IsUUID()
  @ApiProperty()
  public userId: string;

  @IsIn([ROLE.GROUP.ADMIN, ROLE.GROUP.USER])
  @ApiProperty({ example: ROLE.GROUP.ADMIN })
  public role: string;
}
