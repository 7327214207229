import { Age } from '../classification/types/age.types';
import { IClassification } from '../classification/types/classification.types';
import { Gender, GenderValues } from '../classification/types/gender.types';

export interface ICanFoldResult {
  result: boolean;
  translation: string | undefined;
}

export type Translation = { [key: string]: string | undefined };
export type TranslationsMap = { [language: string]: Translation | undefined };

export class Translations {
  private _map: TranslationsMap;
  private language: string = 'EN';
  private fallbackLanguage: string = 'EN';

  public constructor(map: TranslationsMap) {
    this._map = map;
  }

  public setTranslations(map: TranslationsMap) {
    this._map = map;
  }

  public setLanguage(language: string) {
    this.language = language;
  }

  public setFallbackLanguage(language: string) {
    this.fallbackLanguage = language;
  }

  public getMap(): TranslationsMap {
    return this._map;
  }

  public getTranslationForLanguage(language: string): Translation | undefined {
    return this._map[language];
  }

  public canTranslate(key: string): boolean {
    return this.translateUsingLanguage(key, this.language) !== undefined;
  }

  public hasFallback(key: string): boolean {
    return this.translateUsingLanguage(key, this.fallbackLanguage) !== undefined;
  }

  public missingKey(key: string): string {
    return `MISSING_${this.language}: ${key}`;
  }

  public canTranslateWithFallback(key: string): boolean {
    return this.canTranslate(key) || this.hasFallback(key);
  }

  public maybeTranslate(key: string): string | undefined {
    return this.maybeTranslateWithoutFallback(key) || this.translateUsingLanguage(key, this.fallbackLanguage);
  }

  public maybeTranslateWithoutFallback(key: string): string | undefined {
    return this.translateUsingLanguage(key, this.language);
  }

  public translate(key: string): string {
    return this.maybeTranslate(key) || this.missingKey(key);
  }

  public translateWithoutFallback(key: string): string {
    return this.maybeTranslateWithoutFallback(key) || this.missingKey(key);
  }

  public translateSpeciesAge(species: string, age: string): string {
    return this.maybeTranslate(Translations.getCombinedKey({ species, age })) || this.translate(Translations.getKeyForAge(age));
  }

  public maybeTranslateShort(species: string, age: string, gender: string): string | undefined {
    return this.maybeTranslateWithoutFallback(Translations.getCombinedKey({ species, age, gender }));
  }

  public static getKeyForSpecies(species: string): string {
    return `SPECIES_${species}`;
  }

  public static getKeyForGroup(otherGroup: string): string {
    return `GROUP_${otherGroup}`;
  }

  public static getKeyForAge(age: string): string {
    return `AGE_${age}`;
  }

  public static getKeyForGender(gender: string): string {
    return `GENDER_${gender}`;
  }

  public static getKeyForSpecial(gender: string): string {
    return `SPECIAL_${gender}`;
  }

  public static getKeyForClass(clazz: string): string {
    return `CLASS_${clazz}`;
  }

  public static getKeyForEntry(entry: string): string {
    return `ENTRY_${entry}`;
  }

  public static getKeyForRegion(gender: string): string {
    return `REGION_${gender}`;
  }

  public static getCombinedKey(classification: {
    species: string,
    age?: string,
    gender?: string
  }): string {
    return this.buildCombinedKey({
      species: Translations.getKeyForSpecies(classification.species),
      age: classification.age ? Translations.getKeyForAge(classification.age) : undefined,
      gender: classification.gender ? Translations.getKeyForGender(classification.gender) : undefined
    });
  }

  public static buildCombinedKey(classification: {
    species: string,
    age?: string,
    gender?: string
  }): string {
    let key = classification.species;
    if (classification.age) {
      key += `.${classification.age}`;
    }
    if (classification.gender) {
      key += `.${classification.gender}`;
    }
    return key;
  }

  public canFold(classification: IClassification): ICanFoldResult {
    const species = classification.species;
    const age = classification.age;
    const gender = classification.gender;

    if (!species || !age || !gender) {
      return {
        result: false,
        translation: undefined
      };
    }

    // Folding always uses the original language only, never the fallback.
    const translation = this.maybeTranslateWithoutFallback(Translations.getCombinedKey({ species, age, gender }));
    if (translation) {
      return {
        result: true,
        translation
      };
    } else {
      return {
        result: false,
        translation: undefined
      };
    }
  }

  /**
   * Translates the highest part of the given classification (Class > Gender > Age > Species > Other Group).
   * @param classification The classification to translate
   * @param translations The translations object
   */
  public translateClassification(classification: IClassification): string {
    if (classification.class) {
      return this.translate(Translations.getKeyForClass(classification.class));
    }

    if (classification.gender) {
      return this.translate(Translations.getKeyForGender(classification.gender));
    }

    if (classification.age) {
      return this.translate(Translations.getKeyForAge(classification.age));
    }

    if (classification.species) {
      return this.translate(Translations.getKeyForSpecies(classification.species));
    }

    if (classification.group) {
      return this.translate(Translations.getKeyForGroup(classification.group));
    }

    return 'Missing translation for classification: ' + JSON.stringify(classification);
  }

  public translateFullClassification(classification: IClassification): string | undefined {
    const parts: string[] = [];

    if (classification.group) {
      this.pushIfDefined(parts, this.translate(Translations.getKeyForGroup(classification.group)));
    }

    if (classification.species) {
      this.pushIfDefined(parts, this.translate(Translations.getKeyForSpecies(classification.species)));
    }

    const foldResult = this.canFold(classification);

    if (foldResult.result) {
      this.pushIfDefined(parts, foldResult.translation);
    } else {
      if (classification.age) {
        this.pushIfDefined(parts, this.translate(Translations.getKeyForAge(classification.age)));
      }
      if (classification.gender) {
        this.pushIfDefined(parts, this.translate(Translations.getKeyForGender(classification.gender)));
      }
    }

    if (classification.class) {
      this.pushIfDefined(parts, this.translate(Translations.getKeyForClass(classification.class)));
    }

    return parts.length > 0 ? parts.join(' ') : undefined;
  }

  public translateShortClassification(classification: IClassification): string | undefined {

    if (classification.group && !classification.species) {
      return this.translate(Translations.getKeyForGroup(classification.group));
    }

    if (classification.species) {
      return this.translate(Translations.getKeyForSpecies(classification.species));
    }

    return undefined;
  }

  private pushIfDefined<T>(array: T[], item: T | undefined) {
    if (item) {
      array.push(item);
    }
  }

  private translateUsingLanguage(key: string, language: string): string | undefined {
    const translation = this._map[language];
    if (translation != null) {
      return translation[key];
    }
    return undefined;
  }

}

export function translateGendersForAge(translations: Translations, species: string, age: Age): { [x in keyof typeof Gender]: string | undefined } {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any = {};
  GenderValues.forEach((gender) => {
    const foldResult = translations.canFold({ species, age, gender });
    result[gender] = foldResult.translation;
  });
  return result;
}
