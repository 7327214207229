import { Box, List } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Assets } from '../../../../../assets';
import { useInstance } from '../../../../../domain/hooks/useInstance';
import { PoiModel } from '../../../../../domain/model/PoiModel';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { FEATURE } from '../../../../../shared/enum';
import { poiUIModels } from '../../../../../util/PoiUIModels';
import { PoiFilter } from '../../../filters/PoiFilter';
import { Marker } from '../../Marker';
import { FilterListItem } from '../components/FilterListItem';
import { NoEntitiesToFilter } from '../components/NoEntitiesToFilter';
import { SelectDeselectAll } from '../components/SelectDeselectAll';

interface IProps {
  poiFilter: PoiFilter;
}

export const FilterSidebarPois = observer(function FilterSidebarPois(props: IProps) {
  const { t } = useTranslation();
  const hasCustomMarksEnabled = useInstance(SessionStore).hasFeatureEnabled(FEATURE.CUSTOM_MARKS);

  return (
    <Box mx={3} my={1}>
      <Box display="flex" justifyContent={'flex-end'}>
        <SelectDeselectAll filter={props.poiFilter} />
      </Box>
      {props.poiFilter.conditions.length > 0
        ? <List>
          {props.poiFilter.conditions.map((condition) => {
            const color = hasCustomMarksEnabled
              ? condition.customMark?.background
              : undefined;

            return (
              <FilterListItem
                key={`poi-condition-${condition.id}`}
                iconUrl={!condition.customMark
                  ? PoiModel.typeToIcon(condition.type)
                  : !hasCustomMarksEnabled
                    ? Assets.poi.custom_pro
                    : undefined}
                onSelectOnlyThis={() => props.poiFilter.selectOnlyCondition(condition)}
                icon={(condition.customMark && hasCustomMarksEnabled) ? <Marker
                  lat={undefined}
                  lng={undefined}
                  icon={undefined}
                  color={color ?? undefined}
                  skiptransform={true}
                  width={35}
                >
                  <img style={{
                    position: 'absolute',
                    top: '22%',
                    width: '14px',
                  }}
                    src={condition.customMark.foregroundIcon}
                  />
                </Marker> : undefined}
                title={condition.title || (condition.type ? t(poiUIModels[condition.type!]?.i18nKey) : '')}
                checked={condition.active}
                onClick={() => props.poiFilter.toggle(condition)}
              />
            );
          })}
        </List>
        : <NoEntitiesToFilter text={t('filter:no_pois_to_filter')} />}
    </Box>
  );
});
