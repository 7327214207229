import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';

import { AsyncTask } from '../../../../../domain/async/AsyncTask';
import { ViewModel } from '../../../../../domain/core/ViewModel';
import { SubzoneModel } from '../../../../../domain/model/SubzoneModel';
import { SessionStore } from '../../../../../domain/store/SessionStore';
import { transient } from '../../../../../inversify/decorator';
import { FEATURE } from '../../../../../shared/enum';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { MapDistrictsVm } from '../../../MapDistrictsVm';
import { NotificationService } from '../../../../../domain/service/NotificationService';
import { I18nService } from '../../../../../domain/service/I18nService';

export enum SubzoneTabType {
  TYPE = 'tab_type',
  BORDERS = 'tab_borders',
  INFO = 'tab_info',
  ATTRIBUTED_TO = 'tab_attributed_to',
}

export interface ISubzoneTab {
  type: SubzoneTabType;
}

export interface ViewSubzoneProps {
  subzone: SubzoneModel;
  onTabChange: (tab: SubzoneTabType) => void;
  onClose: () => void;
  onDelete: (subzone: SubzoneModel) => void;
  upsertSubzone: (subzone: SubzoneModel) => void;
  onSave: AsyncTask<() => Promise<boolean>>;
  districtsVm: MapDistrictsVm;
}

@transient()
export class ViewSubzoneVm extends ViewModel<ViewSubzoneProps> {

  @observable
  public tabs: ISubzoneTab[] = [{
    type: SubzoneTabType.TYPE,
  }, {
    type: SubzoneTabType.BORDERS,
  }, {
    type: SubzoneTabType.INFO,
  }, {
    type: SubzoneTabType.ATTRIBUTED_TO,
  }];

  @observable
  public currentTab: ISubzoneTab = this.tabs[0];

  constructor(
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(NotificationService) public readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get hasProSubzones() {
    return this.session.hasFeatureEnabled(FEATURE.SUBZONES);
  }

  @computed
  public get canBeSaved() {
    const subzone = this.props.subzone;

    return subzone.mesh.points.length > 2
      && !!subzone.title
      && subzone.subzoneType
      && this.canEditAndDeleteSubzone;
  }

  @computed
  public get canEditAndDeleteSubzone(): boolean {
    return this.props.subzone.owner?.id === this.session.currentUser?.id
      ? true
      : Boolean(this.props.districtsVm.selectedDistrict?.canEditAndDeleteSubzone);
  }

  @computed
  public get backgroundColor() {
    return colors.blueGrey[500];
  }

  @computed
  public get hasNextTab() {
    return this.currentTab.type === SubzoneTabType.TYPE;
  }

  @action
  public setCurrentTab = (tab: ISubzoneTab) => {
    if (!this.canEditAndDeleteSubzone && tab.type === SubzoneTabType.BORDERS) {
      return this.notification.info(this.i18n.t('subzone:wrong_permission'));
    }
    this.currentTab = tab;
    this.props.onTabChange(tab.type);
  }

  @action
  public nextOrSave = () => {
    if (!this.hasNextTab) {
      return this.props.onSave.run();
    }

    const index = this.tabs.findIndex((t) => t.type === this.currentTab.type);
    if (index !== -1) {
      this.setCurrentTab(this.tabs[index + 1]);
    }
  }
}
