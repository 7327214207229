import { ApiProperty } from '@nestjs/swagger';

import { GroupMiniDto } from './group.mini.dto';
import { PhotoResponseDto } from './photo.response.dto';
import { UserResponseDto } from './user.response.dto';

export class PostResponseDto {

  @ApiProperty()
  public id: string;

  @ApiProperty()
  public meta: any;

  @ApiProperty({ required: false })
  public description: string | null;

  @ApiProperty({ type: () => GroupMiniDto, isArray: true })
  public groups: GroupMiniDto[];

  @ApiProperty({ type: () => UserResponseDto })
  public owner: UserResponseDto;

  @ApiProperty({ required: false })
  public lat: number | null;

  @ApiProperty({ required: false })
  public long: number | null;

  @ApiProperty()
  public type: string;

  @ApiProperty({ type: () => PhotoResponseDto, required: false })
  public photo?: PhotoResponseDto;

  @ApiProperty({ type: () => PhotoResponseDto, isArray: true })
  public photos: PhotoResponseDto[];

  @ApiProperty({ example: '2019-02-13T14:23:17.683Z' })
  public createdAt: string; // ISO string
}
