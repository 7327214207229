import { ApiProperty } from '@nestjs/swagger';
import { IsOptional } from 'class-validator';

import { ENTRY_TYPE } from '../enum';
import { REGION } from '../enum/region.enum';
import { ClassificationObjectResponseDto } from './classificationObject.response.dto';
import { DiseaseResponseDto } from './disease.response.dto';
import { PhotoResponseDto } from './photo.response.dto';
import { ShotResponseDto } from './shot/shot.response.dto';
import { UserResponseDto } from './user.response.dto';
import { WeatherRealtimeResponseDto } from './weather';
import { WeightsEntryResponseDto } from './weights.entry.response.dto';

export class EntryResponseDto {

  @ApiProperty()
  public id: string;

  @ApiProperty()
  public isPrivate: boolean;

  @ApiProperty()
  public type: string;

  @ApiProperty()
  public lat: number | null;

  @ApiProperty()
  public long: number | null;

  @ApiProperty()
  public classification: string;

  @ApiProperty()
  public fallwildType: string | null;

  @ApiProperty()
  public entryType: ENTRY_TYPE;

  @ApiProperty()
  public classificationObject?: ClassificationObjectResponseDto;

  @ApiProperty()
  public region?: REGION;

  @ApiProperty()
  public count: number;

  @ApiProperty({ type: () => UserResponseDto })
  public owner: UserResponseDto;

  @ApiProperty()
  public districtId?: string;

  @ApiProperty()
  public eventId?: string;

  @ApiProperty({ type: () => PhotoResponseDto })
  public photo?: PhotoResponseDto;

  @ApiProperty({ type: () => PhotoResponseDto, isArray: true })
  public photos: PhotoResponseDto[];

  @ApiProperty()
  public description?: string;

  @ApiProperty({ type: () => WeatherRealtimeResponseDto })
  public weather?: WeatherRealtimeResponseDto;

  @ApiProperty({ description: 'Date as ISO string' })
  public userDate: string;

  @ApiProperty({ description: 'Date as ISO string' })
  public createdAt: string;

  @ApiProperty({ description: 'Date as ISO string' })
  public updateAt?: string;

  @ApiProperty()
  public specificAge?: number | null;

  @ApiProperty({ description: 'weight of the entry, specified in kilograms and punds. If count is larger than 1, weights are set to null' })
  public weights: WeightsEntryResponseDto[];

  @IsOptional()
  @ApiProperty()
  public shot?: ShotResponseDto | null;

  @IsOptional()
  @ApiProperty()
  public disease?: DiseaseResponseDto | null;
}
