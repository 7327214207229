import axios from 'axios';
import { inject } from 'inversify';
import { env } from '../../env';
import { singleton } from '../../inversify/decorator';
import { LANGUAGE } from '../../shared/enum/language.enum';
import { AjaxService } from '../service/AjaxService';
import { Proxy } from './Proxy';

export interface IMapBoxSearchResultFeature {
  center: number[],
  text: string;
  place_name: string,
  matching_text: string,
  matching_place_name: string,
  bbox: number[],
  context?: {
    id: string,
    wikidata: string,
    text: string,
    short_code?: string
  }[]
}

export interface IMapBoxSearchResult {
  type: string;
  query: string[];
  features: IMapBoxSearchResultFeature[];
}

@singleton()
export class MapboxProxy extends Proxy {
  constructor(
    @inject(AjaxService) private readonly ajax: AjaxService,
  ) {
    super();
  }

  public locationSearch = async (term: string, language?: LANGUAGE): Promise<IMapBoxSearchResult> => {

    const languageParam = language ? `&language=${language}` : '';
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURI(term)}.json?access_token=${env.mapbox.key}${languageParam}&limit=10`;

    const result = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (result.status === 200) {
      return result.data as IMapBoxSearchResult;
    }

    return Promise.reject('Error while running location autocomplete');
  }
}
