import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { TrackingEvent } from '../../../domain/service/tracking/TrackingEvent';
import { ModalWrapper } from '../../../toolkit/components/ModalWrapper';

const subscriptionInfo: string[] = [
  'subscription_info_modal.bullet_1',
  'subscription_info_modal.bullet_2',
  'subscription_info_modal.bullet_3',
  'subscription_info_modal.bullet_4',
  'subscription_info_modal.bullet_5',
  'subscription_info_modal.bullet_6',
];

export function SubscriptionInfoModal() {

  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <ModalWrapper
      btnLocizeKey="payment:subscription_info_modal.button"
      event={TrackingEvent.TERMS_VIEW}
    >
      <Box position="absolute"
        sx={{
          backgroundColor: theme.palette.background.paper,
          border: '1px solid #707070',
          borderRadius: '20px',
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
          overflow: 'auto',
          maxWidth: '70%',
          '@media only screen and (max-width: 1025px)': {
            maxWidth: '95%',
          },
          maxHeight: '95%',
          '@media only screen and (max-height: 1000px)': {
            maxHeight: '80%',
          },
        }}
      >
        <Grid container marginLeft={{ md: 2 }} width="100%">
          <Grid item container justifyContent="center" paddingTop={5} paddingBottom={2}>
            <Typography variant="h1" color="#000">{t('payment:subscription_info_modal.title')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <ul>
              {subscriptionInfo.map((value: string) => (
                <div key={value}>
                  <li>
                    <Typography variant="h4"
                      paddingY={3}
                      sx={{
                        color: '#000',
                        fontWeight: 'normal',
                        fontSize: '18px',
                        letterSpacing: '0.24px',
                        lineHeight: '24px',
                      }}
                    >
                      {t(`payment:${value}`)}
                    </Typography>
                  </li>
                  <Divider />
                </div>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} marginLeft={{ md: 5 }} paddingY={3}>
            <Typography variant="h4"
              sx={{
                color: '#000',
                fontWeight: 'normal',
                fontSize: '18px',
                letterSpacing: '0.24px',
                lineHeight: '24px',
              }}
            >
              {t('payment:subscription_info_modal.legal_1')} <a target="blank" href={t('links:terms-of-use')}>{t('payment:subscription_info_modal.legal_2')}</a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </ModalWrapper>
  );
}
