import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import React from 'react';

import { FileValidated } from '@dropzone-ui/react';

import { AsyncTask } from '../../../domain/async/AsyncTask';
import { ViewModel } from '../../../domain/core/ViewModel';
import { EntryModel } from '../../../domain/model/EntryModel';
import { PhotoModel } from '../../../domain/model/PhotoModel';
import { PoiModel } from '../../../domain/model/PoiModel';
import { EntryProxy } from '../../../domain/proxy/EntryProxy';
import { PoiProxy } from '../../../domain/proxy/PoiProxy';
import { I18nService } from '../../../domain/service/I18nService';
import { NotificationService } from '../../../domain/service/NotificationService';
import { SessionStore } from '../../../domain/store/SessionStore';
import { transient } from '../../../inversify/decorator';
import { AddImageProps } from './AddImage';

@transient()
export class AddImageVm extends ViewModel<AddImageProps<EntryModel | PoiModel>> {

  @observable
  public open: boolean = false;

  @observable
  public imageSrc: string | undefined = undefined;

  @observable
  public files: FileValidated[] = [];

  constructor(
    @inject(SessionStore) public readonly session: SessionStore,
    @inject(I18nService) public readonly i18n: I18nService,
    @inject(EntryProxy) private readonly entryProxy: EntryProxy,
    @inject(PoiProxy) private readonly poiProxy: PoiProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get isEntry() {
    return this.props.item instanceof EntryModel;
  }

  @computed
  public get maxFiles(): number {
    return this.session.isProUser ? 0 : 1;
  }

  @computed
  public get disableSubmit(): boolean {
    return !this.session.isProUser && this.files.length > this.maxFiles;
  }

  @action
  public setOpen = (open: boolean) => {
    this.open = open;
  }

  @action
  public setImageSrc = (imageSrc: string | undefined) => {
    this.imageSrc = imageSrc;
  }

  @action
  public setFiles = (files: FileValidated[]) => {
    this.files = files;
  }

  public updateFiles = (incommingFiles: FileValidated[]) => {
    this.setFiles(incommingFiles);
  };

  public handleSee = (imageSource: string | undefined) => {
    this.setImageSrc(imageSource);
  };

  public closeModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.closeMenu(event);
    this.setOpen(false);
    this.setFiles([]);
  };

  public onDelete = (id: string | number | undefined) => {
    this.setFiles(this.files.filter((x) => x.id !== id));
  };

  public handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    await this.onUpload.run();
    this.closeModal(event);
  }

  public onUpload = new AsyncTask(async () => {
    try {
      const promises = this.files.map(async (file: FileValidated) => {
        const result = this.isEntry
          ? await this.entryProxy.uploadPhoto(this.props.item.id, file.file)
          : await this.poiProxy.uploadPhoto(this.props.item.id, file.file);

        if (result.ok) {
          const newPhoto = result.data.photos.find((photo: PhotoModel) => photo.id);
          this.props.item.addPhoto(newPhoto!);
          this.props.upsert(this.props.item);
          return 'success';
        }

        return 'failed';
      });

      await Promise.all(promises).then((status: string[]) => {
        if (status.includes('failed')) {
          return this.notification.error(this.i18n.t('upload_image:error'));
        }
        return this.notification.success(this.i18n.t('upload_image:success'));
      });

    } catch (e) {
      console.error(`unexpected exception. ${e}`);
      return this.notification.error(this.i18n.t('upload_image:error'));
    }
  });

}
