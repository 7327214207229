import { inject } from 'inversify';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { BaseSyntheticEvent } from 'react';

import { AsyncTask } from '../../../../../../../domain/async/AsyncTask';
import { IGeoLocation } from '../../../../../../../domain/core/IGeoLocation';
import { ViewModel } from '../../../../../../../domain/core/ViewModel';
import { PoiModel } from '../../../../../../../domain/model/PoiModel';
import { DistrictProxy } from '../../../../../../../domain/proxy/DistrictProxy';
import { PoiProxy } from '../../../../../../../domain/proxy/PoiProxy';
import { I18nService } from '../../../../../../../domain/service/I18nService';
import { NotificationService } from '../../../../../../../domain/service/NotificationService';
import { TrackingEvent } from '../../../../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../../../../domain/service/tracking/TrackingService';
import { transient } from '../../../../../../../inversify/decorator';
import { DistrictResponseDto } from '../../../../../../../shared/dto';

export type KmlImportModalRef = {
  open: () => void,
  close: () => void,
}

export interface KmlImportModalProps {
  onDistrictSelected: (name: string, points: IGeoLocation[], pois: PoiModel[], kmlImport: boolean) => void;
  importMultiplePois?: (pois: PoiModel[]) => void;
}

@transient()
export class KmlImportModalVm extends ViewModel<KmlImportModalProps> {

  @observable
  public open = false;

  @observable
  public file: File | null = null;

  @observable
  public possibleDistricts: DistrictResponseDto[] | null = null;

  @observable
  public selectedDistrict: DistrictResponseDto | null = null;

  constructor(
    @inject(DistrictProxy) private readonly districtProxy: DistrictProxy,
    @inject(NotificationService) private readonly notification: NotificationService,
    @inject(I18nService) private readonly i18n: I18nService,
    @inject(TrackingService) private readonly tracking: TrackingService,
    @inject(PoiProxy) private readonly poiProxy: PoiProxy,
  ) {
    super();
    makeObservable(this);
  }

  @action
  public selectDistrict = (district: DistrictResponseDto) => {
    this.selectedDistrict = district;
  }

  @action
  public openModal = () => {
    this.open = true;
  }

  @action
  public closeModal = () => {
    this.open = false;
  }

  @action
  public setFile = (file: File | null) => {
    this.file = file;
  }

  @action
  public setPossibleDistricts = (possibleDistricts: DistrictResponseDto[] | null) => {
    this.possibleDistricts = possibleDistricts;
  }

  @action
  public handleClose = (event: BaseSyntheticEvent) => {
    this.file = null;
    this.setPossibleDistricts(null);
    this.closeModal();
    event.stopPropagation();
  }

  @action
  public onFileSelected = (files: File[]) => {
    if (files.length) {
      this.file = files[0];
      this.setPossibleDistricts(null);
    }
  }

  public import = new AsyncTask(async () => {
    if (!this.file) {
      return;
    }

    if (this.selectedDistrict) {
      await this.tracking.track(TrackingEvent.DISTRICT_IMPORTED);
      return this.props.onDistrictSelected(
        this.getDistrictName(this.selectedDistrict),
        this.createOpenMesh(this.selectedDistrict.mesh),
        this.selectedDistrict.pois.map(p => PoiModel.fromDto(p)),
        true,
      );
    }

    const data = new FormData();
    data.append('file', this.file);

    const result = await this.districtProxy.importDistrict(data);
    if (result.ok) {
      if (result.data.districts) {
        if (result.data.districts.length === 1) {
          return this.props.onDistrictSelected(
            this.getDistrictName(result.data.districts[0]),
            this.createOpenMesh(result.data.districts[0].mesh),
            result.data.districts[0].pois.map(p => PoiModel.fromDto(p)),
            true,
          );
        } else {
          return runInAction(() => {
            this.setPossibleDistricts(result.data.districts);
            this.selectDistrict(this.possibleDistricts![0]);
          });
        }
      }

      if (result.data.pois && result.data.pois.length) {
        const pois = result.data.pois.map(poi => PoiModel.fromDto(poi));
        return this.props.importMultiplePois && this.props.importMultiplePois(pois);
      }
    }
  });

  private readonly getDistrictName = (district: DistrictResponseDto) => {
    return district.name || this.i18n.t('district:kml_district_without_title');
  }

  private readonly createOpenMesh = (mesh: number[][]) => {
    const geoMesh: IGeoLocation[] = mesh.map((point: number[]) => {
      return {
        latitude: point[1],
        longitude: point[0],
      };
    });

    if (geoMesh[0].latitude === geoMesh[geoMesh.length - 1].latitude && geoMesh[0].longitude === geoMesh[geoMesh.length - 1].longitude) {
      geoMesh.pop();
    }

    return geoMesh;
  }
}
