import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Box, Grid, Typography } from '@mui/material';
import { IQuotaClassificationProps, QuotaClassificationVm } from './QuotaClassificationVm';
import { useVm } from '../../../../../../../../../domain/hooks/useVm';
import { ClassificationButton } from '../../../../../../entry/view-entry/classification/components/ClassificationButton';
import { Counter } from '../../../../../../entry/view-entry/classification/components/Counter';
import { Assets } from '../../../../../../../../../assets';

export const QuotaClassification = observer(function QuotaClassification(props: IQuotaClassificationProps) {
  const vm = useVm(QuotaClassificationVm, props);
  const { t } = useTranslation();
  const rowToRender = vm.tree.find((r) => r.every((item) => !item.selected));

  return (
    <Box width="100%" paddingX={2} marginBottom={3}>
      {vm.selectedFirstLevel
        ? <Grid container direction={'row'} spacing={1}>
          <Grid item xs={vm.selectedOtherLevels.length ? 6 : 12}>
            <ClassificationButton info={vm.selectedFirstLevel} />
          </Grid>
          {vm.selectedOtherLevels.length > 0 && <Grid item xs={6} container spacing={1} direction="column">
            {vm.selectedOtherLevels.map((item) => <Grid key={`item-${item?.name}`} item xs={12 / vm.selectedOtherLevels.length}>
              <ClassificationButton info={item!} />
            </Grid>)}
          </Grid>}
        </Grid>
        : <Box display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'4px'} bgcolor={'background.info'} height={'116px'}>
          <Typography textAlign={'center'}>
            {t('entry:classification_placeholder')}
          </Typography>
        </Box>}
      <Box marginY={2}>
        <Counter disabled={false} value={props.tempQuotaPlanSpecie.amount} onChange={props.tempQuotaPlanSpecie.changeAmount} />
      </Box>

      {vm.loadClassificationData.isBusy && <Box display={'flex'} justifyContent={'center'} alignItems={'center'} marginTop={4}>
        <img style={{ width: '60px' }} src={Assets.loading_logo} />
      </Box>}

      {(!vm.loadClassificationData.isBusy && rowToRender) && <Grid container spacing={2}>
        {rowToRender.map((item, buttonIndex) => {
          return <Grid item key={`${item.name}-${buttonIndex}`} xs={4}>
            <ClassificationButton info={item} />
          </Grid>;
        })}
      </Grid>}
    </Box>
  );
});
