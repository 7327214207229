import { Type } from 'class-transformer';
import { IsNotEmpty, IsNumber, IsOptional, IsUUID, ValidateNested } from 'class-validator';

import { GroupMiniDto } from './group.mini.dto';

export class PostPostRequestDto {

  @IsNotEmpty()
  @ValidateNested({ each: true, context: { errorCode: 422 } })
  @Type(() => GroupMiniDto)
  public groups: GroupMiniDto[];

  @IsOptional()
  @IsUUID()
  public photoId?: string;

  @IsOptional()
  public meta?: any;

  @IsOptional()
  public description?: string;

  @IsOptional()
  @IsNumber()
  public lat?: number;

  @IsOptional()
  @IsNumber()
  public long?: number;
}
