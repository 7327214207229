import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { useVm } from '../../domain/hooks/useVm';
import { Background } from '../../toolkit/components/Background';
import { IconAndTitle } from '../../toolkit/components/IconAndTitle';
import { ImageGallery } from './ImageGallery';
import { ImageGalleryVm } from './ImageGalleryVm';
import { LoadingFallback } from '../../LoadingFallback';
import { ProBlockerModal } from '../pro-blocker/ProBlockerModal';

export const ImageGalleryRoute = observer(function ImageGalleryRoute() {
  const vm = useVm(ImageGalleryVm);
  const { t } = useTranslation();

  if (vm.getAllEntries.isBusy) {
    return <LoadingFallback />;
  }

  return (
    <Background paddingX={{ xl: '8%' }}>
      <Box display="flex" flexDirection="column">

        <IconAndTitle title={t('gallery:title')} subtitle={`${vm.entryImages.length} ${t('gallery:subtitle')}`} />

        <ImageGallery vm={vm} />

      </Box>
      {!vm.session.isProUser && <ProBlockerModal />}

    </Background>
  );
});
