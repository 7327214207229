import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableHead, TableRow, Typography } from '@mui/material';

import { useThemeContext } from '../../../../theme/ThemeContextProvider';

interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  { id: 'seat', label: 'group_subscription:table.header_1', },
  { id: 'rights', label: 'group_subscription:table.header_2', },
  { id: 'menu', label: '', },
];

export const ManageGroupOverviewTableHead: React.FC = function () {
  const { theme } = useThemeContext();
  const { t } = useTranslation();

  return (
    <TableHead sx={{ bgcolor: theme.palette.background.container }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'normal'}
          >
            <Typography variant='text_sm_semibold' color={theme.palette.text.secondary}>{t(headCell.label)}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
