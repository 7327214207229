import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Grid, ThemeProvider, useMediaQuery } from '@mui/material';

import { useVm } from '../../../domain/hooks/useVm';
import { LoadingFallback } from '../../../LoadingFallback';
import { AppRoutes } from '../../../router/AppRoutesEnum';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { ForwardingBlockerModal } from '../../payment-modals/ForwardingBlockerModal';
import { AddSeatsSection } from '../group-subscription/components/AddSeatsSection';
import { GroupTitle } from '../group-subscription/components/GroupTitle';
import { GroupOverviewTable } from '../group-subscription/overview/GroupOverviewTable';
import { UpdateGroupPriceOverview } from './UpdateGroupPriceOverview';
import { UpdateGroupSubscriptionVm } from './UpdateGroupSubscriptionVm';

export const UpdateGroupSubscription = observer(function UpdateGroupSubscription() {
  const { theme } = useThemeContext();
  const { t } = useTranslation();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));

  const vm = useVm(UpdateGroupSubscriptionVm);

  const addSeatsSection = useMemo(() => <AddSeatsSection
    candidatesForAssignment={vm.candidatesForAssignment}
    assignedMembers={vm.assignedMembers}
    setAssignedMembers={vm.setAssignedMembers}
    canceledSubscriptionEmptySeats={vm.canceledSubscriptionEmptySeats}
  />, [vm.candidatesForAssignment, vm.assignedMembers, vm.setAssignedMembers, vm.canceledSubscriptionEmptySeats]);

  const upgradeGroupOverviewTable = useMemo(() => <GroupOverviewTable groupOverviewData={vm.groupOverviewWithoutOwner} />, [vm.groupOverviewWithoutOwner]);

  const updateGroupPriceOverview = useMemo(() => <UpdateGroupPriceOverview updateGroupSubscriptionVm={vm} />, [vm]);

  useEffect(() => {
    const { quantity, emptySeats } = vm.groupSubscriptionInfo;
    const memberCount = vm.groupMembersOverview.size;
    const totalSeats = quantity + emptySeats;
    const currentOccupancy = quantity + memberCount;

    if (currentOccupancy > totalSeats) {
      const extraSeats = currentOccupancy - emptySeats;
      vm.getUpcomingInvoice.run(extraSeats);
    }
  }, [vm.getUpcomingInvoice, vm.groupMembersOverview.size, vm.groupSubscriptionInfo]);

  if (vm.fetchData.isBusy || vm.getUpcomingInvoice.isBusy || vm.updateGroupSubscription.isBusy) {
    return <LoadingFallback />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container alignItems={'center'} justifyContent={'center'} bgcolor={theme.palette.background.surface}>
        <Grid container direction={'column'} paddingY={{ xs: 3, md: 6 }} rowGap={isMobileSize ? 2 : 4} maxWidth={{ xs: '95%', sm: '90%', lg: '70%', xl: '60%' }} alignItems={'center'}>
          {/* TITLE */}
          <GroupTitle
            title={t('group_subscription:title.upgrade')}
            route={AppRoutes.ManageGroup}
            buttonLocizeKey='group_subscription:button.navigation_back_manage'
          />

          {/* ADD SEATS SECTION */}
          {addSeatsSection}

          {/* TABLE */}
          {upgradeGroupOverviewTable}

          <Grid item>
            <Divider />
          </Grid>

          {/* PRICE OVERVIEW */}
          {updateGroupPriceOverview}

        </Grid>

      </Grid>
      {vm.groupSubscriptionInfo?.paymentResolveUrl && vm.shouldRenderForwardingBlockerModal && <ForwardingBlockerModal paymentResolveUrl={vm.groupSubscriptionInfo?.paymentResolveUrl} />}
    </ThemeProvider>
  );
});
