import { observer } from 'mobx-react-lite';
import React, { useCallback, useMemo } from 'react';

import { XIcon } from '@HunterCompanion/hunter-design/icons';
import AddIcon from '@mui/icons-material/Add';
import { Box, Divider, Fade, Grid, IconButton, Paper, Popper, Typography } from '@mui/material';

import { useContainer } from '../../../../domain/hooks/useContainer';
import { DistrictModel } from '../../../../domain/model/DistrictModel';
import { PoiModel } from '../../../../domain/model/PoiModel';
import { SessionStore } from '../../../../domain/store/SessionStore';
import { DISTRICT_ROLE, ENTRY_TYPE } from '../../../../shared/enum';
import { IGeoLocation } from '../../../../shared/interfaces/IGeoLocation';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { SvgWrapper } from '../../../../toolkit/components/SvgWrapper';
import { AreaMenu } from './components/AreaMenu';
import { PathsAndZonesMenu } from './components/PathsAndZonesMenu';
import { PinMenu } from './components/PinMenu';

export interface IMapMenuProps {
  selectedDistrict: DistrictModel | null;
  disabled: boolean;
  onNewDistrict: (name: string, points: IGeoLocation[], pois: PoiModel[], kmlImport: boolean) => void;
  onNewEntry: (type: ENTRY_TYPE) => void;
  onNewPoi: () => void;
  onNewPath: () => void;
  onNewSubzone: () => void;
  importMultiplePois?: (pois: PoiModel[]) => void;
}

export const MapMenu = observer(function MapAction(props: IMapMenuProps) {
  const { theme } = useThemeContext();
  const session = useContainer().get(SessionStore);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);

  const isUserGuestInCurrentDistrict = useMemo(() => {
    const findUser = props.selectedDistrict?.members.find(member => member.user?.id === session.userId && member.role?.name === DISTRICT_ROLE.GUEST);

    return Boolean(findUser);
  }, [props.selectedDistrict?.members, session.userId]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const handleCloseMenu = useCallback(() => {
    setOpen(false);
  }, []);

  const title = useMemo(() => (
    <Grid container alignItems={'center'} justifyContent={'space-between'} paddingX={2}>
      <Grid item xs={11}>
        <Typography variant={'text_md_semibold'} color={theme.palette.text.primary}>Add Map Object</Typography>
      </Grid>
      <Grid item xs={1} sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end' }}>
        <IconButton onClick={handleCloseMenu} >
          <SvgWrapper icon={XIcon} fill={theme.palette.icons?.secondary} width={16} height={16} />
        </IconButton>
      </Grid>
    </Grid>
  ), [handleCloseMenu, theme.palette.icons?.secondary, theme.palette.text.primary]);

  return (
    <Box position={'absolute'} bottom={31} right={20}>
      <IconButton
        onClick={handleClick}
        disabled={props.disabled}
        disableRipple
        sx={{
          width: 56,
          height: 56,
          border: `2px solid ${theme.palette.background.cta_default}`,
          borderRadius: 100,
          boxShadow: '0px -2px 4px 0px #00000040',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 2,
          backgroundColor: open ? theme.palette.background.cta_active : theme.palette.background.cta_default,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: open ? theme.palette.background.cta_active : theme.palette.background.cta_default,
          },
          '&:disabled': {
            backgroundColor: theme.palette.background.cta_default,
            opacity: 0.6,
          }
        }}
      >
        <AddIcon htmlColor={theme.palette.system!.black} sx={{ width: 32, height: 32, opacity: props.disabled ? 0.6 : 1 }} />
      </IconButton>

      <Popper
        sx={{ zIndex: 3 }}
        open={open}
        anchorEl={anchorEl}
        placement={'top-end'}
        transition
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 15]
            }
          }
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ paddingY: 1, width: 280 }}>
              {/* TITLE */}
              {title}

              <Divider />

              <Box paddingX={2}>
                <AreaMenu
                  onNewDistrict={props.onNewDistrict}
                  closeMenu={handleCloseMenu}
                  importMultiplePois={props.importMultiplePois}
                />
              </Box>

              <Divider />

              <Box paddingX={2}>
                <PinMenu
                  onNewEntry={props.onNewEntry}
                  onNewPoi={props.onNewPoi}
                  closeMenu={handleCloseMenu}
                  isUserGuestInCurrentDistrict={isUserGuestInCurrentDistrict}
                />
              </Box>

              <Divider />

              {!isUserGuestInCurrentDistrict && <Box paddingX={2}>
                <PathsAndZonesMenu
                  onNewPath={props.onNewPath}
                  onNewSubzone={props.onNewSubzone}
                  closeMenu={handleCloseMenu}
                />
              </Box>}
            </Paper>
          </Fade>
        )}
      </Popper>


    </Box>
  );
});
