import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { XIcon } from '@HunterCompanion/hunter-design/icons';
import {
  Button, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
  Modal, Radio, Tooltip, Typography
} from '@mui/material';

import { useVm } from '../../../../../domain/hooks/useVm';
import { GroupSubscriptionUserModel } from '../../../../../domain/model/GroupSubscriptionUserModel';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import {
  NewSubmitButton
} from '../../../../../toolkit/components/redesign-components/NewSubmitButton';
import { SvgWrapper } from '../../../../../toolkit/components/SvgWrapper';
import { AssignAreaMembersTable } from '../assign-area-members-table/AssignAreaMembersTable';
import { AssignMemberViaEmail } from '../assing-area-members-email/AssignMemberViaEmail';
import { AssignAreaMembersModalVm } from './AssignAreaMembersModalVm';

export type AssignSeatOptionType = 'existing_members' | 'email_invite';

export interface IAssignAreaMembersModalProps {
  open: boolean;
  onCloseModal: () => void;
  assignOption: AssignSeatOptionType;
  candidatesForAssignment: GroupSubscriptionUserModel[];
  assignedMembers: Set<GroupSubscriptionUserModel>;
  setAssignedMembers: (assignedMembers: Set<GroupSubscriptionUserModel>) => void;
  canceledSubscriptionEmptySeats?: number;  // use it only on manage subscription, represents unassigned seats on Canceled Subscription
}

export const AssignAreaMembersModal = observer(function AssignAreaMembersModal(props: IAssignAreaMembersModalProps) {
  const { theme } = useThemeContext();
  const { t } = useTranslation();
  const vm = useVm(AssignAreaMembersModalVm, props);

  const optionsSelection = useMemo(() => (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant='text_md_semibold' color={theme.palette.text.primary}>{t('group_subscription:modal.selector.title')}</Typography>
      </Grid>
      <Grid item>
        <List dense disablePadding>
          {vm.assignSeatOptions.map((option) => (
            <ListItem key={option} disablePadding onClick={() => vm.setAssignSeatOption(option)}>
              <ListItemButton sx={{ paddingLeft: 0, paddingY: 0 }}>
                <ListItemIcon>
                  <Radio
                    checked={vm.assignSeatOption === option}
                    onChange={() => vm.setAssignSeatOption(option)}
                    value={option}
                    inputProps={{ 'aria-label': option }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant='text_md_regular' color={theme.palette.text.primary}>
                    {t(`group_subscription:modal.selector.option.${option}`)}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [t, theme.palette.text.primary, vm, vm.assignSeatOption]);

  const tableComponent = useMemo(() =>
    <AssignAreaMembersTable vm={vm} candidatesForAssignment={props.candidatesForAssignment} />,
    [props.candidatesForAssignment, vm]
  );

  const emailInvitationSection = useMemo(() => <AssignMemberViaEmail vm={vm} />, [vm]);

  const renderAssignButton = useMemo(() => {
    const button = (
      <Grid item sx={{ cursor: vm.exceedsEmptySeats ? 'not-allowed' : 'pointer' }}>
        <NewSubmitButton
          fullWidth
          disabled={vm.isEmailInvite ? !vm.isEmailValid : vm.disableAssign}
          onClick={vm.isEmailInvite ? vm.onAssignViaEmail.run : vm.onAssignSeats}
          submitting={vm.onAssignViaEmail.isBusy}
        >
          {t('group_subscription:modal.cta.assign')}
        </NewSubmitButton>
      </Grid>
    );

    if (vm.exceedsEmptySeats) {
      return (
        <Tooltip arrow placement="top" title={t('manage_group:members.canceled_subscription_assign')}>
          {button}
        </Tooltip>
      );
    }

    return button;

  }, [t, vm.disableAssign, vm.exceedsEmptySeats, vm.isEmailInvite, vm.isEmailValid, vm.onAssignSeats, vm.onAssignViaEmail.isBusy, vm.onAssignViaEmail.run]);

  useEffect(() => {
    vm.setOpenModal(props.open);
  }, [props.open, vm]);

  return (
    <Modal
      open={vm.open}
      onClose={vm.closeModal}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Grid
        container
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        bgcolor={theme.palette.background.surface}
        border={`1px solid ${theme.palette.borderDividers?.border}`}
        borderRadius={1}
        width={{ xs: '95%', sm: '70%', md: vm.isEmailInvite ? '35%' : '50%' }}
        padding={1}
        rowGap={1}
      >
        {/* TITLE */}
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
          <Grid item>
            <Typography variant='text_md_semibold' color={theme.palette.text.primary}>{t('group_subscription:modal.heading.assign')}</Typography>
          </Grid>
          <Grid flexGrow={1} />
          <Grid item sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end' }}>
            <IconButton onClick={vm.closeModal} >
              <SvgWrapper icon={XIcon} fill={theme.palette.icons?.secondary} width={19} height={19} />
            </IconButton>
          </Grid>
        </Grid>

        {/* DIVIDER */}
        <Grid item xs={12} >
          <Divider />
        </Grid>

        {/* OPTIONS SELECTION */}
        {optionsSelection}

        {/* DIVIDER */}
        <Grid item xs={12} >
          <Divider />
        </Grid>

        {/* TABLE */}
        <Grid container>
          {vm.isEmailInvite ? emailInvitationSection : tableComponent}
        </Grid>

        {/* EXPLANATION TEXT */}
        <Grid item xs>
          <Typography variant='text_sm_regular' color={theme.palette.text.secondary}>{t('group_subscription:modal.charge_info')}</Typography>
        </Grid>

        {/* DIVIDER */}
        <Grid item xs={12} >
          <Divider />
        </Grid>

        {/* BUTTONS */}
        <Grid container justifyContent={'space-evenly'} columnSpacing={1}>
          <Grid item xs={6}>
            <Button
              fullWidth
              color='secondary'
              onClick={vm.closeModal}
            >
              {t('common:button.cancel')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            {renderAssignButton}
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );

}
);
