import { XIcon } from '@HunterCompanion/hunter-design/icons';
import { observer } from 'mobx-react-lite';
import React, { BaseSyntheticEvent, useCallback, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Button, Divider, Grid, IconButton, Modal, TextField, ThemeProvider, Typography } from '@mui/material';

import { useVm } from '../../../../../../../domain/hooks/useVm';
import { useThemeContext } from '../../../../../../../theme/ThemeContextProvider';
import { ValidatedForm } from '../../../../../../../toolkit/components/form/ValidatedForm';
import {
  ValidatedFormControl
} from '../../../../../../../toolkit/components/form/ValidatedFormControl';
import {
  NewSubmitButton
} from '../../../../../../../toolkit/components/redesign-components/NewSubmitButton';
import { SvgWrapper } from '../../../../../../../toolkit/components/SvgWrapper';
import { JoinAreaModalVm } from './JoinAreaModalVm';

export type JoinAreaModalRef = {
  open: () => void,
  close: () => void,
}

type JoinAreaModalProps = Record<string, unknown>;

export const JoinAreaModal = observer(
  React.forwardRef<JoinAreaModalRef, JoinAreaModalProps>(
    function JoinAreaModal(props: JoinAreaModalProps, ref) {
      const { theme } = useThemeContext();
      const { t } = useTranslation();
      const vm = useVm(JoinAreaModalVm);

      useImperativeHandle(ref, () => ({
        open() {
          vm.setOpen(true);
        },
        close() {
          vm.setOpen(false);
        }
      }));

      const handleCloseMenu = useCallback((e: BaseSyntheticEvent) => {
        vm.handleClose(e);
      }, [vm]);

      /**
       * Conflict with a browser shortcut: Some browsers have keyboard shortcuts that involve the letter 'c', such as Ctrl+C for copy or Cmd+C on Mac.
       * If the MUI TextField component is not properly configured to handle these shortcuts, they could be triggering a blur event and causing the component to lose focus.
       * You can prevent this behavior by adding an event listener to the TextField component that prevents the event from propagating to any parent event listeners that may be causing the focus loss.
      */
      const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'c') {
          event.stopPropagation();
        }
      }, []);

      return (
        <ThemeProvider theme={theme}>
          <Modal
            open={vm.open}
            onClose={handleCloseMenu}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid
              container
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              bgcolor={theme.palette.background.surface}
              border={`1px solid ${theme.palette.borderDividers?.border}`}
              borderRadius={1}
              width={375}
            >
              {/* TITLE */}
              <Grid container alignItems={'center'} justifyContent={'space-between'} paddingLeft={2} paddingRight={1} paddingTop={1}>
                <Grid item>
                  <Typography variant='text_md_semibold' color={theme.palette.text.primary}>{t('district:join_area_modal.title')}</Typography>
                </Grid>
                <Grid flexGrow={1} />
                <Grid item sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end' }}>
                  <IconButton onClick={handleCloseMenu} >
                    <SvgWrapper icon={XIcon} fill={theme.palette.icons?.secondary} width={19} height={19} />
                  </IconButton>
                </Grid>
              </Grid>
              {/* DIVIDER */}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* BODY */}
              <ValidatedForm onSubmit={vm.redeemCode.run}>
                <Grid container justifyContent={'flex-start'} padding={2} gap={2}>
                  <Grid item>
                    <Typography variant='text_sm_regular' color={theme.palette.text.primary}>
                      {t('district:join_area_modal.subtitle')}
                    </Typography>
                  </Grid>
                  <Grid item container>
                    <Grid item xs={12}>
                      <Typography variant='text_sm_regular' color={theme.palette.text.primary}>
                        {t('district:join_area_modal.label')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} marginTop={0.5}>
                      <ValidatedFormControl
                        fullWidth
                        value={vm.invitationCode}
                        schema={
                          yup
                            .string()
                            .required(t('task:description.placeholder'))
                        }
                      >
                        <TextField
                          fullWidth
                          inputProps={{ maxLength: 6, style: { textTransform: 'uppercase' } }}
                          placeholder={t('district:join_area_modal.placeholder')}
                          onKeyDown={handleKeyDown}
                          InputLabelProps={{ style: { textTransform: 'none' } }}
                          onChange={(event: BaseSyntheticEvent) => {
                            if (event.target.value.length > 6) return;
                            vm.setInvitationCode(event.target.value);
                          }}
                        />
                      </ValidatedFormControl>
                    </Grid>
                  </Grid>
                  {/* DIVIDER */}
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  {/* BUTTONS */}
                  <Grid container justifyContent={'space-evenly'} columnSpacing={1}>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        color='secondary'
                        onClick={handleCloseMenu}
                      >
                        {t('common:button.cancel')}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <NewSubmitButton
                        fullWidth
                        disabled={!vm.isRedeemEnabled}
                      >
                        {t('district:join_area_modal.title')}
                      </NewSubmitButton>
                    </Grid>
                  </Grid>
                </Grid>
              </ValidatedForm>
            </Grid>
          </Modal>
        </ThemeProvider>
      );

    })
);
