import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Box, Grid, Typography } from '@mui/material';

import { useContainer } from '../../../domain/hooks/useContainer';
import { useVm } from '../../../domain/hooks/useVm';
import { NotificationService } from '../../../domain/service/NotificationService';
import { UserPatchResetPasswordDto } from '../../../shared/dto';
import { oldDesignTheme } from '../../../theme/oldDesignTheme';
import { colors } from '../../../theme/options/palette/const/colors';
import { Field } from '../../../toolkit/components/form/Field';
import { Form } from '../../../toolkit/components/form/Form';
import { FormSubmitButton } from '../../../toolkit/components/form/FormSubmitButton';
import { Password } from '../../../toolkit/components/Password';
import { NewPasswordVm } from './NewPasswordVm';

export interface RepeatPassword {
  password: string;
  passwordConfirmation: string;
}

export const NewPassword: React.FC = observer(function NewPassword() {
  const vm = useVm(NewPasswordVm);
  const notification = useContainer().get(NotificationService);
  const { i18n, t } = useTranslation();

  const onSubmit = useCallback(({ password, passwordConfirmation }: RepeatPassword) => {
    if (password !== passwordConfirmation) {
      return notification.warning(t('auth:reset_step3_validation_match'));
    }

    vm.onSubmit(vm.queryToken, { password } as UserPatchResetPasswordDto);
  }, [vm, notification, t]);

  useEffect(() => {
    if (vm.queryLanguage !== i18n.language) {
      i18n.changeLanguage(vm.queryLanguage);
    }
  }, [i18n, vm.queryLanguage]);

  return (
    <Box>
      <Form onSubmit={onSubmit}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography color="textPrimary" variant="h1" textAlign={'center'}>
              {t('auth:reset_step3_form_title')}
            </Typography>
          </Grid>
          <Grid
            item
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="subtitle1">
                {t('auth:reset_step3_form_subtitle1')}
              </Typography>
              <Field name="password"
                fullWidth
                schema={yup
                  .string()
                  .default('')
                  .required(t('auth:validations.new_password.required_password'))
                  .min(8, t('auth:validations_invalid_password'))
                }
              >
                <Password
                  passwordShown={vm.passwordShown}
                  toggleShowPassword={vm.setShowPassword}
                  placeholder={t('auth:reset_step3_form_label1')}
                  sx={{
                    '& .MuiFilledInput-root, .MuiFilledInput-input': {
                      backgroundColor: colors.paper,
                      minHeight: '20px',
                      paddingTop: '10px'
                    },
                    '& .MuiInputBase-input': {
                      color: oldDesignTheme.palette.text.secondary,
                      fontSize: '18px',
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Field>
            </Grid>
            <Grid item xs={12}>
              <Typography color="textPrimary" variant="subtitle1">
                {t('auth:reset_step3_form_subtitle2')}
              </Typography>
              <Field name="passwordConfirmation" fullWidth
                schema={yup
                  .string()
                  .default('')
                  .required(t('auth:validations.new_password.required_password'))
                }
              >
                <Password
                  passwordShown={vm.passwordShown}
                  toggleShowPassword={vm.setShowPassword}
                  placeholder={t('auth:reset_step3_form_label2')}
                  sx={{
                    '& .MuiFilledInput-root, .MuiFilledInput-input': {
                      backgroundColor: colors.paper,
                      minHeight: '20px',
                      paddingTop: '10px'
                    },
                    '& .MuiInputBase-input': {
                      color: oldDesignTheme.palette.text.secondary,
                      fontSize: '18px',
                      fontWeight: 'bold',
                    },
                  }}
                />
              </Field>
            </Grid>
          </Grid>

          <Grid item>
            <Box marginTop={4}>
              <FormSubmitButton submitting={vm.isBusy} sx={{ minWidth: '204px', minHeight: '36px' }}>
                {t('auth:reset_step3_form_submit')}
              </FormSubmitButton>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Box >
  );
});
