import clsx from 'clsx';
import React from 'react';

import { Button, ButtonProps, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { oldDesignTheme } from '../../theme/oldDesignTheme';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  progress: {
    color: oldDesignTheme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

interface IProps extends React.PropsWithChildren<unknown>, ButtonProps {
  submitting?: boolean;
}

export function SubmitButton(props: IProps) {
  const {
    children,
    submitting = false,
    disabled = false,
    color = 'secondary',
    variant = 'contained',
    ...buttonProps
  } = props;

  const classes = useStyles();

  return (
    <Button
      type="submit"
      className={clsx(classes.root)}
      color={color}
      variant={variant}
      disabled={disabled || submitting}
      {...buttonProps}
    >
      {children ? children : 'Submit'}
      {submitting && (
        <CircularProgress
          size={24}
          className={classes.progress}
          disableShrink
        />
      )}
    </Button>
  );
}

export type SubmitButtonProps = IProps;
