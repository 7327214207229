import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Assets } from '../../../../../../assets';
import { useInstance } from '../../../../../../domain/hooks/useInstance';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { FEATURE } from '../../../../../../shared/enum/feature.enum';
import { InfoComponent } from '../../../../../../toolkit/components/InfoComponent';
import { ProCtaButton } from '../../../../../../toolkit/components/ProCtaButton';
import { PoiMarker } from '../../marker/PoiMarker';

interface IProps {
  poi: PoiModel;
  canEditAndDeletePoi: boolean;
  onShootingDirectionEdit: (poi: PoiModel) => void;
}

export function ShootingDirection(props: IProps) {
  const { t } = useTranslation();

  const session = useInstance(SessionStore);
  const hasShootingDirection = session.hasFeatureEnabled(FEATURE.SHOOTING_DIRECTION);

  const edit = useCallback(() => {
    props.onShootingDirectionEdit(props.poi);
  }, [props]);

  return (
    <Box width="100%">
      {hasShootingDirection
        ? <Box display="flex" flexDirection={'column'} justifyContent={'center'} alignItems={'center'} paddingX={4}>
          <Box paddingTop={6} paddingBottom={6}>
            <PoiMarker
              poi={props.poi}
              lat={0}
              lng={0}
              opacity={1}
              skiptransform={true}
              hideTooltip
              width={70}
              miniIconWidth={40}
              symbolWidth={34}
              shootingDirectionWidth={200}
            />
          </Box>
          <Box marginTop={props.poi.hasShootingDirection ? 6 : 0}>
            {props.poi.hasShootingDirection && <Typography variant="body2" textAlign={'center'} py={2}>
              {t(`poi:title_shooting_direction_${props.poi.directionLetter}`)}, {props.poi.shootingAngle}°
            </Typography>}
            {props.canEditAndDeletePoi && <Button onClick={edit} sx={{ mx: 'auto', paddingX: 8 }} color={'secondary'} variant="contained">
              {t('poi:edit_shooting_direction')}
            </Button>}
          </Box>
        </Box>
        : <>
          <InfoComponent
            locizePath="poi:pro_only_feature"
          />
          <img src={Assets.poi.shooting_direction.pro} style={{ width: '100%' }} />
          <Box display="flex" flexDirection={'column'} alignItems={'center'} px={4}>
            <Typography variant="h6" textAlign={'center'} paddingBottom={4}>
              {t('poi:cta_shooting_direction')}
            </Typography>
            <ProCtaButton />
          </Box>
        </>}
    </Box>
  );
}
