import DistrictManagementIcon from '../../assets/img/district_management.svg';
import MappingFeaturesIcon from '../../assets/img/mapping_features.svg';
import WeatherForecastIcon from '../../assets/img/weather_forecast.svg';
export interface PaymentFeature {
  icon: string,
  title: string;
  description: string[];
}

export const PaymentFeaturesList: PaymentFeature[] = [
  {
    icon: DistrictManagementIcon,
    title: 'payment:features.feature_1.title',
    description: [
      'payment:features.feature_1.description_1',
      'payment:features.feature_1.description_2',
      'payment:features.feature_1.description_3',
      'payment:features.feature_1.description_4',
    ]
  },
  {
    icon: MappingFeaturesIcon,
    title: 'payment:features.feature_2.title',
    description: [
      'payment:features.feature_2.description_1',
      'payment:features.feature_2.description_2',
      'payment:features.feature_2.description_3',
      'payment:features.feature_2.description_4',
    ]
  },
  {
    icon: WeatherForecastIcon,
    title: 'payment:features.feature_3.title',
    description: [
      'payment:features.feature_3.description_1',
      'payment:features.feature_3.description_2',
      'payment:features.feature_3.description_3',
      'payment:features.feature_3.description_4',
    ]
  },
];
