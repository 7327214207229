import { makeObservable, observable } from 'mobx';
import { PhotoResponseDto } from '../../shared/dto';

export type FileDescriptorMimeType = 'image/jpeg' | 'image/png';

export class PhotoModel {

  public id: string = '';

  public ownerId?: string;

  public entryId?: string;

  public poiId?: string;

  @observable
  public url: string | null = null;

  @observable
  public name: string | null = null;

  @observable
  public thumbnailUrl: string | null = null;

  @observable
  public pinned: boolean = false;

  @observable
  public uploadedPhoto?: boolean;

  @observable
  public width: number = 0;

  @observable
  public height: number = 0;

  public contentType: FileDescriptorMimeType;

  constructor() {
    makeObservable(this);
  }

  public clone = () => {
    const cloned = new PhotoModel();

    cloned.id = this.id;
    cloned.url = this.url;
    cloned.name = this.name;
    cloned.thumbnailUrl = this.thumbnailUrl;
    cloned.entryId = this.entryId;
    cloned.poiId = this.poiId;
    cloned.ownerId = this.ownerId;
    cloned.pinned = this.pinned;
    cloned.uploadedPhoto = this.uploadedPhoto;

    return cloned;
  }

  public static fromDto(dto: PhotoResponseDto, pinnedPhoto?: PhotoResponseDto): PhotoModel {
    const photo = new PhotoModel();

    photo.id = dto.id;
    photo.url = dto.url;
    photo.name = dto.name;
    photo.thumbnailUrl = dto.thumbnailUrl;
    photo.entryId = dto.entryId;
    photo.poiId = dto.poiId;
    photo.ownerId = dto.owner != null ? dto.owner.id : undefined;
    photo.pinned = pinnedPhoto !== undefined && pinnedPhoto.id === dto.id;
    photo.uploadedPhoto = dto.uploadedPhoto;

    return photo;
  }
}
