import { action, computed, observable } from 'mobx';
import { map, raw, serializable } from 'serializr';
import { Persistence } from '../../../domain/persistence/Persistence';

export interface IDistrictFilterPersistedState {
  [id: string]: boolean;
}

export interface IDistrictFilterCondition {
  id: string;
  active: boolean;
}

export abstract class AbstractDistrictFilter extends Persistence {

  @observable
  @serializable(map(raw()))
  public persistedState: IDistrictFilterPersistedState = {};

  public abstract conditions: IDistrictFilterCondition[];

  constructor(key: string, version: number) {
    super();
    this.activatePersistence(key, version);
  }

  @action
  protected loadCache = <T extends IDistrictFilterCondition>(conditions: T[]): T[] => {
    // no cache, or data not loaded yet
    if (!this.persistedState) {
      return conditions;
    }

    conditions.forEach((c) => {
      c.active = (c.id in this.persistedState) ? this.persistedState[c.id] : c.active;
    });

    return conditions;
  }

  @action
  public persistState = () => {
    const persistedState: IDistrictFilterPersistedState = {};
    this.conditions.forEach((c) => persistedState[c.id] = c.active);
    this.persistedState = persistedState;
  }

  @action
  public toggle(condition: IDistrictFilterCondition) {
    condition.active = !condition.active;
    this.persistState();
  }

  @action
  public selectOnlyCondition = (condition: IDistrictFilterCondition) => {
    this.conditions.forEach((c) => {
      c.active = c.id === condition.id;
    });
    this.persistState();
  }

  @action
  public setAllTo = (active: boolean) => {
    this.conditions.forEach((c) => {
      c.active = active;
    });

    this.persistState();
  }

  @action
  public setConditionTo = (condition: IDistrictFilterCondition, active: boolean) => {
    condition.active = active;
    this.persistState();
  }

  @action
  public deactivate() {
    this.conditions.forEach((c) => c.active = true);
    this.persistState();
  }

  @computed
  public get hasActive(): boolean {
    return this.conditions.some((filter) => !filter.active);
  }

  @computed public get activeConditions() {
    return this.conditions.filter((c) => c.active);
  }
}
