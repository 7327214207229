import { Slider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { colors } from '../../../../../../theme/options/palette/const/colors';

export interface ISliderStep<T = number> {
  text: string;
  value: T;
  level: 1 | 2 | 3;
}

interface IProps {
  sliderPadding: number | string;
  steps: ISliderStep[];
  value: number;
  onChange: (value: number) => void;
}

export function LabeledSlider(props: IProps) {
  return (
    <Box width="100%">
      <Box display="flex" justifyContent={'space-between'} alignItems={'center'}>
        {props.steps.map((step, index) => <Typography
          key={`slider-step-${step.text}-${index}`}
          variant={step.level === 1 ? 'h4' : step.level === 2 ? 'caption' : 'overline'}
          fontSize={step.level === 3 ? '6px' : undefined}
          alignSelf={step.level === 3 ? 'flex-end' : undefined}
          flex={1}
          textAlign={'center'}
          fontWeight="300"
        >
          {step.text}
        </Typography>)}
      </Box>
      <Box width="100%" px={props.sliderPadding}>
        <Slider
          step={1}
          min={0}
          value={props.value}
          color="secondary"
          track={false}
          max={props.steps.length - 1}
          valueLabelDisplay="off"
          onChange={(_, index) => props.onChange(props.steps[index as number].value)}
        />
      </Box>
      <Box display="flex" justifyContent={'space-between'} alignItems={'flex-start'}>
        {props.steps.map((step, index) => <Box
          key={`slider-line-${step.text}-${index}`}
          display="flex"
          justifyContent={'center'}
          alignItems={'center'}
          flex={1}
        >
          <Box
            width={'1px'}
            height={step.level === 1 ? '20px' : step.level === 2 ? '11px' : '6px'}
            bgcolor={colors.blueGrey[900]}
          />
        </Box>)}
      </Box>
    </Box>
  );
}
