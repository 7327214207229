import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, Avatar, Divider, Grid, IconButton, Link, List,
  ListItem, ListItemAvatar, ListItemText, Typography
} from '@mui/material';
import { Box } from '@mui/system';

import { useVm } from '../../../../../../../../domain/hooks/useVm';
import { colors } from '../../../../../../../../theme/options/palette/const/colors';
import { ClassificationRegionProps, ClassificationRegionVm } from './ClassificationRegionVm';

export const ClassificationRegion = observer(function ClassificationRegion(props: ClassificationRegionProps) {
  const vm = useVm(ClassificationRegionVm, props);
  const { t } = useTranslation();

  return (
    <Box height="inherit" display="flex" flexDirection="column" position={'relative'}>
      <Box sx={{ overflowY: 'auto' }}>
        <Grid container direction="column" justifyContent="center" spacing={5} paddingY={1} paddingX={2}
          sx={{
            backgroundColor: props.headerColor,
            color: colors.paper,
          }}
        >
          <Grid item alignSelf={'flex-start'}>
            <IconButton component="span" onClick={props.onClose}>
              <ArrowBackIosNewIcon sx={{ color: 'white' }} />
            </IconButton>
          </Grid>
          <Grid item container direction="column">
            <Typography paddingLeft={2} variant="h4" sx={{ textShadow: '1px 1px 3px #00000029' }}>{t('entry:region')}</Typography>
          </Grid>
        </Grid>
        <Box paddingX={4} paddingY={2}>
          {vm.selectedRegion && <Box sx={{ boxShadow: 5 }} maxWidth={'100%'}>
            <img style={{ maxWidth: '100%' }} src={vm.selectedRegion?.image} />
          </Box>}
          <Box marginTop={4}>
            {vm.regions.map((r) => (<Accordion
              key={`region-${r.region}`}
              expanded={r.region === vm.selectedRegion?.region}
              onClick={() => vm.onRegionChange(r.region)}
            >
              <AccordionSummary
                sx={{
                  width: '100%',
                  '.MuiAccordionSummary-content': {
                    justifyContent: 'space-between',
                  }
                }}
                expandIcon={vm.selectedRegion?.region !== r.region && <ExpandMoreIcon />}
              >
                <Box>
                  <Typography width={'100%'} fontWeight={'600'} color={'primary.main'}>
                    {vm.classificationTranslationService.getRegionName(r.region)}
                  </Typography>
                  <Typography color={'text.info'} fontSize={'15px'}>
                    {r.count} {t('entry:animal_categories')}
                  </Typography>
                </Box>
                {vm.selectedRegion?.region === r.region && <Link color="inherit" textAlign={'right'} onClick={() => props.onRegionChange(r.region)}>
                  {t('entry:use_region')}
                </Link>}
              </AccordionSummary>
              <AccordionDetails>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                  {r.animals.map((animal, index) => <React.Fragment key={`animal-${animal.name}`}>
                    <ListItem alignItems="center">
                      <ListItemAvatar>
                        <Avatar alt={animal.name} src={animal.icon} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={animal.name}
                      />
                    </ListItem>
                    {(index < r.animals.length - 1) && <Divider variant="inset" component="li" />}
                  </React.Fragment>)}
                </List>
              </AccordionDetails>
            </Accordion>))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
