import { action, computed, makeObservable, observable } from 'mobx';
import polylabel from 'polylabel';
import { Assets } from '../../assets';
import { SubzonePutRequestDto } from '../../shared/dto/subzone/subzone.put.request.dto';
import { SubzoneResponseDto } from '../../shared/dto/subzone/subzone.response.dto';
import { CUSTOM_MARK_COLOR } from '../../shared/enum/customMarkColor.enum';
import { SUBZONE_TYPE } from '../../shared/enum/subzoneType.enum';
import { IGeoLocation } from '../../shared/interfaces/IGeoLocation';
import { MeshModel } from './MeshModel';
import { UserModel } from './UserModel';
import { DistrictModel } from './DistrictModel';

export class SubzoneModel {

  @observable
  public id: string = '';

  @observable
  public title: string = '';

  @observable
  public owner: UserModel | undefined = undefined;

  @observable
  public districtId: string | undefined = undefined;

  @observable
  public subzoneType: SUBZONE_TYPE | undefined = undefined;

  @observable
  public background?: CUSTOM_MARK_COLOR | null = null;

  @observable
  public notes?: string = '';

  @observable
  public mesh: MeshModel = new MeshModel([]);

  constructor() {
    makeObservable(this);
  }

  @action
  public setDistrictId = (districtId: string) => {
    this.districtId = districtId;
  }

  @action
  public setTitle = (value: string) => {
    this.title = value;
  }

  @action
  public setType = (type: SUBZONE_TYPE) => {
    this.subzoneType = type;
    if (type !== SUBZONE_TYPE.CUSTOM) {
      this.setColor(null);
    }
  }

  @action
  public setColor = (color: CUSTOM_MARK_COLOR | null) => {
    this.background = color;
  }

  @action
  public setNotes = (notes: string) => {
    this.notes = notes;
  }

  @computed
  public get color(): string {
    if (this.background) {
      return this.background;
    }

    return this.getSubzoneTypeColor(this.subzoneType);
  }

  @computed
  public get fillColor(): string {
    if (this.background) {
      return this.background;
    }

    return this.getSubzoneTypeColor(this.subzoneType);
  }

  @computed
  public get icon() {
    if (this.subzoneType) {
      return SubzoneModel.typeToIcon(this.subzoneType);
    }
  }

  public static typeToIcon = (type: SUBZONE_TYPE): string | undefined => {
    return [{
      type: SUBZONE_TYPE.SUBZONE,
      icon: Assets.subzone.subzone,
    }, {
      type: SUBZONE_TYPE.CLOSED_AREA,
      icon: Assets.subzone.subzone_closed_area,
    }, {
      type: SUBZONE_TYPE.FIELD,
      icon: Assets.subzone.subzone_field,
    }, {
      type: SUBZONE_TYPE.FOREST,
      icon: Assets.subzone.subzone_forest,
    }, {
      type: SUBZONE_TYPE.SWAMP,
      icon: Assets.subzone.subzone_swamp,
    }, {
      type: SUBZONE_TYPE.CROP_FIELD,
      icon: Assets.subzone.subzone_crop_field,
    }, {
      type: SUBZONE_TYPE.CUSTOM,
      icon: Assets.subzone.subzone_other,
    }].find((t) => t.type === type)?.icon;
  }

  private getSubzoneTypeColor = (subzoneType?: SUBZONE_TYPE) => {
    switch (subzoneType) {
      case SUBZONE_TYPE.CLOSED_AREA:
        return '#FA7A7C';
      case SUBZONE_TYPE.CROP_FIELD:
        return '#FFA700';
      case SUBZONE_TYPE.CUSTOM:
        return '#CDD2DE';
      case SUBZONE_TYPE.FIELD:
        return '#80C9B8';
      case SUBZONE_TYPE.FOREST:
        return '#ADC87C';
      case SUBZONE_TYPE.SUBZONE:
        return '#FFA700';
      case SUBZONE_TYPE.SWAMP:
        return '#A8967C';
      default:
        return '#FFA700';
    }
  };

  @computed
  public get pinLocation(): IGeoLocation | undefined {

    if (!this.mesh || this.mesh.points.length < 3) {
      return undefined;
    }

    // Beautiful solution for the problem that the geometric center of a polygon might not be inside the actual polygon
    // https://github.com/mapbox/polylabel
    const point = polylabel([this.mesh.points.map(p => [p.latitude, p.longitude])]);

    return {
      latitude: point[0],
      longitude: point[1]
    };
  }

  public clone = (): SubzoneModel => {
    const model = new SubzoneModel();
    model.id = this.id;
    model.districtId = this.districtId;
    model.mesh = this.mesh.clone();
    model.background = this.background;
    model.subzoneType = this.subzoneType;
    model.notes = this.notes;
    model.title = this.title;
    model.owner = this.owner?.clone();

    return model;
  }

  public toDto(): SubzonePutRequestDto {
    const dto = new SubzonePutRequestDto();

    dto.id = this.id || undefined!;
    dto.title = this.title;
    dto.notes = this.notes;
    dto.subzoneType = this.subzoneType;
    dto.background = this.subzoneType !== SUBZONE_TYPE.CUSTOM ? null : this.background;
    dto.mesh = this.mesh.points.slice();
    dto.districtId = this.districtId !== DistrictModel.worldMapId ? this.districtId : undefined;

    return dto;
  }

  public static fromDto(dto: SubzoneResponseDto): SubzoneModel {
    const model = new SubzoneModel();
    model.id = dto.id;
    model.title = dto.title;
    model.owner = UserModel.fromDto(dto.owner);
    model.districtId = dto.districtId ? dto.districtId : DistrictModel.worldMapId;
    model.subzoneType = dto.subzoneType;
    model.notes = dto.notes;
    model.mesh = dto.mesh ? new MeshModel(dto.mesh) : new MeshModel([]);
    model.background = dto.background;

    return model;
  }
}

