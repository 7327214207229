import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { InformationIcon } from '@HunterCompanion/hunter-design/icons';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { SvgWrapper } from '../../../../toolkit/components/SvgWrapper';

interface IOwnerPriceProps {
  isOwnerAlreadyPro: boolean;
  ownerPriceWithoutDiscount: number;
  ownerDiscount: number;
  ownerFinalPrice: number;
  currency: string;
}

export const OwnerPrice: React.FC<IOwnerPriceProps> = React.memo(function OwnerPrice({
  isOwnerAlreadyPro,
  ownerPriceWithoutDiscount,
  ownerDiscount,
  ownerFinalPrice,
  currency,
}: IOwnerPriceProps) {
  const { t } = useTranslation();
  const { theme } = useThemeContext();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));

  return isOwnerAlreadyPro ? (
    <Box display={'flex'} flexWrap={'nowrap'} alignItems={'center'}>
      <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary} sx={{ textDecoration: 'line-through' }}>
        {ownerPriceWithoutDiscount.toFixed(2)}{currency}
      </Typography>&nbsp;
      <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.brand?.brand_color}>
        {ownerFinalPrice.toFixed(2)}{currency}
      </Typography>&nbsp;
      <Tooltip title={t('group_subscription:table.table_row.price.tooltip_discount.group_owner', { discount: `${ownerDiscount.toFixed(2)}${currency}` })} placement='top'>
        <span>
          <SvgWrapper icon={InformationIcon} fill={theme.palette.brand?.brand_color} width={16} height={16} />
        </span>
      </Tooltip>
    </Box>
  ) : (
    <Typography variant={isMobileSize ? 'text_md_regular' : 'text_sm_regular'} color={theme.palette.text.primary}>
      {ownerPriceWithoutDiscount.toFixed(2)}{currency}
    </Typography>
  );
});
