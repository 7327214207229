import { ApiProperty } from '@nestjs/swagger';

export class CustomMarkResponseDto {

  @ApiProperty()
  public id: string;

  @ApiProperty()
  public name: string;

  @ApiProperty()
  public foreground: string;

  @ApiProperty()
  public background: string;

  @ApiProperty()
  public isBookable: boolean;

  @ApiProperty()
  public canHaveTasks: boolean;

  @ApiProperty()
  public hasShootingDirection: boolean;

  @ApiProperty()
  public createdAt: string; // ISO string

  @ApiProperty()
  public updateAt?: string; // ISO string

  @ApiProperty()
  public districtId?: string;

  @ApiProperty({ description: 'event ID happenning in marks district or worldmap events that overlaps with marks location. Currently only populated in GET api/event/:eventId/custom-mark}' })
  public eventId?: string;

  @ApiProperty()
  public ownerId?: string;
}
