import { t } from 'i18next';
import React, { BaseSyntheticEvent } from 'react';

import { Grid, Typography, useMediaQuery } from '@mui/material';

import { Assets } from '../../../../../../../assets';
import { useThemeContext } from '../../../../../../../theme/ThemeContextProvider';
import { AppStepper, IStepperSteps } from '../../../../../../../toolkit/components/AppStepper';

interface IDistrictRolesStepperProps {
  closeModal: (e: BaseSyntheticEvent) => void;
}

const steps: IStepperSteps[] = [
  {
    icon: Assets.roles_modal_1,
    title: 'district:role_modal.stepper.screen_one.title',
    description_1: 'district:role_modal.stepper.screen_one.description_1',
    description_2: 'district:role_modal.stepper.screen_one.description_2',
  },
  {
    icon: Assets.roles_modal_2,
    title: 'district:role_modal.stepper.screen_one.title',
    description_1: 'district:role_modal.stepper.screen_two.description_1',
    description_2: 'district:role_modal.stepper.screen_two.description_2',
  },
];
export const DistrictRolesStepper = function DistrictRolesStepper(props: IDistrictRolesStepperProps) {
  const { theme } = useThemeContext();

  const [activeStep, setActiveStep] = React.useState(0);

  const isMobileSize = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <AppStepper steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} closeModal={props.closeModal}>
      {/* STEPPER BODY */}
      <Grid item container direction={'column'} alignItems={'center'} justifyContent={'flex-start'} paddingBottom={2} rowGap={2}>
        {/* IMAGE */}
        <Grid item>
          <img src={steps[activeStep].icon} width={250} />
        </Grid>
        {/* SUBTITLE */}
        <Grid item textAlign={'center'}>
          <Typography variant={isMobileSize ? 'headingSubtitleMobile' : 'headingSubtitleDesktop'} color={theme.palette.text.primary}>{t(steps[activeStep].title)}</Typography>
        </Grid>
        <Grid container rowGap={2} justifyContent={'center'} textAlign={'center'}>
          {/* BODY DESCRIPTION 1 */}
          <Grid item>
            <Typography variant='text_md_regular' color={theme.palette.text.secondary}>
              {t(steps[activeStep].description_1)}
            </Typography>
          </Grid>
          {/* BODY DESCRIPTION 2 */}
          {steps[activeStep].description_2 && <Grid item>
            <Typography variant='text_md_regular' color={theme.palette.text.secondary}>
              {t(steps[activeStep].description_2!)}
            </Typography>
          </Grid>}
        </Grid>
      </Grid>
    </AppStepper>
  );

};
