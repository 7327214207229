import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Menu, MenuItem, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useVm } from '../../../../../../../../domain/hooks/useVm';
import { DISTRICT_ROLE } from '../../../../../../../../shared/enum';
import { DistrictViewListItem } from '../../DistrictViewListItem';
import { ViewDistrictMemberProps, ViewDistrictMemberVm } from './ViewDistrictMemberVm';
import React from 'react';

export const ViewDistrictMember = observer(function ViewDistrictMember(props: ViewDistrictMemberProps) {
  const { t } = useTranslation();
  const { member } = props;
  const vm = useVm(ViewDistrictMemberVm, props);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  const [leavingTheDistrictDialogOpen, setLeavingTheDistrictDialogOpen] = useState(false);
  const [removeFromTheDistrictDialogOpen, setRemoveFromTheDistrictDialogOpen] = useState(false);
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);

  const showMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const closeTransferDialog = useCallback(() => {
    setTransferDialogOpen(false);
  }, []);

  const closeLeavingTheDistrictDialog = useCallback(() => {
    setLeavingTheDistrictDialogOpen(false);
  }, []);

  const closeRemoveFromTheDistrictDialogOpen = useCallback(() => {
    setRemoveFromTheDistrictDialogOpen(false);
  }, []);

  const closeMenu = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuAnchorEl(null);
  }, []);

  const promoteToOwner = useCallback((event: React.MouseEvent) => {
    closeMenu(event);
    vm.changeRole.run(DISTRICT_ROLE.OWNER).then(closeTransferDialog);
  }, [closeMenu, closeTransferDialog, vm]);

  const promoteToAdmin = useCallback((event: React.MouseEvent) => {
    closeMenu(event);
    vm.changeRole.run(DISTRICT_ROLE.ADMIN);
  }, [closeMenu, vm]);

  const promoteToMember = useCallback((event: React.MouseEvent) => {
    closeMenu(event);
    vm.changeRole.run(DISTRICT_ROLE.USER);
  }, [closeMenu, vm]);

  const demoteAdminRole = useCallback((event: React.MouseEvent) => {
    closeMenu(event);
    vm.changeRole.run(DISTRICT_ROLE.USER);
  }, [closeMenu, vm]);

  const demoteMemberRole = useCallback((event: React.MouseEvent) => {
    closeMenu(event);
    vm.changeRole.run(DISTRICT_ROLE.GUEST);
  }, [closeMenu, vm]);

  const removeMember = useCallback((event: React.MouseEvent) => {
    closeMenu(event);
    vm.removeMember.run();
  }, [closeMenu, vm]);

  return (
    <DistrictViewListItem
      key={`district-member-${member.user?.id}`}
      image={member.user?.photo?.thumbnailUrl ?? undefined}
      firstLine={t(member.role?.roleNameI18n ?? 'district:role_guest')}
      secondLine={member.user?.fullName}
      thirdLine={member.user?.email}
      withDivider={props.showDivider}
      rightComponent={
        (vm.canSeeMenu) && <>
          <Button
            onClick={showMenu}
            color="secondary"
          >
            {t('district:options_dropdown')}
          </Button>
          {vm.isMe
            ? <Menu
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={closeMenu}
            >
              <MenuItem onClick={() => {
                setLeavingTheDistrictDialogOpen(true);
                setMenuAnchorEl(null);
              }}>
                <Typography color={'error'}>
                  {t('share:leave_district.menu_option')}
                </Typography>
              </MenuItem>
            </Menu>
            : <Menu
              anchorEl={menuAnchorEl}
              open={menuOpen}
              onClose={closeMenu}
            >
              {(vm.canBePromotedToOwner) && <MenuItem onClick={() => {
                setTransferDialogOpen(true);
                setMenuAnchorEl(null);
              }}>
                {t('share:promote_to_owner')}
              </MenuItem>}
              {(vm.canBePromotedToAdmin) &&
                <MenuItem onClick={promoteToAdmin}>
                  {t('share:promote_to_admin')}
                </MenuItem>}
              {(vm.canBePromotedToMember) &&
                <MenuItem onClick={promoteToMember}>
                  {t('share:promote_to_member')}
                </MenuItem>}
              {(vm.canAdminRoleBeDemoted) && <MenuItem onClick={demoteAdminRole}>
                {t('share:remove_admin')}
              </MenuItem>}
              {(vm.canMemberRoleBeDemoted) && <MenuItem onClick={demoteMemberRole}>
                {t('share:demote_member')}
              </MenuItem>}
              {(vm.canBeRemoved) && <MenuItem onClick={() => {
                setRemoveFromTheDistrictDialogOpen(true);
                setMenuAnchorEl(null);
              }}>
                <Typography color={'error'}>
                  {t('share:remove_member')}
                </Typography>
              </MenuItem>}
            </Menu>
          }
          {/* confirmation dialog for TRANSFER */}
          <Dialog open={transferDialogOpen} onClose={closeTransferDialog} onClick={(event) => event.stopPropagation()}>
            <DialogTitle sx={{ fontSize: '1.125rem', fontWeight: '500' }}>
              {t('share:transfer_ownership_modal_title')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText variant="subtitle1">
                {t('share:transfer_ownership_modal_message')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeTransferDialog}>
                <Typography variant="subtitle2">
                  {t('common:button.cancel')}
                </Typography>
              </Button>
              <Button
                onClick={promoteToOwner}
                autoFocus
              ><Typography variant="subtitle2" color={'text.hunter_green'}>
                  {t('share:transfer_btn')}
                </Typography>
              </Button>
            </DialogActions>
          </Dialog>

          {/* confirmation dialog for LEAVING the district */}
          <Dialog open={leavingTheDistrictDialogOpen} onClose={closeLeavingTheDistrictDialog} onClick={(event) => event.stopPropagation()}>
            <DialogTitle sx={{ fontSize: '1.125rem', fontWeight: '500' }}>
              {t('share:leave_district.modal_title')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText variant="subtitle1">
                {t('share:leave_district.modal_message')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeLeavingTheDistrictDialog}>
                <Typography variant="subtitle2">
                  {t('common:button.cancel')}
                </Typography>
              </Button>
              <Button
                onClick={removeMember}
                autoFocus
              ><Typography variant="subtitle2" color={'text.hunter_green'}>
                  {t('share:leave_district.confirm_btn')}
                </Typography>
              </Button>
            </DialogActions>
          </Dialog>

          {/* confirmation dialog for REMOVING MEMBER FROM the district */}
          <Dialog open={removeFromTheDistrictDialogOpen} onClose={closeRemoveFromTheDistrictDialogOpen} onClick={(event) => event.stopPropagation()}>
            <DialogTitle sx={{ fontSize: '1.125rem', fontWeight: '500' }}>
              {t('share:remove_from_district.modal_title')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText variant="subtitle1">
                {t('share:remove_from_district.modal_message')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeRemoveFromTheDistrictDialogOpen}>
                <Typography variant="subtitle2">
                  {t('common:button.cancel')}
                </Typography>
              </Button>
              <Button
                onClick={removeMember}
                autoFocus
              ><Typography variant="subtitle2" color={'text.hunter_green'}>
                  {t('share:remove_from_district.confirm_btn')}
                </Typography>
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    />
  );
});
