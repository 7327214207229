import { ApiProperty } from '@nestjs/swagger';
import { Type } from 'class-transformer';
import { IsEmail, IsIn, IsOptional, ValidateNested } from 'class-validator';
import { ROLE } from '../enum';


// tslint:disable:max-classes-per-file
export class InviteGroupRequestDto {

  @IsEmail({}, { context: { errorCode: 422 } })
  @ApiProperty()
  public email: string;

  @IsOptional()
  @IsIn([ROLE.GROUP.ADMIN, ROLE.GROUP.USER])
  @ApiProperty({ example: ROLE.GROUP.ADMIN, required: false })
  public role?: string;
}

export class InviteGroupPatchRequestDto {

  @ValidateNested({ each: true, context: { errorCode: 422 } })
  @Type(() => InviteGroupRequestDto)
  @ApiProperty({ type: [InviteGroupRequestDto] })
  public users: InviteGroupRequestDto[];
}
