import { Box, List } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Assets } from '../../../../../assets';
import { MapPathsFilter } from '../../../filters/MapPathsFilter';
import { FilterListItem } from '../components/FilterListItem';
import { NoEntitiesToFilter } from '../components/NoEntitiesToFilter';
import { SelectDeselectAll } from '../components/SelectDeselectAll';

interface IProps {
  mapPathFilter: MapPathsFilter;
}

export const FilterSidebarMapPaths = observer(function FilterSidebarMapPaths(props: IProps) {
  const { t } = useTranslation();

  return (
    <Box mx={3} my={1}>
      <Box display="flex" justifyContent={'flex-end'}>
        <SelectDeselectAll filter={props.mapPathFilter} />
      </Box>
      {props.mapPathFilter.conditions.length > 0 ? <List>
        {props.mapPathFilter.conditions.map((condition) => {
          return (
            <FilterListItem
              key={`animal-type-condition-${condition.id}`}
              onSelectOnlyThis={() => props.mapPathFilter.selectOnlyCondition(condition)}
              icon={<Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius={'50%'}
                width={'35px'}
                height={'35px'}
                bgcolor={condition.type.color ?? 'primary'}
              >
                <img style={{ width: '20px', height: '20px' }} src={Assets.path.icon} />
              </Box>}
              title={t(condition.type.i18nKey)}
              checked={condition.active}
              onClick={() => props.mapPathFilter.toggle(condition)}
            />
          );
        })}
      </List> : <NoEntitiesToFilter text={t('filter:no_paths_to_filter')} />}
    </Box>
  );
});
