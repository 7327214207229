import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,
  ThemeProvider
} from '@mui/material';

import { env } from '../../env';
import { useThemeContext } from '../../theme/ThemeContextProvider';
import { FilterButton } from './components/FilterButton';
import { LocationSearch } from './components/location-search/LocationSearch';
import { MapControls } from './components/map-controls/MapControls';
import { MapMenu } from './components/map-menu/MapMenu';
import { MapPathTabType } from './components/map-path/view-map-path/ViewMapPathVm';
import { MapLayerSelector } from './components/MapLayerSelector';
import { MapPoiLabelsSwitch } from './components/MapPoiLabelsSwitch';
import { MeshSetupControls } from './components/MeshSetupControls';
import { PoiImportControls } from './components/PoiImportControls';
import { DistrictTutorialSnackbar } from './components/snackbar/DistrictTutorialSnackbar';
import { MapPathTutorialSnackbar } from './components/snackbar/MapPathTutorialSnackbar';
import { SubzoneTutorialSnackbar } from './components/snackbar/SubzoneTutorialSnackbar';
import { SubzoneTabType } from './components/subzone/view-subzone/ViewSubzoneVm';
import { MapRouteVm } from './MapRouteVm';
import { MapProvider, MapType } from './MapVm';
import { PinSizeContext } from './PinSizeContext';
import { PrintMapButton } from './print-map/PrintMapButton';
import { PrintMapModal } from './print-map/PrintMapModal';
import { PrintMapOverlay } from './print-map/PrintMapOverlay';

interface IProps {
  vm: MapRouteVm;
}

export const MapOverlay = observer(function MapOverlay(props: IProps) {
  const { theme } = useThemeContext();
  const vm = props.vm;
  const { t } = useTranslation();
  const [lastStyleUrl, setLastStyleUrl] = useState<string | undefined>(undefined);
  const [confirmOpened, setOpenConfirmModal] = useState(false);
  const pinSizeContext = useContext(PinSizeContext);

  // * disabled for non PRO users, and also depending if developer/tester switched on FREE_OR_PRO item
  const isDisabled = useMemo(() => {
    if (!env.isProduction) {
      const isProUser = props.vm.session.isProUser;

      // If the user is a Pro user, features should not be disabled
      if (isProUser) return false;

      // Check the FREE_OR_PRO key in localStorage
      const freeOrPro = localStorage.getItem(`FREE_OR_PRO-${vm.session.userId}`);

      // If the key doesn't exist in localStorage, default to enabling Pro features
      if (freeOrPro === null) return false;

      // Disable only if FREE_OR_PRO is explicitly set to 'false'
      return freeOrPro === 'false';
    }
    else {
      return !props.vm.session.isProUser || vm.printMapVm.isPrintMapActive;
    }
  }, [props.vm.session.isProUser, vm.printMapVm.isPrintMapActive, vm.session.userId]);

  const openConfirmModal = useCallback(() => {
    setOpenConfirmModal(true);
  }, [setOpenConfirmModal]);

  const closeConfirmModal = useCallback(() => {
    setOpenConfirmModal(false);
  }, [setOpenConfirmModal]);

  useEffect(() => {
    if (!props.vm.districtsVm.setupModeActive && props.vm.mapVm.mapType === 'parcels') {
      props.vm.mapVm.selectDefault();
    }
  }, [props.vm.districtsVm.setupModeActive, props.vm.mapVm]);

  const selectMapTypeConfirmed = useCallback(() => {
    props.vm.mapVm.selectMapType('mapbox', 'parcels', lastStyleUrl);
  }, [props.vm.mapVm, lastStyleUrl]);

  const selectMapType = useCallback((mapProvider: MapProvider, mapType: MapType, styleUrl?: string) => {
    setLastStyleUrl(styleUrl);

    if (mapProvider === 'mapbox' && (props.vm.districtsVm.selectedDistrict?.mesh.points.length ?? 0) > 100) {
      // notification.warning(t('map:mapbox_map_import_slow_for_big_areas'), { autoHideDuration: 5000 });
      return openConfirmModal();
    }

    props.vm.mapVm.selectMapType(mapProvider, mapType, styleUrl);
  }, [props.vm.mapVm, props.vm.districtsVm.selectedDistrict?.mesh.points.length, openConfirmModal]);

  useEffect(() => {
    pinSizeContext.setIsPrintMapOverlayActive(vm.printMapVm.isPrintMapActive);
  }, [pinSizeContext, vm.printMapVm.isPrintMapActive]);

  return (
    <React.Fragment>
      {vm.printMapVm.isPrintMapActive && <PrintMapOverlay vm={vm.printMapVm} />}

      {vm.printMapVm.isPrintMapActive && <PrintMapModal printMapVm={vm.printMapVm} selectedDistrict={vm.selectedDistrict} />}

      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        marginTop: 4,
        paddingX: 1.5,
        display: 'flex',
        width: vm.setupMode ? '100%' : '400px',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        visibility: vm.printMapVm.hideElements ? 'hidden' : 'visible'
      }}>
        {/* OLD MAP MENU */}
        <Grid container direction={'row'} spacing={1}>
          <Grid item xs={12}>
            <LocationSearch
              isDisabled={isDisabled}
              onSelect={(location) => vm.mapVm.flyTo(location, 12)}
            />
          </Grid>

          {/* FILTER */}
          {!vm.setupMode && <Grid item xs={12}>
            <FilterButton
              disabled={isDisabled}
              onClick={vm.openFilters}
              hasActiveFilters={vm.districtFilterVm.hasActiveFilter}
            />
          </Grid>}

          {/* PRINT MAP */}
          {!vm.setupMode && <Grid item xs={12}>
            <PrintMapButton vm={vm.printMapVm} disabled={isDisabled} />
          </Grid>}
        </Grid>

        {vm.poiVm.poisImportInProgress && <PoiImportControls
          onCancel={vm.cancelPoisImport}
          onSave={vm.saveMultiplePoisImport}
          isSaving={vm.poiVm.saveMultiplePois.isBusy}
        />}

        {vm.selectedDistrict?.setupActive && <MeshSetupControls
          onUndo={vm.districtsVm.undoMeshPoint}
          onCancel={vm.districtsVm.cancelDistrictSetup}
          onSave={vm.saveDistrict}
          isSaving={vm.districtsVm.saveDistrict.isBusy}
          saveable={vm.districtsVm.selectedDistrict?.canBeSaved ?? false}
        />}
        {(vm.mapPathsVm.currentMapPath && vm.mapPathsVm.lastTab === MapPathTabType.PATH) && <MeshSetupControls
          onUndo={vm.mapPathsVm.currentMapPath.mesh.undoLastMeshOperation}
        />}
        {(vm.subzoneVm.currentSubzone && vm.subzoneVm.lastTab === SubzoneTabType.BORDERS) && <MeshSetupControls
          onUndo={vm.subzoneVm.currentSubzone.mesh.undoLastMeshOperation}
        />}
        {vm.setupMode && <Box width={'100%'} />}
      </Box>

      {/* DISTRICT TUTORIAL */}
      {vm.districtsVm.setupModeActive && <DistrictTutorialSnackbar />}

      {/* MAP PATH TUTORIAL */}
      {(vm.mapPathsVm.creatingNewPath && vm.hasProPaths) && <MapPathTutorialSnackbar />}

      {/* SUBZONE TUTORIAL */}
      {(vm.subzoneVm.creatingNewSubzone && vm.hasProSubzones) && <SubzoneTutorialSnackbar />}

      <ThemeProvider theme={theme}>
        {/* POI LABELS SWITCH */}
        {!vm.printMapVm.isPrintMapActive && <MapPoiLabelsSwitch showPoiLabels={vm.showPoiLabels} toggleShowPoiLabels={vm.toggleShowPoiLabels} />}

        {/* MAP CONTROLS */}
        {!vm.printMapVm.isPrintMapActive && <MapControls
          googleMapVm={vm.googleMapVm}
          zoomToDistrictLevel={vm.districtsVm.zoomToDistrictLevel}
        />}

        {/* MAP MENU ACTION BUTTON */}
        {(!vm.printMapVm.isPrintMapActive && !vm.setupMode) && <MapMenu
          selectedDistrict={vm.selectedDistrict}
          onNewDistrict={vm.startNewDistrict}
          onNewEntry={vm.startNewEntry}
          onNewPoi={vm.startNewPoi}
          onNewPath={vm.startNewMapPath}
          onNewSubzone={vm.startNewSubzone}
          disabled={isDisabled}
          importMultiplePois={vm.importMultiplePois}
        />}
      </ThemeProvider>

      {/* MAP LAYERS SELECTOR */}
      <MapLayerSelector
        setupMode={vm.districtsVm.selectedDistrict?.isWorldMap === false && vm.districtsVm.selectedDistrict?.mesh.isMeshClosed === false}
        mapProvider={props.vm.mapVm.mapProvider}
        mapType={props.vm.mapVm.mapType}
        styleUrl={props.vm.mapVm.styleUrl}
        onTypeSelected={selectMapType}
        mapboxDisabled={props.vm.districtsVm.kmlImport}
      />
      <Dialog
        open={confirmOpened}
        onClose={closeConfirmModal}
      >
        <DialogTitle>
          {t('map:slow_map_switch_confirm_title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('map:slow_map_switch_confirm_message')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmModal}>{t('common:button.cancel')}</Button>
          <Button onClick={selectMapTypeConfirmed} autoFocus>
            {t('common:button.continue')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
});
