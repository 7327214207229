import React from 'react';

import { Box } from '@mui/material';

interface IProps {
  children: React.ReactNode;
  opacity: number;
  width?: number;
}

export function MarkerTopLeftIcon(props: IProps) {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: props.width ? `${props.width}px` : '26px',
      position: 'absolute',
      right: '60%',
      top: '-12%',
      borderRadius: '50%',
      padding: '1px',
      backgroundColor: 'white',
      opacity: props.opacity,
    }}>
      {props.children}
    </Box>
  );
}
