import { Box, Typography } from '@mui/material';

import { usePinSizeContext } from './google-map/hooks/usePinSizeContext';

interface PoiLabelProps {
  name: string;
}

export const PoiLabel = function PoiLabel({ name }: PoiLabelProps) {
  const { pinWidth } = usePinSizeContext();

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      width: 'max-content',
      maxWidth: 120,
      position: 'absolute',
      px: '25%',
      py: '10%',
      left: '70%',
      top: '-5%',
      zIndex: 2,
      backgroundColor: '#ECEFF1',
      borderRadius: '12px',
      borderWidth: '1.3px',
    }}>
      <Typography variant='body2' fontSize={`${pinWidth * 0.29}px`} color='#263238' lineHeight={pinWidth < 9 ? 2 : 1} textAlign="center">{name}</Typography>
    </Box>
  );
};
