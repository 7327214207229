import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Button, Chip, Grid, IconButton, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { Assets } from '../../../../../assets';
import { useVm } from '../../../../../domain/hooks/useVm';
import { colors } from '../../../../../theme/options/palette/const/colors';
import { ImageCarousel } from '../../../../../toolkit/components/image-carousel/ImageCarousel';
import { InfoComponent } from '../../../../../toolkit/components/InfoComponent';
import { OptionalTooltip } from '../../../../../toolkit/components/OptionalTooltip';
import { ProCtaButton } from '../../../../../toolkit/components/ProCtaButton';
import { CustomizedMenu } from '../../entry/view-entry/components/CustomizedMenu';
import { PoiNotes } from './components/PoiNotes';
import { PoiPosition } from './components/PoiPosition';
import { PoiShootingDirection } from './components/PoiShootingDirection';
import { PoiTypeSelection } from './components/PoiTypeSelection';
import { IPoiTab, NewPoiProps, NewPoiVm, PoiTabType } from './NewPoiVm';

export const NewPoi = observer(function NewPoi(props: NewPoiProps) {
  const vm = useVm(NewPoiVm, props);
  const { t } = useTranslation();

  const onPoiUpsert = useCallback(() => {
    props.upsertPoi(props.poi);
  }, [props]);

  useEffect(() => {
    if (!props.district) return;

    if (props.district?.id === props.poi.districtId) {
      return;
    }

    return props.poi.setDistrictId(props.district.id);
  }, [props.district, props.poi]);

  const renderHeader = useCallback(() => {
    return (
      <Box paddingLeft={2.5} width={'100%'}>
        {/* CLOSE ICON */}
        <Grid item container justifyContent={'flex-end'}>
          <IconButton color="inherit" onClick={props.onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid item container direction="column" paddingBottom={1} marginTop={5}>
          <Grid item container alignItems={'center'} paddingBottom={props.poi.photos.length ? 3 : 0}>
            <Grid item container justifyContent={'space-between'} alignItems="center">
              {/* TITLE */}
              <Grid item xs={11}>
                <TextField
                  onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
                  fullWidth
                  variant='standard'
                  sx={{
                    input: {
                      color: colors.paper,
                      fontSize: '24px',
                      fontWeight: '600',
                    },
                    '& .MuiInput-underline:before': {
                      borderBottomColor: !vm.isNameValid ? colors.error : colors.paper,
                    },
                    '& .MuiInput-underline:after': {
                      borderBottomColor: !vm.isNameValid ? colors.error : colors.paper,
                    },
                    '.css-1vb9et-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before': {
                      borderBottomColor: !vm.isNameValid ? colors.error : colors.paper,
                    },
                    '.css-1vb9et-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):after': {
                      borderBottomColor: !vm.isNameValid ? colors.error : colors.paper,
                    }
                  }}
                  placeholder={t('poi:new_poi_title_placeholder')}
                  value={props.poi.name || t('poi:new_poi_type_placeholder')}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.poi.setName(event.target.value)}
                  error={!vm.isNameValid}
                  helperText={!vm.isNameValid ? t('poi:new_poi_title_error') : ''}
                />
                <Typography variant='caption'>{props.district?.name}</Typography>
              </Grid>

              {/* MENU */}
              {props.poi.id && <Grid item xs={1}>
                <CustomizedMenu
                  locizeNamespace="poi"
                  onUpsert={onPoiUpsert}
                  canEditAndDeletePoi={vm.canEditAndDeletePoi}
                  item={props.poi}
                />
              </Grid>}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }, [onPoiUpsert, props.district?.name, props.onClose, props.poi, t, vm.canEditAndDeletePoi, vm.isNameValid]);

  const renderHeaderNoImages = useCallback(() => (
    <Grid container direction="column" justifyContent="center"
      sx={{
        background: vm.headerBackgroundColor,
        color: colors.paper,
        paddingX: 2,
        paddingY: 1,
        minHeight: 150,
      }}
    >
      {renderHeader()}
    </Grid>
  ), [vm.headerBackgroundColor, renderHeader]);

  const renderHeaderWithImages = () => (
    <React.Fragment>
      <ImageCarousel
        item={props.poi}
        canDeleteImage={vm.canEditAndDeletePoi}
        locizeNamespace="poi"
        onUpsert={onPoiUpsert}
        onClose={props.onClose}
      >{renderHeader()}</ImageCarousel>
    </React.Fragment>
  );

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      flex={'1 0 auto'}
    >
      <Box height="auto" display="flex" flexDirection="column">

        {/* POI HEADER */}
        {!props.poi.photos.length ? renderHeaderNoImages() : renderHeaderWithImages()}

        {/* TABS */}
        <Grid container alignItems="center"
          spacing={1}
          paddingX={4}
          paddingY={2}
          sx={{
            maxWidth: 'inherit',
            boxShadow: '0 8px 3px -6px #00000033',
          }}>
          {vm.tabs.map((tab: IPoiTab) => {
            return (
              <Grid position={'relative'} item key={`poi-tab-${tab.type}`}>
                <Chip
                  clickable
                  variant="outlined"
                  label={t(tab.type)}
                  onClick={() => props.onTabChange(tab)}
                  style={props.currentTab.type === tab.type
                    ? {
                      borderColor: colors.green[800],
                      color: colors.green[800],
                      backgroundColor: colors.green[50],
                    }
                    : {}} />
                {!tab.enabled && <img src={Assets.shield} style={{
                  position: 'absolute',
                  right: -10,
                  top: -3,
                  width: '24px'
                }} />}
              </Grid>
            );
          })}
        </Grid>

        {props.currentTab.type === PoiTabType.POSITION && <PoiPosition poi={props.poi} />}
        {props.currentTab.type === PoiTabType.TYPE && <PoiTypeSelection poi={props.poi} onClose={props.onClose} weatherPoisCount={props.weatherPoisCount} />}
        {props.currentTab.type === PoiTabType.DETAILS && <PoiNotes poi={props.poi} />}
        {props.currentTab.type === PoiTabType.SHOOTING_DIRECTION && (vm.hasShootingDirectionEnabled
          ? <PoiShootingDirection poi={props.poi} />
          : <>
            <InfoComponent
              locizePath="poi:pro_only_feature"
            />
            <img src={Assets.poi.shooting_direction.pro} style={{ width: '100%' }} />
            <Box display="flex" flexDirection={'column'} alignItems={'center'} px={4}>
              <Typography variant="h6" textAlign={'center'} paddingBottom={4}>
                {t('poi:cta_shooting_direction')}
              </Typography>
              <ProCtaButton />
            </Box>
          </>)}

      </Box>

      <Box
        marginTop={'auto'}
        display="flex"
        justifyContent={'center'}
        alignItems={'center'}
        position={'sticky'}
        bottom={0}
        bgcolor="white"
        width="100%"
        minHeight={'70px'}
        borderTop={1}
        borderColor={'text.details'}
        paddingX={4}
      >
        <Button onClick={props.onClose} sx={{ paddingX: 7, paddingY: 1, marginRight: 4, }} variant="outlined" color="error">
          <Typography>{t('entry:entry_cancel')}</Typography>
        </Button>

        {/* If poi.name.length > 22, disable save btn, hide tooltip */}
        {!vm.isNameValid ? <span>
          <LoadingButton
            loading={props.onSave.isBusy}
            disabled={!vm.canBeSaved}
            onClick={vm.saveClick}
            sx={{ paddingX: 7, paddingY: 1, }} variant="contained" color="secondary"
          >
            <Typography>
              {(vm.hasNextTab) ? t('entry:entry_next_tab') : t('entry:entry_save')}
            </Typography>
          </LoadingButton>
        </span> : <OptionalTooltip
          enabled={!vm.canBeSaved}
          title={<Typography
            bgcolor={'primary.main'}
            color="white"
            textAlign={'center'}
            borderRadius={1}
            paddingX={2}
            paddingY={1}
          >
            {t('entry:entry_missing_position_popup')}
          </Typography>}
        >
          <span>
            <LoadingButton
              loading={props.onSave.isBusy}
              disabled={!vm.canBeSaved}
              onClick={vm.saveClick}
              sx={{ paddingX: 7, paddingY: 1, }} variant="contained" color="secondary"
            >
              <Typography>
                {(vm.hasNextTab) ? t('entry:entry_next_tab') : t('entry:entry_save')}
              </Typography>
            </LoadingButton>
          </span>
        </OptionalTooltip>}
      </Box>

    </Box>
  );
});
