import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  Box, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow,
  Typography
} from '@mui/material';

import { colors } from '../../../theme/options/palette/const/colors';
import { MinusIcon } from './MinusIcon';
import { PlusIcon } from './PlusIcon';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F7F7F7',
    color: 'black',
    padding: 0
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(even)': {
    backgroundColor: '#F7F7F7',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(name: string, freeUser: JSX.Element, plusUser: JSX.Element, proUser: JSX.Element) {
  return { name, freeUser, plusUser, proUser };
}

export function FeaturesTable() {

  const { t } = useTranslation();

  const featuresTableData = useMemo(() => {
    return [
      createData(t('payment:modal.features.feature_1'), <PlusIcon />, <PlusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_2'), <PlusIcon />, <PlusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_3'), <MinusIcon />, <PlusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_4'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_5'), <PlusIcon />, <PlusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_6'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_7'), <PlusIcon />, <PlusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_8'), <MinusIcon />, <PlusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_9'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_10'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_11'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_12'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_13'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_14'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_15'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_16'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_17'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_18'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_19'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_20'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_21'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_22'), <PlusIcon />, <PlusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_23'), <MinusIcon />, <MinusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_24'), <MinusIcon />, <PlusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_25'), <MinusIcon />, <PlusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_26'), <MinusIcon />, <PlusIcon />, <PlusIcon />),
      createData(t('payment:modal.features.feature_27'), <MinusIcon />, <PlusIcon />, <PlusIcon />),
    ];
  }, [t]);

  return (
    <TableContainer sx={{
      '@media only screen and (max-height: 1000px)': {
        maxHeight: '500px',
      },
      maxHeight: '800px',
    }}>
      <Table stickyHeader aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell align="center">
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 'none',
                  borderRadius: '10px',
                  backgroundColor: colors.blueGrey[200],
                  color: colors.paper,
                  paddingX: 1,
                  paddingY: 0.5,
                  cursor: 'default',
                  fontSize: '10px',
                  minWidth: 70,
                  maxWidth: 120,
                  fontWeight: 'bold',
                  outline: 'none',
                }}
              >
                <Typography fontWeight={'bold'} textAlign={'center'}>{t('payment:modal.user_1').toUpperCase()}</Typography>
              </Box>
            </StyledTableCell>
            <StyledTableCell align="center">
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 'none',
                  borderRadius: '10px',
                  backgroundColor: colors.green[700],
                  color: colors.paper,
                  paddingX: 1,
                  paddingY: 0.5,
                  cursor: 'default',
                  fontSize: '10px',
                  minWidth: 70,
                  maxWidth: 120,
                  fontWeight: 'bold',
                  outline: 'none',
                }}
              >
                <Typography fontWeight={'bold'} textAlign={'center'}>{t('payment:modal.user_3').toUpperCase()}</Typography>
              </Box>
            </StyledTableCell>
            <StyledTableCell align="center">
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 'none',
                  borderRadius: '10px',
                  backgroundColor: colors.orange[500],
                  color: colors.paper,
                  paddingX: 1,
                  paddingY: 0.5,
                  cursor: 'default',
                  fontSize: '10px',
                  minWidth: 70,
                  maxWidth: 120,
                  fontWeight: 'bold',
                  outline: 'none',
                }}
              >
                <Typography fontWeight={'bold'} textAlign={'center'}>{t('payment:modal.user_2').toUpperCase()}</Typography>
              </Box>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {featuresTableData.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell align="center">{row.freeUser}</StyledTableCell>
              <StyledTableCell align="center">{row.plusUser}</StyledTableCell>
              <StyledTableCell align="center">{row.proUser}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

}
