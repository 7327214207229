import { useTranslation } from 'react-i18next';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Grid, Typography } from '@mui/material';

import { useThemeContext } from '../../../../theme/ThemeContextProvider';

export const GroupProductBlocker = function GroupProductBlocker() {
  const { t } = useTranslation();
  const { theme } = useThemeContext();

  return (
    <Box
      border={`1px solid ${theme.palette.borderDividers?.lighter_darker}`}
      borderRadius={2}
      padding={2}
      bgcolor={theme.palette.background.container}
      width={'100%'}
    >
      <Grid container spacing={1}>
        <Grid item>
          <WarningAmberIcon htmlColor={theme.palette.icons?.primary} />
        </Grid>
        <Grid item xs>
          <Typography variant='text_sm_semibold' color={theme.palette.text.primary}>
            {t('group_subscription:disabled_payment_1')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item>
          <WarningAmberIcon htmlColor={theme.palette.icons?.primary} sx={{ visibility: 'hidden' }} />
        </Grid>
        <Grid item xs>
          <Typography variant='text_sm_regular' color={theme.palette.text.secondary}>
            {t('group_subscription:disabled_payment_2')}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
