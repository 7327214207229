import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';
import { IsBoolean, IsDateString, IsOptional, IsString, IsUUID } from 'class-validator';

export class TaskPostRequestDto {

  @IsString()
  @ApiProperty()
  public description: string;

  @IsString()
  @IsOptional()
  @ApiProperty()
  public details?: string;

  @IsBoolean()
  @ApiProperty()
  public done: boolean;

  @IsOptional()
  @IsUUID()
  @ApiPropertyOptional()
  public assigneeId?: string;

  @IsUUID()
  @IsOptional()
  @ApiProperty()
  public poiId?: string;

  @IsUUID()
  @IsOptional()
  @ApiProperty()
  public districtId?: string;

  @IsUUID()
  @IsOptional()
  @ApiProperty()
  public subzoneId?: string;

  @IsOptional()
  @IsDateString()
  @ApiProperty()
  public dueDate?: string;

  @IsOptional()
  @IsUUID(undefined, { each: true })
  @ApiPropertyOptional()
  public assigneesId?: string[];

}
