import { createTheme } from '@mui/material';

import { colors } from './options/palette/const/colors';
import { breakpoints } from './options/breakpoints';
import { palette } from './options/palette/palette';
import { typography } from './options/typography';

export const oldDesignTheme = createTheme({
  palette,
  typography,
  breakpoints,
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          headingTitleDesktop: 'h1',
          headingTitleMobile: 'h1',
          headingSubtitleDesktop: 'h2',
          headingSubtitleMobile: 'h2',
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: 'bold',
          textTransform: 'none',
          borderRadius: 100,
        },
        containedSecondary: {
          color: '#FFFFFF',
          backgroundColor: '#0D654E',
          '&:hover': {
            backgroundColor: '#0a503e',
          },
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            fontFamily: 'Roboto',
            fontSize: '15px',
            fontWeight: 'normal',
          },
        },
        root: {
          backgroundColor: colors.paper
        }
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,
          minWidth: '198px',
          color: palette.text?.secondary,
          backgroundColor: colors.paper,
          padding: 0,
        },
        arrow: {
          fontSize: 25,
          '&::before': {
            backgroundColor: colors.paper,
          }
        },
      }
    },
  }
});
