import { computed, makeObservable, observable } from 'mobx';

import { DistrictModel } from '../../../domain/model/DistrictModel';
import { AbstractDistrictFilter } from './AbstractDistrictFilter';

export class DistrictsSelectorFilterCondition {

  @observable
  public district: DistrictModel;

  @observable
  public id: string = '';

  @observable
  public active: boolean = false;

  constructor() {
    makeObservable(this);
  }

  public static fromDistrict(district: DistrictModel): DistrictsSelectorFilterCondition {
    const districtsSelectorFilter = new DistrictsSelectorFilterCondition();
    districtsSelectorFilter.district = district;
    districtsSelectorFilter.id = district.id ?? '';
    districtsSelectorFilter.active = true;

    return districtsSelectorFilter;
  }
}

export class DistrictsSelectorFilter extends AbstractDistrictFilter {

  constructor(
    private readonly districts: DistrictModel[],
  ) {
    super((districts[0] && districts[0].id) ? `DistrictsSelectorFilter-districts-${districts[0]!.id}` : 'DistrictsSelectorFilter', 1);
    makeObservable(this);
  }

  @computed
  public get conditions() {
    if (!this.districts) {
      return [];
    }

    const districts = new Set<DistrictModel>();
    const conditions: DistrictsSelectorFilterCondition[] = [];

    this.districts.forEach((district) => {
      if (!districts.has(district)) {
        conditions.push(DistrictsSelectorFilterCondition.fromDistrict(district));
      }

      districts.add(district);
    });

    return this.loadCache(conditions);
  }

  public check = (districtId: string): boolean => {
    const activeConditions = this.conditions.filter((f) => f.active);

    // there is no active condition
    if (!activeConditions.length) {
      return false;
    }

    return activeConditions.some((c) => {
      return c.id === districtId;
    });
  }
}
