import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { MeshModel } from '../../../../domain/model/MeshModel';
import { colors } from '../../../../theme/options/palette/const/colors';
import { palette } from '../../../../theme/options/palette/palette';
import { GeoUtil } from '../../../../util/GeoUtil';

interface IProps {
  mesh: MeshModel;
}

export const MapboxDistrictMeshLines = observer(function MapboxDistrictMesh(props: IProps) {
  const points = props.mesh.points;

  const dashedLine: GeoJSON.Feature<GeoJSON.Geometry> = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: points.length > 1 ? [
        [points[0].longitude, points[0].latitude],
        [points[points.length - 1].longitude, points[points.length - 1].latitude],
      ] : []
    }
  };

  const dashedLineLayer: LayerProps = useMemo(() => {
    return {
      type: 'line',
      paint: {
        'line-color': palette.common?.districtMesh,
        'line-width': 3,
        'line-dasharray': [2, 1]
      }
    };
  }, []);

  return (
    <>
      {points.map((point, index) => {
        if (index === points.length - 1) {
          return;
        }

        const id = `district-mesh-point-${index}`;
        const startPoint = [point.longitude, point.latitude];
        const endPoint = [points[index + 1].longitude, points[index + 1].latitude];

        return (
          <Source key={id} id={id} type="geojson" data={{
            type: 'Feature',
            properties: {},
            geometry:
            {
              type: 'LineString',
              coordinates: [startPoint, endPoint],
            }
          }}>
            <Layer type="line" layout={{ 'line-join': 'round', 'line-cap': 'round' }} paint={{
              'line-color': props.mesh.selectedPair && GeoUtil.isSamePoint(point, props.mesh.selectedPair[0])
                ? colors.warning
                : palette.common?.districtMesh,
              'line-width': 4,
            }}
            />
          </Source>
        );
      })}
      {props.mesh.points.length > 2 && <Source generateId type="geojson" data={dashedLine}>
        <Layer {...dashedLineLayer} />
      </Source>}
    </>
  );
});
