import { inject } from 'inversify';
import { action, computed, makeObservable, observable } from 'mobx';
import { ViewModel } from '../../../../domain/core/ViewModel';
import { TrackingEvent } from '../../../../domain/service/tracking/TrackingEvent';
import { TrackingService } from '../../../../domain/service/tracking/TrackingService';
import { SessionStore } from '../../../../domain/store/SessionStore';
import { transient } from '../../../../inversify/decorator';
import { FEATURE } from '../../../../shared/enum';
import { DistrictFilterVm } from '../../filters/DistrictFilterVm';

export enum FilterSidebarTabEnum {
  GENERAL = 'filter:filter_tab_general',
  USER = 'filter:filter_tab_members',
  POI = 'filter:filter_tab_pois',
  ANIMAL_TYPE = 'filter:filter_tab_animal_type',
  MAP_PATHS = 'filter:map_paths',
  SUBZONES = 'filter:filter_tab_subzones',
}

export interface FilterSidebarTab {
  name: FilterSidebarTabEnum;
  enabled: boolean;
  hasFilterEnabled: boolean;
}

export interface FilterSidebarProps {
  onClose: () => void;
  districtFilterVm: DistrictFilterVm;
}

@transient()
export class FilterSidebarVm extends ViewModel<FilterSidebarProps> {

  @observable
  public tab: FilterSidebarTab | null = null;

  constructor(
    @inject(SessionStore) private readonly session: SessionStore,
    @inject(TrackingService) public readonly tracking: TrackingService,
  ) {
    super();
    makeObservable(this);
  }

  @action
  public override onInit = async () => {
    this.tab = this.poiTabs[0];
    await this.tracking.track(TrackingEvent.FILTER_OPENED);
  }

  @computed
  public get poiTabs(): FilterSidebarTab[] {
    const tabs: FilterSidebarTab[] = [
      {
        name: FilterSidebarTabEnum.GENERAL,
        hasFilterEnabled: this.props.districtFilterVm.hasActiveGeneralFilter || this.props.districtFilterVm.poiFilter.allHidden,
        enabled: true,
      },
      {
        name: FilterSidebarTabEnum.USER,
        hasFilterEnabled: this.props.districtFilterVm.hasActiveUserFilter,
        enabled: true,
      },
      {
        name: FilterSidebarTabEnum.POI,
        hasFilterEnabled: this.props.districtFilterVm.hasActivePoiFilter,
        enabled: true,
      },
      {
        name: FilterSidebarTabEnum.ANIMAL_TYPE,
        hasFilterEnabled: this.props.districtFilterVm.hasActiveAnimalTypeFilter,
        enabled: true,
      },
      {
        name: FilterSidebarTabEnum.MAP_PATHS,
        hasFilterEnabled: this.props.districtFilterVm.hasActiveMapPathsFilter,
        enabled: this.session.hasFeatureEnabled(FEATURE.MAP_PATH),
      },
      {
        name: FilterSidebarTabEnum.SUBZONES,
        hasFilterEnabled: this.props.districtFilterVm.hasActiveSubzoneFilter,
        enabled: this.session.hasFeatureEnabled(FEATURE.SUBZONES),
      },
    ];

    return tabs;
  }

  @action
  public setTab = (tab: FilterSidebarTab) => {
    this.tab = tab;
  }
}
