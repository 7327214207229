import { default as React, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { oldDesignTheme } from '../../../../theme/oldDesignTheme';
import { palette } from '../../../../theme/options/palette/palette';
import { DeleteAccount, DeleteAccountRef } from '../delete-account/DeleteAccount';
import { legalLinksList } from './UserProfileLegalLinks';

const useStyles = makeStyles({
  root: {
    '&.MuiTypography-subtitle1': {
      color: '#3B3B3B',
      fontSize: '15px',
      fontWeight: 'normal',
    },
  },
  aTag: {
    fontSize: 15,
    fontWeight: 'bold',
    color: oldDesignTheme.palette.text.secondary,
  },
  containerPadding: {
    marginLeft: '2rem'
  },
});


export const UserProfileLegal: React.FC = () => {

  const classes = useStyles();
  const { t } = useTranslation();

  const deleteAccountRef: React.RefObject<DeleteAccountRef> = React.createRef();

  const legalLinks = useMemo(() => legalLinksList, []);

  return (
    <Grid container item
      xs={12} lg={6}
      spacing={2}
      padding={2}
      justifyContent="center"
    >
      {/* HELP CENTER */}
      <Grid
        item container xs={6} md={4} alignItems="center" direction="column"
      >
        <Grid item textAlign="left">
          <Typography variant="subtitle1" className={classes.root}>{t('profile:info.subtitle1')}</Typography>
          <a target="blank" href={t('links:helpcenter')} className={classes.aTag}>{t('profile:info.links_1')}</a>
          <Typography
            fontWeight={'bold'}
            fontSize={15}
            fontFamily="-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif"
            color={palette.text?.secondary}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => deleteAccountRef.current?.open()}
          >{t('profile:delete_account')}</Typography>
          <DeleteAccount ref={deleteAccountRef} />
        </Grid>
      </Grid>

      {/* LEGAL */}
      <Grid
        item container xs={6} md={4} alignItems="center" direction="column"
      >
        <Grid item textAlign="left">
          <Typography variant="subtitle1" className={classes.root}>{t('profile:info.subtitle2')}</Typography>
          {legalLinks.map((link) => {
            return (
              <Grid key={link.to} item xs={12}>
                <a target="blank" href={t(link.to)} className={classes.aTag}>{t(link.label)}</a>
              </Grid>
            );
          })}
        </Grid>
      </Grid>

    </Grid>
  );
};
