import { makeObservable, observable } from 'mobx';
import { EntityMemberResponseDto, MemberResponseDto } from '../../shared/dto';
import { DISTRICT_ROLE } from '../../shared/enum';
import { RoleModel } from './RoleModel';
import { UserModel } from './UserModel';


export class MemberModel {

  @observable
  public user: UserModel | null = null;

  @observable
  public role: RoleModel | null = null;

  constructor() {
    makeObservable(this);
  }

  public static fromEntityMember = (dto: EntityMemberResponseDto) => {
    const member = new MemberModel();

    member.user = UserModel.fromDto(dto.user);
    member.role = RoleModel.fromRole(dto.role as DISTRICT_ROLE);

    return member;
  }

  public static fromDto = (dto: MemberResponseDto) => {
    const member = new MemberModel();

    member.user = UserModel.fromDto(dto.user);
    member.role = RoleModel.fromDto(dto.role);

    return member;
  }

  public clone = () => {
    const cloned = new MemberModel();
    cloned.user = this.user?.clone() ?? null;
    cloned.role = this.role?.clone() ?? null;

    return cloned;
  }
}
