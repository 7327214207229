import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import {
  Box, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup
} from '@mui/material';

import { useContainer } from '../../../../../../domain/hooks/useContainer';
import { useVm } from '../../../../../../domain/hooks/useVm';
import { EntryModel } from '../../../../../../domain/model/EntryModel';
import { FALLWILD_TYPE, HARVEST_TYPE } from '../../../../../../shared/enum';
import { palette } from '../../../../../../theme/options/palette/palette';
import { EntryHelper } from '../../../../../../util/EntryHelper';
import { ItemAccordion } from '../../../../../../toolkit/components/ItemAccordion';
import { EntryFallwildVm, IFallwildListItem, SectionList } from './EntryFallwildVm';
import { useCallback } from 'react';

export interface EntryFallwildProps {
  entry: EntryModel;
  canEditAndDeleteEntries: boolean;
  isHarvestVisibleAndEditable: boolean;
}

export const EntryFallwild = observer(function EntryFallwild(props: EntryFallwildProps) {
  const { t } = useTranslation();
  const vm = useVm(EntryFallwildVm, props);
  const entryHelper = useContainer().get(EntryHelper);

  const checkPermissions = useCallback(() => {
    if (!props.canEditAndDeleteEntries) {
      if (!props.isHarvestVisibleAndEditable) {
        return entryHelper.notifyWrongPermission('entry:harvest_readonly_permission');
      }
      return entryHelper.notifyWrongPermission();
    }
  }, [entryHelper, props.canEditAndDeleteEntries, props.isHarvestVisibleAndEditable]);

  const handleFallwildTypeChange = useCallback((event) => {
    checkPermissions();
    props.entry.setEntryFallwildType(event.target.value as FALLWILD_TYPE);
  }, [checkPermissions, props.entry]);


  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="inherit">

      <ItemAccordion selectedItem={t(vm.fallWildTranslation)}>
        <Grid container direction="column" rowSpacing={4}>
          {vm.fallwildList.map((sectionItem: SectionList<IFallwildListItem>) => {
            return (
              <Grid item key={`sectionTitle-${sectionItem.title}`}>
                <FormControl fullWidth>
                  <FormLabel
                    sx={{
                      color: palette.text?.details,
                      fontSize: '1.125rem'
                    }}
                  >{t(sectionItem.title)}</FormLabel>
                  <RadioGroup
                    value={props.entry.fallwildType ?? HARVEST_TYPE.HARVEST}
                    onChange={handleFallwildTypeChange}
                    name="fallwild-radio-buttons-group"
                  >
                    {sectionItem.data.map((fallwildItem: IFallwildListItem) => {
                      return (
                        <Box marginRight={2} key={`fallwild-${fallwildItem.key}`}>
                          <FormControlLabel
                            value={fallwildItem.key}
                            control={
                              <Radio
                                color='secondary'
                                inputProps={{
                                  readOnly: !props.canEditAndDeleteEntries
                                }}
                              />}
                            label={fallwildItem.value}
                            labelPlacement="start"
                            sx={{
                              '&.MuiFormControlLabel-root': {
                                display: 'flex',
                                justifyContent: 'space-between',
                              },
                              '& .MuiTypography-root': {
                                color: '#000'
                              }
                            }}
                          />
                          <Divider />
                        </Box>
                      );
                    })}
                  </RadioGroup>
                </FormControl>
              </Grid>
            );
          })}
        </Grid>
      </ItemAccordion>
    </Box>
  );
});
