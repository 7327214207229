import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Types } from '../../inversify/types';
import { ViewModel } from '../core/ViewModel';
import { useContainer } from './useContainer';

export function useVm<TViewModel extends ViewModel<TProps>, TProps>(
  Vm: new (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...args: any[]) => TViewModel,
  props: TProps = {} as TProps,
): TViewModel {

  const vmRef = useRef<TViewModel>();
  const container = useContainer();
  const navigate = useNavigate();
  const params = useParams();

  const initializing = !vmRef.current;
  if (initializing) {
    const vmContainer = container.createChild();
    vmContainer.bind(Types.Navigate).toConstantValue(navigate);
    vmContainer.bind(Types.Props).toConstantValue(props);
    vmContainer.bind(Types.Params).toConstantValue(params);

    vmRef.current = vmContainer.get(Vm);
  }

  if (vmRef.current) {
    vmRef.current.setProps(props);

    // should be run only once
    if (initializing) {
      vmRef.current.onInit();
    }

    vmRef.current.onRender();
  }

  useEffect(() => {
    return function cleanup() {
      vmRef.current?.onDestroy();
    };
  }, []);

  return vmRef.current!;
}
