import { Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PoiModel } from '../../../../../../domain/model/PoiModel';
import { ShootingAngleType, ShootingDirectionType } from '../../../../../../shared/types/shootingDirection';
import { ISliderStep, LabeledSlider } from './LabeledSlider';

interface IProps {
  poi: PoiModel;
}

export const PoiShootingDirection = observer(function PoiShootingDirection(props: IProps) {

  const { t } = useTranslation();

  const directionSteps: ISliderStep<ShootingDirectionType>[] = useMemo(() => {
    return [
      { text: t('poi:shooting_direction_S'), value: 180, level: 1, },
      { text: t('poi:shooting_direction_SSW'), value: 202.5, level: 3, },
      { text: t('poi:shooting_direction_SW'), value: 225, level: 2, },
      { text: t('poi:shooting_direction_WSW'), value: 247.5, level: 3, },
      { text: t('poi:shooting_direction_W'), value: 270, level: 1, },
      { text: t('poi:shooting_direction_WNW'), value: 292.5, level: 3, },
      { text: t('poi:shooting_direction_NW'), value: 315, level: 2, },
      { text: t('poi:shooting_direction_NNW'), value: 337.5, level: 3, },
      { text: t('poi:shooting_direction_N'), value: 0, level: 1, },
      { text: t('poi:shooting_direction_NNE'), value: 22.5, level: 3, },
      { text: t('poi:shooting_direction_NE'), value: 45, level: 2, },
      { text: t('poi:shooting_direction_ENE'), value: 67.5, level: 3, },
      { text: t('poi:shooting_direction_E'), value: 90, level: 1, },
      { text: t('poi:shooting_direction_ESE'), value: 112.5, level: 3, },
      { text: t('poi:shooting_direction_SE'), value: 135, level: 2, },
      { text: t('poi:shooting_direction_SSE'), value: 157.5, level: 3, },
    ];
  }, [t]);

  const angleSteps: ISliderStep<ShootingAngleType>[] = useMemo(() => {
    return [
      { text: '0°', value: 0, level: 1, },
      { text: '45°', value: 45, level: 2, },
      { text: '90°', value: 90, level: 1, },
      { text: '135°', value: 135, level: 2, },
      { text: '180°', value: 180, level: 1, },
      { text: '225°', value: 225, level: 2, },
      { text: '270°', value: 270, level: 1, },
      { text: '315°', value: 315, level: 2, },
      { text: '360°', value: 360, level: 1, },
    ];
  }, []);

  return (
    <Box width="100%" px={2}>
      <Stack spacing={2}>
        <Box>
          <Typography ml={.5} my={1} variant="body1">{t('poi:shooting_direction')}</Typography>
          <LabeledSlider
            sliderPadding={'2.8%'}
            steps={directionSteps}
            value={props.poi.shootingDirection != null ? directionSteps.findIndex((s) => s.value == props.poi.shootingDirection) : 0}
            onChange={(direction) => props.poi.setShootingDirection(direction as ShootingDirectionType)}
          />
        </Box>
        <Box>
          <Typography ml={.5} my={1} variant="body1">{t('poi:shooting_angle')}</Typography>
          <LabeledSlider
            sliderPadding={'5.3%'}
            steps={angleSteps}
            value={props.poi.shootingAngle ? angleSteps.findIndex((s) => s.value == props.poi.shootingAngle) : 0}
            onChange={(angle) => props.poi.setShootingAngle(angle as ShootingAngleType)}
          />
        </Box>
      </Stack>
    </Box>
  );
});
