import { parseISO } from 'date-fns';
import { makeObservable, observable } from 'mobx';

import {
  SubscriptionReceiptResponseDto
} from '../../shared/dto/subscription/subscription.receipt.response.dto';
import { PAYMENT_PLATFORM } from '../../shared/enum/paymentPlatform.enum';
import { RC_PERIOD_TYPE_ENUM } from '../../shared/enum/revenuecat.period.type.enum';
import { RC_WEBHOOK_EVENT_TYPE } from '../../shared/enum/revenuecat.webhook.event.type.enum';

export class SubscriptionReceiptResponseModel {

  @observable
  public platform: PAYMENT_PLATFORM | null = null;

  @observable
  public expiresAt: Date | null = null;

  /**
   * If true, then this exact subscription last the longest
   * else false
  */
  @observable
  public active: boolean = false;

  @observable
  public periodType?: RC_PERIOD_TYPE_ENUM = undefined;

  @observable
  public type?: RC_WEBHOOK_EVENT_TYPE = undefined;

  @observable
  public productId?: string | undefined = undefined;

  /**
   * If null or undefined -> user DOES HAVE active subscription
   * else -> user CANCELED subscription
  */
  @observable
  public unsubscribeDetectedAt: string | null | undefined = null;

  constructor() {
    makeObservable(this);
  }

  public static fromDto = (dto: SubscriptionReceiptResponseDto) => {
    const model = new SubscriptionReceiptResponseModel();

    model.platform = dto.platform;
    model.expiresAt = dto.expiresAt ? parseISO(dto.expiresAt) : null;
    model.active = dto.active;
    model.productId = dto.productId;
    model.periodType = dto.periodType;
    model.type = dto.type;
    model.unsubscribeDetectedAt = dto.unsubscribeDetectedAt;

    return model;
  }

}
