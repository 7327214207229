import { observer } from 'mobx-react-lite';
import { BaseSyntheticEvent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { Autocomplete, Box, Grid, MenuItem, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import { useVm } from '../../../../domain/hooks/useVm';
import { MEASUREMENT_SYSTEM } from '../../../../shared/enum';
import { oldDesignTheme } from '../../../../theme/oldDesignTheme';
import { colors } from '../../../../theme/options/palette/const/colors';
import { ValidatedForm } from '../../../../toolkit/components/form/ValidatedForm';
import { ValidatedFormControl } from '../../../../toolkit/components/form/ValidatedFormControl';
import { SubmitButton } from '../../../../toolkit/components/SubmitButton';
import { languageList } from '../../../../toolkit/constants/LanguageList';
import { CountryListItem, UserProfileFormPartVm } from './UserProfileFormPartVm';

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-input': {
      color: oldDesignTheme.palette.text.secondary,
      fontSize: '18px',
      fontWeight: 'bold',
    },
    '& .MuiSelect-select': {
      whiteSpace: 'break-spaces',
    },
    '&$disabled': {
      color: colors.paper,
      backgroundColor: '#A5B1C2',
      pointerEvents: 'unset', // allow :hover styles to be triggered
      cursor: 'not-allowed', // and custom cursor can be defined without :hover state
    },
  },
  disabled: {},
  inputProps: {
    color: oldDesignTheme.palette.text.secondary,
    fontSize: '18px',
    fontWeight: 'bold',
  },
  btnDimensions: {
    width: '246px',
    height: '48px',
  },
});

interface UserProfileFormProps {
  vm: UserProfileFormPartVm;
}

const UserProfileFormPart = observer(function UserProfileFormPart({ vm }: UserProfileFormProps) {

  const classes = useStyles();
  const { i18n, t } = useTranslation();

  const isSaveButtonDisabled = useMemo(() => !vm.isFormDirty || vm.isBusy, [vm.isBusy, vm.isFormDirty]);

  useEffect(() => {
    if (vm.isLanguageChanged) {
      i18n.changeLanguage(vm.language);
      vm.setIsLanguageChanged(vm.language !== i18n.language);
      vm.updateCountryList();
    }
  }, [i18n, i18n.language, vm, vm.isLanguageChanged]);

  return (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>

      <Grid item container direction="column" xs={11} lg={8} spacing={2}>
        {/* FIRST NAME */}
        <Grid item>
          <ValidatedFormControl
            value={vm.firstName}
            fullWidth
            schema={yup
              .string()
              .required(t('profile:validations.required.firstname'))
            }
          >
            <TextField
              value={vm.firstName}
              className={classes.root}
              label={t('profile:info.user_firstname')}
              variant="filled"
              fullWidth
              onChange={(event: BaseSyntheticEvent) => {
                vm.setFirstname(event.target.value);
              }}
            />
          </ValidatedFormControl>
        </Grid>
        {/* LAST NAME */}
        <Grid item>
          <ValidatedFormControl
            value={vm.lastName}
            fullWidth
            schema={yup
              .string()
              .required(t('profile:validations.required.lastname'))
            }
          >
            <TextField
              value={vm.lastName}
              className={classes.root}
              label={t('profile:info.user_lastname')}
              variant="filled"
              fullWidth
              onChange={(event: BaseSyntheticEvent) => {
                vm.setLastname(event.target.value);
              }}
            />
          </ValidatedFormControl>
        </Grid>
        {/* LANGUAGE */}
        <Grid item>
          <ValidatedFormControl
            value={vm.language}
            fullWidth
            schema={yup
              .string()
              .required(t('profile:validations.required.language'))
            }
          >
            <TextField
              select
              value={vm.language}
              fullWidth
              label={t('profile:info.user_language')}
              variant="filled"
              InputProps={{
                className: classes.inputProps
              }}
              onChange={(event: BaseSyntheticEvent) => {
                vm.setLanguage(event.target.value);
              }}
            >
              {languageList.map((language) => {
                return (
                  <MenuItem key={language.label} value={language.value}>
                    {t(language.label)}
                  </MenuItem>
                );
              })}
            </TextField>
          </ValidatedFormControl>
        </Grid>
        {/* COUNTRY */}
        <Grid item>
          <ValidatedFormControl
            fullWidth
            value={vm.country ?? ''}
          >
            <Autocomplete<CountryListItem, false, true, false>
              autoHighlight
              disableClearable={true}
              value={vm.country ?? undefined}
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              options={vm.sortedCountriesList}
              fullWidth
              renderOption={(props, option) => <Box component="li" {...props}>{option.label}</Box>}
              onChange={(_event, value) => vm.setCountry(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label={t('profile:info.user_country')}
                  classes={{ root: classes.root }}
                  variant="filled"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </ValidatedFormControl>
        </Grid>
        {/* MEASURES */}
        <Grid item>
          <ValidatedFormControl
            value={vm.distanceUnit}
            fullWidth
            schema={yup
              .string()
              .required(t('profile:validations.required.measures'))
            }
          >
            <TextField
              value={vm.distanceUnit}
              select
              fullWidth
              label={t('profile:info.user_measures')}
              variant="filled"
              InputProps={{
                className: classes.inputProps
              }}
              onChange={(event: BaseSyntheticEvent) => {
                vm.setDistanceUnit(event.target.value);
              }}
            >
              {Object.values(MEASUREMENT_SYSTEM).map((measure) => {
                return (
                  <MenuItem key={measure} value={measure}>
                    {t(`profile:measure_${measure}`)}
                  </MenuItem>
                );
              })}
            </TextField>
          </ValidatedFormControl>
        </Grid>
        {/* EMAIL */}
        <Grid item>
          <ValidatedFormControl
            value={vm.email}
            fullWidth
            schema={yup
              .string()
              .required(t('profile:validations.required.email'))
            }
          >
            <TextField
              value={vm.email}
              className={classes.root}
              type="email"
              label={t('auth:register_email_form_email')}
              variant="filled"
              fullWidth
              onChange={(event: BaseSyntheticEvent) => {
                vm.setEmail(event.target.value);
              }}
            />
          </ValidatedFormControl>
        </Grid>
      </Grid>

      <Grid item container justifyContent="center" xs={11} sm={8}>
        <Grid item>
          <SubmitButton
            disabled={isSaveButtonDisabled} // Enable/disable the "Save" button based on form dirty status and submission state
            submitting={vm.isBusy}
            className={classes.btnDimensions}
            classes={{ root: classes.root, disabled: classes.disabled }}
            color="secondary"
          >
            {t('profile:info.button_update')}
          </SubmitButton>
        </Grid>
      </Grid>
    </Grid>
  );
});

export const UserProfileForm = () => {
  const vm = useVm(UserProfileFormPartVm);
  return (
    <ValidatedForm onSubmit={vm.updateUser.run}>
      <UserProfileFormPart vm={vm} />
    </ValidatedForm>
  );
};
