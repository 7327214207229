import { format } from 'date-fns';
import { inject } from 'inversify';
import { computed, makeObservable } from 'mobx';

import { ViewModel } from '../../../../domain/core/ViewModel';
import { PhotoModel } from '../../../../domain/model/PhotoModel';
import {
  SubscriptionReceiptResponseModel
} from '../../../../domain/model/SubscriptionReceiptResponseModel';
import { I18nService } from '../../../../domain/service/I18nService';
import { SessionStore } from '../../../../domain/store/SessionStore';
import { transient } from '../../../../inversify/decorator';
import { PAYMENT_PLATFORM } from '../../../../shared/enum/paymentPlatform.enum';
import { UserProfileProps } from '../../UserProfileRoute';
import { RECEIPT_PRODUCT_IDS } from '../../UserProfileRouteVm';

@transient()
export class UserProfileButtonPartVm extends ViewModel<UserProfileProps> {

  constructor(
    @inject(SessionStore) public readonly sessionStore: SessionStore,
    @inject(I18nService) private readonly i18n: I18nService,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  public get firstName(): string {
    return this.sessionStore.session?.user.firstName ?? '';
  }

  @computed
  public get lastName(): string {
    return this.sessionStore.session?.user.lastName ?? '';
  }

  @computed
  public get photo(): PhotoModel | null {
    return this.sessionStore.session?.user.photo ?? null;
  }

  @computed
  public get fullName(): string {
    if (!this.sessionStore.session) return '';

    return `${this.firstName} ${this.lastName}`;
  }

  @computed
  public get activeSubscription(): SubscriptionReceiptResponseModel | boolean {
    return Boolean(this.props.userProfileVm.receipts.find(receipt => receipt.active));
  }

  @computed
  public get groupSubscriptionOwner(): string {
    return this.sessionStore.session?.groupSubscriptionOwner?.fullName ?? '';
  }

  public getExpirationDate = (expiresAt: Date | null) => {
    if (!expiresAt) return this.i18n.t('profile:pro_lifetime');
    return format(expiresAt, 'dd.MM.yyyy.');
  }

  public isExpired = (expiresAt: Date): boolean => {
    const today = new Date();

    return expiresAt.getTime() <= today.getTime();
  };



  public isGroupOwner = (receipt: SubscriptionReceiptResponseModel): boolean => {
    if (this.sessionStore.currentUser?.isPartOfGroupSubscription && this.sessionStore.session?.groupSubscriptionOwner) {
      return !!(this.sessionStore.session.user.id === this.sessionStore.session.groupSubscriptionOwner?.id);
    }

    const isExpired = receipt.expiresAt && this.isExpired(new Date(receipt.expiresAt));

    const isGroupOwner = !!(
      receipt.platform === PAYMENT_PLATFORM.STRIPE
      && this.sessionStore.session?.isGroupOwner
      && !isExpired
    );
    return isGroupOwner;
  }

  public isGroupMember = (receipt: SubscriptionReceiptResponseModel): boolean => {
    if (this.sessionStore.currentUser?.isPartOfGroupSubscription && this.sessionStore.session?.groupSubscriptionOwner) {
      return !!(this.sessionStore.currentUser.id !== this.sessionStore.session.groupSubscriptionOwner?.id);
    }

    const isExpired = receipt.expiresAt && this.isExpired(new Date(receipt.expiresAt));

    return !!(receipt.platform === PAYMENT_PLATFORM.PROMOTIONAL
      && !receipt.unsubscribeDetectedAt
      && !isExpired
      && receipt.productId === RECEIPT_PRODUCT_IDS.GROUP_MEMBER
    );
  }

  public getSubscriptionInfo = (receipt: SubscriptionReceiptResponseModel): string => {
    const isGroupSubscription = this.isGroupMember(receipt) || this.isGroupOwner(receipt);

    return `${isGroupSubscription ? this.i18n.t('profile:pro_group_until') : this.i18n.t('profile:pro_until')} ${this.getExpirationDate(receipt.expiresAt)}`;
  }

  public getPaymentPlatformTranslation = (receipt: SubscriptionReceiptResponseModel): string => {
    switch (receipt.platform) {
      case PAYMENT_PLATFORM.PLAY_STORE:
        return this.i18n.t('profile:payment_platform.play_store');
      case PAYMENT_PLATFORM.APP_STORE:
        return this.i18n.t('profile:payment_platform.app_store');
      case PAYMENT_PLATFORM.STRIPE:
        return this.i18n.t('profile:payment_platform.stripe');
      default:
        return '';
    }
  }

}
