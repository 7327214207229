import { computed, makeObservable } from 'mobx';
import { ViewModel } from '../../../../../../domain/core/ViewModel';
import { IViewPoiInfoProps } from './ViewPoiInfo';
import { inject } from 'inversify';
import { SessionStore } from '../../../../../../domain/store/SessionStore';
import { FEATURE } from '../../../../../../shared/enum';
import { transient } from '../../../../../../inversify/decorator';

@transient()
export class ViewPoiInfoVm extends ViewModel<IViewPoiInfoProps> {
  constructor(
    @inject(SessionStore) public readonly sessionStore: SessionStore,
  ) {
    super();
    makeObservable(this);
  }

  @computed
  private get hasCustomMarks(): boolean {
    return this.sessionStore.hasFeatureEnabled(FEATURE.CUSTOM_MARKS);
  }

  @computed
  public get canEdit(): boolean {
    return this.props.canEditAndDeletePoi && (this.props.poi.isCustomPoi || this.hasCustomMarks);
  }
}
