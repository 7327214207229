import { ApiProperty, ApiPropertyOptional } from '@nestjs/swagger';

export class MapPathTypeResponseDto {

  @ApiProperty()
  public id: string;

  @ApiProperty()
  public key: string;

  @ApiPropertyOptional()
  public color: string | null;
}
